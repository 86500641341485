// @flow

import React, { useState } from 'react';
import { Pendency } from '~/screens/ContractDetails/Panels/Pendencies/styled';
import pendencyTitle from '~/typings/enums/PendencyType';
import { Button, Modal } from '~/components';
import Selection from './components/Selection';

type DocumentsProps = {
  onEnter: (number: string) => void,
  id?: number,
  type?: string,
};

function DocumentNumber(props: DocumentsProps) {
  const [showModal, setStateShowModal] = useState(false);
  const setShowModal = (show) => () => {
    setStateShowModal(show);
  };

  const onEnter = (number) => {
    props.onEnter(number);
  };

  return (
    <>
      <Pendency onClick={setShowModal(true)}>
        {pendencyTitle(props.type) || props.children}
        <span>{props.reason}</span>
      </Pendency>
      <Modal
        title="Número do RG"
        maskClosable
        visible={showModal}
        key="modal"
        okText="Enviar"
        onCancel={setShowModal(false)}
        onOk={onEnter}
        footer={[<Button title="Enviar" onClick={onEnter} />]}
      >
        <Selection onPressEnter={onEnter} />
      </Modal>
    </>
  );
}

export default DocumentNumber;
