// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import { Select } from 'antd';

import ImmutableSelectorGenerator from '~/common/ImmutableSelectorGenerator';
import { SearchSelect } from '~/common';

type BankSelectProps = {
  onChange: any,
};

export default function BankSelect({ onChange, ...props }: BankSelectProps) {
  const banks = useSelector(
    ImmutableSelectorGenerator.generate([
      'fetchFinancialInstitutionsForDeposit',
    ]),
  );

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      allowClear
      placeholder='Selecione'
      filterOption={(input, option) =>
        SearchSelect(input, option.props.children)
      }
      optionFilterProp='children'
      onChange={onChange}
      {...props}
    >
      {banks.getIn(['payload']).map(item => (
        <Select.Option key={item.bankNumber}>{item.name}</Select.Option>
      ))}
    </Select>
  );
}
