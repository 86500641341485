import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const SidebarContainer = styled.div`
  width: 336px;
  height: 100%;
  min-width: 230px;
  background: ${({ theme }) => theme.colors.white.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SidebarTop = styled.div`
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  border-bottom: 0.7px solid ${({ theme }) => theme.colors.gray['100']};
  gap: 4px;
  
  .tags {
    margin-top: 8px;
  }
`;

export const SidebarMenu = styled.div`
  display: flex;
  flex: 1;
`;

export const Menu = styled.div`
  width: 100%;
  padding: 24px 0;
`;

interface ActiveProp {
  active?: boolean;
}

interface DisabledProp {
  disabled?: boolean;
}

export const MenuItem = styled.div<ActiveProp & DisabledProp>`
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding-left: 24px;
  border-right: 5px solid transparent;
  cursor: pointer;
  
  ${({ theme, active }) => active && css`
    background-color: ${theme.colors.gray['50']};
    border-right: 5px solid ${theme.colors.primary.main};
    cursor: default;
  `}
  
  ${({ disabled }) => disabled && css`
    opacity: 0.3;
    pointer-events: none;
  `}
`;
