import React, { useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import useCombineLoadings from '~/hooks/useCombineLoadings';
import useAsyncActionOpenFile from '~/hooks/useAsyncActionOpenFile';
import { Spinner } from '~/components';
import Hiscon from '~/typings/entities/Hiscon';
import { getHisconHistoryExtract, requestHisconDownload } from '~/store/enrollments/actions';
import { HisconContainer, Container, Tip, Title, Info } from './styled';

type HisconHistoricProps = {
  id: number;
};

function HisconHistoric({ id }: HisconHistoricProps) {
  const getPaychecksControl = useAwaitControl(getHisconHistoryExtract);
  const getPaycheckFileControl = useAwaitControl(requestHisconDownload);

  const loading = useCombineLoadings(
    getPaycheckFileControl.isRunning(),
    getPaychecksControl.isRunning(),
  );
  useAsyncActionOpenFile(getPaycheckFileControl);
  const { entries }: { entries: Hiscon[] } = getPaychecksControl.result();

  useEffect(() => {
    if (id) {
      getPaychecksControl.start({ enrollmentId: id });
    }
  }, [id]);

  const onPressHisconDownload = useCallback(
    (paycheckId: number) => () => {
      getPaycheckFileControl.start({ enrollmentId: id, paycheckId });
    },
    [id],
  );

  return (
    <Container>
      <Spinner spinning={loading} />
      {entries?.map((hiscon) => (
        <HisconContainer key={hiscon.id} onClick={onPressHisconDownload(hiscon.id)}>
          <Title>Enviado em:</Title>
          <Info>{hiscon.referenceDate.format('DD/MM/YYYY HH:mm')}</Info>
          {hiscon.processed && (
            <>
              <Title>Processado em:</Title>
              <Info>{hiscon.processedAt.format('DD/MM/YYYY HH:mm')}</Info>
            </>
          )}
          <Title>Tipo:</Title>
          <Info marginBottom={5}>{hiscon.personPaycheckFileType}</Info>
          <Tip>Clique para visualizar</Tip>
        </HisconContainer>
      ))}

      {!loading && !entries?.length && (
        <HisconContainer>
          <Title>Não há histórico de transações disponível.</Title>
        </HisconContainer>
      )}
    </Container>
  );
}

export default HisconHistoric;
