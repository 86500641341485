import React, { useMemo } from 'react';
import { Button, Form as DefaultForm, FormInput, Select, withStepperProps } from '~/ui/components';

import _ from 'lodash';
import { AssessmentBody, SectionTitle } from '../Assessment.styled';

export type FormProps = {
  values: any;
  onSubmit: (values: any) => void;
  loading?: boolean;
};

const YES_NO_OPTIONS = [
  { label: 'Sim', value: 'true' },
  { label: 'Não', value: 'false' },
];

export const AnalysisForm = withStepperProps<FormProps>(({ values, loading, onSubmit }) => {
  const getInitialValue = (value) => {
    if (_.isBoolean(value)) return [String(value)];
    return value ? [value] : null;
  };

  const inputs: FormInput[] = useMemo(
    () => [
      {
        id: 'section-title',
        label: <SectionTitle>Bloco Ouvir</SectionTitle>,
      },
      {
        id: 'historyAnalyzed',
        label: 'Análise do histórico',
        input: <Select options={YES_NO_OPTIONS} />,
        initialValue: getInitialValue(values.historyAnalyzed),
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
        labelHelp: {
          title: 'Análise de histórico',
          width: 346,
          content: (
            <>
              <p>
                O agente está atento ao histórico de interação do cliente durante o atendimento e/ou
                realiza consulta dos registros e informações no bko pertinentes a demanda/jornada do
                cliente?
              </p>
              <p>
                O expert deve estar atento para analisar o histórico de interação do cliente ou de
                contatos anteriores dele com o nosso atendimento para evitar realizar
                questionamentos de informações que o cliente já nos passou e para dar continuidade a
                uma demanda já solicitada e que não foi concluida em um atendimento anterior,
                mostrando interesse genuino em resolver o seu problema ou necessidade.
              </p>
            </>
          ),
        },
      },
      {
        id: 'activeListening',
        label: 'Escuta ativa',
        input: <Select options={YES_NO_OPTIONS} />,
        initialValue: getInitialValue(values.activeListening),
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
        labelHelp: {
          title: 'Escuta ativa',
          width: 346,
          content: (
            <>
              <p>
                O agente demonstra escuta ativa durante o atendimento, mostrando interesse genuíno
                nas preocupações e necessidades do cliente com o objetivo de solucionar sua demanda
                ou objetivo?
              </p>
              <p>
                A escuta ativa no atendimento ao cliente é fundamental para estabelecer uma
                comunicação eficaz, entender as necessidades do cliente e fornecer soluções
                adequadas para seus problemas ou preocupações.
              </p>
            </>
          ),
        },
      },
      {
        id: 'problemDiscovery',
        label: 'Discovery de problemas ou melhorias',
        input: <Select options={YES_NO_OPTIONS} />,
        initialValue: getInitialValue(values.problemDiscovery)
          ? [String(values.problemDiscovery)]
          : null,
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
        labelHelp: {
          title: 'Discovery de problemas ou melhorias',
          width: 346,
          content: (
            <>
              <p>
                O agente buscou realizar um discovery, realizando perguntas pertinentes para
                entender completamente os problemas ou sugestões de melhorias apresentadas pelo
                cliente?
              </p>
              <p>
                O expert deve realizar o discovery de forma eficiente, buscando entender se o
                cliente teve alguma dificuldade na jornada, no manuseio do nosso app, ou qualquer
                insegurança que o impediu de integrar/formalizar de forma autônoma e independente.
              </p>
            </>
          ),
        },
      },
      {
        id: 'section-title2',
        label: <SectionTitle>Bloco Resolver</SectionTitle>,
      },
      {
        id: 'proactivity',
        label: 'Proatividade e solução de dúvidas',
        input: <Select options={YES_NO_OPTIONS} />,
        initialValue: getInitialValue(values.proactivity),
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
        labelHelp: {
          title: 'Proatividade e solução de dúvidas',
          width: 346,
          content: (
            <>
              <p>
                Apresentou uma solução eficiente para a dúvida¹demanda do cliente, mostrando domínio
                e segurança sobre os nossos produtos e regras de negócio,conforme os fluxos
                desenhados para área?
              </p>
              <p>
                Respondeu o cliente com foco em solucionar sua dúvida, sem rodeios e transmitindo
                segurança?
              </p>
              <p>Ser proativo em:</p>
              <div>-Auxilio no envio do hiscon;</div>
              <div>-Auxilio na recuperação da senha;</div>
              <div>-Auxilio na etapa de documentos/selfie;</div>
              <div>
                -Auxiliar o cliente no manuseio do app ou envio de evidências de erros/problemas.
              </div>
              <div>-Tornar o atendimento mais ágil;</div>
              <div>
                -Se adiantar em situações que podem gerar problemas futuros no contrato ou impactar
                na experiência do cliente, orientando de forma assertiva;
              </div>
              <div>-Interesse em novas oportunidades;</div>
            </>
          ),
        },
      },
      {
        id: 'reassuring',
        label: 'Resolução de objeções e inseguranças',
        input: <Select options={YES_NO_OPTIONS} />,
        initialValue: getInitialValue(values.reassuring),
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
        labelHelp: {
          title: 'Resolução de Objeções e Inseguranças',
          width: 346,
          content: (
            <>
              <p>
                Em caso de objeções, insegurança ou demonstração de medo sobre nossos processos,
                houve sondagem e tentativa de conversão e/ou retenção da opinião do cliente,
                evidenciando nossos diferenciais?
              </p>
              <p>-Sondagem/Argumentação para fechamento de proposta.</p>
              <div>-Tentativa de retenção</div>
              <div>-Inseguranças/insatisfação do cliente com nossos processos.</div>
            </>
          ),
        },
      },
      {
        id: 'proceduresExecuted',
        label: 'Execução dos procedimentos de Atendimento',
        input: <Select options={YES_NO_OPTIONS} />,
        initialValue: getInitialValue(values.proceduresExecuted)
          ? [String(values.proceduresExecuted)]
          : null,
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
        labelHelp: {
          title: 'Execução dos Procedimentos de Atendimento',
          width: 346,
          content: (
            <>
              <p>
                O agente realizou os procedimentos internos/operacionais necessários em atendimento
                para resolver o problema/demanda do cliente?
              </p>
              <p>
                Inclusão na SOS, reapresentação de pagamento, anexar os documentos/DED, envio de
                passo a passo, alteração/inclusão no bko...
              </p>
            </>
          ),
        },
      },
    ],
    [values],
  );

  const handleSubmit = (values: any) => {
    onSubmit?.(values);
  };

  return (
    <AssessmentBody>
      <DefaultForm inputs={inputs} onSubmit={handleSubmit} loading={loading}>
        <Button rounded customColor="black" fullWidth loading={loading}>
          Prosseguir com a avaliação
        </Button>
      </DefaultForm>
    </AssessmentBody>
  );
});
