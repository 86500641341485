const uiTheme = {
  colors: {
    light: {
      brand: {
        primary: '#D80073',
        primaryAlt: '#A60058',
        secondary: '#FFE5F3',
        secondaryAlt: '#FFA3D4',
        overPrimary: '#FFFFFF',
      },
      info: {
        primary: '#2F2EE6',
        primaryAlt: '#0100B2',
        secondary: '#D6E4FF',
        secondaryAlt: '#B2CDFF',
        overPrimary: '#FFFFFF',
      },
      alert: {
        primary: '#F67701',
        primaryAlt: '#B74A01',
        secondary: '#FFF1E0',
        secondaryAlt: '#FFD8B2',
        overPrimary: '#FFFFFF',
      },
      positive: {
        primary: '#45B12E',
        primaryAlt: '#005C00',
        secondary: '#E5FFE5',
        secondaryAlt: '#C3FAB8',
        overPrimary: '#FFFFFF',
      },
      negative: {
        primary: '#E2001B',
        primaryAlt: '#B00015',
        secondary: '#FFE8E5',
        secondaryAlt: '#FF99A5',
        overPrimary: '#FFFFFF',
      },
      neutral: {
        primary: '#1A1A1A',
        primaryAlt: '#2B2B2B',
        secondary: '#F5F5F5',
        secondaryAlt: '#DEDEDE',
        overPrimary: '#FFFFFF',
      },
      element: {
        primary: '#1A1A1A',
        secondary: '#666666',
        placeholder: '#9A9A9A',
        disabled: '#CFCFCF',
        disabledBackground: '#ECECEC',
        invert: '#FFFFFF',
        onSuccess: '#005C00',
        onError: '#B00015',
        overImage: '#1A1A1A',
        overHeader: '#FFFFFF',
      },
      background: {
        primary: '#FFFFFF',
        secondary: '#F5F5F5',
        tertiary: '#ECECEC',
        quartenary: '#1A1A1A',
        navbar: '#F5F5F5CC',
        transparent: 'transparent',
      },
      divider: {
        primary: '#00000033',
        secondary: '#1A1A1A',
        brand: '#D80073',
        positive: '#005C00',
        negative: '#B00015',
        alert: '#B74A01',
        info: '#0100B2',
        invert: '#FFFFFF33',
      },
      state: {
        hover: '#4C4C4C14',
        active: '#4C4C4C29',
        overlay: '#1A1A1AA3',
        imageOverlay: '#1A1A1A29',
      },
    },
    dark: {
      brand: {
        primary: '#FF429E',
        primaryAlt: '#FFA3D0',
        secondary: '#7F003E',
        secondaryAlt: '#B20056',
        overPrimary: '#292929',
      },
      info: {
        primary: '#1897EC',
        primaryAlt: '#6EC3F7',
        secondary: '#00396B',
        secondaryAlt: '#004F8F',
        overPrimary: '#292929',
      },
      alert: {
        primary: '#F17706',
        primaryAlt: '#F7AA64',
        secondary: '#633803',
        secondaryAlt: '#804604',
        overPrimary: '#292929',
      },
      positive: {
        primary: '#38B12E',
        primaryAlt: '#A0E49A',
        secondary: '#123706',
        secondaryAlt: '#1C4912',
        overPrimary: '#292929',
      },
      negative: {
        primary: '#FB516D',
        primaryAlt: '#FEB3BF',
        secondary: '#730D0D',
        secondaryAlt: '#A11223',
        overPrimary: '#292929',
      },
      neutral: {
        primary: '#F5F5F5',
        primaryAlt: '#DEDEDE',
        secondary: '#191919',
        secondaryAlt: '#2B2B2B',
        overPrimary: '#292929',
      },
      element: {
        primary: '#FFFFFF',
        secondary: '#DEDEDE',
        placeholder: '#ECECEC',
        disabled: '#7F7F7F',
        disabledBackground: '#464646',
        invert: '#1A1A1A',
        onSuccess: '#A0E49A',
        onError: '#FEB3BF',
        overImage: '#1A1A1A',
        overHeader: '#FFFFFF',
      },
      background: {
        primary: '#292929',
        secondary: '#1A1A1A',
        tertiary: '#000000',
        quartenary: '#1A1A1A',
        navbar: '#1A1A1AE0',
        transparent: '#FFFFFF03',
      },
      divider: {
        primary: '#FFFFFF3D',
        secondary: '#FFFFFF',
        brand: '#FF429E',
        positive: '#A0E49A',
        negative: '#FEB3BF',
        alert: '#F7AA64',
        info: '#6EC3F7',
        invert: '#00000033',
      },
      state: {
        hover: '#00000033',
        active: '#4C4C4C29',
        overlay: '#1A1A1AA3',
        imageOverlay: '#1A1A1A29',
      },
    },
  },
  spacing: {
    '75': 2,
    '150': 4,
    '300': 8,
    '350': 12,
    '400': 16,
    '450': 20,
    '500': 24,
    '600': 32,
    '700': 40,
    '800': 48,
    '900': 56,
    '1000': 64,
    '1100': 72,
    '1200': 80,
    '1300': 88,
    '1400': 96,
    '1800': 128,
  },
  typography: {
    fontWeight: {
      bold: '700',
      semibold: '600',
      regular: '400',
    },
    heading: {
      fontFamily: 'Montserrat',
      h1: {
        fontSize: 48,
        lineHeight: 72,
      },
      h2: {
        fontSize: 40,
        lineHeight: 64,
      },
      h3: {
        fontSize: 32,
        lineHeight: 48,
      },
      h4: {
        fontSize: 24,
        lineHeight: 40,
      },
      h5: {
        fontSize: 20,
        lineHeight: 32,
      },
      h6: {
        fontSize: 18,
        lineHeight: 24,
      },
    },
    body: {
      fontFamily: 'Open Sans',
      lg: {
        fontSize: 18,
        lineHeight: 28,
      },
      md: {
        fontSize: 16,
        lineHeight: 24,
      },
      sm: {
        fontSize: 14,
        lineHeight: 20,
      },
      xs: {
        fontSize: 12,
        lineHeight: 18,
      },
    },
  },
  radius: {
    '0': 0,
    '200': 4,
    '300': 8,
    '400': 12,
    '500': 16,
    '600': '50%',
  },
};

export default uiTheme;
