/* @flow */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { AUTHENTICATION_CHAT_FETCH_REQUEST, success, failure } from './action';

import { ajax as AjaxRequest } from '~/store';

const events = action$ =>
  action$
    .ofType(AUTHENTICATION_CHAT_FETCH_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(data => {
      return AjaxRequest.open('post', '/loginlivechat', data)('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err)));
    });

export default combineEpics(events);
