import styled from 'styled-components';
import { Switch } from 'antd';
import { Typography } from '~/ui/components';
import { themeColor } from '~/common/utils/theme';

export const Container = styled.div.attrs({
  className: 'switch-container',
})`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledSwitch = styled(Switch)`
  --antd-wave-shadow-color: ${({ theme }) => theme.colors.brand.primary};

  &.ant-switch {
    background-color: ${themeColor('element.disabled', { dark: 'element.disabledBackground' })};
  }

  &.ant-switch::after {
    background-color: ${themeColor('element.invert', { dark: 'element.disabled' })};
  }

  &.ant-switch-checked {
    background-color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }

  &.ant-switch-checked::after {
    background-color: ${themeColor('element.invert', { dark: 'element.primary' })};
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

export const SwitchLabel = styled(Typography).attrs({
  type: 'paragraphSmall',
  element: 'label',
})`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
`;
