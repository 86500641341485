import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 16px 0;

  ${({ loading }) =>
    loading &&
    css`
      button {
        opacity: 0.5;
        pointer-events: none;
      }
    `}
`;

export const ImageContainer = styled.div`
  background: transparent;
`;
