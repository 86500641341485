import {
  useCallback, useState, useEffect, useMemo,
} from 'react';

import moment from 'moment';

import { useInterval } from '~/hooks/useInterval';

const emptyDiff = moment.duration(moment(moment.now()).diff(moment.now()));

export default function useTimer(
  endTime: string,
  showSeconds: boolean = false,
  updateInterval: number = 45_000,
  onFinish: () => any = null,
) {
  const [duration, setDuration] = useState<any>();
  const [onFinishFired, setOnFinishFired] = useState(false);

  const updateDuration = useCallback(() => {
    const diff = moment.duration(moment(endTime).diff(moment.now()));
    if (onFinish && !onFinishFired && Math.trunc(diff.asSeconds()) <= 0) {
      setOnFinishFired(true);
      onFinish();
    } else if (onFinish) {
      setOnFinishFired(false);
    }
    setDuration(diff.asSeconds() > 0 ? diff : emptyDiff);
  }, [endTime, onFinishFired]);

  useInterval(updateDuration, updateInterval);

  useEffect(() => {
    updateDuration();
  }, []);

  return useMemo(() => {
    const timer = duration && `${Math.abs(Math.trunc(duration.asHours()))}:${Math.abs(duration.minutes()).toString().padStart(2, '0')}`;

    if (showSeconds && timer) {
      return `${timer}:${Math.abs(duration.seconds?.()).toString().padStart(2, '0')}`;
    }

    return timer;
  }, [duration]);
}
