import React from 'react';
import styled from 'styled-components';

export const Infos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: -14px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    margin-bottom: 6px;
  }
`;

export const Date = styled.span`
  color: rgba(0, 0, 0, 0.3.5);
  text-align: right;
  font-size: 13px;
  margin-right: 10px;
  padding: 5px 0;

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;
