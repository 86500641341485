import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import validateFileByType from '~/common/validateFileByType';
import { useModal } from '~/hooks/useModal';
import { message, Tooltip } from 'antd';
import { PreviewModal } from '~/components';
import {
  ActionButton,
  ActionButtons,
  DocumentContainer,
  DocumentItem,
  DocumentName,
  FileImage,
  PDFIcon,
} from './FileUpload.styled';

export type FileItem = {
  name: string;
  id: string;
  file: File | null;
};

type FileUploadProps = {
  id: string;
  name?: string;
  onChange?: (file: FileItem) => void;
  onRemove?: (file: FileItem) => void;
  accept?: string;
};

export function FileUpload({
  name,
  id,
  onChange,
  onRemove,
  accept = 'application/pdf, image/*',
}: FileUploadProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | ArrayBuffer | null>(null);
  const { openModal } = useModal();

  const isPdf = useMemo(() => file?.type === 'application/pdf', [file]);

  const handleFileUpload = () => {
    if (!file) {
      inputRef.current?.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    validateFileByType(files?.[0], accept);
    if (validateFileByType(files?.[0], accept)) {
      setFile(files?.[0] ?? null);
      onChange?.({ id, name, file: files?.[0] ?? null });
    } else {
      message.warning(
        `Tipo de arquivo inválido! Os tipos aceitos são: ${accept?.split(',').join(', ')}`,
      );
      inputRef.current.value = '';
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFilePreview(null);
    inputRef.current.value = '';
    onRemove?.({ id, name, file: null });
  };

  const preview = useMemo(() => {
    if (isPdf) {
      return <PDFIcon />;
    }
    return <FileImage preview={filePreview} alt="document" selected={!!file} />;
  }, [file, filePreview]);

  const handleFilePreview = useCallback(() => {
    openModal(<PreviewModal file={file} isPdf={isPdf} />, {
      width: 'auto',
    });
  }, [file, openModal]);

  useEffect(() => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
    }

    return () => {
      reader.abort();
    };
  }, [file]);

  return (
    <>
      <DocumentContainer className={`${!!file && 'selected'}`}>
        <Tooltip title={file?.name} placement="top">
          <DocumentItem onClick={handleFileUpload}>{preview}</DocumentItem>
        </Tooltip>

        <DocumentName>{name}</DocumentName>

        <ActionButtons>
          <ActionButton icon="Eye" onClick={handleFilePreview} />
          <ActionButton icon="Close" onClick={handleRemoveFile} />
        </ActionButtons>
      </DocumentContainer>

      <input
        ref={inputRef}
        accept={accept}
        type="file"
        onChange={handleFileChange}
        name={id}
        id={id}
        hidden
      />
    </>
  );
}
