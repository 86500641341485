import takeRequest from '~/store/takeRequest';
import { put, takeLatest } from 'redux-saga/effects';
import * as TicketActions from './actions';

export function* reloadTicketsList({ meta }) {
  const { personId } = meta.payload.ticket;
  yield put(TicketActions.getTicketsByUser.start({ personId }));
}

export function* reloadTicketCommentsList({ meta }) {
  const id = meta.payload.ticketId;
  yield put(TicketActions.getTicketComments.start(id, { actionId: id }));
}

export function* reloadTicketAndList({ meta }) {
  const { personId, ticketId } = meta.payload;
  yield put(TicketActions.getTicketDetails.start({ id: ticketId }, { actionId: ticketId }));
  yield put(TicketActions.getTicketsByUser.start({ personId }));
}

export function* ticketSaga() {
  yield takeRequest(TicketActions.getServiceChannels);
  yield takeRequest(TicketActions.getReasons);
  yield takeRequest(TicketActions.createTicket);
  yield takeRequest(TicketActions.createTicketComment);
  yield takeRequest(TicketActions.getTicketUsers);
  yield takeRequest(TicketActions.getTicketsByUser);
  yield takeRequest(TicketActions.getTicketTeams);
  yield takeRequest(TicketActions.getTicketDetails);
  yield takeRequest(TicketActions.getTicketComments);
  yield takeRequest(TicketActions.updateTicketComment);
  yield takeRequest(TicketActions.updateTicket);
  yield takeRequest(TicketActions.getTicketResolvingAreas);
  yield takeRequest(TicketActions.getTicketStatus);
  yield takeRequest(TicketActions.getTicketAccompaniments);
  yield takeRequest(TicketActions.hasLinkedTicket);
  yield takeRequest(TicketActions.selectTicket);
  yield takeRequest(TicketActions.getTicketProducts);
  yield takeRequest(TicketActions.getTicketJourneys);
  yield takeRequest(TicketActions.getTicketResolutionReasons);
  yield takeRequest(TicketActions.getTicketSubResolutionReasons);
  yield takeRequest(TicketActions.getTicketJourneysSteps);
  yield takeRequest(TicketActions.getTickets);
  yield takeRequest(TicketActions.selectAssessment);
  yield takeRequest(TicketActions.getExperts);
  yield takeRequest(TicketActions.createTicketAssessment);
  yield takeRequest(TicketActions.updateTicketAssessment);
  yield takeRequest(TicketActions.getTicketMessages);
  yield takeRequest(TicketActions.getTicketAssessment);
  yield takeRequest(TicketActions.getTicketMedia);

  yield takeLatest(TicketActions.createTicket.success.toString(), reloadTicketsList);
  yield takeLatest(TicketActions.updateTicket.success.toString(), reloadTicketAndList);
  yield takeLatest(TicketActions.createTicketComment.success.toString(), reloadTicketCommentsList);
  yield takeLatest(TicketActions.updateTicketComment.success.toString(), reloadTicketCommentsList);
}
