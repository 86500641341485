import React from 'react';
import styled from 'styled-components';
import { Timeline as AntTimeline } from 'antd';
import { Typography } from '~/ui/components';

export const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

type ColorProps = {
  color: string;
};

export const Dot = styled.div`
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 1px;
  width: 100%;
`;

export const DotContainer = styled.div<ColorProps>`
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 1px;
  border: 1px solid ${({ color }) => color};
  width: 16px;

  ${Dot} {
    border: 1px solid ${({ color }) => color};
    background: ${({ color }) => color};
  }
`;

export const Timeline = styled(AntTimeline)`
  padding: 14px 4px 4px;
`;

export const TimelineItem = styled(AntTimeline.Item).attrs(({ color }) => ({
  dot: (
    <DotContainer color={color}>
      <Dot />
    </DotContainer>
  ),
}))`
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: 0 0 20px;

  &:last-child {
    padding-bottom: 0;
  }

  .ant-timeline-item-content {
    margin: 0 0 0 36px;
  }

  .ant-timeline-item-tail {
    display: block;
  }
`;

export const TimelineTitle = styled(Typography).attrs({
  type: 'paragraphSmall',
  weight: '700',
})``;

export const TimelineText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  weight: '500',
  color: theme.colors.gray[500],
}))`
  b {
    font-weight: 600;
  }
`;

export const TimelineDate = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  weight: '400',
  color: theme.colors.gray[500],
}))``;
