import { Upload, Divider as DividerComponent } from 'antd';
import styled from 'styled-components';
import { ArrowLeft, CloseCircle, FilePlus } from '~/ui/assets/icons';
import { Button, Typography } from '~/ui/components';

const { Dragger: DraggerComponent } = Upload;

export const Container = styled.div``;

export const StepsContainer = styled.div`
  position: relative;
  overflow: hidden;

  & > div {
    position: relative;
    left: 0;
  }

  &.slideOut > div {
    opacity: 0;
    left: -20px;
    transition: opacity 0.2s ease, left 0.2s ease;
  }

  &.slideLeft > div {
    opacity: 0;
    left: 40px;
  }

  &.slideRight > div {
    opacity: 0;
    left: -40px;
    transition: opacity 0.2s ease, left 0.2s ease;
  }

  &.slideIn > div {
    left: 0;
    opacity: 1;
    transition: opacity 0.2s ease, left 0.2s ease;
  }
`;

export const AlertContainer = styled.div`
  margin-bottom: 16px;
`;

export const Dragger = styled(DraggerComponent)`
  margin-top: 10px;
  background: ${(props) => props.theme.colors.white.main} !important;
  border-color: ${(props) => props.theme.colors.gray[400]} !important;
  border-radius: ${(props) => props.theme.cardBorderRadius} !important;

  > span.ant-upload-btn {
    padding: 24px 0 !important;
  }

  :hover {
    border-color: ${(props) => props.theme.colors.secondary['50']} !important;
  }
`;

export const DraggerClickLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: 500,
  element: 'span',
  color: theme.colors.primary.main,
}))``;

export const DraggerLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: 400,
  element: 'p',
  color: theme.colors.gray[800],
}))``;

export const Caption = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'p',
  color: theme.colors.gray[800],
}))`
  margin-top: 2px;
`;

export const Text = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: theme.colors.gray[700],
}))`
  margin: 2px 0;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'h5',
  color: theme.colors.gray.main,
  weight: 500,
}))``;

export const FakeTextArea = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray[100]};
  border-radius: 8px;

  padding: 10px 12px;
  margin-top: 4px;
`;

export const FileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FileIcon = styled(FilePlus)`
  stroke: ${(props) => props.theme.colors.primary.main};
  margin-bottom: 3px;
  margin-right: 5px;
`;

export const FileSize = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'p',
  color: theme.colors.gray[700],
}))`
  margin-left: 22px;
  margin-bottom: 20px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px 0;
`;

export const ModalTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  weight: 500,
  color: theme.colors.gray[800],
}))`
  flex: 1;
`;

export const CloseButton = styled(CloseCircle)`
  fill: ${({ theme }) => theme.colors.primary.main};
  margin-bottom: 4px;
  cursor: pointer;
`;

export const BackButton = styled(ArrowLeft)`
  stroke: ${({ theme }) => theme.colors.primary.main};
  margin-right: 10px;
  cursor: pointer;
`;

export const Divider = styled(DividerComponent)`
  margin: 2px 0 10px !important;
  height: 2px !important;
  background: ${(props) => props.theme.colors.secondary['50']} !important;
`;

export const ActionButton = styled(Button).attrs(({ theme, tooltipPlacement }) => ({
  iconOnly: true,
  size: 'sm',
  variant: 'text',
  customColor: theme.colors.primary.main,
  tooltipPlacement: tooltipPlacement || 'topLeft',
}))`
  padding: 0;

  &:focus-visible {
    outline: none;
  }
`;
