import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Content } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background-color: white;

  svg {
    width: 56px;
    height: 64px;
  }
`;

export const InfoContent = styled(Content)`
  background-color: ${themeColor('background.tertiary')};
  border-radius: 16px;
  width: 100%;
  gap: 2px;
  padding-block: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
  margin-block: 12px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-block: 16px;

  svg,
  h6,
  p {
    width: 100%;
    text-align: center;
  }

  .align-center {
    width: 100%;
    text-align: center;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
