import styled, { css } from 'styled-components';

export const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

type StepProps = {
  active?: boolean;
  prev?: boolean;
  next?: boolean;
  animation?: 'left' | 'right';
  duration?: number;
};

const TranslateLength = 20;

export const Step = styled.div<StepProps>`
  ${({ duration }) => css`
    width: 100%;
    height: 100%;
    opacity: 1;

    &.slideLeft {
      opacity: 0;
      transform: translateX(${TranslateLength}%);
    }

    &.slideLeftEnter {
      opacity: 1;
      transform: translateX(0);
      transition: all ${duration || 500}ms ease, opacity ${(duration || 500) + 300}ms ease;
    }

    &.slideLeftExit {
      opacity: 0;
      transform: translateX(-${TranslateLength}%);
      transition: all ${duration || 500}ms ease;
    }

    &.slideRight {
      opacity: 0;
      transform: translateX(-${TranslateLength}%);
    }

    &.slideRightEnter {
      opacity: 1;
      transform: translateX(0);
      transition: all ${duration || 500}ms ease, opacity ${(duration || 500) + 300}ms ease;
    }

    &.slideRightExit {
      opacity: 0;
      transform: translateX(${TranslateLength}%);
      transition: all ${duration || 500}ms ease;
    }
  `}
`;
