export const TicketStatusIds = {
  CLOSED: 44,
  SCHEDULED: 3,
  FORWARDED: 4,
};

export const TicketStatusColors = {
  1: { bgColor: '#FFF1E0', textColor: '#B74A01' },
  3: { bgColor: '#D6E4FF', textColor: '#0100B2' },
};

export const TicketGroupStatusColors = {
  2: { bgColor: '#FFE5F3', textColor: '#A60058' },
  3: { bgColor: '#E5FFE5', textColor: '#005C00' },
};
