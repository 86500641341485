/* @flow */

export const REFUSE_PERSON_REGISTRY_REQUEST = 'REFUSE_PERSON_REGISTRY_REQUEST';
export const REFUSE_PERSON_REGISTRY_SUCCESS = 'REFUSE_PERSON_REGISTRY_SUCCESS';
export const REFUSE_PERSON_REGISTRY_FAILURE = 'REFUSE_PERSON_REGISTRY_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: REFUSE_PERSON_REGISTRY_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: REFUSE_PERSON_REGISTRY_FAILURE,
      payload: resource,
    });
  };
}

export default function ApprovePersonRegistry(params) {
  return dispatch => {
    dispatch({
      type: REFUSE_PERSON_REGISTRY_REQUEST,
      payload: params,
    });
  };
}
