import React, { useCallback } from 'react';

import { OldActions as MenuAction } from '~/components';
import LoanType from '~/typings/enums/LoanType';
import { useModal } from '~/hooks/useModal';
import { DedConfirmModal } from './DedConfirmModal/DedConfirmModal';

type GenerateDEDProps = {
  contractId: string;
  contract: any;
  kycValidation?: boolean;
};

export default function GenerateDEDAction({
  contractId,
  contract,
  kycValidation,
}: GenerateDEDProps) {
  const { openModal } = useModal();

  const { loanStatus, loanType } = contract;
  const isPaid = loanStatus === 'PAID';
  const { REFIN, NEW, PORTABILITY, FUTUREMARGIN, FGTS_NEW } = LoanType.LOAN_TYPES;
  const hasLoanType = [REFIN, NEW, PORTABILITY, FUTUREMARGIN, FGTS_NEW].includes(loanType);

  if (!isPaid || !hasLoanType || kycValidation) {
    return null;
  }

  const handleOnPress = useCallback(() => {
    openModal(<DedConfirmModal contractId={contractId} />, {
      title: 'Acessar DED',
      width: 540,
      closable: true,
    });
  }, []);

  return <MenuAction key="download_ded" onPress={handleOnPress} title="Acessar DED" />;
}
