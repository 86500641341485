import React from 'react';
import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

import { Tooltip } from '~/ui/components';
import { CaretDown } from '~/ui/assets/icons';
import { themeColor, themeToggleValue } from '~/common/utils/theme';

export const NavbarLogo = styled.div`
  height: 100%;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};

  svg {
    height: 25px;
    width: 112px;
    display: block;
  }

  .mobile {
    display: none;
    width: 24px;
    height: 28px;
  }

  @media (max-width: 1158px) {
    svg {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
`;

export const NavbarMenu = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  gap: 36px;

  @media (max-width: 880px) {
    gap: 24px;
  }
`;

const linkCSS = css`
  background: transparent;
  height: 25px;
  gap: 9px;
  padding: 0;
  border-radius: 0;
  position: relative;
  min-width: max-content;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.element.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
  font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
  line-height: ${({ theme }) => theme.typography.types.paragraphSmall.lineHeight};

  svg {
    height: 14px;
    width: auto;
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }

  .caret {
    width: 9px;
    margin-left: 8px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};

      svg {
        color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
      }
    `};

  @media (max-width: 880px) {
    font-size: 14px;
  }

  @media (max-width: 794px) {
    font-size: 12px;
  }
`;

export const NavbarButton = styled((props: LinkProps) => <Link {...props} />)`
  ${linkCSS};
`;

type NavbarItemProps = {
  trigger?: 'click' | 'hover';
};

export const NavbarItem = styled.div<NavbarItemProps>`
  ${({ trigger }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    ${trigger === 'click' ? '&.open' : '&:hover'} {
      svg {
        color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
      }

      .submenu {
        display: block;
        width: 100%;
        opacity: 1;
        left: -10px;
        overflow: visible;
      }

      .bottom-line {
        width: 100%;
        transition: width 0.2s ease-in;
      }
    }
  `}
`;

export const Caret = styled(CaretDown)`
  color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  margin-left: 6px;
  width: 10px;
`;

export const Submenu = styled.nav`
  background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
  border-radius: 16px;
  padding: 0 19px 0 16px;
  position: absolute;
  top: 30px;
  box-shadow: ${({ theme }) =>
    themeToggleValue(theme, '0 2px 8px rgb(0 0 0 / 15%)', {
      dark: `3px 3px 0px ${theme.colors.divider.invert}`,
    })};
`;

export const SubItem = styled(NavbarButton)`
  ${linkCSS};

  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray['100']};
  width: 100%;
  justify-content: flex-start;
  height: 56px;

  svg {
    height: 16px;
  }

  &:last-of-type {
    border: none;
  }

  &:hover {
    .typography {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`;

export const SubmenuWrapper = styled.div.attrs({
  className: 'submenu',
})`
  height: 50px;
  width: 0;
  left: -30px;
  position: absolute;
  top: 25px;
  transition: opacity 0.2s ease-in, left 0.2s ease-in;
  opacity: 0;
  overflow: hidden;
`;

export const ButtonLine = styled.div`
  height: 2px;
  width: 0;
  background-color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  position: absolute;
  transition: width 0.1s ease-out;
  bottom: -2px;
  left: 0;
`;

export const ProfilePicture = styled(Tooltip).attrs({
  placement: 'bottom',
})`
  background-color: ${themeColor('brand.secondaryAlt', { dark: 'brand.primary' })};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
`;

export const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 73px;
  background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
  box-sizing: border-box;
  padding: 23px 38px 23px 46px;
  min-width: max-content;
  z-index: 1000;
  gap: 32px;

  @media (max-width: 1070px) {
    padding: 23px 10px 22px 23px;
  }
`;

export const RightNavbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 12px;
  gap: 16px;

  @media (max-width: 1024px) {
    gap: 8px;
  }
`;

type RightContainerProps = {
  trigger?: 'click' | 'hover';
};

export const RightContainer = styled.div<RightContainerProps>`
  .nav-item {
    ${({ trigger }) => css`
      ${trigger === 'click' ? '&.open' : '&:hover'} {
        .submenu {
          width: 160px !important;
          right: -5px;
          display: block;
          opacity: 1;
          left: -10px;
          overflow: visible;
          transition: opacity 0.2s ease-in, right 0.2s ease-in !important;
        }
      }
    `}

    &.closing {
      .submenu {
        right: 20px;
        opacity: 0;
        transition: opacity 0.2s ease-in, right 0.2s ease-in !important;
      }
    }

    .submenu {
      top: 45px;
      left: unset !important;
      right: 20px;
      transition: none;

      nav {
        right: 0;
        top: 22px;
        padding: 8px 16px;
        cursor: default;

        a {
          padding: 0;
          height: 40px;
          border: none;

          svg {
            color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
          }
        }
      }
    }
  }
`;
