export default function style({ unit }) {
  const color = '#636878';
  const fontFamily = 'Rubik, sans-serif';

  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0 29px',
    },
    content: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
    },
    personName: {
      color,
      fontFamily,
      fontSize: '24px',
      fontWeight: '300',
      letterSpacing: 0,
      margin: 0,
    },
    personDocument: {
      width: '200px',
    },
    personDocument__preset: {
      color,
      fontSize: unit * 2,
      letterSpacing: 0,
    },
    personEnrollment: {
      width: '150px',
    },
    personRegistrationNumber: {
      width: '150px',
    },

    // -> Elementos para informações com maior foco:

    large: {
      // width: "200px"
    },
    large__preset: {
      color: '#272D40',
      fontSize: unit * 1.8,
      letterSpacing: 0,
    },
    large__description: {
      marginLeft: unit,
      fontSize: unit * 1.4,
    },

    // -> Elementos de Informações Bancárias:

    bankImage: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      border: '1px solid #eee',
      borderRadius: '3px',
      height: '110px',
      marginBottom: '20px',
      width: '150px',
    },
    agency: {
      color: '#272D40',
      display: 'block',
      fontSize: unit * 2,
      letterSpacing: 0,
      lineHeight: 1,
    },
    account: {
      color: '#272D40',
      display: 'block',
      fontSize: unit * 2,
      letterSpacing: 0,
      lineHeight: 1,
    },
    account__preset: {
      display: 'block',
      margin: `${unit * 1.5}px 0`,
    },
    bankName: {
      color: '#272D40',
      display: 'block',
      fontFamily,
      fontSize: unit * 1.3,
      margin: `${unit * 1}px 0`,
      letterSpacing: 0,
      lineHeight: 1,
    },

    // -> Tipo de contrato:

    contractType: {
      background: '#95a5a6',
      boxShadow: 'inset 0 -1px 0 0 rgba(0,0,0,0.10)',
      borderRadius: 5,
      color: 'white',
      display: 'inline-block',
      marginBottom: unit,
      opacity: 0.65,
      padding: unit * 0.8,
      textTransform: 'uppercase',
      letterSpacing: '0.6px',
    },
    contractType__orange: {
      background: '#ffc64d',
    },
    contractType__red: {
      background: '#c0392b',
    },
    contractType__blue: {
      background: '#3498db',
    },

    // -> Elementos Globais:

    aside: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',
    },
    key: {
      display: 'block',
      color: '#949494',
      fontFamily,
      fontSize: unit * 0.9,
      letterSpacing: '0',
    },
    value: {
      width: '150px',
    },
    value__preset: {
      color,
      fontSize: unit * 2,
      letterSpacing: 0,
    },
    preset: {
      color,
      display: 'block',
      fontFamily,
      fontSize: unit * 1.4,
      fontWeight: 'bold',
    },
    input: {
      background: '#FFFFFF',
      border: '1px solid #CECECE',
      boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.10)',
      borderRadius: 3,
      height: unit * 4,
    },
    item: {
      minWidth: '130px',
      padding: '10px',
    },
  };
}
