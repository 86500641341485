import styled, { keyframes } from 'styled-components';
import { SpringValues } from '@react-spring/core';
import { animated } from '@react-spring/web';

export const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

export const fadeOut = keyframes`
  0% { left: 0; opacity: 1; display: flex; }
  50% { opacity: 0; display: flex; }
  100% { opacity: 0; display: none; }
`;

export const breath = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const fadeInTop = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export type AnimationProps = {
  animation: SpringValues;
};

export const Animate = styled(animated.div).attrs(({ style, animation }) => ({
  style: {
    ...style,
    ...animation,
  },
}))<AnimationProps>``;
