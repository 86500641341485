import styled from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Button } from '~/ui/components';

export const StyledButton = styled(Button).attrs(({ theme }) => ({
  size: 'md',
  customColor: themeToggleColor(theme, 'brand.secondary', { dark: 'brand.primaryAlt' }),
  icon: 'Ticket',
}))`
  border: 2px solid ${themeColor('brand.primaryAlt', { dark: 'brand.secondaryAlt' })} !important;
  border-radius: var(--radius-200, 16px);
  width: 136px;
  height: 42px;

  span {
    color: ${themeColor('brand.primaryAlt', { dark: 'brand.secondaryAlt' })} !important;
    font-size: 14px;
  }

  svg {
    color: ${themeColor('brand.primaryAlt', { dark: 'brand.secondaryAlt' })} !important;
    width: 22px;
    height: 22px;
  }
`;
