import React from 'react';
import { Overlay, Bounce1, Bounce2, Bounce3, Spinner } from './styled';

import './Spinner.css';

export default function SpinnerComponent({ invertColor: invert, spinning }) {
  if (spinning === false) return null;

  return (
    <Overlay inverted={invert}>
      <Spinner>
        <Bounce1 inverted={invert} />
        <Bounce2 inverted={invert} />
        <Bounce3 inverted={invert} />
      </Spinner>
    </Overlay>
  );
}

SpinnerComponent.defaultProps = {
  spinning: false,
  invertColor: false,
};
