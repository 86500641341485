import React, { useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { SideActions } from '~/components';
import { getContractBojo } from '~/store/clix/actions';
import { getPersonDetail } from '~/store/people/actions';

type ClixActionsProps = {
  loading?: boolean;
  onClick?: () => void;
  className?: string;
};

export function ClixActions({ loading, onClick, className }: ClixActionsProps) {
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getContractBojoControl = useAwaitControl(getContractBojo);

  const person = getPersonDetailsControl.result();
  const contractBojo = getContractBojoControl.result();
  const contractBojoLoading = getContractBojoControl.isRunning();
  const contractBojoSuccess = getContractBojoControl.isSuccessful();

  const handleDownloadContractBojo = useCallback((person) => {
    getContractBojoControl.start({ document: person.document });
    onClick?.();
  }, []);

  const actions = [
    {
      title: 'Baixar bojo do contrato',
      onClick: () => handleDownloadContractBojo(person),
    },
  ];

  useEffect(() => {
    if (contractBojoSuccess) {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${contractBojo}`;
      a.download = `bojo-${person.document}.zip`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [contractBojoSuccess]);

  return (
    <SideActions
      className={className}
      actions={actions}
      loading={loading}
      disabled={contractBojoLoading}
    />
  );
}
