enum TransactionsStatus {
  'CANCELED' = 'Cancelado',
  'INITIAL' = 'Em andamento',
  'PENDING' = 'Em andamento',
  'PROCESSING' = 'Em processamento',
  'PROCESSED' = 'Enviado',
  'ERROR' = 'Erro na transação',
  'REVERSED' = 'Estorno',
}

export default TransactionsStatus;
