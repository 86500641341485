const DEVELOPMENT = 'development';
const HOMOLOG = 'homolog';
const PRODUCTION = 'production';

const AppStage = {
  DEVELOPMENT,
  HOMOLOG,
  PRODUCTION,
  ALL: [DEVELOPMENT, HOMOLOG, PRODUCTION],
};

export default AppStage;
