import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';

import { Button, Input, SelectDate, Typography } from '~/ui/components';

import _ from 'lodash';
import { FilterContainer, FilterForm } from './Filters.styled';

export type FiltersProps = {
  onSubmit?: (values: any) => void;
  loading?: boolean;
};

export function Filters({ onSubmit, loading }: FiltersProps) {
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  const filters = useMemo(
    () => [
      {
        id: 'name',
        label: 'Nome:',
        initialValue: params.name,
        input: <Input placeholder="Insira o nome" allowClear />,
      },
      {
        id: 'document',
        label: 'CPF:',
        initialValue: params.document,
        input: <Input placeholder="Insira o CPF" allowClear />,
      },
      {
        id: 'email',
        label: 'Email',
        initialValue: params.email,
        input: <Input placeholder="Insira o email" allowClear />,
      },
      {
        id: 'phoneNumber',
        label: 'Telefone',
        initialValue: params.phoneNumber,
        input: <Input placeholder="Insira o telefone" allowClear />,
      },
      {
        id: 'dateRange',
        label: 'Período',
        initialValue:
          params.startDate && params.endDate ? [params.startDate, params.endDate] : null,
        input: <SelectDate selectRange maxDate={new Date()} />,
      },
    ],
    [],
  );

  const handleOnSubmit = (values) => {
    onSubmit?.(_.omitBy(values, _.isEmpty));
  };

  return (
    <FilterContainer>
      <Typography type="paragraphLarge" weight={500}>
        Filtros
      </Typography>

      <FilterForm inputs={filters} onSubmit={handleOnSubmit} loading={loading ? 1 : 0}>
        <Button icon="Search" size="sm" rounded className="submit-button" loading={loading}>
          Filtrar
        </Button>
      </FilterForm>
    </FilterContainer>
  );
}
