export default {
  NO_VALID_FACES_FOUND: 'Nenhum rosto válido foi encontrado.',
  PERSON_NOT_FOUND: 'Pessoa não encontrada',
  NOFACES_FOUND: 'Rosto não detectado',
  MULTIPLEFACES_FOUND: 'Foi detectado mais de um rosto',
  FACEALREADYREGISTERED_FOUND: 'Já existe um cadastro com esse rosto',
  FACIALPOSSIBLEFRAUD: 'Possível fraude',
  INTERNAL_SERVER_ERROR: 'Erro interno do servidor',
  NOT_FOUND: 'Não encontrado',
  DIFF_ENROLLMENT_IN_FILE:
    'A matrícula no arquivo é diferente da matrícula da pessoa',
  READING_PDF_ERROR: 'Erro ao ler o arquivo de PDF',
};
