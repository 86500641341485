import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';

export const requestHisconAutoUpdate = createAsyncAction('REQUEST_HISCON_UPDATE_REQUEST', {
  context: {
    request: ({ personId }) =>
      telli.get(`/admin/personcustom/people/${personId}/requesthisconautoupdate`),
    onRequestSuccess: () => message.success('Solicitação enviada!'),
    onRequestError: () => message.error('Falha ao enviar solicitação'),
  },
});
