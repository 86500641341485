import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, CopyableTextBox, Flex } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import { generateDED } from '~/store/contracts/actions';
import useUserRoles from '~/hooks/useUserRoles';

export type DedConfirmModalProps = {
  contractId: string;
};

export function DedConfirmModal({ contractId }: DedConfirmModalProps) {
  const { isAdmin, hasRole } = useUserRoles();

  const generateDEDControl = useAwaitControl(generateDED);

  const documentInfo: string | undefined = generateDEDControl.result();
  const loading = generateDEDControl.isRunning();
  const success = generateDEDControl.isSuccessful();

  const canDownload = useMemo(() => isAdmin || hasRole('LEGAL_TEAM'), [isAdmin, hasRole]);

  const handleDownload = useCallback(() => {
    generateDEDControl.start({ contractId });
  }, [contractId, success]);

  function download(documentInfo: string) {
    const pdfBlob = new Blob([documentInfo], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');

    link.setAttribute('download', `${contractId}.pdf`);
    link.setAttribute('href', fileURL);
    link.click();
    link.remove();
    URL.revokeObjectURL(fileURL);

    generateDEDControl.clear();
  }

  useEffect(() => {
    if (documentInfo && success) {
      download(documentInfo);
    }
  }, [documentInfo, success]);

  return (
    <Flex direction="column" gap={16}>
      <CopyableTextBox
        helpText="O acesso a DED é exclusivo para o cliente. Copie o link abaixo e envie para seu cliente."
        buttonText="Copiar link"
        textToCopy={`https://meutudo.go.link/FinancingDetail?adj_t=10g55ewj&contractId=${contractId}`}
      />

      {canDownload && (
        <Button
          rounded
          color="black"
          icon="Download"
          fullWidth
          onClick={handleDownload}
          loading={loading}
        >
          Baixar DED
        </Button>
      )}
    </Flex>
  );
}
