// @flow

import React from 'react';
import LoanType from '~/typings/enums/LoanType';
import { omit } from 'underscore';
import UFs from '~/constants/UFs';
import MaritalStatus from '~/constants/MaritalStatus';
import Genders from '~/constants/Genders';
import toNumber from '~/common/masked/toNumber';
import { Icon, Tooltip } from 'antd';
import Field from '~/screens/ContractDetails/Actions/components/SendContractToConsignee/Field';
import masked from '~/common/masked';

type FieldsProps = {
  loanType: string,
  onChange: () => void,
  disabledFields: any,
  fieldsData: any,
  getFieldDecorator: any,
};

const codProductText = `Código do produto em que a proposta será digitada.
    Caso seja Portabilidade, informe o código do produto do Refinanciamento da Portabilidade.`;

export const getFieldsData = (loanType: string, agreementId: number) => {
  const isFGTS = loanType === LoanType.LOAN_TYPES.FGTS_NEW;
  const fields = [
    {
      type: 'input',
      label: 'Nome',
      span: 24,
      field: 'name',
      allowForLoanTypes: Object.values(LoanType.LOAN_TYPES),
      placeholder: 'Digite o nome do cliente',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
      requiredMessage: 'Digite o nome do cliente',
    },
    {
      type: 'input',
      label: 'Nome da Mãe',
      span: 24,
      field: 'motherName',
      allowForLoanTypes: Object.values(LoanType.LOAN_TYPES),
      placeholder: 'Digite o nome da mãe',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
      requiredMessage: 'Digite o nome da mãe',
    },
    {
      type: 'input',
      label: 'CPF',
      span: isFGTS ? 6 : 5,
      normalize: masked.toPersonDocument,
      field: 'document',
      allowForLoanTypes: Object.values(LoanType.LOAN_TYPES),
      placeholder: 'Digite o número do CPF',
      requiredMessage: 'Digite o número do CPF',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Número do RG',
      span: 5,
      offset: 1,
      field: 'registrationNumber',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Digite o número do RG',
      requiredMessage: 'Digite o número do RG',
      visible: agreementId !== 75,
      get required() {
        return this.allowForLoanTypes.includes(loanType) && agreementId !== 75;
      },
    },
    {
      type: 'input',
      label: 'Orgão Emissor',
      span: 4,
      offset: 1,
      field: 'registerNumberEmitter',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Digite o orgão',
      requiredMessage: 'Digite o orgão',
      visible: agreementId !== 75,
      get required() {
        return this.allowForLoanTypes.includes(loanType) && agreementId !== 75;
      },
    },
    {
      type: 'select',
      label: 'UF',
      span: 2,
      offset: 1,
      field: 'registerNumberState',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Escolha a UF',
      requiredMessage: 'Escolha a UF',
      visible: agreementId !== 75,
      get required() {
        return this.allowForLoanTypes.includes(loanType) && agreementId !== 75;
      },
      data: UFs,
    },
    {
      type: 'date',
      label: 'Data de Emissão',
      span: 4,
      offset: 1,
      field: 'registerNumberDateOfIssue',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Informe a data de emissão',
      requiredMessage: 'Informe a data de emissão',
      visible: agreementId !== 75,
      get required() {
        return this.allowForLoanTypes.includes(loanType) && agreementId !== 75;
      },
    },
    {
      type: 'date',
      label: 'Data de Nascimento',
      offset: isFGTS ? 1 : 0,
      span: isFGTS ? 8 : 7,
      field: 'birthDate',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Informe a data de nascimento',
      requiredMessage: 'Informe a data de nascimento',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'select',
      label: 'Sexo',
      span: 5,
      offset: 1,
      field: 'gender',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Selecione um sexo',
      requiredMessage: 'Selecione um sexo',
      visible: agreementId !== 75,
      get required() {
        return this.allowForLoanTypes.includes(loanType) && agreementId !== 75;
      },
      data: Genders,
    },
    {
      type: 'select',
      label: 'Estado Cívil',
      span: 4,
      offset: 1,
      field: 'maritalStatus',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Selecione o estado cívil',
      requiredMessage: 'Selecione o estado cívil',
      visible: agreementId !== 75,
      get required() {
        return this.allowForLoanTypes.includes(loanType) && agreementId !== 75;
      },
      data: MaritalStatus,
    },
    {
      type: 'input',
      label: 'Nacionalidade',
      span: 5,
      offset: 1,
      field: 'nationality',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Digite a nacionalidade',
      requiredMessage: 'Digite a nacionalidade',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Cidade de Nascimento',
      span: 7,
      offset: undefined,
      field: 'cityOfBirth',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Digite a cidade de nascimento',
      requiredMessage: 'Digite a cidade de nascimento',
      visible: agreementId !== 75,
      get required() {
        return this.allowForLoanTypes.includes(loanType) && agreementId !== 75;
      },
    },
    {
      type: 'select',
      data: UFs,
      label: 'Estado de Nascimento',
      span: 8,
      offset: 1,
      field: 'stateOfBirth',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      placeholder: 'Selecione o estado de nascimento',
      requiredMessage: 'Selecione o estado de nascimento',
      visible: agreementId !== 75,
      get required() {
        return this.allowForLoanTypes.includes(loanType) && agreementId !== 75;
      },
    },
    {
      type: 'input',
      label: 'CEP',
      span: isFGTS ? 8 : 7,
      offset: 1,
      field: 'zipCode',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Digite o CEP',
      requiredMessage: 'Digite o CEP',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Rua',
      span: 6,
      offset: undefined,
      field: 'street',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Digite a rua',
      requiredMessage: 'Digite a rua',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'N°',
      normalize: toNumber,
      span: 2,
      offset: 1,
      field: 'addressNumber',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Digite o número',
      requiredMessage: 'Digite o número',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Bairro',
      span: 5,
      offset: 1,
      field: 'district',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Digite o bairro',
      requiredMessage: 'Digite o bairro',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Cidade',
      span: 5,
      offset: 1,
      field: 'city',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Digite a cidade',
      requiredMessage: 'Digite a cidade',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'select',
      label: 'Estado',
      data: UFs,
      span: 2,
      offset: 1,
      field: 'state',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Selecione o estado',
      requiredMessage: 'Selecione o estado',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'select',
      label: 'Banco',
      span: 8,
      field: 'bankNumber',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Selecione um banco',
      requiredMessage: 'Selecione um banco',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Conta',
      normalize: toNumber,
      span: 5,
      offset: 1,
      field: 'checkingAccount',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      placeholder: 'Digite o número da conta',
      requiredMessage: 'Digite o número da conta',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Dígito',
      normalize: toNumber,
      span: 2,
      offset: 1,
      maxLength: 1,
      field: 'checkingAccountDigit',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      requiredMessage: 'Digito da conta',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Agência',
      normalize: toNumber,
      maxLength: 4,
      len: 4,
      span: 3,
      offset: 1,
      field: 'agency',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      requiredMessage: 'Digite o número da agência',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Dígito',
      normalize: toNumber,
      maxLength: 1,
      span: 2,
      offset: 1,
      field: 'agencyDigit',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES)),
      requiredMessage: 'Dígito da agência',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
    {
      type: 'input',
      label: 'Código do Produto na Consignatária',
      span: 12,
      field: 'factorCode',
      allowForLoanTypes: Object.values(omit(LoanType.LOAN_TYPES, 'FGTS_NEW')),
      fieldProps: {
        suffix: (
          <Tooltip placement="top" title={codProductText}>
            <Icon type="info-circle" theme="twoTone" />
          </Tooltip>
        ),
      },
      requiredMessage: 'Dígito da agência',
      get required() {
        return this.allowForLoanTypes.includes(loanType);
      },
    },
  ];

  return fields.filter((f) => f.allowForLoanTypes.includes(loanType));
};

function Fields(props: FieldsProps) {
  const { loanType, agreementId, disabledFields, fieldsData, getFieldDecorator } = props;

  if (!loanType) return null;
  const fields = getFieldsData(loanType, agreementId);
  return fields.map((field) => (
    <Field
      {...field}
      key={field.field}
      onChange={props.onChange}
      getFieldDecorator={getFieldDecorator}
      data={fieldsData[field.field] || field.data}
      disabled={disabledFields[field.field]}
    />
  ));
}

export default Fields;
