/* eslint-disable */

import styled from 'styled-components';
import Card from '~/components/Card';

export const TableCard = styled(Card)`
  margin-top: 16px;
`;


export default common => {
  const { unit } = common;
  const fontFamily = "Rubik, sans-serif";

  return {
    container: {
      width: "100%"
    },
    main: {}
  };
};
