const CIVIL_STATE = [
  {
    name: 'Solteiro',
    value: 'SINGLE',
  },
  {
    name: 'Casado',
    value: 'MARRIED',
  },
  {
    name: 'Separado',
    value: 'SEPARATED',
  },
  {
    name: 'Divorciado',
    value: 'DIVORCED',
  },
  {
    name: 'Viúvo',
    value: 'WIDOWER',
  },
];

export default CIVIL_STATE;
