export default {
  main: '#722ED1',
  50: '#FFCCFF',
  100: '#FFB6FF',
  200: '#EF9FFF',
  300: '#D789FF',
  400: '#BF74FF',
  500: '#A75EFF',
  600: '#8F49EE',
  700: '#7833D6',
  800: '#601CC0',
  900: '#4700A9',
};
