import takeRequest from '~/store/takeRequest';
import { put, takeEvery } from 'redux-saga/effects';
import * as OpportunitiesActions from './actions';
import * as PeopleActions from '../people/actions';

export function* reloadContracts({ payload }: any) {
  yield put(PeopleActions.getContractsOfPerson.start({ id: payload?.personId }));
}

export function* opportunitiesSaga() {
  yield takeRequest(OpportunitiesActions.getOpportunities);
  yield takeRequest(OpportunitiesActions.simulateOpportunity);
  yield takeRequest(OpportunitiesActions.prepareContract);
  yield takeRequest(OpportunitiesActions.suggestedProposals);

  yield takeEvery(OpportunitiesActions.prepareContract.success.toString(), reloadContracts);
}
