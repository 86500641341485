import { TicketStatusColors, TicketGroupStatusColors } from '~/typings/enums/Ticket/TicketStatus';

export const COMPLETE_GROUP_STATUS_ID = 3;

export const getTicketStatusColor = (statusList, statusId) => {
  const status = statusList.find((status) => status.id === statusId);
  const statusColor = TicketStatusColors[status?.id];
  const groupStatusColors = TicketGroupStatusColors[status?.groupStatusId];
  return statusColor || groupStatusColors || null;
};

export const isTicketStatusComplete = (statusList, statusId) => {
  const status = statusList.find((status) => status.id === statusId);
  return status?.groupStatusId === COMPLETE_GROUP_STATUS_ID;
};

export const getJourneyId = (journeyLabel, journeys) =>
  journeys?.find((journey) => journey.name === journeyLabel)?.id
    ? [journeys?.find((journey) => journey.name === journeyLabel).id]
    : [];

export const checkDateDifferenceInDays = (date) =>
  (new Date().getTime() - new Date(date).getTime()) / (1000 * 60 * 60 * 24);

export const FGTSInitialJourneyStep = (
  person,
  filteredJourneys,
  qiTechOpportunity,
  selectedContracts,
  operationStepId,
) => {
  if (person?.isOpportunity) {
    return getJourneyId('Simulação', filteredJourneys);
  }

  if (qiTechOpportunity === 'OK') {
    return getJourneyId('Integração', filteredJourneys);
  }

  if (qiTechOpportunity === 7) {
    return getJourneyId('Integração', filteredJourneys);
  }

  if (!selectedContracts.length) return [];

  return [operationStepId];
};

export const INSSInitialJourneyStep = (
  person,
  filteredJourneys,
  selectedContracts,
  operationStepId,
) => {
  if (person?.isOpportunity) {
    return getJourneyId('Simulação', filteredJourneys);
  }

  if (checkDateDifferenceInDays(person.portabilityUpdateDate) > 90) {
    return getJourneyId('Integração', filteredJourneys);
  }

  if (!selectedContracts.length) return [];

  return [operationStepId];
};
