// @flow

import React from 'react';
import { connect } from 'react-redux';
import Account from './Account';
import solveAccount, {
  SOLVE_BANK_ACCOUNT_SUCCESS,
} from '~/store/solveBankAccount/action';
import updatePendencies from '~/screens/ContractDetails/Panels/Pendencies/components/actions/updatePendencies';
import { compose } from 'recompose';

type DocumentsContainerProps = {};

const AccountContainer = (props: DocumentsContainerProps) => {
  const onPressSend = fields => {
    props.dispatch(solveAccount({ ...fields, id: props.id }));
  };

  return <Account {...props} onEnter={onPressSend} />;
};

export default compose(
  connect(() => ({})),
  updatePendencies('solveBankAccount', SOLVE_BANK_ACCOUNT_SUCCESS),
)(AccountContainer);
