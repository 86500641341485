import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { Button, Input, Select, SelectDate, Typography } from '~/ui/components';

import _ from 'lodash';
import moment from 'moment';
import validateCPF from '~/common/Validations/validateCPF';
import { WarrantyTypeNames, WarrantyType } from '~/typings/enums/Clix';
import { FilterContainer, FilterForm } from './Filters.styled';

export type FiltersProps = {
  onSubmit?: (values: any) => void;
  loading?: boolean;
};

export function Filters({ onSubmit, loading }: FiltersProps) {
  const form = useRef<WrappedFormUtils>();
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const [warranty, setWarranty] = useState(params?.warranty || null);
  const [chosenFilter, setChosenFilter] = useState(null);

  const warrantyOptions = useMemo(
    () =>
      Object.keys(WarrantyTypeNames).map((key) => ({ value: key, label: WarrantyTypeNames[key] })),
    [WarrantyTypeNames],
  );

  const handleWarrantyChange = (value: string[]) => {
    setWarranty(value?.[0]);
  };

  const handleInputChange = useCallback((value, id) => {
    if (value) {
      setChosenFilter(id);
    } else {
      setChosenFilter(null);
    }
  }, []);

  const filters = useMemo(() => {
    let inputsList = [
      {
        id: 'document',
        label: 'CPF:',
        initialValue: params.document,
        input: <Input placeholder="Insira o CPF" allowClear />,
        options: {
          rules: [{ validator: validateCPF, message: 'CPF inválido!' }],
        },
      },
      {
        id: 'personId',
        label: 'Person ID:',
        initialValue: params.personId,
        input: <Input placeholder="Insira o person ID" allowClear />,
      },
      {
        id: 'signatureDate',
        label: 'Data de assinatura:',
        initialValue: params.signatureDate,
        input: <SelectDate maxDate={new Date()} />,
      },
      {
        id: 'warranty',
        label: 'Tipo de contrato:',
        initialValue: params.warranty ? [params.warranty] : null,
        input: <Select options={warrantyOptions} onChange={handleWarrantyChange} />,
      },
    ];

    if (warranty === WarrantyType.CREDIT) {
      inputsList = inputsList.concat([
        {
          id: 'reference',
          label: 'Reference:',
          initialValue: params.reference,
          input: (
            <Input
              placeholder="Insira o reference"
              allowClear
              onChange={(value) => handleInputChange(value, 'reference')}
              disabled={chosenFilter && chosenFilter !== 'reference'}
            />
          ),
        },
        {
          id: 'transactionId',
          label: 'N. da transação:',
          initialValue: params.transactionId,
          input: (
            <Input
              placeholder="Insira o N. da transação"
              allowClear
              onChange={(value) => handleInputChange(value, 'transactionId')}
              disabled={chosenFilter && chosenFilter !== 'transactionId'}
            />
          ),
        },
        {
          id: 'nsu',
          label: 'NSU:',
          initialValue: params.nsu,
          input: (
            <Input
              placeholder="Insira o NSU"
              allowClear
              onChange={(value) => handleInputChange(value, 'nsu')}
              disabled={chosenFilter && chosenFilter !== 'nsu'}
            />
          ),
        },
      ]);
    }
    return inputsList;
  }, [warranty, chosenFilter]);

  const handleOnSubmit = (values) => {
    onSubmit?.(_.omitBy(values, (el) => _.isEmpty(el) && !moment.isDate(el)));
  };

  useEffect(() => {
    setChosenFilter(null);
    form.current.setFieldsValue({ reference: undefined, transactionId: undefined, nsu: undefined });
  }, [warranty]);

  return (
    <FilterContainer>
      <Typography type="paragraphLarge" weight={500}>
        Filtros
      </Typography>

      <FilterForm inputs={filters} onSubmit={handleOnSubmit} ref={form} loading={loading ? 1 : 0}>
        <Button icon="Search" size="sm" rounded className="submit-button" loading={loading}>
          Filtrar
        </Button>
      </FilterForm>
    </FilterContainer>
  );
}
