import React, { Children, useCallback, useState } from 'react';

import { Button } from '~/components';
import type { ButtonProps } from '~/components/Button/Button';

import { Container, Header, Title, Actions, Icon, Child } from './styled';

type CardProps = {
  icon?: string;
  title?: string;
  actions?: Array<ButtonProps & { render: (...args) => any }>;
  children: any;
  full?: boolean;
  style?: any;
  hiddenOverflow?: boolean;
  controlShowChild?: boolean;
  initShowChild?: boolean;
  shouldHideIfIsEmpty?: boolean;
  disabled?: boolean;
};

function Card({
  style,
  full = true,
  hiddenOverflow,
  icon,
  title,
  actions,
  controlShowChild,
  children,
  initShowChild = true,
  shouldHideIfIsEmpty = false,
  disabled,
}: CardProps) {
  const [isShowChild, setIsShowChild] = useState(initShowChild);

  const onToggleIsShow = useCallback(() => {
    setIsShowChild((value) => !value);
  }, []);

  if (shouldHideIfIsEmpty) {
    const childrenList = Children.toArray(children);
    const shouldHide =
      childrenList.length === 0 ||
      childrenList.every((child) => {
        const value = child.valueOf();
        if (typeof value === 'object') {
          return (value as any).props?.style?.display === 'none';
        }
        return true;
      });
    if (shouldHide) {
      return null;
    }
  }

  return (
    <Container style={style} full={full} hiddenOverflow={hiddenOverflow} disabled={disabled}>
      {icon || title || (actions && actions.length) ? (
        <Header full={full}>
          <Title icon={icon}>
            {icon && <Icon type={icon} />}
            <span>{title}</span>
          </Title>
          <Actions>
            {actions &&
              actions
                .filter((o) => !!o)
                .map((action) =>
                  action.render ? action.render() : <Button type="primary" {...action} />,
                )}
          </Actions>
          {controlShowChild && (
            <Icon
              type={isShowChild ? 'up-circle' : 'down-circle'}
              theme="filled"
              onClick={onToggleIsShow}
            />
          )}
        </Header>
      ) : null}
      <Child isShowChild={isShowChild}>{children}</Child>
    </Container>
  );
}

export default Card;
