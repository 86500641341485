import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { updatePersonInfo } from '~/store/people/actions';
import ExtinctionDateBenefit from './ExtiinctionDateBenefit';

type ExtinctionDateBenefitContainerProps = {
  lifeLong: Boolean,
  extinctionDate: string,
  personName: string,
  personId: number,
  hideLabel?: boolean;
};

type PropsSave = {
  lifeLong: Boolean,
  extinctionDate: string,
};

export default function ExtinctionDateBenefitContainer(props: ExtinctionDateBenefitContainerProps) {
  const dispatch = useDispatch();

  const onSave = useCallback((data: PropsSave) => {
    const { personId } = props;
    dispatch(updatePersonInfo.start({ id: personId, ...data }));
  }, []);

  return <ExtinctionDateBenefit {...props} onSave={onSave} />;
}
