import React, { useCallback } from 'react';
import moment from 'moment';

import { useModal } from '~/hooks/useModal';
import toMoney from '~/common/masked/toMoney';
import LoanType from '~/typings/enums/LoanType';
import ContractStatus, {
  CONTRACT_STATUS_COLOR,
  INSURANCE_STATUS_COLOR,
} from '~/typings/enums/ContractStatus';
import { ContractHistory } from '~/screens/Person/components';
import { Table, TableColumns, Tag, Tooltip } from '~/ui/components';
import { useTheme } from 'styled-components';
import { useDrawer } from '~/hooks/useDrawer';
import { ActionButton } from '.';
import { CellWrapper, FlexWrapper, StyledButton, Link } from './Tabs.styled';
import { InsuranceDetails } from './InsuranceDetails/InsuranceDetails';

type ContractRow = {
  contractDate: string;
  contractId: string;
  contractType: string;
  contractValue: string;
  createdLocation: string;
  financialInstitution: string;
  installmentsValue: string;
  loanType: string;
  operationStep: string;
  contractSituation: string;
  workCode: string;
  workName: string;
  insurance?: boolean;
};

interface ContractsProps {
  contracts?: any;
  loading?: boolean;
}

export function Contracts({ contracts, loading }: ContractsProps) {
  const { openModal } = useModal();
  const { openDrawer } = useDrawer();
  const theme = useTheme();

  const normalizedContracts = contracts?.map((contract) => ({
    ...contract,
    children:
      contract.insurances?.map((insurance) => ({
        insurance: true,
        contractId: insurance.id,
        contractDate: insurance.initialDate,
        contractSituation: insurance.status,
        loanType: insurance.type,
        contractValue: insurance.value,
      })) || [],
  }));

  const handleHistoryModal = useCallback(
    (id) => () => {
      openModal(<ContractHistory id={id} />, {
        title: 'Histórico de contrato',
        closable: true,
        width: 550,
      });
    },
    [openModal],
  );

  const renderLabelWithInfo = useCallback(
    (label, info) => (
      <FlexWrapper>
        <CellWrapper>{label ?? '-'}</CellWrapper>
        {info && <Tooltip content={info} placement="bottomLeft" maxWidth="350px" />}
      </FlexWrapper>
    ),
    [],
  );

  const handleContractDetails = useCallback((insurance) => {
    openDrawer('insuranceDetails', <InsuranceDetails id={insurance.contractId} />, {
      title: 'Seguro Renda Protegida',
      closable: true,
      width: 550,
    });
  }, []);

  const renderLink = useCallback(
    (info) =>
      info.row.original?.insurance ? (
        <StyledButton
          variant="text"
          title={info.getValue()}
          onClick={() => handleContractDetails(info.row.original)}
        />
      ) : (
        <Link to={`/backoffice/contracts/${info.getValue()}`}>{info.getValue()}</Link>
      ),
    [],
  );

  const renderHistory = useCallback(
    (contractId, insurance = false) => (
      <ActionButton
        onClick={handleHistoryModal(contractId)}
        iconOnly
        icon="FileSearch"
        variant="text"
        disabled={insurance}
      />
    ),
    [],
  );

  const columns: TableColumns<ContractRow> = [
    {
      accessorKey: 'contractId',
      header: () => 'COD',
      cell: (info) => renderLink(info),
    },
    {
      accessorKey: 'contractDate',
      header: () => 'DATA',
      sortingFn: (rowA: any, rowB: any, columnId: any): number => {
        const dateA = moment(rowA.getValue(columnId));
        const dateB = moment(rowB.getValue(columnId));
        return dateA.isBefore(dateB) ? -1 : 1;
      },
      cell: ({ row }) => moment(row.original.contractDate).format('DD/MM/YY'),
    },
    {
      accessorKey: 'installmentsValue',
      header: () => 'PARCELA',
      accessorFn: ({ installmentsValue }) => toMoney(installmentsValue),
    },
    {
      accessorKey: 'contractSituation',
      header: () => 'SITUAÇÃO',
      cell: ({ row }) => {
        const { contractSituation, insurance } = row.original;
        const pallete =
          (insurance
            ? INSURANCE_STATUS_COLOR[contractSituation]
            : CONTRACT_STATUS_COLOR[contractSituation]) || 'neutral';
        const bgColor = theme.colors?.[pallete]?.secondary;
        const textColor = theme.colors?.[pallete]?.primaryAlt;
        return (
          <Tag small rounded bgColor={bgColor} textColor={textColor}>
            {insurance ? contractSituation : ContractStatus({ value: contractSituation })}
          </Tag>
        );
      },
    },
    {
      accessorKey: 'loanType',
      header: () => 'TIPO',
      accessorFn: ({ loanType }) => LoanType({ value: loanType }),
    },
    {
      accessorKey: 'contractValue',
      header: () => 'VALOR',
      accessorFn: (value) => toMoney(value.contractValue),
      sortingFn: (rowA: any, rowB: any, columnId: any): number => {
        const valueA = Number(rowA.getValue(columnId).replace(/\D/g, ''));
        const valueB = Number(rowB.getValue(columnId).replace(/\D/g, ''));
        return valueA < valueB ? -1 : 1;
      },
    },
    {
      accessorKey: 'benefitCode',
      header: () => 'BENEFÍCIO',
      minSize: 80,
      cell: (info) => renderLabelWithInfo(info.row.original.workCode, info.row.original.workName),
    },
    {
      id: 'history',
      header: () => 'HISTÓRICO',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => renderHistory(info.row.original.contractId, info.row.original?.insurance),
    },
  ];

  return (
    <Table
      noItemsMessage="Nenhum contrato encontrado"
      responsiveCols={[
        'contractId',
        'contractDate',
        'installmentsValue',
        'contractSituation',
        'history',
      ]}
      loading={loading}
      data={normalizedContracts}
      columns={columns}
      initialSorting={[{ id: 'contractDate', desc: true }]}
    />
  );
}
