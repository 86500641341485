import styled, { css } from 'styled-components';
import { Button as ButtonComponent, Icon, List as ListComponent, Switch, Tag, Tooltip } from 'antd';

import { Card } from '~/components';

export const Container = styled.div``;

export const Content = styled(Card).attrs(() => ({
  style: {
    padding: '0 8px',
  },
}))``;

export const Title = styled.p`
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 15px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  border-top: 2px solid #e5e5e5;

  padding: 19px 0 30px;
  margin: 41px 26px 0;
`;

export const Button = styled(ButtonComponent).attrs(() => ({
  type: 'primary',
}))`
  margin: 0 10px;
` as typeof ButtonComponent;

export const OptionsButton = styled(ButtonComponent).attrs(() => ({
  ghost: true,
}))`
  border-color: transparent;
  color: ${({ theme }) => theme.accentColor};
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border-color: transparent;
    color: ${({ theme }) => theme.accentColor};
  }

  &:hover {
    border-color: transparent !important;
    color: ${({ theme }) => theme.accentColor} !important;
    background-color: ${({ theme }) => theme.grayExtraLight} !important;
  },
`;

export const EllipsisIcon = styled(Icon).attrs(() => ({
  type: 'ellipsis',
}))`
  height: 22px;

  svg {
    font-size: 20px;
  }
`;

export const OptionLabel = styled.span`
  font-size: 14px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 8px 8px;
`;

export const Info = styled.span`
  span {
    font-weight: bold;
    margin-left: 15px;
  }
`;

export const TimerIcon = styled(Icon).attrs({ type: 'clock-circle' })`
  font-size: 18px;
  font-weight: 600;
`;

export const TimerValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.primaryColor};
  margin-left: 15px;
`;

export const ModalTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const ModalContent = styled.div`
  margin: 40px 0 30px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-left: 40px;
  }
`;

export const List = styled(ListComponent)``;

export const ListItem = styled(ListComponent.Item)`
  height: 35px;
  background-color: ${({ odd }) => (!odd ? '#' : '#FFF')};

  ${({ odd }) =>
    !odd &&
    css`
      background-color: ${({ selected }) => (selected ? '#EB93C5' : '#ECECEC')};
    `}

  ${({ odd }) =>
    odd &&
    css`
      background-color: ${({ selected }) => (selected ? '#EB93C5' : '#FFF')};
    `}
`;

export const ListItemContent = styled.a`
  padding: 0 8px !important;
  border-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #000000;
`;

export const TimerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Timer = styled.div`
  display: flex;
  align-items: center;
`;

export const TimerText = styled.span<{ disabled: boolean }>`
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const ConfigContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
`;

export const ConfigLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.accentColor};
  margin-bottom: 0;
`;
