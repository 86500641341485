import React from 'react';

export default function useChildrenWithProps(
  children: React.ReactElement | React.ReactElement[],
  customProps: any,
) {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, customProps);
    }

    return child;
  });
}
