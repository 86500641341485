export enum DeactivationReasons {
  DELETE_DATA = 'Desejo excluir meus dados',
  CHANGE_AGREEMENT = 'Desejo trocar meu convênio',
  NO_OPPORTUNITY = 'Não vi nenhuma oportunidade',
  CHANGE_DATA = 'Desejo alterar meus dados',
  NO_ACCESS = 'Problemas ao acessar o app',
  TOO_MANY_MESSAGES = 'Recebo muitas mensagens',
  CHANGE_BENEFIT = 'Desejo trocar meu benefício',
  OTHER_REASON = 'Outro motivo',
}
