import React, { useMemo } from 'react';
import moment from 'moment';

import ExtinctionDateBenefit from '~/screens/components/ExtinctionDateBenefit';
import { Person } from '~/typings/entities/person';
import { CaretDown } from '~/ui/assets/icons';
import { InfoGrid, InfoGridItem, Popover, Tooltip } from '~/ui/components';
import masked from '~/common/masked';
import Enums from '~/typings/enums';

import useUserRoles from '~/hooks/useUserRoles';
import { PopoverWrapper } from './EnrollmentInfo.styled';

const { RegistrationStatus } = Enums;

interface EnrollmentInfoProps {
  personDetails: Person;
  loading?: boolean;
}

export function EnrollmentInfo({ personDetails, loading }: EnrollmentInfoProps) {
  const { hasRole } = useUserRoles();

  const showAgreementPassword = hasRole('AGREEMENT_PASSWORD');

  const codeWorkCode = ({ workCode, workName }) => {
    if (personDetails?.workCode) {
      return (
        <>
          {workCode} <Tooltip content={workName} placement="bottomRight" />
        </>
      );
    }

    return 'Indisponível';
  };

  const agreementPasswordInfoContent = () => (
    <>
      <p>
        Agora é possível visualizar se o cliente possui uma senha cadastrada e, caso tenha, a data e
        hora da última atualização.
      </p>
      <p>
        Se a senha não estiver cadastrada ou estiver desatualizada, abra uma pendência para que o
        cliente possa cadastrar ou atualizá-la.
      </p>
    </>
  );

  const renderAgreementPasswordPopover = (date) => (
    <PopoverWrapper>
      Sim
      {!!date && (
        <Popover
          noHeader
          placement="bottom"
          trigger="click"
          width="auto"
          content={<>{moment(date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY [às] HH[h]mm')}</>}
        >
          <CaretDown className="action-icon" width={12} height={12} />
        </Popover>
      )}
    </PopoverWrapper>
  );

  const extinctionDateBenefit = ({
    id,
    name,
    lifeLong,
    extinctionDate,
    agreementName,
    workCode,
  }: Person) => {
    if (agreementName !== 'INSS' || !['21', '93'].includes(workCode)) return '-';

    return (
      <ExtinctionDateBenefit
        personId={id}
        personName={name}
        lifeLong={lifeLong}
        extinctionDate={extinctionDate}
        hideLabel
      />
    );
  };

  const agreementPasswordInfo = () => {
    if (!personDetails?.acceptConsentDate) {
      return 'Cliente não deu permissão';
    }

    return personDetails?.agreementPassword || 'Indisponível'
  }

  const list = useMemo(() => {
    const agreementPassword = showAgreementPassword
      ? {
          name: 'Senha de convênio',
          copyable: true,
          value: agreementPasswordInfo(),
        }
      : {
          name: ' Senha cadastrada',
          info: {
            title: 'Senha cadastrada',
            content: agreementPasswordInfoContent(),
            width: 330,
          },
          value:
            !!personDetails?.agreementPassword || personDetails?.hasAgreementPassword
              ? renderAgreementPasswordPopover(personDetails?.agreementPasswordLastUpdate)
              : 'Não',
        };

    const options: InfoGridItem[] = [
      {
        name: 'Margem calculada',
        copyable: true,
        value: masked.toMoney(personDetails?.computedMarginLoan),
      },
      {
        name: 'Matrícula',
        copyable: true,
        value: personDetails?.enrollment || '-',
      },
      {
        name: 'Convênio',
        copyable: true,
        value: personDetails?.agreementName,
      },
      {
        name: 'Código do benefício',
        copyable: !!personDetails?.workCode,
        copyText: personDetails?.workCode,
        value: codeWorkCode(personDetails),
      },
      {
        name: 'Margem de empréstimo',
        copyable: true,
        value: masked.toMoney(personDetails?.marginLoan),
      },
      {
        name: 'Status',
        copyable: true,
        value: RegistrationStatus(personDetails?.registrationStatus),
      },
      agreementPassword,
      {
        name: 'Validade do benefício',
        value: extinctionDateBenefit(personDetails),
      },
      {
        name: 'Margem de cartão',
        copyable: true,
        value: masked.toMoney(personDetails?.marginCard),
      },
    ];

    return options;
  }, [personDetails]);

  return <InfoGrid loading={loading} list={list} />;
}
