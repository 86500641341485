import React from 'react';
import AsyncActionControl from 'react-redux-await-control/dist/AsyncActionControl';
import AsyncActionWrapper from './AsyncActionWrapper';

const createWrapperComponent = (
  action: AsyncActionControl,
  dataFieldName: string,
  actionKey?: string,
) => function ({ children }: { children: React.ReactElement[] }) {
  return (
    <AsyncActionWrapper
      action={action}
      actionKey={actionKey}
      dataFieldName={dataFieldName}
    >
      {children}
    </AsyncActionWrapper>
  );
};

export default createWrapperComponent;
