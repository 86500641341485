/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { LIST_ROLES_REQUEST, success, failure } from './action';

const getUserRoles = (action$, state$, { AjaxRequest }) => action$.ofType(LIST_ROLES_REQUEST).mergeMap((payload) => AjaxRequest.open('get', '/admin/usercustom/roles')('tudo')
  .flatMap(({ response }) => Observable.of(success(response.resource)))
  .catch((err) => Observable.of(failure(err))));

export default combineEpics(getUserRoles);
