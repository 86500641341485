import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';

export const sendPushNotification = createAsyncAction('SEND_PUSH_NOTIFICATION', {
  initialValue: {},
  saveResult: true,
  context: {
    request: (payload) =>
      telli.post(`/admin/personcustom/people/${payload.personId}/sendpushnotification`, payload),
    onRequestError: () => message.error('Falha ao Fazer a Notificão.'),
    onRequestSuccess: () => message.success('Notificação Enviada com Sucesso.'),
  },
});
