import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Col, DatePicker, Input } from 'antd';
import moment from 'moment';
import { isEmpty } from 'underscore';
import masked from '~/common/masked';
import { Card as Segment, Spinner } from '~/components';
import { SearchSelect } from '~/common';
import 'moment/locale/pt-br';
import locale from 'antd/lib/date-picker/locale/pt_BR';

import { Row, FirstRow, Button, Title, Form, Select } from './OfferPersonRegistry.styled';

const { RangePicker } = DatePicker;
const { Item: FormItem } = Form;
const { Option } = Select;
const { toMoneyForm, toNumber } = masked;

type Props = {
  agreements: Array<any>,
  fetchAllFinancialInstitutions: () => void,
  fetchFinancialInstitutionsByAgreementId: () => void,
  form: {
    validateFields: () => any,
  },
  factorTypes: Array<any>,
  financialInstitutions: Array<any>,
  getFieldDecorator: any,
  registering: Boolean,
  isResponseBackFromServer: Boolean,
  isResponseBackFromServerWithError: Boolean,
  onSubmit: () => void,
};

class OfferPersonRegistry extends Component<Props> {
  constructor(props) {
    super(props);

    this.financialInstitutionRef = React.createRef();
    this.state = {
      personId: this.props.match.params.id,
      title: '',
      description: '',
      contractValue: '',
      installmentValue: '',
      numberOfInstallments: '',
      nominalRate: '',
      agreementId: '',
      financialInstitutionId: undefined,
      startDate: '',
      endDate: '',
    };
  }

  stripMoneyValue = (value) =>
    // Divide por 100 devido a retirada da virgula
    value.replace(/[.,\s]/g, '').replace(/^0+/, '') / 100;

  stripNominalRateValue = (value) =>
    // Alem do tratamento da virgula, o backend precisa do valor dividido por 100
    value.replace(/[.,\s]/g, '').replace(/^0+/, '') / 100 / 100;

  removeleadingZeros = (value) => value.replace(/^0+/, '');

  onTitleChange = (title) => {
    this.setState({ title: title.target.value });
  };

  onDescriptionChange = (description) => {
    this.setState({ description: description.target.value });
  };

  onContractValueChange = (contractValue) => {
    this.setState({
      contractValue: this.stripMoneyValue(contractValue.target.value),
    });
  };

  onInstallmentValueChange = (installmentValue) => {
    this.setState({
      installmentValue: this.stripMoneyValue(installmentValue.target.value),
    });
  };

  onNominalRateValueChange = (nominalRate) => {
    this.setState({
      nominalRate: this.stripNominalRateValue(nominalRate.target.value),
    });
  };

  onNumberOfInstallmentsValueChange = (numberOfInstallments) => {
    if (!this.state.numberOfInstallmentsChanged) {
      this.setState({ numberOfInstallmentsChanged: true });
    }
    this.setState({
      numberOfInstallments: this.removeleadingZeros(numberOfInstallments.target.value),
    });
  };

  onPeriodChange = (period) => {
    const startDate = period[0];
    const endDate = period[1];
    this.setState({ startDate, endDate });
  };

  onSingleFactorChange = (singleFactor) => {
    this.setState({ singleFactor: singleFactor.target.checked });
  };

  onLoanFactorTypeChange = (loanFactorType) => {
    this.setState({ loanFactorType });
  };

  onAgreementChange = (agreementId) => {
    this.setState({ agreementId }, () => {
      this.props.form.setFieldsValue({
        financialInstitution: undefined,
      });

      if (agreementId) {
        this.props.fetchFinancialInstitutionsByAgreementId({ agreementId });
      } else {
        this.props.fetchAllFinancialInstitutions();
      }
    });
  };

  onFinancialInstitutionChange = (financialInstitutionId) => {
    this.setState({ financialInstitutionId });
  };

  onClick = ({ match }) => {
    this.props.onSubmit(this.state);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        this.props.onSubmit(this.state);
      }
    });
  };

  renderRedirectToListing() {
    const redirectState = this.props.isResponseBackFromServerWithError
      ? 'isResponseBackFromServerWithError'
      : 'isResponseBackFromServerWithSuccess';

    return this.props.isResponseBackFromServer ? (
      <Redirect
        to={{
          pathname: '../loanfactorgroups',
          state: redirectState,
        }}
      />
    ) : null;
  }

  reset = () => {
    this.props.form.resetFields();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const isNominalRateRequired =
      this.state.contractValue && this.state.installmentValue && !this.state.nominalRate;

    return (
      <Segment style={{ padding: 16 }}>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Spinner spinning={this.props.registering} />
          <FirstRow>
            <Col xs={{ span: 24 }} lg={{ span: 14 }}>
              <Title>Título:</Title>
              <FormItem>
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite um título para a oferta',
                    },
                  ],
                })(<Input placeholder="" onChange={this.onTitleChange} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 9, offset: 1 }}>
              <Title>Período:</Title>
              <FormItem>
                {getFieldDecorator('period', {
                  rules: [{ required: true, message: 'Selecione o período do grupo' }],
                })(
                  <RangePicker
                    defaultPickerValue={[moment().utc(), moment().utc()]}
                    locale={locale}
                    onChange={this.onPeriodChange}
                    format="DD/MM/YYYY"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  />,
                )}
              </FormItem>
            </Col>
          </FirstRow>
          <Row>
            <Col xs={{ span: 24 }}>
              <Title>Descrição:</Title>
              <FormItem>
                {getFieldDecorator('description', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite uma descrição para a oferta',
                    },
                  ],
                })(<Input placeholder="" onChange={this.onDescriptionChange} />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 7 }}>
              <Title>Valor do Contrato:</Title>
              <FormItem>
                {getFieldDecorator('contractValue', {
                  normalize: toMoneyForm,
                })(<Input addonBefore="R$" type="text" onChange={this.onContractValueChange} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8, offset: 1 }}>
              <Title>Valor da Parcela:</Title>
              <FormItem>
                {getFieldDecorator('installmentValue', {
                  normalize: toMoneyForm,
                })(<Input addonBefore="R$" type="text" onChange={this.onInstallmentValueChange} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
              <Title>Número de Parcelas:</Title>
              <FormItem>
                {getFieldDecorator('numberOfInstallments', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite o número de parcelas',
                    },
                    {
                      pattern: /^([1-9]([0-9])*)$/g,
                      message: 'Digite um valor válido, sem iniciar com 0',
                    },
                  ],
                  normalize: toNumber,
                })(<Input type="text" onChange={this.onNumberOfInstallmentsValueChange} />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 7 }}>
              <Title>Taxa Nominal:</Title>
              <FormItem
                validateStatus={isNominalRateRequired ? 'error' : ''}
                help={isNominalRateRequired ? 'Digite uma taxa nominal' : ''}
              >
                {getFieldDecorator('nominalRate', {
                  normalize: toMoneyForm,
                })(<Input addonAfter="%" type="text" onChange={this.onNominalRateValueChange} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8, offset: 1 }}>
              <Title>Convênio:</Title>
              <FormItem>
                {getFieldDecorator('agreement', {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione um convênio',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Selecione"
                    onChange={this.onAgreementChange}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => SearchSelect(input, option.props.children)}
                  >
                    {this.props.agreements?.map((item) => (
                      <Option key={item.id}>{item.name}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
              <Title>Instituição Financeira:</Title>
              <FormItem>
                {getFieldDecorator('financialInstitution', {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione uma instituição financeira',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Selecione"
                    onChange={this.onFinancialInstitutionChange}
                    disabled={!this.state.agreementId}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => SearchSelect(input, option.props.children)}
                  >
                    {this.props.financialInstitutions.map((item) => (
                      <Option key={item.id}>{item.consignee.name}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginTop: '18px' }}>
            <Col xs={{ span: 3 }}>
              <Button
                block
                disabled={this.props.registering}
                className="submit-button"
                type="primary"
                onClick={this.handleSubmit}
              >
                {this.props.registering ? 'Salvando...' : 'Cadastrar'}
              </Button>
            </Col>
          </Row>
        </Form>
        {this.props.isResponseBackFromServer ? this.renderRedirectToListing() : null}
      </Segment>
    );
  }
}

OfferPersonRegistry.defaultProps = {
  agreements: [],
  factorTypes: [],
  financialInstitutions: [],
};

const WrappedRegistry = Form.create()(OfferPersonRegistry);

export default WrappedRegistry;
