import { useEffect } from 'react';
import { AwaitControlHook } from 'react-redux-await-control/dist/types';

export default function useAsyncActionOpenFile(
  control: AwaitControlHook,
  actionId?: string | number,
) {
  const file = control.result(actionId);
  const downloadSuccess = control.isSuccessful(actionId);

  useEffect(() => {
    if (downloadSuccess && file) {
      window.open(file);
    }
    return control.clear;
  }, [file, downloadSuccess]);
}
