import styled from 'styled-components';
import { Popover as DefaultPopover } from 'antd';
import { Typography } from '~/ui/components';

interface ContentWrapperProps {
  width?: number;
  maxWidth?: number;
  small?: boolean;
}

export const Popover = styled(DefaultPopover).attrs(({ overlayClassName, small }) => ({
  overlayClassName: ['mt-custom-tooltip', overlayClassName, small ? 'small' : ''].join(' '),
}))``;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
  max-width: ${({ maxWidth }) => (typeof maxWidth === 'string' ? maxWidth : `${maxWidth}px`)};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'headingXSmall',
  weight: 600,
  color: theme.colors.white.main,
}))`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Content = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.white.main};
  font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};

  p:last-of-type {
    margin-bottom: 0;
  }

  .cell-wrapper {
    opacity: 1;
    color: ${({ theme }) => theme.colors.white.main};
  }
`;
