import styled, { css } from 'styled-components';
import { Button } from '~/ui/components';
import { Button as AntButton } from 'antd';

type AnimationProps = {
  animation?: boolean;
};

export const Container = styled.div<AnimationProps>`
  inset: 0;
  position: fixed;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: ${({ animation }) => animation || 'fadeIn'};
  animation-fill-mode: forwards;
  animation-duration: 0.4s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const ImagesList = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
  flex: 1;

  img {
    height: 100%;
    max-height: 346px;
  }
`;

type SelectedImageProps = {
  selected: boolean;
};

type HideProps = {
  hide?: boolean;
};

export const PreviewMask = styled.div<SelectedImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ selected }) => (selected ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  span {
    color: white;
    font-size: 12px;
  }
`;

export const ImagePreview = styled.div<SelectedImageProps & HideProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  position: relative;
  height: auto;
  border-radius: 12px;
  overflow: hidden;

  animation-name: ${({ hide }) => (hide ? 'hide' : 'show')};
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  @keyframes show {
    from {
      scale: 0;
    }
    to {
      scale: 1;
    }
  }

  img {
    border-radius: 8px;
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid #fff;
      border-radius: 12px;
      overflow: hidden;
      opacity: 0.6;
    `}

  &:hover {
    ${PreviewMask} {
      display: flex;
    }
  }
`;

export const PreviewImagesList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 120px;

  img {
    height: 80px;
  }
`;

export const ImagePreviewSkeleton = styled.div`
  width: 346px;
  height: 346px;
  border-radius: 8px;
  border: 1px dashed #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  span {
    text-align: center;
    color: white;
  }
`;

export const IconButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  iconOnly: true,
  customColor: theme.colors.white.main,
}))``;

export const CloseButton = styled(AntButton)`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 11000;
  border-bottom-left-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border: transparent;
  color: #fff;

  i {
    font-size: 12px;
    transform: translate(3px, -3px);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
  }

  &:focus {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
`;
