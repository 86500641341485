import styled, { css } from 'styled-components';
import { Typography } from '~/ui/components';
import { ColorsTypes } from '~/ui/theme/colors';

interface CheckedProps {
  checked?: boolean;
}

interface DisabledProps {
  disabled?: boolean;
}

interface RadioProps {
  radio?: boolean;
}

interface PalettedProps {
  colorPalette?: ColorsTypes;
}

interface SmallProps {
  small?: boolean;
}

export const Container = styled.div<DisabledProps>`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  input {
    display: none;
    width: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
`;

export const InputRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: left;
`;

export const FakeInputContainer = styled.div<PalettedProps & SmallProps>`
  width: ${({ small }) => (small ? '24px' : '32px')};
  height: ${({ small }) => (small ? '24px' : '32px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: ${({ colorPalette, theme }) => theme.colors[colorPalette]['50']};
  }

  &:active {
    background-color: ${({ colorPalette, theme }) => theme.colors[colorPalette]['100']};
  }
`;

export const FakeInput = styled.div<
  CheckedProps & PalettedProps & SmallProps & DisabledProps & RadioProps
>`
  ${({ theme, small, colorPalette, checked, disabled, radio }) => css`
    width: ${small ? '10px' : '18px'};
    height: ${small ? '10px' : '18px'};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${theme.colors[colorPalette].main};
    border-radius: ${radio ? '50%' : '2px'};
    transition: all 0.2s ease-out;
    background-color: ${checked ? theme.colors[colorPalette].main : 'transparent'}
      ${disabled &&
      css`
        background-color: ${checked ? theme.colors.gray['200'] : 'transparent'};
        border: 2px solid ${theme.colors.gray['200']};
      `};
  `};
`;

export const Label = styled(Typography).attrs(({ small }) => ({
  type: small ? 'footnote' : 'paragraphSmall',
  element: 'label',
}))<PalettedProps & SmallProps>`
  margin-left: 8px;

  a {
    color: ${({ theme, colorPalette }) => theme.colors[colorPalette].main};
    font-size: 13px;
    text-decoration: none;
    font-weight: 700;
  }
`;

export const ErrorMessage = styled.span`
  margin-left: 35px;
  margin-top: 2px;
`;
