import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import {
  TabList as TabListt,
  Tab as Tabb,
} from 'react-tabs';

export const FlexWrapper = styled(Flex)`
  align-items: flex-start;
  width: 100%;
  min-width: 720px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${({ theme }) => theme.unit * 2}px;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #c9c9c9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 40px;
  min-height: 300px;
  padding: ${({ theme }) => theme.unit * 2}px;
`;

export const TabList = styled(TabListt)`
  box-shadow: inset 0 -3px 0 rgba(150, 150, 150, 0.1);
  list-style: none;
  margin-bottom: 0;
  padding: 0;
`;

export const Tab = styled(Tabb)`
  border-bottom: 3px solid transparent;
  color: #949494;
  cursor: pointer;
  display: inline-block;
  fontsize: ${({ theme }) => theme.unit * 1.4}px;
  margin-right: 20px;
  padding: ${({ theme }) => theme.unit}px;

  ${({ active }) => {
    if (active) {
      return css`
        color: ${(props) => props.theme.primaryColor};
        border-bottom: 3px solid ${(props) => props.theme.primaryColor};
      `;
    }
  }}
`;

export const TimerValue = styled.span`
  font-size: 32px;
  font-weight: bold; 
  color:  ${(props) => props.theme.primaryColor};
`;
