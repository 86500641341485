import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import toMoney from '~/common/masked/toMoney';
import { themeToggleColor } from '~/common/utils/theme';
import {
  ContractValueList,
  OpportunityListItem,
  SuggestedProposalReturn,
  SuggestedProposals,
} from '~/typings/entities/Opportunity';
import { suggestedProposals } from '~/store/opportunities/actions';
import { getPersonDetail } from '~/store/people/actions';
import {
  Alert,
  Button,
  Checkbox,
  Flex,
  Shimmer,
  Tooltip,
  Typography,
  withStepperProps,
} from '~/ui/components';
import _ from 'lodash';
import { ChildrenDrawer, DownloadNodeModal } from '~/components';
import { useDrawer } from '~/hooks/useDrawer';
import LoanType from '~/typings/enums/LoanType';
import formatName from '~/common/formatName';
import { useModal } from '~/hooks/useModal';
import { SelectionResume } from '~/screens/Person/components/Drawers/Opportunity/REFIN/Resume/SelectionResume';
import { ContractBox, CheckboxContainer } from './Selection.styled';
import Card from './Card';
import { OpportunityBody, OpportunityContainer, OpportunityFooter } from '../../Opportunity.styled';

export type DetailsProps = {
  opportunity: OpportunityListItem;
  simulationAlert?: boolean;
  previousSimulation?: { contractValue: number; loanType: string };
  setSimulationAlert?: (value: boolean) => void;
  setSelectedProposal?: (proposal: SuggestedProposalReturn & { contractsIds?: number[] }) => void;
};

type SuccessOrError<T> = T & { statusCode?: number };

export const Selection = withStepperProps<DetailsProps>(
  ({
    stepper,
    opportunity,
    simulationAlert,
    previousSimulation,
    setSimulationAlert,
    setSelectedProposal,
  }) => {
    const theme = useTheme();
    const { setConfig, closeDrawer } = useDrawer();
    const { openModal } = useModal();
    const { toStepById } = stepper;
    const buttonColor = themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondary' });
    const [contractsIds, setContractsIds] = useState<number[]>([]);
    const [withMargin, setWithMargin] = useState<boolean>(true);
    const withoutMarginTypes = [
      'MAX_VALUE_FOR_DEPOSIT_CONSOLIDATION_WITHOUT_MARGIN',
      'MAX_VALUE_FOR_DEPOSIT_WITHOUT_AGGREGATE_MARGIN',
    ];

    const proposalsControl = useAwaitControl(suggestedProposals);
    const proposalsLoading = proposalsControl.isRunning();
    const proposalsResult: SuccessOrError<SuggestedProposals> = proposalsControl.result();
    const proposalsFailure = proposalsControl.hasFailure();

    const personControl = useAwaitControl(getPersonDetail);
    const person = personControl.result();

    const loading = proposalsLoading;

    const sort = (list: any) => list?.sort((a, b) => b.installmentValue - a.installmentValue);

    const contracts = useMemo(
      () => sort(opportunity?.refinancingContracts?.[0]?.contractValueList),
      [opportunity],
    );

    const allContractIds = useMemo(
      () => contracts?.map((contract: ContractValueList) => contract?.contractId),
      [contracts],
    );

    const proposalWithoutMargin: SuggestedProposalReturn[] = useMemo(
      () =>
        sort(
          proposalsResult?.suggestedProposalReturns?.filter((proposal) =>
            withoutMarginTypes.includes(proposal.suggestedProposalType),
          ),
        ),
      [proposalsResult],
    );

    const proposalWithMargin: SuggestedProposalReturn[] = useMemo(
      () =>
        sort(
          proposalsResult?.suggestedProposalReturns?.filter(
            (proposal) => !withoutMarginTypes.includes(proposal.suggestedProposalType),
          ),
        ),
      [proposalsResult],
    );

    const currentProposal: SuggestedProposalReturn = useMemo(() => {
      if (withMargin && proposalWithMargin?.length) {
        return proposalWithMargin?.[0];
      }

      return proposalWithoutMargin?.[0];
    }, [proposalWithMargin, proposalWithoutMargin, withMargin]);

    const isSelectAll = contractsIds.length === contracts.length;

    const handleSelectContract = (contractId: number) => (checked) => {
      if (checked) {
        setContractsIds((values) => [...values, contractId]);
      } else {
        setContractsIds(contractsIds.filter((id) => id !== contractId));
      }
    };

    const handleSelectAll = () => {
      if (isSelectAll) {
        setContractsIds([]);
      } else {
        setContractsIds(allContractIds);
      }
    };

    const handleSelectNone = () => {
      setContractsIds([]);
    };

    const handleSelectAllOrNone = (checked: any) => {
      if (checked) {
        handleSelectAll();
      } else {
        handleSelectNone();
      }
    };

    const handleNextStep = () => {
      if (previousSimulation) {
        setSimulationAlert(true);
      } else {
        setSelectedProposal({ contractsIds, ...currentProposal });
        toStepById('details');
      }
    };

    const handleContinueToNextStep = () => {
      setSimulationAlert(false);
      setSelectedProposal({ contractsIds, ...currentProposal });
      toStepById('details');
    };

    const handleDownload = () => {
      const name = formatName(person?.name, 2);
      const date = moment().format('DD-MM-YYYY');
      const fileName = `Oportunidade Refinanciamento - ${name} - ${date}`;

      openModal(
        <DownloadNodeModal fileName={fileName}>
          <SelectionResume
            contracts={contracts}
            currentProposal={currentProposal}
            contractsIds={contractsIds}
            marginLoan={proposalsResult.marginLoan}
            withMargin={withMargin}
          />
        </DownloadNodeModal>,
        {
          closable: true,
          maskClosable: false,
          noPadding: true,
          width: 460,
        },
      );
    };

    const renderMarginLoan = useCallback(
      () => (
        <ContractBox disabled={!proposalWithoutMargin?.length}>
          <CheckboxContainer rounded checked={withMargin}>
            <Checkbox
              disabled={!proposalWithoutMargin?.length}
              name="proposal_with_margin"
              onChange={setWithMargin}
              checked={withMargin}
            >
              <Typography type="bodyLarge" weight={600}>
                Aproveitar margem livre
              </Typography>
            </Checkbox>
          </CheckboxContainer>

          <Flex gap={4}>
            <Typography type="bodyMedium" color="element.secondary">
              Margem disponível:
            </Typography>
            <Typography type="bodyMedium" weight={600}>
              {toMoney(proposalsResult.marginLoan) || 'R$ 0,00'}
            </Typography>
          </Flex>
        </ContractBox>
      ),
      [proposalWithoutMargin, withMargin, proposalsResult],
    );

    useEffect(() => {
      if (proposalsFailure && proposalsResult?.statusCode !== 404) {
        toStepById('error');
      }
    }, [proposalsFailure, proposalsResult]);

    useEffect(() => {
      setConfig('opportunity-details', {
        maskClosable: !loading,
        backButton: false,
        extraAction: (
          <Button
            variant="text"
            size="sm"
            iconOnly
            icon="Download"
            title="Baixar resumo"
            disabled={loading}
            onClick={handleDownload}
          />
        ),
      });
    }, [loading]);

    useEffect(() => {
      if (!_.isEmpty(contractsIds)) {
        proposalsControl.start({
          agreementId: person?.agreementId,
          contractIds: contractsIds,
          enrollmentId: opportunity?.enrollmentId,
          loanType: opportunity?.loanType,
        });
      }
    }, [contractsIds]);

    useEffect(() => {
      setContractsIds(allContractIds);
    }, []);

    return (
      <>
        <OpportunityContainer loading={loading ? 1 : 0}>
          <OpportunityBody>
            <Flex direction="column" gap={24} pv={24}>
              <Typography type="paragraphLarge" weight={500} element="h4">
                Selecione abaixo os contratos que seu cliente deseja assinar
              </Typography>

              <CheckboxContainer>
                <Checkbox name="counter" onChange={handleSelectAllOrNone} checked={isSelectAll}>
                  {contractsIds.length > 0
                    ? `${contractsIds.length} de ${contracts.length} contratos selecionados`
                    : 'Nenhum contrato selecionado'}
                </Checkbox>
              </CheckboxContainer>

              <Flex direction="column" gap={24} width="100%">
                {contracts?.map((contract, index) => (
                  <Card
                    contract={contract}
                    contractsIds={contractsIds}
                    onChange={handleSelectContract}
                    index={index}
                    key={contract.contractId}
                  />
                ))}

                {!!proposalWithoutMargin?.length &&
                  !!proposalWithMargin?.length &&
                  renderMarginLoan()}
              </Flex>
            </Flex>
          </OpportunityBody>

          <OpportunityFooter>
            <Flex direction="column" gap={8} width="100%" mb={32} mt={8}>
              <Flex justify="space-between" align="center" width="100%">
                <Typography type="bodyMedium" weight={600}>
                  Valor selecionado total:
                </Typography>
                {loading ? (
                  <Shimmer width={120} height={32} />
                ) : (
                  <Typography type="headingH5" weight={600}>
                    {contractsIds.length && currentProposal?.valueForDeposit
                      ? toMoney(currentProposal?.valueForDeposit || 0)
                      : 'R$ 0,00'}
                  </Typography>
                )}
              </Flex>

              <Flex justify="space-between" align="center" width="100%">
                <Typography type="bodyMedium" weight={600}>
                  Nova parcela:
                </Typography>
                {loading ? (
                  <Shimmer width={88} height={28} />
                ) : (
                  <Typography type="headingH6" weight={600}>
                    {contractsIds.length && currentProposal?.installmentValue
                      ? toMoney(currentProposal?.installmentValue || 0)
                      : 'R$ 0,00'}
                  </Typography>
                )}
              </Flex>
            </Flex>

            <Button
              onClick={handleNextStep}
              customColor={buttonColor}
              loading={loading}
              disabled={!contractsIds.length || !currentProposal}
              fullWidth
              rounded
            >
              Avançar
            </Button>
          </OpportunityFooter>
        </OpportunityContainer>

        <ChildrenDrawer
          title="Uma simulação já foi iniciada anteriormente"
          visible={simulationAlert}
          container=".ant-drawer-body"
        >
          <Flex direction="column" gap={24} pv={24} ph={24}>
            <Typography type="bodyMedium" center color="element.secondary" element="p">
              Já existe uma simulação de{' '}
              <Typography.Bold weight={600} color="element.primary">
                {LoanType(previousSimulation?.loanType)}
              </Typography.Bold>
              {', '}
              que foi iniciada anteriormente com um valor de
              <Typography.Bold weight={600} color="element.primary">
                {' '}
                {toMoney(previousSimulation?.contractValue)}
              </Typography.Bold>
              .
            </Typography>

            <Alert status="warning">
              Ao optar por uma nova simulação, a anterior será substituída pela nova.
            </Alert>

            <Flex gap={8} direction="column" width="100%">
              <Button color="black" fullWidth rounded onClick={handleContinueToNextStep}>
                Avançar com a nova simulação
              </Button>

              <Button
                color="black"
                variant="outline"
                rounded
                fullWidth
                onClick={() => closeDrawer('opportunity-details')}
              >
                Fechar
              </Button>
            </Flex>
          </Flex>
        </ChildrenDrawer>
      </>
    );
  },
);
