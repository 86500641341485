import DataValid from './DataValid';
import FGTSInstallments from './FGTSInstallments';
import Pendencies from './Pendencies';
import ModalStep from './ModalStep';
import UpdateBirthDate from './UpdateBirthDate';
import UpdateContractNumber from './UpdateContractNumber';

export const CONTRACT_DETAIL_MODALS = [
  DataValid,
  FGTSInstallments,
  DataValid,
  UpdateBirthDate,
  UpdateContractNumber,
  ModalStep,
  Pendencies,
];

export default {
  ModalStep,
  FGTSInstallments,
  DataValid,
  UpdateBirthDate,
  UpdateContractNumber,
  Pendencies,
};
