import React, { useMemo, useState } from 'react';
import { Popover } from '~/ui/components';
import { TicketUpdateModal } from '~/components';
import { useModal } from '~/hooks/useModal';
import { useAwaitControl } from 'react-redux-await-control';
import { getTicketDetails, getTicketStatus, selectTicket } from '~/store/tickets/actions';
import { isTicketStatusComplete } from '~/common/tickets';
import { ActionButton, Option, OptionsList, TwilioOption } from './TicketOptions.styled';
import { AWAITING_RESOLUTION } from '../../TicketModal/TicketResume/TicketResume';

export function TicketOptions() {
  const ticketControl = useAwaitControl(getTicketDetails);
  const getTicketStatusControl = useAwaitControl(getTicketStatus);
  const selectTicketControl = useAwaitControl(selectTicket);

  const id = selectTicketControl.result();
  const ticket = ticketControl.result(id);
  const status = getTicketStatusControl.result();

  const { openModal } = useModal();
  const [visible, setVisible] = useState(false);

  const hasTwilioProtocol = useMemo(() => !!ticket?.protocolTwilio, [ticket]);

  const isComplete = useMemo(
    () => isTicketStatusComplete(status, ticket?.ticketStatusId),
    [status, ticket],
  );

  const shouldShowOptions = useMemo(
    () => hasTwilioProtocol || !isComplete || ticket?.ticketStatusId === AWAITING_RESOLUTION,
    [hasTwilioProtocol, isComplete],
  );

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handleOpenModal = () => {
    openModal(<TicketUpdateModal id={id} />, {
      id: 'ticket-update-modal',
      title: 'Alterar status do ticket',
      width: 350,
    });

    setVisible(false);
  };

  if (!shouldShowOptions) {
    return null;
  }

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      onVisibleChange={handleVisibleChange}
      visible={visible}
      width={258}
      noHeader
      content={
        <OptionsList className="ticket-options">
          {hasTwilioProtocol && (
            <TwilioOption icon="Twilio">#{ticket?.protocolTwilio}</TwilioOption>
          )}

          {(!isComplete || ticket?.ticketStatusId === AWAITING_RESOLUTION) && (
            <Option icon="Ticket" onClick={handleOpenModal} className="update-ticket-status">
              Alterar status do ticket
            </Option>
          )}
        </OptionsList>
      }
    >
      <ActionButton className="ticket-options-button" />
    </Popover>
  );
}
