import React, { ReactNode } from 'react';

import { ContentContainer, SectionContainer, Divider } from './styled';

type BaseProps = {
  children: ReactNode;
}

type SectionProps = {
  title?: string;
} & BaseProps;

function Content({ title, children }: SectionProps) {
  return (
    <ContentContainer>
      {title && (
        <span>{title}</span>
      )}
      {children}
    </ContentContainer>
  );
}

type BoxProps = {} & BaseProps;

export default function Section({ children }: BoxProps) {
  return (
    <SectionContainer>
      {children}
    </SectionContainer>
  );
}

Section.Divider = Divider;
Section.Content = Content;
