import React, { useCallback, useMemo } from 'react';

import { useModal } from '~/hooks/useModal';
import { ExcludeAgreementPasswordModal } from '~/components/AgreementPasswordActions/ExcludeAgreementPasswordModal/ExcludeAgreementPasswordModal';
import { Typography } from '~/ui/components';
import { AgreementPasswordHistoryModal } from '~/components/AgreementPasswordActions/AgreementPasswordHistoryModal/AgreementPasswordHistoryModal';
import { useTheme } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import useUserRoles from '~/hooks/useUserRoles';
import { RequestAgreementPasswordModal } from './RequestAgreementPasswordModal/RequestAgreementPasswordModal';
import { Dots, Options } from './AgreementPasswordActions.styled';

type AgreementPasswordActionsProps = {
  id: string;
  onClick?: () => void;
};

function OptionText({ text, highlight }: { text: string; highlight?: string }) {
  const theme = useTheme();

  const textColor = useMemo(
    () => themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
    [theme],
  );

  return (
    <Typography type="caption" weight={400} element="span" color={textColor}>
      {text} {highlight && <b>{highlight}</b>}
    </Typography>
  );
}

function AgreementPasswordActions({ id, onClick }: AgreementPasswordActionsProps) {
  const { openModal } = useModal();
  const { isAdmin, hasRole } = useUserRoles();

  const allowedToRevokePassword = useMemo(
    () => isAdmin || hasRole('REVOKE_AGREEMENT_PASSWORD'),
    [],
  );

  const handleRequestAgreementPassword = useCallback(() => {
    openModal(<RequestAgreementPasswordModal id={id} />, {
      title: 'Confirme a requisição da senha',
      id: 'request-agreement-password-modal',
      width: 370,
      closable: true,
    });
    onClick?.();
  }, [id, onClick, openModal]);

  const handleExcludeAgreementPassword = useCallback(() => {
    openModal(<ExcludeAgreementPasswordModal />, {
      title: 'Confirme a exclusão da senha',
      id: 'exclude-agreement-password-modal',
      width: 370,
      closable: true,
      maskClosable: false,
    });
    onClick?.();
  }, [onClick, openModal]);

  const handleHistoryAgreementPassword = useCallback(() => {
    openModal(<AgreementPasswordHistoryModal id={id} />, {
      title: 'Histórico dos agentes',
      id: 'agreement-password-history-modal',
      width: 350,
      closable: true,
    });
    onClick?.();
  }, [id, onClick, openModal]);

  const options = useMemo(() => {
    let optionsList = [
      {
        key: 'request',
        label: <OptionText text="Requisitar senha" highlight="Meu INSS" />,
        onClick: handleRequestAgreementPassword,
      },
      {
        key: 'exclude',
        label: <OptionText text="Excluir senha" highlight="Meu INSS" />,
        onClick: handleExcludeAgreementPassword,
        disabled: !allowedToRevokePassword,
      },
      {
        key: 'history',
        label: 'Histórico de agentes',
        onClick: handleHistoryAgreementPassword,
      },
    ];

    if (!allowedToRevokePassword) {
      optionsList = optionsList.filter((option) => option.key !== 'exclude');
    }

    return optionsList;
  }, [allowedToRevokePassword]);

  return (
    <Options options={options} width="auto" placement="bottomRight">
      <span>
        Ajustes da senha do <b>Meu INSS</b>
      </span>{' '}
      <Dots />
    </Options>
  );
}

export default AgreementPasswordActions;
