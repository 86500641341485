// @flow

import React, { useState } from 'react';
import { Col, Form, Input, Row, Select, Tooltip, Icon } from 'antd';
import { Button, Modal } from '~/components';
import { isEmpty } from 'underscore';
import masked from '~/common/masked';
import BankSelect from '~/components/BankSelect';

const { Item: FormItem } = Form;
const { Option } = Select;

const { toNumber } = masked;

type SelectionProps = {
  onEntryChange: (fields: {}) => void,
  onFieldsError: () => void,
  showModal: boolean,
  onModalClose: () => void,
  onSend: (info: {}) => void,
};

const accountTypesDefault = [
  {
    key: 'CHECKING_ACCOUNT',
    name: 'Conta corrente',
  },
  {
    key: 'SAVINGS_ACCOUNT',
    name: 'Conta poupança',
  },
];

function Selection(props: SelectionProps) {
  const [fields, setFields] = useState({});

  const onEntryChange = field => e => {
    const newfields = {
      ...fields,
      [field]: typeof e === 'string' ? e : e.target.value,
    };
    setFields(newfields);
  };

  const onEnter = () => {
    props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        props.onSend(fields);
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <Modal
      title='Conta bancária'
      maskClosable
      width={700}
      visible={props.showModal}
      key='modal'
      okText='Enviar'
      onCancel={props.onModalClose}
      footer={[<Button title='Enviar' onClick={onEnter} />]}
    >
      <div>
        <Form onError={props.onFieldsError}>
          <Row type='flex' justify='start'>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              Tipo de conta
              <FormItem>
                {getFieldDecorator('accountType', {
                  rules: [
                    {
                      required: true,
                      message: 'Tipo de conta é obrigatório',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Selecione'
                    onChange={onEntryChange('accountType')}
                  >
                    {accountTypesDefault.map(item => (
                      <Option key={item.key}>{item.name}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 11, offset: 1 }}>
              Banco
              <FormItem>
                {getFieldDecorator('bankNumber', {
                  rules: [
                    {
                      required: true,
                      message: 'Banco é obrigatório',
                    },
                  ],
                })(<BankSelect onChange={onEntryChange('bankNumber')} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 7 }}>
              Agência
              <FormItem>
                {getFieldDecorator('agency', {
                  rules: [
                    {
                      len: 4,
                      required: true,
                      message: 'Agência é obrigatório',
                    },
                  ],
                  normalize: toNumber,
                })(
                  <Input
                    maxLength={4}
                    placeholder='Digite o n° da agência'
                    onChange={onEntryChange('agency')}
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
              Dígito
              <FormItem>
                {getFieldDecorator('agencyDigit', {
                  normalize: toNumber,
                })(
                  <Input
                    maxLength={1}
                    placeholder='Digite o dígito'
                    onChange={onEntryChange('agencyDigit')}
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6, offset: 1 }}>
              Conta
              <FormItem>
                {getFieldDecorator('checkingAccount', {
                  rules: [
                    {
                      required: true,
                      message: 'Conta é obrigatória',
                    },
                  ],
                  normalize: toNumber,
                })(
                  <Input
                    maxLength={20}
                    placeholder='Digite o n° da conta'
                    onChange={onEntryChange('checkingAccount')}
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
              Dígito
              <FormItem>
                {getFieldDecorator('checkingAccountDigit', {
                  rules: [
                    {
                      required: true,
                      message: 'Dígito de conta é obrigatório',
                    },
                  ],
                  normalize: toNumber,
                })(
                  <Input
                    maxLength={1}
                    placeholder='Digite o dígito'
                    onChange={onEntryChange('checkingAccountDigit')}
                    suffix={
                      <Tooltip
                        placement='top'
                        title={'Substitua letra por 0 quando houver.'}
                      >
                        <Icon type='info-circle' theme='twoTone' />
                      </Tooltip>
                    }
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

const WrappedSelection: SelectionProps = Form.create()(Selection);

export default WrappedSelection;
