import { css } from 'styled-components';
import popover from './components/popover';
import tooltip from './components/tooltip';
import select from './components/select';
import modal from './components/modal';
import datepicker from './components/datepicker';
import timepicker from './components/timepicker';
import drawer from './components/drawer';
import layout from './components/layout';

export default css`
  ${popover};
  ${tooltip};
  ${select};
  ${modal};
  ${datepicker};
  ${timepicker};
  ${drawer};
  ${layout};
`;
