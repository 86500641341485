/* @flow */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ModalsProvider } from '~/contexts/ModalsContext';
import { ScreenConfig } from '~/typings/components';

export function HelmetTitle({ title }: { title: string }) {
  return (
    <Helmet>
      <title>{title ? `${title} | ` : ''}Back Office</title>
    </Helmet>
  );
}

export default function screenRegister(screenConfig: ScreenConfig) {
  if (!screenConfig) {
    throw new Error('You need to pass a screen configuration to component.');
  }

  return function screenRegisterHOC(WrappedComponent) {
    const Wrapper: any = withRouter((props) => {
      if (screenConfig.modals?.length) {
        return (
          <ModalsProvider>
            <HelmetTitle title={screenConfig.headerTitle} />
            <WrappedComponent __type="screen" {...props} />

            {screenConfig.modals.map((Modal) => (
              <Modal key={Modal.alias} />
            ))}
          </ModalsProvider>
        );
      }

      return (
        <>
          <HelmetTitle title={screenConfig.headerTitle} />
          <WrappedComponent __type="screen" {...props} />
        </>
      );
    });

    Wrapper.__type = 'screen';

    Wrapper.screenName = screenConfig.screenName;
    Wrapper.path = screenConfig.path;
    Wrapper.headerTitle = screenConfig.headerTitle;
    Wrapper.customHeaderTitle = {
      selector: screenConfig.headerTitleSelector,
      load: screenConfig.headerTitleLoadAction,
      parser: screenConfig.headerTitleParser,
    };
    Wrapper.headerMenu = screenConfig.headerMenu;
    Wrapper.customClass = screenConfig.customClass;
    Wrapper.customLayout = screenConfig.customLayout;
    Wrapper.headerMenuPosition = screenConfig.headerMenuPosition;
    Wrapper.modals = screenConfig.modals;
    Wrapper.stages = screenConfig.stages;

    return Wrapper;
  };
}
