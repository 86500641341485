import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, message, Row } from 'antd';
import { useAwaitControl } from 'react-redux-await-control';

import { CompareImages, Popover, Shimmer, Switch } from '~/ui/components';

import { useModal } from '~/hooks/useModal';
import { FileHistoryModal, PreviewModal, UploadFileModal } from '~/components';

import { getPersonRegistryFiles } from '~/store/people/actions';
import { getFiles } from '~/store/files/actions';
import { getContractBundleFiles, uploadContractFile } from '~/store/contracts/actions';
import { FileSingle } from './FileSingle';
import {
  ActionButton,
  CloudDownloadIcon,
  Container,
  Divider,
  EmptyMessage,
  FileContainer,
  FileDetails,
  FileListItem,
  Files,
  FolderDownloadIcon,
  HeaderContainer,
  LoadingSpinnerIcon,
  Option,
  SwitchRow,
  Text,
  Title,
} from './FilesList.styled';

export type FileItem = {
  id?: string | number;
  name: string;
  url: string;
  size: number;
  uploadDate?: string;
  loading?: boolean;
  fileType?: string;
  personImageType?: string;
  extension: string;
  personImageOrigin?: string;
};

type FilesListProps = {
  type: 'PERSON' | 'CONTRACT';
  id?: string | number;
  noImageText?: string;
};

export function FilesList({ id, noImageText, type }: FilesListProps) {
  const { openModal } = useModal();
  const [compareFile, setCompareFile] = useState<FileItem | null>(null);
  const [showInactiveFiles, setShowInactiveFiles] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const getPersonRegistryFilesControl = useAwaitControl(getPersonRegistryFiles);
  const downloadBundleControl = useAwaitControl(getContractBundleFiles);
  const getFilesControl = useAwaitControl(getFiles);
  const uploadContractFileControl = useAwaitControl(uploadContractFile);

  const files = getFilesControl.result(`${type}_${id}`);
  const loading = getFilesControl.isRunning(`${type}_${id}`);

  const personRegistryFiles = getPersonRegistryFilesControl.result(id);
  const personRegistryFilesSuccess = getPersonRegistryFilesControl.isSuccessful(id);
  const uploadContractFileControlSuccess = uploadContractFileControl.isSuccessful();

  const downloadBundleLoading = downloadBundleControl.isRunning();
  const downloadRegistryFilesLoading = getPersonRegistryFilesControl.isRunning();

  const isPdf = useCallback((file) => file?.extension === '.pdf', []);

  const isContractFiles = useCallback(
    (file) =>
      type === 'CONTRACT' && ['FACEFRONT', 'FACEBIOMETRY', 'DOCUMENTFRONT'].includes(file.type),
    [type],
  );

  const filteredFiles = useMemo(() => files.filter((file) => file.url && !file.inactive), [files]);

  const filesToCompare = useMemo(() => {
    const filteredFiles = files.filter((file) => file.url && !isPdf(file) && !file.inactive);

    if (type === 'CONTRACT') {
      return filteredFiles.filter(isContractFiles);
    }

    return filteredFiles;
  }, [files]);

  const inactiveFiles = useMemo(() => files.filter((file) => file.url && file.inactive), [files]);

  const downloadLoading = useMemo(
    () => downloadBundleLoading || downloadRegistryFilesLoading,
    [downloadBundleLoading, downloadRegistryFilesLoading],
  );

  const hasFilesToShow = useMemo(() => {
    if (showInactiveFiles) {
      return inactiveFiles?.length || filteredFiles?.length;
    }
    return filteredFiles?.length;
  }, [filteredFiles, showInactiveFiles, inactiveFiles]);

  const downloadFile = (url, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    link.remove();
  };

  const handleFilePreview = useCallback(
    async (file) => {
      openModal(
        <PreviewModal url={file.url} isPdf={isPdf(file)} name={file?.name} withControls />,
        {
          width: 'auto',
          noBackground: true,
          id: 'preview-modal',
        },
      );
    },
    [openModal],
  );

  const openFileUploadModal = useCallback(() => {
    setIsPopoverVisible(false);
    openModal(<UploadFileModal id={id} />, {
      width: '360px',
    });
  }, []);

  const handlePopoverVisibleChange = useCallback((visible) => {
    setIsPopoverVisible(visible);
  }, []);

  const handleShowHistory = useCallback(
    (file) => {
      openModal(
        <FileHistoryModal
          documentType={file.documentType}
          fileType={file.personImageType || file.type}
          id={id}
          type={type}
        />,
        {
          title: 'Histórico da imagem',
          closable: true,
        },
      );
    },
    [id],
  );

  const handleChangeInactiveFiles = useCallback(
    (value) => {
      setShowInactiveFiles(value);
    },
    [showInactiveFiles],
  );

  const checkFileExt = (name, ext) => name.match(new RegExp(`\\.${ext}$`));

  const handleDownload = useCallback(
    (file: FileItem) => {
      const fileExt = isPdf(file) ? 'pdf' : 'jpg';
      const fileName = checkFileExt(file.name, fileExt) ? file.name : `${file.name}.${fileExt}`;
      downloadFile(file.url, fileName);
    },
    [isPdf],
  );

  const handleDownloadBundle = useCallback(() => {
    downloadBundleControl.start({ id });
  }, [id]);

  const handleCompareFiles = useCallback((file: FileItem) => {
    setCompareFile(file);
  }, []);

  const renderShimmer = useCallback(
    () => (
      <>
        <FileContainer>
          <Shimmer br={8} width={246} height={246} />
          <FileDetails>
            <Shimmer width={200} height={20} mb={8} />
            <Shimmer width={150} height={15} />
          </FileDetails>
        </FileContainer>
        <FileContainer>
          <Shimmer br={8} width={246} height={246} />
          <FileDetails>
            <Shimmer width={200} height={20} mb={8} />
            <Shimmer width={150} height={15} />
          </FileDetails>
        </FileContainer>
        <FileContainer>
          <Shimmer br={8} width={246} height={246} />
          <FileDetails>
            <Shimmer width={200} height={20} mb={8} />
            <Shimmer width={150} height={15} />
          </FileDetails>
        </FileContainer>
      </>
    ),
    [],
  );

  useEffect(() => {
    if (uploadContractFileControlSuccess) {
      getFilesControl.start({ type, id }, { actionId: `${type}_${id}` });
    }
  }, [uploadContractFileControlSuccess]);

  useEffect(() => {
    if (personRegistryFilesSuccess && personRegistryFiles) {
      const url = URL.createObjectURL(personRegistryFiles);
      downloadFile(url, `bojo-registro-${id}.zip`);
    } else if (personRegistryFilesSuccess && !personRegistryFiles) {
      message.warning('Arquivo não encontrado');
    }
  }, [personRegistryFilesSuccess]);

  return (
    <Container loading={loading ? 1 : 0}>
      <HeaderContainer isContract={type === 'CONTRACT'}>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={12}>
            <Title>
              Arquivos do {type === 'CONTRACT' ? 'contrato' : 'seu cliente'}
              {type === 'CONTRACT' && (
                <Popover
                  noHeader
                  trigger="click"
                  visible={isPopoverVisible}
                  onVisibleChange={handlePopoverVisibleChange}
                  width="250px"
                  disabled={downloadLoading}
                  content={
                    <>
                      <FileListItem onClick={openFileUploadModal} disabled={downloadLoading}>
                        <FolderDownloadIcon width={20} height={20} />
                        <Option>Inserir novos arquivos</Option>
                      </FileListItem>
                      <Divider />
                      <FileListItem onClick={handleDownloadBundle} disabled={downloadLoading}>
                        {downloadBundleLoading ? (
                          <LoadingSpinnerIcon width={20} height={20} />
                        ) : (
                          <CloudDownloadIcon width={20} height={20} />
                        )}
                        <Option>Baixar todos os arquivos</Option>
                      </FileListItem>
                    </>
                  }
                >
                  <ActionButton icon="Download" />
                </Popover>
              )}
            </Title>

            {type === 'CONTRACT' && (
              <Text>
                É possível baixar os arquivos em massa ou bojo digital.{' '}
                <u>Os boletos tem validade de um dia útil.</u>
              </Text>
            )}
          </Col>
          <Col span={12}>
            {!!inactiveFiles?.length && (
              <SwitchRow>
                <Switch
                  small
                  onChange={handleChangeInactiveFiles}
                  label="Exibir todos os arquivos"
                />
              </SwitchRow>
            )}
          </Col>
        </Row>
      </HeaderContainer>

      <Files>
        {!!loading && renderShimmer()}

        {!loading && !hasFilesToShow && (
          <EmptyMessage>
            <span>Nenhum arquivo encontrado</span>
          </EmptyMessage>
        )}

        {!loading &&
          filteredFiles.map((file) => (
            <FileSingle
              key={`${file.name}_${file.personImageType || file.type}`}
              file={file}
              noImageText={noImageText}
              handleCompareFiles={handleCompareFiles}
              handleFilePreview={handleFilePreview}
              handleDownload={handleDownload}
              handleShowHistory={handleShowHistory}
            />
          ))}

        {!loading &&
          showInactiveFiles &&
          inactiveFiles.map((file) => (
            <FileSingle
              key={`${file.name}_${file.personImageType || file.type}_inactive`}
              file={file}
              noImageText={noImageText}
              handleCompareFiles={handleCompareFiles}
              handleFilePreview={handleFilePreview}
              handleDownload={handleDownload}
              handleShowHistory={handleShowHistory}
              inactive
            />
          ))}
      </Files>

      {compareFile && (
        <CompareImages
          files={filesToCompare}
          initialFile={compareFile}
          onClose={() => setCompareFile(null)}
          allFiles
        />
      )}
    </Container>
  );
}
