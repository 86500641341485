import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import {
  Button, Input, Menu, Dropdown, Icon, Checkbox, Tooltip,
} from 'antd';

import type { PersonComment } from '~/typings/entities/person';
import CommentMotives, { ClixCommentMotives } from '~/typings/enums/CommentMotives';
import { Spinner } from '~/components';
import { addCommentToPerson, updateCommentToPerson } from '~/store/people/actions';

import FilePlusIcon from '~/assets/icons/file-plus.svg';
import FileSearchIcon from '~/assets/icons/file-search.svg';
import CallingIcon from '~/assets/icons/calling.svg';
import FilterIcon from '~/assets/icons/filter.svg';
import SmileIcon from '~/assets/icons/smile.svg';
import ChannelService from '~/typings/enums/ChannelServices';
import CommentFilters from '~/typings/enums/CommentFilters';
import {
  CustomDropdown, FormCommentContainer, MenuItem, MenuSearchInput, SubMenu,
} from './styled';
import Icons from './Icons';

type CommentFormProps = {
  setVisibility?: () => void;
  initial?: PersonComment;
  contracts: any[];
  personId: number;
};

enum CommentMoods {
  HAPPY = 'Resolvido',
  SAD = 'Não resolvido',
  MEH = 'Sem definição',
}

const IconMap = {
  SAD: Icons.Frown,
  MEH: Icons.Meh,
  HAPPY: Icons.Smile,
};

export default function CommentForm({
  personId,
  initial,
  setVisibility,
  contracts,
}: CommentFormProps) {
  const [mood, setMood] = useState<string>(initial?.mood);
  const [reason, setReason] = useState(initial?.motive);
  const [customerServiceArea, setCustomerServiceArea] = useState(initial?.customerServiceArea);
  const [channelService, setChannelService] = useState(initial?.channelService);
  const [contractsIds, setContractsIds] = useState<number[]>(initial?.contracts || []);
  const [contractsMenuVisibility, setContractsMenuVisibility] = useState(false);
  const [reasonFilter, setReasonFilter] = useState<string>('');
  const [reasonsMenuVisibility, setReasonsMenuVisibility] = useState(false);
  const [comment, setComment] = useState<string>(initial?.comment);

  const [addCommentControl, updateCommentControl] = useAwaitControl([
    addCommentToPerson,
    updateCommentToPerson,
  ]);
  const addCommentLoading = addCommentControl.isRunning();
  const addCommentSuccess = addCommentControl.isSuccessful();

  const updateCommentLoading = updateCommentControl.isRunning(initial?.id);
  const updateCommentSuccess = updateCommentControl.isSuccessful(initial?.id);

  const handleChangeComment = useCallback((e) => setComment(e.target.value), []);

  const includesStringSearch = useCallback((str, searchString) => {
    const searchStringWithoutAccents = searchString
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const strWithoutAccents = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return new RegExp(searchStringWithoutAccents, 'i').test(strWithoutAccents);
  }, []);

  const handleChangeReason = useCallback(
    (key) => () => {
      setReason(key);
      setReasonsMenuVisibility(false);
    },
    [],
  );

  const handleChangeContractIds = useCallback(
    (id) => {
      if (contractsIds.includes(id)) {
        setContractsIds(contractsIds.filter((el) => el !== id));
      } else {
        setContractsIds([...contractsIds, id]);
      }
    },
    [contractsIds, setContractsIds],
  );

  const renderIcon = useCallback(
    (m, active = true) => {
      const MoodIcon = IconMap[m];
      return <MoodIcon active={active} />;
    },
    [IconMap],
  );

  const moods = (
    <Menu>
      {Object.keys(CommentMoods).map((key) => (
        <MenuItem key={key} onClick={() => setMood(key)}>
          {renderIcon(key)}

          {CommentMoods[key]}
        </MenuItem>
      ))}
    </Menu>
  );

  const reasons = useMemo(() => {
    const filterByReasonString = (key) => {
      if (ClixCommentMotives[key]) {
        return includesStringSearch(ClixCommentMotives[key], reasonFilter);
      }

      return includesStringSearch(CommentMotives[key], reasonFilter);
    };

    return (
      <Menu mode="inline">
        <MenuItem noPadding key="search">
          <MenuSearchInput
            placeholder="Pesquise pelo motivo"
            onChange={(e) => setReasonFilter(e.target.value)}
            value={reasonFilter}
          />
        </MenuItem>

        <SubMenu
          key="sub_Item1"
          title={(
            <>
              <span>Clix</span>
            </>
          )}
        >
          {Object.keys(ClixCommentMotives)
            .filter(filterByReasonString)
            .map((key) => (
              <MenuItem key={key} onClick={handleChangeReason(key)}>
                {ClixCommentMotives[key]}
              </MenuItem>
            ))}
        </SubMenu>

        {Object.keys(CommentMotives)
          .filter(filterByReasonString)
          .map((key) => (
            <MenuItem key={key} onClick={handleChangeReason(key)}>
              {CommentMotives[key]}
            </MenuItem>
          ))}
      </Menu>
    );
  }, [reasonFilter, CommentMotives]);

  const filters = (
    <Menu>
      {Object.keys(CommentFilters).map((key) => (
        <MenuItem key={key} onClick={() => setCustomerServiceArea(key)}>
          {CommentFilters[key]}
        </MenuItem>
      ))}
    </Menu>
  );

  const channels = (
    <Menu>
      {Object.keys(ChannelService).map((key) => (
        <MenuItem
          key={key}
          onClick={() => {
            setChannelService(key);
          }}
        >
          {ChannelService[key]}
        </MenuItem>
      ))}
    </Menu>
  );

  const contractsMenu = (
    <Menu>
      {contracts.map((contract) => (
        <MenuItem key={contract.contractId}>
          <Checkbox
            checked={contractsIds.includes(contract.contractId)}
            onChange={() => handleChangeContractIds(contract.contractId)}
          >
            {contract.contractId}
          </Checkbox>
        </MenuItem>
      ))}
    </Menu>
  );

  const isValid = useMemo(
    () => !!mood && !!reason && !!customerServiceArea && !!channelService,
    [mood, reason, customerServiceArea, channelService],
  );

  const submitComment = useCallback(() => {
    addCommentControl.start({
      mood,
      reason,
      contracts: contractsIds,
      comment,
      personId,
      customerServiceArea,
      channelService,
    });
  }, [mood, reason, contractsIds, comment, personId, initial, channelService, customerServiceArea]);

  useEffect(() => {
    setTimeout(() => setReasonFilter(''), 500);
  }, [reasonsMenuVisibility]);

  useEffect(() => {
    if (addCommentSuccess || updateCommentSuccess) {
      setVisibility();
      addCommentControl.clear();
      updateCommentControl.clear({ actionId: initial?.id });
      setContractsIds([]);
      setComment(null);
      setMood(null);
      setReason(null);
      setCustomerServiceArea(null);
      setChannelService(null);
    }
  }, [addCommentSuccess, updateCommentSuccess, initial?.id]);

  return (
    <>
      <Spinner spinning={addCommentLoading || updateCommentLoading} />
      <FormCommentContainer>
        <Input.TextArea
          onChange={handleChangeComment}
          value={comment}
          rows={4}
          placeholder="Escrever um novo comentário"
        />

        <div className="preview">
          {mood && renderIcon(mood, false)}
          {reason && CommentMotives[reason] ? (
            <span>{CommentMotives[reason]}</span>
          ) : (
            reason && ClixCommentMotives[reason] && <span>{ClixCommentMotives[reason]}</span>
          )}
          {customerServiceArea && <span>{CommentFilters[customerServiceArea]}</span>}
          {contractsIds?.map((id) => (
            <span>{id}</span>
          ))}
          {channelService && <span>{ChannelService[channelService]}</span>}
        </div>

        <div className="actions-section">
          <div>
            <Tooltip title="adicionar novas reações" placement="top">
              <Dropdown overlay={moods} trigger={['click']}>
                <Icon component={SmileIcon} />
              </Dropdown>
            </Tooltip>

            <Tooltip title="escolha um novo motivo" placement="top">
              <CustomDropdown
                overlay={reasons}
                trigger={['click']}
                visible={reasonsMenuVisibility}
                onVisibleChange={setReasonsMenuVisibility}
              >
                <Icon component={FileSearchIcon} />
              </CustomDropdown>
            </Tooltip>

            <Tooltip title="inserir um filtro novo" placement="top">
              <Dropdown overlay={filters} trigger={['click']}>
                <Icon component={FilterIcon} />
              </Dropdown>
            </Tooltip>

            <Tooltip title="inserir contratos" placement="top">
              <Dropdown
                overlay={contractsMenu}
                trigger={['click']}
                visible={contractsMenuVisibility}
                onVisibleChange={setContractsMenuVisibility}
              >
                <Icon component={FilePlusIcon} />
              </Dropdown>
            </Tooltip>

            <Tooltip title="canal de atendimento" placement="top">
              <Dropdown overlay={channels} trigger={['click']}>
                <Icon component={CallingIcon} />
              </Dropdown>
            </Tooltip>
          </div>

          <Button type="primary" onClick={submitComment} disabled={!isValid}>
            Publicar
          </Button>
        </div>
      </FormCommentContainer>
    </>
  );
}
