import React, { ReactNode } from 'react';
import { ConfigLabel, Icon, LabelAlert } from './Alert.styled';

export interface AlertProps {
  label?: string | ReactNode;
  renderValue?: ReactNode;
  children?: ReactNode;
  icon?: 'exclamation-circle' | string;
  status?: 'success' | 'warning' | 'error' | 'info';
  className?: string;
  fullWidth?: boolean;
}

export function Alert({
  label,
  renderValue,
  icon = 'exclamation-circle',
  status = 'success',
  children,
  ...props
}: AlertProps) {
  return (
    <LabelAlert status={status} className="mt-alert" {...props}>
      <Icon status={status} type={icon} data-testid="alert-icon" />
      {renderValue}
      {!renderValue && <ConfigLabel>{label || children}</ConfigLabel>}
    </LabelAlert>
  );
}
