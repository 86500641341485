import React, { useState } from 'react';

import { ContractValueList } from '~/typings/entities/Opportunity';
import toMoney from '~/common/masked/toMoney';
import { Caret, Checkbox, Flex, Typography } from '~/ui/components';
import toPercentage from '~/common/masked/toPercentage';
import { CheckboxContainer, ContractBox, Informations } from './Selection.styled';

type CardProps = {
  contract: ContractValueList;
  contractsIds: number[];
  onChange: (contracts: number) => (value: boolean) => void;
  index: number;
};

function Card({ contract, contractsIds, onChange, index }: CardProps) {
  const [isOpen, setIsOpen] = useState(false);

  const isContractSelected = (contractId: number) => contractsIds.includes(contractId);

  return (
    <ContractBox>
      <CheckboxContainer rounded checked={isContractSelected(contract.contractId)}>
        <Checkbox
          name={`contract_${contract.contractId}`}
          onChange={onChange(contract.contractId)}
          checked={isContractSelected(contract.contractId)}
        >
          <Typography type="bodyLarge" weight={600}>
            Contrato {index + 1}
          </Typography>
        </Checkbox>
      </CheckboxContainer>

      <div>
        <Flex gap={4}>
          <Typography type="bodyMedium" color="element.secondary">
            Parcela original:
          </Typography>
          <Typography type="bodyMedium" weight={600}>
            {toMoney(contract?.installmentValue)}
          </Typography>
        </Flex>

        <Informations mt={8} gap={8} align="center" onClick={() => setIsOpen(!isOpen)}>
          <Typography type="bodyMedium" color="brand.primary" weight={700}>
            {!isOpen ? 'Mais informações' : 'Menos informações'}
          </Typography>
          <Caret caretOpen={isOpen} />
        </Informations>

        {isOpen && (
          <>
            <Flex gap={4}>
              <Typography type="bodySmall" color="element.secondary">
                Valor original do contrato
              </Typography>
              <Typography type="bodySmall" color="element.primary" weight={600}>
                {toMoney(
                  Number(contract?.numberOfInstallments) * Number(contract?.installmentValue),
                )}
              </Typography>
            </Flex>

            <Flex gap={4}>
              <Typography type="bodySmall" color="element.secondary">
                Parcelas restantes
              </Typography>
              <Typography type="bodySmall" color="element.primary" weight={600}>
                {Number(contract?.numberOfInstallments) - Number(contract?.paidInstallments)}X{' '}
                {toMoney(contract?.installmentValue) || '-'}
              </Typography>
            </Flex>

            <Flex gap={4}>
              <Typography type="bodySmall" color="element.secondary">
                Taxa contrato original
              </Typography>
              <Typography type="bodySmall" color="element.primary" weight={600}>
                {toPercentage(contract?.nominalRatePerMonth) || '-'} (a.m)
              </Typography>
            </Flex>
          </>
        )}
      </div>
    </ContractBox>
  );
}

export default Card;
