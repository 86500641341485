import takeRequest from '~/store/takeRequest';

import { put, takeLatest } from 'redux-saga/effects';
import { getPersonDetail } from '~/store/people/actions';
import { eraseAgreementPassword, listAgreements } from './actions';

function* loadPerson({ meta: { payload } }: any) {
  yield put(getPersonDetail.start(payload));
}

export function* agreementsSaga() {
  yield takeRequest(listAgreements);
  yield takeRequest(eraseAgreementPassword);

  yield takeLatest(eraseAgreementPassword.success.toString(), loadPerson);
}
