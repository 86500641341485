import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { isEmpty } from 'underscore';

import masked from '~/common/masked';

import registerLoanHistory, {
  REGISTER_LOAN_HISTORY_REQUEST,
  REGISTER_LOAN_HISTORY_SUCCESS,
  REGISTER_LOAN_HISTORY_FAILURE,
} from '~/store/registerLoanHistory/action';

import updateLoanHistory, {
  UPDATE_LOAN_HISTORY_REQUEST,
  UPDATE_LOAN_HISTORY_SUCCESS,
  UPDATE_LOAN_HISTORY_FAILURE,
} from '~/store/updateLoanHistory/action';

import { Spinner } from '~/components';
const { Item: FormItem } = Form;
const { toMoneyForm, toNumber } = masked;

class LoanHistoryFormModal extends Component {
  state = {
    personId: undefined,
    loanHistoryId: undefined,
    contractDate: undefined,
    paidInstallments: undefined,
    bankNumber: undefined,
    numberOfInstallments: undefined,
    installmentValue: undefined,
    contractValue: undefined,
    outstandingBalance: undefined,
    reservationKey: undefined,
  };

  componentDidMount() {
    const {
      loanHistoryId,
      contractDate,
      paidInstallments,
      bankNumber,
      numberOfInstallments,
      installmentValue,
      contractValue,
      outstandingBalance,
      reservationKey,
    } = this.props.loanHistoryValues;

    document.addEventListener('keypress', this.handleKeyPress);

    this.setState(
      {
        personId: this.props.match.params.id,
        loanHistoryId,

        bankNumber,
        contractDate,
        paidInstallments,
        numberOfInstallments,
        installmentValue,
        contractValue,
        outstandingBalance,
        reservationKey,
      },
      () => {
        this.props.form.setFieldsValue({
          loanHistoryId: this.state.loanHistoryId,

          bankNumber: this.state.bankNumber,
          contractDate: this.state.contractDate
            ? moment(this.state.contractDate)
            : undefined,
          paidInstallments: this.state.paidInstallments,
          numberOfInstallments: this.state.numberOfInstallments,
          installmentValue: this.state.installmentValue
            ? this.state.installmentValue.toFixed(2)
            : this.state.installmentValue,
          contractValue: this.state.contractValue
            ? this.state.contractValue.toFixed(2)
            : this.state.contractValue,
          outstandingBalance: this.state.outstandingBalance
            ? this.state.outstandingBalance.toFixed(2)
            : this.state.outstandingBalance,
          reservationKey: this.state.reservationKey,
        });
      },
    );
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress);
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.onPressSaveLoanHistory();
    }
  };

  stripMoneyValue = value => {
    //Divide por 100 devido a retirada da virgula
    return value.replace(/[.,\s]/g, '').replace(/^0+/, '') / 100;
  };

  onBankNumberChange = e => {
    this.setState({ bankNumber: e.target.value });
  };

  onContractDateChange = contractDate => {
    this.setState({ contractDate });
  };

  onPaidInstallmentsChange = e => {
    this.setState({ paidInstallments: e.target.value });
  };

  onNumberOfInstallmentsChange = e => {
    this.setState({ numberOfInstallments: e.target.value });
  };

  onInstallmentValueChange = e => {
    this.setState({ installmentValue: this.stripMoneyValue(e.target.value) });
  };

  onContractValueChange = e => {
    this.setState({ contractValue: this.stripMoneyValue(e.target.value) });
  };

  onOutstandingBalanceChange = e => {
    this.setState({ outstandingBalance: this.stripMoneyValue(e.target.value) });
  };

  onReservationKeyChange = e => {
    this.setState({ reservationKey: e.target.value });
  };

  onPressSaveLoanHistory = e => {
    if (e) e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        if (this.state.loanHistoryId) {
          this.props.onPressUpdateLoanHistory(this.state);
        } else {
          this.props.onPressRegisterLoanHistory(this.state);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.onPressSaveLoanHistory}>
        <Spinner spinning={this.props.isSaving} />
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 7, offset: 0 }}>
            <span>N° Banco:</span>
            <FormItem>
              {getFieldDecorator('bankNumber', {
                rules: [
                  {
                    required: true,
                    message: 'Informe o número do banco',
                  },
                  {
                    pattern: /(([0-9])*)$/g,
                    message: 'Digite um valor válido, sem iniciar com 0',
                  },
                ],
                normalize: toNumber,
              })(
                <Input
                  type='text'
                  onChange={e => this.onBankNumberChange(e)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
            <span>Data do Contrato:</span>
            <FormItem>
              {getFieldDecorator('contractDate', {
                rules: [
                  {
                    required: true,
                    message: 'Informe a data do contrato',
                  },
                ],
              })(
                <DatePicker
                  onChange={this.onContractDateChange}
                  locale={locale}
                  format='DD/MM/YYYY'
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
            <span>Valor do Contrato:</span>
            <FormItem>
              {getFieldDecorator('contractValue', {
                rules: [
                  {
                    required: true,
                    message: 'Digite o valor do contrato',
                  },
                ],
                normalize: toMoneyForm,
              })(
                <Input
                  addonBefore='R$'
                  type='text'
                  onChange={this.onContractValueChange}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 7, offset: 0 }}>
            <span>Valor de Parcela:</span>
            <FormItem>
              {getFieldDecorator('installmentValue', {
                rules: [
                  {
                    required: true,
                    message: 'Digite o valor de parcela do contrato',
                  },
                ],
                normalize: toMoneyForm,
              })(
                <Input
                  addonBefore='R$'
                  type='text'
                  onChange={this.onInstallmentValueChange}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
            <span>N° de Parcelas:</span>
            <FormItem>
              {getFieldDecorator('numberOfInstallments', {
                rules: [
                  {
                    required: true,
                    message: 'Informe o número de parcelas',
                  },
                  {
                    pattern: /^([1-9]([0-9])*)$/g,
                    message: 'Digite um valor válido, sem iniciar com 0',
                  },
                ],
                normalize: toNumber,
              })(
                <Input
                  type='text'
                  onChange={e => this.onNumberOfInstallmentsChange(e)}
                />,
              )}
            </FormItem>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
            <span>Parcelas Pagas:</span>
            <FormItem>
              {getFieldDecorator('paidInstallments', {
                rules: [
                  {
                    required: true,
                    message: 'Informe o número de parcelas pagas',
                  },
                  {
                    pattern: /^([1-9]([0-9])*)$/g,
                    message: 'Digite um valor válido',
                  },
                ],
                normalize: toNumber,
              })(
                <Input
                  type='text'
                  onChange={e => this.onPaidInstallmentsChange(e)}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 7, offset: 0 }}>
            <span>Saldo Devedor:</span>
            <FormItem>
              {getFieldDecorator('outstandingBalance', {
                rules: [
                  {
                    required: true,
                    message: 'Informe o saldo devedor',
                  },
                ],
                normalize: toMoneyForm,
              })(
                <Input
                  addonBefore='R$'
                  type='text'
                  onChange={e => this.onOutstandingBalanceChange(e)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
            <span>Chave de Reserva:</span>
            <FormItem>
              {getFieldDecorator('reservationKey', {
                rules: [
                  {
                    required: true,
                    message: 'Informe a chave de reserva',
                  },
                ],
              })(<Input type='text' onChange={this.onReservationKeyChange} />)}
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: '18px' }}>
          <Col xs={{ span: 2 }}>
            <Button
              type='primary'
              onClick={this.onPressSaveLoanHistory}
              disabled={this.props.isSaving}
            >
              {this.props.isSaving ? 'Salvando...' : 'Salvar'}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

LoanHistoryFormModal.defaultProps = {};

function onPressRegisterLoanHistory({ dispatch, match }) {
  return values => {
    dispatch(registerLoanHistory(values));
  };
}

function onPressUpdateLoanHistory({ dispatch, match }) {
  return values => {
    dispatch(updateLoanHistory(values));
  };
}

export function mapStateToProps(state) {
  return {
    registerLoanHistory: state.getIn(['registerLoanHistory']),
    updateLoanHistory: state.getIn(['updateLoanHistory']),
  };
}

export function receiveChanges(
  prevProps,
  {
    dispatch,
    match,
    history,
    registerLoanHistory,
    updateLoanHistory,
    setIsSaving,
  },
) {
  if (Immutable.is(prevProps.updateLoanHistory, updateLoanHistory) === false) {
    switch (updateLoanHistory.getIn(['type'])) {
      case UPDATE_LOAN_HISTORY_REQUEST: {
        setIsSaving(true);
        return true;
      }
      case UPDATE_LOAN_HISTORY_SUCCESS: {
        setIsSaving(false);
        return true;
      }
      case UPDATE_LOAN_HISTORY_FAILURE: {
        setIsSaving(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (
    Immutable.is(prevProps.registerLoanHistory, registerLoanHistory) === false
  ) {
    switch (registerLoanHistory.getIn(['type'])) {
      case REGISTER_LOAN_HISTORY_REQUEST: {
        setIsSaving(true);
        return true;
      }
      case REGISTER_LOAN_HISTORY_SUCCESS: {
        setIsSaving(false);
        return true;
      }
      case REGISTER_LOAN_HISTORY_FAILURE: {
        setIsSaving(false);
        return true;
      }
      default:
        return false;
    }
  }

  return false;
}

export function propagateStateChangeToProps() {}

const WrappedLoanHistoryFormModal = Form.create()(LoanHistoryFormModal);

export default compose(
  withRouter,
  connect(mapStateToProps),
  withState('isSaving', 'setIsSaving', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onPressRegisterLoanHistory,
    onPressUpdateLoanHistory,
  }),
)(WrappedLoanHistoryFormModal);
