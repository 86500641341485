import { Button } from '~/ui/components';
import styled from 'styled-components';
import { themeColor, themeToggleColor, themeToggleValue } from '~/common/utils/theme';

export const DetailsButton = styled(Button).attrs(({ theme }) => ({
  iconOnly: true,
  size: 'sm',
  icon: 'CaretRight',
  variant: themeToggleValue(theme, 'contained', { dark: 'outline' }),
  customColor: themeToggleColor(theme, 'brand.secondary', { dark: 'brand.primaryAlt' }),
}))`
  height: 24px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;

  svg {
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    width: 12px;
    height: 12px;
  }

  &:disabled {
    opacity: 0.4;

    svg {
      color: #b0b0b0;
    }
  }
`;
