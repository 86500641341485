import styled from 'styled-components';

export const RegistrationLogList = styled.div`
  overflow-y: auto;
  max-height: 66vh;

  & > *:not(:last-child) {
    padding-bottom: 13px;
    border-bottom: 1px dashed #727272;
    margin-bottom: 16px;
  }
`;
