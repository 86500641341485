import { createWrapperComponent } from '~/components/AsyncActionWrapper';
import masked from '~/common/masked';
import removeDoubleSpaces from '~/common/removeDoubleSpaces';
import { listTagTypesPerson } from '~/store/tagTypes/actions';

const { toNumber } = masked;

export default [
  {
    title: 'Nome:',
    placeholder: 'Nome',
    textValueKey: 'name',
    type: 'text',
    normalize: (str: string) => removeDoubleSpaces(str.replace(/\.|-/g, '')),
  },
  {
    title: 'CPF:',
    placeholder: 'CPF',
    textValueKey: 'document',
    type: 'text',
    normalize: (str: string) => removeDoubleSpaces(str.replace(/\.|-/g, '')),
  },
  {
    title: 'Matricula:',
    placeholder: 'Matricula',
    textValueKey: 'enrollment',
    type: 'text',
    normalize: (str: string) => removeDoubleSpaces(str.replace(/\.|-/g, '')),
  },
  {
    title: 'E-mail:',
    placeholder: 'E-mail',
    textValueKey: 'email',
    type: 'text',
  },
  {
    title: 'Período:',
    type: 'period',
  },
  {
    title: 'Tag:',
    selectValueKey: 'tagTypes',
    placeholder: 'Selecione',
    type: 'selection',
    displayNormalize: (item) => item.name,
    extractKey: (item) => item.id,
    wrapperComponent: createWrapperComponent(listTagTypesPerson, 'options'),
    extra: {
      mode: 'multiple',
    },
  },
  {
    title: 'Telefone:',
    placeholder: 'Telefone',
    textValueKey: 'phoneNumber',
    type: 'text',
    normalize: toNumber,
  },
];
