import { Button, Table, Typography } from '~/ui/components';
import styled, { css } from 'styled-components';
import { fadeIn } from '~/ui/theme/animations';
import { CellProps } from '~/ui/components/Table/Table.styled';

export const TableTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  element: 'h5',
  weight: 500,
  color: theme.colors.gray.main,
}))`
  display: flex;
  align-items: baseline;
  margin: 0 28px;
  padding-top: 16px;
`;

type InfoContainerProps = {
  noBorder?: boolean;
};

export const InfoContainer = styled.div<InfoContainerProps>`
  padding: 16px 0;
  display: flex;
  gap: 22px;
  flex-wrap: wrap;

  border-bottom: ${({ noBorder }) => (noBorder ? 0 : '1px')} solid
    ${({ theme }) => theme.colors.primary[100]};
`;

export const SectionTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  color: theme.colors.gray.main,
  weight: 500,
  element: 'h3',
}))``;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 16px 0;

  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const SmallTable = styled(Table)`
  padding: 0;

  tr {
    th,
    td {
      font-size: ${({ theme }) => theme.typography.types.caption.size};
      line-height: ${({ theme }) => theme.typography.types.caption.lineHeight};
    }
  }
`;

export const ActionButton = styled(Button).attrs(({ theme }) => ({
  color: theme.colors.primary[100],
  iconOnly: true,
  variant: 'text',
  size: 'sm',
  customColor: theme.colors.primary.main,
}))``;

export const EventButton = styled(Button).attrs(({ theme }) => ({
  color: theme.colors.primary[100],
  variant: 'text',
  size: 'sm',
  customColor: theme.colors.primary.main,
}))`
  height: auto;
  padding: 0;
  width: auto;
  min-width: min-content;

  .typography {
    font-size: 12px;
    font-weight: 500;
  }
`;

type TruncatedCellProps = {
  minSize?: number;
  maxSize?: number;
  align: 'left' | 'center' | 'right';
};

export const TruncatedCell = styled.div<TruncatedCellProps>`
  justify-content: ${({ align }) => align || 'left'};
  align-items: center;
  min-width: ${({ minSize }) => minSize || 'none'}px;
  max-width: ${({ maxSize }) => maxSize || 'none'}px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const CustomTable = styled(SmallTable)`
  border-bottom: 1px dashed #dddddd;
  margin-bottom: 16px;
  padding: 24px 28px 28px 28px;
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 16px 0;
`;
