import React from 'react';
import { EnumMappers, formatAgency } from '~/common';
import masked from '~/common/masked';
import moment from 'moment/moment';
import { ClearSaleScore } from '~/components';
import { consignee } from '~/common/constants';

const { OperationStepType, AccountType, ValidationStatusUnico, ContractStatus, LoanType } =
  EnumMappers;

const { toPersonDocument, toPhone, toNumber, toMoney, toDate, toPercentage } = masked;

const ALERT_COLORS = {
  RED: '#c0392b',
  ORANGE: '#eb8934',
  GREEN: '#52b771',
};

type Block = {
  columns: Array<any>;
  title: string;
};

type DefaultFields = Array<Block>;

const fields: DefaultFields = [
  {
    title: 'Status score',
    columns: [
      [
        {
          label: 'Status lógico',
          actionType: ({ pendencies }) => (pendencies?.length > 1 ? 'open_modal' : 'copy'),
          clickable: ({ pendencies }) => pendencies?.length > 1,
          value: ({ contractStatus, contractStatus2, pendencies }) => {
            if (pendencies?.length > 1) {
              return `${pendencies.length} status pendentes, clique para exibir`;
            }

            return contractStatus2 ? `${contractStatus} - ${contractStatus2}` : contractStatus;
          },
          modalName: 'PENDENCIES',
          modalParams: ({ pendencies }) => ({ pendencies }),
        },
      ],
      [
        {
          label: 'Passo da esteira',
          actionType: 'open_modal',
          value: ({ operationStep }) => OperationStepType(operationStep),
          clickable: true,
          modalName: 'STEP_MODAL',
        },
        {
          label: 'Score UNICO',
          actionType: 'copy',
          tooltip: ({ unicoValidationStatus }) => ValidationStatusUnico(unicoValidationStatus),
          value: ({ scoreUnico, unicoValidationStatus }) =>
            ['VALID', 'INCONCLUSIVE', 'INVALID'].includes(unicoValidationStatus) ? scoreUnico : '-',
          valueColor: (value: number) => (value > 10 ? ALERT_COLORS.GREEN : ALERT_COLORS.RED),
        },
      ],
      [
        {
          label: 'Status da consignatária pessoa',
          actionType: 'copy',
          value: 'person.consigneeLastStatus',
        },
        {
          label: 'Score clear sale',
          actionType: 'open_modal',
          value: ({ scoreClearSale, person }) => (
            <ClearSaleScore score={scoreClearSale} scoreHistory={person?.clearSaleScores} />
          ),
          valueColor: (value: string, fields: any) => {
            const valueColor = parseFloat(fields?.scoreClearSale);
            if (valueColor > 80) {
              return ALERT_COLORS.RED;
            }
            if (valueColor > 50) {
              return ALERT_COLORS.ORANGE;
            }
            return ALERT_COLORS.GREEN;
          },
        },
      ],
      [
        {
          label: 'Status da consignatária',
          actionType: 'copy',
          value: ({ consigneeLastStatus, consigneeLastStatusRefPort, loanType }) => {
            if (!consigneeLastStatusRefPort || loanType !== 'PORTABILITY') {
              return consigneeLastStatus;
            }
            return consigneeLastStatusRefPort;
          },
        },
        {
          label: 'Verificado com datavalid',
          actionType: 'open_modal',
          value: ({ infos }) => infos?.valid !== undefined && (infos?.valid ? 'SIM' : 'NÃO'),
          clickable: true,
          modalName: 'DATAVALID',
          modalParams: ({ infos, person }) => ({ infos, personId: person?.id }),
        },
      ],
    ],
  },
  {
    title: 'Contas de pagamento',
    columns: [
      [
        {
          label: 'Informações da conta',
          actionType: 'copy',
          value: 'bankName',
        },
        {
          label: 'Tipo de conta do benefício',
          actionType: 'copy',
          value: 'benefitAccountType',
        },
        {
          label: 'Matricula',
          actionType: 'copy',
          value: 'person.enrollment',
        },
      ],
      [
        {
          label: 'Tipo de conta',
          actionType: 'copy',
          value: ({ accountType }) => (accountType ? AccountType(accountType) : 'Não disponível'),
        },
        {
          label: 'Código do local de trabalho',
          actionType: 'copy',
          value: 'person.workCode',
        },
        {
          label: 'status da reserva',
          actionType: 'copy',
          value: 'reservationStatus',
        },
      ],
      [
        {
          label: 'Agência',
          actionType: 'copy',
          value: ({ agency }) => formatAgency(agency),
        },
        {
          label: 'Convênio',
          actionType: 'copy',
          value: 'person.agreementName',
        },
        {
          label: 'Produto',
          actionType: 'product_tags',
          value: ({ usedFutureMargin, loanType }) => ({ usedFutureMargin, loanType }),
        },
      ],
      [
        {
          label: 'Conta',
          actionType: 'copy',
          value: ({ checkingAccount, checkingAccountDigit }) =>
            checkingAccount && checkingAccountDigit
              ? `${checkingAccount}-${checkingAccountDigit}`
              : 'Não Disponível',
        },
        {
          label: 'Senha cadastrada',
          actionType: 'copy',
          tooltip: ({ person }) => {
            if (!person?.acceptConsentDate) {
              return 'O cliente precisa conceder a permissão de visualização da senha no app da Meutudo.';
            }

            return (
              !!person?.agreementPasswordLastUpdate &&
              moment(person?.agreementPasswordLastUpdate, 'DD/MM/YYYY HH:mm:ss').format(
                'DD/MM/YYYY [às] HH:mm',
              )
            );
          },
          value: ({ person, showAgreementPassword }) => {
            if (!person?.acceptConsentDate) {
              return 'Cliente não deu permissão';
            }

            if (showAgreementPassword) {
              return person.agreementPassword || 'Indisponível';
            }

            return !!person?.agreementPassword || person?.hasAgreementPassword ? 'Sim' : 'Não';
          },
        },
      ],
    ],
  },
  {
    title: 'Informações pessoais',
    columns: [
      [
        {
          label: 'CPF',
          actionType: 'copy',
          value: ({ person: { document } }) => toPersonDocument(document),
        },
        {
          label: 'RG',
          actionType: 'copy',
          value: 'person.registrationNumber',
        },
      ],
      [
        {
          label: 'Nome da mãe',
          actionType: 'copy',
          value: 'person.motherName',
        },
        {
          label: 'Data de nascimento',
          actionType: 'open_modal',
          value: ({ person }) => toDate(person?.birthDate),
          clickable: true,
          modalName: 'UPDATE_BIRTHDATE',
          modalParams: ({ person }) => ({ birthDate: person?.birthDate, personId: person?.id }),
        },
      ],
      [
        {
          label: 'Telefone',
          actionType: 'copy',
          value: ({ person: { phone } }) => toPhone(phone),
          valueToCopy: (value) => toNumber(value) || '',
        },
        {
          label: 'E-mail',
          actionType: 'copy',
          value: 'person.email',
        },
      ],
      [],
    ],
  },
  {
    title: 'Informações do contrato',
    columns: [
      [
        {
          label: 'Valor líquido',
          actionType: 'copy',
          value: ({ loanStatus, netValue, netValueApproved }) =>
            toMoney(
              loanStatus !== 'CCBSIGNED' && loanStatus !== 'PAID'
                ? netValue
                : netValue || netValueApproved,
            ),
        },
        {
          label: 'Data inicial contrato',
          actionType: 'copy',
          value: ({ contractInitialDate }) => toDate(contractInitialDate),
        },
        {
          label: 'IOF',
          actionType: 'copy',
          value: ({ iof }) => toMoney(iof),
        },
        {
          label: 'Data do pagamento ref port',
          actionType: 'copy',
          value: ({ paymentDateRefPort }) =>
            paymentDateRefPort ? toDate(paymentDateRefPort) : 'Não consta',
        },
        {
          label: 'Status da reserva',
          actionType: 'copy',
          value: 'reservationStatus',
        },
      ],
      [
        {
          label: 'Valor depósito',
          actionType: 'copy',
          value: ({ loanStatus, valueForDeposit, valueForDepositApproved }) =>
            toMoney(
              loanStatus !== 'CCBSIGNED' && loanStatus !== 'PAID'
                ? valueForDeposit
                : valueForDeposit || valueForDepositApproved,
            ),
        },
        {
          label: 'Data assinatura contrato',
          actionType: 'copy',
          value: ({ contractDate, showContractDate }) =>
            showContractDate ? toDate(contractDate) : 'Indisponível',
        },
        {
          label: 'Status de validação',
          actionType: 'copy',
          value: ({ unicoValidationStatus }) => ValidationStatusUnico(unicoValidationStatus),
        },
        {
          label: 'Atendente',
          actionType: 'copy',
          value: ({ contractOwner }) => contractOwner || 'Não atribuído',
        },
        {
          label: 'Contratação em lote',
          actionType: 'group_contracts',
          value: 'groupContracts',
        },
      ],
      [
        {
          label: 'Parcelas',
          actionType: ({ loanType }) =>
            loanType === LoanType.LOAN_TYPES.FGTS_NEW ? 'open_modal' : 'copy',
          clickable: ({ loanType }) => loanType === LoanType.LOAN_TYPES.FGTS_NEW,
          modalName: 'FGTS_INSTALLMENTS',
          modalParams: ({ installments }) => ({ installments }),
          value: ({
            loanStatus,
            numberOfInstallments,
            installmentValue,
            installmentValueApproved,
            loanType,
          }) => {
            if (loanType === LoanType.LOAN_TYPES.FGTS_NEW) {
              return `${numberOfInstallments} parcelas anuais`;
            }
            if (loanStatus !== 'CCBSIGNED' && loanStatus !== 'PAID') {
              return `${numberOfInstallments} x ${toMoney(installmentValue)}`;
            }
            return `${numberOfInstallments} x ${toMoney(
              installmentValue || installmentValueApproved,
            )}`;
          },
        },
        {
          label: 'Data de pagamento',
          actionType: 'copy',
          value: ({ paymentDate }) => toDate(paymentDate),
        },
        {
          label: 'Chave do contrato',
          actionType: 'copy',
          value: 'reservationKey',
        },
        {
          label: 'Status do contrato',
          actionType: 'copy',
          value: ({ loanStatus }) => ContractStatus(loanStatus),
        },
        {
          label: 'Consignatária',
          actionType: 'copy',
          value: ({ financialInstitution }) => consignee[financialInstitution?.bankNumber] || '-',
        },
      ],
      [
        {
          label: 'Taxa nominal mês',
          actionType: 'copy',
          value: ({ nominalRatePerMonth }) => toPercentage(nominalRatePerMonth),
        },
        {
          label: 'Valor bruto',
          actionType: 'copy',
          value: ({ grossValue }) => toMoney(grossValue),
        },
        {
          label: 'N do contrato (refin da port)',
          actionType: ({ financialInstitution, financialInstitutionKeyPort }) =>
            financialInstitution?.bankNumber === '326' && !!financialInstitutionKeyPort
              ? 'copy'
              : 'open_modal',
          clickable: ({ financialInstitution, financialInstitutionKeyPort }) =>
            financialInstitution?.bankNumber !== '326' && !!financialInstitutionKeyPort,
          modalName: 'UPDATE_CONTRACT_NUMBER',
          modalParams: ({ financialInstitutionKeyPort }) => ({
            initialValue: financialInstitutionKeyPort,
            type: 'REFIN_PORT',
          }),
          value: 'financialInstitutionKeyPort',
        },
        {
          label: 'N do contrato',
          actionType: ({ financialInstitution, financialInstitutionKey }) =>
            financialInstitution?.bankNumber === '326' && !!financialInstitutionKey
              ? 'copy'
              : 'open_modal',
          clickable: ({ financialInstitution, financialInstitutionKey }) =>
            financialInstitution?.bankNumber !== '326' && !!financialInstitutionKey,
          modalName: 'UPDATE_CONTRACT_NUMBER',
          modalParams: ({ financialInstitutionKey }) => ({ initialValue: financialInstitutionKey }),
          value: 'financialInstitutionKey',
        },
      ],
    ],
  },
];

export default fields;
