import styled from 'styled-components';

import { Tag, Typography, Input } from '~/ui/components';

interface IContainerProps {
  $alignItems: 'start' | 'center' | 'end';
}

interface IImageContainerProps {
  $imageWidth: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems};
  text-align: ${({ $alignItems }) => $alignItems};
`;

export const ImageContainer = styled.div<IImageContainerProps>`
  max-width: ${({ $imageWidth }) => $imageWidth};
`;

export const Image = styled.img`
  width: 100%;
  margin-top: ${({ $imageMargin }) => $imageMargin};
  margin-bottom: ${({ $imageMargin }) => $imageMargin};
`;

export const TagContainer = styled.div<IContainerProps>`
  display: flex;
  margin-bottom: 12px;
`;

export const CustomTag = styled(Tag)`
  height: 20px;
  size: 12px;
  margin-right: 8px;
`;

export const Title = styled(Typography).attrs({
  type: 'paragraphLarge',
  element: 'h1',
  weight: '600',
})`
  margin-bottom: 8px;
`;

export const Text = styled(Typography).attrs({
  type: 'caption',
  element: 'h2',
  weight: '500',
})`
  margin-bottom: 16px;
  align-items: ${({ $alignItems }) => $alignItems};
`;

export const InputContent = styled.div`
  width: 100%;
`;

export const CustomizedInput = styled((props) => <Input {...props} />)`
  height: 56px !important;
  max-width: 400px;
  background-color: transparent !important;
  margin-bottom: 8px;
`;

export const Span = styled(Typography).attrs({
  type: 'footnote',
  element: 'span',
})`
  display: flex;
  color: #666666;
  margin-bottom: 12px;
`;
