/* @flow */

export const REQUEST_RESET_PERSON_REQUEST = 'REQUEST_RESET_PERSON_REQUEST';
export const REQUEST_RESET_PERSON_SUCCESS = 'REQUEST_RESET_PERSON_SUCCESS';
export const REQUEST_RESET_PERSON_FAILURE = 'REQUEST_RESET_PERSON_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: REQUEST_RESET_PERSON_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: REQUEST_RESET_PERSON_FAILURE,
      payload: resource,
    });
  };
}

export default function refuseContract(
  personId,
  password,
  personResetReasonId,
  openResetReasonDescription,
) {
  return dispatch => {
    dispatch({
      type: REQUEST_RESET_PERSON_REQUEST,
      payload: {
        id: personId,
        password,
        personResetReasonId,
        openResetReasonDescription,
      },
    });
  };
}
