import { createAsyncAction } from 'react-redux-await-control';

import { telli } from '~/services';
import { handleErrors } from '~/common/utils/errorHandler';
import { message } from 'antd';

type ProposalType = 'mtproposal' | 'qiproposal';

export const listActionHistoric = createAsyncAction('LIST_ACTION_HISTORIC', {
  saveResult: true,
  context: {
    responseParser: ({ data }) => data.resource,
    errorParser: () => [],
    request: ({ actionProposalId }) =>
      telli.get(`/admin/mtproposalaction/${actionProposalId}/statushistory`),
  },
});

export const listQiActionHistoric = createAsyncAction('LIST_QITECH_ACTION_HISTORIC', {
  saveResult: true,
  initialValue: [],
  context: {
    errorParser: () => [],
    request: ({ qiActionProposalId }) =>
      telli.get(`/admin/qiproposalstatushistory/${qiActionProposalId}/proposalstatushistorys`),
  },
});

export const cancelQiProposal = createAsyncAction('CANCEL_QITECH_PROPOSAL', {
  context: {
    responseParser: ({ data }) => data.resource,
    errorParser: () => [],
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao tentar cancelar a proposta' }),
    request: ({ proposalId }) => telli.post(`/admin/qiproposal/${proposalId}/cancelproposalqitech`),
  },
});

export const revokeCancelQiProposal = createAsyncAction('REVOKE_CANCEL_QI_PROPOSAL', {
  context: {
    responseParser: ({ data }) => data.resource,
    errorParser: () => [],
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao tentar revogar cancelamento da proposta' }),
    request: ({ proposalId }) => telli.post(`/admin/qiproposal/${proposalId}/cancelRevoke`),
  },
});

type ProposalRequest = {
  proposalId: string;
  proposalType: ProposalType;
};

export const signContract = createAsyncAction('SIGN_CONTRACT', {
  saveResult: true,
  context: {
    request: ({ proposalId, proposalType }: ProposalRequest) =>
      telli.post(`/admin/${proposalType}/${proposalId}/cleansignature`),
    onRequestSuccess: () => message.success('Sucesso!'),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao tentar assinar contrato' }),
  },
});

export const forceResubmitPaymentQiContract = createAsyncAction(
  'FORCE_RESUBMIT_PAYMENT_QI_CONTRACT',
  {
    context: {
      request: ({ proposalId }) =>
        telli.post(`/admin/qiproposal/${proposalId}/forceresubmitpayment`),
      onRequestSuccess: () => message.success('Sucesso!'),
      onRequestError: (err) =>
        handleErrors(err, { defaultMessage: 'Erro ao tentar forçar reenvio de pagamento' }),
    },
  },
);

export const cancelQiReservation = createAsyncAction('CANCEL_RESERVATION_QITECH', {
  context: {
    request: ({ proposalId }) => telli.post(`/admin/qiproposal/${proposalId}/cancelreservation`),
    onRequestSuccess: () => message.success('Sucesso!'),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao tentar desaverbar proposta' }),
  },
});

export const listActionProposals = createAsyncAction('LIST_ACTIONS_PROPOSAL', {
  saveResult: true,
  initialValue: [],
  context: {
    errorParser: () => [],
    request: ({ proposalId }) => telli.get(`/admin/mtproposalaction/${proposalId}/actions`),
  },
});

export const listQIActionProposals = createAsyncAction('LIST_QI_ACTIONS_PROPOSAL', {
  saveResult: true,
  initialValue: [],
  context: {
    errorParser: () => [],
    request: ({ qiProposalId }) =>
      telli.get(`/admin/qiproposalaction/${qiProposalId}/proposalsactions`),
  },
});

type ContractProposalRequest = {
  contractId: string;
  proposalType: ProposalType;
};

export const listContractProposals = createAsyncAction('LIST_CONTRACT_PROPOSALS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ contractId, proposalType }: ContractProposalRequest) =>
      telli.get(`/admin/${proposalType}/${contractId}/proposals`),
    errorParser: () => ({}),
  },
});

export const cancelInternalProposal = createAsyncAction('CANCEL_INTERNAL_PROPOSAL', {
  context: {
    request: ({ contractId, type }) =>
      telli.post(
        `/admin/${type === 'PAB' ? 'mtproposal' : 'qiproposal'}/${contractId}/cancelproposal`,
      ),
    onRequestSuccess: () => message.success('Sucesso!'),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Erro ao tentar cancelar proposta interna' }),
  },
});

export const listReturnsParati = createAsyncAction('LIST_RETURNS_PARATI', {
  saveResult: true,
  initialValue: {
    paratiAccount: null,
    paratiAccountReturns: null,
    paratiPreProposalReturns: [],
    paratiPreProposals: [],
    paratiProposalReturns: [],
    paratiProposals: [],
  },
  context: {
    request: ({ contractId }) =>
      telli.get(`/admin/contractcustom/contracts/${contractId}/consigneereturns`),
  },
});

export const updateAccountParati = createAsyncAction('UPDATE_ACCOUNT_PARATI', {
  context: {
    onRequestSuccess: () => message.success('Atualizada com sucesso!'),
    request: ({ contractId, ...rest }) =>
      telli.get(`/admin/contractcustom/contracts/${contractId}/updateaccountparati`, {
        params: rest,
      }),
  },
});

export const removePreProposal = createAsyncAction('REMOVE_PRE_PROPOSAL', {
  context: {
    request: ({ id }) => telli.post(`/admin/contractcustom/contracts/${id}/cancelpreproposal`),
  },
});

export const removeProposal = createAsyncAction('REMOVE_PROPOSAL', {
  context: {
    request: ({ id }) => telli.post(`/admin/contractcustom/contracts/${id}/cancelproposal`),
  },
});

export const listStubbornReturnsParati = createAsyncAction('LIST_RETURNS_PARATI_STUBBORN', {
  saveResult: true,
  initialValue: [],
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data?.resource?.events || [],
    request: ({ contractId, returnId }) =>
      telli.get(`/admin/contractcustom/contracts/${contractId}/consigneereturns/${returnId}`),
  },
});
