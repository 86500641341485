import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { Tag, Tooltip } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import { getTicketStatus } from '~/store/tickets/actions';
import {
  InfoLabel,
  ItemContainer,
  InfoValue,
  StatusIndicator,
  TicketId,
} from './ResultItem.styled';

export const TicketStatusColors = {
  default: { bgColor: '#F5F5F5', textColor: '#1A1A1A' },
  Encaminhado: { bgColor: '#D6E4FF', textColor: '#0100B2' },
  Resolvido: { bgColor: '#E5FFE5', textColor: '#005C00' },
  Aberto: { bgColor: '#FFF1E0', textColor: '#B74A01' },
  'Em Análise': { bgColor: '#FFF1E0', textColor: '#B74A01' },
};

export const AssessmentStatusColors = {
  DONE: 'positive',
  IN_PROGRESS: 'alert',
  NONE: 'neutral',
};

export const AssessmentStatusNames = {
  DONE: 'Avaliado',
  IN_PROGRESS: 'Em andamento',
  NONE: 'Não avaliado',
};

export type ResultItemProps = {
  ticketId: number;
  createdBy: string;
  team: string;
  channel: string;
  reason: string;
  resolutionStatus: string;
  assessmentStatus: string;
  date: string;
  selected?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export function ResultItem({
  ticketId,
  createdBy,
  team,
  channel,
  reason,
  resolutionStatus,
  date,
  selected,
  assessmentStatus,
  ...props
}: ResultItemProps) {
  const getTicketStatusControl = useAwaitControl(getTicketStatus);
  const statusList = getTicketStatusControl.result();

  const renderStatus = useCallback(
    (status: string) => {
      const colors = TicketStatusColors[status] || TicketStatusColors.default;
      return (
        <Tooltip placement="bottomLeft" content={status} small>
          <Tag small rounded ellipsis {...colors}>
            {status}
          </Tag>
        </Tooltip>
      );
    },
    [statusList],
  );

  useEffect(() => {
    if (!statusList) getTicketStatusControl.start();
  }, []);

  return (
    <ItemContainer selected={selected} data-testid="result-ticket-item" {...props}>
      <div>
        <Tooltip placement="bottomLeft" content={AssessmentStatusNames[assessmentStatus]} small>
          <StatusIndicator statusColor={AssessmentStatusColors[assessmentStatus]} />
        </Tooltip>
        <TicketId data-testid="result-ticket-id">#{ticketId}</TicketId>
      </div>

      <div>
        <InfoLabel>Criado por:</InfoLabel>
        <InfoValue data-testid="result-ticket-created-by">{createdBy}</InfoValue>
      </div>

      <div>
        <InfoLabel>Time:</InfoLabel>
        <InfoValue data-testid="result-ticket-team">{team || '-'}</InfoValue>
      </div>

      <div>
        <InfoLabel>Canal:</InfoLabel>
        <InfoValue data-testid="result-ticket-channel">{channel || '-'}</InfoValue>
      </div>

      <div>
        <InfoLabel>Motivo:</InfoLabel>
        <InfoValue data-testid="result-ticket-reason">{reason || '-'}</InfoValue>
      </div>

      <div>
        <InfoLabel>Status da Resolução:</InfoLabel>
        <InfoValue data-testid="result-ticket-status">{renderStatus(resolutionStatus)}</InfoValue>
      </div>

      <div>
        <InfoLabel>Data:</InfoLabel>
        <InfoValue data-testid="result-ticket-date">
          {moment(date).format('DD/MM/YYYY [às] HH:mm')}
        </InfoValue>
      </div>
    </ItemContainer>
  );
}
