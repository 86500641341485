import { Map } from 'immutable';

import {
  GET_CONTRACT_FILES_REQUEST,
  GET_CONTRACT_FILES_FAILURE,
  GET_CONTRACT_FILES_SUCCESS,
} from './action';

const initialState = Map({
  type: '',
  payload: {},
});

function reducer(state = initialState, action) {
  const { data, type, loading } = action;

  if (type === GET_CONTRACT_FILES_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['loading'], () => loading)
      .updateIn(['data'], () => data);
  }

  if (type === GET_CONTRACT_FILES_FAILURE) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['loading'], () => loading)
      .setIn(['payload'], payload);
  }

  if (type === GET_CONTRACT_FILES_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['loading'], () => loading)
      .setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
