/* @flow */

export const DELETE_LOAN_HISTORY_REQUEST = 'DELETE_LOAN_HISTORY_REQUEST';
export const DELETE_LOAN_HISTORY_SUCCESS = 'DELETE_LOAN_HISTORY_SUCCESS';
export const DELETE_LOAN_HISTORY_FAILURE = 'DELETE_LOAN_HISTORY_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: DELETE_LOAN_HISTORY_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: DELETE_LOAN_HISTORY_FAILURE,
      payload: resource,
    });
  };
}

export default function deleteLoanHistory(params) {
  return dispatch => {
    dispatch({
      type: DELETE_LOAN_HISTORY_REQUEST,
      payload: params,
    });
  };
}
