/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { CREATE_USER_REQUEST, success, failure } from './action';

const prepare = ({ payload }) => {
  const user = {};

  Object.entries(payload).forEach(item => {
    const [key, value] = item;

    if (typeof value === 'string') {
      user[key] = value.trim();
    } else {
      user[key] = value;
    }
  });

  return user;
};

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(CREATE_USER_REQUEST)
    .map(prepare)
    .mergeMap(payload =>
      AjaxRequest.open(
        'post',
        `/admin/usercustom/createuserbackoffice`,
        payload,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
