import React from 'react';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  &:hover {
    background: #eee;
    width: 95%;
  }
  min-height: 50px;
  border-radius: 4px;
`;

export const Label = styled.div`
  display: block;
  color: #949494;
  font-size: 9px;
  letter-spacing: 0;
`;

export const Value = styled.div`
  font-weight: bold;
  color: #636878;
  font-size: 20px;
  letter-spacing: 0;
`;

export default function style({ unit }) {
  const color = '#636878';
  const fontFamily = 'Rubik, sans-serif';

  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: unit * 2,
    },
    content: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
    },
    preset__large: {
      color,
      fontSize: unit * 2,
      letterSpacing: 0,
    },
    preset__medium: {
      color,
      display: 'block',
      fontFamily,
      fontSize: unit * 1.7,
      fontWeight: 'bold',
    },

    // -> Elementos específicos:

    personName: {
      color,
      fontFamily,
      fontSize: '24px',
      fontWeight: '300',
      letterSpacing: 0,
      margin: 0,
    },
    personDocument: {
      width: '200px',
    },
    personEnrollment: {
      width: '150px',
    },
    personRegistrationNumber: {
      width: '150px',
    },

    keyInput: {
      display: 'block',
      color: '#949494',
      fontFamily,
      fontSize: unit * 0.9,
      letterSpacing: '0',
    },

    label: {
      minHeight: '50px',
    },

    labelHover: {
      ':hover': {
        background: '#eee',
        width: '95%',
      },
      minHeight: '50px',
      borderRadius: '4px',
    },

    financialInstitutionKeyInput: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    // -> Elementos para informações com maior foco:

    large: {
      width: '200px',
    },
    large__preset: {
      color: '#272D40',
      fontSize: unit * 1.8,
      letterSpacing: 0,
    },
    large__description: {
      marginLeft: unit,
      fontSize: unit * 1.4,
    },

    // -> Elementos de Informações Bancárias:

    bankImage: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      border: '1px solid #eee',
      borderRadius: '3px',
      height: '110px',
      marginBottom: '20px',
      width: '150px',
    },
    agency: {
      color: '#272D40',
      display: 'block',
      fontSize: unit * 2,
      letterSpacing: 0,
      lineHeight: 1,
    },
    account: {
      color: '#272D40',
      display: 'block',
      fontSize: unit * 2,
      letterSpacing: 0,
      lineHeight: 1,
    },
    account__preset: {
      display: 'block',
      margin: `${unit * 1.5}px 0`,
    },
    bankName: {
      color: '#272D40',
      display: 'block',
      fontFamily,
      fontSize: unit * 1.3,
      margin: `${unit * 1}px 0`,
      letterSpacing: 0,
      lineHeight: 1,
    },

    // -> Tipo de contrato:

    contractType: {
      background: '#95a5a6',
      boxShadow: 'inset 0 -1px 0 0 rgba(0,0,0,0.10)',
      borderRadius: 5,
      color: 'white',
      display: 'inline-block',
      marginBottom: unit,
      opacity: 0.65,
      padding: unit * 0.8,
      textTransform: 'uppercase',
      letterSpacing: '0.6px',
    },
    contractType__orange: {
      background: '#ffc64d',
    },
    contractType__red: {
      background: '#c0392b',
    },
    contractType__blue: {
      background: '#3498db',
    },

    // -> Elementos Globais:

    aside: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',
    },
    key: {
      display: 'block',
      color: '#949494',
      fontFamily,
      fontSize: unit * 0.9,
      letterSpacing: '0',
    },
    value: {
      width: 'calc(100% - 30px)',
    },
    value__preset: {
      color,
      fontSize: unit * 2,
      letterSpacing: 0,
    },
    preset: {
      color,
      fontSize: unit * 1.4,
      fontWeight: 'bold',
    },
    preset__green: {
      color: '#52b771',
      fontWeight: 'bold',
    },
    preset__red: {
      color: '#c0392b',
      fontWeight: 'bold',
    },
    input: {
      background: '#FFFFFF',
      boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.10)',
      borderRadius: 3,
      height: unit * 4,
      width: '90%',
    },
    contractNumberActionWrapper: {
      width: '92%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '-15px',
    },
  };
}
