// @flow
import React from 'react';
import { Spin } from 'antd';

import { LoadingContent } from './styled';

export default function Loading() {
  return (
    <LoadingContent>
      <Spin size='large' />
    </LoadingContent>
  );
}
