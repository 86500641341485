import React from 'react';
import { ActionSendFile } from '~/components';
import { isProduction } from '~/MainApp';

export default function SendBiometry({ onPressSendBiometry }) {
  if (isProduction()) return null;

  return (
    <ActionSendFile
      title='Enviar Foto Verdade'
      onSendFile={onPressSendBiometry}
    />
  );
}
