/* @flow */

export const REQUEST_ADDRESS_REQUEST = 'REQUEST_ADDRESS_REQUEST';
export const REQUEST_ADDRESS_SUCCESS = 'REQUEST_ADDRESS_SUCCESS';
export const REQUEST_ADDRESS_FAILURE = 'REQUEST_ADDRESS_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: REQUEST_ADDRESS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: REQUEST_ADDRESS_FAILURE,
      payload: resource,
    });
  };
}

export default function ApprovePersonRegistry(params) {
  return dispatch => {
    dispatch({
      type: REQUEST_ADDRESS_REQUEST,
      payload: params,
    });
  };
}
