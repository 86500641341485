import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

type LoadingProp = {
  loading?: boolean;
};

export const OpportunityContainer = styled.div<LoadingProp>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 84px);

  ${({ loading }) =>
    loading &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const OpportunityBody = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 24px;

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.element.secondary};
    }
  }
`;

export const OpportunityFooter = styled.div`
  padding: 24px;
  border-top: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  position: sticky;
  bottom: 0;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
`;
