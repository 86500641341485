/* @flow */

export default function CPFCheck(doc, initialState = {}) {
  const cpf = doc.replace(/\D/g, '');
  const pattern = /^(\d{1})\1{10}$/;

  let { i, sum, mod, digit } = initialState;

  if (cpf.length !== 11) {
    return false;
  }
  if (pattern.test(cpf)) {
    return false;
  }
  sum = 0;
  for (i = 0; i < 9; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  mod = sum % 11;
  digit = mod > 1 ? 11 - mod : 0;
  if (parseInt(cpf.charAt(9), 10) !== digit) {
    return false;
  }
  sum = 0;
  for (i = 0; i < 10; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  mod = sum % 11;
  digit = mod > 1 ? 11 - mod : 0;
  if (parseInt(cpf.charAt(10), 10) !== digit) {
    return false;
  }
  return true;
}

function checkFormattedCPF(cpf: string) {
  return CPFCheck(cpf.replace(/\./g, '').replace(/-/g, ''));
}

export { checkFormattedCPF };
