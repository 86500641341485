import { createGlobalStyle } from 'styled-components';
import antCustomTheme from '~/ui/theme/antd/theme';
import { themeColor } from '~/common/utils/theme';

const globalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;

    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      border: none;
      border-radius: 0;
      background-color: ${themeColor('background.primary', {
        dark: 'background.secondary',
      })};
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 1px solid transparent;
      background-clip: content-box;
      background-color: #666666;
    }
  }

  body {
    font-family: Libre Franklin, sans-serif !important;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  .App {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    background-color: ${themeColor('background.secondary', { dark: 'background.tertiary' })};
  }

  .content {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 32px 24px;
  }

  .content.no-padding {
    padding: 0;
  }

  .content.overflow-hidden {
    overflow: hidden;
  }
  
  .no-scroll {
    overflow: hidden;
  }

  main {
    background: ${themeColor('background.secondary', { dark: 'background.tertiary' })};
  }
  
  ${antCustomTheme};
`;

export default globalStyle;
