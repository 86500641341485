import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { listContractProposals } from '~/store/proposals/actions';
import { Table, TableColumns } from '~/ui/components';
import { useDrawer } from '~/hooks/useDrawer';

import { MtProposal } from '~/typings/entities/Proposals';
import { ActionButton, TableTitle } from '../Returns.styled';
import { ReturnDetails } from './ReturnDetails';

export type MtReturnsProps = {
  contractId: string;
};

export function MTReturns({ contractId }: MtReturnsProps) {
  const proposalType = 'mtproposal';
  const { openDrawer } = useDrawer();

  const listProposalsControl = useAwaitControl(listContractProposals);

  const proposalsLoading = listProposalsControl.isRunning(contractId);
  const proposals = listProposalsControl.result(contractId);

  const handleDetailsClick = (proposal) => () => {
    openDrawer('return-details', <ReturnDetails proposal={proposal} />, {
      title: 'Detalhes da proposta',
      closable: true,
      width: 700,
    });
  };

  // TODO: Verificar quais ações são possíveis para as propostas 'mtproposal' e quais os endpoints de cada uma e adicionar as ações na tabela usando como exemplo a tabela de QiReturns
  const columns: TableColumns<MtProposal> = [
    { accessorKey: 'id', header: () => 'ID' },
    { accessorKey: 'id_extern', header: () => 'COD' },
    { accessorKey: 'banquier', header: () => 'BANQUIER' },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <ActionButton onClick={handleDetailsClick(info.row.original)} icon="FileSearch" />
      ),
    },
  ];

  useEffect(() => {
    listProposalsControl.start({ contractId, proposalType }, { actionId: contractId });
  }, []);

  return (
    <>
      <TableTitle>Propostas</TableTitle>
      <Table
        noItemsMessage="Nenhuma proposta encontrada"
        loading={proposalsLoading}
        data={proposals}
        columns={columns}
      />
    </>
  );
}
