export default function style({ unit }) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0 29px',
    },
    button: {
      marginTop: '18px',
    },
    label: {
      marginBottom: '5px',
    },
    container__list: {
      overflow: 'auto',
      maxHeight: '40vh',
      paddingRight: '16px',
      textAlign: 'justify',
    },
    item__infos: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '26px',
    },
    item__date: {
      color: 'rgba(0, 0, 0, 0.45)',
      textAlign: 'right',
      fontSize: '14px',
      marginRight: '10px',
      padding: '7px 0',
      paddingLeft: '6px',
    },
    empty: {
      textAlign: 'center',
      padding: unit * 2,
    },
  };
}
