/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import {
  FETCH_CONTRACT_REFUSAL_REASONS_REQUEST,
  success,
  failure,
} from './action';

const fetchContracts = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(FETCH_CONTRACT_REFUSAL_REASONS_REQUEST)
    .map(({ payload: { params } }) => params)
    .mergeMap(id =>
      AjaxRequest.open(
        'get',
        `/admin/contractcustom/contracts/${id}/refusalreasons`,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(fetchContracts);
