export type Pendency = {
  contractId: number;
  id: number;
  isNewPending: boolean;
  pendingType: string;
  reason: string;
  reasonType: string;
  pendingDate: string;
  status: string;
};

export const PendencyStatusColors = {
  Aberta: 'warning',
  Resolvida: 'success',
  Excluída: 'gray',
};
