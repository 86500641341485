/* @flow */
export const APPROVE_PERSON_REGISTRY_REQUEST =
  'APPROVE_PERSON_REGISTRY_REQUEST';
export const APPROVE_PERSON_REGISTRY_SUCCESS =
  'APPROVE_PERSON_REGISTRY_SUCCESS';
export const APPROVE_PERSON_REGISTRY_FAILURE =
  'APPROVE_PERSON_REGISTRY_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: APPROVE_PERSON_REGISTRY_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: APPROVE_PERSON_REGISTRY_FAILURE,
      payload: resource,
    });
  };
}

export default function ApprovePersonRegistry(params) {
  return dispatch => {
    dispatch({
      type: APPROVE_PERSON_REGISTRY_REQUEST,
      payload: params,
    });
  };
}
