import { createAsyncAction } from 'react-redux-await-control';

import { telli } from '~/services';

export const registerOfferPerson = createAsyncAction('REGISTER_OFFER_PERSON', {
  context: {
    request: (payload) => {
      const formData = new FormData();

      formData.append('personId', payload.personId);
      formData.append('title', payload.title);
      formData.append('description', payload.description);
      formData.append('contractValue', payload.contractValue);
      formData.append('installmentValue', payload.installmentValue);
      formData.append('numberOfInstallments', payload.numberOfInstallments);
      formData.append('nominalRate', payload.nominalRate);
      formData.append('financialInstitutionId', payload.financialInstitutionId);
      formData.append('startDate', payload.startDate);
      formData.append('endDate', payload.endDate);

      return telli.post('/admin/offerpersoncustom/register', formData);
    },
  },
});
