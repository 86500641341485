import styled from 'styled-components';
import { Table } from '~/ui/components';

export const UserScreenContainer = styled.div`
  margin: 1rem 2rem;
  padding: 24px 24px 40px 24px;
  background-color: white;
  border-radius: 16px;
`;

export const CreateUserButton = styled.button`
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 20px;
  min-height: 56px;

  &:hover {
    cursor: pointer;
  }
`;

export const TopArea = styled.div`
  display: flex;
  padding-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #9a9a9a;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const FilterContent = styled.div`
  width: 270px;
  display: inline-flex;
  align-items: center;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const CustomizedTable = styled(Table)`
  padding: 0 72.5px 0 0;
  margin-bottom: 24px;

  td {
    border-top: none !important;
    border-bottom: 1px dashed #cfcfcf !important;
  }
`;
