/* @flow */

import { message } from '~/common';

export const SOLVE_ADDRESS_REQUEST = 'SOLVE_ADDRESS_REQUEST';
export const SOLVE_ADDRESS_SUCCESS = 'SOLVE_ADDRESS_SUCCESS';
export const SOLVE_ADDRESS_FAILURE = 'SOLVE_ADDRESS_FAILURE';

export function success(payload) {
  message.success('Pendência de endereço resolvida');
  return dispatch => {
    dispatch({
      type: SOLVE_ADDRESS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  message.error('Falha ao resolver pendência');
  return dispatch => {
    dispatch({
      type: SOLVE_ADDRESS_FAILURE,
      payload: resource,
    });
  };
}

export default function updateConsigneeStatus(params) {
  return dispatch => {
    dispatch({
      type: SOLVE_ADDRESS_REQUEST,
      payload: params,
    });
  };
}
