import React from 'react';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primaryColor};

  svg {
    fill: ${({ theme }) => theme.primaryColor};
    margin-right: 10px;
  }
`;
