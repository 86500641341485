import React, { useRef, useEffect } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useAwaitControl } from 'react-redux-await-control';

import { Form, Button, CustomizableModalContent } from '~/ui/components';
import { createUser } from '~/store/user/actions';
import { useModal } from '~/hooks/useModal';
import theme from '~/ui/theme';
import { User } from '~/ui/assets/icons';
import Glasses from '~/assets/images/person-glasses.png';
import ErrorImg from '~/assets/images/error.png';
import { InputModal, SelectModal } from './AddUserModal.styled';

const roles = [
  { value: 'ADMINISTRATOR', label: 'Administrador' },
  { value: 'BACKOFFICE', label: 'Agente' },
  { value: 'BACKOFFICE_SUPERVISOR', label: 'Supervisor' },
];

export function AddUserModal() {
  const { setLoading, closeModal, openModal } = useModal();
  const form = useRef<WrappedFormUtils>();
  const createUserControl = useAwaitControl(createUser);
  const createUserLoading = createUserControl.isRunning();
  const createUserSuccess = createUserControl.isSuccessful();

  const handleOnSubmit = ({ team, role, ...values }) => {
    // createUserControl.start({
    //   team: [team],
    //   roles: [role],
    //   ...values,
    // });
  };

  useEffect(() => {
    setLoading('add-user', createUserLoading);
  }, [createUserLoading]);

  useEffect(() => {
    if (createUserSuccess) {
      closeModal('add-user');

      openModal(
        <CustomizableModalContent
          title="Cadastro feito"
          text="As informações da senha serão enviadas para o e-mail do usuário."
          image={Glasses}
          buttonLabel="Fechar"
          onClick={() => closeModal('user-successfully-added')}
        />,
        {
          id: 'user-successfully-added',
          closable: true,
          width: 360,
        },
      );
    }

    // Erro ao criar o usuário

    // if (!createUserSuccess) {
    //   closeModal('add-user');

    //   openModal(
    //     <CustomizableModalContent
    //       title="Algo deu errado"
    //       text="Não foi possível realizar o cadastro, atualize a página e tente novamente."
    //       image={ErrorImg}
    //       buttonLabel="Fechar"
    //       onClick={() => closeModal('failure-adding-user')}
    //     />,
    //     {
    //       id: 'failure-adding-user',
    //       closable: true,
    //       width: 360,
    //     },
    //   );
    // }

    return () => {
      createUserControl.clear();
    };
  }, [createUserSuccess]);

  return (
    <Form
      ref={form}
      onSubmit={handleOnSubmit}
      inputs={[
        {
          id: 'name',
          label: 'Nome e sobrenome',
          input: (
            <InputModal
              placeholder="Informe o nome"
              allowClear
              icon={<User width={16} height={16} color="#666666" />}
            />
          ),
        },
        {
          id: 'email',
          label: 'Email',
          input: <InputModal placeholder="@meutudo.app" allowClear />,
          options: {
            rules: [{ type: 'email', message: 'Este campo deve ser um email!' }],
          },
        },
        {
          id: 'team',
          label: 'Time',
          input: (
            <SelectModal
              placeholder="Selecione o time"
              options={roles}
              backgroundColor={theme.colors.white.main}
              borderColor="#c6c6c6"
            />
          ),
        },
        {
          id: 'role',
          label: 'Perfil',
          input: (
            <SelectModal
              placeholder="Selecione o cargo"
              options={roles}
              backgroundColor={theme.colors.white.main}
              borderColor="#c6c6c6"
            />
          ),
        },
      ]}
    >
      <Button rounded fullWidth loading={false} color="black">
        Prosseguir com criação
      </Button>
    </Form>
  );
}
