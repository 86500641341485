export default {
  main: '#6CD551',
  50: '#E5FFE5',
  100: '#8CF56F',
  200: '#75DE59',
  300: '#5DC744',
  400: '#45B12E',
  500: '#299B14',
  600: '#008500',
  700: '#007000',
  800: '#005C00',
  900: '#004800',
};
