const GENDERS = [
  {
    value: 'MALE',
    name: 'Masculino',
  },
  {
    value: 'FEMALE',
    name: 'Feminino',
  },
];

export default GENDERS;
