import React from 'react';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

export const ContainerButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;
