const CONTRACT_QUEUE_STATUS = {
  NEW: 'Contrato novo',
  PORT: 'Portabilidade',
  PAB: 'Auxílio Brasil',
  FGTS: 'Fgts normal',
  FUTURE_MARGIN: 'Margem futura',
};

const CONTRACT_QUEUE_STATUS_ABBREVIATED = {
  NEW: 'Contrato novo',
  PORT: 'Portabilidade',
  PAB: 'Auxílio Brasil',
  FGTS: 'FGTS',
  FUTURE_MARGIN: 'Margem futura',
};

const parseContractQueueStatus = (step: string, abbreviated = false) => {
  if (abbreviated) {
    return CONTRACT_QUEUE_STATUS_ABBREVIATED[step] || step;
  }

  return CONTRACT_QUEUE_STATUS[step] || step;
};

export { parseContractQueueStatus, CONTRACT_QUEUE_STATUS, CONTRACT_QUEUE_STATUS_ABBREVIATED };
