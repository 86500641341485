import React from 'react';

import { EmptyPanel, Spinner } from '~/components';
import { PanelKindTypes } from '~/components/EmptyPanel/EmptyPanel';
import getViewForPendency from '~/screens/ContractDetails/Panels/Pendencies/components/actions';

import { Title, Container, Content } from './styled';

export type PendencyType = { id: number, pendingType: string };

type PendenciesProps = {
  pendencies: Array<PendencyType>,
  loading: boolean,
};

export default function Pendencies({ pendencies, loading }: PendenciesProps) {
  return (
    <Container>
      <Spinner spinning={loading} />
      {pendencies?.length || loading ? (
        <>
          <Title>{pendencies?.length} pendências</Title>
          <Content>
            {pendencies &&
              !!pendencies?.length &&
              pendencies?.map((pendency) => getViewForPendency(pendency))}
          </Content>
        </>
      ) : (
        <EmptyPanel kind={PanelKindTypes.PENDENCY} />
      )}
    </Container>
  );
}
