import { Modal, Form, Menu, Collapse as CollapseComponent, Dropdown as AntDropdown } from 'antd';
import styled, { css } from 'styled-components';
import { Button, Typography } from '~/ui/components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import Icon from '~/components/Icon';

interface CollapseProps {
  collapsed?: boolean;
}

export const CollapseButton = styled<CollapseProps>(Button).attrs(({ collapsed }) => ({
  size: 'sm',
  iconOnly: true,
  variant: 'text',
  icon: 'CaretDown',
  title: collapsed ? 'Expandir' : 'Recolher',
}))`
  width: 36px;
  height: 36px;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};

  svg {
    width: 14px;
    height: 14px;
    fill: none !important;
    transition: transform 0.24s, -webkit-transform 0.24s;
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      svg {
        transform: rotate(-90deg);
      }
    `}

  &:disabled {
    opacity: 1;

    svg {
      opacity: 0.4;
    }
  }
`;

export const MainButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  padding: 10px 5px 5px 22px;
`;

export const ActionsTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  element: 'h4',
  weight: 600,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px 32px 6px 24px;
  margin-left: 5px;
  border-left: 3px solid transparent;
  cursor: pointer;
  transition: all 0.18s;

  :hover {
    p {
      font-weight: 600 !important;
    }

    background-color: ${({ theme }) => theme.colors.state.hover};
    border-color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export const ItemText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const Divider = styled.div`
  height: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.divider.primary};
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  margin: 20px 32px 10px;
`;

export const ModalContainer = styled(Modal).attrs(() => ({
  width: 415,
  centered: true,
}))`
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-close-x {
      display: none;
    }
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    padding: 18px 20px 20px;
    background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};

    p,
    span {
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
`;

export const SubmitButton = styled(Button).attrs(({ theme }) => ({
  size: 'sm',
  customColor: themeToggleColor(theme, 'brand.primary', { dark: 'brand.secondaryAlt' }),
}))`
  align-self: flex-end;
  span {
    color: ${themeColor('element.invert', { dark: 'element.primary' })} !important;
  }
`;

export const NotificationForm = styled(Form)`
  display: flex;
  flex-direction: column;

  .ant-row.ant-form-item:last-child {
    margin-bottom: 10px;
  }
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  weight: 500,
  color: theme.colors.gray.main,
}))``;

export const ItemWrapper = styled(Menu).attrs({
  selectable: false,
})`
  width: 200px;
  margin: 0;
  padding: 4px 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%) !important;
  background-color: ${({ theme }) => theme.colors.background.primary};
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
`;

export const Option = styled(Menu.Item).attrs({})`
  padding: 0;
  margin: 0;

  :hover {
    background-color: ${themeColor('brand.secondaryAlt', { dark: 'brand.primaryAlt' })}30;
    color: ${themeColor('brand.primary', { dark: 'brand.primary' })};
  }
`;

export const Collapse = styled(CollapseComponent)`
  .ant-collapse-item.ant-collapse-no-arrow {
    margin-bottom: 0 !important;
  }

  &,
  .ant-collapse-item {
    background: ${themeColor('background.primary', { dark: 'background.secondary' })} !important;
  }
`;

export const ShimmerContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const CloseModalIcon = styled(Icon)`
  path {
    fill: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })} !important;
  }
`;

export const Dropdown = styled(AntDropdown)`
  &.anticon {
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })} !important;
  }
`;
