import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form as DefaultForm, FormInput, Select, withStepperProps } from '~/ui/components';
import { MultiSelect } from '~/ui/components/MultiSelect/MultiSelect';

import { useAwaitControl } from 'react-redux-await-control';
import {
  getReasons,
  getTicketJourneys,
  getTicketResolutionReasons,
  getTicketStatus,
  selectTicket,
} from '~/store/tickets/actions';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import _ from 'lodash';
import { AssessmentBody, SectionTitle } from '../Assessment.styled';

export type ReportFormProps = {
  onSubmit: (values: any) => void;
  values: any;
  loading?: boolean;
};

const YES_NO_OPTIONS = [
  { label: 'Sim', value: 'true' },
  { label: 'Não', value: 'false' },
];

const WRONG_FIELDS = [
  { label: 'Jornada', value: 'JOURNEY' },
  { label: 'Motivo', value: 'REASON' },
  { label: 'Status', value: 'STATUS' },
  { label: 'Justificativa de resolução', value: 'JUSTIFICATION' },
];

export const ReportForm = withStepperProps<ReportFormProps>(({ onSubmit, values, loading }) => {
  const form = useRef<WrappedFormUtils>();

  const [hasIncorrectFields, setHasIncorrectFields] = useState(null);
  const [selectedWrongFields, setSelectedWrongFields] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const selectedTicketControl = useAwaitControl(selectTicket);
  const selectedTicket = selectedTicketControl.result();

  const getJourneysControl = useAwaitControl(getTicketJourneys);
  const journeys = getJourneysControl.result();

  const getReasonsControl = useAwaitControl(getReasons);
  const reasons = getReasonsControl.result();

  const getStatusControl = useAwaitControl(getTicketStatus);
  const status = getStatusControl.result();

  const getResolutionReasonsControl = useAwaitControl(getTicketResolutionReasons);
  const resolutionReasons = getResolutionReasonsControl.result();

  const filteredJourneys = useMemo(() => {
    const filteredItems = journeys?.filter((journey) =>
      journey.products?.includes(selectedTicket?.product),
    );

    return filteredItems || [];
  }, [journeys, selectedTicket]);

  const filteredReasons = useMemo(() => {
    const relatedJourney = selectedWrongFields.includes('JOURNEY')
      ? selectedJourney
      : selectedTicket?.journeyId;

    const filteredItems = reasons?.filter(
      (reason) =>
        reason.journeys?.includes(relatedJourney) &&
        reason.products?.includes(selectedTicket?.product),
    );
    return filteredItems || [];
  }, [journeys, selectedTicket, selectedJourney, selectedWrongFields]);

  const filteredResolutionReasons = useMemo(() => {
    const relatedJourney = selectedWrongFields.includes('JOURNEY')
      ? selectedJourney
      : selectedTicket?.journeyId;

    const relatedStatus = selectedWrongFields.includes('STATUS')
      ? selectedStatus
      : selectedTicket?.ticketStatusId;

    const filteredItems = resolutionReasons?.filter(
      (reason) =>
        reason.journeys?.includes(relatedJourney) && reason.status?.includes(relatedStatus),
    );
    return filteredItems || [];
  }, [resolutionReasons, selectedWrongFields, selectedJourney, selectedStatus, selectedTicket]);

  const filteredStatuses = useMemo(() => {
    const filteredItems = status?.filter((status) => status.steps?.includes('CREATE_TICKET'));
    return filteredItems || [];
  }, [status]);

  const disableButton = useMemo(() => {
    if (hasIncorrectFields !== null) {
      return hasIncorrectFields ? selectedWrongFields.length === 0 : false;
    }

    return true;
  }, [selectedWrongFields, hasIncorrectFields]);

  const handleJourneyChange = useCallback(
    ([journey]) => {
      setSelectedJourney(journey);

      if (journey !== selectedJourney) {
        form.current?.setFieldsValue({ correctReasonId: [], correctJustificationResolutionId: [] });
      }
    },
    [setSelectedJourney],
  );

  const handleStatusChange = useCallback(
    ([status]) => {
      setSelectedStatus(status);

      if (status !== selectedStatus) {
        form.current?.setFieldsValue({ correctJustificationResolutionId: [] });
      }
    },
    [setSelectedStatus],
  );

  const handleSelectedWrongFields = useCallback(
    (values: string[]) => {
      const removeDiff = _.difference(selectedWrongFields, values);
      const addDiff = _.difference(values, selectedWrongFields);

      if (removeDiff.includes('JOURNEY')) {
        setSelectedJourney(null);
        setSelectedStatus(null);

        form.current?.setFieldsValue({
          correctReasonId: [],
          correctJustificationResolutionId: [],
        });
      }

      if (removeDiff.includes('STATUS')) {
        setSelectedStatus(null);

        form.current?.setFieldsValue({
          correctJustificationResolutionId: [],
        });
      }

      if (addDiff.includes('JOURNEY')) {
        form.current?.setFieldsValue({
          correctReasonId: [],
          correctJustificationResolutionId: [],
        });
      }

      setSelectedWrongFields(values);
    },
    [selectedWrongFields, setSelectedWrongFields],
  );

  const handleSubmit = (values) => {
    onSubmit?.(values);
  };

  const inputs: FormInput[] = useMemo(() => {
    const inputs: FormInput[] = [
      {
        id: 'section-title',
        label: <SectionTitle>Bloco Reportar</SectionTitle>,
      },
      {
        id: 'filledCorrectly',
        label: 'Marcação correta?',
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
        input: (
          <Select
            options={YES_NO_OPTIONS}
            onChange={(values) => setHasIncorrectFields(values?.[0] === 'false')}
          />
        ),
      },
    ];

    if (hasIncorrectFields) {
      inputs.push({
        id: 'wrongFields',
        label: 'Quais os campos incorretos?',
        input: (
          <MultiSelect
            placeholder="Selecione as opções"
            options={WRONG_FIELDS}
            onChange={handleSelectedWrongFields}
          />
        ),
      });
    }

    if (selectedWrongFields.includes('JOURNEY')) {
      inputs.push({
        id: 'correctJourneyId',
        label: 'Qual é a jornada correta?',
        input: (
          <Select
            options={filteredJourneys}
            fieldNames={{ label: 'name', value: 'id' }}
            onChange={handleJourneyChange}
          />
        ),
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
      });
    }

    if (selectedWrongFields.includes('REASON')) {
      inputs.push({
        id: 'correctReasonId',
        label: 'Qual é motivo correto?',
        input: (
          <Select
            options={filteredReasons}
            fieldNames={{ label: 'name', value: 'id' }}
            notFoundContent="Selecione uma jornada"
          />
        ),
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
      });
    }

    if (selectedWrongFields.includes('STATUS')) {
      inputs.push({
        id: 'correctStatusId',
        label: 'Qual é o status de resolução correto?',
        input: (
          <Select
            options={filteredStatuses}
            fieldNames={{ label: 'name', value: 'id' }}
            onChange={handleStatusChange}
            popupPlacement="left"
          />
        ),
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
      });
    }

    if (selectedWrongFields.includes('JUSTIFICATION')) {
      inputs.push({
        id: 'correctJustificationResolutionId',
        label: 'Qual é a justificativa de resolução correta?',
        input: (
          <Select
            options={filteredResolutionReasons}
            fieldNames={{ label: 'justification', value: 'id' }}
          />
        ),
        options: { rules: [{ required: true, message: 'Campo obrigatório' }] },
      });
    }

    return inputs;
  }, [
    selectedWrongFields,
    hasIncorrectFields,
    filteredJourneys,
    filteredReasons,
    filteredStatuses,
    filteredResolutionReasons,
    values,
  ]);

  useEffect(() => {
    if (!journeys.length) getJourneysControl.start();
    if (!reasons.length) getReasonsControl.start();
    if (!status.length) getStatusControl.start();
    if (!resolutionReasons.length) getResolutionReasonsControl.start();
  }, []);

  return (
    <AssessmentBody>
      <DefaultForm ref={form} inputs={inputs} onSubmit={handleSubmit}>
        <Button rounded customColor="black" fullWidth disabled={disableButton} loading={loading}>
          Finalizar avaliação
        </Button>
      </DefaultForm>
    </AssessmentBody>
  );
});
