import styled, { css } from 'styled-components';
import { Link as RouterDomLink } from 'react-router-dom';

import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';
import { fadeIn } from '~/ui/theme/animations';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 0;

  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const Link = styled(RouterDomLink)`
  font-weight: 700;
  color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
`;

export const TagsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  margin-top: 8px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;

  svg {
    height: 24px;
    width: 24px;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

type TicketInfoProps = {
  noBorder?: boolean;
};

export const InfoContainer = styled.div<TicketInfoProps>`
  padding: 24px 0;
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  margin-bottom: 32px;
  border-bottom: ${({ noBorder }) => (noBorder ? 0 : '1px')} solid
    ${themeColor('divider.brand', { dark: 'divider.secondary' })};

  div {
    margin-bottom: 14px !important;
  }
`;

type CoverageContentProps = {
  hasBottomMargin?: boolean;
};

export const CoverageContent = styled.div<CoverageContentProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  ${({ hasBottomMargin }) =>
    hasBottomMargin &&
    css`
      padding-bottom: 32px;
      border-bottom: 1px solid ${themeColor('divider.brand', { dark: 'divider.secondary' })};
      margin-bottom: 24px;
    `}

  caption {
    color: ${themeColor('element.secondary')} !important;
  }
`;

export const SectionTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'bodyLarge',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  weight: 700,
  element: 'h3',
}))``;
