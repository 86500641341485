import React, { useCallback, useEffect, useState } from 'react';
import { FileDownload, Loading } from '~/ui/assets/icons';
import { useAwaitControl } from 'react-redux-await-control';
import { getTicketMedia } from '~/store/tickets/actions';
import {
  Image,
  ImageModal,
  ImageContent,
  PDFContent,
  AudioContent,
  VideoContent,
} from './Media.styled';

const mediaType = {
  'application/pdf': 'PDF',
  'image/jpeg': 'Image',
  'image/png': 'Image',
  'image/jpg': 'Image',
  'image/gif': 'Image',
  'audio/ogg': 'Audio',
  'video/mp4': 'Video',
};
export interface MediaProps {
  media: {
    category: string;
    size: number;
    filename: string;
    contentType: string;
    sid: string;
  };
  ticket: string;
}

export function Media({ media, ticket }: MediaProps) {
  const [isImageOpen, setIsImageOpen] = useState(false);

  const ticketMediaControl = useAwaitControl(getTicketMedia);

  const ticketMedia = ticketMediaControl.result(media.sid);

  const renderMedia = useCallback(
    () => (
      <>
        {!ticketMedia && <Loading />}

        {mediaType[media.contentType] === 'Image' && ticketMedia && (
          <Image image={ticketMedia?.content} onClick={() => setIsImageOpen(true)} />
        )}

        {mediaType[media.contentType] === 'PDF' && ticketMedia && (
          <FileDownload
            width={38}
            height={48}
            onClick={() => setIsImageOpen(true)}
            style={{ cursor: 'pointer' }}
          />
        )}

        {mediaType[media.contentType] === 'Audio' && ticketMedia && (
          <AudioContent controls style={{ backgroundColor: 'transparent' }}>
            <source src={ticketMedia?.content} />
            <track src={ticketMedia?.content} kind="captions" label="Português" />
          </AudioContent>
        )}

        {mediaType[media.contentType] === 'Video' && ticketMedia && (
          <VideoContent controls>
            <source src={ticketMedia?.content} />
            <track src={ticketMedia?.content} kind="captions" label="Português" />
          </VideoContent>
        )}

        {isImageOpen && (
          <ImageModal onClick={() => setIsImageOpen(false)}>
            {mediaType[media.contentType] === 'Image' && (
              <ImageContent src={ticketMedia?.content} />
            )}
            {mediaType[media.contentType] === 'PDF' && (
              <PDFContent src={ticketMedia?.content} type="application/pdf" />
            )}
          </ImageModal>
        )}
      </>
    ),
    [ticketMedia, isImageOpen, media],
  );

  useEffect(() => {
    ticketMediaControl.start(
      {
        conversationId: ticket,
        sid: media.sid,
      },
      { actionId: media.sid },
    );
  }, [ticket, media]);

  return renderMedia();
}
