import styled, { css } from 'styled-components';

type PreviewContainerProps = {
  withControls?: boolean;
};

export const PreviewContainer = styled.div<PreviewContainerProps>`
  img {
    height: 100%;
  }

  ${({ withControls }) =>
    withControls &&
    css`
      .image-control img {
        padding: 16px !important;
        background-color: white;
      }
    `}
`;

export const Embed = styled.embed`
  width: 60vw;
  height: 80vh;
`;

export const Object = styled.object`
  width: 60vw;
  height: 80vh;
`;

export const LoadingWrapper = styled.div`
  min-width: 300px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.primary.main};
    width: 24px;
    height: 24px;
  }
`;
