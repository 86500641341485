// @flow

import React from 'react';
import { Col, DatePicker, Form, Input, Select } from 'antd';
import theme from '~/themes/aphroditeTheme/theme';
import common from '~/screens/ContractDetails/Actions/components/styles';
import { SearchSelect } from '~/common';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import moment from 'moment';

const { css, withStyles } = theme;
const { Option } = Select;
const { Item: FormItem } = Form;

export type FieldProps = {
  type: 'input' | 'select' | 'date',
  getFieldDecorator: any,
  data: [],
  label: string,
  normalize: () => void,
  field: string,
  fieldProps?: any,
  placeholder?: string,
  required?: boolean,
  requiredMessage?: string,
  span: number,
  offset: number,
  disabled: boolean,
  maxLength: number,
  len: number,
  onChange: () => void,
};

const styles = (props) => ({
  ...common(props),
});

function getItem(props: FieldProps) {
  const { type, styles, disabled, onChange } = props;

  if (type === 'input') {
    return (
      <Input
        {...props.fieldProps}
        disabled={disabled}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        onChange={onChange(props.field)}
        style={css(styles.input)}
      />
    );
  }

  if (type === 'select') {
    return (
      <Select
        style={{ width: '100%' }}
        disabled={disabled}
        placeholder={props.placeholder}
        onChange={onChange(props.field)}
        allowClear
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => SearchSelect(input, option.props.children)}
      >
        {props.data.map((item) => (
          <Option key={item.value}>{item.name}</Option>
        ))}
      </Select>
    );
  }

  if (type === 'date') {
    return (
      <DatePicker
        locale={locale}
        disabled={disabled}
        style={{ width: '100%' }}
        onChange={props.onChange(props.field)}
        format="DD/MM/YYYY"
        disabledDate={(d) => !d || d.isAfter(moment())}
      />
    );
  }
  return null;
}

function Field(props: FieldProps) {
  const {
    label,
    span,
    offset,
    getFieldDecorator,
    field,
    required,
    visible,
    normalize,
    onChange,
    len,
    requiredMessage,
  } = props;
  const rules = [];
  if (required) {
    rules.push({
      required,
      message: requiredMessage,
    });
  }
  if (len) {
    rules.push({
      len,
      message: `O campo deve ter exatamente ${len} caracteres`,
    });
  }

  if (visible === false) {
    return false;
  }

  return (
    <Col xs={{ span, offset }}>
      <span>{label}</span>
      <FormItem>
        {getFieldDecorator(field, {
          rules,
          normalize,
        })(getItem({ ...props, onChange }))}
      </FormItem>
    </Col>
  );
}

export default withStyles(styles)(Field);
