// @flow
import { useCallback, useEffect, useState } from 'react';

type Dimensions = {
  width: number,
  height: number,
};

function getElementDimensions(elementRef): Dimensions {
  const { offsetWidth: width, offsetHeight: height } = elementRef;
  return { width, height };
}

function getWindowDimensions(): Dimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export default function useDimensions(elementRef) {
  const getDimensions = useCallback(() => {
    if (elementRef) {
      return getElementDimensions(elementRef);
    }

    return getWindowDimensions();
  }, [elementRef]);

  const [dimensions, setDimensions] = useState<Dimensions>(getDimensions());

  useEffect(() => {
    function handleResize() {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return dimensions;
}
