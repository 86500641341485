import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import getHistory from '~/store/getFileHistory/action';
import screenRegister from '~/hoc/screenRegister';

import FileHistory from './FileHistory';

type FileHistoryContainerProps = {};

class FileHistoryContainer extends Component<FileHistoryContainerProps> {
  componentDidMount(): void {
    const { location, match } = this.props;
    const contractId = location.search.split('=')[1];
    this.setState({ contractId });
    this.props.dispatch(
      getHistory({ fileType: match.params.type, contractId }),
    );
  }

  render() {
    const { history, historyFiles } = this.props;
    return (
      <FileHistory
        loading={history.loading}
        history={history.payload}
        files={historyFiles.payload}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    history: state.getIn(['getFileHistory']).toJS(),
    historyFiles: state.getIn(['getFileHistoryFiles']).toJS(),
  };
}

export default compose(
  screenRegister({
    screenName: 'FileHistory',
    path: '/backoffice/filehistory/:type',
    headerTitle: 'Histórico de arquivo',
  }),
  connect(mapStateToProps),
)(FileHistoryContainer);
