import { css } from 'styled-components';

export default css`
  .mt-date-picker {
    .ant-calendar {
      margin-top: 36px;
    }

    .ant-calendar-input-wrap {
      display: none;
    }

    .ant-calendar-date {
      background: transparent;
      font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
      font-size: ${({ theme }) => theme.typography.types.caption.size};
      line-height: ${({ theme }) => theme.typography.types.caption.lineHeight};
      color: ${({ theme }) => theme.colors.gray[800]};
      font-weight: ${({ theme }) => theme.typography.types.caption.weight};
    }

    .ant-calendar-last-month-cell,
    .ant-calendar-next-month-btn-day {
      opacity: 0.5;
    }

    .ant-calendar-disabled-cell {
      opacity: 0.2;
      pointer-events: none;
    }

    .ant-calendar-disabled-cell {
      .ant-calendar-date {
        background: transparent;
      }
    }

    .ant-calendar-today,
    .ant-calendar-selected-day {
      .ant-calendar-date {
        background: transparent;
        border: none;

        &::before {
          border: none;
          background: transparent;
        }
      }
    }

    .ant-calendar-selected-day {
      .ant-calendar-date {
        color: ${({ theme }) => theme.colors.warning.main};
      }
    }
    .ant-calendar-header {
      .ant-calendar-my-select * {
        font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
        font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
        line-height: ${({ theme }) => theme.typography.types.paragraphSmall.lineHeight};
        font-weight: ${({ theme }) => theme.typography.types.paragraphSmall.weight};
        color: ${({ theme }) => theme.colors.gray[800]};
      }

      .ant-calendar-column-header {
        .ant-calendar-column-header-inner {
          text-decoration: underline;
        }
      }

      .ant-calendar-prev-year-btn,
      .ant-calendar-next-year-btn {
        display: none;
      }

      .ant-calendar-prev-month-btn {
        left: 10px;
      }

      .ant-calendar-next-month-btn {
        right: 10px;
      }

      .ant-calendar-prev-month-btn,
      .ant-calendar-next-month-btn {
        &::before {
          width: 14px;
          height: 14px;
          border-width: 3px 0 0 3px;
          border-color: ${({ theme }) => theme.colors.primary.main};
        }
      }
    }
  }
`;
