import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Button } from '~/ui/components';

export const SimulateButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  size: 'sm',
  rounded: true,
  iconOnly: true,
  icon: 'Check',
  customColor: themeToggleColor(theme, 'positive.primary', { dark: 'positive.primaryAlt' }),
}))`
  svg {
    width: 18px;
    height: 18px;
  }
`;
