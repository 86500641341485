/* @flow */
import { getPendencies } from '~/store/pendencies/actions';

export const REQUEST_NEW_IMAGE_REQUEST = 'REQUEST_NEW_IMAGE_REQUEST';
export const REQUEST_NEW_IMAGE_SUCCESS = 'REQUEST_NEW_IMAGE_SUCCESS';
export const REQUEST_NEW_IMAGE_FAILURE = 'REQUEST_NEW_IMAGE_FAILURE';

export function success(payload) {
  return (dispatch) => [
    dispatch({
      type: REQUEST_NEW_IMAGE_SUCCESS,
      payload,
    }),

    getPendencies.start(payload),
  ];
}

export function failure(resource) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_NEW_IMAGE_FAILURE,
      payload: resource,
    });
  };
}

export default function requestNewImage(params) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_NEW_IMAGE_REQUEST,
      payload: params,
    });
  };
}
