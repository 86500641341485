import { Icon } from 'antd';
import React, { useContext } from 'react';

import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';
import {
  CONTRACT_QUEUE_STATUS,
  parseContractQueueStatus,
} from '~/typings/enums/ContractQueueStatus';
import CloseIcon from '~/assets/icons/close.svg';
import { useAwaitControl } from 'react-redux-await-control';
import { getContractQueueInformation } from '~/store/contracts/actions';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
  Divider,
  FilterConfigLabel,
  PopoverHeader,
  Radio,
  RadioDivider,
  Subtitle,
  Title,
  RadioContainer,
} from './styled';

const radioStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '17px',
};

type ContractFilterProps = {
  closePopover: () => void;
};

export default function ProductFilter({ closePopover }: ContractFilterProps) {
  const { setContractType, contract } = useContext(ContractDetailsContext);
  const [getContractQueueInformationControl] = useAwaitControl([getContractQueueInformation]);
  const { queues } = getContractQueueInformationControl.result();

  const handleClose = () => {
    closePopover();
  };

  const onChange = (e: RadioChangeEvent) => {
    setContractType(e.target.value);
    closePopover();
  };

  const list = () => {
    const queueNames = Object.keys(CONTRACT_QUEUE_STATUS).sort();
    return queueNames.map((qName) => ({
      id: qName,
      title: parseContractQueueStatus(qName),
      amount: queues?.find((queue: any) => queue?.contractQueueStatus === qName)?.count || 0,
    }));
  };

  return (
    <>
      <PopoverHeader>
        <Title>Filtro de produtos</Title>
        <Icon component={CloseIcon} onClick={handleClose} style={{ fontSize: '24px' }} />
      </PopoverHeader>
      <Divider />
      <FilterConfigLabel>
        Selecione qual o tipo de produto você deseja puxar para sua fila automática.
      </FilterConfigLabel>
      <Radio.Group
        style={{ width: '100%', marginBottom: '10px' }}
        onChange={onChange}
        value={contract}
      >
        {list().map((item, index, contractList) => (
          <>
            <RadioContainer>
              <Radio key={item.id} value={item.id} style={radioStyle}>
                {item.title}
              </Radio>
              <Subtitle>
                {item.amount}
                {item.amount === 1 ? ' contrato' : ' contratos'}
              </Subtitle>
            </RadioContainer>
            {contractList.length !== index + 1 && <RadioDivider />}
          </>
        ))}
      </Radio.Group>
    </>
  );
}
