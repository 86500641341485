/* @flow */

export const UPDATE_MARGIN_REQUEST = 'UPDATE_MARGIN_REQUEST';
export const UPDATE_MARGIN_SUCCESS = 'UPDATE_MARGIN_SUCCESS';
export const UPDATE_MARGIN_FAILURE = 'UPDATE_MARGIN_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: UPDATE_MARGIN_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: UPDATE_MARGIN_FAILURE,
      payload: resource,
    });
  };
}

export default function updateMargin(params) {
  return dispatch => {
    dispatch({
      type: UPDATE_MARGIN_REQUEST,
      payload: params,
    });
  };
}
