import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import { Money } from '~/common';
import { Transaction } from '~/typings/entities/Clix';
import {
  TransactionStatusColors,
  TransactionStatusString,
  TransactionType,
  WarrantyType,
} from '~/typings/enums/Clix';

import { useDrawer } from '~/hooks/useDrawer';

import {
  Button,
  CalendarValue,
  Flex,
  SelectDate,
  Table,
  TableColumns,
  Tag,
  Tooltip,
} from '~/ui/components';
import { Calendar, Cancel } from '~/ui/assets/icons';

import { getTransactions } from '~/store/clix/actions';
import usePagination from '~/hooks/usePagination';
import { getPersonDetail } from '~/store/people/actions';
import { TableContainer } from '..';
import { TransactionDetails } from '../../Drawers';

type TransactionsTableProps = {
  warranty: WarrantyType;
  personId: string;
};

export function TransactionsTable({ personId, warranty }: TransactionsTableProps) {
  const { openDrawer } = useDrawer();
  const { colors } = useTheme();
  const [filters, setFilters] = useState<{}>();

  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getClixTransactionsControl = useAwaitControl(getTransactions);

  const person = getPersonDetailsControl.result();
  const personSuccess = getPersonDetailsControl.result();
  const { transactions, pagination } = getClixTransactionsControl.result(`${personId}_${warranty}`);
  const transactionsLoading = getClixTransactionsControl.isRunning(`${personId}_${warranty}`);

  const { page, size, paginatedData, renderPagination, clear } = usePagination({
    data: transactions,
    total: pagination?.totalElements,
  });

  const fetchTransactions = (page: number) => {
    getClixTransactionsControl.start(
      { document: person?.document, warranty, size, page, ...filters },
      { actionId: `${personId}_${warranty}` },
    );
  };

  const handleDateFilterChange = (dates: CalendarValue) => {
    let initialDate;
    let endDate;

    if (dates) {
      initialDate = moment(dates[0]).startOf('day').format('YYYY-MM-DD');
      endDate = moment(dates[1]).endOf('day').format('YYYY-MM-DD');
    } else {
      initialDate = null;
      endDate = null;
    }

    setFilters((values) => ({ ...values, initialDate, endDate }));
  };

  const handleDetailsClick = (id: string) => {
    openDrawer('transactionDetails', <TransactionDetails id={id} />, { width: 480 });
  };

  const columns: TableColumns<Transaction> = [
    {
      header: () => '',
      accessorKey: 'status',
      cellAlign: 'center',
      maxSize: 50,
      cell: (info) => {
        const value = info.getValue<string>();
        const palette = TransactionStatusColors[value];
        const textColor = colors?.[palette]?.primaryAlt;
        const bgColor = colors?.[palette]?.secondary;
        return (
          <Tooltip content={TransactionStatusString[value]} small>
            <Tag small rounded textColor={textColor} bgColor={bgColor}>
              <Cancel />
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'purchaseDate',
      header: () => 'DATA',
      cell: (info) => {
        const date = info.getValue();
        return moment(date).format('DD/MM/YYYY hh[h]mm');
      },
    },
    {
      header: () => 'Total a pagar',
      accessorKey: 'totalValue',
      cell: (info) => Money(info.getValue()),
    },
    {
      header: () => 'Total enviado',
      accessorKey: 'principalValue',
      cell: (info) => Money(info.getValue()),
    },
    {
      header: () => 'Tipo de transferência',
      accessorKey: 'type',
      cell: (info) => TransactionType[info.getValue<string>()],
    },
    {
      id: 'actions',
      header: () => 'Detalhes',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: (info) => (
        <Button
          onClick={() => handleDetailsClick(info.row.original.id)}
          customColor={colors.brand.primary}
          iconOnly
          icon="FileSearch"
          variant="text"
        />
      ),
    },
  ];

  useEffect(() => {
    if (filters && person?.document) {
      clear();
      fetchTransactions(0);
    }
  }, [filters]);

  useEffect(() => {
    if (personSuccess && !paginatedData?.length && person?.document) {
      fetchTransactions(page);
    }
  }, [page, person, personSuccess]);

  return (
    <TableContainer>
      <Flex>
        <SelectDate
          selectRange
          placeholder="Filtre por data"
          icon={<Calendar width={18} height={18} />}
          onChange={handleDateFilterChange}
          asFilter
        />
      </Flex>

      <Table
        noItemsMessage="Nenhuma transação encontrada"
        responsiveCols={[
          'status',
          'purchaseDate',
          'totalValue',
          'principalValue',
          'type',
          'actions',
        ]}
        loading={!paginatedData?.length && transactionsLoading}
        columns={columns}
        data={paginatedData}
        noPadding
      />

      {transactions && renderPagination()}
    </TableContainer>
  );
}
