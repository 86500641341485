import React, { useCallback, useRef, useState } from 'react';
import { Form, Row, Col, DatePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/lib/date-picker/locale/pt_BR';

import { Button } from '~/ui/components/';
import { useAwaitControl } from 'react-redux-await-control';
import { uploadMedicalCertificateByEnrollment } from '~/store/enrollments/actions';

type MedicalCertificate = {
  file: File;
  fileName: string;
};

type UploadCertificateModalProps = {
  enrollmentId: number;
};

export function UploadCertificateModal({ enrollmentId }: UploadCertificateModalProps) {
  const [medicalCertificate, setMedicalCertificate] = useState<MedicalCertificate>(null);
  const [medicalCertificateDate, setMedicalCertificateDate] = useState('');

  const uploadMedicalCertificateControl = useAwaitControl(uploadMedicalCertificateByEnrollment);

  const isLoading = uploadMedicalCertificateControl.isRunning();

  const inputNewEl = useRef<HTMLInputElement>(null);

  const handleDateChange = (medicalCertificateDate) => {
    setMedicalCertificateDate(medicalCertificateDate);
  };

  const onPressAttachFileButton = useCallback(() => {
    if (inputNewEl.current) {
      inputNewEl.current.click();
    }
  }, [inputNewEl]);

  const onPressUploadMedicalCertificate = useCallback(
    (event) => {
      const reader = new FileReader();
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      setMedicalCertificate({ file, fileName: file.name });

      reader.readAsDataURL(file);
      reader.onload = () => {
        inputNewEl.current.value = null;
      };
    },
    [inputNewEl],
  );

  const handleSubmit = () => {
    const parsedDate = moment(medicalCertificateDate);

    uploadMedicalCertificateControl.start({
      enrollmentId,
      medicalCertificate: medicalCertificate?.file,
      medicalCertificateDate: parsedDate,
    });
  };

  return (
    <Form>
      <Row>
        <Col span={12}>
          <span>Data:</span>
          <FormItem required>
            <DatePicker
              placeholder="Informe a data da perícia"
              locale={locale}
              format="DD/MM/YYYY"
              onChange={handleDateChange}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <span>Arquivo:</span>
          <FormItem>
            <Button
              onClick={onPressAttachFileButton}
              color="primary"
              size="sm"
              disabled={isLoading}
              icon={isLoading ? 'LoadingSpinner' : ''}
            >
              {medicalCertificate?.fileName
                ? `${medicalCertificate.fileName.substring(0, 20)}...`
                : 'Selecionar arquivo'}
            </Button>
          </FormItem>
        </Col>
        <input
          ref={inputNewEl}
          type="file"
          accept="application/pdf"
          onChange={onPressUploadMedicalCertificate}
          data-testid="certificate-file-importer"
          style={{ display: 'none' }}
        />
      </Row>
      <Button
        title="Enviar"
        size="sm"
        color="primary"
        onClick={handleSubmit}
        disabled={isLoading}
        icon={isLoading ? 'LoadingSpinner' : 'FileUpload'}
      />
    </Form>
  );
}
