import React from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { useModal } from '~/hooks/useModal';
import { getPersonDetail } from '~/store/people/actions';
import { ItemText, MenuItem } from '../OtherOptions.styled';
import { RequestResetModal } from './RequestResetModal';
import { ResetModal } from './ResetModal';

type ResetPersonButtonProps = {
  onClick?: () => void;
};

export function ResetPersonButton({ onClick }: ResetPersonButtonProps) {
  const personDetailControl = useAwaitControl(getPersonDetail);
  const personDetails = personDetailControl.result();

  const { openModal } = useModal();

  const canRequest = !personDetails?.isRequestResetActive && personDetails?.resettable;

  const canReset = personDetails?.isRequestResetActive && personDetails?.resettable;

  const openResetRequestModal = () => {
    onClick?.();
    openModal(<RequestResetModal />, {
      id: 'request-reset-modal',
      closable: true,
      title: 'Solicitar o reset do cadastro',
      width: '500px',
    });
  };

  const openResetModal = () => {
    onClick?.();
    openModal(<ResetModal />, {
      id: 'reset-modal',
      closable: true,
      title: 'Confirme o reset do cadastro',
      width: '430px',
    });
  };

  if (canReset) {
    return (
      <MenuItem onClick={openResetModal}>
        <ItemText>Resetar cadastro</ItemText>
      </MenuItem>
    );
  }

  if (canRequest) {
    return (
      <MenuItem onClick={openResetRequestModal}>
        <ItemText>Solicitar reset de cadastro</ItemText>
      </MenuItem>
    );
  }

  return null;
}
