import styled from 'styled-components';
import { Button, Tag, Typography } from '~/ui/components';

export const GroupTransaction = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TransactionDate = styled(Typography).attrs({
  type: 'caption',
  element: 'h4',
  weight: 600,
})`
  text-transform: uppercase;
`;

export const TransactionRow = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  .icon {
    flex-wrap: nowrap;
  }
`;

export const InstallmentTag = styled(Tag).attrs({
  bgColor: '#FFE5F3',
  textColor: '#A60058',
  small: true,
  rounded: true,
  noWrap: true,
})`
  font-size: 12px;
  font-weight: 700;
  gap: 5px;

  svg {
    width: 14px;
    height: 14px;
    color: #a60058 !important;
  }
`;

export const InfoRow = styled(Typography).attrs(({ weight }) => ({
  type: 'paragraphSmall',
  element: 'div',
  weight: weight || 400,
}))`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  svg {
    color: ${({ theme }) => theme.colors.brand.primary};
  }

  b {
    font-weight: 500;
  }

  button {
    height: auto;
  }

  .installment {
    color: ${({ theme }) => theme.colors.brand.primary};
  }
`;

export const RecipientName = styled(Typography).attrs({
  type: 'caption',
  element: 'span',
})`
  margin: -4px 0 6px 0;
`;

export const Title = styled(Typography).attrs({
  type: 'paragraphLarge',
  element: 'h6',
  weight: 500,
})`
  flex: 1;
`;

export const Time = styled(Typography).attrs({
  type: 'caption',
  element: 'span',
})``;

export const TransactionInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const TransactionButton = styled(Button).attrs({
  iconOnly: true,
  icon: 'ArrowRightCircle',
  variant: 'text',
})`
  svg {
    width: 20px;
  }
`;

export const CurrentInstallmentNumber = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  weight: 700,
  color: theme.colors.brand.primary,
}))``;
