import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import boxSad from '~/assets/images/box-sad.png';
import { Button, Flex, Typography } from '~/ui/components';
import { useDrawer } from '~/hooks/useDrawer';
import { themeToggleColor } from '~/common/utils/theme';

import { OpportunityBody, OpportunityContainer, OpportunityFooter } from '../../Opportunity.styled';

export function Error() {
  const { closeDrawer, setConfig } = useDrawer();
  const theme = useTheme();

  const buttonColor = themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' });

  const handleClose = () => {
    closeDrawer('opportunity-details');
  };

  useEffect(() => {
    setConfig('opportunity-details', {
      backButton: false,
      extraAction: null,
    });
  }, []);

  return (
    <OpportunityContainer>
      <OpportunityBody>
        <Flex direction="column" gap={24} pt={24} align="center">
          <img width={236} height={236} src={boxSad} alt="" />

          <Typography type="headingH5" weight={600} center>
            Erro na simulação
          </Typography>

          <Typography type="bodyLarge" weight={400} center>
            Aconteceu alguma coisa errada aqui. Informe ao cliente que será necessário realizar o
            contrato no aplicativo da meutudo.
          </Typography>
        </Flex>
      </OpportunityBody>

      <OpportunityFooter>
        <Button variant="outline" customColor={buttonColor} fullWidth rounded onClick={handleClose}>
          Fechar
        </Button>
      </OpportunityFooter>
    </OpportunityContainer>
  );
}
