import React from 'react';
import moment from 'moment';

import { InfoItem } from '~/components';

import { ParatiPreProposal, ParatiPreProposalReturn } from '~/typings/entities/Proposals';
import { InfoContainer, Section, SectionTitle } from '../../Returns.styled';
import { ReturnsTable } from './ReturnsTable';

export type PreProposalDetailsProps = {
  preProposal: ParatiPreProposal;
  preProposalReturns: ParatiPreProposalReturn[];
  contractId: string;
};

export function PreProposalDetails({
  contractId,
  preProposal,
  preProposalReturns,
}: PreProposalDetailsProps) {
  return (
    <>
      <Section>
        <SectionTitle>Detalhes</SectionTitle>

        <InfoContainer>
          <InfoItem title="Código de portabilidade" value={preProposal.portabilityNumber} />
          <InfoItem
            title="Data"
            value={moment(preProposal.creationDate).format('DD/MM/YYYY [às] HH:mm:ss')}
          />
          <InfoItem title="status" value={preProposal.status} />
          <InfoItem title="Código" value={preProposal.preProposalNumber} fullWidth />
        </InfoContainer>
      </Section>

      <Section>
        <SectionTitle>Retornos</SectionTitle>
        <ReturnsTable contractId={contractId} returns={preProposalReturns} />
      </Section>
    </>
  );
}
