/* @flow */

export const CONCESSION_GET_REQUEST = 'CONCESSION_GET_REQUEST';
export const CONCESSION_GET_SUCCESS = 'CONCESSION_GET_SUCCESS';
export const CONCESSION_GET_FAILURE = 'CONCESSION_GET_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: CONCESSION_GET_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: CONCESSION_GET_FAILURE,
      payload: resource,
    });
  };
}

export default function GET(params) {
  return dispatch => {
    dispatch({
      type: CONCESSION_GET_REQUEST,
      payload: params,
    });
  };
}
