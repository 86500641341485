import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Drawer as DrawerDefault } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { DrawerHeader } from '~/ui/components/Drawer/DrawerHeader';
import { DrawerBody } from '~/ui/components/Drawer/Drawer.styled';

type Config = DrawerProps & {
  width?: number;
  extraAction?: ReactNode;
  noPadding?: boolean;
  backButton?: boolean;
  onBackClick?: () => void;
};

interface Drawer {
  component?: any;
  config: Config;
  opened: boolean;
  id: string;
  loading?: boolean;
}

interface DrawerContextData {
  openDrawer(id: string, component: any, config?: Config): void;
  closeDrawer(id: string): void;
  closeAllDrawers(): void;
  setConfig(id: string, config?: Config, replace?: boolean): void;
  setLoading(id: string, loading: boolean): void;
}

const defaultConfig: DrawerProps = {
  closable: false,
};

const DrawerContext = createContext<DrawerContextData>({} as DrawerContextData);

function DrawerProvider({ children }: PropsWithChildren<{}>) {
  const [drawers, setDrawers] = useState<Drawer[]>([]);

  const clearClosedDrawers = useCallback(() => {
    setTimeout(() => {
      if (drawers.some((el) => el.opened === false)) {
        setDrawers((currentValue) => currentValue.filter((el) => el.opened !== false));
      }
    }, 500);
  }, [drawers]);

  const closeAllDrawers = () => {
    setDrawers((currentDrawers) => currentDrawers.map((drawer) => ({ ...drawer, opened: false })));
  };

  const closeDrawer = (id: string) => {
    setDrawers((currentDrawers) => {
      const restOfDrawers = currentDrawers.filter((el) => el.id !== id);
      const selectedDrawer = currentDrawers.find((el) => el.id === id);

      if (selectedDrawer) {
        selectedDrawer.opened = false;
      }
      return [...restOfDrawers, selectedDrawer];
    });
  };

  const setConfig = (id: string, config?: Config, replace?: boolean) => {
    setDrawers((currentDrawers) => {
      const restOfDrawers = currentDrawers.filter((el) => el.id !== id);
      const selectedDrawer = currentDrawers.find((el) => el.id === id);

      if (selectedDrawer) {
        selectedDrawer.config = replace ? config : { ...selectedDrawer.config, ...config };
        return [...restOfDrawers, selectedDrawer];
      }

      return currentDrawers;
    });
  };

  const setLoading = (id: string, loading?: boolean) => {
    setDrawers((currentDrawers) => {
      const restOfDrawers = currentDrawers.filter((el) => el.id !== id);
      const selectedDrawer = currentDrawers.find((el) => el.id === id);

      if (selectedDrawer) {
        selectedDrawer.loading = loading;

        selectedDrawer.config = {
          ...selectedDrawer.config,
          maskClosable: loading ? false : selectedDrawer.config?.maskClosable,
          keyboard: loading ? false : selectedDrawer.config?.keyboard,
        };

        return [...restOfDrawers, selectedDrawer];
      }

      return currentDrawers;
    });
  };

  const openDrawer = useCallback(
    (id: string, component: any, config?: Config) => {
      setDrawers((currentDrawers) => {
        const restOfDrawers = currentDrawers.filter((el) => el.id !== id);
        let selectedDrawer = currentDrawers.find((el) => el.id === id);

        if (selectedDrawer) {
          selectedDrawer.opened = true;
        } else {
          selectedDrawer = {
            id,
            opened: true,
            component,
            config,
          };
        }
        return [...restOfDrawers, selectedDrawer];
      });
    },
    [setDrawers],
  );

  const handleOnClose = (drawer) => {
    if (drawer?.config?.onClose) {
      drawer?.config?.onClose();
    }

    closeDrawer(drawer?.id);
  };

  useEffect(() => {
    clearClosedDrawers();
  }, [drawers]);

  return (
    <DrawerContext.Provider
      value={{ openDrawer, closeDrawer, closeAllDrawers, setConfig, setLoading }}
    >
      {children}
      {drawers.map((drawer) => {
        const { extraAction, noPadding, backButton, onBackClick, ...config } = drawer?.config || {};

        return (
          <DrawerDefault
            key={drawer.id}
            {...defaultConfig}
            {...config}
            width={config?.width ? `min(${config?.width}px, 100%)` : null}
            visible={drawer.opened}
            onClose={() => handleOnClose(drawer)}
            className={`mt-drawer ${noPadding ? 'no-padding' : ''} ${
              drawer.loading ? 'loading' : ''
            }`}
          >
            {drawer.config?.title && (
              <DrawerHeader
                title={config?.title}
                closable={config?.closable}
                onClose={() => handleOnClose(drawer)}
                extraAction={extraAction}
                backButton={backButton}
                onBackClick={onBackClick}
              />
            )}
            <DrawerBody className="mt-drawer-body">{drawer.component}</DrawerBody>
          </DrawerDefault>
        );
      })}
    </DrawerContext.Provider>
  );
}

function useDrawer(): DrawerContextData {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useDrawer must be used inside of DrawerProvider');
  }

  return context;
}

export { DrawerProvider, useDrawer };
