import { Icon } from 'antd';
import React, { useContext } from 'react';

import CloseIcon from '~/assets/icons/close.svg';
import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';
import { PROFILE_FILTER_NAMES } from '~/screens/DistributedContracts/components';
import { Divider, PopoverHeader, Radio, RadioDivider, Title } from './styled';

interface HourFilterProps {
  closePopover: () => void;
}

const radioStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '18px',
};

export default function ProfileFilter({ closePopover }: HourFilterProps) {
  const { setQueueConfig, queueFilter } = useContext(ContractDetailsContext);

  const handleClose = () => {
    closePopover();
  };

  const handleChange = (e) => {
    setQueueConfig(e.target.value);
    closePopover();
  };

  return (
    <>
      <PopoverHeader>
        <Title>Filtro de perfil</Title>
        <Icon component={CloseIcon} onClick={handleClose} style={{ fontSize: '24px' }} />
      </PopoverHeader>
      <Divider />
      <Radio.Group
        style={{ width: '100%', marginBottom: '10px' }}
        onChange={handleChange}
        value={queueFilter}
      >
        <Radio style={radioStyle} value="TRANSACTIONAL">
          {PROFILE_FILTER_NAMES.TRANSACTIONAL}
        </Radio>

        <RadioDivider />

        <Radio style={radioStyle} value="ONBOARDING">
          {PROFILE_FILTER_NAMES.ONBOARDING}
        </Radio>
      </Radio.Group>
    </>
  );
}
