/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import queryString from 'query-string';

import {
  FETCH_AVAILABLE_LOAN_HISTORIES_REQUEST,
  success,
  failure,
} from './action';

const fetchContracts = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(FETCH_AVAILABLE_LOAN_HISTORIES_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(({ params }) =>
      AjaxRequest.open(
        'get',
        `/admin/contractloancustom/availableloanhistories?${queryString.stringify(
          params,
        )}`,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(fetchContracts);
