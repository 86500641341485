const CreatedLocation = (props: any) => {
  switch (props.value) {
    case 'APP':
      return 'Aplicativo';
    case 'EXTERNAL':
      return 'Externo';
    default:
      return null;
  }
};

export default CreatedLocation;
