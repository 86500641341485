import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Image from '~/assets/images/person-airplane.png';
import {
  Filter,
  SearchBar,
  Switch,
  TableColumns,
  Tag,
  Tooltip,
  Typography,
  selectedFiltersType,
  CustomizableModalContent,
} from '~/ui/components';
import { AlertCircle } from '~/ui/assets/icons';
import { useAwaitControl } from 'react-redux-await-control';
import { getRolesByUser } from '~/store/permLevels/actions';
import { UserType } from '~/typings/entities/user';
import {
  Container,
  CustomizedTable,
  FilterContent,
  LeftContent,
  PermFilter,
  RightContent,
  SearchSection,
} from './PermissionModal.styled';

const permissionFilter = [
  {
    label: 'Permissões ativas',
    value: 'ativas',
  },
  {
    label: 'Permissões desativadas',
    value: 'desativadas',
  },
  {
    label: 'Permissões temporárias',
    value: 'temporarias',
  },
];

type PermissionRow = {
  functionality?: string;
  description: string;
  enable: boolean;
  name: string;
  id: string;
};

interface IPermissionModalProps {
  user: UserType;
}

export function PermissionModal({ user }: IPermissionModalProps) {
  const [searchValue, setSearchValue] = useState('');
  const [enabledLines, setEnableLines] = useState<(number | string)[]>([]);
  const [filter, setFilter] = useState<selectedFiltersType>({});

  const getUserPermissions = useAwaitControl(getRolesByUser);
  const usersControl = getUserPermissions.result();
  const loadingUsers = getUserPermissions.isRunning();

  const fixedPermissions = useMemo(
    () => usersControl.filter((permission) => permission.enable).map((permission) => permission.id),
    [usersControl],
  );

  const handleOnChangeSwitch = useCallback(
    (_, data) => {
      const newEnabledLines = [...enabledLines];

      if (newEnabledLines.includes(data.id)) {
        newEnabledLines.splice(newEnabledLines.indexOf(data.id), 1);
      } else {
        newEnabledLines.push(data.id);
      }

      setEnableLines(newEnabledLines);
    },
    [enabledLines, user],
  );

  const handleOnChangeSearchBar = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const handleOnChangeFilter = useCallback((value) => {
    setFilter(value);
  }, []);

  const columns: TableColumns<PermissionRow> = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: () => 'HABILITAR/DESABILITAR',
        cell: (data) => (
          <>
            <Switch
              checked={data.row.original.enable || enabledLines.includes(data.row.original.id)}
              disabled={
                !fixedPermissions.length
                  ? data.row.original.enable
                  : fixedPermissions.includes(data.row.original.id)
              }
              onChange={(e) => handleOnChangeSwitch(e, data.row.original)}
              label={data.row.original.name}
            />
            {!data.row.original.enable && enabledLines.includes(data.row.original.id) && (
              <Tag rounded small bgColor="#D6E4FF" textColor="#0100B2">
                <Tooltip small content={<Typography>Funcionalidade temporária</Typography>}>
                  <AlertCircle width={13} height={13} color="#0100B2" /> TEMP
                </Tooltip>
              </Tag>
            )}
          </>
        ),
      },
      {
        accessorKey: 'description',
        header: () => (
          <Typography type="footnote" element="div" color="#868686">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              ONDE ESTÁ?{' '}
              <Tooltip
                content={
                  <Typography>Em qual área do Backoffice fica essa funcionalidade</Typography>
                }
                small
              >
                <AlertCircle width={13} height={13} color="#1A1A1A" />
              </Tooltip>
            </div>
          </Typography>
        ),
        cell: (data) => (
          <Typography
            type="footnote"
            color={enabledLines.includes(data.row.original.id) ? '#1A1A1A' : '#9A9A9A'}
          >
            {data.row.original.description}
          </Typography>
        ),
      },
      {
        accessorKey: 'functionality',
        header: () => 'FUNCIONALIDADE/SUB',
        cell: (data) => (
          <PermFilter
            label="Dropdown Content"
            options={[]}
            onChange={() => null}
            withSearchBar
            isDisabled={!enabledLines.includes(data.row.original.id)}
          />
        ),
      },
    ],
    [enabledLines, fixedPermissions, filter],
  );

  const handlePermTable = useMemo(
    () =>
      usersControl.filter((data) => {
        const searchFilter =
          data.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ?? true;

        const activeFilter =
          Object.keys(filter).length > 0 && Object.keys(filter).includes('ativas')
            ? data.id.includes(String(enabledLines))
            : true;

        const disabledFilter =
          Object.keys(filter).length > 0 && Object.keys(filter).includes('desativadas')
            ? !data.id.includes(String(enabledLines))
            : true;

        const temporaryFilter =
          Object.keys(filter).length > 0 && Object.keys(filter).includes('temporarias')
            ? !data.enable
            : true;

        return searchFilter && activeFilter && disabledFilter && temporaryFilter;
      }),
    [usersControl, fixedPermissions, searchValue, filter, enabledLines],
  );

  useEffect(() => {
    getUserPermissions.start({ userId: user.id });
  }, [user]);

  return (
    <Container>
      <LeftContent>
        <CustomizableModalContent
          title="Editar premissões"
          text="As permissões são definidas automaticamente quando criamos um novo usuário, de acordo com o cargo dele. Cada cargo já tem um conjunto de configurações pré-escolhidas. Você pode adicionar novas permissões, mas não pode tirar as que já estão lá."
          tags={['Aviso de leitura']}
          image={Image}
          imageWidth="258px"
          imageMargin="24px"
          alignItems="start"
        />
      </LeftContent>
      <RightContent>
        <SearchSection>
          <SearchBar placeholder="Buscar funcionalidade" onChange={handleOnChangeSearchBar} />
          <FilterContent>
            <Filter
              label="Filtrar permissões"
              options={permissionFilter}
              onChange={handleOnChangeFilter}
              withCounter
              filterDirection="right"
            />
          </FilterContent>
        </SearchSection>
        <CustomizedTable
          columns={columns}
          data={handlePermTable}
          rowHeight={58}
          withoutBorders
          isTHeadFixed
          maxHeight={366}
          loading={loadingUsers}
          shimmerLines={10}
        />
      </RightContent>
    </Container>
  );
}
