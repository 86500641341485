import React, { useEffect, useMemo } from 'react';

import screenRegister from '~/hoc/screenRegister';
import { getReports, getTableauToken } from '~/store/utilities/actions';
import { useAwaitControl } from 'react-redux-await-control';
import useQueryParams from '~/hooks/useQueryParams';
import { Loading } from '~/components';
import { sendAuditLog } from '~/store/logs/actions';
import { getLoggedUser } from '~/store/user/actions';
import { EmbedContainer, LoadingContainer } from './Reports.styled';

function Reports() {
  const getTableauTokenControl = useAwaitControl(getTableauToken);
  const getReportsControl = useAwaitControl(getReports);
  const getLoggedUserControl = useAwaitControl(getLoggedUser);
  const sendAuditLogControl = useAwaitControl(sendAuditLog);
  const tableauTokenLoading = getTableauTokenControl.isRunning();
  const reportsControlLoading = getReportsControl.isRunning();
  const tableauTokenSuccess = getTableauTokenControl.isSuccessful();
  const tableauToken = getTableauTokenControl.result();
  const loggedUser = getLoggedUserControl.result();
  const reports = getReportsControl.result();
  const query = useQueryParams();
  const reportId = query.get('report');

  const hasPermission = useMemo(() => reports.some((el) => el.id === Number(reportId)), [reports]);

  function initTableauReport(token: string, patternUrl: string) {
    const containerDiv = document.getElementById('tableauViz');
    const url = patternUrl.replace('{token}', token);
    const options = {
      hideTabs: true,
      width: containerDiv.offsetWidth,
      height: containerDiv.offsetHeight,
    };

    try {
      const viz = new (window as any).tableau.Viz(containerDiv, url, options);

      const reportData = {
        userId: loggedUser.id,
        eventName: 'TABLEAU_GET_REPORT',
        data: {
          report: patternUrl,
          username: loggedUser.login,
        },
      };

      sendAuditLogControl.start(reportData);
    } catch (e) {
      console.warn('[debug] Erro ao tentar visualizar gráfico do tableau. Erro:', e);
    }
  }

  useEffect(() => {
    if (hasPermission) {
      getTableauTokenControl.start();
    }
  }, [reportId, hasPermission]);

  useEffect(() => {
    if (tableauTokenSuccess && hasPermission) {
      const url = reports.find((el) => el.id === Number(reportId))?.url;
      initTableauReport(tableauToken, url);
    }
  }, [tableauTokenSuccess, tableauToken, hasPermission]);

  if (tableauTokenLoading || reportsControlLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (!hasPermission) {
    return <p>Você não tem permissão para acessar este relatório!</p>;
  }

  return <EmbedContainer id="tableauViz" />;
}

export default screenRegister({
  screenName: 'Reports',
  path: '/backoffice/reports',
  headerTitle: 'Relatórios',
})(Reports);
