import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withPropsOnChange, withState, lifecycle } from 'recompose';

import { mapObject } from 'underscore';

import theme from '~/themes/aphroditeTheme/theme';
import screenRegister from '~/hoc/screenRegister';
import { message } from '~/common';
import { fetchAgreements, fetchFinancialInstitutions } from '~/store/utilities/actions';

import { registerOfferPerson } from '~/store/offers/actions';

import OfferPersonRegistry from './OfferPersonRegistry';

const { withStyles } = theme;

OfferPersonRegistry.defaultProps = {};

const Styles = () => ({});

function componentDidMount() {
  this.props.dispatch(fetchAgreements.start());
  this.props.dispatch(fetchFinancialInstitutions.start());
}

function fetchFinancialInstitutionsByAgreementId({ dispatch }) {
  return (params) => {
    dispatch(fetchFinancialInstitutions.start(params));
  };
}

function fetchAllFinancialInstitutions({ dispatch }) {
  return () => dispatch(fetchFinancialInstitutions.start());
}

function onSubmit({ dispatch, isRegisteringOfferPerson }) {
  return (params) => {
    isRegisteringOfferPerson(true);
    dispatch(registerOfferPerson.start(params));
  };
}

function mapStateToProps(state) {
  return {
    agreements: fetchAgreements.getResult()(state),
    financialInstitutions: fetchFinancialInstitutions.getResult()(state),
    registerOfferPersonSuccess: registerOfferPerson.isSuccessful()(state),
    registerOfferPersonFailure: registerOfferPerson.hasFailure()(state),
  };
}

function receiveChanges(
  prevProps,
  {
    agreements,
    financialInstitutions,
    registerOfferPersonSuccess,
    registerOfferPersonFailure,
    history,
    isRegisteringOfferPerson,
  },
) {
  if (Immutable.is(prevProps.agreements, agreements) === false) {
    return true;
  }

  if (Immutable.is(prevProps.financialInstitutions, financialInstitutions) === false) {
    return true;
  }

  if (
    Immutable.is(prevProps.registerOfferPersonSuccess, registerOfferPersonSuccess) &&
    registerOfferPersonSuccess
  ) {
    isRegisteringOfferPerson(false);
    history.push({
      pathname: '/backoffice/offers-people',
      state: {
        registeredWithSuccess: true,
      },
    });

    return true;
  }

  if (
    Immutable.is(prevProps.registerOfferPersonFailure, registerOfferPersonFailure) &&
    registerOfferPersonFailure
  ) {
    isRegisteringOfferPerson(false);
    message.error('Falha ao cadastrar novo fator de empréstimo');
    return false;
  }

  return false;
}

function propagateStateChangeToProps(state) {
  return mapObject(
    {
      agreements: state.agreements,
      factorTypes: state.factorTypes,
      financialInstitutions: state.financialInstitutions,
    },
    (state) => (typeof state === 'undefined' ? [] : state.getIn?.(['payload']) || state),
  );
}

export default compose(
  withStyles(Styles),
  withRouter,
  screenRegister({
    screenName: 'OfferPersonRegistry',
    path: '/backoffice/people/:personId/offer-person/register',
    headerTitle: 'Nova Oferta Individual',
  }),
  connect(mapStateToProps),
  withState('registering', 'isRegisteringOfferPerson', false),
  withState('personId', 'setPersonId', null),
  lifecycle({
    componentDidMount,
  }),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    fetchAllFinancialInstitutions,
    fetchFinancialInstitutionsByAgreementId,
    onSubmit,
  }),
)(OfferPersonRegistry);
