import React from 'react';

import { List } from 'antd';

import modalBuilder from '~/hoc/modalBuilder';

const MODAL_NAME = 'PENDENCIES';

type PendenciesProps = {
  pendencies: string[],
}

function Pendencies({ pendencies }: PendenciesProps) {
  return (
    <List
      bordered
      dataSource={pendencies}
      renderItem={(item) => (
        <List.Item>
          {item}
        </List.Item>
      )}
    />
  );
}

export default modalBuilder(Pendencies, MODAL_NAME, {
  title: 'Status lógico',
  centered: true,
});
