// @flow
import React, { useCallback } from 'react';
import { useWizard } from 'react-use-wizard';
import { useAwaitControl } from 'react-redux-await-control';
import { Col, Icon, Row } from 'antd';

import masked from '~/common/masked';
import { simulateRefin, updateContractValues } from '~/store/contracts/actions';
import type { Contract } from '~/typings/entities/contract';
import {
  CustomFormButton,
  Label,
  StepFooter,
  StepTitle,
  Value,
} from './styled';

type PreventErrorProps = {
  id: number,
  contract: Contract,
  cancel: () => any,
};

const { toMoney, toPercentage } = masked;

export default function PreventError({
  id,
  contract,
  cancel,
}: PreventErrorProps) {
  const { nextStep } = useWizard();
  const [simulateRefinControl, updateContractControl] = useAwaitControl([
    simulateRefin,
    updateContractValues,
  ]);

  const {
    installmentValue,
    numberOfInstallments,
    valueForDeposit,
    nominalRatePerMonth,
  } = simulateRefinControl.result(id) || {};

  const updateContract = useCallback(() => {
    updateContractControl.start(
      {
        forceUpdateMargin: false,
        financialData: {
          installmentValue,
          numberOfInstallments,
          nominalRate: nominalRatePerMonth / 100,
        },
        id,
      },
      { actionId: id },
    );
    nextStep();
  }, [
    id,
    nextStep,
    installmentValue,
    numberOfInstallments,
    nominalRatePerMonth,
  ]);

  return (
    <div>
      <StepTitle>Você deseja alterar os valores do contrato?</StepTitle>
      <div>
        <Row>
          <Col span={24}>
            <Col span={6} offset={2}>
              <Label align="end">valor de parcela</Label>
              <Label align="end">taxa de juros</Label>
              <Label align="end">parcelas</Label>
              <Label align="end">troco</Label>
            </Col>
            <Col span={5} offset={1}>
              <Value>{toMoney(contract.installmentValue)}</Value>
              <Value>
                {`${toPercentage(
                  contract.nominalRatePerMonth,
                )} a.m.`}
              </Value>
              <Value>{`x${contract.numberOfInstallments}`}</Value>
              <Value>{toMoney(contract.valueForDeposit)}</Value>
            </Col>
            <Col span={6} offset={1}>
              <Value>
                <Icon type="right" />
                {' '}
                {toMoney(installmentValue)}
              </Value>
              <Value>
                <Icon type="right" />
                {' '}
                {`${toPercentage(nominalRatePerMonth / 100)} a.m.`}
              </Value>
              <Value>
                <Icon type="right" />
                {' '}
                {`x${numberOfInstallments}`}
              </Value>
              <Value>
                <Icon type="right" />
                {' '}
                {toMoney(valueForDeposit)}
              </Value>
            </Col>
          </Col>
        </Row>
      </div>
      <StepFooter>
        <Col>
          <CustomFormButton onClick={cancel} type="secondary" size="large">
            Não
          </CustomFormButton>
          <CustomFormButton
            onClick={updateContract}
            type="primary"
            size="large"
          >
            Sim
          </CustomFormButton>
        </Col>
      </StepFooter>
    </div>
  );
}
