import styled from 'styled-components';
import { Typography } from '~/ui/components';

export const Container = styled.div<{ bgColor: string }>`
  background: ${({ bgColor }) => bgColor || '#fff'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 54px 24px;
  text-align: center;
  gap: 15px;
  width: 100%;
  
  img {
    width: 300px;
  }
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'headingSmall',
  color: theme.colors.gray['900'],
}))``;

export const Message = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  color: theme.colors.gray['900'],
  weight: 500,
  width: 553,
}))``;
