import styled from 'styled-components';
import { Drawer as AntDrawer } from 'antd';
import { themeToggleValue } from '~/common/utils/theme';

export const Drawer = styled(AntDrawer)`
  position: ${({ position }) => position ?? 'fixed'};

  .ant-drawer-mask {
    background-color: ${({ theme }) =>
      themeToggleValue(theme, 'rgba(0, 0, 0, 0.35)', { dark: 'rgba(0, 0, 0, 0.80)' })};
  }

  .ant-drawer-content-wrapper {
    border-radius: 16px;
    overflow: hidden;
  }

  .ant-drawer-header {
    text-align: center;
    padding: 24px 24px 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  .ant-drawer-content {
    height: auto;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;
