/* @flow */

export const GET_CONTRACT_LOGS_REQUEST = 'GET_CONTRACT_LOGS_REQUEST';
export const GET_CONTRACT_LOGS_SUCCESS = 'GET_CONTRACT_LOGS_SUCCESS';
export const GET_CONTRACT_LOGS_FAILURE = 'GET_CONTRACT_LOGS_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_LOGS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_LOGS_FAILURE,
      payload: resource,
    });
  };
}

export default function get(contractId) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_LOGS_REQUEST,
      payload: {
        contractId,
      },
    });
  };
}
