import React, { useEffect } from 'react';
import moment from 'moment';
import { Timeline } from 'antd';
import { useAwaitControl } from 'react-redux-await-control';

import { getFileHistory } from '~/store/files/actions';
import { useModal } from '~/hooks/useModal';
import {
  LoadingIcon,
  HistoryDate,
  HistoryRow,
  LoadingContainer,
  TimelineContainer,
} from './FileHistoryModal.styled';
import { ImageHistory } from './ImageHistory';

interface ContractHistoryProps {
  fileType: string;
  type: 'PERSON' | 'CONTRACT';
  id?: string | number;
  documentType?: string | number;
}

export function FileHistoryModal({ fileType, id, type, documentType }: ContractHistoryProps) {
  const { closeAll } = useModal();
  const getFileHistoryControl = useAwaitControl(getFileHistory);

  const fileHistory = getFileHistoryControl.result();
  const fileHistoryLoading = getFileHistoryControl.isRunning();
  const fileHistoryFailed = getFileHistoryControl.hasFailure();

  useEffect(() => {
    if (fileHistoryFailed) {
      closeAll();
    }
  }, [fileHistoryFailed]);

  useEffect(() => {
    const formattedFileType = fileType.replace(/[^a-zA-Z ]/g, '');
    const personId = type === 'PERSON' ? id : undefined;
    const contractId = type === 'CONTRACT' ? id : undefined;
    getFileHistoryControl.start({
      fileType: formattedFileType,
      personId,
      contractId,
      documentType,
    });

    return () => {
      getFileHistoryControl.clear();
    };
  }, []);

  const formatDate = (date) => moment(date).format('DD [de] MMM YYYY HH[h]mm');

  if (fileHistoryLoading) {
    return (
      <LoadingContainer>
        <LoadingIcon />
      </LoadingContainer>
    );
  }

  return (
    <TimelineContainer>
      <Timeline>
        {fileHistory?.map((file, key) => (
          <HistoryRow key={key}>
            <HistoryDate>{formatDate(file.uploadDate)}</HistoryDate>

            <ImageHistory file={file} />
          </HistoryRow>
        ))}
      </Timeline>
    </TimelineContainer>
  );
}
