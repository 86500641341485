import styled, { css } from 'styled-components';
import { Button, Tag, Typography } from '~/ui/components';
import {
  Loading as LoadingIcon,
  LoadingSpinner,
  FolderDownload,
  DownloadAlt,
  CloudDownload,
} from '~/ui/assets/icons';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

type LoadingProps = {
  loading?: boolean | 1 | 0;
};

type InactiveProps = {
  inactive?: boolean | 1 | 0;
};

export const Container = styled.div<LoadingProps>`
  ${({ loading }) =>
    loading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

export const HeaderContainer = styled.div`
  margin: ${({ isContract }) => (isContract ? '0 28px 0' : '22px 28px 0')};
  border-bottom: 1px solid #dddddd;
  padding-bottom: 16px;
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  element: 'h5',
  weight: 500,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  margin-bottom: 4px;
  display: flex;
  align-items: baseline;
`;

export const Text = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  width: 360,
  color: theme.colors.gray['900'],
}))``;

export const ActionButton = styled(Button).attrs(({ theme, tooltipPlacement }) => ({
  iconOnly: true,
  size: 'sm',
  variant: 'text',
  customColor: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
  tooltipPlacement: tooltipPlacement || 'topLeft',
}))`
  padding: 0;

  &:focus-visible {
    outline: none;
  }
`;

export const FileListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;

  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray['50']};
  margin: 4px 0;
`;

export const FolderDownloadIcon = styled(FolderDownload)`
  margin-right: 8px;
  margin-bottom: 2px;
  fill: ${({ theme }) => theme.colors.primary.main};
`;

export const FileDownloadIcon = styled(DownloadAlt)`
  margin-right: 8px;
  margin-bottom: 2px;
  fill: ${({ theme }) => theme.colors.primary.main};
`;

export const CloudDownloadIcon = styled(CloudDownload)`
  margin-right: 8px;
  margin-bottom: 2px;
  fill: ${({ theme }) => theme.colors.primary.main};
`;

export const LoadingSpinnerIcon = styled(LoadingSpinner)`
  margin-right: 8px;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const Option = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  color: theme.colors.gray.main,
}))``;

export const SwitchRow = styled.div`
  justify-content: end;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Files = styled.div`
  padding: 26px 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  justify-content: flex-start;
`;

export const FileContainer = styled.div<LoadingProps & InactiveProps>`
  max-width: 246px;
  width: 100%;
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  opacity: ${({ loading, inactive }) => (loading || inactive ? 0.5 : 1)};
  position: relative;
  cursor: pointer;
`;

export const FileDetails = styled.div`
  margin: 12px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray['100']};
  padding-bottom: 12px;
  max-width: 246px;
  width: 100%;
`;

export const FileName = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  margin-bottom: 4px;
`;

export const FileSize = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'span',
  color: theme.colors.gray['500'],
}))`
  margin-bottom: 4px;
`;

export const FileDate = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'span',
  color: theme.colors.gray['500'],
}))``;

export const ImagePreview = styled.div`
  border-radius: 8px;
  height: 246px;
  width: 246px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 246px;
    object-fit: cover;
  }

  ${({ loading }) =>
    loading &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.colors.gray['50']};
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  p {
    color: ${({ theme }) => theme.colors.gray['500']};
    font-size: ${({ theme }) => theme.typography.types.footnote.size};
    font-weight: ${({ theme }) => theme.typography.types.footnote.weight};
    font-family: ${({ theme }) => theme.typography.types.footnote.libreFranklin};
  }
`;

export const Loading = styled(LoadingIcon)`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.primary['500']};
`;

export const FileDownload = styled(FileDownloadIcon).attrs(({ theme }) => ({
  fill: theme.colors.primary.main,
}))``;

export const ImageOriginTag = styled(Tag).attrs(({ theme }) => ({
  bgColor: theme.colors.secondary[50],
  textColor: theme.colors.primary[800],
  small: true,
  rounded: true,
  noWrap: true,
}))`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  font-weight: 700;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  cursor: default;
  gap: 5px;

  svg {
    cursor: default;
  }
`;

export const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
`;
