import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import { listQIActionProposals } from '~/store/proposals/actions';
import colors from '~/ui/theme/colors';
import { Button, TableColumns } from '~/ui/components';
import { useModal } from '~/hooks/useModal';

import { QiProposalAction } from '~/typings/entities/Proposals';
import { SmallTable } from '../Returns.styled';
import { History } from './History';

export type ActionsProps = {
  qiProposalId: number;
};

export function Actions({ qiProposalId }: ActionsProps) {
  const { openModal } = useModal();
  const listQiActionsControl = useAwaitControl(listQIActionProposals);

  const actions = listQiActionsControl.result(qiProposalId);
  const actionsLoading = listQiActionsControl.isRunning(qiProposalId);

  const handleHistoryClick = (id) => () => {
    openModal(<History qiActionProposalId={id} />, {
      title: 'Histórico do retorno',
      width: 'auto',
    });
  };

  const columns: TableColumns<QiProposalAction> = [
    { accessorKey: 'id', header: () => 'ID' },
    { accessorKey: 'actionType', header: () => 'TIPO' },
    { accessorKey: 'actionStatus', header: () => 'STATUS' },
    {
      accessorKey: 'creationDate',
      header: () => 'DATA',
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
    },
    {
      id: 'actions',
      header: () => 'HISTÓRICO',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <Button
          iconOnly
          icon="FileSearch"
          variant="text"
          customColor={colors.primary.main}
          onClick={handleHistoryClick(info.row.original.id)}
          tooltip="Ver histórico"
          size="sm"
          tooltipPlacement="bottom"
        />
      ),
    },
  ];

  useEffect(() => {
    if (qiProposalId && (!actions || !actions.length)) {
      listQiActionsControl.start({ qiProposalId }, { actionId: qiProposalId });
    }
  }, [qiProposalId]);

  return (
    <SmallTable
      noItemsMessage="Nenhum retorno encontrado"
      loading={actionsLoading}
      data={actions}
      columns={columns}
      rowHeight={64}
    />
  );
}
