/* @flow */

export const UPLOAD_MEDICAL_CERTIFICATE_REQUEST =
  'UPLOAD_MEDICAL_CERTIFICATE_REQUEST';
export const UPLOAD_MEDICAL_CERTIFICATE_SUCCESS =
  'UPLOAD_MEDICAL_CERTIFICATE_SUCCESS';
export const UPLOAD_MEDICAL_CERTIFICATE_FAILURE =
  'UPLOAD_MEDICAL_CERTIFICATE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: UPLOAD_MEDICAL_CERTIFICATE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: UPLOAD_MEDICAL_CERTIFICATE_FAILURE,
      payload: resource,
    });
  };
}

export default function uploadMedicalCertificate(params) {
  return dispatch => {
    dispatch({
      type: UPLOAD_MEDICAL_CERTIFICATE_REQUEST,
      payload: params,
    });
  };
}
