import styled, { css } from 'styled-components';

export const TabsContainer = styled.div`
  width: 100%;
  min-width: fit-content;
`;

export const TabsList = styled.ul`
  list-style: none;
  display: flex;
  gap: 4px;
  align-items: flex-end;
  min-height: 40px;
  margin: 0;
  padding: 0;
`;

interface TapProps {
  active?: boolean;
  bgColor?: string;
  loading?: boolean;
}

export const Tab = styled.li<TapProps>`
  background-color: ${({ bgColor }) => bgColor};
  padding: 0 16px;
  height: ${({ active }) => (active ? '40px' : '28px')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px 8px 0 0;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  transition: all 0.3s ease;

  &,
  .typography {
    transition: all 0.3s ease;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  ${({ loading }) =>
    loading &&
    css`
      min-width: 200px;
      pointer-events: none;
      opacity: 0.5;

      svg {
        fill: ${({ theme }) => theme.colors.white.main};
      }
    `}
`;

interface DividerProps {
  color: string;
}

export const Divider = styled.div<DividerProps>`
  height: 12px;
  width: 1px;
  background-color: ${({ color }) => color};
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
