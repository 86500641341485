import React from 'react';
import { SwitchProps as SwitchPropsDefault } from 'antd/lib/switch';
import theme from '~/ui/theme';
import { Container, StyledSwitch, SwitchLabel } from './Switch.styled';

export type SwitchProps = SwitchPropsDefault & {
  label?: string | React.ReactNode;
  small?: boolean;
  color?: string;
};

export function Switch({ label, small, color = theme.colors.gray['800'], ...props }: SwitchProps) {
  const id = `switch-${Math.random()}`;
  return (
    <Container>
      <StyledSwitch size={small ? 'small' : 'default'} {...props} id={id} />
      {label && (
        <SwitchLabel htmlFor={id} $color={color}>
          {label}
        </SwitchLabel>
      )}
    </Container>
  );
}
