import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { listReturnsParati } from '~/store/proposals/actions';

import { NoData } from '~/screens/ContractDetails/Panels/Returns/Returns.styled';
import { PreProposalsTable } from './PreProposals/PreProposalsTable';
import { AccountTable } from './Account/AccountTable';
import { ProposalsTable } from './Proposals/ProposalsTable';

export type ParatiReturnsProps = {
  contractId: string;
  loan?: string;
};

export function ParatiReturns({ contractId, loan }: ParatiReturnsProps) {
  const listReturnsParatiControl = useAwaitControl(listReturnsParati);
  const { paratiAccount, paratiPreProposals, paratiProposals } =
    listReturnsParatiControl.result(contractId);

  useEffect(() => {
    listReturnsParatiControl.start({ contractId }, { actionId: contractId });
  }, [contractId]);

  if (!paratiAccount && !paratiPreProposals?.length && !paratiProposals?.length) {
    return (
      <NoData>
        <p>Nenhum retorno encontrado</p>
      </NoData>
    );
  }

  return (
    <>
      <AccountTable contractId={contractId} />
      <PreProposalsTable contractId={contractId} />
      <ProposalsTable contractId={contractId} loan={loan} />
    </>
  );
}
