const SerachSelect = (input, option) => {
  if (!option) return false;
  const normalizedOption = option
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  const normalizedSearch = input
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  return normalizedOption.includes(normalizedSearch);
};

export default SerachSelect;
