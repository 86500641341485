import { Map } from 'immutable';

import {
  FETCH_REFINANCED_CONTRACTS_REQUEST,
  FETCH_REFINANCED_CONTRACTS_FAILURE,
  FETCH_REFINANCED_CONTRACTS_SUCCESS,
} from './action';

const initialState = Map({
  type: FETCH_REFINANCED_CONTRACTS_REQUEST,
  payload: {
    entries: [],
    totalEntries: 0,
  },
});

function reducer(state = initialState, action) {
  const { type } = action;

  if (type === FETCH_REFINANCED_CONTRACTS_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => true)
      .updateIn(['isLoaded'], () => false);
  }

  if (type === FETCH_REFINANCED_CONTRACTS_FAILURE) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], payload);
  }

  if (type === FETCH_REFINANCED_CONTRACTS_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => true)
      .setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
