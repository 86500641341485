import React, { ReactNode } from 'react';
import { ConfigLabel, Icon, LabelAlertTag } from './styled';

export interface AlertTagProps {
  label?: string;
  renderValue?: ReactNode;
  icon?: 'exclamation-circle' | string;
  status?: 'success' | 'warning' | 'error';
}

export function AlertTag({
  label,
  renderValue,
  icon = 'exclamation-circle',
  status = 'success',
}: AlertTagProps) {
  return (
    <LabelAlertTag status={status}>
      <Icon status={status} type={icon} />
      {renderValue}
      {!renderValue && <ConfigLabel>{label}</ConfigLabel>}
    </LabelAlertTag>
  );
}
