/* @flow */

import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, lifecycle, withPropsOnChange, withState, withHandlers } from 'recompose';
import theme from '~/themes/aphroditeTheme/theme';

import getContractComments, {
  GET_CONTRACT_COMMENTS_REQUEST,
  GET_CONTRACT_COMMENTS_SUCCESS,
  GET_CONTRACT_COMMENTS_FAILURE,
} from '~/store/getContractComments/action';

import sendContractComment, {
  SEND_CONTRACT_COMMENT_SUCCESS,
  SEND_CONTRACT_COMMENT_FAILURE,
} from '~/store/sendContractComment/action';

import { message } from 'antd';
import Comments from './Comments';
import Styles from './Comments.Styles';

const { withStyles } = theme;

function componentDidMount() {
  const { id } = this.props.match.params;
  this.props.dispatch(getContractComments(id));
}

function onPressSendComment({ dispatch, match }) {
  return (comment) => {
    const { id } = match.params;
    dispatch(sendContractComment(id, comment));
  };
}

function mapStateToProps(state) {
  return {
    commentsList: state.getIn(['getContractComments']) || [],
    sendComment: state.getIn(['sendContractComment']),
  };
}

function receiveChanges(
  prevProps,
  { commentsList, sendComment, setIsLoadingComments, setComments, dispatch, match },
) {
  if (Immutable.is(prevProps.commentsList, commentsList) === false) {
    const { commentaries } = commentsList.getIn(['payload']);

    switch (commentsList.getIn(['type'])) {
      case GET_CONTRACT_COMMENTS_REQUEST:
        setIsLoadingComments(true);
        return true;

      case GET_CONTRACT_COMMENTS_FAILURE:
        message.error('Não foi possivél carregar os comentários deste contrato!');
        setIsLoadingComments(false);
        return false;

      case GET_CONTRACT_COMMENTS_SUCCESS:
        setIsLoadingComments(false);
        setComments(commentaries);
        return true;
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.sendComment, sendComment) === false) {
    const { id } = match.params;

    switch (sendComment.getIn(['type'])) {
      case SEND_CONTRACT_COMMENT_FAILURE:
        message.error('Não foi possivél enviar o seu comentário!');
        return true;
      case SEND_CONTRACT_COMMENT_SUCCESS:
        message.success('Comentário enviado com sucesso!');
        dispatch(getContractComments(id));
        return false;
      default:
        return false;
    }
  }
  return false;
}

function propagateStateChangeToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withStyles(Styles),
  withState('comment', 'setComment', ''),
  withState('comments', 'setComments', []),
  withState('isLoadingComments', 'setIsLoadingComments', false),
  withHandlers({
    onPressSendComment,
  }),
  lifecycle({
    componentDidMount,
  }),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
)(Comments);
