import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';

export const listTagTypes = createAsyncAction('LIST_TAG_TYPES', {
  saveResult: true,
  initialValue: [],
  context: {
    responseParser: ({ data }) => data.resource,
    request: (params) =>
      telli.get(`/admin/tag/tagtype`, {
        params,
      }),
  },
});

export const listTagTypesPerson = createAsyncAction('LIST_TAG_TYPES_PERSON', {
  saveResult: true,
  initialValue: [],
  context: {
    additionalPayload: { type: 'PERSON' },
    request: (params) =>
      telli.get(`/admin/tag/tagtype`, {
        params,
      }),
  },
});
