import React, { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';

import toMoney from '~/common/masked/toMoney';
import { themeToggleColor } from '~/common/utils/theme';
import { ContractValueList, OpportunityListItem } from '~/typings/entities/Opportunity';
import { Alert, Button, Flex, Typography, withStepperProps } from '~/ui/components';
import LoanType from '~/typings/enums/LoanType';
import { ChildrenDrawer, DownloadNodeModal } from '~/components';
import { useDrawer } from '~/hooks/useDrawer';
import { prepareContract, suggestedProposals } from '~/store/opportunities/actions';
import formatName from '~/common/formatName';
import moment from 'moment/moment';
import { SelectionResume } from '~/screens/Person/components/Drawers/Opportunity/PORTABILITY/Resume/SelectionResume';
import { getPersonDetail } from '~/store/people/actions';
import { useModal } from '~/hooks/useModal';
import { OpportunityBody, OpportunityContainer, OpportunityFooter } from '../../Opportunity.styled';
import Card from './Card';

export type DetailsProps = {
  opportunity: OpportunityListItem;
  selectedContract: ContractValueList;
  setSelectedContract: (contract: ContractValueList) => void;
  previousSimulation?: { contractValue: number; loanType: string };
  simulationAlert?: boolean;
  setSimulationAlert?: (value: boolean) => void;
};

export const Selection = withStepperProps<DetailsProps>(
  ({
    stepper,
    opportunity,
    selectedContract,
    setSelectedContract,
    simulationAlert,
    previousSimulation,
    setSimulationAlert,
  }) => {
    const theme = useTheme();
    const { toStepById } = stepper;
    const { openModal } = useModal();
    const { closeDrawer, setConfig } = useDrawer();
    const buttonColor = themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondary' });

    const proposalsControl = useAwaitControl(suggestedProposals);
    const prepareContractControl = useAwaitControl(prepareContract);

    const personControl = useAwaitControl(getPersonDetail);
    const person = personControl.result();

    const sort = (list: any) => list?.sort((a, b) => b.installmentValue - a.installmentValue);

    const contracts = useMemo(
      () =>
        sort(opportunity.portableContracts.reduce((a, b) => [...a, ...b.contractValueList], [])),
      [opportunity],
    );

    const handleNextStep = () => {
      if (previousSimulation) {
        setSimulationAlert(true);
      } else {
        toStepById('details');
      }
    };

    const handleContinueToNextStep = () => {
      proposalsControl.clear();
      prepareContractControl.clear();
      setSimulationAlert(false);
      toStepById('details');
    };

    const handleDownload = () => {
      const name = formatName(person?.name, 2);
      const date = moment().format('DD-MM-YYYY');
      const fileName = `Oportunidade Portabilidade - ${name} - ${date}`;

      openModal(
        <DownloadNodeModal fileName={fileName}>
          <SelectionResume contracts={contracts} opportunity={opportunity} />
        </DownloadNodeModal>,
        {
          closable: true,
          maskClosable: false,
          noPadding: true,
          width: 460,
        },
      );
    };

    useEffect(() => {
      setSelectedContract(opportunity?.portableContracts?.[0]?.contractValueList?.[0]);

      setConfig('opportunity-details', {
        backButton: false,
        extraAction: (
          <Button
            variant="text"
            size="sm"
            iconOnly
            icon="Download"
            title="Baixar resumo"
            onClick={handleDownload}
          />
        ),
      });
    }, []);

    return (
      <>
        <OpportunityContainer>
          <OpportunityBody>
            <Flex direction="column" gap={24} pv={24}>
              <Typography type="paragraphLarge" weight={500} element="h4">
                Escolha abaixo o contrato que seu cliente deseja fazer a portabilidade
              </Typography>

              <Flex direction="column" gap={24} width="100%">
                {contracts?.map((contract) => (
                  <Card
                    contract={contract}
                    opportunity={opportunity}
                    selectedContract={selectedContract}
                    setSelectedContract={setSelectedContract}
                    key={contract.contractId}
                  />
                ))}
              </Flex>
            </Flex>
          </OpportunityBody>

          <OpportunityFooter>
            <Button
              onClick={handleNextStep}
              customColor={buttonColor}
              fullWidth
              rounded
              disabled={!selectedContract}
            >
              Avançar
            </Button>
          </OpportunityFooter>
        </OpportunityContainer>

        <ChildrenDrawer
          title="Uma simulação já foi iniciada anteriormente"
          visible={simulationAlert}
          container=".ant-drawer-body"
        >
          <Flex direction="column" gap={24} pv={24} ph={24}>
            <Typography type="bodyMedium" center color="element.secondary" element="p">
              Já existe uma simulação de{' '}
              <Typography.Bold weight={600} color="element.primary">
                {LoanType(previousSimulation?.loanType)}
              </Typography.Bold>
              {', '}
              que foi iniciada anteriormente com um valor de
              <Typography.Bold weight={600} color="element.primary">
                {' '}
                {toMoney(previousSimulation?.contractValue)}
              </Typography.Bold>
              .
            </Typography>

            <Alert status="warning">
              Ao optar por uma nova simulação, a anterior será substituída pela nova.
            </Alert>

            <Flex gap={8} direction="column" width="100%">
              <Button color="black" fullWidth rounded onClick={handleContinueToNextStep}>
                Avançar com a nova simulação
              </Button>

              <Button
                color="black"
                variant="outline"
                rounded
                fullWidth
                onClick={() => closeDrawer('opportunity-details')}
              >
                Fechar
              </Button>
            </Flex>
          </Flex>
        </ChildrenDrawer>
      </>
    );
  },
);
