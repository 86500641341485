import masked from '~/common/masked';

const { toDate } = masked;

export const requestCancelFields = [
  {
    title: 'Solicitação do cancelamento',
    columns: [
      [
        {
          label: 'Usuário que solicitou',
          actionType: 'copy',
          value: 'refusalRequestedBy',
        },
      ],
      [
        {
          label: 'Data da solicitação',
          actionType: 'copy',
          value: ({ refusalRequestDate }) => toDate(refusalRequestDate),
        },
      ],
      [
        {
          label: 'Motivo da solicitação',
          actionType: 'copy',
          value: 'refusalReason',
        },
      ],
      [
        {
          label: 'Comentário',
          actionType: 'copy',
          value: 'openRefusalReason',
        },
      ],
    ],
  },
];
