const CONTRACT_SITUATION = {
  OPENED: 'Simulação iniciada',
  REQUESTED: 'Requerido',
  REQUESTEDANDSIGNED: 'Requerido e assinado',
  RESERVED: 'Margem Reservada',
  APPROVED: 'Averbado pela consignatária',
  FINISHED: 'Finalizado',
  REFINANCED: 'Refinanciado',
  CANCELLED: 'Cancelado',
  REFUSED_BACKOFFICE: 'Rejeitado pelo backoffice',
  NOTFOUND: 'Não encontrado',
  WAITING_USER_ACCEPTANCE: 'Aguardando aprovação do usuário',
  REJECTED_BY_USER: 'Contrato sugerido recusado pelo usuário',
};

function ContractSituation(props: any) {
  if (props) {
    return CONTRACT_SITUATION[props.value] || CONTRACT_SITUATION[props] || null;
  }
  return null;
}

export default ContractSituation;
