import React, { useCallback, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import useUserRoles from '~/hooks/useUserRoles';
import { TableColumns } from '~/ui/components';
import { useModal } from '~/hooks/useModal';

import { updateEventReturn } from '~/store/contracts/actions';
import ReactJson from 'react-json-view';
import { ParatiPreProposalReturn } from '~/typings/entities/Proposals';
import { ActionButton, EventButton, SmallTable, TruncatedCell } from '../../Returns.styled';

export type ReturnsTableProps = {
  returns: ParatiPreProposalReturn[];
  contractId: string;
};

export function ReturnsTable({ contractId, returns }: ReturnsTableProps) {
  const { isAdmin } = useUserRoles();
  const { openModal } = useModal();

  const updateEventReturnControl = useAwaitControl(updateEventReturn);

  const onUpdateEvent = useCallback(
    (id) => () => {
      updateEventReturnControl.start({ contractId, eventId: id });
    },
    [],
  );

  const renderRawMessage = useCallback((json, parsed = false) => {
    let jsonObj = { message: 'No response' };

    if (parsed) {
      jsonObj = json;
    } else {
      try {
        jsonObj = JSON.parse(json);
      } catch (e) {}
    }

    openModal(
      <ReactJson
        style={{ maxHeight: 600, overflow: 'scroll' }}
        displayDataTypes={false}
        src={jsonObj}
      />,
      {
        title: 'Mensagem',
        width: 800,
        closable: true,
      },
    );
  }, []);

  const renderMessage = useCallback((message) => {
    try {
      const parsedMessage = JSON.parse(message);

      return (
        <TruncatedCell>
          {message}
          <EventButton onClick={() => renderRawMessage(parsedMessage, true)}>Ver tudo</EventButton>
        </TruncatedCell>
      );
    } catch (error) {
      return message;
    }
  }, []);

  const tableColumns = useMemo(() => {
    const columns: TableColumns<ParatiPreProposalReturn> = [
      {
        accessorKey: 'eventDate',
        header: () => 'DATA',
        maxSize: 92,
        cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
      },
      {
        accessorKey: 'message',
        header: () => 'MENSAGEM',
        cell: (info) => renderMessage(info.getValue()),
        maxSize: 200,
      },
      { accessorKey: 'status', header: () => 'STATUS', cellAlign: 'center' },
      { accessorKey: 'eventId', header: () => 'ID EVENTO', cellAlign: 'center' },
      { accessorKey: 'attempt', header: () => 'TENTATIVA', cellAlign: 'center' },
      {
        id: 'resp',
        header: () => 'RESPOSTA',
        cellAlign: 'center',
        headerAlign: 'center',
        cell: ({ row }) => (
          <ActionButton icon="FileSearch" onClick={() => renderRawMessage(row.original.rawBody)} />
        ),
      },
    ];

    if (isAdmin) {
      columns.push({
        id: 'reply',
        header: () => 'REPROCESSAR',
        cellAlign: 'center',
        headerAlign: 'center',
        cell: ({ row }) => (
          <ActionButton icon="Refresh" onClick={onUpdateEvent(row.original.eventId)} />
        ),
      });
    }

    return columns;
  }, [isAdmin]);

  return (
    <SmallTable noItemsMessage="Nenhum retorno encontrado" data={returns} columns={tableColumns} />
  );
}
