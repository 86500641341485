const unit = 10;

export const colors = {
  primaryColor: '#D22688',
  primaryColorLight: '#d27bb1',
  primaryColorLightest: '#d2b0c6',
  primaryColorLightHover: '#d2bace',
  primaryColorTable: '#F4F9FF',
  primaryColorGray: '#d4c1cf',
  complementary: '#323335',
  successColor: '#63ab00',
  successColorDark: '#549000',
  successColorDarkest: '#005C00',
  successColorLight: '#d1ff88',
  successColorLightest: '#E5FFE5',
  tabBarBackgroundColor: '#fff',
  primaryColorDarkest: '#8f0063',
  primaryColorDark: '#4b0137',
  accentColor: '#000000',
  borderGrayColor: '#6E6E6E',
  titleGray: '#444444',
  grayContainer: '#f0f0f0',
  grayContainerDark: '#dcdcdc',
  grayDark: '#7a7a7a',
  grayDarkest: '#414141',
  grayMid: '#969696',
  gray: '#d8d8d8',
  grayExtraLight: '#ededed',
  grayLight: '#c6c6c6',
  grayExtraDark: '#343a40',
  grayAlmostWhite: '#f8f8f8',
  gray5: 'rgba(0,0,0,0.05)',
  gray10: 'rgba(0,0,0,0.10)',
  gray20: 'rgba(0,0,0,0.20)',
  gray40: 'rgba(0,0,0,0.40)',
  gray60: 'rgba(0,0,0,0.60)',
  dangerColor: '#cc0007',
  dangerColorLight: '#ff414c',
  dangerColorLightest: '#FFE8E5',
  dangerColorDark: '#970007',
  dangerColorDarkest: '#490003',
  warnningColorDark: '#e07400',
  alertColorLight: '#FFF1E0',
  alertColorDark: '#B74A01',
};

const components = {
  form: {},
  input: {
    borderColor: colors.grayLight,
    borderRadius: 4,
    borderSize: 1,
    fontSize: 12,
    boxShadow: {
      color: colors.grayExtraLight,
      offset: 'inset 0 1px 3px',
    },
  },
  label: {
    color: '#949494',
    display: 'block',
    fontSize: unit * 0.9,
    letterSpacing: '0',
    textTransform: 'uppercase',
  },
  breadcrumb: {
    separator: {
      color: colors.gray,
    },
    link: {
      color: colors.grayLight,

      current: {
        color: colors.grayExtraDark,
      },
      disabled: {
        color: colors.grayExtraLight,
      },
    },
  },
  breadcrumbToolbar: {
    backgroundColor: 'white',
    borderRadius: 2,
    padding: '0.65em',
  },
};

const zIndex = {
  headerZIndex: 50,
  sideMenuZIndex: 60,
  headerTabChatZIndex: 50,
  headerACtionsChatZIndex: 50,
};

export const defaultTheme = {
  ...colors,
  ...zIndex,
  unit,
  components,
  cardBorderRadius: '10px',
};
