import React, { useCallback, useRef, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { NavbarItem, SubItem, Submenu, SubmenuWrapper } from '~/ui/components/Menu/Menu.styled';
import { logout } from '~/store/user/actions';
import { useModal } from '~/hooks/useModal';
import { Connection, Logout, User, UserPlus } from '~/ui/assets/icons';
import { EnvironmentModal } from '~/components/EnvironmentModal/EnvironmentModal';
import { CreateUserModal } from '~/components/CreateUserModal/CreateUserModal';
import useUserRoles from '~/hooks/useUserRoles';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';

import useOutsideClickEffect from '~/common/useOutsideClickEffect';
import { Switch } from '~/ui/components';
import { useTheme } from '~/hooks/useTheme';
import { Container, SettingsButton } from './Settings.styled';

type SettingsProps = {
  trigger?: 'hover' | 'click';
};

export function Settings({ trigger = 'hover' }: SettingsProps) {
  const containerRef = useRef(null);
  const { openModal } = useModal();
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(false);
  const { isAdmin, hasRole } = useUserRoles();
  const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'dark');
  const logoutControl = useAwaitControl(logout);
  const { setTheme } = useTheme();

  const allowDarkThemeButton =
    localStorage.getItem('allowDarkThemeButton') === 'true' || remoteValues.allowDarkThemeButton;

  const showUsersTableAccess = hasRole('VIEW_USERS_PAGE');

  const handleLogout = useCallback((e) => {
    e.preventDefault();
    logoutControl.start();
  }, []);

  const close = () => {
    setClosing(true);
    setTimeout(() => {
      setOpened(false);
      setClosing(false);
    }, 300);
  };

  const handleAddUser = useCallback(() => {
    close();
  }, []);

  const handleChangeEnvironment = useCallback(
    (e) => {
      e?.preventDefault();
      close();
      openModal(<EnvironmentModal />, {
        id: 'environment',
        title: 'Alterar ambiente',
        closable: true,
      });
    },
    [openModal],
  );

  const handleCreateUser = useCallback(
    (e) => {
      e?.preventDefault();
      close();
      openModal(<CreateUserModal />, {
        id: 'create-user',
        title: 'Criar novo usuário',
        closable: true,
        width: 400,
      });
    },
    [openModal],
  );

  const handleItemClick = useCallback(() => {
    if (trigger === 'click') {
      setOpened(true);
    }
  }, [opened]);

  const handleDarkMode = useCallback(
    (e) => {
      e?.preventDefault();
      setTheme(darkMode ? 'light' : 'dark');
      setDarkMode(!darkMode);
    },
    [darkMode],
  );

  useOutsideClickEffect(containerRef, () => {
    if (trigger === 'click' && opened) {
      close();
    }
  });

  return (
    <Container trigger={trigger} ref={containerRef}>
      <NavbarItem
        className={`nav-item ${opened ? 'open' : ''} ${closing ? 'closing' : ''}`}
        onClick={handleItemClick}
        trigger={trigger}
      >
        <SettingsButton data-testid="nav-bar-settings-button" />

        <SubmenuWrapper>
          <Submenu>
            {allowDarkThemeButton && (
              <SubItem to="/" onClick={handleDarkMode}>
                <Switch checked={darkMode} small label="Modo noturno" />
              </SubItem>
            )}

            {isAdmin && (
              <SubItem to="/" onClick={handleCreateUser}>
                <UserPlus />
                Criar novo usuário
              </SubItem>
            )}
            {showUsersTableAccess && (
              <SubItem to="/backoffice/users" onClick={handleAddUser}>
                <User />
                Adicionar ou editar acessos
              </SubItem>
            )}

            <SubItem to="/" onClick={handleChangeEnvironment}>
              <Connection />
              Alterar ambiente
            </SubItem>

            <SubItem className="logout" to="/" onClick={handleLogout}>
              <Logout />
              Sair
            </SubItem>
          </Submenu>
        </SubmenuWrapper>
      </NavbarItem>
    </Container>
  );
}
