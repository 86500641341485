import styled from 'styled-components';

import { themeColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: theme.colors.element.primary,
}))`
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
`;

export const Value = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'p',
  color: theme.colors.element.primary,
}))`
  margin-bottom: 0;
`;

export const UpdateDate = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'p',
  color: theme.colors.element.primary,
}))`
  margin-bottom: 0;
`;

export const ListWithArrow = styled.ul`
  li {
    list-style: none;
    display: flex;
    line-height: normal;
    color: ${themeColor('element.secondary')};

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    svg {
      margin-top: 4px;
      margin-right: 8px;
      color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    }
  }
`;
