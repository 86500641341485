/* @flow */

import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, lifecycle, withPropsOnChange, withState } from 'recompose';
import { Button, Icon, Popconfirm } from 'antd';
import Modal from 'react-modal';
import { X } from 'react-feather';
import Masker from 'vanilla-masker';
import { message } from '~/common';
import common from './styles';
import masked from '~/common/masked';
import { TableReact as Table, Container, Spinner } from '~/components';

import theme from '~/themes/aphroditeTheme/theme';

import fetchLoanHistories, {
  LOAN_HISTORIES_FETCH_REQUEST,
  LOAN_HISTORIES_FETCH_SUCCESS,
  LOAN_HISTORIES_FETCH_FAILURE,
} from '~/store/fetchLoanHistories/action';

import {
  REGISTER_LOAN_HISTORY_SUCCESS,
  REGISTER_LOAN_HISTORY_FAILURE,
} from '~/store/registerLoanHistory/action';

import {
  UPDATE_LOAN_HISTORY_SUCCESS,
  UPDATE_LOAN_HISTORY_FAILURE,
} from '~/store/updateLoanHistory/action';

import deleteLoanHistory, {
  DELETE_LOAN_HISTORY_REQUEST,
  DELETE_LOAN_HISTORY_SUCCESS,
  DELETE_LOAN_HISTORY_FAILURE,
} from '~/store/deleteLoanHistory/action';

import '~/common/styles/radiobutton.css';
import '~/common/styles/textAlignment.css';

import Card from '~/components/Card';
import LoanHistoryFormModal from './LoanHistoryFormModal';

const { css, withStyles } = theme;

const { toDate } = masked;

const moneyFormatter = {
  unit: 'R$',
};

type Props = {
  styles: any,
};

class LoanHistoryTable extends Component<Props> {
  state = {
    selectedLoanHistory: undefined,
    loanHistoryValues: {},
  };

  presentation = {
    columns: [
      {
        accessor: 'bankNumber',
        name: 'Banco',
        props: {
          minWidth: 130,
          Cell: (row) => <div className="text-center">{row.original.bankNumber}</div>,
          width: 60,
        },
      },
      {
        accessor: 'contractDate',
        name: 'Data',
        props: {
          minWidth: 85,
          Cell: (row) => {
            if (row.original.contractDate) {
              return toDate(row.original.contractDate);
            }
            return null;
          },
        },
      },
      {
        accessor: 'contractValue',
        name: 'Valor Contrato',
        props: {
          minWidth: 142,
          Cell: (row) => {
            if (row.original.contractValue) {
              return Masker.toMoney(row.original.contractValue.toFixed(2), moneyFormatter);
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'installmentValue',
        name: 'Valor Parcela',
        props: {
          minWidth: 150,
          Cell: (row) => {
            if (row.original.installmentValue) {
              return Masker.toMoney(row.original.installmentValue.toFixed(2), moneyFormatter);
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'numberOfInstallments',
        name: 'N° Parcelas',
        props: {
          minWidth: 140,
        },
      },
      {
        accessor: 'paidInstallments',
        name: 'Parcelas Pagas',
        props: {
          minWidth: 160,
        },
      },
      {
        accessor: 'outstandingBalance',
        name: 'Saldo Devedor',
        props: {
          minWidth: 140,
          Cell: (row) => {
            if (row.original.outstandingBalance) {
              return Masker.toMoney(row.original.outstandingBalance.toFixed(2), moneyFormatter);
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'contractDate',
        name: 'Data',
        props: {
          minWidth: 120,
          Cell: (row) => {
            if (row.original.contractDate) {
              return toDate(row.original.contractDate);
            }
            return null;
          },
        },
      },
      {
        name: 'Ações',
        minWidth: 120,
        props: {
          minWidth: 120,
          Cell: (row) => (
            <div {...css(this.props.styles.actions)}>
              <Button
                onClick={() => this.openLoanHistoryFormModal(row.original)}
                className="ant-btn-border-none"
              >
                <Icon type="edit" theme="twoTone" style={{ fontSize: '16px' }} />
              </Button>
              <Popconfirm
                title="Você realmente deseja deletar este contrato?"
                onConfirm={() => this.onConfirmDeleteLoanHistory(row.original.loanHistoryId)}
                okText="Sim"
                cancelText="Não"
                placement="topRight"
              >
                <Button className="ant-btn-border-none">
                  <Icon
                    type="delete"
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                    style={{ fontSize: '16px' }}
                  />
                </Button>
              </Popconfirm>
            </div>
          ),
        },
      },
    ],
  };

  openLoanHistoryFormModal = (loanHistoryValues) => {
    if (loanHistoryValues) {
      this.setState({ loanHistoryValues });
    } else {
      this.setState({ loanHistoryValues: {} });
    }
    this.props.setIsModalVisible(true);
  };

  onConfirmDeleteLoanHistory = (loanHistoryId) => {
    const { dispatch } = this.props;
    dispatch(deleteLoanHistory(loanHistoryId));
  };

  onPressClose = () => {
    this.setState({ loanHistoryValues: {} });
    this.props.setIsModalVisible(false);
  };

  renderModalHeader = () => {
    const { styles } = this.props;
    const { loanHistoryId } = this.state.loanHistoryValues;

    return (
      <div {...css(styles.modal__header)}>
        {loanHistoryId ? (
          <span {...css(styles.modal__title)}>Edição de Contrato</span>
        ) : (
          <span {...css(styles.modal__title)}>Novo Contrato</span>
        )}
        <button type="button" onClick={this.onPressClose} {...css(styles.modal__close)}>
          <X {...css(styles.closeButton)} size={20} />
        </button>
      </div>
    );
  };

  renderLoanHistoryFormModal = () => {
    const { styles } = this.props;

    const style = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s',
        background: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1000,
      },
    };
    return (
      <Modal
        ariaHideApp={false}
        contentLabel="LoanHistoryFormModal"
        isOpen={this.props.isModalVisible}
        key="modal"
        style={style}
        {...css(styles.modal)}
      >
        {this.renderModalHeader()}
        <LoanHistoryFormModal loanHistoryValues={this.state.loanHistoryValues} />
      </Modal>
    );
  };

  renderHeading(text) {
    const { styles } = this.props;
    return (
      <div {...css(styles.heading)}>
        <h3 {...css(styles.headingText)}>{text}</h3>
      </div>
    );
  }

  renderTable() {
    const { loanHistories, styles } = this.props;

    return (
      <div>
        <Table
          items={loanHistories}
          presentation={this.presentation}
          className={css(styles.table).className}
          pageSize={5}
        />
      </div>
    );
  }

  renderEmpty() {
    const { emptyMessage, isLoading, styles } = this.props;

    if (isLoading || !emptyMessage) return null;

    return (
      <div {...css(styles.status)}>
        <i>{emptyMessage}</i>
      </div>
    );
  }

  renderNewLoanHistoryButton() {
    return (
      <Button
        style={{ width: '120px', marginLeft: 'auto' }}
        onClick={() => this.openLoanHistoryFormModal()}
      >
        <Icon type="plus-circle" style={{ fontSize: '16px', margin: '0 5px 0 -21px' }} />
        Novo
      </Button>
    );
  }

  render() {
    return (
      <Card
        title="Contratos"
        style={{ marginTop: 16 }}
        actions={[
          {
            render: () => this.renderNewLoanHistoryButton(),
          },
        ]}
      >
        <Container>
          <Spinner spinning={this.props.isLoading} />
          <div>
            {this.renderTable()}
            {this.renderEmpty()}
            {this.renderLoanHistoryFormModal()}
          </div>
        </Container>
      </Card>
    );
  }
}

LoanHistoryTable.defaultProps = {
  fetchLoanHistories: [],
};

function componentDidMount() {
  const { personId } = this.props;

  const params = { personId };
  this.props.dispatch(fetchLoanHistories(params));
}

function mapStateToProps(state) {
  return {
    loanHistories: state.getIn(['fetchLoanHistories']),
    registerLoanHistory: state.getIn(['registerLoanHistory']),
    updateLoanHistory: state.getIn(['updateLoanHistory']),
    deleteLoanHistory: state.getIn(['deleteLoanHistory']),
  };
}

function receiveChanges(
  prevProps,
  {
    loanHistories,
    deleteLoanHistory,
    isLoading,
    setEmptyMessage,
    setIsLoading,
    setIsModalVisible,
    registerLoanHistory,
    updateLoanHistory,
    dispatch,
    match,
  },
) {
  if (Immutable.is(prevProps.loanHistories, loanHistories) === false) {
    switch (loanHistories.getIn(['type'])) {
      case LOAN_HISTORIES_FETCH_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case LOAN_HISTORIES_FETCH_SUCCESS: {
        setIsLoading(false);
        if (loanHistories.getIn(['payload']).entries.length !== 0) {
          setEmptyMessage('');
        }
        return true;
      }
      case LOAN_HISTORIES_FETCH_FAILURE: {
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }
  if (Immutable.is(prevProps.registerLoanHistory, registerLoanHistory) === false) {
    switch (registerLoanHistory.getIn(['type'])) {
      case REGISTER_LOAN_HISTORY_SUCCESS: {
        message.success('Contrato registrado com sucesso.');
        setIsModalVisible(false);

        const { id } = match.params;
        const params = { personId: id };
        dispatch(fetchLoanHistories(params));

        return true;
      }
      case REGISTER_LOAN_HISTORY_FAILURE: {
        message.error('Falha ao registrar contrato.');
        setIsModalVisible(false);
        return true;
      }
      default:
        return false;
    }
  }
  if (Immutable.is(prevProps.updateLoanHistory, updateLoanHistory) === false) {
    switch (updateLoanHistory.getIn(['type'])) {
      case UPDATE_LOAN_HISTORY_SUCCESS: {
        message.success('Contrato atualizado com sucesso.');
        setIsModalVisible(false);

        const { id } = match.params;
        const params = { personId: id };
        dispatch(fetchLoanHistories(params));

        return true;
      }
      case UPDATE_LOAN_HISTORY_FAILURE: {
        message.error('Falha ao atualizar contrato.');
        setIsModalVisible(false);
        return true;
      }
      default:
        return false;
    }
  }
  if (Immutable.is(prevProps.deleteLoanHistory, deleteLoanHistory) === false) {
    switch (deleteLoanHistory.getIn(['type'])) {
      case DELETE_LOAN_HISTORY_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case DELETE_LOAN_HISTORY_SUCCESS: {
        setIsLoading(false);

        const { id } = match.params;
        const params = { personId: id };
        dispatch(fetchLoanHistories(params));

        message.success('Contrato deletado com sucesso');
        return true;
      }
      case DELETE_LOAN_HISTORY_FAILURE: {
        message.error('Falha ao deletar contrato');
        setIsLoading(false);
        break;
      }
      default:
        return false;
    }
  }
  return false;
}

function propagateStateChangeToProps(state) {
  const { entries } = state.loanHistories.toJS().payload;
  return {
    loanHistories: entries,
  };
}

const styles = (props) => ({
  ...common(props),
  modal: {
    ...common(props).modal,
    width: 688,
  },
  loadingIcon: {
    display: 'inline-block',
    fontSize: 20,
    marginTop: 5,
  },
  hidden: {
    display: 'none',
  },
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  lifecycle({
    componentDidMount,
  }),
  withState('isLoading', 'setIsLoading', true),
  withState('isModalVisible', 'setIsModalVisible', false),
  withState('emptyMessage', 'setEmptyMessage', 'Nenhum Registro Encontrado'),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
)(LoanHistoryTable);
