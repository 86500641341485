/* @flow */

export const LIST_ROLES_REQUEST = 'LIST_ROLES_REQUEST';
export const LIST_ROLES_SUCCESS = 'LIST_ROLES_SUCCESS';
export const LIST_ROLES_FAILURE = 'LIST_ROLES_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: LIST_ROLES_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: LIST_ROLES_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch() {
  return dispatch => {
    dispatch({
      type: LIST_ROLES_REQUEST,
      payload: {},
    });
  };
}
