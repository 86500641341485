import { useState, useEffect } from 'react';

export default (media: string) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    const mediaQueryList = window.matchMedia(`(${media})`); // Ex.: min-width: 768px

    mediaQueryList.addEventListener('change', handleResize);
    return () => mediaQueryList.addEventListener('change', handleResize);
  }, []);

  return screenSize;
};
