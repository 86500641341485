/* @flow */
import _ from 'lodash';

export const GET_FILE_HISTORY_FILES_REQUEST = 'GET_FILE_HISTORY_FILES_REQUEST';
export const GET_FILE_HISTORY_FILES_SUCCESS = 'GET_FILE_HISTORY_FILES_SUCCESS';
export const GET_FILE_HISTORY_FILES_FAILURE = 'GET_FILE_HISTORY_FILES_FAILURE';

export function success(payload) {
  return (dispatch, getState) => {
    const historyFiles = getState()
      .getIn(['getFileHistoryFiles'])
      .toJS();
    const allFiles = historyFiles.payload;
    const newFiles = Object.assign({}, allFiles, payload);
    dispatch({
      type: GET_FILE_HISTORY_FILES_SUCCESS,
      loading: false,
      payload: newFiles,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: GET_FILE_HISTORY_FILES_FAILURE,
      loading: false,
      payload: resource,
    });
  };
}

export default function fetch(files) {
  return dispatch => {
    dispatch({
      type: GET_FILE_HISTORY_FILES_REQUEST,
      loading: true,
      data: {
        files,
      },
    });
  };
}
