import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ImageControl } from '~/ui/components/CompareImages/ImageControl';
import { Tooltip } from 'antd';

import {
  CloseButton,
  Container,
  IconButton,
  ImagePreview,
  ImagePreviewSkeleton,
  ImagesList,
  PreviewImagesList,
  PreviewMask,
} from './CompareImages.styled';

export type FileItem = Partial<{
  name: string;
  url: string;
  loading?: boolean;
  hide?: boolean;
  active?: boolean;
}>;

export type CompareImagesProps = {
  files: FileItem[];
  initialFile?: FileItem;
  multiples?: boolean;
  allFiles?: boolean;
  onClose?: () => void;
};

export function CompareImages({
  files,
  initialFile,
  multiples,
  onClose,
  allFiles,
}: CompareImagesProps) {
  const [selectedFiles, setSelectedFiles] = useState<FileItem[]>([]);
  const [animation, setAnimation] = useState<'fadeIn' | 'fadeOut'>('fadeIn');

  const selectableImages = useMemo(
    () => files.filter((file) => file.name !== initialFile.name),
    [files, initialFile],
  );

  const isImageSelected = useCallback(
    (file: FileItem) => selectedFiles?.find((selectedFile) => selectedFile.name === file.name),
    [selectedFiles],
  );

  const handleClose = useCallback(() => {
    setAnimation('fadeOut');
    setTimeout(() => {
      onClose?.();
    }, 400);
  }, [onClose]);

  const handleCloseClick = useCallback((ev) => {
    if (ev.target.classList.contains('closable-click')) {
      handleClose();
    }
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const handleToggleImage = (file: FileItem) => {
    const findImage = selectedFiles?.find((selectedFile) => selectedFile.name === file.name);
    if (findImage) {
      setSelectedFiles(
        selectedFiles?.map((selectedFile) => ({
          ...selectedFile,
          hide: file.name === selectedFile.name,
        })),
      );

      setTimeout(() => {
        setSelectedFiles((currentFiles) =>
          currentFiles.filter((selectedFile) => !selectedFile.hide),
        );
      }, 200);
    } else if (multiples) {
      setSelectedFiles([...selectedFiles, file]);
    } else {
      setSelectedFiles([file]);
    }
  };

  useEffect(() => {
    if (allFiles) {
      setSelectedFiles(files);
    }
  }, [allFiles]);

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <Container animation={animation} onClick={handleCloseClick}>
      <CloseButton tabIndex={0} icon="close" onClick={handleClose} />

      <ImagesList className="closable-click">
        {!allFiles && <ImageControl file={initialFile} />}

        {!selectedFiles?.length && (
          <ImagePreviewSkeleton>
            {multiples ? (
              <span>Escolha até {selectableImages?.length} imagens para comparar</span>
            ) : (
              <span>Escolha uma imagem para comparar</span>
            )}
          </ImagePreviewSkeleton>
        )}

        {selectedFiles?.map((file) => (
          <ImageControl file={file} key={file.name} />
        ))}
      </ImagesList>

      {!allFiles && (
        <PreviewImagesList>
          {selectableImages?.map((file) => (
            <ImagePreview
              key={file.name}
              src={file.url}
              onClick={() => handleToggleImage(file)}
              selected={isImageSelected(file)}
            >
              <PreviewMask selected={isImageSelected(file)}>
                <span>
                  {isImageSelected(file) ? (
                    <Tooltip placement="top" title="Remover" overlayStyle={{ zIndex: 10000 }}>
                      <IconButton icon="Close" />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title="Comparar" overlayStyle={{ zIndex: 10000 }}>
                      <IconButton icon="Add" />
                    </Tooltip>
                  )}
                </span>
              </PreviewMask>
              <img src={file.url} alt={file.name} />
            </ImagePreview>
          ))}
        </PreviewImagesList>
      )}
    </Container>
  );
}

export * from './ImageControl';
