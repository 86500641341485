import { css } from 'styled-components';

export default css`
  .mt-custom-tooltip {
    .ant-popover-inner-content {
      padding: 16px;
    }

    .ant-popover-inner {
      background: rgba(67, 67, 67, 0.89);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(1.5px);
      border-radius: 8px;
    }

    .ant-popover-arrow {
      display: none;
    }

    &.small {
      .ant-popover-inner-content {
        padding: 8px 16px;
      }
    }
  }

  .mt-popover-no-arrow {
    .ant-popover-arrow {
      display: none;
    }
  }

  .mt-popover-no-padding {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
`;
