import Immutable from 'immutable';

import message from '~/common/message';

import concludeContractSignature, {
  CONCLUDE_CONTRACT_SIGNATURE_REQUEST,
  CONCLUDE_CONTRACT_SIGNATURE_SUCCESS,
  CONCLUDE_CONTRACT_SIGNATURE_FAILURE,
} from '~/store/concludeContractSignature/action';

import linkContractOwner, {
  LINK_CONTRACTOWNER_REQUEST,
  LINK_CONTRACTOWNER_FAILURE,
  LINK_CONTRACTOWNER_SUCCESS,
} from '~/store/linkContractOwner/action';

import updateContractValues, {
  UPDATE_CONTRACT_VALUES_REQUEST,
  UPDATE_CONTRACT_VALUES_FAILURE,
  UPDATE_CONTRACT_VALUES_SUCCESS,
} from '~/store/updateContractValues/action';

import requestNewContractBankAccount, {
  REQUEST_NEW_CONTRACT_BANK_ACCOUNT_REQUEST,
  REQUEST_NEW_CONTRACT_BANK_ACCOUNT_SUCCESS,
  REQUEST_NEW_CONTRACT_BANK_ACCOUNT_FAILURE,
} from '~/store/requestNewContractBankAccount/action';

import requestNewImage, {
  REQUEST_NEW_IMAGE_REQUEST,
  REQUEST_NEW_IMAGE_SUCCESS,
  REQUEST_NEW_IMAGE_FAILURE,
} from '~/store/requestNewImage/action';

import requestNewSignature, {
  REQUEST_NEW_SIGNATURE_REQUEST,
  REQUEST_NEW_SIGNATURE_FAILURE,
  REQUEST_NEW_SIGNATURE_SUCCESS,
} from '~/store/requestNewSignature/action';

import requestAddress, {
  REQUEST_ADDRESS_FAILURE,
  REQUEST_ADDRESS_REQUEST,
  REQUEST_ADDRESS_SUCCESS,
} from '~/store/requestAddress/action';

import revertToCetelem, {
  REVERT_TO_CETELEM_FAILURE,
  REVERT_TO_CETELEM_SUCCESS,
  REVERT_TO_CETELEM_REQUEST,
} from '~/store/revertCetelem/action';

import reserveMargin, {
  RESERVE_MARGIN_REQUEST,
  RESERVE_MARGIN_SUCCESS,
  RESERVE_MARGIN_FAILURE,
} from '~/store/reserveMargin/action';

import reverseCancelContract, {
  REVERSE_CANCEL_CONTRACT_FAILURE,
  REVERSE_CANCEL_CONTRACT_REQUEST,
  REVERSE_CANCEL_CONTRACT_SUCCESS,
} from '~/store/reverseCancelContract/action';

import { getContractDetails } from '~/store/contracts/actions';

import {
  UPDATE_PDF_FILE_REQUEST,
  UPDATE_PDF_FILE_SUCCESS,
  UPDATE_PDF_FILE_FAILURE,
} from '~/store/updatePDFFile/action';

import updateConsigneeStatus, {
  UPDATE_CONSIGNEE_STATUS_REQUEST,
  UPDATE_CONSIGNEE_STATUS_FAILURE,
  UPDATE_CONSIGNEE_STATUS_SUCCESS,
} from '~/store/updateConsigneeStatus/action';

import setCheckedContract, {
  SET_CHECKED_CONTRACT_REQUEST,
  SET_CHECKED_CONTRACT_FAILURE,
  SET_CHECKED_CONTRACT_SUCCESS,
} from '~/store/setCheckedContract/action';

import setUncheckedContract, {
  SET_UNCHECKED_CONTRACT_REQUEST,
  SET_UNCHECKED_CONTRACT_FAILURE,
  SET_UNCHECKED_CONTRACT_SUCCESS,
} from '~/store/setUncheckedContract/action';

import sendBiometry, {
  SEND_BIOMETRY_FAILURE,
  SEND_BIOMETRY_SUCCESS,
  SEND_BIOMETRY_REQUEST,
} from '~/store/sendBiometry/action';

import { getPendingReasons, requestPendingData } from '~/store/pendencies/actions';
import { getPersonDetail, requestAgreementPassword } from '~/store/people/actions';
import { requestHisconAutoUpdate } from '~/store/requestHisconAutoUpdate/actions';

export function onPressConcludeContractSignature({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(concludeContractSignature(id));
  };
}

export function onPressRevertCetelem({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(revertToCetelem(id));
  };
}

export function onPressLinkContractOwner({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(linkContractOwner(id));
  };
}

export function onPressUpdateContractValues({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(updateContractValues(id));
  };
}

export function onPressReverseCancel({ dispatch, match }) {
  return (reason) => {
    const { id } = match.params;
    dispatch(reverseCancelContract(id, reason));
  };
}

export function onPressDispatchRequestNewContractBankAccount({ dispatch, match }) {
  return (reason, type, reasonType, pendingReasonId, reasonDetailsId) => {
    const { id } = match.params;
    dispatch(
      requestNewContractBankAccount({ id, reason, reasonType, pendingReasonId, reasonDetailsId }),
    );
  };
}

export function onPressRequestAddress({ dispatch, match }) {
  return (reason, type, reasonType, pendingReasonId, reasonDetailsId) => {
    const { id } = match.params;
    dispatch(requestAddress({ id, reason, reasonType, pendingReasonId, reasonDetailsId }));
  };
}

export function onPressRequestPendencyData({ match, dispatch, contractDetails }) {
  return (reason, type, reasonType, pendingReasonId, reasonDetailsId) => {
    const { id: contractId } = match.params;
    const { id } = contractDetails.person;
    dispatch(
      requestPendingData.start({
        personId: id,
        pendingType: type,
        reason,
        reasonType,
        contractId,
        pendingReasonId,
        reasonDetailsId,
      }),
    );
  };
}

export function onPressDispatchRequestNewImage({ dispatch, match }) {
  return (reason, pendingImageType, reasonType, pendingReasonId, reasonDetailsId) => {
    const { id } = match.params;
    dispatch(
      requestNewImage({
        contractId: id,
        pendingImageType,
        reasonType,
        reason,
        pendingReasonId,
        reasonDetailsId,
      }),
    );
  };
}

export function onRequestAgreementPassword({ dispatch, match, contractDetails }) {
  return (reason, pendingImageType, reasonType, pendingReasonId, reasonDetailsId) => {
    const { id } = contractDetails.person;
    dispatch(
      requestAgreementPassword.start({ id, reasonType, reason, pendingReasonId, reasonDetailsId }),
    );
  };
}

export function onPressRequestNewSignature({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(requestNewSignature(id));
  };
}

export function onPressReserveMargin({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(reserveMargin(id));
  };
}

export function onPressUpdateConsigneeStatus({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(updateConsigneeStatus(id));
  };
}

export function onPressCheckedContract({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(setCheckedContract(id));
  };
}

export function onPressUncheckedContract({ dispatch, match }) {
  return () => {
    const { id } = match.params;
    dispatch(setUncheckedContract(id));
  };
}

export function onPressSendBiometry({ dispatch, match }) {
  return (file) => {
    const { id } = match.params;
    dispatch(sendBiometry({ id, file }));
  };
}

export function mapStateToProps(state) {
  return {
    contractDetails: getContractDetails.getResult()(state),
    contractDetailsSuccess: getContractDetails.isSuccessful()(state),
    contractDetailsFailure: getContractDetails.hasFailure()(state),
    hisconAutoUpdateFailure: requestHisconAutoUpdate.hasFailure()(state),
    pendingReasons: getPendingReasons.getResult()(state),
    concludeContractSignature: state.getIn(['concludeContractSignature']),
    linkContractOwner: state.getIn(['linkContractOwner']),
    payContract: state.getIn(['payContract']),
    requestNewContractBankAccount: state.getIn(['requestNewContractBankAccount']),
    requestNewImage: state.getIn(['requestNewImage']),
    reserveMargin: state.getIn(['reserveMargin']),
    requestSignature: state.getIn(['requestNewSignature']),
    requestAddress: state.getIn(['requestAddress']),
    sendContract: state.getIn(['sendContract']),
    checkedContract: state.getIn(['setCheckedContract']),
    uncheckedContract: state.getIn(['setUncheckedContract']),
    updateContractValues: state.getIn(['updateContractValues']),
    updatePDFFile: state.getIn(['updatePDFFile']),
    updateConsigneeStatus: state.getIn(['updateConsigneeStatus']),
    reverseCancelContract: state.getIn(['reverseCancelContract']),
    sendBiometry: state.getIn(['sendBiometry']),
    revertCetelem: state.getIn(['revertCetelem']),
  };
}

export function receiveChanges(
  prevProps,
  {
    checkedContract,
    uncheckedContract,
    contractDetails,
    contractDetailsSuccess,
    contractDetailsFailure,
    concludeContractSignature,
    dispatch,
    history,
    linkContractOwner,
    match,
    payContract,
    requestNewContractBankAccount,
    requestNewImage,
    requestAddress,
    requestSignature,
    reserveMargin,
    sendContract,
    setIsLoading,
    updateContractValues,
    updatePDFFile,
    updateConsigneeStatus,
    reverseCancelContract,
    sendBiometry,
    revertCetelem,
  },
) {
  if (Immutable.is(prevProps.contractDetailsSuccess, contractDetailsSuccess) === false) {
    return !contractDetailsSuccess;
  }

  if (Immutable.is(prevProps.contractDetailsFailure, contractDetailsFailure) === false) {
    return !contractDetailsFailure;
  }

  if (Immutable.is(prevProps.concludeContractSignature, concludeContractSignature) === false) {
    switch (concludeContractSignature.getIn(['type'])) {
      case CONCLUDE_CONTRACT_SIGNATURE_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case CONCLUDE_CONTRACT_SIGNATURE_SUCCESS: {
        message.success('Assinatura do Contrato Concluída com Sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);

        return true;
      }
      case CONCLUDE_CONTRACT_SIGNATURE_FAILURE: {
        message.error('Falha ao Concluir Assinatura do Contrato.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }
  if (Immutable.is(prevProps.updateConsigneeStatus, updateConsigneeStatus) === false) {
    switch (updateConsigneeStatus.getIn(['type'])) {
      case UPDATE_CONSIGNEE_STATUS_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case UPDATE_CONSIGNEE_STATUS_SUCCESS: {
        message.success('Status da consignatária atualizado com sucesso!');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);
        return true;
      }
      case UPDATE_CONSIGNEE_STATUS_FAILURE: {
        message.error('Falha ao atualizar status da consignatária');
        setIsLoading(false);
        return true;
      }

      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.linkContractOwner, linkContractOwner) === false) {
    switch (linkContractOwner.getIn(['type'])) {
      case LINK_CONTRACTOWNER_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case LINK_CONTRACTOWNER_SUCCESS: {
        message.success('Contrato associado com sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);

        return true;
      }
      case LINK_CONTRACTOWNER_FAILURE: {
        message.error('Falha ao associar contrato.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.updatePDFFile, updatePDFFile) === false) {
    switch (updatePDFFile.getIn(['type'])) {
      case UPDATE_PDF_FILE_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case UPDATE_PDF_FILE_SUCCESS: {
        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);
        return true;
      }
      case UPDATE_PDF_FILE_FAILURE: {
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.updateContractValues, updateContractValues) === false) {
    switch (updateContractValues.getIn(['type'])) {
      case UPDATE_CONTRACT_VALUES_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case UPDATE_CONTRACT_VALUES_SUCCESS: {
        message.success('Valores do Contrato Atualizados com Sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);

        return true;
      }
      case UPDATE_CONTRACT_VALUES_FAILURE: {
        message.error('Falha ao Enviar o Contrato para a Consignatária.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (
    Immutable.is(prevProps.requestNewContractBankAccount, requestNewContractBankAccount) === false
  ) {
    switch (requestNewContractBankAccount.getIn(['type'])) {
      case REQUEST_NEW_CONTRACT_BANK_ACCOUNT_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case REQUEST_NEW_CONTRACT_BANK_ACCOUNT_SUCCESS: {
        const { isNewPending } = requestNewContractBankAccount.getIn(['payload']);

        if (isNewPending) {
          message.success('Informações de Conta Bancária Requisitada com Sucesso.');
        } else {
          message.warning('Esta Pendência Já Havia Sido Gerada.');
        }
        setIsLoading(false);
        return true;
      }
      case REQUEST_NEW_CONTRACT_BANK_ACCOUNT_FAILURE: {
        message.error('Falha ao Solicitar Informações de Conta Bancária.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.requestNewImage, requestNewImage) === false) {
    switch (requestNewImage.getIn(['type'])) {
      case REQUEST_NEW_IMAGE_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case REQUEST_NEW_IMAGE_SUCCESS: {
        const requestNewImagePayload = requestNewImage.getIn(['payload']);

        if (requestNewImagePayload.isNewPending) {
          message.success('Nova Imagem Requisitada com Sucesso.');
        } else {
          message.warning('Esta Pendência Já Havia Sido Gerada.');
        }
        setIsLoading(false);
        return true;
      }
      case REQUEST_NEW_IMAGE_FAILURE: {
        message.error('Falha ao Solicitar Nova Imagem.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.requestSignature, requestSignature) === false) {
    switch (requestSignature.getIn(['type'])) {
      case REQUEST_NEW_SIGNATURE_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case REQUEST_NEW_SIGNATURE_SUCCESS: {
        const requestSignaturePayload = requestSignature.getIn(['payload']);

        if (requestSignaturePayload.isNewPending) {
          message.success('Pedido de Reenvio de Assinatura Enviado com Sucesso.');
        } else {
          message.warning('Esta Pendência Já Havia Sido Gerada.');
        }
        setIsLoading(false);
        return true;
      }
      case REQUEST_NEW_SIGNATURE_FAILURE: {
        message.error('Falha ao Solicitar Reenvio de Assinatura.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.requestAddress, requestAddress) === false) {
    switch (requestAddress.getIn(['type'])) {
      case REQUEST_ADDRESS_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case REQUEST_ADDRESS_SUCCESS: {
        const payload = requestAddress.getIn(['payload']);

        if (payload.isNewPending) {
          message.success('Pedido de Reenvio de Endereço Enviado com Sucesso.');
        } else {
          message.warning('Esta Pendência Já Havia Sido Gerada.');
        }
        setIsLoading(false);
        return true;
      }
      case REQUEST_ADDRESS_FAILURE: {
        message.error('Falha ao Solicitar Reenvio de Endereço.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.reserveMargin, reserveMargin) === false) {
    switch (reserveMargin.getIn(['type'])) {
      case RESERVE_MARGIN_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case RESERVE_MARGIN_SUCCESS: {
        message.success('Margem Reservada com Sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);

        return true;
      }
      case RESERVE_MARGIN_FAILURE: {
        message.error('Falha ao Reservar Margem.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.revertCetelem, revertCetelem) === false) {
    switch (revertCetelem.getIn(['type'])) {
      case REVERT_TO_CETELEM_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case REVERT_TO_CETELEM_SUCCESS: {
        message.success('Sucesso ao Reverter Contrato.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);

        return true;
      }
      case REVERT_TO_CETELEM_FAILURE: {
        message.error('Falha ao Reverter Contrato.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.reverseCancelContract, reverseCancelContract) === false) {
    switch (reverseCancelContract.getIn(['type'])) {
      case REVERSE_CANCEL_CONTRACT_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case REVERSE_CANCEL_CONTRACT_SUCCESS: {
        setIsLoading(false);
        message.success('Sucesso ao Reverter Cancelamento.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));

        return true;
      }
      case REVERSE_CANCEL_CONTRACT_FAILURE: {
        setIsLoading(false);
        message.error('Falha ao Reverter Cancelamento.');
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.checkedContract, checkedContract) === false) {
    switch (checkedContract.getIn(['type'])) {
      case SET_CHECKED_CONTRACT_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case SET_CHECKED_CONTRACT_SUCCESS: {
        message.success('Marcado com Sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);

        return true;
      }
      case SET_CHECKED_CONTRACT_FAILURE: {
        message.error('Falha ao marcar contrato como verificado.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.uncheckedContract, uncheckedContract) === false) {
    switch (uncheckedContract.getIn(['type'])) {
      case SET_UNCHECKED_CONTRACT_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case SET_UNCHECKED_CONTRACT_SUCCESS: {
        message.success('Desmarcado com Sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);

        return true;
      }
      case SET_UNCHECKED_CONTRACT_FAILURE: {
        message.error('Falha ao desmarcar contrato como verificado.');
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (Immutable.is(prevProps.sendBiometry, sendBiometry) === false) {
    switch (sendBiometry.getIn(['type'])) {
      case SEND_BIOMETRY_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case SEND_BIOMETRY_SUCCESS: {
        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));
        setIsLoading(false);

        return true;
      }
      case SEND_BIOMETRY_FAILURE: {
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  return {};
}

export function propagateStateChangeToProps() {
  return {};
}
