/* @flow */

export const SEND_CONTRACT_COMMENT_REQUEST = 'SEND_CONTRACT_COMMENT_REQUEST';
export const SEND_CONTRACT_COMMENT_SUCCESS = 'SEND_CONTRACT_COMMENT_SUCCESS';
export const SEND_CONTRACT_COMMENT_FAILURE = 'SEND_CONTRACT_COMMENT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: SEND_CONTRACT_COMMENT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: SEND_CONTRACT_COMMENT_FAILURE,
      payload: resource,
    });
  };
}

export default function sendContractComment(contractId, comment) {
  return dispatch => {
    dispatch({
      type: SEND_CONTRACT_COMMENT_REQUEST,
      payload: {
        contractId,
        commentary: comment,
      },
    });
  };
}
