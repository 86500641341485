import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import TagManager from 'react-gtm-module';
import MainApp from './MainApp';
import { keycloakInit } from './common/utils/keycloak';
import { initRemoteConfig } from './common/utils/firebase/remoteConfig';
import { getSentryConfig } from '../config/sentry';
import packageJson from '../package.json';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');

const release = `${packageJson.version}:${process.env.REACT_APP_ENV}-${process.env.REACT_APP_BUILD_NUM}`;
const { dsn, authToken } = getSentryConfig(
  release,
  process.env.REACT_APP_SENTRY_AUTH_TOKEN,
  process.env.NODE_ENV,
);

Sentry.init({
  release,
  dsn,
  authToken,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const renderApp = () => render(<MainApp />, container);

(async () => {
  try {
    await initRemoteConfig();
  } catch (e) {
    console.warn('ATENÇÃO!!! Erro ao carregar remote config. Erro:', e);
  }

  await keycloakInit();
  renderApp();
})();
