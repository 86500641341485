import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

type InputFieldProps = {
  onFileOk: (file: any) => any;
  accept?: string;
};

function UploadFile({ onFileOk, accept = 'image/*' }: InputFieldProps, ref) {
  const iptRef = useRef(null);

  useImperativeHandle(ref, () => ({
    open: () => {
      iptRef.current.click();
    },
  }));

  const onChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      iptRef.current.value = null;
      onFileOk(file);
    },
    [onFileOk, iptRef.current],
  );

  return <input ref={iptRef} hidden type="file" accept={accept} onChange={onChange} />;
}

export default forwardRef(UploadFile);
