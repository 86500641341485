import takeRequest from '~/store/takeRequest';
import * as ClixActions from './actions';

export function* clixSaga() {
  yield takeRequest(ClixActions.listClixContracts);
  yield takeRequest(ClixActions.getClientDetails);
  yield takeRequest(ClixActions.getTransactions);
  yield takeRequest(ClixActions.getTransactionDetails);
  yield takeRequest(ClixActions.getInvoices);
  yield takeRequest(ClixActions.getTransactionFile);
  yield takeRequest(ClixActions.getInvoiceDetails);
  yield takeRequest(ClixActions.getScores);
  yield takeRequest(ClixActions.getTransactionBojo);
  yield takeRequest(ClixActions.getContractBojo);
  yield takeRequest(ClixActions.getAvailableProducts);
}
