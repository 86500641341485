/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import queryString from 'query-string';

import { LOAN_HISTORIES_FETCH_REQUEST, success, failure } from './action';

const fetchPeople = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(LOAN_HISTORIES_FETCH_REQUEST)
    .map(({ payload: { params } }) => params)
    .mergeMap(params =>
      AjaxRequest.open(
        'get',
        `/admin/loanhistorycustom/loanhistories?${queryString.stringify(
          params,
        )}`,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(fetchPeople);
