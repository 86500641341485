/* @flow */
/* global document, FormData */
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withState,
  lifecycle,
} from 'recompose';
import { mapObject } from 'underscore';

import theme from '~/themes/aphroditeTheme/theme';
import screenRegister from '~/hoc/screenRegister';

import { fetchFinancialInstitutions } from '~/store/utilities/actions';
import { fetchLoanTypes } from '~/store/contracts/actions';

import fillContract, {
  FILL_CONTRACT_SUCCESS,
  FILL_CONTRACT_FAILURE,
} from '~/store/fillContract/action';

import { message } from '~/common';
import ApiErrorCodes from '~/constants/ApiErrorCodes';
import FillContract from './FillContract';

const { CONTRACT_CONTRACTVALUEBELOWREQUIRED } = ApiErrorCodes;
const { withStyles } = theme;

FillContract.defaultProps = {};

const Styles = () => ({});

function componentDidMount() {
  this.props.dispatch(fetchLoanTypes.start());
}

function fetchFinancialInstitutionsByAgreementId({ dispatch }) {
  return (id) => {
    dispatch(fetchFinancialInstitutions.start(id));
  };
}

function onSubmit({ dispatch, isFillingContract }) {
  return (params) => {
    isFillingContract(true);
    dispatch(fillContract(params));
  };
}

function mapStateToProps(state) {
  return {
    financialInstitutions: fetchFinancialInstitutions.getResult()(state),
    loanTypes: fetchLoanTypes.getResult()(state),
    loanTypesSuccess: fetchLoanTypes.isSuccessful()(state),
    loanTypesFailure: fetchLoanTypes.hasFailure()(state),
    fillContractState: state.getIn(['fillContract']),
  };
}

function receiveChanges(
  prevProps,
  {
    financialInstitutions,
    loanTypes,
    loanTypesSuccess,
    loanTypesFailure,
    fillContractState,
    history,
    isFillingContract,
  },
) {
  if (Immutable.is(prevProps.loanTypes, loanTypes) === false) {
    if (loanTypesSuccess) {
      return true;
    }
    return false;
  }

  if (Immutable.is(prevProps.loanTypesFailure, loanTypesFailure) === false) {
    if (loanTypesFailure) {
      return true;
    }
    return false;
  }

  if (Immutable.is(prevProps.fillContractState, fillContractState) === false) {
    switch (fillContractState.getIn(['type'])) {
      case FILL_CONTRACT_SUCCESS: {
        isFillingContract(false);
        const contractId = fillContractState.getIn(['payload']);
        history.push({
          pathname: `/backoffice/contracts/${contractId}`,
          state: {
            registeredWithSuccess: true,
          },
        });

        return true;
      }
      case FILL_CONTRACT_FAILURE: {
        isFillingContract(false);
        const payload = fillContractState.getIn(['payload']);
        const errorCode = payload.messages ? payload.messages[0] : null;

        if (
          errorCode
          && errorCode.text === CONTRACT_CONTRACTVALUEBELOWREQUIRED
        ) {
          message.error(
            'O valor de contrato informado é abaixo do mínimo permitido',
          );
        } else {
          message.error('Falha ao Preencher Contrato');
        }

        break;
      }
      default:
        return false;
    }
  }

  return false;
}

function propagateStateChangeToProps(state) {
  return mapObject(
    {
      factorTypes: state.factorTypes,
      loanTypes: state.loanTypes,
      financialInstitutions: state.financialInstitutions,
    },
    (state) => (typeof state === 'undefined' ? [] : state.getIn?.(['payload'])) || state,
  );
}

export default compose(
  withStyles(Styles),
  screenRegister({
    screenName: 'FillContract',
    path: '/backoffice/people/:id/fill-contract',
    headerTitle: 'Preencher contrato',
  }),
  connect(mapStateToProps),
  withState('fillingContract', 'isFillingContract', false),
  withState('personId', 'setPersonId', null),
  lifecycle({
    componentDidMount,
  }),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    fetchFinancialInstitutionsByAgreementId,
    onSubmit,
  }),
)(FillContract);
