import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';

export const accessByCostumer = createAsyncAction('ACCESS_BY_CUSTOMER', {
  saveResult: true,
  initialValue: {},
  context: {
    request: ({ id }) => telli.get(`/admin/personcustom/people/${id}/logintoken`),
  },
});
