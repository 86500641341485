// @flow
import React, { useCallback, useMemo, useState } from 'react';

import { Button, InputNumber, List } from 'antd';

import {
  CONTRACT_QUEUE_STATUS,
  parseContractQueueStatus,
} from '~/typings/enums/ContractQueueStatus';
import modalBuilder from '~/hoc/modalBuilder';

import { QUEUE_CONTRACT_LIMIT } from '~/constants';
import { useAwaitControl } from 'react-redux-await-control';
import { advanceRequestQueue, listQueueContracts } from '~/store/contracts/actions';
import { ListItem, ListItemContent, ModalContent, ModalFooter, ModalTitle } from './styled';

type AskContractsProps = {
  queues: any[];
  submit: (...args) => void;
};

function AskContracts(props: AskContractsProps) {
  const [selected, setSelected] = useState<any>();
  const [quantity, setQuantity] = useState(1);

  const [listQueueContractsControl, advanceRequestQueueControl] = useAwaitControl([
    listQueueContracts,
    advanceRequestQueue,
  ]);
  const advancedPending = advanceRequestQueueControl.isRunning();
  const currentQueueSize = listQueueContractsControl.result()?.length;

  const limit = useMemo(() => QUEUE_CONTRACT_LIMIT - currentQueueSize, [currentQueueSize]);

  const maxQty = useMemo(() => {
    if (selected) {
      if (!selected.amount) {
        return limit;
      }

      return selected.amount > limit ? limit : selected.amount;
    }
    return limit;
  }, [selected, limit]);

  const list = useMemo(() => {
    const queueNames = Object.keys(CONTRACT_QUEUE_STATUS).sort();
    return queueNames.map((qName) => ({
      id: qName,
      title: parseContractQueueStatus(qName),
      amount: props.queues?.find((queue: any) => queue?.contractQueueStatus === qName)?.count || 0,
    }));
  }, [props.queues]);

  const select = useCallback(
    (item) => () => {
      setSelected(item);
    },
    [],
  );

  const submit = useCallback(
    () => props.submit?.({ contractQueueStatus: selected.id, requestedNumber: quantity }),
    [selected, quantity],
  );

  const changeValue = useCallback((value) => setQuantity(value), []);

  return (
    <>
      <ModalTitle>Escolha a fila e a quantidade de contratos</ModalTitle>
      <ModalContent>
        <List
          bordered={false}
          dataSource={list}
          renderItem={(item, index) => (
            <ListItem odd={index % 2 === 1 ? 1 : 0} selected={selected?.id === item.id}>
              <ListItemContent onClick={select(item)}>
                <span>{item.title}</span>
                <span>
                  {item.amount} {item.amount === 1 ? 'contrato' : 'contratos'}
                </span>
              </ListItemContent>
            </ListItem>
          )}
        />
      </ModalContent>
      <ModalFooter>
        <InputNumber
          min={1}
          max={maxQty}
          value={quantity}
          onChange={changeValue}
          disabled={selected?.amount === 0 ? advancedPending : !selected}
        />

        <Button type="primary" onClick={submit} disabled={!selected}>
          Pegar contratos
        </Button>
      </ModalFooter>
    </>
  );
}

export default modalBuilder(AskContracts, 'ASK_CONTRACTS', { height: 630 });
