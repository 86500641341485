import React, { useEffect } from 'react';
import AsyncActionControl from 'react-redux-await-control/dist/AsyncActionControl';
import { useAwaitControl } from 'react-redux-await-control';

import useChildrenWithProps from '~/hooks/useChildrenWithProps';

type AsyncActionWrapperProps = {
  action: AsyncActionControl;
  dataFieldName?: string;
  actionKey?: string;
  children: React.ReactElement | React.ReactElement[];
}

export default function AsyncActionWrapper({
  action, children, actionKey, dataFieldName = 'data',
}: AsyncActionWrapperProps) {
  const control = useAwaitControl(action);
  const result = control.result();

  const childrenWithProps = useChildrenWithProps(children, { [dataFieldName]: result });

  useEffect(() => {
    control.start({}, { actionId: actionKey });
    return () => control.clear({}, { actionId: actionKey });
  }, []);

  return (
    <>{childrenWithProps}</>
  );
}
