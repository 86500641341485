const MarginSource = (props: any) => {
  switch (props.value) {
    case 'MEUINSSONLINE':
      return 'Meu INSS Senha';
    case 'MEUINSSPDF':
      return 'Meu INSS Hiscon';
    case 'MANUAL':
      return 'Cadastro manual';
    case 'VIPER':
      return 'Viper';
    default:
      return null;
  }
};

export default MarginSource;
