import React, { Component } from 'react';
import {
  Alert, Button, Icon, Popover,
} from 'antd';
import { Container, ContainerButton } from '~/components/FraudAlert/styled';
import type { FraudProfileProps } from '~/components/FraudAlert/components/FraudProfile';
import FraudProfile from '~/components/FraudAlert/components/FraudProfile';

type FraudAlertProps = {
  fraudInfo: {
    relatedFrauds: Array<FraudProfileProps>,
    ifFraud: boolean,
  },
};

class FraudAlert extends Component<FraudAlertProps> {
  state = { popoverVisible: false };

  renderPopover(relatedFrauds) {
    return relatedFrauds.map((fraud) => <FraudProfile {...fraud} />);
  }

  onShowHidePopover = () => {
    this.setState({ popoverVisible: !this.state.popoverVisible });
  };

  render() {
    const { fraudInfo } = this.props;
    if (!fraudInfo) return null;
    const { relatedFrauds, isFraud } = fraudInfo;
    if (!isFraud) return null;
    return (
      <Container>
        <Alert
          type="error"
          showIcon
          icon={(
            <Icon
              type="exclamation-circle"
              theme="twoTone"
              twoToneColor="red"
            />
          )}
          message="Possível fraude"
          description="Esse cliente tem o rosto associado a outras contas."
        />
        <ContainerButton>
          <Popover
            trigger="click"
            content={this.renderPopover(relatedFrauds)}
            title="Contas"
          >
            <Button
              onClick={this.onShowHidePopover}
              type="danger"
              size="small"
              danger
            >
              Ver contas
            </Button>
          </Popover>
        </ContainerButton>
      </Container>
    );
  }
}

export default FraudAlert;
