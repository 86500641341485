import { matchPath } from 'react-router';

import { last } from 'underscore';

import type { ScreenComponent, ModalComponent } from '~/typings/components';

type HeaderRouteConfig = {
  hidden: boolean;
  title: string;
  noBackButton: boolean;
};

export type RouteConfig = {
  path: string;
  exact?: boolean;
  component?: any;
  privateRoute?: boolean;
  stages?: string[];
  modals?: ModalComponent[];
  header?: HeaderRouteConfig;
};

type RoutesConfig = {
  [screenName: string]: RouteConfig;
};

export default class RouterManager {
  _routes: RoutesConfig;

  static parseRoutes(screenComponents: ScreenComponent[]): RoutesConfig {
    return screenComponents.reduce((routesConfig, screenComponent) => {
      if (screenComponent.__type !== 'screen') {
        throw new Error(`Screen ${screenComponent.screenName} wasn't registered as a screen.`);
      }

      if (screenComponent.path) {
        const baseConfig: any = screenComponent.baseConfig || {
          exact: true,
          privateRoute: true,
        };
        baseConfig.header = { hidden: false, noBackButton: true, title: '' };
        baseConfig.path = screenComponent.path;
        baseConfig.stages = screenComponent.stages;
        baseConfig.component = screenComponent;
        baseConfig.modals = screenComponent.modals || [];
        baseConfig.header.title = screenComponent.headerTitle;
        baseConfig.header.customTitle = screenComponent.customHeaderTitle;
        baseConfig.header.enbaledOnMenu = !!screenComponent.headerMenu;
        baseConfig.customClass = screenComponent.customClass;
        baseConfig.customLayout = screenComponent.customLayout;
        baseConfig.header.menuPosition = screenComponent.headerMenuPosition || 9999;
        routesConfig[screenComponent.screenName] = baseConfig;
      }

      return routesConfig;
    }, {});
  }

  static matchPathWithRoute(pathname: string, route: RouteConfig) {
    const path = pathname.replace(/\/$/g, '');

    if (!route.path) return false;
    if (route.path.includes(':')) {
      return !!matchPath(path, { path: route.path, exact: true });
    }

    return route.path === path;
  }

  constructor(...screenComponents: ScreenComponent[]) {
    this._routes = RouterManager.parseRoutes(screenComponents);
  }

  getRoutes() {
    return Object.values(this._routes);
  }

  getRoute(routeName: string) {
    return this._routes[routeName];
  }

  getPath(routeName: string, params: Array<any>) {
    const route = this._routes[routeName];

    if (!route) {
      throw new Error(`No route found for name: ${routeName}`);
    }

    if (route.path.includes(':')) {
      const routeSplitted = route.path.split('/');
      let replaceQty = 0;
      for (let i = 0; i < routeSplitted.length; i += 1) {
        if (routeSplitted[i].includes(':')) {
          routeSplitted[i] = params[replaceQty];
          replaceQty += 1;
        }
      }
      return routeSplitted.join('/');
    }

    return route.path;
  }

  getByPathname(pathname: string) {
    return Object.values(this._routes).find((o) => RouterManager.matchPathWithRoute(pathname, o));
  }

  getRoutesNames() {
    return Object.keys(this._routes).reduce((routeNames, routeName) => {
      routeNames[routeName] = routeName;
      return routeNames;
    }, {});
  }

  getRouteHierarchy(pathname: string, basePath: string = '') {
    if (pathname?.startsWith(basePath)) {
      let path = pathname;

      if (path.endsWith('/')) {
        path = pathname.slice(0, -1);
      }

      path = path.replace(basePath, '');
      const pathsHierarchy = path.split('/').reduce((paths, segment) => {
        const lastPath = last(paths);
        paths.push(`${lastPath || basePath}${segment}/`);
        return paths;
      }, []);

      return pathsHierarchy
        .map((pathHierarchy) => this.getByPathname(pathHierarchy))
        .filter((pathHierarchy) => !!pathHierarchy);
    }

    return [];
  }
}
