const ReservationStatus = (props: any) => {
  switch (props.value) {
    case 'APPROVED':
      return 'Averbado';
    case 'CANCELLED':
      return 'Cancelado';
    case 'RESERVED':
      return 'Reservado';
    case 'NOTFOUND':
      return 'Não Encontrado';
    default:
      return null;
  }
};

export default ReservationStatus;
