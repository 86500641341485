/* @flow */

export const FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_REQUEST =
  'FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_REQUEST';
export const FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_SUCCESS =
  'FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_SUCCESS';
export const FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_FAILURE =
  'FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(params: { contractId: number } = {}) {
  return dispatch => {
    dispatch({
      type: FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_REQUEST,
      payload: {
        params,
        ...params,
      },
    });
  };
}
