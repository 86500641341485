import styled from 'styled-components';

type ImageType = {
  image: string;
};

export const Image = styled.div<ImageType>`
  height: 205px;
  width: 188px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  cursor: pointer;
`;

export const ImageModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ImageContent = styled.img`
  max-width: 90%;
  max-height: 90%;
`;

export const PDFContent = styled.embed`
  min-height: 80vh;
  max-width: 80vw;
  flex-grow: 1;
`;

export const AudioContent = styled.audio`
  background-color: transparent;

  ::-webkit-media-controls-panel {
    background-color: transparent;
  }

  ::-moz-media-controls-panel {
    background-color: transparent;
  }
`;

export const VideoContent = styled.video`
  height: 300px;
  width: 250px;
`;
