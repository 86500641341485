/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { getQueryParams } from '~/common';
import { TAGTYPES_DELETE_REQUEST, success, failure } from './action';

const getTagTypes = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(TAGTYPES_DELETE_REQUEST)
    .map(({ data }) => data)
    .mergeMap((params) =>
      AjaxRequest.open(
        'delete',
        `/admin/tag/tagtype?${getQueryParams(params)}`,
      )('tudo')
        .flatMap((data) => Observable.of(success(data.response.resource)))
        .catch((err) => Observable.of(failure(err))),
    );

export default combineEpics(getTagTypes);
