import React, { useState } from 'react';

import { CaretDown } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';
import { Ticket } from '~/typings/entities/Ticket';

import { useDrawer } from '~/hooks/useDrawer';
import { useAwaitControl } from 'react-redux-await-control';
import { selectTicket } from '~/store/tickets/actions';
import { TicketFieldValue, List, TicketLink } from './TicketLinkPopover.styled';

type TicketLinkPopoverProps = {
  tickets: Ticket[];
};

export function TicketLinkPopover({ tickets }: TicketLinkPopoverProps) {
  const { setConfig } = useDrawer();
  const [isVisible, setIsVisible] = useState(false);
  const selectTicketControl = useAwaitControl(selectTicket);
  const handleLinkCLick = (id: string) => {
    setIsVisible(false);
    setConfig('ticket-details', {
      title: (
        <>
          Ticket número <b>#{id}</b>
        </>
      ),
    });

    selectTicketControl.start(id);
  };

  const renderTicketLink = (ticketId: string) => {
    if (!ticketId) {
      return <span>Não informado</span>;
    }

    return <TicketLink onClick={() => handleLinkCLick(ticketId)}>#{ticketId}</TicketLink>;
  };

  if (tickets.length === 1) {
    return renderTicketLink(`${tickets[0].id}`);
  }

  return (
    <Popover
      trigger="click"
      width="auto"
      onVisibleChange={(ev) => setIsVisible(ev)}
      visible={isVisible}
      noHeader
      content={
        <List>
          {tickets.map(({ id }) => (
            <span key={id}>{renderTicketLink(`${id}`)}</span>
          ))}
        </List>
      }
    >
      <TicketFieldValue>
        <p>#{tickets[0].id || '-'}</p>
        <CaretDown className="action-icon" width={14} height={14} />
      </TicketFieldValue>
    </Popover>
  );
}
