/* @flow */

export const GET_DOCUMENT_CONTRACT_REQUEST = 'GET_DOCUMENT_CONTRACT_REQUEST';
export const GET_DOCUMENT_CONTRACT_SUCCESS = 'GET_DOCUMENT_CONTRACT_SUCCESS';
export const GET_DOCUMENT_CONTRACT_FAILURE = 'GET_DOCUMENT_CONTRACT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: GET_DOCUMENT_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: GET_DOCUMENT_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(contractId) {
  return dispatch => {
    dispatch({
      type: GET_DOCUMENT_CONTRACT_REQUEST,
      payload: {
        contractId,
      },
    });
  };
}
