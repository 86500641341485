import { initializeApp } from 'firebase/app';
import { getRemoteConfig, isSupported } from 'firebase/remote-config';

export const firebaseConfig = {
  apiKey: 'AIzaSyB-6kuCTGxk7ctKNEkTr-EE1nb-m2F4EBk',
  authDomain: 'backoffice-46cfa.firebaseapp.com',
  projectId: 'backoffice-46cfa',
  storageBucket: 'backoffice-46cfa.appspot.com',
  messagingSenderId: '940516904158',
  appId: '1:940516904158:web:d97a131ef344e80690864f',
};

export const app = initializeApp(firebaseConfig);
export const remoteConfig = async () => (await isSupported()) && getRemoteConfig(app);
