import React from 'react';

import { StyledDivider } from './Divider.styled';

export type DividerProps = {
  orientation?: 'horizontal' | 'vertical';
  size?: number | string;
  color?: string;
  lineType?: 'solid' | 'dashed';
  margin?: number;
};

export const Divider: React.FC<DividerProps> = ({
  orientation = 'horizontal',
  size = '100%',
  lineType = 'dashed',
  color,
  margin,
}) => (
  <StyledDivider
    orientation={orientation}
    size={size}
    color={color}
    lineType={lineType}
    margin={margin}
  />
);
