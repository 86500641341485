import colors from './colors';
import shadows from './shadows';
import typography from './typography';
import breakpoints from './breakpoints';

const defaultTheme = {
  colors,
  shadows,
  typography,
  breakpoints,
};

export default defaultTheme;
