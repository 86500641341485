import { combineEpics } from 'redux-observable';

import addressFromZipcode from './addressFromZipcode/epic';
import adjustContract from './adjustContract/epic';
import approvePersonRegistry from './approvePersonRegistry/epic';
import authenticationChat from './authenticationChat/epic';
import cancelContractSignature from './cancelContractSignature/epic';
import concludeContractSignature from './concludeContractSignature/epic';
import createUser from './createUser/epic';
import confirmRequestRefusalContract from './confirmRequestRefusalContract/epic';
import deleteLoanHistory from './deleteLoanHistory/epic';
import fetchAvailableExistingLoans from './fetchAvailableExistingLoans/epic';
import fetchAvailableLoanHistories from './fetchAvailableLoanHistories/epic';
import fetchCommissioningTypes from './fetchCommissioningTypes/epic';
import fetchContractCommisionings from './fetchContractCommisionings/epic';
import fetchContractRefusalReasons from './fetchContractRefusalReasons/epic';
import fetchContracts from './fetchContracts/epic';
import fetchContractsOffers from './fetchContractsOffers/epic';
import fetchFinancialInstitutionsForDeposit from './fetchFinancialInstitutionsForDeposit/epic';
import fetchLoanHistories from './fetchLoanHistories/epic';
import fetchOperationStepsByContract from './fetchOperationStepsByContract/epic';
import fetchPersonRegisterResetReasons from './fetchPersonRegisterResetReasons/epic';
import fetchRefinancedContracts from './fetchRefinancedContracts/epic';
import fillContract from './fillContract/epic';
import getChatInfo from './getChatInfo/epic';
import getConcession from './getConcession/epic';
import getContractComments from './getContractComments/epic';
import getContractDocument from './getContractDocument/epic';
import getContractFiles from './getContractFiles/epic';
import getContractImageFaceFront from './getContractImageFaceFront/epic';
import getContractLogs from './getContractLogs/epic';
import getFileHistory from './getFileHistory/epic';
import getFileHistoryFiles from './getFileHistoryFiles/epic';
import getHiscon from './getHiscon/epic';
import getMedicalCertificate from './getMedicalCertificate/epic';
import getPersonRegistryFiles from './getPersonRegistryFiles/epic';
import getValidationBiometry from './getValidationBiometry/epic';
import linkContractOwner from './linkContractOwner/epic';
import listRolesOfRoles from './listRolesOfUser/epic';
import payContract from './payContract/epic';
import refusePersonRegistry from './refusePersonRegistry/epic';
import registerCommissioning from './registerCommissioning/epic';
import registerLoanHistory from './registerLoanHistory/epic';
import requestAddress from './requestAddress/epic';
import requestNewContractBankAccount from './requestNewContractBankAccount/epic';
import requestNewImage from './requestNewImage/epic';
import requestNewSignature from './requestNewSignature/epic';
import requestRefusalContract from './requestRefusalContract/epic';
import requestResetPerson from './requestResetPerson/epic';
import revertCetelem from './revertCetelem/epic';
import reserveMargin from './reserveMargin/epic';
import resetPerson from './resetPerson/epic';
import reverseCancelContract from './reverseCancelContract/epic';
import saveTrustworthyBiometry from './saveTrustworthyBiometry/epic';
import sendBiometry from './sendBiometry/epic';
import sendCCBForUserSignature from './sendCCBForUserSignature/epic';
import sendContract from './sendContract/epic';
import sendContractComment from './sendContractComment/epic';
import sendSignatureToContract from './sendSignatureToContract/epic';
import setCheckedContract from './setCheckedContract/epic';
import setRefinancedAkey from './setRefinancedAkey/epic';
import setUncheckedContract from './setUncheckedContract/epic';
import solveAddress from './solveAddress/epic';
import solveBankAccount from './solveBankAccount/epic';
import solveDocument from './solveDocument/epic';
import solvePaycheck from './solvePaycheck/epic';
import solveProofOfAddress from './solveProofOfAddress/epic';
import tagsGet from './tagsGet/epic';
import tagTypesDelete from './tagTypesDelete/epic';
import tagTypesGet from './tagTypesGet/epic';
import tagTypesSave from './tagTypesSave/epic';
import undoRequestRefusal from './undoRequestRefusal/epic';
import updateConsigneeStatus from './updateConsigneeStatus/epic';
import updateContractValues from './updateContractValues/epic';
import updateLoanHistory from './updateLoanHistory/epic';
import updateMargin from './updateMargin/epic';
import updatePDFFile from './updatePDFFile/epic';
import updateRefinancedContract from './updateRefinancedContract/epic';
import updateTrustFace from './updateTrustFace/epic';

import uploadMedicalCertificate from './uploadMedicalCertificate/epic';

export default combineEpics(
  addressFromZipcode,
  adjustContract,
  approvePersonRegistry,
  authenticationChat,
  cancelContractSignature,
  concludeContractSignature,
  confirmRequestRefusalContract,
  createUser,
  deleteLoanHistory,
  fetchAvailableExistingLoans,
  fetchAvailableLoanHistories,
  fetchCommissioningTypes,
  fetchContractCommisionings,
  fetchContractRefusalReasons,
  fetchContracts,
  fetchContractsOffers,
  fetchFinancialInstitutionsForDeposit,
  fetchLoanHistories,
  fetchOperationStepsByContract,
  fetchPersonRegisterResetReasons,
  fetchRefinancedContracts,
  fillContract,
  getChatInfo,
  getConcession,
  getContractComments,
  getContractDocument,
  getContractFiles,
  getContractImageFaceFront,
  getContractLogs,
  getFileHistory,
  getFileHistoryFiles,
  getHiscon,
  getMedicalCertificate,
  getPersonRegistryFiles,
  getValidationBiometry,
  linkContractOwner,
  listRolesOfRoles,
  payContract,
  refusePersonRegistry,
  registerCommissioning,
  registerLoanHistory,
  requestAddress,
  requestNewContractBankAccount,
  requestNewImage,
  requestNewSignature,
  requestRefusalContract,
  requestResetPerson,
  revertCetelem,
  reserveMargin,
  resetPerson,
  reverseCancelContract,
  saveTrustworthyBiometry,
  sendCCBForUserSignature,
  sendContract,
  sendContractComment,
  sendSignatureToContract,
  setCheckedContract,
  setRefinancedAkey,
  sendBiometry,
  solveAddress,
  solveBankAccount,
  solveDocument,
  solvePaycheck,
  solveProofOfAddress,
  setUncheckedContract,
  tagsGet,
  tagTypesDelete,
  tagTypesGet,
  tagTypesSave,
  undoRequestRefusal,
  updateConsigneeStatus,
  updateContractValues,
  updateLoanHistory,
  updateMargin,
  updatePDFFile,
  updateRefinancedContract,
  updateTrustFace,
  uploadMedicalCertificate,
);
