/* @flow */

export const FETCH_REFINANCED_CONTRACTS_REQUEST =
  'FETCH_REFINANCED_CONTRACTS_REQUEST';
export const FETCH_REFINANCED_CONTRACTS_SUCCESS =
  'FETCH_REFINANCED_CONTRACTS_SUCCESS';
export const FETCH_REFINANCED_CONTRACTS_FAILURE =
  'FETCH_REFINANCED_CONTRACTS_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: FETCH_REFINANCED_CONTRACTS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FETCH_REFINANCED_CONTRACTS_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(contractId, params = { limit: 10 }) {
  return dispatch => {
    dispatch({
      type: FETCH_REFINANCED_CONTRACTS_REQUEST,
      payload: {
        contractId,
        params,
      },
    });
  };
}
