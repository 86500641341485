import { DatePicker, TimePicker } from 'antd';
import styled from 'styled-components';
import { CloseCircle } from '~/ui/assets/icons';

export const CloseButton = styled(CloseCircle)`
  position: absolute;
  right: 0;
  display: none;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 17px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.primary[50]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[50]};
  position: relative;

  svg {
    fill: ${({ theme }) => theme.colors.primary.main};
  }

  .divider {
    margin: 2px;
  }

  &:hover {
    ${CloseButton} {
      display: block;
    }
  }
`;

export const StyledDatePicker = styled(DatePicker).attrs({
  showToday: false,
  allowClear: false,
  format: 'dddd, DD [de] MMMM',
  className: 'mt-date-picker',
})`
  input {
    cursor: pointer;
    border: none;
    padding: 0 !important;
    font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
    color: ${({ theme }) => theme.colors.gray[900]};
    font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
    line-height: ${({ theme }) => theme.typography.types.paragraphSmall.lineHeight};
    font-weight: 500;

    &:focus {
      border: none;
      box-shadow: unset;
    }
  }

  i {
    display: none;
  }
`;

export const TimeInput = styled(TimePicker).attrs(({ minuteStep }) => ({
  bordered: false,
  allowClear: false,
  minuteStep: minuteStep || 30,
  popupClassName: 'mt-time-picker',
}))`
  width: 90px;

  input {
    color: ${({ theme }) => theme.colors.primary.main};
    font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
    color: ${({ theme }) => theme.colors.primary.main};
    font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
    line-height: ${({ theme }) => theme.typography.types.paragraphSmall.lineHeight};
    font-weight: 500;
    cursor: pointer;
    border: none;
    padding: 0 !important;

    &:focus {
      border: none;
      box-shadow: unset;
    }
  }

  span {
    display: none;
  }
`;
