import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const ContractFieldValue = styled.div`
  display: flex;
  color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  cursor: pointer;

  p {
    margin: 0;
  }

  svg {
    margin-top: 2px;
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const ContractsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
  }
`;
