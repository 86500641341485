import React from 'react';

import { ContractValueList, SuggestedProposalReturn } from '~/typings/entities/Opportunity';
import { Divider, Flex, Typography } from '~/ui/components';

import {
  CheckboxContainer,
  ContractBox,
} from '~/screens/Person/components/Drawers/Opportunity/REFIN/Selection/Selection.styled';
import toMoney from '~/common/masked/toMoney';
import { ResumeBody } from './Resume.styled';

export type ResumeProps = {
  contracts: ContractValueList[];
  currentProposal: SuggestedProposalReturn;
  contractsIds: number[];
  marginLoan: number;
  withMargin: boolean;
};

export function SelectionResume({
  contracts,
  currentProposal,
  contractsIds,
  marginLoan,
  withMargin,
}: ResumeProps) {
  return (
    <ResumeBody direction="column" gap={24} ph={32} pv={24} width="100%">
      <Flex align="center" justify="center" width="100%">
        <Typography type="paragraphLarge" weight={600} center fullWidth>
          Detalhes da oportunidade
        </Typography>
      </Flex>

      <Flex direction="column" gap={24} width="100%">
        {contracts?.map((contract, index) => (
          <>
            {contractsIds.includes(contract?.contractId) && (
              <ContractBox key={contract.contractId}>
                <CheckboxContainer rounded>
                  <Typography type="bodyLarge" weight={600} fullWidth>
                    Contrato {index + 1}
                  </Typography>
                </CheckboxContainer>

                <Flex width="100%" direction="column">
                  <Flex gap={4} width="100%">
                    <Typography type="bodyMedium" color="element.secondary">
                      Quantidade de parcelas:
                    </Typography>
                    <Typography type="bodyMedium" weight={600}>
                      {contract?.numberOfInstallments}
                    </Typography>
                  </Flex>

                  <Flex gap={4}>
                    <Typography type="bodyMedium" color="element.secondary">
                      Valor original:
                    </Typography>
                    <Typography type="bodyMedium" weight={600}>
                      {toMoney(contract?.installmentValue)}
                    </Typography>
                  </Flex>
                </Flex>
              </ContractBox>
            )}
          </>
        ))}

        {withMargin && (
          <ContractBox>
            <CheckboxContainer rounded>
              <Typography type="bodyLarge" weight={600}>
                Aproveitar margem livre
              </Typography>
            </CheckboxContainer>

            <Flex gap={4}>
              <Typography type="bodyMedium" color="element.secondary">
                Margem disponível:
              </Typography>
              <Typography type="bodyMedium" weight={600}>
                {toMoney(marginLoan) || 'R$ 0,00'}
              </Typography>
            </Flex>
          </ContractBox>
        )}
      </Flex>

      <Divider />

      <Flex direction="column" gap={8} width="100%" mb={32} mt={8}>
        <Flex justify="space-between" align="center" width="100%">
          <Typography type="bodyMedium" weight={600} fullWidth>
            Valor selecionado total:
          </Typography>
          <Typography type="headingH5" weight={600} fullWidth className="right">
            {contractsIds.length && currentProposal?.valueForDeposit
              ? toMoney(currentProposal?.valueForDeposit || 0)
              : 'R$ 0,00'}
          </Typography>
        </Flex>

        <Flex justify="space-between" align="center" width="100%">
          <Typography type="bodyMedium" weight={600} fullWidth>
            Nova parcela:
          </Typography>
          <Typography type="headingH6" weight={600} fullWidth className="right">
            {contractsIds.length && currentProposal?.installmentValue
              ? toMoney(currentProposal?.installmentValue || 0)
              : 'R$ 0,00'}
          </Typography>
        </Flex>
      </Flex>
    </ResumeBody>
  );
}
