const registrationStatus = {
  PASSWORD: 'PASSWORD',
  EMAIL: 'EMAIL',
  FACEPICTURE: 'FACEPICTURE',
  TERMS_OF_CONTRACT: 'TERMS_OF_CONTRACT',
  OPEN: 'OPEN',
};

const products = {
  NEW_LOAN: 'NEW',
  REFIN: 'REFIN',
  PORTABILITY: 'PORTABILITY',
};

const contractSatus = {
  FORMALIZATION: 'FORMALIZAÇÃO',
  PENDING: 'PENDENTE',
  RETENTION: 'RETENÇÃO',
};

const consignee = {
  326: 'Parati',
  329: 'Qitech',
  PARATI: 'Parati',
  QITECH: 'Qitech',
};

enum AGREEMENT {
  INSS = 42,
  FTGS = 50,
  PAB = 75,
  CLIX = 999,
}

export { AGREEMENT, contractSatus, registrationStatus, products, consignee };
