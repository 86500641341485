/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
/* global fetch, localStorage */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { endpoint } from '..';
import { UPDATE_PDF_FILE_REQUEST, success, failure } from './action';

const events = action$ =>
  action$
    .ofType(UPDATE_PDF_FILE_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(({ contractId, file, fileType }) => {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('filetype', fileType);

      return Observable.fromPromise(
        fetch(
          `${endpoint}/admin/contractcustom/contracts/${contractId}/updatepdffile`,
          {
            method: 'post',
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'authenticationToken',
              )}`,
            },
            body: formData,
          },
        ),
      )
        .map(response => {
          switch (response.status) {
            case 500:
              throw new Error('Internal Server Error');
            case 400:
              throw new Error('Bad Request');
            default:
              break;
          }
          return true;
        })
        .mergeMap(response => Observable.of(success(response)))
        .catch(err => Observable.of(failure(err)));
    });

export default combineEpics(events);
