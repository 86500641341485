// @flow

import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { Button, Modal, Spinner } from '~/components';
import { isEmpty } from 'underscore';
import { validateOnlyNumbers } from '~/common';

import UFs from '~/constants/UFs';
import formatZipcode from '~/common/formatZipcode';
const { Item: FormItem } = Form;
const { Option } = Select;

type SelectionProps = {
  onEntryChange: (fields: {}) => void,
  onFieldsError: () => void,
  banks: Array<{ name: string, bankNumber: string }>,
  showModal: boolean,
  onModalClose: () => void,
  onSend: (info: {}) => void,
};

const accountTypesDefault = [
  {
    key: 'CHECKING_ACCOUNT',
    name: 'Conta corrente',
  },
  {
    key: 'SAVINGS_ACCOUNT',
    name: 'Conta poupança',
  },
];

function Selection(props: SelectionProps) {
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (props.address && Object.keys(props.address).length) {
      setFields({ ...fields, ...props.address });
      props.form.setFieldsValue(props.address);
    }
  }, [props.address]);

  const onEntryChange = field => e => {
    const value = e && e.target ? e.target.value : e;
    if (field === 'zipCode') {
      if (
        value.length === 8 &&
        props.onEntryZipcodeSuccess &&
        validateOnlyNumbers(field, value)
      ) {
        props.onEntryZipcodeSuccess(value);
      }
    }
    const newfields = {
      ...fields,
      [field]: typeof e === 'string' ? e : value,
    };
    setFields(newfields);
  };

  const onEnter = () => {
    props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        props.onSend(fields);
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <Modal
      title='Endereço'
      maskClosable
      width={700}
      visible={props.showModal}
      key='modal'
      okText='Enviar'
      onCancel={props.onModalClose}
      footer={[<Button title='Enviar' onClick={onEnter} />]}
    >
      <div>
        <Spinner spinning={props.loading} />
        <Form onError={props.onFieldsError}>
          <Row type='flex' justify='start'>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              CEP
              <FormItem>
                {getFieldDecorator('zipCode', {
                  rules: [
                    {
                      required: true,
                      message: 'CEP é Obrigatório.',
                    },
                    {
                      validator: validateOnlyNumbers,
                      message: 'Campo deve conter apenas números.',
                    },
                  ],
                })(
                  <Input
                    placeholder='Digite o CEP'
                    maxLength={8}
                    onChange={onEntryChange('zipCode')}
                  />,
                )}
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 14 }}
              style={{ marginBottom: '10px' }}
            />
            <Col
              xs={{ span: 24 }}
              lg={{ span: 8 }}
              style={{ marginBottom: '10px' }}
            >
              Estado
              <FormItem>
                {getFieldDecorator('state', {
                  rules: [
                    {
                      required: true,
                      message: 'Estado (UF) é obrigatório',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Selecione a UF'
                    onChange={onEntryChange('state')}
                    allowClear
                    showSearch
                  >
                    {UFs.map(item => (
                      <Option key={item.value}>{item.name}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 15, offset: 1 }}
              style={{ marginBottom: '10px', offset: 1 }}
            >
              Cidade
              <FormItem>
                {getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Cidade é obrigatório',
                    },
                    {
                      max: 250,
                      message: 'Campo Cidade deve ter no máximo 250 caracteres',
                    },
                  ],
                })(
                  <Input
                    maxLength={251}
                    placeholder='Digite o nomm da Cidade'
                    onChange={onEntryChange('city')}
                  />,
                )}
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 8 }}
              style={{ marginBottom: '10px' }}
            >
              Bairro
              <FormItem>
                {getFieldDecorator('district', {
                  rules: [
                    {
                      required: true,
                      message: 'Bairro é obrigatório',
                    },
                    {
                      max: 250,
                      message: 'Campo Bairro deve ter no máximo 250 caracteres',
                    },
                  ],
                })(
                  <Input
                    maxLength={251}
                    placeholder='Digite o nome do Bairro'
                    onChange={onEntryChange('district')}
                  />,
                )}
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 15, offset: 1 }}
              style={{ marginBottom: '10px' }}
            >
              Logradouro
              <FormItem>
                {getFieldDecorator('street', {
                  rules: [
                    {
                      required: true,
                      message: 'Logradouro é obrigatória',
                    },
                    {
                      max: 250,
                      message:
                        'Campo Logradouro deve ter no máximo 250 caracteres',
                    },
                  ],
                })(
                  <Input
                    maxLength={251}
                    placeholder='Rua, Av., Tv.'
                    onChange={onEntryChange('street')}
                  />,
                )}
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 8 }}
              style={{ marginBottom: '10px' }}
            >
              Número
              <FormItem>
                {getFieldDecorator('addressNumber', {
                  rules: [
                    {
                      required: true,
                      message: 'Número é obrigatório',
                    },
                    {
                      max: 8,
                      message: 'Campo Número deve ter no máximo 8 caracteres',
                    },
                    {
                      validator: validateOnlyNumbers,
                      message: 'Campo deve conter apenas números.',
                    },
                  ],
                })(
                  <Input
                    maxLength={9}
                    placeholder='Digite o Número'
                    onChange={onEntryChange('addressNumber')}
                  />,
                )}
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 15, offset: 1 }}
              style={{ marginBottom: '10px' }}
            >
              Complemento
              <FormItem>
                {getFieldDecorator('complement', {
                  rules: [
                    {
                      max: 250,
                      message:
                        'Campo Complemento deve ter no máximo 25 caracteres',
                    },
                  ],
                })(
                  <Input
                    placeholder='Digite algum complemento'
                    maxLength={251}
                    onChange={onEntryChange('complement')}
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}

const WrappedSelection: SelectionProps = Form.create()(Selection);

export default WrappedSelection;
