// @flow
import React from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { Col } from 'antd';

import Images from '~/assets/images';
import { updateContractValues } from '~/store/contracts/actions';
import Loading from './Loading';
import {
  ContractNumber,
  CustomFormButton,
  FeedbackImg,
  FeedbackContent,
  StepFooter,
  StepTitle,
} from './styled';

type FeedBackProps = {
  id: number,
  cancel: () => any,
};

export default function FeedBack({ id, cancel }: FeedBackProps) {
  const updateContractControl = useAwaitControl(updateContractValues);
  const success = updateContractControl.isSuccessful(id);

  function SuccessTitle() {
    return (
      <StepTitle>
        Valores do contrato
        {' '}
        <ContractNumber>{`${id}`}</ContractNumber>
        , foram
        alterados com sucesso
      </StepTitle>
    );
  }

  function FailureTitle() {
    return (
      <StepTitle>
        Não foi possível alterar os valores do contrato
        {' '}
        <ContractNumber>{`${id}.`}</ContractNumber>
      </StepTitle>
    );
  }

  if (updateContractControl.isRunning(id)) {
    return <Loading />;
  }

  return (
    <div>
      {success ? <SuccessTitle /> : <FailureTitle />}
      <FeedbackContent>
        {success ? (
          <FeedbackImg src={Images.success} />
        ) : (
          <FeedbackImg src={Images.failure} />
        )}
      </FeedbackContent>
      <StepFooter>
        <Col>
          <CustomFormButton onClick={cancel} type="primary" size="large">
            Fechar
          </CustomFormButton>
        </Col>
      </StepFooter>
    </div>
  );
}
