import styled from 'styled-components';
import { UserPlus } from '~/ui/assets/icons';

export const StatisticsCardContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 10px 12px 10px 14px;
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-height: 70px;
  min-width: 250px;
  align-items: center;
  gap: 8px;
`;

export const IconArea = styled.div`
  box-sizing: border-box;
  display: flex;
  background-color: #ffe5f3;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
`;

export const TextArea = styled.div`
  flex-direction: column;
  border-radius: 4px;
  align-items: center;
`;
