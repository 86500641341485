import styled from 'styled-components';
import { Button, Typography } from '~/ui/components';
import barcodeImg from '~/ui/assets/imgs/barcode.png';
import { Icon } from 'antd';

type SelectedProps = {
  selected: boolean;
};

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const DocumentItem = styled.div<SelectedProps>`
  width: 88px;
  height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 1;
  }

  ${DocumentContainer}.selected & {
    border: 1px solid ${({ theme }) => theme.colors.success.main};
  }

  ${DocumentContainer}.selected:hover & {
    border: 1px solid ${({ theme }) => theme.colors.success.main};
    opacity: 0.7;
    cursor: default;
  }
`;

export const DocumentName = styled(Typography).attrs(({ selected, theme }) => ({
  type: 'caption',
  element: 'span',
  color: selected ? theme.colors.success.main : theme.colors.gray.main,
}))`
  &:nth-of-type(2) {
    display: none;
  }

  ${DocumentContainer}.selected & {
    color: ${({ theme }) => theme.colors.success.main};
  }

  ${DocumentContainer}.selected:hover & {
    color: ${({ theme }) => theme.colors.gray[500]};

    &:first-of-type {
      display: none;
    }

    &:nth-of-type(2) {
      display: block;
    }
  }
`;

type FileImageProps = {
  preview?: File;
};

export const FileImage = styled.img.attrs(({ preview }) => ({
  alt: 'document',
  src: preview || barcodeImg,
}))<FileImageProps>`
  width: ${({ preview }) => (preview ? '100%' : 'auto')};
`;

export const ActionButtons = styled.div`
  display: none;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  ${DocumentContainer}.selected:hover & {
    display: flex;
  }
`;

export const ActionButton = styled(Button).attrs({
  iconOnly: true,
  variant: 'text',
  size: 'sm',
  color: 'gray',
})`
  padding: 0;
  height: 20px;
  min-width: 20px;
  max-width: 20px;

  svg {
    fill: transparent;
    width: 16px;
    height: 16px;
  }

  &:hover {
    svg {
      fill: transparent;
    }
  }
`;

export const PDFIcon = styled(Icon).attrs({
  type: 'file-pdf',
})`
  font-size: 32px;

  svg {
    fill: ${({ theme }) => theme.colors.success.main};
  }
`;
