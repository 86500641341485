import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import theme from '~/themes/aphroditeTheme/theme';
import {
  Card,
  OldActions as MenuAction,
  DownloadHiscon,
  Spinner,
  PendenciesModal,
  Button,
  DownloadMedicalCertificate,
} from '~/components';

import withState from 'recompose/withState';

import { forceSendToQueue } from '~/store/contracts/actions';
import HisconActions from '~/components/HisconActions/HisconActions';
import ConcessionActions from '~/components/ConcessionActions/ConcessionActions';
import Tags from '~/components/Tags';
import UpdateBankAccount from '~/screens/components/UpdateBankAccount';
import MenuList from './components/MenuList';
import GenerateDEDAction from './components/GenerateDEDAction';
import { ValidateKYCStep } from './components/ValidateKYCStep/ValidateKYCStep';
import { DownloadAuditTrail } from './components/DownloadAuditTrail/DownloadAuditTrail';
import { DownloadPortabilityTerm } from './components/DownloadPortabilityTerm/DownloadPortabilityTerm';
import {
  onPressCheckedContract,
  onRequestAgreementPassword,
  onPressUncheckedContract,
  onPressConcludeContractSignature,
  onPressLinkContractOwner,
  onPressUpdateContractValues,
  onPressDispatchRequestNewContractBankAccount,
  onPressRequestAddress,
  onPressDispatchRequestNewImage,
  onPressRequestNewSignature,
  onPressReserveMargin,
  onPressReverseCancel,
  onPressUpdateConsigneeStatus,
  mapStateToProps,
  receiveChanges,
  propagateStateChangeToProps,
  onPressSendBiometry,
  onPressRevertCetelem,
  onPressRequestPendencyData,
} from './Actions.Container';
import {
  CancelContractSignature,
  ConfirmRequestRefusal,
  Payment,
  RequestRefusal,
  SendCCBForUserSignature,
  SendContract,
  ReverseCancel,
  styles,
} from './components';

const { css, withStyles } = theme;

export function Action(props) {
  const { onPress, title } = props;

  return <MenuAction title={title} onPress={onPress} />;
}

class Actions extends Component {
  state = {};

  shouldDisableActions = () => {
    const { contractDetails, isConferenceByPersonEnabled } = this.props;
    return (
      isConferenceByPersonEnabled && contractDetails.isGroupContract && !contractDetails.isGroupLead
    );
  };

  onSendPendency = ({ pendencieType, reason, reasonType, pendingReasonId, reasonDetailsId }) => {
    const {
      onPressRequestAddress,
      onRequestAgreementPassword,
      onPressDispatchRequestNewImage,
      onPressDispatchRequestNewContractBankAccount,
      onPressRequestPendencyData: onPressRequestPendency,
    } = this.props;

    const pendenciesActions = {
      FACEBIOMETRY: onPressDispatchRequestNewImage,
      AGREEMENT_PASSWORD: onRequestAgreementPassword,
      BANK_ACCOUNT: onPressDispatchRequestNewContractBankAccount,
      DOCUMENTFRONT: onPressDispatchRequestNewImage,
      PAYCHECK: onPressDispatchRequestNewImage,
      PROOFOFADDRESS: onPressDispatchRequestNewImage,
      ADDRESS: onPressRequestAddress,
    };

    (pendenciesActions[pendencieType] || onPressRequestPendency)(
      reason,
      pendencieType,
      reasonType,
      pendingReasonId,
      reasonDetailsId,
    );
  };

  renderConcludeContractSignature = () => {
    const { contractDetails, onPressConcludeContractSignature } = this.props;
    if (contractDetails.canConcludeCCBSignature) {
      return (
        <MenuAction
          title="Concluir Assinatura do Contrato"
          onPress={onPressConcludeContractSignature}
          popConfirmProps={{
            title: 'Você realmente deseja concluir a assinatura do contrato?',
          }}
        />
      );
    }
  };

  renderLinkContractOwner = () => {
    const { onPressLinkContractOwner, showLinkContractOwner, styles } = this.props;
    if (showLinkContractOwner) {
      return (
        <MenuAction
          onPress={onPressLinkContractOwner}
          title="Associar-se a este contrato"
          style={css(styles.action)}
        />
      );
    }
  };

  renderUpdateContractValues = () => {
    const { onPressUpdateContractValues, styles } = this.props;

    return (
      <MenuAction
        onPress={onPressUpdateContractValues}
        title="Atualizar Dados do Contrato"
        style={css(styles.action)}
      />
    );
  };

  renderUpdateConsigneeStatus = () => {
    const { onPressUpdateConsigneeStatus, styles } = this.props;

    return (
      <MenuAction
        onPress={onPressUpdateConsigneeStatus}
        title="Atualizar Status da Consignatária"
        style={css(styles.action)}
      />
    );
  };

  renderReverseCancel = () => {
    const { onPressReverseCancel } = this.props;
    return <ReverseCancel onPress={onPressReverseCancel} />;
  };

  renderMedicalCertificate = () => {
    const { id, agreementName } = this.props.contractDetails.person || {};

    if (agreementName !== 'INSS') return null;

    return (
      <MenuAction
        icon="diff"
        title="Perícia Médica"
        subActions={[<DownloadMedicalCertificate personId={id} agreementName={agreementName} />]}
      />
    );
  };

  renderRenderStartConference = (id) => {
    const {
      conferenceData: { inConference, contractQueue },
      contractDetails,
    } = this.props;

    if (inConference || !contractQueue || contractDetails?.contractStatusType === 'CANCELLED')
      return null;

    return (
      <Button
        title="Iniciar Conferência"
        type="default"
        size="large"
        style={{ width: '100%', marginTop: '16px' }}
        onPress={() => this.props.dispatch(forceSendToQueue.start({ id }))}
      />
    );
  };

  render() {
    const {
      contractDetails: details,
      isCancelled,
      isLoading,
      match,
      hisconAutoUpdateFailure,
      kycValidation,
    } = this.props;
    const { id } = match.params;

    const agreementName = details.person ? details.person.agreementName : null;
    const personId = details.person ? details.person.id : null;
    const lastRequestHisconUpdate = details.person ? details.person.lastRequestHisconUpdate : null;

    if (isCancelled) {
      return (
        <div style={{ marginLeft: 16 }}>
          <Card style={{ marginBottom: 20 }}>
            <Tags externalId={id} type="CONTRACT" />
          </Card>
          <Card title="Ações" hiddenOverflow>
            <DownloadHiscon personId={personId} agreementName={agreementName} />
            {this.renderReverseCancel()}
          </Card>
          {this.renderRenderStartConference(id)}
        </div>
      );
    }

    return (
      <div style={{ marginLeft: 16 }}>
        <Payment />
        <Card style={{ marginBottom: 20 }}>
          <Tags externalId={id} type="CONTRACT" />
        </Card>
        <Card title="Ações" hiddenOverflow disabled={this.shouldDisableActions()}>
          <Spinner spinning={isLoading} />

          {/* Reenviar para pagamento */}
          <UpdateBankAccount kycValidation={kycValidation} />

          {/* Hiscon */}
          <HisconActions
            disableUpload
            personId={personId}
            agreementName={agreementName}
            lastRequestHisconUpdate={lastRequestHisconUpdate}
            hisconAutoUpdateFailure={hisconAutoUpdateFailure}
            contractDetails={details}
          />

          {/* Perícia Médica */}
          {this.renderMedicalCertificate()}

          {/* Carta de concessão */}
          <ConcessionActions disableUpload personId={personId} agreementName={agreementName} />

          {/* Criar Bojo Digital */}
          <SendCCBForUserSignature kycValidation={kycValidation} />

          {/* Concluir Assinatura do Contrato */}
          {this.renderConcludeContractSignature()}

          {/* Enviar para Consignataria */}
          <SendContract kycValidation={kycValidation} />

          {/* Associar-se a este contrato */}
          {this.renderLinkContractOwner()}

          {/* Atualizar Dados do Contrato */}
          {this.renderUpdateContractValues()}

          {/* Atualizar Status da Consignatária */}
          {this.renderUpdateConsigneeStatus()}

          {/* Ajustar contrato de refin */}
          <MenuList externalId={id} contract={details} kycValidation={kycValidation} />

          {/* Solicitar Pendência */}
          <PendenciesModal
            pendingReasons={this.props.pendingReasons}
            onSendPendencie={this.onSendPendency}
          />

          {/* Download DED */}
          <GenerateDEDAction contractId={id} contract={details} kycValidation={kycValidation} />

          {/* Invalidar Bojo Digital */}
          <CancelContractSignature kycValidation={kycValidation} />

          {/* Baixar trilha de auditoria */}
          <DownloadAuditTrail contractId={id} details={details} />

          {/* Baixar trilha de auditoria */}
          <DownloadPortabilityTerm contractId={id} details={details} />
        </Card>

        {!this.shouldDisableActions() && (
          <div>
            {/* Iniciar Conferência */}
            {this.renderRenderStartConference(id)}

            {/* Cancelar contrato */}
            <RequestRefusal />

            {/* Solicitar Cancelamento */}
            <ConfirmRequestRefusal />
          </div>
        )}
      </div>
    );
  }
}

Actions.defaultProps = {
  showLinkContractOwner: false,
};

export default compose(
  withRouter,
  withState('isLoadedContractDetails', 'setIsLoadedContractDetails', false),
  withState('isLoading', 'setIsLoading', false),
  withStyles(styles),
  connect(mapStateToProps),
  withHandlers({
    onPressCheckedContract,
    onRequestAgreementPassword,
    onPressUncheckedContract,
    onPressRequestNewSignature,
    onPressLinkContractOwner,
    onPressUpdateContractValues,
    onPressDispatchRequestNewContractBankAccount,
    onPressDispatchRequestNewImage,
    onPressConcludeContractSignature,
    onPressReserveMargin,
    onPressReverseCancel,
    onPressUpdateConsigneeStatus,
    onPressRequestAddress,
    onPressRequestPendencyData,
    onPressSendBiometry,
    onPressRevertCetelem,
  }),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
)(Actions);
