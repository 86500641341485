import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import colors from '~/ui/theme/colors';
import { Button, TableColumns } from '~/ui/components';
import { useModal } from '~/hooks/useModal';

import { listActionProposals } from '~/store/proposals/actions';
import { MtProposalAction } from '~/typings/entities/Proposals';
import { SmallTable } from '../Returns.styled';
import { History } from './History';

export type ActionsProps = {
  proposalId: number;
};

export function Actions({ proposalId }: ActionsProps) {
  const { openModal } = useModal();
  const listActionProposalsControl = useAwaitControl(listActionProposals);

  const actions = listActionProposalsControl.result(proposalId);
  const actionsLoading = listActionProposalsControl.isRunning(proposalId);

  const handleHistoryClick = (id) => () => {
    openModal(<History actionProposalId={id} />, {
      title: 'Histórico do retorno',
      width: 'auto',
    });
  };

  const columns: TableColumns<MtProposalAction> = [
    { accessorKey: 'id', header: () => 'ID' },
    { accessorKey: 'actionType', header: () => 'TIPO' },
    { accessorKey: 'actionStatus', header: () => 'STATUS' },
    {
      accessorKey: 'creationDate',
      header: () => 'DATA',
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
    },
    {
      id: 'actions',
      header: () => 'HISTÓRICO',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <Button
          iconOnly
          icon="FileSearch"
          variant="text"
          customColor={colors.primary.main}
          onClick={handleHistoryClick(info.row.original.id)}
          tooltip="Ver histórico"
          size="sm"
          tooltipPlacement="bottom"
        />
      ),
    },
  ];

  useEffect(() => {
    if (proposalId && (!actions || !actions.length)) {
      listActionProposalsControl.start({ proposalId }, { actionId: proposalId });
    }
  }, [proposalId]);

  return (
    <SmallTable
      noItemsMessage="Nenhum retorno encontrado"
      loading={actionsLoading}
      data={actions}
      columns={columns}
      rowHeight={64}
    />
  );
}
