import Immutable from 'immutable';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { isEmpty, mapObject, pick } from 'underscore';
import { X } from 'react-feather';
import theme from '~/themes/aphroditeTheme/theme';
import masked from '~/common/masked';

import { message } from '~/common';
import moment from 'moment';

import { Button, Input, Form, Row, Col } from 'antd';

import { getContractDetails } from '~/store/contracts/actions';

import sendCCBForUserSignature, {
  SEND_CCB_FOR_USER_SIGNATURE_REQUEST,
  SEND_CCB_FOR_USER_SIGNATURE_FAILURE,
  SEND_CCB_FOR_USER_SIGNATURE_SUCCESS,
} from '~/store/sendCCBForUserSignature/action';

import { Spinner } from '~/components';
import { Action } from '../Actions';
import common from './styles';

const { css, withStyles } = theme;

const { Item: FormItem } = Form;
const { toMoneyForm } = masked;

type Props = {
  details: any,
  form: any,
  setIsModalVisible: any,
  styles: any,
  isModalVisible: any,
  isLoadedContractDetails: any,
  isLoading: any,
  onPressClose: any,
};

class SendCCBForUserSignature extends Component<Props> {
  state = {
    grossValue: 0,
    netValue: 0,
    valueForDeposit: 0,
    installmentValue: 0,
    numberOfInstallments: 0,
    paymentDate: moment().utc(),
  };

  getCurrentValue = (field) => {
    const { details } = this.props;

    return details[`${field}Approved`]
      ? details[`${field}Approved`].toFixed(2)
      : details[`${field}`]?.toFixed(2);
  };

  onPressOpen = () => {
    const { details } = this.props;

    this.setState(
      {
        grossValue: this.getCurrentValue('grossValue'),
        netValue: this.getCurrentValue('netValue'),
        valueForDeposit: this.getCurrentValue('valueForDeposit'),
        installmentValue: this.getCurrentValue('installmentValue'),
        numberOfInstallments: parseInt(details.numberOfInstallments),
        paymentDate: moment().utc(),
      },
      () =>
        this.props.form.setFieldsValue({
          grossValue: this.state.grossValue,
          netValue: this.state.netValue,
          valueForDeposit: this.state.valueForDeposit,
          installmentValue: this.state.installmentValue,
          numberOfInstallments: this.state.numberOfInstallments,
          paymentDate: this.state.paymentDate,
        }),
    );

    this.props.setIsModalVisible(true);
  };

  onSubmit = (e) => {
    const { dispatch, form, match } = this.props;
    const { id } = match.params;
    const valuesToSend = this.getValuesToSend();

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        dispatch(sendCCBForUserSignature(id, valuesToSend));
      }
    });
  };

  getValuesToSend = () =>
    pick(
      this.state,
      ...[
        'grossValue',
        'installmentValue',
        'netValue',
        'numberOfInstallments',
        'valueForDeposit',
        'paymentDate',
      ],
    );

  onChange = (value) => {
    this.setState(value);
  };

  renderForm() {
    const { onChange } = this;
    const { styles } = this.props;

    const { getFieldDecorator } = this.props.form;

    const { grossValue, netValue, valueForDeposit, installmentValue, numberOfInstallments } =
      this.props.details;

    return (
      <Form>
        <Row>
          <Col xs={{ span: 7 }}>
            <span>Valor Bruto</span>
            <FormItem>
              {getFieldDecorator('grossValue', {
                rules: [
                  {
                    required: true,
                    message: 'Digite o valor bruto',
                  },
                ],
                normalize: toMoneyForm,
              })(
                <Input
                  addonBefore="R$"
                  onChange={(e) => this.onChange({ grossValue: e.target.value })}
                  style={css(styles.input)}
                  value={grossValue}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 8, offset: 1 }}>
            <span>Valor Líquido</span>
            <FormItem>
              {getFieldDecorator('netValue', {
                rules: [
                  {
                    required: true,
                    message: 'Digite o valor líquido',
                  },
                ],
                normalize: toMoneyForm,
              })(
                <Input
                  addonBefore="R$"
                  onChange={(e) => this.onChange({ netValue: e.target.value })}
                  style={css(styles.input)}
                  value={netValue}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 7, offset: 1 }}>
            <span>Valor para Depósito</span>
            <FormItem>
              {getFieldDecorator('valueForDeposit', {
                rules: [
                  {
                    required: true,
                    message: 'Digite o valor para depósito',
                  },
                ],
                normalize: toMoneyForm,
              })(
                <Input
                  addonBefore="R$"
                  onChange={(e) => this.onChange({ valueForDeposit: e.target.value })}
                  style={css(styles.input)}
                  value={valueForDeposit}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 7 }}>
            <span>Valor da Parcela</span>
            <FormItem>
              {getFieldDecorator('installmentValue', {
                rules: [
                  {
                    required: true,
                    message: 'Digite o valor de parcela',
                  },
                ],
                normalize: toMoneyForm,
              })(
                <Input
                  addonBefore="R$"
                  onChange={(e) => this.onChange({ installmentValue: e.target.value })}
                  style={css(styles.input)}
                  value={installmentValue}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 8, offset: 1 }}>
            <span>Número de Parcelas</span>
            <FormItem>
              {getFieldDecorator(
                'numberOfInstallments',
                {},
              )(
                <Input
                  readOnly
                  onChange={(e) => onChange({ numberOfInstallments: e.target.value })}
                  style={css(styles.input)}
                  value={numberOfInstallments}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { details, styles, isModalVisible, isLoadedContractDetails } = this.props;

    if (!isLoadedContractDetails || !details.canSendCCBForUserSignature) return null;

    const style = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s',
        background: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1000,
      },
    };

    return [
      <Action
        key="button"
        onPress={this.onPressOpen}
        style={css(styles.action)}
        title="Criar Bojo Digital"
      />,
      <Modal
        ariaHideApp={false}
        contentLabel="SendCCBForUserSignature"
        isOpen={isModalVisible}
        key="modal"
        style={style}
        {...css(styles.modal)}
      >
        <Spinner spinning={this.props.isLoading} />

        <div {...css(styles.modal__header)}>
          <span {...css(styles.modal__title)}>Condições finais do contrato:</span>
          <button type="button" onClick={this.props.onPressClose} {...css(styles.modal__close)}>
            <X {...css(styles.closeButton)} size={20} />
          </button>
        </div>
        <div {...css(styles.modal__content)}>{this.renderForm()}</div>
        <Button type="primary" {...css(styles.buttonConfirm)} onClick={this.onSubmit}>
          Criar
        </Button>
      </Modal>,
    ];
  }
}

function onPressClose({ setIsModalVisible }) {
  setIsModalVisible(false);
}

export function mapStateToProps(state) {
  return {
    sendCCBForUserSignature: state.getIn(['sendCCBForUserSignature']),
    details: getContractDetails.getResult()(state),
    detailsSuccess: getContractDetails.isSuccessful()(state),
    detailsFailure: getContractDetails.hasFailure()(state),
  };
}

export function receiveChanges(
  prevProps,
  {
    setIsModalVisible,
    detailsSuccess,
    detailsFailure,
    dispatch,
    match,
    sendCCBForUserSignature,
    setIsLoading,
    setIsLoadedContractDetails,
  },
) {
  if (Immutable.is(prevProps.detailsSuccess, detailsSuccess) === false) {
    if (detailsSuccess) {
      setIsLoadedContractDetails(true);
      return true;
    }
    return false;
  }

  if (Immutable.is(prevProps.detailsFailure, detailsFailure) === false) {
    if (detailsFailure) {
      return true;
    }
    return false;
  }

  if (Immutable.is(prevProps.sendCCBForUserSignature, sendCCBForUserSignature) === false) {
    switch (sendCCBForUserSignature.getIn(['type'])) {
      case SEND_CCB_FOR_USER_SIGNATURE_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case SEND_CCB_FOR_USER_SIGNATURE_SUCCESS: {
        message.success('CCB Enviado Para Assinatura do Usuário com Sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails.start({ id }));

        setIsLoading(false);
        setIsModalVisible(false);
        return true;
      }
      case SEND_CCB_FOR_USER_SIGNATURE_FAILURE: {
        message.error('Falha ao Enviar CCB Para Assinatura do Usuário.');
        setIsLoading(false);
        setIsModalVisible(false);
        return true;
      }
      default:
        return false;
    }
  }
  return false;
}

export function propagateStateChangeToProps({ details }) {
  return mapObject(
    {
      details,
    },
    (state) => state,
  );
}

const styles = (props) => ({
  ...common(props),
  modal: {
    ...common(props).modal,
    width: 600,
  },
});

const WrappedPayment = Form.create()(SendCCBForUserSignature);

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  withState('isLoading', 'setIsLoading', false),
  withState('isModalVisible', 'setIsModalVisible', false),
  withState('isLoadedContractDetails', 'setIsLoadedContractDetails', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onPressClose,
  }),
)(WrappedPayment);
