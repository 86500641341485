import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Loading } from '~/ui/assets/icons';
import { LoadingWrapper, Object } from './PreviewModal.styled';

type PdfPreviewProps = {
  url?: string;
  file?: string | ArrayBuffer | null;
};

export function PdfPreview({ url, file }: PdfPreviewProps) {
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState(null);
  const reader = new FileReader();

  const changeMimeType = (base64String, newMimeType) =>
    base64String.replace(/^data:[^;]+/, `data:${newMimeType}`);

  const urlToBlob = async (url) => {
    if (!url) return;

    const { data } = await axios.get(url, {
      responseType: 'blob',
    });

    reader.readAsDataURL(data);
    reader.onloadend = () => {
      const base64data = changeMimeType(reader.result, 'application/pdf');
      setPreview(base64data);
      setLoading(false);
    };
  };

  useEffect(() => {
    if (file) {
      setPreview(file);
      setLoading(false);
    } else {
      urlToBlob(url);
    }

    return () => {
      reader.abort();
    };
  }, [url, file]);

  if (loading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return <Object title="pdf" type="application/pdf" data={preview} />;
}
