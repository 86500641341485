type Config = {
  value?: string;
  label?: string;
};

export function createSelectValues(list, targetId, config?: Config) {
  const keyId = config?.value || 'id';
  let result = [];

  list?.forEach((item) => {
    if (item.children && item.children.length > 0) {
      const childIds = item.children.map((child) => child[keyId]);
      if (childIds.includes(targetId)) {
        result = [item[keyId], targetId];
      }
    } else if (item[keyId] === targetId) {
      result = [targetId];
    }
  });

  return result;
}

export function findNamesByIds(list, ids, config?: Config) {
  const keyValue = config?.value || 'id';
  const keyLabel = config?.label || 'name';
  const result = [];

  function traverse(node) {
    if (ids.includes(node[keyValue])) {
      result.push(node[keyLabel]);
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        traverse(child);
      });
    }
  }

  list.forEach((item) => traverse(item));

  return result;
}

export function findNameById(list, id, config?: Config) {
  const ids = createSelectValues(list, id, config);
  const names = findNamesByIds(list, ids, config);
  return names.join(' - ');
}

export function findLastNameById(list, id, config?: Config) {
  const names = findNamesByIds(list, [id], config);
  return names?.[names.length - 1];
}
