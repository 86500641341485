import styled, { css } from 'styled-components';

import theme from '~/ui/theme';
import { themeColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 200px;
`;

export const SelectInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${themeColor('background.secondary', { dark: 'background.primary' })};
  height: 40px;
  padding-inline: 1rem;
  border-radius: 2rem;
  cursor: pointer;

  .caret {
    transition: all 200ms;
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  }

  ${({ isSelectOpen }) =>
    isSelectOpen &&
    css`
      .caret {
        transform: rotate(90deg);
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 20px 0 5px;
  user-select: none;
  color: ${({ theme }) => theme.colors.element.placeholder};

  svg {
    // color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    color: ${themeColor('element.placeholder')};
  }
`;

export const Label = styled(Typography).attrs({
  type: 'caption',
  element: 'label',
})`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.element.placeholder};
`;

export const Counter = styled.div`
  display: flex;
  background-color: #d80073;
  color: #ffff;
  width: 24px;
  height: 24px;
  border-radius: 2rem;
  align-items: center;
  justify-content: center;
`;

export const FilterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  color: #333;
  width: max-content;
  min-width: 250px;
  padding: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  margin-top: 45px;
  z-index: 3;
  box-shadow: 3px 3px 8px rgb(0 0 0 / 15%);
  right: ${({ filterDirection }) => (filterDirection === 'right' ? 0 : undefined)};
`;

export const ListContainer = styled.div`
  max-height: 210px;
  overflow-y: auto;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.colors.state.hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.state.active};
  }

  input[type='checkbox']:checked::before {
    content: url("data:image/svg+xml;utf8,<svg width='10' height='10' viewBox='0 0 16 15' fill='white' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M15.2967 0.548607C15.7669 0.933307 15.8362 1.62633 15.4515 2.09652L5.55147 14.1965C5.33739 14.4582 5.01489 14.6069 4.67689 14.5997C4.3389 14.5926 4.02296 14.4304 3.82011 14.16L0.520114 9.75996C0.155606 9.27395 0.254103 8.58447 0.740114 8.21996C1.22612 7.85545 1.91561 7.95395 2.28011 8.43996L4.73777 11.7168L13.7488 0.703398C14.1335 0.233209 14.8265 0.163907 15.2967 0.548607Z'/></svg>");
  }

  .typography {
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  }
`;

export const OptionLabel = styled.label`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding-inline: 0.5rem;
  padding-block: 0.3rem;
  background-color: #f5f5f5;
  cursor: pointer;

  input[type='checkbox']:checked::before {
    content: url("data:image/svg+xml;utf8,<svg width='10' height='10' viewBox='0 0 16 15' fill='white' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M15.2967 0.548607C15.7669 0.933307 15.8362 1.62633 15.4515 2.09652L5.55147 14.1965C5.33739 14.4582 5.01489 14.6069 4.67689 14.5997C4.3389 14.5926 4.02296 14.4304 3.82011 14.16L0.520114 9.75996C0.155606 9.27395 0.254103 8.58447 0.740114 8.21996C1.22612 7.85545 1.91561 7.95395 2.28011 8.43996L4.73777 11.7168L13.7488 0.703398C14.1335 0.233209 14.8265 0.163907 15.2967 0.548607Z'/></svg>");
  }

  span {
    font-size: 12px;
    font-weight: 500;
    font-family: ${theme.typography.fonts.libreFranklin};
  }
`;

export const CheckBox = styled.input`
  appearance: none;
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid #cfcfcf;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }

  &:checked {
    background-color: #d80073;
    border: #d80073;
  }
`;

export const SearchBarContainer = styled.div`
  margin-bottom: 12px;
`;
