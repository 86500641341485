/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { mapObject } from 'underscore';
import moment from 'moment';

import { PAY_CONTRACT_REQUEST, success, failure } from './action';

const prepare = payload => {
  const { contractId } = payload;
  const values = mapObject(payload.values, (value, key) => {
    if (key === 'commissioningId') return value;
    if (key === 'paymentDate') return moment(value, 'DD/MM/YYYY');
  });
  return {
    values,
    contractId,
  };
};

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(PAY_CONTRACT_REQUEST)
    .map(({ payload }) => prepare(payload))
    .mergeMap(({ contractId, values }) =>
      AjaxRequest.open(
        'post',
        `/admin/contractcustom/contracts/${contractId}/pay`,
        values,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
