import { all, takeEvery, put } from 'redux-saga/effects';

import { logout } from '~/store/user/actions';

import { authenticationSaga } from '~/store/user/sagas';
import { contractsSaga } from '~/store/contracts/sagas';
import { peopleSaga } from '~/store/people/sagas';
import { agreementsSaga } from '~/store/agreements/sagas';
import { tagTypesSaga } from '~/store/tagTypes/sagas';
import { in100Saga } from '~/store/in100/sagas';
import { offersSaga } from '~/store/offers/sagas';
import { logsSaga } from '~/store/logs/sagas';
import { utilitiesSaga } from '~/store/utilities/sagas';
import { pendenciesSaga } from '~/store/pendencies/sagas';
import { clixSaga } from '~/store/clix/sagas';
import { tagsSaga } from '~/store/tags/sagas';
import { proposalsSaga } from '~/store/proposals/sagas';
import { accesByCustomerSaga } from '~/store/accessByCustomer/sagas';
import { ticketSaga } from '~/store/tickets/sagas';
import { permLevelsSaga } from '~/store/permLevels/sagas';
import { notificationSaga } from '../sendPushNotification/sagas';
import { hisconSaga } from '../getHiscon/sagas';
import { hisconUpdateSaga } from '../requestHisconAutoUpdate/sagas';
import { getConcessionSaga } from '../getConcession/sagas';
import { getMedicalCertificateSaga } from '../getMedicalCertificate/sagas';
import { filesSaga } from '../files/sagas';
import { walletSaga } from '../wallet/sagas';
import { opportunitiesSaga } from '../opportunities/sagas';
import { enrollmentsSaga } from '../enrollments/sagas';
import { formalizationSaga } from '../formalization/sagas';

function* requestErrorWatch({ meta }: any) {
  const { statusCode } = meta || {};

  if (statusCode === 401 && window.location.pathname !== '/login') {
    yield put(logout.start());
  }
}

function* requestWatch(): Generator {
  yield takeEvery((action) => /_FAILURE$/.test(action.type), requestErrorWatch);
}

export default function* root(): Generator {
  yield all([
    authenticationSaga(),
    contractsSaga(),
    peopleSaga(),
    agreementsSaga(),
    tagTypesSaga(),
    in100Saga(),
    offersSaga(),
    logsSaga(),
    utilitiesSaga(),
    pendenciesSaga(),
    clixSaga(),
    tagsSaga(),
    proposalsSaga(),
    accesByCustomerSaga(),
    notificationSaga(),
    hisconSaga(),
    hisconUpdateSaga(),
    getConcessionSaga(),
    getMedicalCertificateSaga(),
    filesSaga(),
    ticketSaga(),
    permLevelsSaga(),
    walletSaga(),
    opportunitiesSaga(),
    requestWatch(),
    enrollmentsSaga(),
    formalizationSaga(),
  ]);
}
