import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import { listQiActionHistoric } from '~/store/proposals/actions';
import { TableColumns } from '~/ui/components';

import { QiProposalHistory } from '~/typings/entities/Proposals';
import { SmallTable } from '../Returns.styled';

export type HistoryProps = {
  qiActionProposalId: string;
};

export function History({ qiActionProposalId }: HistoryProps) {
  const listQiActionHistoricControl = useAwaitControl(listQiActionHistoric);

  const historyLoading = listQiActionHistoricControl.isRunning(qiActionProposalId);
  const history = listQiActionHistoricControl.result(qiActionProposalId);

  const columns: TableColumns<QiProposalHistory> = [
    { accessorKey: 'id', header: () => 'ID' },
    { accessorKey: 'qiProposalId', header: () => 'ID PROPOSTA' },
    { accessorKey: 'qiProposalActionHistoryId', header: () => 'ID HISTÓRICO' },
    { accessorKey: 'actionType', header: () => 'TIPO' },
    { accessorKey: 'actionStatus', header: () => 'STATUS' },
    {
      accessorKey: 'creationDate',
      header: () => 'DATA',
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
    },
  ];

  useEffect(() => {
    if (qiActionProposalId && (!history || !history.length)) {
      listQiActionHistoricControl.start({ qiActionProposalId }, { actionId: qiActionProposalId });
    }
  }, [qiActionProposalId]);

  return (
    <SmallTable
      noItemsMessage="Nenhum retorno encontrado"
      loading={historyLoading}
      data={history}
      columns={columns}
      rowHeight={64}
    />
  );
}
