import React from 'react';
import { OldActions } from '~/components';

class SendBiometry extends React.Component {
  onClicked = () => {
    this.input.click();
  };

  onFileOk = (event) => {
    const file = event.target.files[0];
    this.input.value = null;
    this.props.onSendFile(file);
  };

  render() {
    const { title, accept } = this.props;
    return [
      <OldActions title={title} onPress={this.onClicked} />,
      <input
        ref={(ref) => (this.input = ref)}
        type="file"
        accept={accept || 'image/*'}
        onChange={this.onFileOk}
      />,
    ];
  }
}

export default SendBiometry;
