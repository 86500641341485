import styled from 'styled-components';
import { Icon, Table as AntTable } from 'antd';

export const TableStyled = styled(AntTable)`
  .ant-table table {
    border-spacing: 0 7px;
  }

  .ant-table-thead > tr > th {
    font-weight: 500;
    border: 0;
    border-collapse: initial;
    background: transparent;
    padding: 0 16px;
    line-height: 0;
    text-transform: uppercase;
  }

  .ant-table-thead .ant-table-column-sorters {
    margin-bottom: 1em;
  }

  .ant-table-column-sorters .ant-table-column-sorter {
    font-size: 20px;
  }

  .ant-table-thead th.ant-table-column-sort {
    background: transparent !important;
  }

  .ant-table-thead .ant-table-column-sorters:hover::before {
    background: transparent !important;
    border-bottom: ${props => props.theme.primaryColor} solid 1.5px;
  }

  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: transparent !important;
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: ${props => props.theme.primaryColorLightHover} !important;
  }

  .ant-table-tbody > tr {
    border-radius: 5px;
    box-shadow: 0px 0px 4px ${props => props.theme.primaryColorLightest};
    transition: all 1s;
  }

  .ant-table-tbody > tr > td {
    border: 0;
    padding: 5px 16px;
    background-color: #fff;
  }

  .ant-table-tbody .ant-table-row:hover td {
    background: ${props => props.theme.primaryColorLightHover} !important;
  }

  .ant-table-tbody > tr > :first-child {
    border-radius: ${props => props.borderRadius && '5px 0 0 5px'};
  }

  .ant-table-tbody > tr > :last-child {
    border-radius: ${props => props.borderRadius && '0 5px 5px 0'};
  }

  .ant-table-pagination.ant-pagination {
    margin: ${props => !props.borderRadius && '12px'};
  }

  .ant-table-tbody > .ant-table-expanded-row :last-child {
    text-align: left;
  }
`;

export const IconStyled = styled(Icon)`
  padding: 5px 3px;

  :hover {
    border-radius: 100%;
    background: ${props => props.theme.primaryColorLightest} !important;
    color: ${props => props.theme.primaryColor};
  }

  svg {
    width: 12px;
    height: 10px;
  }
`;
