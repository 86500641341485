import React from 'react';
import moment from 'moment';
import { InfoCircle2, Label as LabelIcon } from '~/ui/assets/icons';
import { TransactionType } from '~/typings/enums/Clix';
import { Transaction } from '~/typings/entities/Clix';
import { Money } from '~/common';
import { useDrawer } from '~/hooks/useDrawer';

import { TransactionDetails } from '../../Drawers';

import {
  GroupTransaction,
  TransactionDate,
  TransactionInfo,
  InfoRow,
  TransactionRow,
  Time,
  Title,
  RecipientName,
  InstallmentTag,
  TransactionButton,
  CurrentInstallmentNumber,
} from './GroupedTransactions.styled';

interface GroupedTransactionsProps {
  transactions: Transaction[];
  date: string;
}

export function GroupedTransactions({ transactions, date }: GroupedTransactionsProps) {
  const { openDrawer } = useDrawer();

  const handleTransactionClick = (id: string) => {
    openDrawer('transactionDetails', <TransactionDetails id={id} />, { width: 450 });
  };

  return (
    <GroupTransaction>
      <TransactionDate>{moment(date).format('DD MMM YYYY')}</TransactionDate>

      {transactions?.map((transaction) => (
        <TransactionRow key={transaction.id}>
          <TransactionInfo>
            <InfoRow>
              <LabelIcon />
              <Title>{TransactionType[transaction?.type]}</Title>
              <Time>às {moment(transaction?.dateProcessing).format('HH:mm')}</Time>
            </InfoRow>

            <RecipientName weight={400}>{transaction?.recipientName}</RecipientName>

            {!!transaction?.installment && (
              <InfoRow>
                <InstallmentTag>
                  <InfoCircle2 /> Parcelado em {transaction?.installment?.installmentsNumber}x
                </InstallmentTag>

                <div>
                  <CurrentInstallmentNumber>
                    {transaction?.installment.currentInstallment}X
                  </CurrentInstallmentNumber>{' '}
                  <b>{Money(transaction?.installment.installmentValue)}</b>
                </div>
              </InfoRow>
            )}

            <InfoRow>
              Crédito enviado
              <b>{Money(transaction?.principalValue)}</b>
            </InfoRow>

            <InfoRow>
              Crédito utilizado
              <b>{Money(transaction?.totalValue)}</b>
            </InfoRow>

            <InfoRow>
              Exibir detalhes{' '}
              <TransactionButton onClick={() => handleTransactionClick(transaction?.id)} />
            </InfoRow>
          </TransactionInfo>

          {/* <TransactionButton onClick={() => handleTransactionClick(transaction?.id)}> */}
          {/*  <ArrowRight /> */}
          {/* </TransactionButton> */}
        </TransactionRow>
      ))}
    </GroupTransaction>
  );
}
