import { createAsyncAction } from 'react-redux-await-control';

import { telli } from '~/services';
import { message } from 'antd';

export const listAgreements = createAsyncAction('LIST_AGREEMENTS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: () => telli.get('/admin/agreements'),
  },
});

export const eraseAgreementPassword = createAsyncAction('INVALIDATE_AGREEMENT_PASSWORD', {
  context: {
    request: ({ id }) => telli.get(`/admin/personcustom/people/${id}/eraseagreementpassword`),
    onRequestError: () => message.error('Erro ao tentar invalidar senha do convênio'),
    onRequestSuccess: () => message.success('Senha do convênio invalidada com sucesso'),
  },
});
