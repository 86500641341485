// @flow

import React from 'react';
import { StepContainer } from './styled';

export type StepProps = {
  title: string,
  onClick: () => void,
  active?: boolean,
  done?: boolean,
  key: string,
};

function Step(props: StepProps) {
  return (
    <StepContainer active={props.active} done={props.done}>
      <span>{props.title}</span>
    </StepContainer>
  );
}

export default Step;
