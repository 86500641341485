import React, { useMemo } from 'react';

import { OldActions as MenuAction } from '~/components';
import LOAN_TYPES from '~/constants/LoanTypes';
import useModals from '~/hooks/useModals';
import useUserRoles from '~/hooks/useUserRoles';
import RefinAdjustment from './RefinAdjustment/RefinAdjustment';

export default function MenuList({ contract, externalId, kycValidation }) {
  const { openModal } = useModals();

  const { loanType } = contract;

  const { roles } = useUserRoles();

  const items = useMemo(
    () => [
      {
        title: 'Ajustar contrato de refin',
        action: openModal(RefinAdjustment.alias, { contract, externalId }),
        filters: {
          loanTypes: [LOAN_TYPES.PORTABILITY],
          userLevels: ['ADMINISTRATOR'],
        },
      },
    ],
    [contract, externalId],
  );

  const availableItems = useMemo(
    () =>
      items.filter((item) => {
        const { loanTypes, userLevels } = item.filters;

        const userValid =
          !userLevels ||
          userLevels === 'all' ||
          (Array.isArray(roles) && roles.filter((role) => userLevels.includes(role)).length);
        const loanTypeValid = !loanTypes || loanTypes === 'all' || loanTypes.includes(loanType);

        return userValid && loanTypeValid;
      }),
    [loanType, roles],
  );

  return availableItems.map((item) => (
    <MenuAction key={item.title} onPress={item.action} title={item.title} />
  ));
}
