/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { combineEpics } from 'redux-observable';
import _ from 'lodash';

import { GET_FILE_HISTORY_FILES_REQUEST, success, failure } from './action';

const getFile = (ajax, file) => {
  const { contractId, ...data } = file;

  return ajax
    .open('post', `/admin/filehistory/file`, data, { responseType: 'blob' })('tudo')
    .mergeMap(({ response }) => Observable.of(response))
    .flatMap((blob) =>
      Observable.of({
        type: data.historyType,
        uri: URL.createObjectURL(blob),
        id: data.id,
        documentType: data.documentType,
        size: blob.size,
        exist: blob.size !== 0,
      }),
    )
    .pipe(catchError((error) => Observable.of(error)));
};

const getFiles = (ajax, files) => forkJoin(...files.map((file) => getFile(ajax, file)));

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(GET_FILE_HISTORY_FILES_REQUEST)
    .map(({ data: { files } }) => ({ files }))
    .mergeMap(({ files }) =>
      getFiles(AjaxRequest, files)
        .flatMap((response) => Observable.of(success(_.keyBy(response, (elem) => elem.id))))
        .catch((err) => Observable.of(failure(err))),
    );

export default combineEpics(events);
