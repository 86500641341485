import { Icon, Tooltip } from 'antd';
import InfoIcon from '~/assets/icons/info-Icon.svg';
import CornerIcon from '~/assets/icons/corner-down-right.svg';
import React from 'react';
import LoanType from '~/typings/enums/LoanType';
import OperationStepType from '~/typings/enums/OperationStepType';
import { Person } from '~/typings/entities/person';
import { InfoPopover, InfoTooltip } from './styled';

export function TooltipInfoCard() {
  return (
    <InfoTooltip>
      <h3>
        <Icon component={InfoIcon} />
        {' '}
        Atenção as informações
      </h3>

      <p>
        As informações são referentes ao
        {' '}
        <b>canal de contato, setor e motivo do contato.</b>
      </p>

      <p>
        Para visualizar outras informações referente ao contrato, clique no número do
        mesmo exibido na tela e tenha acesso a todas as informações necessárias.
      </p>
    </InfoTooltip>
  );
}

export function TooltipContractInfo() {
  return (
    <InfoTooltip>
      <h3>
        <Icon component={InfoIcon} />
        {' '}
        Atenção as informações
      </h3>

      <p>
        A ordem das informações no contrato são:
        {' '}
        <b>Produto, convênio e passo na esteira.</b>
      </p>
    </InfoTooltip>
  );
}

interface PopoverContractInfoProps {
  contract: any;
  person: Person;
}

export function PopoverContractInfo({ contract, person }: PopoverContractInfoProps) {
  return (
    <InfoPopover>
      <h3>
        Informações
        {' '}
        <Tooltip title={<TooltipContractInfo />}>
          <Icon component={InfoIcon} />
        </Tooltip>
      </h3>

      <div>
        <Icon component={CornerIcon} />
        <span>{LoanType(contract.loanType)}</span>
      </div>

      <div>
        <Icon component={CornerIcon} />
        <span>{person.agreementName}</span>
      </div>

      {!!contract.operationStep && (
        <div>
          <Icon component={CornerIcon} />
          <span>{OperationStepType(contract.operationStep)}</span>
        </div>
      )}

    </InfoPopover>
  );
}
