import styled from 'styled-components';

interface DividerProps {
  orientation?: 'horizontal' | 'vertical';
  size: number | string;
  color?: string;
  margin?: number;
}

const getSize = (size: number | string) => {
  if (typeof size === 'number') {
    return `${size}px`;
  }
  return size;
};

export const StyledDivider = styled.div<DividerProps>`
  margin: ${({ orientation, margin }) =>
    orientation === 'vertical' ? `0 ${margin || 0}px` : `${margin || 0}px 0`};
  height: ${({ orientation, size }) => (orientation === 'vertical' ? '100%' : getSize(size))};
  width: ${({ orientation, size }) => (orientation === 'horizontal' ? '100%' : getSize(size))};
  border-right: ${({ orientation, lineType, theme, color }) =>
    orientation === 'vertical'
      ? `1px ${lineType} ${color || theme.colors.divider.primary}`
      : 'none'};
  border-bottom: ${({ orientation, lineType, theme, color }) =>
    orientation === 'horizontal'
      ? `1px ${lineType} ${color || theme.colors.divider.primary}`
      : 'none'};
`;
