import React, { useCallback, useEffect, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import {
  createTicketAssessment,
  getTicketAssessment,
  getTickets,
  selectAssessment,
  selectTicket,
  updateTicketAssessment,
} from '~/store/tickets/actions';
import { Button, Flex, Typography } from '~/ui/components';

import { Loading } from '~/ui/assets/icons';
import moment from 'moment';
import { ResultItem } from '~/screens/Tickets/Search/ResultItem/ResultItem';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { Filters } from './Filters/Filters';

import { LoadingContainer, ResultsContainer, SearchContainer, SearchHeader } from './Search.styled';

export function Search() {
  const history = useHistory();
  const { search } = history.location;
  const searchParams = queryString.parse(search);

  const [containerClass, setContainerClass] = useState('');
  const [tickets, setTickets] = useState([] as any[]);
  const [filters, setFilters] = useState<any>({
    sort: 'ticketOpeningDate,DESC',
    page: 0,
  });

  const assessmentTicketControl = useAwaitControl(getTicketAssessment);

  const createAssessmentControl = useAwaitControl(createTicketAssessment);
  const createAssessmentSuccess = createAssessmentControl.isSuccessful();

  const updateAssessmentControl = useAwaitControl(updateTicketAssessment);
  const updateAssessmentSuccess = updateAssessmentControl.isSuccessful();

  const selectTicketControl = useAwaitControl(selectTicket);
  const selectedTicket = selectTicketControl.result();

  const selectAssessmentControl = useAwaitControl(selectAssessment);
  const selectedAssessment = selectAssessmentControl.result();

  const getTicketsControl = useAwaitControl(getTickets);
  const ticketLoading = getTicketsControl.isRunning();
  const ticketsSuccess = getTicketsControl.isSuccessful();

  const ticketsResult = getTicketsControl.result();

  const setQueryParam = (key: string, value: string) => {
    const { pathname, search } = history.location;
    const searchParams = queryString.parse(search);

    searchParams[key] = value;

    const nonEmptyParams = _(searchParams).omitBy(_.isUndefined).omitBy(_.isNull).value();
    history.push({ pathname, search: queryString.stringify(nonEmptyParams) });
  };

  const updateTicketStatus = (ticketId: string, status: string) => {
    const foundTicketIndex = tickets.findIndex((t) => t.ticket.id === ticketId);
    const ticket = tickets[foundTicketIndex];

    if (foundTicketIndex !== -1 && ticket) {
      ticket.assessmentStatus = status;
    }

    if (status === 'DONE') {
      assessmentTicketControl.start({ ticketId });
    }
  };

  const getTicketCount = useCallback(() => {
    if (!ticketsSuccess && !tickets?.length) {
      return 'Nenhum ticket encontrado';
    }

    if (ticketLoading) {
      return 'Carregando...';
    }

    return `Exibindo ${tickets?.length} de ${ticketsResult?.total || 0} tickets encontrados`;
  }, [tickets, ticketsResult, ticketsSuccess, ticketLoading]);

  const reset = () => {
    selectAssessmentControl.clear();
    selectTicketControl.clear();
    createAssessmentControl.clear();
    updateAssessmentControl.clear();
  };

  const handleTicketClick = (ticket: any) => {
    reset();
    selectTicketControl.start(ticket);
  };

  const loadTickets = useCallback(
    (values?: any) => {
      if (values?.period) {
        const { period, ...rest } = values;
        const start = moment(period[0]).format('YYYY-MM-DD');
        const end = moment(period[1]).format('YYYY-MM-DD');

        getTicketsControl.start({ ...rest, start, end });
      } else {
        getTicketsControl.start(values);
      }
    },
    [filters],
  );

  const onClear = () => {
    setFilters({ sort: 'ticketOpeningDate,DESC', page: 0 });
    reset();
    setTickets([]);
    getTicketsControl.clear();
    setQueryParam('ticketId', null);
  };

  const onSubmit = useCallback((values: any) => {
    let newFilters: any;

    if (values.ticketId) {
      setQueryParam('ticketId', values.ticketId);
    } else {
      setQueryParam('ticketId', null);
    }

    setFilters(() => {
      newFilters = { ...values, page: 0 };
      return newFilters;
    });

    reset();
    setFilters(newFilters);
    setTickets([]);
    getTicketsControl.clear();
    loadTickets(newFilters);
  }, []);

  const onLoadMore = () => {
    const newFilters = { ...filters, page: filters.page + 1 };
    setFilters(newFilters);
    loadTickets(newFilters);
  };

  useEffect(() => {
    if (selectedAssessment) {
      setTimeout(() => {
        setContainerClass('collapsed');
      }, 300);
    } else {
      setTimeout(() => {
        setContainerClass('');
      }, 400);
    }
  }, [selectedAssessment]);

  useEffect(() => {
    if (ticketsResult?.tickets) {
      setTickets((prev) => [...prev, ...ticketsResult.tickets]);
    }
  }, [ticketsResult]);

  useEffect(() => {
    if (createAssessmentSuccess) {
      const ticketId = selectedTicket?.id;
      updateTicketStatus(ticketId, 'IN_PROGRESS');
      createAssessmentControl.clear();
    }
  }, [createAssessmentSuccess]);

  useEffect(() => {
    if (updateAssessmentSuccess) {
      const ticketId = selectedTicket?.id;
      updateTicketStatus(ticketId, 'DONE');
      updateAssessmentControl.clear();
    }
  }, [updateAssessmentSuccess]);

  useEffect(() => {
    if (ticketsSuccess && searchParams?.ticketId) {
      if (tickets?.[0]?.ticket) {
        handleTicketClick(tickets?.[0]?.ticket);
      }
    }
  }, [ticketsSuccess, tickets]);

  useEffect(() => {
    if (searchParams.ticketId) {
      const newFilters = { ...filters, ticketId: searchParams.ticketId };
      setFilters(newFilters);
      loadTickets(newFilters);
    }
  }, []);

  return (
    <SearchContainer collapse={!!selectedAssessment} className={containerClass}>
      <SearchHeader>
        <Typography type="bodyLarge" weight={700}>
          Tickets
        </Typography>

        <Filters initialValues={filters} onSubmit={onSubmit} onClear={onClear} />

        <Typography type="bodyXSmall" color="element.secondary">
          {getTicketCount()}
        </Typography>
      </SearchHeader>

      {ticketLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}

      {!ticketLoading && ticketsSuccess && tickets?.length === 0 && (
        <Flex justify="center" pv={16} ph={32}>
          <Typography type="bodySmall">Nenhum ticket encontrado</Typography>
        </Flex>
      )}

      {!ticketLoading && !ticketsSuccess && (
        <Flex justify="center" align="center" pv={16} ph={32} height="calc(100vh - 290px)">
          <Typography type="bodyXSmall" color="element.secondary">
            Faça uma busca para encontrar tickets
          </Typography>
        </Flex>
      )}

      {!!tickets?.length && (
        <ResultsContainer>
          <>
            {tickets?.map(({ ticket, assessmentStatus }) => (
              <ResultItem
                key={ticket.id}
                selected={selectedTicket?.id === ticket.id}
                ticketId={ticket.id}
                createdBy={ticket.userCreated}
                team={ticket.team}
                channel={ticket.channel}
                reason={ticket.reason}
                resolutionStatus={ticket.statusTicket}
                assessmentStatus={assessmentStatus}
                date={ticket.ticketOpeningDate}
                onClick={() => handleTicketClick(ticket)}
              />
            ))}
            {ticketsResult?.total > tickets.length && (
              <Flex justify="center" pv={16}>
                <Button size="sm" variant="text" onClick={onLoadMore}>
                  Carregar mais
                </Button>
              </Flex>
            )}
          </>
        </ResultsContainer>
      )}
    </SearchContainer>
  );
}
