import React, { useCallback, useEffect } from 'react';

import * as Icons from '~/ui/assets/icons';

import moment from 'moment';
import { getUserNotifications, markNotificationsAsRead } from '~/store/utilities/actions';
import { useAwaitControl } from 'react-redux-await-control';
import { useHistory } from 'react-router';
import { Smile } from '~/ui/assets/icons';
import {
  NotificationContainer,
  MarkAsRead,
  NotificationHeader,
  Title,
  MessagesList,
  Message,
  MessageIcon,
  MessageText,
  MessageDate,
  MessageBody,
} from './NotificationMessages.styled';

type MessageType = 'HISCON';

const MessageIcons = {
  HISCON: { icon: 'Refresh', color: '#45B12E' },
};

type MessageItem = {
  id: string;
  user: string;
  readed: boolean;
  notificationDate: string;
  level: 'ERROR' | 'SUCCESS';
  params: any;
  type: MessageType;
  description: string;
};

export type NotificationMessagesProps = {
  messages: MessageItem[];
  onMessageClick: (id: string | number) => void;
};

export function NotificationMessages({ messages = [], onMessageClick }: NotificationMessagesProps) {
  const history = useHistory();
  const markNotificationsAsReadControl = useAwaitControl(markNotificationsAsRead);
  const notificationsControl = useAwaitControl(getUserNotifications);

  const markAsReadSuccess = markNotificationsAsReadControl.isSuccessful();
  const notificationsLoading = notificationsControl.isRunning();

  const renderDate = useCallback((date) => {
    if (!date) return '';
    const now = moment();
    const duration = moment.duration(moment(date).diff(now));
    return `${duration.humanize()} atrás`;
  }, []);

  const renderIcon = useCallback((type, level) => {
    const iconType = MessageIcons[type];
    const isError = level === 'ERROR';
    const Icon = Icons[iconType.icon];
    const color = isError ? 'red' : iconType.color;
    if (!iconType) return null;
    return <MessageIcon color={color}>{isError ? <Icons.AlertCircle /> : <Icon />}</MessageIcon>;
  }, []);

  const handleMessageClick = useCallback((message) => {
    onMessageClick?.(message.id);

    if (!message.readed) {
      markNotificationsAsReadControl.start({ ids: [message.id] });
    }

    if (message.params.personId) {
      history.push(`/backoffice/people/${message.params.personId}`);
    }
  }, []);

  const markAsRead = useCallback(() => {
    const ids = messages.filter((m) => !m.readed).map((m) => m.id);
    if (ids?.length) {
      markNotificationsAsReadControl.start({ ids });
    }
  }, [messages]);

  useEffect(() => {
    if (markAsReadSuccess) {
      notificationsControl.start();
    }
  }, [markAsReadSuccess]);

  return (
    <NotificationContainer>
      <NotificationHeader>
        <Title>Notificações</Title>
        <MarkAsRead onClick={markAsRead}>Marcar tudo como lido</MarkAsRead>
      </NotificationHeader>

      <MessagesList>
        {!!messages?.length &&
          messages?.map((message) => (
            <Message
              unread={!message.readed}
              key={message.id}
              onClick={() => handleMessageClick(message)}
            >
              {renderIcon(message.type, message.level)}
              <MessageBody>
                <MessageText>{message.description}</MessageText>
                <MessageDate>{renderDate(message.notificationDate)}</MessageDate>
              </MessageBody>
            </Message>
          ))}

        {!messages?.length && !notificationsLoading && (
          <Message>
            <Smile width={24} height={24} />
            <MessageBody>
              <MessageText>No momento não há nenhuma notificação.</MessageText>
            </MessageBody>
          </Message>
        )}
      </MessagesList>
    </NotificationContainer>
  );
}
