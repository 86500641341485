import React from 'react';

import { Typography } from '~/ui/components';
import { UserPlus } from 'react-feather';
import theme from '~/ui/theme';
import { IconArea, StatisticsCardContainer, TextArea } from './StatisticsCard.styled';

interface StatisticsCardsProps {
  title: string;
  description: string;
  titleColor?: string;
  descriptionColor?: string;
}

export function StatisticsCard({
  title,
  description,
  titleColor = '#666666',
  descriptionColor = '#1A1A1A',
}: StatisticsCardsProps) {
  return (
    <StatisticsCardContainer>
      <IconArea>
        <UserPlus
          color={theme.colors.primary.main}
          height={30}
          width={30}
          data-testid="user-plus-icon"
        />
      </IconArea>
      <TextArea>
        <Typography type="footnote" color={titleColor} element="p" weight={500}>
          {title}
        </Typography>
        <Typography type="caption" color={descriptionColor} element="p" weight={600}>
          {description}
        </Typography>
      </TextArea>
    </StatisticsCardContainer>
  );
}
