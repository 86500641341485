/* @flow */

import { setLocalTag } from '~/store/tagsGet/action';
import { message } from '~/common';
import { addTag } from '~/store/tags/actions';

export const TAGTYPES_SAVE_REQUEST = 'TAGTYPES_SAVE_REQUEST';
export const TAGTYPES_SAVE_SUCCESS = 'TAGTYPES_SAVE_SUCCESS';
export const TAGTYPES_SAVE_FAILURE = 'TAGTYPES_SAVE_FAILURE';

export function success(payload) {
  message.success('Tag criada');
  return (dispatch, getState) => {
    const { externalId } = getState().getIn(['tagTypesSave', 'data']);
    if (externalId) {
      const { id: tagTypeId, tagResourceType: type, name } = payload;
      dispatch(addTag.start({ externalId, tagTypeId, type, name }));
    }
    dispatch({
      type: TAGTYPES_SAVE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  message.error('Falha ao criar tag');
  return (dispatch) => {
    dispatch({
      type: TAGTYPES_SAVE_FAILURE,
      payload: resource,
    });
  };
}

type Payload = {
  tagResourceType: 'CONTRACT' | 'PERSON',
  color?: string,
  name: string,
  externalId: number,
};

export default function saveTagTypes(data: Payload) {
  data.level = 'PUBLIC';
  return (dispatch) => {
    dispatch(setLocalTag(data));
    dispatch({
      type: TAGTYPES_SAVE_REQUEST,
      data,
    });
  };
}
