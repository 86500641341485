import React, { useMemo } from 'react';
import { OldActions as MenuAction } from '~/components';
import { downloadPortabilityTerm } from '~/store/contracts/actions';
import { useAwaitControl } from 'react-redux-await-control';
import useUserRoles from '~/hooks/useUserRoles';
import { useHandleAsyncDocumentDownload } from '~/hooks/useHandleAsyncDocumentDownload';
import LoanType from '~/typings/enums/LoanType';

export type ValidateKYCStepProps = {
  contractId: string;
  details: any;
};

export function DownloadPortabilityTerm({ contractId, details }: ValidateKYCStepProps) {
  const { PORTABILITY } = LoanType.LOAN_TYPES;
  const { loanType } = details;
  const { hasRole, roles, isAdmin } = useUserRoles();
  const downloadPortabilityTermControl = useAwaitControl(downloadPortabilityTerm);
  const loading = downloadPortabilityTermControl.isRunning();

  const handleOnPress = () => {
    downloadPortabilityTermControl.start({ contractId });
  };

  const shouldShowButton = useMemo(
    () =>
      ['SIGNED', 'PAID'].includes(details?.loanStatus) &&
      [PORTABILITY].includes(loanType) &&
      (hasRole('LEGAL_TEAM') || isAdmin),
    [details?.loanStatus, roles],
  );

  useHandleAsyncDocumentDownload(downloadPortabilityTerm, `termo-portabilidade-${contractId}.pdf`);

  if (!shouldShowButton) {
    return null;
  }

  return (
    <MenuAction
      disabled={loading}
      onClick={handleOnPress}
      key="portability-term"
      title="Baixar Termo de Portabilidade"
    />
  );
}
