import React from 'react';
import type { PendencyType } from '~/screens/ContractDetails/Panels/Pendencies/Pendencies';
import pendencyTitle from '~/typings/enums/PendencyType';
import { Pendency } from '~/screens/ContractDetails/Panels/Pendencies/styled';
import { PendenciesContext } from '~/screens/ContractDetails/Panels/Pendencies/PendenciesContainer';
import Documents from './Documents';
import DocumentNumber from './DocumentNumber';
import ProofOfResidence from './ProofOfResidence';
import Biometry from './Biometry';
import Paycheck from './Paycheck';
import Address from './Address';
import Account from './Account';

const pendencyViews = {
  AGREEMENT_PASSWORD: Pendency,
  DOCUMENTS: Documents,
  PICTURE_DOCUMENT: Documents,
  REGISTER_NUMBER: DocumentNumber,
  PROOF_OF_ADDRESS: ProofOfResidence,
  PAYCHECK: Paycheck,
  FACEPICTURE: Biometry,
  ADDRESS: Address,
  BANK_ACCOUNT_INVALID: Account,
  PICTURE_FACE_FRONT_RECOGNITION: Biometry,
};

function UnknownPendency({ pendingType, reason }: PendencyType) {
  return (
    <Pendency>
      <b>{pendencyTitle(pendingType)}</b>
      <span>{reason}</span>
    </Pendency>
  );
}

export default function getViewForPendency(pendency: PendencyType) {
  const Component = pendencyViews[pendency.pendingType];

  return (
    <PendenciesContext.Consumer>
      {({ getPendencies }) =>
        Component ? (
          <Component {...pendency} getPendencies={getPendencies} key={pendency.id}>
            {pendencyTitle(pendency.pendingType)}
          </Component>
        ) : (
          <UnknownPendency {...pendency} />
        )
      }
    </PendenciesContext.Consumer>
  );
}
