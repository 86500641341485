/* @flow */

export const PERSON_REGISTRY_FILES_REQUEST = 'PERSON_REGISTRY_FILES_REQUEST';
export const PERSON_REGISTRY_FILES_SUCCESS = 'PERSON_REGISTRY_FILES_SUCCESS';
export const PERSON_REGISTRY_FILES_FAILURE = 'PERSON_REGISTRY_FILES_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: PERSON_REGISTRY_FILES_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: PERSON_REGISTRY_FILES_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(params) {
  return dispatch => {
    dispatch({
      type: PERSON_REGISTRY_FILES_REQUEST,
      payload: params,
    });
  };
}
