/* @flow */

export const UPDATE_REFINANCED_CONTRACT_REQUEST =
  'UPDATE_REFINANCED_CONTRACT_REQUEST';
export const UPDATE_REFINANCED_CONTRACT_SUCCESS =
  'UPDATE_REFINANCED_CONTRACT_SUCCESS';
export const UPDATE_REFINANCED_CONTRACT_FAILURE =
  'UPDATE_REFINANCED_CONTRACT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: UPDATE_REFINANCED_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: UPDATE_REFINANCED_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

type Payload = {
  contractId: number,
  refinancedContractId: number,
  fields: {},
};

export default function updateContract(params: Payload) {
  return dispatch => {
    dispatch({
      type: UPDATE_REFINANCED_CONTRACT_REQUEST,
      payload: params,
    });
  };
}
