import React, { useMemo } from 'react';

import { BankAccount } from '~/screens/Person/components/Modals/Pendency/Resolutions/BankAccount';
import { Steps } from '~/ui/components';
import { FaceFrontRecognition } from './Resolutions/FaceFrontRecognition';
import { PictureDocument } from './Resolutions/PictureDocument';
import { ProfOfAddress } from './Resolutions/ProfOfAddress';
import { Paycheck } from './Resolutions/Paycheck';
import { Address } from './Resolutions/Address';

import { PendencyDetails } from './PendencyDetails';

interface PendencyProps {
  pendency: any;
}

export function Pendency({ pendency }: PendencyProps) {
  const pendencySteps = {
    ADDRESS: <Address pendency={pendency} />,
    BANK_ACCOUNT_INVALID: <BankAccount pendency={pendency} />,
    PAYCHECK: <Paycheck pendency={pendency} />,
    PICTURE_DOCUMENT: <PictureDocument pendency={pendency} />,
    PICTURE_FACE_FRONT_RECOGNITION: <FaceFrontRecognition pendency={pendency} />,
    PROOF_OF_ADDRESS: <ProfOfAddress pendency={pendency} />,
  };

  const hasResolution = useMemo(
    () => Object.keys(pendencySteps).includes(pendency?.pendingType),
    [pendency, pendencySteps],
  );

  const solved = useMemo(() => pendency?.status === 'Resolvida', [pendency, pendencySteps]);

  const steps = useMemo(
    () => [
      {
        id: 'DETAILS',
        component: (
          <PendencyDetails solved={solved} hasResolution={hasResolution} pendency={pendency} />
        ),
      },
      {
        id: 'RESOLUTION',
        component: pendencySteps[pendency.pendingType],
      },
    ],
    [pendency, pendencySteps, hasResolution],
  );

  return <Steps steps={steps} />;
}
