import React from 'react';
import moment from 'moment';

import { OpportunitySimulation } from '~/typings/entities/Opportunity';
import toPercentage from '~/common/masked/toPercentage';
import toMoney from '~/common/masked/toMoney';
import { Divider, Flex, Typography } from '~/ui/components';

import { ResumeBody } from './Resume.styled';

export type ResumeProps = {
  contract: OpportunitySimulation;
};

export function Resume({ contract }: ResumeProps) {
  const formatDate = (date: string, format = 'DD/MM/YYYY') =>
    date ? moment(date).format(format) : '';

  return (
    <ResumeBody direction="column" gap={24} ph={32} pv={24} width="100%">
      <Flex align="center" justify="center" width="100%">
        <Typography type="paragraphLarge" weight={600} center>
          Detalhes da oportunidade
        </Typography>
      </Flex>

      <Flex direction="column" gap={16} width="100%">
        <div>
          <Typography type="bodyMedium">Valor aproximado que vai receber</Typography>

          <Flex gap={8} align="center" width="100%">
            <Typography type="headingH5" weight={600}>
              {contract?.contractValue ? toMoney(contract?.contractValue || 0) : '-'}
            </Typography>
          </Flex>
        </div>

        <Flex gap={8} direction="column" mv={8} width="100%">
          <Typography type="bodyMedium" weight={600}>
            Previsão de pagamento
          </Typography>

          <Typography type="bodySmall">
            Entre {formatDate(contract?.nearEstimatedEndDate) || '-'} e{' '}
            {formatDate(contract?.farEstimatedEndDate) || '-'}
          </Typography>
        </Flex>

        <Divider />

        <Flex gap={8} width="100%" direction="column">
          <Flex gap={8} direction="column" mv={8} width="100%">
            <Typography type="bodyMedium" weight={600}>
              Parcelas do contrato
            </Typography>

            <Typography type="bodySmall">
              {contract?.numberOfInstallments}x de {toMoney(contract?.installmentValue || 0)}
            </Typography>
          </Flex>

          <Flex gap={8} direction="column" mv={8} width="100%">
            <Typography type="bodyMedium" weight={600}>
              Custo efetivo total (CET):
            </Typography>

            <Typography type="bodySmall">
              {toPercentage(contract?.effectiveRate) || '-'} (a.m) |{' '}
              {toPercentage(contract?.totalEffectiveCost) || '-'} (a.a)
            </Typography>
          </Flex>

          <Flex gap={8} direction="column" mv={8} width="100%">
            <Typography type="bodyMedium" weight={600}>
              IOF (Imposto sobre operações financeiras):
            </Typography>

            <Typography type="bodySmall">{toMoney(contract?.iofValue || 0)}</Typography>
          </Flex>
        </Flex>
      </Flex>
    </ResumeBody>
  );
}
