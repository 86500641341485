import styled, { css } from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Button } from '~/ui/components';

type ControlsPanelProps = {
  animation?: 'hide' | 'show';
};

type FocusedProp = {
  focused?: boolean;
};

export const ControlsPanel = styled.div.attrs({
  className: 'editor-control-panel',
})<ControlsPanelProps>`
  width: 100%;
  gap: 4px;
  display: flex;
  overflow: hidden;
  height: 0;
  padding-top: 0;
  margin-top: 0;
  border-top: none;
  animation: ${({ animation }) => animation} 0.2s linear;

  ${({ animation }) =>
    animation === 'show' &&
    css`
      height: 40px;
      padding-top: 15px;
      margin-top: 6px;
      border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};
    `};

  ${({ animation }) =>
    animation === 'hide' &&
    css`
      height: 0;
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    `};

  @keyframes show {
    0% {
      height: 0;
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
    100% {
      height: 40px;
      padding-top: 15px;
      margin-top: 6px;
      border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};
    }
  }

  @keyframes hide {
    0% {
      height: 40px;
      padding-top: 15px;
      margin-top: 6px;
      border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};
    }
    100% {
      height: 0;
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
  }

  ${({ controlsVisible }) =>
    controlsVisible &&
    css`
      height: 40px;
      padding-top: 15px;
      margin-top: 6px;
      border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};
      animation: none;
    `};
`;

type ControlButtonProps = {
  height?: number;
};

export const ControlButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  size: 'sm',
  iconOnly: true,
  type: 'button',
  customColor: theme.colors.gray[600],
}))<ControlButtonProps>`
  height: 22px;
  min-width: 22px;
  max-width: 22px;
  transition: none;

  &:hover {
    box-shadow: 1px 1px 0 ${({ theme }) => theme.colors.gray[100]};
  }

  &:active {
    box-shadow: 1px 1px 0 ${({ theme }) => theme.colors.gray[100]} inset;
  }

  svg {
    height: ${({ height }) => height || 15}px;
  }
`;

export const EditorContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid #c6c6c6;
  border-radius: 12px;
  width: 100%;
  padding: 12px;
  min-height: ${({ small }) => (small ? '52px' : '68px')};
  display: flex;
  flex-direction: column;
  justify-content: center;

  [data-slate-placeholder='true'],
  [data-slate-string='true'],
  [data-slate-node='text'] {
    color: ${({ theme }) => theme.colors.element.secondary};
  }

  & > div {
    outline: none;

    p {
      margin: 0;
    }
  }
`;

type EditableElementProps = FocusedProp & {};

export const EditableElement = styled.div<EditableElementProps>`
  outline: none;
  color: ${({ theme }) => theme.colors.element.placeholder};

  ${({ focused }) =>
    focused &&
    css`
      color: ${({ theme }) => theme.colors.element.primary};
    `}
`;

type PreviewProps = {
  bordered?: boolean;
};

export const Preview = styled.div.attrs({
  className: 'editor-preview',
})<PreviewProps>`
  
  & > div {
    display: inline;
    margin-right: 4px;
  }

  p:last-child {
    margin-bottom: 0;
    display: inline;
  }
  
  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid #c6c6c6;
      border-radius: 12px;
      padding: 12px 10px;
    `}
}
`;

export const ActionButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  size: 'sm',
  customColor: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
}))`
  height: 22px;
  text-decoration: underline;
`;

export const ActionButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
