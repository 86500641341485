import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import { listActionHistoric } from '~/store/proposals/actions';
import { TableColumns } from '~/ui/components';

import { MtProposalHistory } from '~/typings/entities/Proposals';
import { SmallTable } from '../Returns.styled';

export type HistoryProps = {
  actionProposalId: string;
};

export function History({ actionProposalId }: HistoryProps) {
  const listActionHistoricControl = useAwaitControl(listActionHistoric);

  const historyLoading = listActionHistoricControl.isRunning(actionProposalId);
  const history = listActionHistoricControl.result(actionProposalId);

  const columns: TableColumns<MtProposalHistory> = [
    { accessorKey: 'id', header: () => 'ID' },
    { accessorKey: 'proposal', header: () => 'ID PROPOSTA' },
    { accessorKey: 'proposalAction', header: () => 'ID ACTION' },
    { accessorKey: 'actionType', header: () => 'TIPO' },
    { accessorKey: 'actionStatus', header: () => 'STATUS' },
    {
      accessorKey: 'creationDate',
      header: () => 'DATA',
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
    },
  ];

  useEffect(() => {
    if (actionProposalId && (!history || !history.length)) {
      listActionHistoricControl.start({ actionProposalId }, { actionId: actionProposalId });
    }
  }, [actionProposalId]);

  return (
    <SmallTable
      noItemsMessage="Nenhum retorno encontrado"
      loading={historyLoading}
      data={history}
      columns={columns}
      rowHeight={64}
    />
  );
}
