// @flow

import React, { useRef, useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

type SelectionProps = {
  onFileSelected: (file: File) => void,
};

const OPTIONS = [
  {
    title: 'Fatura de energia',
    type: 'ENERGYBILL',
  },
  {
    title: 'Fatura de água',
    type: 'WATERBILL',
  },
  {
    title: 'Fatura de telefone',
    type: 'TELEPHONEBILL',
  },
  {
    title: 'Fatura de cartão',
    type: 'CREDITCARDBILL',
  },
  {
    title: 'Outros',
    type: 'OTHER',
  },
];

function Selection(props: SelectionProps) {
  const inputEl = useRef(null);
  const [type, setType] = useState('OTHER');

  const onFileOk = (event) => {
    const file = event.target.files[0];
    props.onFileSelected(file, type);
  };

  const onStepTypeChange = (type) => {
    setType(type);
    inputEl.current.click();
  };

  return (
    <div>
      <Select
        style={{ width: '100%' }}
        showSearch
        placeholder="Selecione o novo passo"
        onChange={onStepTypeChange}
        allowClear
        optionFilterProp="children"
      >
        {OPTIONS.map((item) => (
          <Option key={item.type}>{item.title}</Option>
        ))}
      </Select>
      <input ref={inputEl} hidden type="file" accept="image/*" onChange={onFileOk} />
    </div>
  );
}

export default Selection;
