import { DatePicker, TimePicker, Upload } from 'antd';
import styled, { css } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Button, Typography } from '~/ui/components';

const { Dragger: DraggerComponent } = Upload;

const InputCss = css`
  input {
    cursor: pointer;
    border: none;
    padding: 4px 4px !important;

    font-size: 15px;

    &:focus {
      border: none;
      box-shadow: unset;
    }
  }
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary['50']};
  margin: 20px 0;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.gray['700']};
`;

export const FormWrapper = styled.div`
  padding: 24px 0;

  .mt-alert {
    margin-bottom: 16px;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const DateInput = styled(DatePicker).attrs({
  showToday: false,
})`
  width: 230px;

  ${InputCss}

  i {
    display: none;
  }
`;

export const TimeInput = styled(TimePicker)`
  width: 90px;
  ${InputCss}

  input {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  span {
    display: none;
  }
`;

export const CustomButton = styled(Button).attrs(({ theme }) => ({
  customColor: themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondaryAlt' }),
}))`
  margin-top: 8px;
`;

export const Dragger = styled(DraggerComponent)`
  margin-top: 10px;
  background: ${(props) => props.theme.colors.white.main} !important;
  border-color: ${(props) => props.theme.colors.gray[400]} !important;
  border-radius: ${(props) => props.theme.cardBorderRadius} !important;

  > span.ant-upload-btn {
    padding: 24px 0 !important;
  }

  :hover {
    border-color: ${(props) => props.theme.colors.secondary['50']} !important;
  }
`;

export const DraggerClickLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: 500,
  element: 'span',
  color: theme.colors.primary.main,
}))``;

export const DraggerLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: 400,
  element: 'p',
  color: theme.colors.gray[800],
}))``;

export const Caption = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'p',
  color: theme.colors.gray[800],
}))`
  margin-top: 2px;
`;
