import React, { useMemo } from 'react';
import { ContractInfo } from '~/typings/entities/ContractInfo';
import { QiReturns } from '~/screens/ContractDetails/Panels/Returns/QIReturns/QiReturns';
import { ParatiReturns } from '~/screens/ContractDetails/Panels/Returns/ParatiReturns/ParatiReturns';
import { MTReturns } from './MTReturns/MTReturns';

export type ReturnsProps = {
  contractId: string;
  contractInfo: ContractInfo;
};

export function Returns({ contractId, contractInfo }: ReturnsProps) {
  const isMtProposal = useMemo(
    () => contractInfo.isPAB || contractInfo.isINSSQiTech,
    [contractInfo],
  );

  const isParatiProposal = useMemo(() => contractInfo?.isParati, [contractInfo]);

  if (isParatiProposal) {
    return <ParatiReturns contractId={contractId} loan={contractInfo.loan} />;
  }

  if (isMtProposal) {
    return <MTReturns contractId={contractId} />;
  }

  if (!isMtProposal) {
    return <QiReturns contractId={contractId} />;
  }
}
