import React, { useCallback, useEffect } from 'react';

import { useModal } from '~/hooks/useModal';
import { solvePaycheck } from '~/store/pendencies/actions';
import { useAwaitControl } from 'react-redux-await-control';
import { FileItem } from '~/screens/Person/components';
import { getPersonDetail } from '~/store/people/actions';
import { getContractDetails } from '~/store/contracts/actions';
import { SingleDocument } from './SingleDocument';

type PaycheckProps = {
  pendency?: any;
};

export function Paycheck({ pendency }: PaycheckProps) {
  const { setLoading, closeModal } = useModal();
  const solvePaycheckControl = useAwaitControl(solvePaycheck);
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getContractDetailsControl = useAwaitControl(getContractDetails);

  const person = getPersonDetailsControl.result();
  const contract = getContractDetailsControl.result();

  const solvePaycheckLoading = solvePaycheckControl.isRunning();
  const solvePaycheckSuccess = solvePaycheckControl.isSuccessful();

  const handleOnSubmit = useCallback(
    ({ file }: FileItem) => {
      const idKey = contract?.id ? 'contractId' : 'personId';
      const idValue = contract?.id || person?.id;

      solvePaycheckControl.start({ file, id: pendency.id, [idKey]: idValue });
    },
    [pendency.id, contract?.id, person?.id],
  );

  useEffect(() => {
    setLoading('pendency-details', solvePaycheckLoading);
  }, [solvePaycheckLoading]);

  useEffect(() => {
    if (solvePaycheckSuccess) {
      closeModal('pendency-details');
    }
  }, [solvePaycheckSuccess]);

  return (
    <SingleDocument
      id="payCheck"
      name="Contracheque"
      loading={solvePaycheckLoading}
      onSubmit={handleOnSubmit}
      pendency={pendency}
    />
  );
}
