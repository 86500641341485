import React, { useEffect, useState } from 'react';

import { Caret, Typography } from '~/ui/components';
import { Link } from 'react-router-dom';
import { LOAN_TYPE_NAMES } from '~/typings/enums/LoanType';
import { TicketContractsPopover } from '~/components/Ticket/TicketDetails/TicketContractsPopover/TicketContractsPopover';
import { Button, Content, Header, Item, Label, Value, Wrapper } from './TicketInformations.styled';

const contactOriginType = {
  ACTIVE: 'Ativo',
  RECEPTIVE: 'Receptivo',
};
interface TicketInformationsProps {
  ticket: any;
}

export function TicketInformations({ ticket }: TicketInformationsProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(false);
  }, [ticket]);

  return (
    <Wrapper>
      <Header onClick={() => setIsVisible(!isVisible)}>
        <Typography type="bodyLarge" weight={600}>
          Informações do ticket
        </Typography>
        <Button>
          <Caret caretOpen={!isVisible} />
        </Button>
      </Header>

      {isVisible && (
        <Content>
          <Item>
            <Label>Person ID</Label>
            {ticket?.personId && (
              <Link to={`/backoffice/people/${ticket?.personId}`}>
                <Value link>{ticket?.personId || '-'}</Value>
              </Link>
            )}
            {!ticket?.personId && <Value>-</Value>}
          </Item>

          <Item>
            <Label>Produto</Label>
            <Value>{LOAN_TYPE_NAMES[ticket?.product] || '-'}</Value>
          </Item>

          <Item bold>
            <Label>{ticket?.contracts?.length > 1 ? 'Contratos' : 'Contrato'}</Label>
            {ticket?.contracts?.length > 0 && (
              <TicketContractsPopover contracts={ticket.contracts} />
            )}
            {!ticket?.contracts && <Value>-</Value>}
          </Item>

          <Item>
            <Label>Etapa da jornada</Label>
            <Value>{ticket?.journey || '-'}</Value>
          </Item>

          <Item>
            <Label>Origem do contato</Label>
            <Value>{contactOriginType[ticket?.contactOrigin] || '-'}</Value>
          </Item>

          <Item>
            <Label>Time</Label>
            <Value>{ticket?.team || '-'}</Value>
          </Item>

          <Item>
            <Label>Justificativa da resolução</Label>
            <Value>{ticket?.justification || '-'}</Value>
          </Item>

          <Item>
            <Label>Protocolo</Label>
            <Value>{ticket?.protocolTwilio || '-'}</Value>
          </Item>

          <Item>
            <Label>Descrição</Label>
            <Value>
              {ticket?.description ? (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: ticket?.description }} />
              ) : (
                '-'
              )}
            </Value>
          </Item>
        </Content>
      )}
    </Wrapper>
  );
}
