import React, { useCallback, useEffect, useMemo } from 'react';
import { ListGroup, Popover, Table, TableColumns, Tag, Tooltip } from '~/ui/components';
import moment from 'moment';

import { Ticket } from '~/typings/entities/Ticket';
import { useDrawer } from '~/hooks/useDrawer';
import { TicketDetails } from '~/components';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';

import { TicketResumePopover } from '~/screens/Person/components/Tabs/Tickets/TicketResumePopover';
import { useAwaitControl } from 'react-redux-await-control';
import {
  getServiceChannels,
  getTicketsByUser,
  getTicketStatus,
  selectTicket,
} from '~/store/tickets/actions';
import {
  CellWrapper,
  LinkToTicket,
  TicketsWrapper,
} from '~/screens/Person/components/Tabs/Tickets/Tickets.styled';
import { TicketOptions } from '~/components/Ticket/TicketDetails/TicketOptions/TicketOptions';
import { createSelectValues, findLastNameById, findNamesByIds } from '~/common/selectValues';
import { getTicketStatusColor, isTicketStatusComplete } from '~/common/tickets';
import { TicketAccompaniment } from '~/components/Ticket/TicketAccompaniment/TicketAccompaniment';
import { Dots } from '~/ui/assets/icons';
import theme from '~/ui/theme';
import { TicketModal } from '~/components/Ticket/TicketModal/TicketModal';
import useUserRoles from '~/hooks/useUserRoles';
import { useHistory } from 'react-router';
import { ActionButton } from '..';

interface TicketsProps {
  tickets?: Ticket[];
  loading?: boolean;
}

export function Tickets({ tickets, loading }: TicketsProps) {
  const history = useHistory();
  const { openDrawer, closeDrawer } = useDrawer();
  const { ready, hasRole, roles } = useUserRoles();
  const { ticketLimitHours } = remoteValues;

  const getServiceChannelsControl = useAwaitControl(getServiceChannels);
  const getTicketStatusControl = useAwaitControl(getTicketStatus);
  const getTicketsByUserControl = useAwaitControl(getTicketsByUser);
  const selectTicketControl = useAwaitControl(selectTicket);

  const isStatusLoading = getTicketStatusControl.isRunning();
  const isTicketsLoading = getTicketsByUserControl.isRunning();

  const channels = getServiceChannelsControl.result();
  const status = getTicketStatusControl.result();

  const isLoading = useMemo(
    () => loading || isTicketsLoading || isStatusLoading,
    [loading, isTicketsLoading, isStatusLoading],
  );

  const renderNames = useCallback((list, id: number, last = false) => {
    const ids = createSelectValues(list, id);
    const names = findNamesByIds(list, ids) || [];

    if (last) {
      return findLastNameById(list, id);
    }

    return names?.join(' - ');
  }, []);

  const redirectToTicket = (id: string) => {
    closeDrawer('ticket-details');
    setTimeout(() => {
      history.push(`/backoffice/tickets?ticketId=${id}`);
    }, 300);
  };

  const renderTitle = useCallback(
    (id: string, isMonitor) => {
      if (isMonitor) {
        return (
          <>
            Ticket número <LinkToTicket onClick={() => redirectToTicket(id)}>#{id}</LinkToTicket>
          </>
        );
      }

      return (
        <>
          Ticket número <b>#{id}</b>
        </>
      );
    },
    [ready, hasRole, roles],
  );

  const handleDetails = useCallback(
    (id) => () => {
      selectTicketControl.start(id);

      openDrawer('ticket-details', <TicketDetails id={id} />, {
        title: renderTitle(id, hasRole('TICKET_MONITORING')),
        extraAction: <TicketOptions />,
        closable: true,
        width: 600,
      });
    },
    [openDrawer, hasRole, renderTitle],
  );

  const handleOnClickActions = useCallback((e, row) => {
    if (e.id === 'update-ticket') {
      openDrawer('ticket-modal', <TicketModal isUpdating ticket={row} />, {
        title: 'Ticket de encaminhamento',
        width: 460,
        closable: true,
        maskClosable: false,
      });
    }

    return null;
  }, []);

  const isActionsEnabled = useCallback(
    (row) => {
      const isBOT = row.userCreatedId === 393;
      const date = moment(row.ticketOpeningDate);
      const isFortyEightHours = moment().diff(date, 'hours') <= ticketLimitHours;

      if (isBOT && isFortyEightHours) {
        return false;
      }

      return true;
    },
    [ticketLimitHours],
  );

  const columns: TableColumns<Ticket> = [
    {
      accessorKey: 'id',
      header: () => 'COD',
      cell: (info) => (
        <TicketResumePopover
          disabled={isTicketStatusComplete(status, info.row.original.ticketStatusId)}
          ticket={info.row.original}
        />
      ),
    },
    {
      accessorKey: 'ticketOpeningDate',
      header: () => 'DATA',
      cell: (info) => (
        <CellWrapper disabled={isTicketStatusComplete(status, info.row.original.ticketStatusId)}>
          <TicketAccompaniment
            triggerText={moment(info.row.original.ticketOpeningDate).format('DD/MM/YYYY')}
            ticket={info.row.original}
          />
        </CellWrapper>
      ),
      sortingFn: (rowA: any, rowB: any, columnId: any): number => {
        const dateA = moment(rowA.getValue(columnId), 'DD/MM/YYYY');
        const dateB = moment(rowB.getValue(columnId), 'DD/MM/YYYY');
        return dateA.isBefore(dateB) ? -1 : 1;
      },
    },
    {
      id: 'channel',
      header: () => 'CANAL',
      cell: (info) => {
        const { serviceChannelId } = info.row.original;
        return (
          <CellWrapper disabled={isTicketStatusComplete(status, info.row.original.ticketStatusId)}>
            {renderNames(channels, serviceChannelId, true)}
          </CellWrapper>
        );
      },
    },
    {
      id: 'reason',
      header: () => 'MOTIVO',
      ellipsis: true,
      maxSize: 170,
      cell: (info) => {
        const ticket = info.row.original;
        const reason = (
          <CellWrapper
            disabled={isTicketStatusComplete(status, info.row.original.ticketStatusId)}
            ellipsis
          >
            {ticket?.subReason ? `${ticket?.reason} - ${ticket?.subReason}` : ticket?.reason}
          </CellWrapper>
        );

        return (
          <Tooltip placement="bottomLeft" content={reason} small>
            {reason}
          </Tooltip>
        );
      },
    },
    {
      accessorKey: 'ticketStatusId',
      header: () => 'SITUAÇÃO',
      maxSize: 170,
      cell: (info) => {
        const statusName = findLastNameById(status, info.getValue<string>());
        return (
          <Tooltip placement="bottomLeft" content={statusName} small>
            <Tag small rounded {...getTicketStatusColor(status, info.getValue<string>())}>
              {statusName}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      id: 'history',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <ActionButton
          onClick={handleDetails(info.row.original.id)}
          iconOnly
          icon="FileSearch"
          variant="text"
        />
      ),
    },
    {
      id: 'actions',
      header: () => 'AÇÕES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <Popover
          width={250}
          placement="bottom"
          noHeader
          trigger="hover"
          disabled={isActionsEnabled(info.row.original)}
          content={
            <ListGroup
              items={[{ id: 'update-ticket', label: 'Editar ticket' }]}
              onClickItem={(e) => handleOnClickActions(e, info.row.original)}
            />
          }
        >
          <Dots
            color={
              isActionsEnabled(info.row.original)
                ? theme.colors.gray[900]
                : theme.colors.primary.main
            }
          />
        </Popover>
      ),
    },
  ];

  useEffect(() => {
    getTicketStatusControl.start();
  }, []);

  return (
    <TicketsWrapper>
      <Table
        noItemsMessage="Nenhum ticket encontrado"
        loading={!tickets?.length && isLoading}
        data={tickets}
        responsiveCols={['id', 'ticketOpeningDate', 'reason', 'ticketStatusId']}
        columns={columns}
      />
    </TicketsWrapper>
  );
}
