import { Icon } from 'antd';
import React, { useContext } from 'react';

import CloseIcon from '~/assets/icons/close.svg';
import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';
import {
  Divider,
  PopoverHeader,
  Radio,
  RadioDivider,
  Subtitle,
  SubtitleSmall,
  Title,
} from './styled';

interface HourFilterProps {
  closePopover: () => void;
}

const radioStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '18px',
};

export default function HourFilter({ closePopover }: HourFilterProps) {
  const { setHourConfig, hourFilter } = useContext(ContractDetailsContext);
  const { onHoursText, offHoursText } = remoteValues;

  const handleClose = () => {
    closePopover();
  };

  const handleChange = (e) => {
    setHourConfig(e.target.value);
    closePopover();
  };

  const renderSubtitle = (text) => {
    if (text.startsWith('*')) {
      return <SubtitleSmall>{text}</SubtitleSmall>;
    }
    return <Subtitle>{text}</Subtitle>;
  };

  return (
    <>
      <PopoverHeader>
        <Title>Filtro de horário</Title>
        <Icon component={CloseIcon} onClick={handleClose} style={{ fontSize: '24px' }} />
      </PopoverHeader>
      <Divider />
      <Radio.Group
        style={{ width: '100%', marginBottom: '10px' }}
        onChange={handleChange}
        value={hourFilter}
      >
        <Radio style={radioStyle} value="ONHOURS">
          ON Hours
        </Radio>
        {onHoursText?.map((text) => renderSubtitle(text))}

        <RadioDivider />
        <Radio style={radioStyle} value="OFFHOURS">
          OFF Hours
        </Radio>
        {offHoursText?.map((text) => renderSubtitle(text))}
      </Radio.Group>
    </>
  );
}
