import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Button, Typography } from '~/ui/components';
import {
  getTicketAssessment,
  getTicketMessages,
  selectAssessment,
  selectTicket,
} from '~/store/tickets/actions';
import { getLoggedUser } from '~/store/user/actions';
import { ContentBody, ContentContainer, ContentHeader, NoTicketsContainer } from './Content.styled';
import { TicketInformations } from './components/TicketInformations/TicketInformations';
import { CustomerService } from './components/CustomerService/CustomerService';
import { EvaluationHistory } from './components/AssessmentHistory/AssessmentHistory';

export function Content() {
  const selectedTicketControl = useAwaitControl(selectTicket);
  const ticketMessagesControl = useAwaitControl(getTicketMessages);
  const assessmentTicketControl = useAwaitControl(getTicketAssessment);
  const loggedUserControl = useAwaitControl(getLoggedUser);

  const selectedTicket = selectedTicketControl.result();
  const assessments = assessmentTicketControl.result();
  const loggedUser = loggedUserControl.result();

  const assessmentsLoading = assessmentTicketControl.isRunning();

  const selectAssessmentControl = useAwaitControl(selectAssessment);
  const selectedAssessment = selectAssessmentControl.result();

  const handleSelectAssessment = (assessment: any) => {
    if (assessment.inProgress && assessment.creatorId !== loggedUser.id) return;
    selectAssessmentControl.start(assessment);
  };

  const inProgressAssessment = useMemo(
    () => assessments?.find((item) => item.creatorId === loggedUser.id && item.inProgress),
    [assessments, loggedUser],
  );

  const disableAssessmentButton = useMemo(
    () =>
      assessmentsLoading ||
      !!selectedAssessment ||
      assessments?.find((item) => item.creatorId !== loggedUser.id && item.inProgress),
    [assessments, loggedUser, selectedAssessment, assessmentsLoading],
  );

  useEffect(() => {
    if (selectedTicket?.conversationId) {
      ticketMessagesControl.start({ conversationId: selectedTicket.conversationId });
    }
  }, [selectedTicket]);

  useEffect(() => {
    if (selectedTicket?.id) {
      assessmentTicketControl.start({ ticketId: selectedTicket.id });
    }
  }, [selectedTicket]);

  return (
    <ContentContainer>
      {!selectedTicket && (
        <NoTicketsContainer>
          <Typography type="headingH5" weight={700} center width={470}>
            Selecione um ticket ao lado para visualizar.
          </Typography>
        </NoTicketsContainer>
      )}

      {selectedTicket?.id && (
        <>
          <ContentHeader>
            <Typography type="headingH6" weight={700}>
              Ticket <Typography.Bold color="brand.primary">#{selectedTicket?.id}</Typography.Bold>
            </Typography>

            <Button
              rounded
              icon="Pencil"
              customColor="black"
              disabled={disableAssessmentButton}
              onClick={() => handleSelectAssessment(inProgressAssessment ?? {})}
            >
              {inProgressAssessment ? 'Continuar avaliação' : 'Avaliar'}
            </Button>
          </ContentHeader>
          <ContentBody>
            <TicketInformations ticket={selectedTicket} />
            <CustomerService ticket={selectedTicket} />
            <EvaluationHistory
              onClick={handleSelectAssessment}
              ratedTickets={assessments}
              ticket={selectedTicket}
            />
          </ContentBody>
        </>
      )}
    </ContentContainer>
  );
}
