// @flow

import React from 'react';
import { Icon, Modal as AntdModal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import { TitleContainer } from './styled';

type ModalProps = {
  enableFooter?: boolean,
};

function Title({ title, icon }: ModalFuncProps & ModalProps) {
  return (
    <TitleContainer>
      {icon && <Icon type={icon} />}
      {title}
    </TitleContainer>
  );
}

function Modal(props: ModalFuncProps) {
  return (
    <AntdModal
      footer={props.enableFooter ? undefined : null}
      {...props}
      title={<Title {...props} />}
    >
      {props.children}
    </AntdModal>
  );
}

Modal.defaultProps = {
  enableFooter: false,
  centered: true,
};

export default Modal;
