import React from 'react';
import { Link } from 'react-router-dom';

import masked from '~/common/masked';
import { EnumMappers } from '~/common';

const { RegistrationStatus, MarginSource } = EnumMappers;
const { toDate, toMoney } = masked;

export default {
  columns: [
    {
      accessor: 'id',
      name: 'Cod',
      props: {
        width: 60,
        Cell: (row) => (
          <div className="text-center">
            <Link href="/" to={`/backoffice/people/${row.original.id}`}>
              {row.original.id}
            </Link>
          </div>
        ),
      },
    },
    {
      accessor: 'name',
      name: 'Nome',
      props: {
        minWidth: 240,
      },
    },
    {
      accessor: 'registrationStatus',
      name: 'Situação',
      props: {
        minWidth: 110,
        Cell: (row) => (
          <div className="text-center">
            <RegistrationStatus value={row.original.registrationStatus} />
          </div>
        ),
      },
    },
    {
      accessor: 'registrationDate',
      name: 'Data Registro',
      props: {
        minWidth: 130,
        Cell: (row) => {
          if (row.original.registrationDate) {
            return toDate(row.original.registrationDate);
          }
          return null;
        },
      },
    },
    {
      accessor: 'marginLoan',
      name: 'Margem Empréstimo',
      props: {
        minWidth: 180,
        Cell: (row) => toMoney(row.original.marginLoan),
      },
    },
    {
      accessor: 'marginCard',
      name: 'Margem Cartão',
      props: {
        minWidth: 140,
        Cell: (row) => toMoney(row.original.marginCard),
      },
    },
    {
      accessor: 'isPrime',
      name: 'Cliente Prime',
      props: {
        minWidth: 180,
        Cell: (row) => <div className="text-center">{row.original.isPrime ? 'Sim' : 'Não'}</div>,
      },
    },
    {
      accessor: 'marginSource',
      name: 'Fonte Margem',
      props: {
        minWidth: 130,

        Cell: (row) => (
          <div className="text-center">
            <MarginSource value={row.original.marginSource} />
          </div>
        ),
      },
    },
    {
      accessor: 'marginExpirationDate',
      name: 'Validade Margem',
      props: {
        minWidth: 150,
        Cell: (row) => {
          if (row.original.marginExpirationDate) {
            return toDate(row.original.marginExpirationDate);
          }
          return null;
        },
      },
    },
  ],
};
