import React, { useCallback, useRef, useState } from 'react';
import { withStyles } from 'react-with-styles';

import usePresentationColumns from '~/components/Table/usePresentationColumns';
import { Table as ReactTable } from '~/components/Table/styled';
import { Spinner } from '~/components';
import { mergeDeep } from '~/common/mergeDeep';
import theme from '~/themes/aphroditeTheme/theme';

import VirtualizedBody from './VirtualizedBody';

import 'react-table/react-table.css';
import './Table.css';

const { css } = theme;

type TableProps = {
  virtualized?: boolean,
  isLoading: boolean,
  hidePagination: boolean,
  roles: string[],
  onPressUpdateEvent: () => void,
  items: any,
  minRows: number,
  pageSize: number,
  presentation: {
    columns: Array<{}>,
  },
  className: any,
  totalEntries: number,
  showPageSizeOptions: boolean,
};

type VirtualizedTableProps = TableProps & {
  fixedHeight: number,
  fixedWidth: number,
};

const styles = {
  thead: {
    borderBottom: '1px solid rgba(0, 40, 100, 0.12)',
    boxShadow: 'none',
  },
  theadTr: {
    color: 'rgb(154, 160, 172)',
    padding: '0.75rem',
    paddingBottom: '.5rem',
    paddingTop: '.5rem',
    textTransform: 'uppercase',
  },
  tr: {
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 40, 100, 0.12)',
    display: 'flex',
    padding: '0.75rem',
  },
};

function Empty({ children, styles }) {
  return (
    <div {...css(styles.status)}>
      <i>{children}</i>
    </div>
  );
}

const emptyStyles = ({ unit }) => ({
  status: {
    textAlign: 'center',
    padding: unit * 3,
  },
});

const NoDataComponent = withStyles(emptyStyles)(Empty);

export default function Table({
  pageSize,
  presentation,
  isLoading,
  className,
  ...props
}: TableProps) {
  const [pgSize, setPgSize] = useState(pageSize);

  const columns = usePresentationColumns(presentation);

  const getTheadProps = useCallback(() => ({ style: styles.thead }), []);
  const getTheadTrProps = useCallback(() => ({ style: styles.theadTr }), []);
  const getTrProps = useCallback(
    (state, rowInfo, column) => {
      const trValue = { style: { ...styles.tr } };

      if (props.virtualized) {
        trValue.style = { ...trValue.style, height: '35px' };
      }

      return !props.getTrProps
        ? trValue
        : mergeDeep(trValue, props.getTrProps(state, rowInfo, column));
    },
    [props],
  );

  return (
    <div className={className}>
      <Spinner spinning={isLoading} />
      <div>
        <ReactTable
          {...props}
          pageText="Página"
          ofText="de"
          nextText="Próximos"
          previousText="Anteriores"
          noDataText={props.noDataText || 'Nenhum Registro Encontrado'}
          rowsText="registros"
          withoutNoDataText
          multiSort
          pageSize={pgSize}
          onPageSizeChange={setPgSize}
          showPagination={!(props.items.length === 0 || props.hidePagination)}
          NoDataComponent={NoDataComponent}
          showPageJump
          defaultPageSize={10}
          data={props.items}
          getTheadProps={getTheadProps}
          getTheadTrProps={getTheadTrProps}
          getTrProps={getTrProps}
          columns={[...columns]}
          className="-highlight"
        />
      </div>
    </div>
  );
}

Table.Virtualized = function ({
  fixedHeight,
  fixedWidth,
  ...props
}: VirtualizedTableProps) {
  const TbodyComponent = useRef((props) => (
    <VirtualizedBody height={fixedHeight} width={fixedWidth} {...props} />
  ));
  return (
    <Table {...props} TbodyComponent={TbodyComponent.current} virtualized />
  );
};

Table.defaultProps = {
  items: [],
  minRows: 0,
  pageSize: 10,
  showPagination: true,
  showPageSizeOptions: true,
  presentation: {
    columns: [],
  },
  totalEntries: 0,
};
