import { useMemo, useState } from 'react';

import useLocalStorageObserver from '~/hooks/useLocalStorageObserver';
import moment from 'moment';

const TOKEN_KEY = 'authenticationToken';

export default function useIsAuthenticated() {
  const [token, setToken] = useState(localStorage.getItem(TOKEN_KEY));
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  const isExpired = useMemo(() => {
    try {
      const parsedExp = JSON.parse(atob(token.split('.')[1]))?.exp;
      return moment(parsedExp * 1000).isBefore(moment());
    } catch (e) {
      return null;
    }
  }, [token]);

  const handleLocalStorageChange = ({ newValue }: { key: string; newValue: any }) => {
    setToken(newValue);
    setIsAuthenticated(!!newValue);
  };

  const refreshToken = () => {
    setToken(localStorage.getItem(TOKEN_KEY));
  };

  useLocalStorageObserver(TOKEN_KEY, handleLocalStorageChange);

  return useMemo(
    () => ({
      isAuthenticated,
      token,
      isExpired,
      refreshToken,
    }),
    [token, isAuthenticated, isExpired],
  );
}
