import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useAwaitControl } from 'react-redux-await-control';

import { listReturnsParati, updateAccountParati } from '~/store/proposals/actions';
import { useDrawer } from '~/hooks/useDrawer';
import { TableColumns } from '~/ui/components';
import { MenuOptions } from '~/components';

import { ParatiAccount } from '~/typings/entities/Proposals';
import { ActionButton, CustomTable, TableTitle } from '../../Returns.styled';
import { AccountDetails } from './AccountDetails';

export type AccountTableProps = {
  contractId: string;
};

export function AccountTable({ contractId }: AccountTableProps) {
  const { openDrawer } = useDrawer();

  const listReturnsParatiControl = useAwaitControl(listReturnsParati);
  const updateAccountParatiControl = useAwaitControl(updateAccountParati);

  const returnsParatiLoading = listReturnsParatiControl.isRunning(contractId);
  const updateAccountParatiLoading = updateAccountParatiControl.isRunning();

  const { paratiAccount, paratiAccountReturns } = listReturnsParatiControl.result(contractId);

  const handleUpdateAccount = useCallback(() => {
    updateAccountParatiControl.start({ contractId, force: true });
  }, [contractId]);

  const handleDetailsClick = (account) => () => {
    const accountReturns = paratiAccountReturns.filter(
      (paratiAccountReturn) => paratiAccountReturn.accountId === account.paratiAccount,
    );

    openDrawer(
      'account-details',
      <AccountDetails contractId={contractId} account={account} accountReturns={accountReturns} />,
      {
        title: 'Retornos da conta',
        closable: true,
        width: 800,
      },
    );
  };

  const options = useMemo(
    () => [
      { label: 'Atualizar', onClick: handleUpdateAccount, disabled: updateAccountParatiLoading },
    ],
    [handleUpdateAccount, updateAccountParatiLoading],
  );

  const columns: TableColumns<ParatiAccount> = [
    { accessorKey: 'accountId', header: () => 'COD', minSize: 288 },
    { accessorKey: 'status', header: () => 'STATUS' },
    {
      accessorKey: 'creationDate',
      header: () => 'DATA',
      maxSize: 100,
      cell: (info) => {
        const date = info.getValue();
        return date ? moment(date).format('DD/MM/YYYY [às] HH:mm:ss') : '-';
      },
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <ActionButton onClick={handleDetailsClick(info.row.original)} icon="FileSearch" />
      ),
    },
    {
      id: 'actions',
      header: () => 'AÇÕES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: (info) => (
        <MenuOptions options={options} width="auto">
          <ActionButton icon="Dots" />
        </MenuOptions>
      ),
    },
  ];

  if (!paratiAccount) {
    return null;
  }

  return (
    <>
      <TableTitle>Conta</TableTitle>
      <CustomTable
        noItemsMessage="Nenhum retorno de conta encontrado"
        loading={returnsParatiLoading}
        data={[paratiAccount]}
        columns={columns}
      />
    </>
  );
}
