import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';

import { telli as telliApi } from '~/services';
import { handleHisconErrors } from '~/common/utils/errorHandler';
import Hiscon from '~/typings/entities/Hiscon';

export const getEnrollmentByUserId = createAsyncAction('ENROLLMENT_GET_BY_USER_ID', {
  initialValue: [],
  saveResult: true,
  context: {
    errorParser: () => [],
    responseParser: ({ data }) => data.resource,
    onRequestError: () => message.error('Erro ao tentar consultar matrículas'),
    request: ({ personId }) => telliApi.get(`admin/enrollments/${personId}`),
  },
});

export const getHisconByEnrollment = createAsyncAction('GET_HISCON_BY_ENROLLMENT_REQUEST', {
  saveResult: true,
  context: {
    request: ({ enrollmentId }) =>
      telliApi.get(`/admin/enrollments/${enrollmentId}/hiscon`, {
        responseType: 'blob',
      }),
    responseParser: (response) => {
      if (response.status !== 200) {
        throw new Error('Bad response from server.');
      }

      return response.data;
    },
    onRequestSuccess: () => message.success('Hiscon requisitado com sucesso.'),
    onRequestError: () => message.error('Falha ao requisitar Hiscon.'),
  },
});

export const uploadHisconByEnrollment = createAsyncAction('UPLOAD_HISCON_BY_ENROLLMENT_REQUEST', {
  context: {
    request: ({ enrollmentId, file, version }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filetype', version === 'old' ? 'EXTRACT' : 'EXTRACT2');
      return telliApi.post(`/admin/enrollments/${enrollmentId}/uploadhiscon`, formData);
    },
    additionalMeta: {
      errorMessage: 'Falha ao Fazer Upload de Hiscon.',
    },
    onRequestSuccess: () => message.success('Upload de Hiscon concluído com sucesso.'),
    onRequestError: (err: any) =>
      handleHisconErrors(err, { defaultMessage: 'Erro no upload do Hiscon', duration: 5 }),
  },
});

export const getHisconHistoryExtract = createAsyncAction('GET_HISCON_HISTORY_EXTRACT_REQUEST', {
  saveResult: true,
  initialValue: { entries: [] },
  context: {
    request: ({ enrollmentId }) => telliApi.get(`/admin/enrollments/${enrollmentId}/paycheckfile`),
    responseParser: ({ data: { resource } }) => ({
      ...resource,
      entries: resource?.entries?.map((data) => new Hiscon(data)) || [],
    }),
    onRequestError: () => {},
  },
});

export const requestHisconDownload = createAsyncAction('DOWNLOAD_HISCON_REQUEST', {
  saveResult: true,
  context: {
    request: ({ enrollmentId, paycheckId }) =>
      telliApi.get(`/admin/personcustom/people/${enrollmentId}/paycheckfile/${paycheckId}`, {
        responseType: 'blob',
      }),
    responseParser: (response) => URL.createObjectURL(response.data),
    onRequestError: () => message.error('Falha ao Baixar Arquivo.'),
  },
});

export const requestHisconUpdate = createAsyncAction('UPDATE_HISCON_REQUEST', {
  context: {
    request: ({ enrollmentId }) => telliApi.post(`/admin/enrollments/${enrollmentId}/updatehiscon`),
    onRequestSuccess: () => message.success('Solicitação enviada!'),
    onRequestError: () => message.error('Falha ao solicitar atualização'),
  },
});

export const getConcessionByEnrollment = createAsyncAction('GET_CONCESSION_BY_ENROLLMENT_REQUEST', {
  saveResult: true,
  context: {
    request: ({ enrollmentId }) =>
      telliApi.get(`/admin/enrollments/${enrollmentId}/concession`, {
        responseType: 'blob',
      }),
    responseParser: (response) => {
      if (response.status !== 200) {
        throw new Error('Bad response from server.');
      }

      return response.data;
    },
    onRequestSuccess: () => message.success('Carta de concessão requisitada com sucesso.'),
    onRequestError: () => message.error('Falha ao requisitar carta de concessão.'),
  },
});

export const uploadConcessionByEnrollment = createAsyncAction('UPLOAD_CONCESSION_REQUEST', {
  context: {
    request: ({ enrollmentId, file }) => {
      const formData = new FormData();
      formData.append('file', file);
      return telliApi.post(`/admin/enrollments/${enrollmentId}/uploadconcession`, formData);
    },
    additionalMeta: {
      errorMessage: 'Falha ao Fazer Upload da carta de concessão.',
    },
    onRequestSuccess: () => message.success('Upload de carta de concessão concluído com sucesso.'),
  },
});

export const getMedicalCertificateByEnrollment = createAsyncAction(
  'GET_MEDICAL_CERTIFICATE_BY_ENROLLMENT_REQUEST',
  {
    saveResult: true,
    context: {
      request: ({ enrollmentId }) =>
        telliApi.get(`/admin/enrollments/${enrollmentId}/medicalcertificate`, {
          responseType: 'blob',
        }),
      responseParser: (response) => {
        if (response.status !== 200) {
          throw new Error('Bad response from server.');
        }

        return response.data;
      },
      onRequestSuccess: () => message.success('Perícia médica requisitada com sucesso.'),
      onRequestError: () => message.error('Falha ao requisitar perícia médica.'),
    },
  },
);

export const uploadMedicalCertificateByEnrollment = createAsyncAction(
  'UPLOAD_MEDICAL_CERTIFICATE_REQUEST',
  {
    context: {
      request: ({ enrollmentId, medicalCertificate, medicalCertificateDate }) => {
        const formData = new FormData();

        formData.append('file', medicalCertificate);
        formData.append('medicalCertificateDate', medicalCertificateDate);

        return telliApi.post(
          `/admin/enrollments/${enrollmentId}/uploadmedicalcertificate`,
          formData,
        );
      },
      additionalMeta: {
        errorMessage: 'Falha ao fazer Upload de perícia médica.',
      },
      onRequestSuccess: () => message.success('Upload de perícia médica concluído com sucesso.'),
    },
  },
);
