// @flow
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import useDimensions from '~/hooks/useDimensions';
import Timer from '~/screens/DistributedContracts/Timer';
import Table from '~/components/Table';
import OperationStepType from '~/typings/enums/OperationStepType';
import { CONTRACT_QUEUE_STATUS } from '~/typings/enums/ContractQueueStatus';
import LoanType from '~/typings/enums/LoanType';
import toDate from '~/common/masked/toDate';

import { QueuedContract } from '~/typings/entities/contract';
import { PROFILE_FILTER_NAMES } from '~/screens/DistributedContracts/components/ProfileConfig/ProfileConfig';

type TableProps = {
  contracts: QueuedContract[];
};

const { FGTS } = CONTRACT_QUEUE_STATUS;

export function ContractTable({ contracts }: TableProps) {
  const containerRef = useRef(null);
  const { width } = useDimensions(containerRef.current);

  const { current: presentation } = useRef({
    columns: [
      {
        name: 'COD',
        props: {
          width: width * 0.1,
          Cell: (row) => {
            const { contractId, operationStepType } = row.original;

            return (
              <div className="text-center">
                <Link
                  href="/"
                  target="_blank"
                  to={`/backoffice/contracts/${contractId}?panel=${
                    FGTS === operationStepType ? 3 : 1
                  }`}
                >
                  {row.original.contractId}
                </Link>
              </div>
            );
          },
        },
      },
      {
        name: 'DATA',
        props: {
          width: width * 0.1,
          Cell: (row) => <div className="text-center">{toDate(row.original.contractDate)}</div>,
        },
      },
      {
        name: 'PRODUTO',
        props: {
          width: width * 0.1,
          Cell: (row) => <div className="text-center">{LoanType(row.original.loanType)}</div>,
        },
      },
      {
        name: 'CLIENTE',
        accessor: 'personName',
        props: {
          width: width * 0.25,
        },
      },
      {
        name: 'STEP',
        props: {
          Cell: (row) => OperationStepType(row.original.operationStepType),
        },
      },
      {
        name: 'PERFIL',
        props: {
          width: width * 0.1,
          Cell: (row) => (
            <div className="text-center">
              {PROFILE_FILTER_NAMES?.[row.original.kycStatus] || '-'}
            </div>
          ),
        },
      },
      {
        name: 'TEMPO PARA CONFERIR',
        props: {
          width: width * 0.15,
          Cell: (row) => <Timer endTime={row.original.endDate} />,
        },
      },
    ],
  });

  return <Table ref={containerRef} presentation={presentation} items={contracts} hidePagination />;
}
