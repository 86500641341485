import React, { useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { notification } from 'antd';

import {
  Installment,
  OpportunityListItem,
  OpportunitySimulation,
} from '~/typings/entities/Opportunity';
import LoanType from '~/typings/enums/LoanType';
import { prepareContract, simulateOpportunity } from '~/store/opportunities/actions';
import { getPersonDetail } from '~/store/people/actions';
import { themeToggleColor } from '~/common/utils/theme';
import toMoney from '~/common/masked/toMoney';
import toPercentage from '~/common/masked/toPercentage';
import { useDrawer } from '~/hooks/useDrawer';
import {
  Alert,
  Button,
  Divider,
  Typography,
  withStepperProps,
  Flex,
  Shimmer,
} from '~/ui/components';
import {
  Collapsible,
  CurrencyInput,
  ChildrenDrawer,
  Loading,
  DownloadNodeModal,
} from '~/components';

import formatName from '~/common/formatName';
import { useModal } from '~/hooks/useModal';
import { getDeeplink } from '~/store/formalization/actions';
import { OpportunityBody, OpportunityContainer, OpportunityFooter } from '../../Opportunity.styled';
import { SimulateButton } from './Details.styled';
import { Resume } from '../Resume/Resume';

type DetailsProps = {
  opportunity: OpportunityListItem;
  simulationAlert?: boolean;
  setSimulationAlert?: (value: boolean) => void;
  previousSimulation?: { contractValue: number; loanType: string };
};

export const Details = withStepperProps<DetailsProps>(
  ({ stepper, opportunity, simulationAlert, setSimulationAlert, previousSimulation }) => {
    const [editable, setEditable] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [simulationValue, setSimulationValue] = useState({
      current: opportunity?.loanMaxValue || 0,
      previous: opportunity?.loanMaxValue || 0,
    });

    const personControl = useAwaitControl(getPersonDetail);
    const person = personControl.result();

    const getDeeplinkControl = useAwaitControl(getDeeplink);

    const prepareContractControl = useAwaitControl(prepareContract);
    const prepareContractLoading = prepareContractControl.isRunning();
    const prepareContractSuccess = prepareContractControl.isSuccessful();
    const prepareContractFailure = prepareContractControl.hasFailure();
    const prepareContractResult: OpportunitySimulation = prepareContractControl.result();

    const simulateControl = useAwaitControl(simulateOpportunity);
    const simulateLoading = simulateControl.isRunning();
    const simulateSuccess = simulateControl.isSuccessful();
    const simulateFailure = simulateControl.hasFailure();
    const simulateResult = simulateControl.result();

    const { toStepById } = stepper;
    const { setConfig, closeDrawer } = useDrawer();
    const { openModal } = useModal();
    const theme = useTheme();
    const buttonColor = themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondary' });
    const editButtonColor = themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' });

    const loading = useMemo(
      () => simulateLoading || prepareContractLoading,
      [simulateLoading, prepareContractLoading],
    );

    const formatDate = (date: string, format = 'DD/MM/YYYY') =>
      date ? moment(date).format(format) : '';

    const getTotalAmount = (simulation: OpportunitySimulation) => {
      if (!simulation?.installments) return 0;
      return simulation?.installments.reduce((acc, { totalAmount }) => acc + totalAmount, 0);
    };

    const getTaxAmount = (simulation: OpportunitySimulation) => {
      if (!simulation?.installments) return 0;
      const totalAmount = getTotalAmount(simulation);
      return totalAmount - simulation.contractValue;
    };

    const fetchSimulation = (contractValue: number) => {
      simulateControl.start({
        agreementId: person?.agreementId,
        enrollmentId: opportunity?.enrollmentId,
        loanType: opportunity?.loanType,
        contractValue,
      });
    };

    const handleNextStep = () => {
      toStepById('success');
    };

    const handleChangeSimulationValue = (value: number) => {
      setSimulationValue({ ...simulationValue, current: value });
    };

    const handleConfirmSimulationValue = () => {
      getDeeplinkControl.clear();
      prepareContractControl.clear();
      setSimulationValue((values) => {
        fetchSimulation(values.current);
        return { previous: values.current, current: values.current };
      });
      setEditable(false);
    };

    const handleEditableButton = () => {
      if (simulationValue.current !== simulationValue.previous) {
        setSimulationValue((values) => ({ ...values, current: values.previous }));
      }

      setEditable((value) => !value);
    };

    const handleDownload = () => {
      const name = formatName(person?.name, 2);
      const date = moment().format('DD-MM-YYYY');
      const fileName = `Oportunidade FGTS - ${name} - ${date}`;

      openModal(
        <DownloadNodeModal fileName={fileName}>
          <Resume contract={prepareContractResult} />
        </DownloadNodeModal>,
        {
          closable: true,
          maskClosable: false,
          noPadding: true,
          width: 460,
        },
      );
    };

    useEffect(() => {
      setConfig('opportunity-details', {
        maskClosable: !loading,
        backButton: false,
        extraAction: (
          <Button
            variant="text"
            size="sm"
            iconOnly
            icon="Download"
            title="Baixar resumo"
            disabled={loading}
            onClick={handleDownload}
          />
        ),
      });
    }, [loading]);

    useEffect(() => {
      if (simulateSuccess && !prepareContractSuccess) {
        prepareContractControl.start(simulateResult);
      } else if (prepareContractSuccess && simulateSuccess) {
        simulateControl.clear();
      }
    }, [simulateSuccess, prepareContractSuccess]);

    useEffect(() => {
      const contractValue = prepareContractResult?.contractValue;
      const currentValue = simulationValue?.current;

      if (prepareContractSuccess && contractValue !== currentValue) {
        setSimulationValue({ current: contractValue, previous: contractValue });

        const description =
          currentValue < contractValue
            ? 'O valor foi alterado para o mínimo permitido.'
            : 'O valor teve uma pequena alteração para se ajustar a melhor proposta.';

        if (simulateSuccess) {
          notification.warning({
            message: 'Valor alterado',
            description,
            duration: 5,
            top: 80,
          });
        }
      }
    }, [prepareContractSuccess]);

    useEffect(() => {
      if (simulateFailure || prepareContractFailure) {
        toStepById('error');
      }
    }, [simulateFailure, prepareContractFailure]);

    useEffect(() => {
      if (!prepareContractResult && !simulationAlert) {
        fetchSimulation(opportunity?.loanMaxValue);
      }
    }, [simulationAlert]);

    return (
      <>
        {simulationAlert ? (
          <OpportunityContainer loading={loading ? 1 : 0} className="opportunity-container">
            <OpportunityBody>
              <Loading />
            </OpportunityBody>
          </OpportunityContainer>
        ) : (
          <OpportunityContainer loading={loading ? 1 : 0} className="opportunity-container">
            <OpportunityBody>
              <Flex direction="column" gap={16} pv={22}>
                <div>
                  <Typography type="bodyMedium">Valor que o cliente vai receber</Typography>

                  <Flex gap={8} align="center">
                    {editable ? (
                      <CurrencyInput
                        value={simulationValue?.current || 0}
                        onChange={handleChangeSimulationValue}
                        error={
                          simulationValue?.current > opportunity?.loanMaxValue &&
                          `O valor máximo é: ${toMoney(opportunity?.loanMaxValue)}`
                        }
                      />
                    ) : (
                      <Typography type="headingH5" weight={600}>
                        {toMoney(simulationValue?.current) || '-'}
                      </Typography>
                    )}

                    <Flex>
                      <Button
                        customColor={editButtonColor}
                        variant="text"
                        size="sm"
                        rounded
                        iconOnly
                        icon={editable ? 'Close' : 'Pencil'}
                        onClick={handleEditableButton}
                        disabled={loading}
                        title={editable ? 'Cancelar' : 'Editar'}
                      />

                      {editable && (
                        <SimulateButton
                          disabled={simulationValue?.current > opportunity?.loanMaxValue}
                          title="Confirmar"
                          onClick={handleConfirmSimulationValue}
                        />
                      )}
                    </Flex>
                  </Flex>
                </div>

                <Flex gap={8} direction="column" pv={8}>
                  <Typography type="bodyMedium" weight={600}>
                    Previsão de Pagamento
                  </Typography>

                  {loading && <Shimmer width={210} height={20} />}

                  {!loading && prepareContractResult ? (
                    <Typography type="bodySmall">
                      Entre {formatDate(prepareContractResult?.nearEstimatedEndDate) || '-'} e{' '}
                      {formatDate(prepareContractResult?.farEstimatedEndDate) || '-'}
                    </Typography>
                  ) : (
                    <Typography type="bodySmall">-</Typography>
                  )}
                </Flex>

                <Divider />

                <Typography type="paragraphLarge" weight={500}>
                  Detalhes da antecipação
                </Typography>

                <Flex gap={8} direction="column" mt={8} mb={4} width="100%">
                  <Typography type="bodyMedium" weight={600}>
                    Total retirado do FGTS
                  </Typography>

                  {loading ? (
                    <Shimmer width={85} height={20} />
                  ) : (
                    <Typography type="bodySmall">
                      {prepareContractResult ? toMoney(getTotalAmount(prepareContractResult)) : '-'}
                    </Typography>
                  )}
                </Flex>

                <Flex gap={8} direction="column" mt={4} mb={8} width="100%">
                  <Typography type="bodyMedium" weight={600}>
                    Juros
                  </Typography>

                  {loading ? (
                    <Shimmer width={85} height={20} />
                  ) : (
                    <Typography type="bodySmall">
                      {prepareContractResult ? toMoney(getTaxAmount(prepareContractResult)) : '-'}
                    </Typography>
                  )}
                </Flex>

                <Divider />

                <Collapsible
                  activeKey={collapsed && !loading ? '1' : null}
                  onChange={(key) => setCollapsed(key.includes('1'))}
                >
                  <Collapsible.Panel
                    key="1"
                    header={
                      <Typography type="bodyLarge" weight={600}>
                        Detalhes por parcela
                      </Typography>
                    }
                  >
                    <Alert status="info">
                      O valor retirado em cada parcela equivale a quanto o cliente recebe
                      adicionando juros e IOF.
                    </Alert>

                    {prepareContractResult?.installments?.map((installment: Installment) => (
                      <Flex gap={8} direction="column" pv={8} key={installment.installmentNumber}>
                        <Flex gap={8} align="center">
                          <Typography type="bodyMedium" weight={600}>
                            Parcela {installment.installmentNumber}
                          </Typography>

                          <Typography type="bodyXSmall" element="span">
                            {formatDate(installment.dueDate)}
                          </Typography>
                        </Flex>

                        <Flex gap={8} align="center">
                          <Typography type="bodySmall">Total retirado</Typography>

                          <Typography type="bodySmall" weight={600}>
                            {toMoney(installment.totalAmount)}
                          </Typography>
                        </Flex>
                      </Flex>
                    ))}
                  </Collapsible.Panel>
                </Collapsible>

                <Flex gap={4} direction="column" width="100%">
                  <Flex align="center" gap={8} width="100%">
                    <Typography type="bodySmall">IOF:</Typography>

                    {loading ? (
                      <Shimmer width={65} height={18} />
                    ) : (
                      <Typography type="bodySmall" weight={600}>
                        {toMoney(prepareContractResult?.iofValue || 0)}
                      </Typography>
                    )}
                  </Flex>

                  <Flex align="center" gap={8} width="100%">
                    <Typography type="bodySmall">Taxa de juros:</Typography>

                    {loading ? (
                      <Shimmer width={170} height={18} />
                    ) : (
                      <Typography type="bodySmall" weight={600}>
                        {toPercentage(prepareContractResult?.nominalRate) || '-'} (a.m) |{' '}
                        {toPercentage(prepareContractResult?.annualNominalRate) || '-'} (a.a)
                      </Typography>
                    )}
                  </Flex>

                  <Flex align="center" gap={8} width="100%">
                    <Typography type="bodySmall">Custo efetivo total (CET):</Typography>

                    {loading ? (
                      <Shimmer width={172} height={18} />
                    ) : (
                      <Typography type="bodySmall" weight={600}>
                        {toPercentage(prepareContractResult?.effectiveRate) || '-'} (a.m) |{' '}
                        {toPercentage(prepareContractResult?.totalEffectiveCost) || '-'} (a.a)
                      </Typography>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </OpportunityBody>

            <OpportunityFooter>
              <Button
                customColor={buttonColor}
                loading={loading || editable}
                fullWidth
                rounded
                onClick={handleNextStep}
              >
                Avançar
              </Button>
            </OpportunityFooter>
          </OpportunityContainer>
        )}

        <ChildrenDrawer
          title="Uma simulação já foi iniciada anteriormente"
          visible={simulationAlert}
          container=".ant-drawer-body"
        >
          <Flex direction="column" gap={24} pv={24} ph={24}>
            <Typography type="bodyMedium" center color="element.secondary" element="p">
              Já existe uma simulação de{' '}
              <Typography.Bold weight={600} color="element.primary">
                {LoanType(previousSimulation?.loanType)}
              </Typography.Bold>
              {', '}
              que foi iniciada anteriormente com um valor de
              <Typography.Bold weight={600} color="element.primary">
                {' '}
                {toMoney(previousSimulation?.contractValue)}
              </Typography.Bold>
              .
            </Typography>

            <Alert status="warning">
              Ao optar por uma nova simulação, a anterior será substituída pela nova.
            </Alert>

            <Flex gap={8} direction="column" width="100%">
              <Button color="black" fullWidth rounded onClick={() => setSimulationAlert(false)}>
                Refazer simulação
              </Button>

              <Button
                color="black"
                variant="outline"
                rounded
                fullWidth
                onClick={() => closeDrawer('opportunity-details')}
              >
                Fechar
              </Button>
            </Flex>
          </Flex>
        </ChildrenDrawer>
      </>
    );
  },
);
