import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';

import { getQueryParams } from '~/common';
import { telli } from '~/services';
import dialogErrors from '~/common/dialogErrors';
import { handleErrors } from '~/common/utils/errorHandler';

const formatParam = (param) => (Array.isArray(param) ? param.join(',') : param);

export const searchContracts = createAsyncAction('SEARCH_CONTRACTS', {
  saveResult: true,
  initialValue: {},
  context: {
    responseParser: ({ data }) => data.resource,
    request: ({ tagTypes, loanTypes, ...params }) =>
      telli.get(`/admin/contractcustom/contracts`, {
        params: {
          tagTypes: formatParam(tagTypes),
          loanTypes: formatParam(loanTypes),
          ...params,
        },
      }),
  },
});

export const simulateRefin = createAsyncAction('SIMULATE_REFIN', {
  saveResult: true,
  context: {
    request: ({ id, ...data }) =>
      telli.post(`/admin/contractcustom/contracts/${id}/simulate`, data),
  },
});

export const updateContractValues = createAsyncAction('UPDATE_CONTRACT_VALUES', {
  context: {
    request: ({ id, ...data }) =>
      telli.post(`/admin/contractcustom/contracts/${id}/updatecontractvalues`, data),
  },
});

export const getUserActiveToQueue = createAsyncAction('GET_USER_ACTIVE_TO_QUEUE', {
  initialValue: null,
  saveResult: true,
  context: {
    request: ({ userId }) => telli.get(`/admin/contractcustom/useractivetoqueue/${userId}`),
  },
});

export const checkUserContractQueueStatus = createAsyncAction('CHECK_USER_CONTRACT_QUEUE_STATUS', {
  initialValue: false,
  saveResult: true,
  context: {
    request: ({ userId }) => telli.get(`/admin/contractcustom/checkactiveuserqueue/${userId}`),
  },
});

export const updateActiveUserQueue = createAsyncAction('UPDATE_ACTIVE_USER_QUEUE', {
  context: {
    request: ({ userId, ...rest }) =>
      telli.post(`/admin/contractcustom/updateactiveuserqueue/${userId}`, rest),
  },
});

export const activateUserContractQueueStatus = createAsyncAction('ACTIVATE_USER_CONTRACT_QUEUE', {
  context: {
    request: (data) => {
      const { userId } = data;
      return telli.post(`/admin/contractcustom/activeuserqueue/${userId}`, data);
    },
  },
});

export const deactivateUserContractQueueStatus = createAsyncAction(
  'DEACTIVATE_USER_CONTRACT_QUEUE',
  {
    context: {
      request: ({ userId }) => telli.post(`/admin/contractcustom/desativeuserqueue/${userId}`),
    },
  },
);

export const listQueueContracts = createAsyncAction('LIST_QUEUE_CONTRACTS', {
  initialValue: [],
  saveResult: true,
  context: {
    request: () => telli.get(`/admin/contractcustom/contractstoqueuebyuser`),
    errorParser: () => [],
  },
});

export const getContractQueueInformation = createAsyncAction('GET_CONTRACT_QUEUE_INFO', {
  initialValue: { contractAmount: 0, contractChecked: 0, inConference: 0 },
  saveResult: true,
  context: {
    request: (payload) =>
      telli.get(`/admin/contractcustom/contractQueueInformation`, {
        params: {
          type: payload?.type,
          kycStatus: payload?.kycStatus,
        },
      }),
    errorParser: () => [],
  },
});

export const getContractQueueInformationManual = createAsyncAction(
  'GET_CONTRACT_QUEUE_INFO_MANUAL',
  {
    initialValue: { contractAmount: 0, contractChecked: 0, inConference: 0 },
    saveResult: true,
    context: {
      request: () => telli.get('/admin/contractcustom/contractQueueInformation'),
      errorParser: () => [],
    },
  },
);

export const getContractToQueue = createAsyncAction('GET_CONTRACT_TO_QUEUE', {
  context: {
    request: (data) => telli.post('/admin/contractcustom/contractstoqueue', data),
  },
});

export const clearQueue = createAsyncAction('CLEAR_CONTRACT_QUEUE', {
  context: {
    request: () => telli.post('/admin/contractcustom/clearqueue'),
  },
});

export const checkQueueContract = createAsyncAction('CHECK_QUEUE_CONTRACT', {
  context: {
    request: (params) =>
      telli.post(`/admin/contractcustom/checkcontracttoqueue?${getQueryParams(params)}`, {}),
  },
});

export const updateEventReturn = createAsyncAction('UPDATE_EVENT_RETURN', {
  context: {
    request: ({ contractId, eventId }) =>
      telli.post(`/admin/contractcustom/contracts/${contractId}/reprocesseventparati`, {
        paratiReturnEventIds: [eventId],
      }),
  },
});

export const listReturnsParati = createAsyncAction('LIST_RETURNS_PARATI', {
  saveResult: true,
  initialValue: {
    paratiAccount: null,
    paratiAccountReturns: null,
    paratiPreProposalReturns: [],
    paratiPreProposals: [],
    paratiProposalReturns: [],
    paratiProposals: [],
  },
  context: {
    request: ({ contractId }) =>
      telli.get(`/admin/contractcustom/contracts/${contractId}/consigneereturns`),
  },
});

export const checkContractInConference = createAsyncAction('CHECK_CONTRACT_IN_CONFERENCE', {
  initialValue: { inConference: false, user: null, contractQueue: null },
  saveResult: true,
  context: {
    request: ({ id }) => telli.get(`/admin/contractcustom/contracts/${id}/inconference`),
  },
});

export const forceSendToQueue = createAsyncAction('FORCE_SEND_CONTRACT_TO_QUEUE', {
  context: {
    request: ({ id }) =>
      telli.post(`/admin/contractcustom/forcesendcontracttoqueue?contractId=${id}`),
  },
});

export const advanceRequestQueue = createAsyncAction('ADVANCE_REQUEST_QUEUE', {
  context: {
    resultValidator: (response) => response?.data?.resource?.length,
    request: (data) => telli.post('/admin/contractcustom/contractstoqueue', data),
  },
});

export const getContractLogs = createAsyncAction('GET_CONTRACT_LOGS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ id }) => telli.get(`/admin/contractcustom/contracts/${id}/log`),
    onRequestError: () => message.error('Erro ao tentar carregar histórico do contrato'),
    errorParser: () => [],
  },
});

export const generateDED = createAsyncAction('GENERATE_DED', {
  saveResult: true,
  context: {
    request: ({ contractId }) =>
      telli.get(`/admin/contractcustom/${contractId}/ded`, { responseType: 'blob' }),
    responseParser: (response) => response.data,
    onRequestError: (err: any) =>
      handleErrors(err, {
        defaultMessage: 'Não foi possível realizar a solicitação',
        duration: 5,
      }),
  },
});

export const getContractDetails = createAsyncAction('GET_CONTRACT_DETAILS', {
  saveResult: true,
  initialValue: {
    id: false,
    loanType: 'NEW',
  },
  context: {
    request: ({ id }) => telli.get(`/admin/contractloancustom/loans/${id}`),
    responseParser: (response, { id }) => ({
      ...response.data.resource,
      contractId: id,
    }),
  },
});

export const resendToPayment = createAsyncAction('RESEND_TO_PAYMENT', {
  saveResult: true,
  context: {
    request: (payload) =>
      telli.post(`/admin/contractcustom/contracts/${payload.contractId}/resubmitpayment`, payload),
  },
});

export const newResendToPayment = createAsyncAction('RESEND_TO_PAYMENT', {
  saveResult: true,
  context: {
    request: (payload) =>
      telli.post(`/admin/mtproposal/${payload.contractId}/resubmitpayment`, payload),
  },
});

export const getContractFiles = createAsyncAction('GET_CONTRACT_FILES');

export const getContractFile = createAsyncAction('GET_CONTRACT_FILE', {
  saveResult: true,
  initialValue: {},
  context: {
    request: ({ id, document, file: { type, documentType } }) =>
      telli.post(
        `/admin/contractcustom/contracts/${id}/${document}?type=${type}&documenttype=${documentType}`,
      ),
  },
});

export const getContractBundleFiles = createAsyncAction('GET_CONTRACT_BUNDLE_FILES', {
  saveResult: true,
  context: {
    request: ({ id }) =>
      telli.get(`/admin/contractcustom/contracts/${id}/contractfiles`, { responseType: 'blob' }),
    responseParser: (response) => response.data,
    onRequestSuccess: (file) => window.open(URL.createObjectURL(file)),
    onRequestError: () => message.error('Falha ao Baixar Arquivos.'),
  },
});

export const getContractInactiveFiles = createAsyncAction('GET_INACTIVE_CONTRACT_FILES', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ id }) => telli.get(`/admin/contractcustom/contracts/${id}/inactivefiles`),
    errorParser: () => [],
  },
});

export const updateFinancialInstitutionKey = createAsyncAction('UPDATE_FINANCIAL_INSTITUTION_KEY', {
  context: {
    request: (payload) =>
      telli.post(
        `/admin/contractloancustom/loans/${payload.contractId}/updatefinancialinstitutionkey`,
        payload,
      ),
    onRequestSuccess: () => message.success('Número do contrato atualizado com sucesso!'),
    onRequestError: () => message.error('Falha ao atualizar número do contrato'),
  },
});

export const updateFinancialInstitutionKeyPort = createAsyncAction(
  'UPDATE_FINANCIAL_INSTITUTION_KEY_PORT',
  {
    context: {
      request: (payload) =>
        telli.post(
          `/admin/contractloancustom/loans/${payload.contractId}/updatefinancialinstitutionkeyport`,
          payload,
        ),
      onRequestSuccess: () =>
        message.success('Número do contrato de refin de portabilidade atualizado com sucesso!'),
      onRequestError: () =>
        message.error('Falha ao atualizar número do contrato de refin de portabilidade'),
    },
  },
);

export const updateOperationStep = createAsyncAction('UPDATE_OPERATION_STEP', {
  context: {
    request: (payload) =>
      telli.post(`/admin/contractcustom/contracts/${payload.contractId}/operationstep`, payload),
    onRequestSuccess: () => message.success('Passo atualizado com sucesso!'),
    onRequestError: () => message.error('Falha ao atualizar a esteira'),
  },
});

export const fetchLoanTypes = createAsyncAction('LOAN_TYPES_FETCH_REQUEST', {
  initialValue: [],
  saveResult: true,
  context: {
    request: () => telli.get('/admin/contractcustom/loantypes'),
  },
});

export const fetchOperationStatusTypes = createAsyncAction('OPERATION_STATUS_TYPES_FETCH_REQUEST', {
  saveResult: true,
  initialValue: [],
  context: {
    request: () => telli.get('/admin/contractloancustom/operationstatustypes'),
  },
});

export const fetchLoanStatuses = createAsyncAction('LOAN_STATUSES_FETCH_REQUEST', {
  saveResult: true,
  initialValue: [],
  context: {
    request: () => telli.get('/admin/contractcustom/loanstatuses'),
    onRequestError: (e) => dialogErrors(e.messages),
  },
});

export const fetchOperationStepsTypes = createAsyncAction('OPERATION_STEPS_TYPES_FETCH_REQUEST', {
  initialValue: [],
  saveResult: true,
  context: {
    request: () => telli.get('/admin/contractloancustom/operationsteptypes'),
  },
});

export const cancelPreProposal = createAsyncAction('CANCEL_PREPROPOSAL_BY_CONTRACTS', {
  context: {
    request: (fileList) => {
      const formData = new FormData();

      const file = fileList[0];

      formData.append('file', file);

      return telli.post('/admin/contractcustom/cancelpreproposalbycontracts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    additionalMeta: {
      errorMessage: 'Falha ao Fazer cancelamento de pré proposta.',
    },
    onRequestSuccess: () => message.success('Sucesso no Upload de CSV.'),
  },
});

export const uploadContractFile = createAsyncAction('UPLOAD_PERSON_FILE', {
  context: {
    request: (payload) => {
      const { uploadReason, comment, file, id } = payload;

      const reason = uploadReason.enum;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('commentary', comment);
      formData.append('type', reason);

      return telli.post(`/admin/contractcustom/contracts/${id}/uploadfilepdf`, formData);
    },
    additionalMeta: {
      errorMessage: 'Falha ao enviar arquivos.',
    },
    onRequestSuccess: () => message.success('Sucesso no upload de arquivo.'),
  },
});

export const getContractInsurance = createAsyncAction('GET_CONTRACT_INSURANCE', {
  initialValue: {},
  saveResult: true,
  context: {
    request: ({ id }) => telli.get(`/admin/contractcustom/insurance/${id}`),
    responseParser: ({ data }) => data.resource,
  },
});

export const validateKYC = createAsyncAction('VALIDATE_KYC', {
  context: {
    request: ({ contractId }) => telli.post(`/admin/contractcustom/${contractId}/validatekyc`),
    onRequestError: () => message.error('Falha ao validar KYC'),
    onRequestSuccess: () => message.success('KYC validado com sucesso!'),
  },
});

export const downloadAuditTrail = createAsyncAction('DOWNLOAD_AUDIT_TRAIL', {
  saveResult: true,
  context: {
    request: ({ contractId }) =>
      telli.get(`/admin/audit-trail/download/${contractId}`, {
        responseType: 'blob',
      }),
    onRequestError: () => message.error('Falha ao baixar trilha de auditoria'),
    responseParser: (res) => res,
  },
});

export const downloadPortabilityTerm = createAsyncAction('DOWNLOAD_PORTABILITY_TERM', {
  saveResult: true,
  context: {
    request: ({ contractId }) =>
      telli.get(`/admin/portability-term/download/${contractId}`, {
        responseType: 'blob',
      }),
    onRequestError: () => message.error('Falha ao baixar o termo de portabilidade'),
    responseParser: (res) => res,
  },
});
