import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavbarItem, SubItem, Submenu, SubmenuWrapper } from '~/ui/components/Menu/Menu.styled';

import useOutsideClickEffect from '~/common/useOutsideClickEffect';
import { useAwaitControl } from 'react-redux-await-control';
import { getReports } from '~/store/utilities/actions';
import { Container, ReportButton } from './Reports.styled';

type SettingsProps = {
  trigger?: 'hover' | 'click';
};

type Report = {
  id: number;
  name: string;
  url: string;
};

export function Reports({ trigger = 'hover' }: SettingsProps) {
  const containerRef = useRef(null);
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(false);
  const getReportsControl = useAwaitControl(getReports);
  const reports: Report[] = getReportsControl.result();

  const close = () => {
    setClosing(true);
    setTimeout(() => {
      setOpened(false);
      setClosing(false);
    }, 300);
  };

  const handleItemClick = useCallback(() => {
    if (trigger === 'click') {
      setOpened(true);
    }
  }, [opened]);

  useOutsideClickEffect(containerRef, () => {
    if (trigger === 'click' && opened) {
      close();
    }
  });

  useEffect(() => {
    getReportsControl.start();
  }, []);

  if (!reports?.length) {
    return null;
  }

  return (
    <Container trigger={trigger} ref={containerRef}>
      <NavbarItem
        className={`nav-item ${opened ? 'open' : ''} ${closing ? 'closing' : ''}`}
        onClick={handleItemClick}
        trigger={trigger}
      >
        <ReportButton data-testid="nav-bar-settings-button" />

        <SubmenuWrapper>
          <Submenu>
            {reports?.map((report) => (
              <SubItem
                key={report.id}
                to={`/backoffice/reports?report=${report.id}`}
                onClick={close}
              >
                {report.name}
              </SubItem>
            ))}
          </Submenu>
        </SubmenuWrapper>
      </NavbarItem>
    </Container>
  );
}
