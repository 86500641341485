import React from 'react';
import styled from 'styled-components';
import { Button as AntButton, DatePicker as AntDatePicker } from 'antd';

export const ContainerModal = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

export const Button = styled(AntButton)`
  width: calc(40% - 16px);
`;

export const DatePicker = styled(AntDatePicker)`
  width: 50%;
`;

export const Text = styled.div`
  margin-bottom: 16px;
  text-align: center;
`;

export const TextPink = styled.div`
  cursor: pointer;
  margin-bottom: 16px;
  text-align: center;
  display: inline;
  text-decoration: underline;
  color: ${({ theme }) => theme.primaryColorDarkest};
`;

export const TextUppercase = styled.div`
  display: inline;
  text-transform: uppercase;
  font-weight: 700;
`;

export const HighlightedText = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: underline;
`;
