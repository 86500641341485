import styled, { css } from 'styled-components';

export const SearchContainer = styled.div<{ collapse: boolean }>`
  flex: 1;
  max-width: 420px;
  min-width: 350px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.divider.primary};
  transition: all 0.3s ease-in-out, overflow 1s ease-in-out;
  position: relative;

  @media (max-width: 1255px) {
    ${({ collapse }) =>
      collapse &&
      css`
        max-width: 0;
        min-width: 0;
        border-right: none;
        overflow: hidden;
      `}

    &.collapsed {
      overflow: hidden;
    }
  }
`;

export const SearchHeader = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
  padding: 32px 32px 8px 32px;
  position: relative;
  z-index: 99;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px 32px;
  align-items: center;
  position: absolute;
  inset: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0.5;

  svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.brand.primary};
  }
`;

export const ResultsContainer = styled.div`
  overflow: auto;
  height: calc(100% - 163px);
`;
