import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';
import { endpoint } from '~/store';

export const getHiscon = createAsyncAction('HISCON_GET_REQUEST', {
  saveResult: true,
  context: {
    request: ({ id }) =>
      telli.get(`${endpoint}/admin/personcustom/people/${id}/hiscon`, {
        responseType: 'blob',
      }),
    responseParser: (response) => {
      if (response.status !== 200) {
        throw new Error('Bad response from server.');
      }

      return response.data;
    },
    onRequestSuccess: () => message.success('Hiscon requisitado com sucesso.'),
    onRequestError: () => message.error('Falha ao requisitar Hiscon.'),
  },
});
