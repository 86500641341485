import styled from 'styled-components';
import { Icon, Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import React from 'react';

export const ConfigContent = styled.div`
  border-radius: ${(props) => props.theme.cardBorderRadius};
  background-color: #fff;
  max-width: 355px;
  width: 100%;
  height: 78px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
`;

export const UserConfig = styled.div`
  margin-left: 14px;
`;

export const ConfigTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.accentColor};
  margin-bottom: 2px;
`;

export const ConfigSetup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ConfigLabel = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.accentColor};
  margin-bottom: 0;
`;

export const IconStyled = styled(Icon)`
  font-weight: 600;
  padding: 8px;
  border-radius: 100%;
  background: ${(props) => props.theme.grayExtraLight};
  color: ${({ theme, active }) => (active ? theme.successColorDark : theme.primaryColor)};
  transition: all 0.3s ease;

  border: 1px solid
    ${({ theme, active }) => (active ? `${theme.successColorDark}` : `${theme.grayExtraLight}`)};

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const EnableContractSwitch: React.FunctionComponent<SwitchProps> = styled(Switch).attrs(
  () => ({
    size: 'small',
    style: {
      marginLeft: 10,
      border: '1px solid #d9d9d9',
      backgroundColor: 'transparent',
    },
  }),
)`
  &::after {
    background-color: ${({ theme }) => theme.grayMid} !important;
  }

  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.successColorDark} !important;

    &::after {
      background-color: #fff !important;
    }
  }
`;
