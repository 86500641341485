// @flow
import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { isUndefined } from 'underscore';

import { DownloadConcession } from '~/components';
import { Action } from '~/components/PersonActions/Actions';
import { uploadConcession } from '~/store/people/actions';

type ConcessionActionsProps = {
  disableUpload: boolean,
  personId: number,
  agreementName: string,
};

export const ConcessionContext = React.createContext({
  personId: undefined,
});

function ConcessionActions(props: ConcessionActionsProps) {
  const { personId, agreementName } = props;
  const dispatch = useDispatch();
  const inputEl = useRef(null);

  const onPressInputFile = useCallback(() => {
    if (inputEl.current) {
      inputEl.current.click();
    }
  }, []);

  const onPressUploadConcession = useCallback((ev) => {
    const reader = new FileReader();
    const file = ev.target.files[0];

    if (isUndefined(file)) {
      return;
    }

    reader.readAsDataURL(file);
    reader.onload = () => {
      inputEl.current.value = null;
      dispatch(uploadConcession.start({ file, personId }));
    };
  });

  if (agreementName !== 'INSS') return null;
  return (
    <ConcessionContext.Provider
      value={{
        personId,
      }}
    >
      <Action
        icon="diff"
        title="Carta de concessão"
        placement="topRight"
        // style={css(styles.action)}
        subActions={[
          <DownloadConcession title="Abrir" personId={personId} agreementName={agreementName} />,
          props.disableUpload ? null : { name: 'Upload', onPress: onPressInputFile },
        ]}
      />
      <input
        ref={inputEl}
        type="file"
        accept="application/pdf"
        onChange={onPressUploadConcession}
        style={{ display: 'none' }}
      />
    </ConcessionContext.Provider>
  );
}

export default compose(React.memo)(ConcessionActions);
