import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const SidebarContainer = styled.div`
  height: calc(100vh - 73px);
  left: 0;
  position: fixed;
  z-index: 999;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  transition: all 0.2s;
  width: 336px;

  & ~ .ant-layout-content {
    margin-left: 336px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 0;
    width: 0;
    min-width: 0;

    & ~ .ant-layout-content {
      margin-left: 0;
    }
  }
`;

export const SidebarContent = styled.div`
  padding: 26px 0;
  height: 100%;
  overflow-y: auto;

  .ant-collapse-header {
    display: none;
  }

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-content-box {
    padding: unset;
    background: ${themeColor('background.primary', { dark: 'background.secondary' })};
    overflow-x: auto;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #999;
    }
  }
`;

export const MobileSidebar = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 100%;
    height: max-content;
    position: relative;
    background-color: transparent;
    margin-top: -12px;
    display: block;

    .side-actions,
    .other-options {
      display: none;
    }
  }
`;
