import styled, { css, keyframes } from 'styled-components';

const bounceDelay = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const Bounce = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.primaryAlt};
  ${({ inverted }) =>
    inverted &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.secondaryAlt};
    `}
`;

export const Overlay = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.primary}90;
  display: inline-flex !important;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 100;
  ${({ inverted }) => {
    if (inverted) {
      return css`
        background-color: rgba(9, 30, 66, 0.54);
      `;
    }
  }}
`;

export const Bounce1 = styled(Bounce)`
  animation-delay: -0.32s;
`;

export const Bounce2 = styled(Bounce)`
  animation-delay: -0.16s;
`;

export const Bounce3 = styled(Bounce)`
  animation-delay: 0s;
`;

export const Spinner = styled.div`
  width: 70px;
  text-align: center;

  & > div {
    width: 18px;
    height: 18px;
    /* background-color: #333; */

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${bounceDelay} 1.4s infinite ease-in-out both;
    animation: ${bounceDelay} 1.4s infinite ease-in-out both;
  }

  ${Bounce1} {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  ${Bounce2} {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  ${Bounce3} {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
`;
