import React, { useCallback, useEffect, useMemo, useState } from 'react';

import screenRegister from '~/hoc/screenRegister';
import { useModal } from '~/hooks/useModal';
import {
  Button,
  Filter,
  selectedFiltersType,
  SearchBar,
  AddUserModal,
  StatisticsCard,
} from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import { getUsers } from '~/store/permLevels/actions';
import {
  SearchBarContainer,
  TopArea,
  UserScreenContainer,
  FilterContent,
  Content,
} from './Users.styled';
import { MOCKED_FILTER_OPTIONS } from './Users.mocks';
import { UsersTable } from './components/UsersTable';

export function UsersScreen() {
  const [searchBarValue, setSearchBarValue] = useState('');
  const [search, setSearch] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchName, setSearchName] = useState('');
  const [filterValues, setFilterValues] = useState<selectedFiltersType>({});

  const { openModal } = useModal();
  const getUsersControl = useAwaitControl(getUsers);
  const usersResult = getUsersControl.result();

  const totalUsers = useMemo(() => usersResult?.total || 0, [usersResult]);

  const handleOnClickCreateUser = useCallback(() => {
    openModal(<AddUserModal />, {
      id: 'create-user',
      title: 'Cadastrar usuário',
      closable: true,
      width: 400,
    });
  }, [openModal]);

  const handleOnChangeFilter = useCallback((value) => {
    setFilterValues(value);
  }, []);

  const clearSearch: boolean = useMemo(() => {
    if (search && searchBarValue.length === 0) {
      setSearch('');
      setSearchName('');
      setSearchEmail('');
      return true;
    }
    return false;
  }, [search, searchBarValue]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        setSearch(searchBarValue);
      }
    },
    [searchBarValue],
  );

  useEffect(() => {
    if (search.length > 0) {
      if (!search.includes('@')) {
        setSearchName(search);
        setSearchEmail('');
      } else {
        setSearchEmail(search);
        setSearchName('');
      }
    }
  }, [search]);

  return (
    <UserScreenContainer>
      <TopArea>
        <Content>
          <StatisticsCard title="Total de membros" description={`${totalUsers} usuários`} />
        </Content>
        <Button
          title="Adicionar usuário"
          icon="Add"
          iconPosition="right"
          rounded
          variant="outline"
          color="black"
          onClick={handleOnClickCreateUser}
        />
      </TopArea>

      <SearchBarContainer>
        <FilterContent>
          <Filter
            label="Filtrar uma categoria"
            options={MOCKED_FILTER_OPTIONS}
            onChange={handleOnChangeFilter}
          />
        </FilterContent>
        <Content>
          <SearchBar
            placeholder="Buscar usuário por nome"
            onChange={(value) => setSearchBarValue(value)}
            onKeyPress={handleKeyPress}
          />
          <Button
            variant="outline"
            color="gray"
            rounded
            size="lg"
            icon="Search"
            onClick={() => setSearch(searchBarValue)}
          />
        </Content>
      </SearchBarContainer>

      <UsersTable name={searchName} email={searchEmail} clearSearch={clearSearch} />
    </UserScreenContainer>
  );
}

export default screenRegister({
  screenName: 'Users',
  path: '/backoffice/users',
  headerTitle: 'Usuários',
  headerMenu: true,
  headerMenuPosition: 1,
})(UsersScreen);
