export default function SearchBy(data, search, key) {
  if (!search || search.length < 2) return data;
  return data.filter(item => {
    const selection = item[key];
    if (!selection) return false;
    const normalizedItem = selection
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    const normalizedSearch = search
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    return normalizedItem.includes(normalizedSearch);
  });
}
