// @flow

import React from 'react';
import moment from 'moment';
import { Container, Box, Image } from './styles';

type FileProps = {
  docDate: Date,
  blob: Blob,
};

function File(props: FileProps) {
  function onClick() {
    props.onClick([
      {
        src: props.blob,
        alt: `Enviado em ${moment(props.docDate).format('DD/MM/YYYY - HH:mm')}`,
      },
    ]);
  }
  return (
    <Container onClick={onClick}>
      <Box>
        <Image src={props.blob} />
      </Box>
      <span>Enviado em {moment(props.docDate).format('DD/MM/YYYY')}</span>
    </Container>
  );
}

export default File;
