/* @flow */

import { message } from '~/common';

export const SOLVE_BANK_ACCOUNT_REQUEST = 'SOLVE_BANK_ACCOUNT_REQUEST';
export const SOLVE_BANK_ACCOUNT_SUCCESS = 'SOLVE_BANK_ACCOUNT_SUCCESS';
export const SOLVE_BANK_ACCOUNT_FAILURE = 'SOLVE_BANK_ACCOUNT_FAILURE';

export function success(payload) {
  message.success('Pendência de conta bancária resolvida');
  return dispatch => {
    dispatch({
      type: SOLVE_BANK_ACCOUNT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  message.error('Falha ao resolver pendência');
  return dispatch => {
    dispatch({
      type: SOLVE_BANK_ACCOUNT_FAILURE,
      payload: resource,
    });
  };
}

export default function updateConsigneeStatus(params) {
  return dispatch => {
    dispatch({
      type: SOLVE_BANK_ACCOUNT_REQUEST,
      payload: params,
    });
  };
}
