import takeRequest from '~/store/takeRequest';
import { put, takeLatest } from 'redux-saga/effects';
import * as TagsActions from './actions';

export function* reloadListTagsAfterRemove({ meta }) {
  const payload = meta.payload.data;
  const actionId = `${payload.type}_${payload.externalId}`;
  yield put(TagsActions.listTags.start(payload, { actionId }));
}

export function* reloadListTagsAfterAdd({ meta }) {
  const {
    payload: { externalId, type },
  } = meta;
  const actionId = `${type}_${externalId}`;
  yield put(TagsActions.listTags.start({ externalId, type }, { actionId }));
}

export function* tagsSaga() {
  yield takeRequest(TagsActions.listTags);
  yield takeRequest(TagsActions.addTag);
  yield takeRequest(TagsActions.removeTag);
  yield takeRequest(TagsActions.deleteTag);
  yield takeRequest(TagsActions.listTagsTypes);

  yield takeLatest(TagsActions.removeTag.success.toString(), reloadListTagsAfterRemove);
  yield takeLatest(TagsActions.addTag.success.toString(), reloadListTagsAfterAdd);
}
