import React, { useCallback, useEffect } from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/pt_BR';

import 'moment/locale/pt-br';

import { disableToFutureDates } from '~/common';
import { FieldProps, DateRangeType } from '../Props';
import useTableFilterContext from '../useTableFilterContext';

import FieldWrapper from './FieldWrapper';

const { RangePicker } = DatePicker;

const styles = {
  range: {
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
};

export default function RangeDateField({
  definition: {
    title,
    defaultValue,
    startDateKey = 'startDate',
    endDateKey = 'endDate',
    normalize = (value) => value,
  }, index,
}: FieldProps<DateRangeType>) {
  const { changeValue } = useTableFilterContext();

  useEffect(() => {
    if (Array.isArray(defaultValue)) {
      changeValue(startDateKey, normalize(defaultValue[0]));
      changeValue(endDateKey, normalize(defaultValue[1]));
    }
  }, []);

  const onChange = useCallback(([startDate, endDate]) => {
    changeValue(startDateKey, normalize(startDate));
    changeValue(endDateKey, normalize(endDate));
  }, []);

  return (
    <FieldWrapper index={index}>
      {title}
      <RangePicker
        locale={locale}
        onChange={onChange}
        format="DD/MM/YYYY"
        disabledDate={disableToFutureDates}
        style={styles.range}
      />
    </FieldWrapper>
  );
}
