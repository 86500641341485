import { createAsyncAction } from 'react-redux-await-control';

import { getQueryParams } from '~/common';

import { gateway, telli } from '~/services';

export const listGenericLogs = createAsyncAction('LIST_GENERIC_LOGS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: (payload) => telli.get(`/admin/log/genericlogs?${getQueryParams(payload)}`),
  },
});

export const sendAuditLog = createAsyncAction('SEND_AUDIT_LOG', {
  context: {
    request: (data) => gateway.post(`/backoffice/audit/BACKOFFICE`, data),
  },
});
