import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';

import { telli } from '~/services';
import { handleErrors } from '~/common/utils/errorHandler';

export const login = createAsyncAction('LOGIN', {
  context: {
    beforeRequest: () => localStorage.removeItem('authenticationToken'),
    request: (data) => telli.post('/logintoken', data, { headers: {} }),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Falha ao tentar realizar login.' }),
  },
});

export const keycloakLogin = createAsyncAction('KEYCLOAK_LOGIN', {
  context: {
    beforeRequest: () => localStorage.removeItem('authenticationToken'),
    request: (data) => telli.post('/login-keycloak-token', data, { headers: {} }),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Falha ao tentar realizar o login.' }),
  },
});

export const refreshKeycloakLogin = createAsyncAction('REFRESH_KEYCLOAK_LOGIN', {
  context: {
    request: (data) => telli.post('/login-keycloak-token', data, { headers: {} }),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Falha ao tentar realizar a renovação da sessão.' }),
  },
});

export const getLoggedUser = createAsyncAction('GET_LOGGED_USER', {
  initialValue: {},
  saveResult: true,
  context: {
    request: () => telli.get('/admin/usercustom/loggeduser'),
  },
});

export const getSessionToken = createAsyncAction('GET_SESSION_TOKEN');

export const logout = createAsyncAction('LOGOUT');

export const fetchUsers = createAsyncAction('USERS_FETCH_REQUEST', {
  initialValue: [],
  saveResult: true,
  context: {
    request: () => telli.get('/admin/usercustom/users'),
  },
});

export const createUser = createAsyncAction('CREATE_USER', {
  context: {
    request: (data) => telli.post('/admin/usercustom/createuserbackoffice', data),
    onRequestSuccess: () => message.success('Usuário criado com sucesso'),
    onRequestError: (err) => handleErrors(err, { defaultMessage: 'Falha ao tentar criar usuário' }),
  },
});
