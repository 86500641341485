import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';

import personGlasses from '~/assets/images/person-glasses.png';
import { Flex } from '~/ui/components/Layout/Flex';
import { themeToggleColor } from '~/common/utils/theme';
import { Button, CopyableTextBox, Tag, Typography, withStepperProps } from '~/ui/components';
import { useDrawer } from '~/hooks/useDrawer';
import { useAwaitControl } from 'react-redux-await-control';
import { getPersonDetail } from '~/store/people/actions';
import { prepareContract } from '~/store/opportunities/actions';
import { OpportunitySimulation } from '~/typings/entities/Opportunity';
import { getDeeplink } from '~/store/formalization/actions';
import { OpportunityBody, OpportunityContainer, OpportunityFooter } from '../../Opportunity.styled';
import { Ol } from './Success.styled';

export const Success = withStepperProps(({ stepper }) => {
  const theme = useTheme();
  const { setConfig, closeDrawer } = useDrawer();

  const personDetailsControl = useAwaitControl(getPersonDetail);
  const person = personDetailsControl.result();

  const prepareContractControl = useAwaitControl(prepareContract);
  const prepareContractResult: OpportunitySimulation = prepareContractControl.result();

  const getDeeplinkControl = useAwaitControl(getDeeplink);
  const getDeeplinkLoading = getDeeplinkControl.isRunning();
  const getDeeplinkSuccess = getDeeplinkControl.isSuccessful();
  const deeplink = getDeeplinkControl.result();

  const buttonColor = themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondary' });

  useEffect(() => {
    if (!getDeeplinkSuccess) {
      getDeeplinkControl.start({
        document: person?.document,
        contractId: prepareContractResult?.contractNumber,
      });
    }
  }, [getDeeplinkSuccess]);

  useEffect(() => {
    setConfig('opportunity-details', {
      backButton: true,
      onBackClick: stepper.prevStep,
      extraAction: null,
    });
  }, []);

  return (
    <OpportunityContainer>
      <OpportunityBody>
        <Flex direction="column" gap={24} pt={24} align="center">
          <img width={200} height={182} src={personGlasses} alt="" />

          <Typography type="headingH6" weight={600} center>
            Antecipação concluída com sucesso.
          </Typography>

          <Typography type="bodyLarge" weight={400} center>
            Informe ao cliente os próximos passos a serem realizados para prosseguir no aplicativo
            MeuTudo:
          </Typography>

          <Flex direction="column" gap={12} align="flex-start" width="100%">
            <Tag
              small
              rounded
              bgColor={theme.colors.info.secondary}
              textColor={theme.colors.info.primaryAlt}
            >
              Primeira vez contratando
            </Tag>

            <Typography type="bodyMedium" weight={400}>
              Documentos necessários para o cliente seguir no aplicativo na primeira contratação:
            </Typography>

            <Flex direction="column">
              <Ol className="custom-list">
                <li>Endereço da residência</li>
                <li>Dados bancários</li>
                <li>Foto do documento</li>
                <li>Foto do rosto</li>
              </Ol>
            </Flex>
          </Flex>

          <Flex direction="column" gap={12} align="flex-start" width="100%">
            <Tag
              small
              rounded
              bgColor={theme.colors.info.secondary}
              textColor={theme.colors.info.primaryAlt}
            >
              Cliente recorrente
            </Tag>

            <Typography type="bodyMedium" weight={400}>
              Documentos necessários para o cliente prosseguir no aplicativo quando já é cliente:
            </Typography>

            <Flex direction="column">
              <Ol className="custom-list">
                <li>Endereço da residência</li>
                <li>Dados bancários</li>
                <li>Foto do rosto</li>
              </Ol>
            </Flex>
          </Flex>
        </Flex>
      </OpportunityBody>

      <OpportunityFooter>
        <Flex direction="column" gap={16} width="100%">
          <CopyableTextBox
            helpText="Copie a URL para que o cliente prossiga de onde você parou e realize o envio para ele."
            textToCopy={
              getDeeplinkLoading
                ? 'Carregando...'
                : deeplink?.pagination?.link || 'Erro ao gerar link'
            }
          />

          <Button
            customColor={buttonColor}
            fullWidth
            variant="outline"
            rounded
            onClick={() => closeDrawer('opportunity-details')}
          >
            Fechar
          </Button>
        </Flex>
      </OpportunityFooter>
    </OpportunityContainer>
  );
});
