import moment from 'moment';

export const formatDate = (dateToFormat, isDateAndHour = true) => {
  if (!dateToFormat) return 'Indisponível';

  if (isDateAndHour) {
    return moment(dateToFormat).format('DD/MM/YYYY [às] HH:mm');
  }

  return moment(dateToFormat).format('DD/MM/YYYY');
};

export const checkDateDifferenceInDays = (date) =>
  Math.floor((new Date().getTime() - new Date(date).getTime()) / (1000 * 60 * 60 * 24));
