import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Icon, Popover, Tooltip } from 'antd';

import FileIcon from '~/assets/icons/file-plus.svg';
import InfoIcon from '~/assets/icons/info-Icon.svg';

import masked from '~/common/masked';
import CommentMotives, { ClixCommentMotives } from '~/typings/enums/CommentMotives';
import type { PersonComment } from '~/typings/entities/person';

import ChannelService from '~/typings/enums/ChannelServices';
import CommentFilters from '~/typings/enums/CommentFilters';
import { Person } from '~/typings/entities/person';
import { PopoverContractInfo, TooltipInfoCard } from './Tooltips';
import Icons from './Icons';
import CommentForm from './CommentForm';
import { AgentComment, CommentContainer } from './styled';

const { toDateAndHour } = masked;

type CommentProps = {
  contracts: any[];
  person: Person;
  comment: PersonComment;
};

const IconMap = {
  SAD: Icons.Frown,
  MEH: Icons.Meh,
  HAPPY: Icons.Smile,
};

export default function Comment({ comment, contracts, person }: CommentProps) {
  const [visible, setVisible] = useState(false);

  const setVisibility = useCallback(() => {
    setVisible((value) => !value);
  }, []);

  const MoodIcon = useMemo(() => IconMap[comment.mood], [comment?.mood]);

  const getContractById = useCallback(
    (id) => contracts.find((contract) => contract.contractId === id),
    [contracts],
  );

  return (
    <>
      {visible ? (
        <CommentForm
          setVisibility={setVisibility}
          contracts={contracts}
          personId={person?.id}
          initial={comment}
        />
      ) : (
        <CommentContainer>
          <div className="comment-header">
            <span>{comment.author}</span>
            <span>{toDateAndHour(new Date(comment.commentDate))}</span>
          </div>

          <div className="info-box">
            <Icon type="arrow-down" />
            <span>Informações</span>

            <Tooltip placement="bottomLeft" title={TooltipInfoCard}>
              <Icon component={InfoIcon} />
            </Tooltip>
          </div>

          <div className="comment-tags">
            <MoodIcon active />

            {comment?.channelService && (
              <>
                <Divider type="vertical" />
                <span>{ChannelService[comment?.channelService]}</span>
              </>
            )}

            {comment?.customerServiceArea && (
              <>
                <Divider type="vertical" />
                <span>{CommentFilters[comment?.customerServiceArea]}</span>
              </>
            )}

            {comment?.reason && (
              <>
                <Divider type="vertical" />
                <span>
                  {CommentMotives[comment?.reason]
                    ? CommentMotives[comment?.reason]
                    : ClixCommentMotives[comment?.reason]}
                </span>
              </>
            )}
          </div>

          <AgentComment>
            <p>{comment.comment || 'O agente não inseriu nenhum comentário'}</p>
          </AgentComment>

          {comment?.contracts?.length && (
            <div className="contract-list">
              <Tooltip title="Contratos" placement="bottom">
                <Icon component={FileIcon} />
              </Tooltip>

              {comment?.contracts.map((contractId) => (
                <Popover
                  key={contractId}
                  placement="bottomLeft"
                  content={
                    <PopoverContractInfo contract={getContractById(contractId)} person={person} />
                  }
                  arrowPointAtCenter
                >
                  <Link href="/" to={`/backoffice/contracts/${contractId}`}>
                    <Divider type="vertical" />
                    <span>{contractId}</span>
                  </Link>
                </Popover>
              ))}
            </div>
          )}
        </CommentContainer>
      )}
    </>
  );
}
