// @flow

import React from 'react';
import { Flex as StyledFlex } from './styled';

type FlexProps = {
  direction?: string,
};

const Flex = (props: FlexProps) => {
  return <StyledFlex {...props} />;
};

export default Flex;
