import styled from 'styled-components';
import {
  Button as AntButton,
  Row as AntRow,
  Form as AntForm,
  Select as AntSelect,
  DatePicker as AntDatePicker,
} from 'antd';
import { themeColor } from '~/common/utils/theme';

export const Row = styled(AntRow)`
  margin: 1em 0 !important;
`;

export const FirstRow = styled(AntRow)`
  margin-top: 1em;
`;

export const Button = styled(AntButton)`
  margin-bottom: 1em;
  background-color: ${themeColor('brand.primary', { dark: 'brand.secondaryAlt' })};
`;

export const Title = styled.span`
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  padding-bottom: 18px;
`;

export const Form = styled(AntForm)`
  input {
    background-color: ${themeColor('background.secondary', { dark: 'background.primary' })};
    color: ${({ theme }) => theme.colors.element.primary};
    border: 1px solid ${({ theme }) => theme.colors.divider.primary};
  }

  .ant-calendar-range-picker-input {
    border: none !important;
  }

  .has-error .ant-input {
    background-color: ${themeColor('background.secondary', { dark: 'background.primary' })};
    color: ${({ theme }) => theme.colors.element.primary};
  }

  .has-error .ant-form-explain {
    color: ${themeColor('negative.primaryAlt', { dark: 'negative.primary' })};
  }

  .ant-form-item-children > .ant-calendar-picker > .ant-calendar-picker-input {
    background-color: ${themeColor('background.secondary', {
      dark: 'background.primary',
    })} !important;
    border: 1px solid ${({ theme }) => theme.colors.divider.primary};
  }
`;

export const Select = styled(AntSelect)`
  .ant-select-selection {
    background-color: ${themeColor('background.secondary', {
      dark: 'background.primary',
    })} !important;
    color: ${({ theme }) => theme.colors.element.primary} !important;
    border: 1px solid ${({ theme }) => theme.colors.divider.primary} !important;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.element.placeholder};
  }

  .ant-select-dropdown .ant-select-dropdown-hidden {
    background-color: ${themeColor('background.secondary', {
      dark: 'background.primary',
    })} !important;
    color: ${({ theme }) => theme.colors.element.primary} !important;
  }
`;

export const DatePicker = styled(AntDatePicker)`
  background-color: ${themeColor('background.secondary', {
    dark: 'background.primary',
  })} !important;
  color: ${({ theme }) => theme.colors.element.primary} !important;
`;
