import { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router';

export default function usePersistTicketParams(personId: string) {
  const history = useHistory();

  const getPreFilledParam = useCallback(
    (key: string) => {
      let paramsObject: any;
      const preFilledParams = sessionStorage.getItem(`ticketParams-${personId}`);

      try {
        paramsObject = JSON.parse(preFilledParams);
      } catch (e) {
        paramsObject = {};
      }

      return paramsObject?.[key];
    },
    [personId],
  );

  const persistTicketParams = useCallback(() => {
    const enabledParams = [
      'ticketContactOrigin',
      'twillioProtocol',
      'ticketChannel',
      'conversationId',
    ];
    const searchParams = queryString.parse(history.location.search);
    const storedParams = sessionStorage.getItem(`ticketParams-${personId}`);

    const preFilledParams = enabledParams.reduce((acc, param) => {
      if (searchParams[param]) {
        acc[param] = searchParams[param];
      }
      return acc;
    }, {});

    if (storedParams) {
      const storedParamsObject = JSON.parse(storedParams);
      const newParams = { ...storedParamsObject, ...preFilledParams };
      sessionStorage.setItem(`ticketParams-${personId}`, JSON.stringify(newParams));
    } else {
      sessionStorage.setItem(`ticketParams-${personId}`, JSON.stringify(preFilledParams));
    }
  }, [history.location.search]);

  return useMemo(
    () => ({
      getPreFilledParam,
      persistTicketParams,
    }),
    [],
  );
}
