import {
  InvoiceStatus,
  InvoiceStatusPalette,
  InvoiceStatusNames,
  PaymentStatus,
  PaymentStatusPalette,
} from '~/typings/enums/Clix';

export const getInvoiceStatus = (invoice) => {
  if (!invoice) {
    return { label: null, colorPalette: null };
  }

  const { status, payment } = invoice;

  if (status === InvoiceStatus.CLOSED) {
    return {
      label: PaymentStatus[payment],
      colorPalette: PaymentStatusPalette[payment],
    };
  }

  return {
    label: InvoiceStatusNames[status],
    colorPalette: InvoiceStatusPalette[status],
  };
};
