import React, { useContext, useState } from 'react';
import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';
import HourFilter from './HourFilter';
import {
  ConfigContent,
  ConfigLabel,
  ConfigTitle,
  HoursConfigContainer,
  LabelContainer,
  LabelDisabledTag,
  LabelSuccessTag,
  LabelTag,
  Popover,
} from './styled';

export function HoursConfig() {
  const [visible, setVisible] = useState(false);
  const { isDistributionActive, hourFilter, filterSelected, allStepsConcluded } =
    useContext(ContractDetailsContext);

  const closePopover = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    if (isDistributionActive && !allStepsConcluded) {
      setVisible(visible);
    }
  };

  return (
    <Popover
      placement="bottomLeft"
      content={<HourFilter closePopover={closePopover} />}
      trigger="click"
      visible={isDistributionActive && !allStepsConcluded && visible}
      onVisibleChange={handleVisibleChange}
    >
      <ConfigContent>
        <HoursConfigContainer disabled={isDistributionActive && allStepsConcluded}>
          <LabelContainer>
            <ConfigTitle enabled={isDistributionActive}>Filtro de horário</ConfigTitle>
            {!isDistributionActive && <LabelDisabledTag>Ative seu status</LabelDisabledTag>}
            {isDistributionActive && !filterSelected && <LabelTag>Selecione o horário</LabelTag>}
            {filterSelected && <LabelSuccessTag>{hourFilter}</LabelSuccessTag>}
          </LabelContainer>
          <ConfigLabel enabled={isDistributionActive}>
            Escolha o horário que os contratos chegaram
          </ConfigLabel>
        </HoursConfigContainer>
      </ConfigContent>
    </Popover>
  );
}
