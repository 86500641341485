import Immutable from 'immutable';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose';
import { mapObject, isEmpty } from 'underscore';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Tooltip,
  Icon,
  Input,
  Form,
  Row,
} from 'antd';
import { X } from 'react-feather';
import theme from '~/themes/aphroditeTheme/theme';
import masked from '~/common/masked';
import { message } from '~/common';
import EnumMappers from '~/typings/enums';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/lib/date-picker/locale/pt_BR';

import { getContractDetails } from '~/store/contracts/actions';

import fetchCommissioningTypes from '~/store/fetchCommissioningTypes/action';
import registerCommissioning, {
  REGISTER_COMMISSIONING_REQUEST,
  REGISTER_COMMISSIONING_SUCCESS,
  REGISTER_COMMISSIONING_FAILURE,
} from '~/store/registerCommissioning/action';

import { Spinner } from '~/components';
import { Action } from '../Actions';
import common from './styles';

const { css, withStyles } = theme;

const { Item: FormItem } = Form;
const { toDate, toMoneyForm } = masked;
const { CommissioningType } = EnumMappers;

type Props = {
  details: any,
  setIsModalVisible: any,
  form: any,
  onPressRegisterCommisioning: any,
  styles: any,
  isModalVisible: any,
  isLoading: any,
  onPressClose: any,
};

class Commissioning extends Component<Props> {
  state = {
    name: '',
    numberOfInstallments: this.props.details.numberOfInstallments || undefined,
    commissioningType:
      this.props.details.loanStatus === 'PORTABILITY_PAID'
        ? 'REFIN_PORTABILITY'
        : this.props.details.loanType || undefined,
    startDate: undefined,
    financialInstitution: undefined,
    nominalRatePerMonth: '',
    flatGrossValue: '',
    flatNetValue: '',
    flatValueForDeposit: '',
    flatOutstandingBalance: '',
    deferredGrossValue: '',
    deferredNetValue: '',
    deferredValueForDeposit: '',
    deferredOutstandingBalance: '',
  };

  onPressOpen = () => {
    const { details } = this.props;

    this.setState({ financialInstitution: details.financialInstitution.id });

    this.props.setIsModalVisible(true);
  };

  onChange = (field) => {
    const fieldKey = Object.keys(field)[0];
    const fieldValue = field[`${fieldKey}`];

    const { getFieldDecorator } = this.props.form;
    this.setState(field, () => getFieldDecorator(fieldKey, { initialValue: { fieldValue } }));
  };

  onCommissioningDateChange = (CommissioningDate) => {
    this.setState({ CommissioningDate });
  };

  onPressRegisterCommisioning = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        this.props.onPressRegisterCommisioning(this.state);
      }
    });
  };

  renderInfo() {
    const { styles, details } = this.props;
    const {
      financialInstitution,
      contractInitialDate,
      person,
      loanStatus,
      numberOfInstallments,
    } = details;

    let { loanType } = details;

    if (loanStatus === 'PORTABILITY_PAID') loanType = 'REFIN_PORTABILITY';

    return (
      <Row type="flex" justify="space-between">
        <Col style={{ margin: '0 10px' }}>
          <span {...css(styles.key)}>TIPO</span>
          <span {...css(styles.preset__medium)}>
            {CommissioningType({ value: loanType })}
          </span>
        </Col>
        <Col style={{ margin: '0 10px' }}>
          <span {...css(styles.key)}>CONVÊNIO</span>
          <span {...css(styles.preset__medium)}>{person.agreementName}</span>
        </Col>
        <Col style={{ margin: '0 10px' }}>
          <span {...css(styles.key)}>BANCO</span>
          <span {...css(styles.preset__medium)}>
            {financialInstitution.name}
          </span>
        </Col>
        <Col style={{ margin: '0 10px' }}>
          <span {...css(styles.key)}>PARCELAS</span>
          <span {...css(styles.preset__medium)}>
            {' '}
            {numberOfInstallments}
          </span>
        </Col>

        <Col style={{ margin: '0 10px' }}>
          <span {...css(styles.key)}>DATA DE REFERÊNCIA</span>
          <span {...css(styles.preset__medium)}>
            {' '}
            {toDate(contractInitialDate)}
          </span>
        </Col>
        <Divider />
      </Row>
    );
  }

  renderForm() {
    const { onChange } = this;
    const { styles, details } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <Row>
          <Col xs={{ span: 24 }}>
            <span>Nome</span>
            <FormItem>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Informe o nome da comissão',
                  },
                ],
              })(
                <Input
                  placeholder="Nome"
                  onChange={(e) => this.onChange({ name: e.target.value })}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 0 }}>
            <span>Taxa nominal mensal</span>
            <FormItem>
              {getFieldDecorator(
                'nominalRatePerMonth',
                {
                  normalize: toMoneyForm,
                  rules: [
                    {
                      required: true,
                      message: 'Informe a taxa nominal mensal',
                    },
                  ],
                },
                {
                  normalize: toMoneyForm,
                },
              )(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({ nominalRatePerMonth: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
            <span>Data de início</span>
            <FormItem>
              {getFieldDecorator('startDate', {
                rules: [
                  {
                    required: true,
                    message:
                      'Informe a data de início da vingência da comissão',
                  },
                ],
              })(
                <DatePicker
                  locale={locale}
                  Property
                  Description
                  Type
                  Default
                  Version
                  defaultPickerValue={moment(details.contractInitialDate)}
                  onChange={(e) => onChange({ startDate: e })}
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                />,
              )}
            </FormItem>
          </Col>

          <Divider orientation="left">
            <span {...css(styles.modal__section)}>Comissão Fixa</span>
          </Divider>

          <Col xs={{ span: 24 }} lg={{ span: 5 }}>
            <span>Valor bruto</span>
            <FormItem>
              {getFieldDecorator('flatGrossValue', {
                normalize: toMoneyForm,
              })(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({ flatGrossValue: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
            <span>Valor líquido</span>
            <FormItem>
              {getFieldDecorator('flatNetValue', {
                normalize: toMoneyForm,
              })(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({ flatNetValue: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5, offset: 2 }}>
            <span>Valor para depósito</span>
            <FormItem>
              {getFieldDecorator('flatValueForDeposit', {
                normalize: toMoneyForm,
              })(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({ flatValueForDeposit: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
            <span>Saldo devedor</span>
            <FormItem>
              {getFieldDecorator('flatOutstandingBalance', {
                normalize: toMoneyForm,
              })(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({ flatOutstandingBalance: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>

          <Divider orientation="left">
            <span {...css(styles.modal__section)}>Comissão Diferida</span>
          </Divider>

          <Col xs={{ span: 24 }} lg={{ span: 5 }}>
            <span>Valor bruto</span>
            <FormItem>
              {getFieldDecorator('deferredGrossValue', {
                normalize: toMoneyForm,
              })(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({ deferredGrossValue: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
            <span>Valor líquido</span>
            <FormItem>
              {getFieldDecorator('deferredNetValue', {
                normalize: toMoneyForm,
              })(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({ deferredNetValue: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5, offset: 2 }}>
            <span>Valor para depósito</span>
            <FormItem>
              {getFieldDecorator('deferredValueForDeposit', {
                normalize: toMoneyForm,
              })(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({ deferredValueForDeposit: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
            <span>Saldo devedor</span>
            <FormItem>
              {getFieldDecorator('deferredOutstandingBalance', {
                normalize: toMoneyForm,
              })(
                <Input
                  addonAfter="%"
                  onChange={(e) => this.onChange({
                    deferredOutstandingBalance: e.target.value,
                  })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }}>
            <Button
              type="primary"
              {...css(styles.buttonConfirm)}
              onClick={this.onPressRegisterCommisioning}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { styles, isModalVisible } = this.props;

    const style = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s',
        background: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1000,
      },
    };

    return [
      <Tooltip placement="right" title="Nova comissão">
        <Action
          key="button"
          onPress={this.onPressOpen}
          style={css(styles.newCommissioning)}
          title={<Icon type="plus" style={{ fontSize: '18px' }} />}
        />
      </Tooltip>,
      <Modal
        ariaHideApp={false}
        contentLabel="Commissioning"
        isOpen={isModalVisible}
        key="modal"
        style={style}
        {...css(styles.modal)}
      >
        <Spinner spinning={this.props.isLoading} />

        <div {...css(styles.modal__header)}>
          <span {...css(styles.modal__title)}>Nova Comissão</span>
          <button
            type="button"
            onClick={this.props.onPressClose}
            {...css(styles.modal__close)}
          >
            <X {...css(styles.closeButton)} size={20} />
          </button>
        </div>
        <div>{this.renderInfo()}</div>
        <div>{this.renderForm()}</div>
      </Modal>,
    ];
  }
}

function onPressRegisterCommisioning({ dispatch, match }) {
  return (state) => {
    dispatch(registerCommissioning(state));
  };
}

function onPressClose({ setIsModalVisible }) {
  setIsModalVisible(false);
}

function componentDidMount() {
  const { id } = this.props.match.params;

  this.props.dispatch(fetchCommissioningTypes(id));
}

export function mapStateToProps(state) {
  return {
    registerCommissioning: state.getIn(['registerCommissioning']),
    commissioningTypes: state.getIn(['fetchCommissioningTypes']),
    detailsSuccess: getContractDetails.isSuccessful()(state),
    detailsFailure: getContractDetails.hasFailure()(state),
  };
}

export function receiveChanges(
  prevProps,
  {
    commissioningTypes,
    detailsSuccess,
    detailsFailure,
    registerCommissioning,
    setIsLoading,
    setIsModalVisible,
  },
) {
  if (Immutable.is(prevProps.detailsSuccess, detailsSuccess) === false
    || Immutable.is(prevProps.detailsFailure, detailsFailure)) {
    return detailsFailure || detailsSuccess;
  }

  if (
    Immutable.is(prevProps.registerCommissioning, registerCommissioning)
    === false
  ) {
    switch (registerCommissioning.getIn(['type'])) {
      case REGISTER_COMMISSIONING_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case REGISTER_COMMISSIONING_SUCCESS: {
        message.success('Comissão registrada com sucesso.');
        setIsLoading(false);
        setIsModalVisible(false);
        return true;
      }
      case REGISTER_COMMISSIONING_FAILURE: {
        const { messages } = registerCommissioning.getIn(['payload']);
        if (messages && messages[0]) {
          message.error(messages[0].text);
        } else {
          message.error('Erro ao registrar comissão.');
        }
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  if (
    Immutable.is(prevProps.commissioningTypes, commissioningTypes) === false
  ) {
    return true;
  }
  return false;
}

export function propagateStateChangeToProps({ commissioningTypes, details }) {
  return mapObject(
    {
      commissioningTypes,
      details,
    },
    (state) => state.getIn(['payload']),
  );
}

const styles = (props) => ({
  ...common(props),
  modal: {
    ...common(props).modal,
    width: 750,
  },
});

Commissioning.defaultProps = {
  values: {
    grossValueApproved: 0,
    installmentValueApproved: 0,
    netValueApproved: 0,
    numberOfInstallments: 0,
    CommissioningDate: moment().utc(),
    valueForDepositApproved: 0,
  },
};

const WrappedCommissioning = Form.create()(Commissioning);

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  lifecycle({
    componentDidMount,
  }),
  withState('isLoading', 'setIsLoading', false),
  withState('isModalVisible', 'setIsModalVisible', false),
  withState('isLoadedContractDetails', 'setIsLoadedContractDetails', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onPressRegisterCommisioning,
    onPressClose,
  }),
)(WrappedCommissioning);
