import { Map } from 'immutable';
import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import { createLogger } from 'redux-logger';

import AppStage from '~/typings/enums/AppStage';
import root from '~/store/general/sagas';

import configurations from './config';
import AjaxRequest from './ajax';
import epics from './epics';
import createReducer from './reducers';

export const { endpoint } = configurations;
export const ajax = new AjaxRequest();

const epicMiddleware = createEpicMiddleware(epics, {
  dependencies: { AjaxRequest: ajax },
});

const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;
const middlewares = [thunk, epicMiddleware, sagaMiddleware];

if (process.env.REACT_APP_STAGE !== AppStage.PRODUCTION) {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;

  const logger = createLogger({
    stateTransformer: (state) => {
      const newState = state.toJS();
      Object.keys(newState).forEach((key) => {
        if (!!newState[key].present && typeof newState[key].present.toJS === 'function') {
          newState[key].present = newState[key].present.toJS();
        }
      });
      return newState;
    },
  });

  middlewares.push(logger);
}

export function configureStore() {
  const store = createStore(
    createReducer,
    Map({}),
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(root);

  return store;
}
