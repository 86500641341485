import React, { useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { OpportunityListItem } from '~/typings/entities/Opportunity';
import { prepareContract, simulateOpportunity } from '~/store/opportunities/actions';
import { Steps } from '~/ui/components';
import { getContractsOfPerson } from '~/store/people/actions';
import { Success } from './Success/Success';
import { Details } from './Details/Details';
import { Error } from './Error/Error';

export type FgtsStepsProps = {
  opportunity: OpportunityListItem;
};

export function FgtsSteps({ opportunity }: FgtsStepsProps) {
  const simulateControl = useAwaitControl(simulateOpportunity);
  const prepareContractControl = useAwaitControl(prepareContract);
  const getContractsOfPersonControl = useAwaitControl(getContractsOfPerson);

  const { entries: contracts } = getContractsOfPersonControl.result();

  const previousSimulation = useMemo(
    () =>
      contracts?.find((contract) => ['INITIAL', 'REQUESTED'].includes(contract?.contractSituation)),
    [contracts],
  );

  const [simulationAlert, setSimulationAlert] = useState(!!previousSimulation);

  useEffect(
    () => () => {
      simulateControl.clear();
      prepareContractControl.clear();
    },
    [],
  );

  return (
    <Steps
      extraProps={{ opportunity, simulationAlert, setSimulationAlert, previousSimulation }}
      steps={[
        { id: 'details', component: <Details /> },
        { id: 'success', component: <Success /> },
        { id: 'error', component: <Error /> },
      ]}
    />
  );
}
