/* @flow */
import React, { Component } from 'react';
import { Button, Icon, Input } from 'antd';
import theme from '~/themes/aphroditeTheme/theme';
import Masker from 'vanilla-masker';
import styles, { Container, Label } from './InputLabel.Styles';
import { Value } from '~/components/SimpleInfo/styled';

const { css, withStyles } = theme;

type Props = {
  field: string,
  title?: string,
  format?: string,
  placeholder?: string,
  initialValue: string,
  onSubmit: (value: string) => void,
};

class InputLabel extends Component<Props> {
  state = {
    value: null,
    showLabel: true,
  };

  renderLabel() {
    const { initialValue, title } = this.props;
    return (
      <Container onClick={this.showInput} initialValue={initialValue}>
        <Label>{title}</Label>
        <Value>{initialValue}</Value>
      </Container>
    );
  }

  renderInput() {
    const { styles, initialValue, placeholder, title } = this.props;
    const { value } = this.state;

    return (
      <div>
        <span {...css(styles.keyInput)}>{title}</span>
        <div {...css(styles.input)}>
          <Input
            type='text'
            placeholder={placeholder}
            onChange={this.onChange}
            onPressEnter={this.update}
            value={value === null ? initialValue : value}
            autoFocus
          />
        </div>
        <div {...css(styles.contractNumberActionWrapper)}>
          <Button
            style={{ padding: '9px' }}
            onClick={this.clearInput}
            className='ant-btn-border-none'
          >
            <Icon type='close' style={{ fontSize: '16px' }} />
          </Button>
          {value ? (
            <Button
              style={{ padding: '9px' }}
              onClick={this.update}
              className='ant-btn-border-none'
            >
              <Icon type='check' style={{ fontSize: '16px' }} />
            </Button>
          ) : null}
        </div>
      </div>
    );
  }

  showInput = () => {
    this.setState({ showLabel: false });
  };

  update = () => {
    this.props.onSubmit(this.state.value);
  };

  clearInput = () => {
    this.setState({
      showLabel: true,
      value: '',
    });
  };

  onChange = e => {
    let value = e.target.value;
    if (this.props.format) {
      value = Masker.toPattern(value, this.props.format);
    }
    this.setState({ value });
  };

  render() {
    return this.state.showLabel ? this.renderLabel() : this.renderInput();
  }
}

InputLabel.defaultProps = {
  field: '',
};

export default withStyles(styles)(InputLabel);
