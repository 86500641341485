import React, { useCallback, useState } from 'react';

import { useDrawer } from '~/hooks/useDrawer';
import { CaretDown } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';
import { ContractFieldValue, List, Link } from './TicketContractsPopover.styled';

export function TicketContractsPopover({ contracts }: { contracts: { id: number }[] }) {
  const { closeAllDrawers } = useDrawer();
  const [isVisible, setIsVisible] = useState(false);

  const handleLinkCLick = useCallback(() => {
    closeAllDrawers();
    setIsVisible(false);
  }, []);

  const renderContractLink = (contractId: string) => {
    if (!contractId) {
      return <span>Não informado</span>;
    }

    return (
      <Link onClick={handleLinkCLick} to={`/backoffice/contracts/${contractId}`}>
        #{contractId}
      </Link>
    );
  };

  if (contracts.length === 1) {
    return renderContractLink(`${contracts[0].id}`);
  }

  return (
    <Popover
      trigger="click"
      width={265}
      onVisibleChange={(ev) => setIsVisible(ev)}
      visible={isVisible}
      noHeader
      content={
        <List>
          {contracts.map(({ id }) => (
            <span key={id}>{renderContractLink(`${id}`)}</span>
          ))}
        </List>
      }
    >
      <ContractFieldValue>
        <p>#{contracts[0].id || '-'}</p>
        <CaretDown className="action-icon" width={14} height={14} />
      </ContractFieldValue>
    </Popover>
  );
}
