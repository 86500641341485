import React from 'react';
import { CalendarProps as DefaultCalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

/**
 * Para mais informações sobre a lib "react-calendar", acesse o link da doc abaixo:
 * https://github.com/wojtekmaj/react-calendar/blob/main/packages/react-calendar/README.md
 */

import moment from 'moment';
import { CalendarStyled } from './Calendar.styled';

type DatePiece = Date | string;
export type CalendarValue = DatePiece | DatePiece[];

type DefaultProps = Pick<
  DefaultCalendarProps,
  'defaultValue' | 'value' | 'selectRange' | 'onChange' | 'minDate' | 'maxDate'
>;

export type CalendarProps = Omit<DefaultProps, 'defaultValue'> & {
  onChange?: (value: CalendarValue, event: React.MouseEvent<HTMLButtonElement>) => void;
  defaultValue?: CalendarValue;
};

export function Calendar({
  defaultValue,
  value,
  onChange,
  minDate,
  maxDate,
  selectRange,
}: CalendarProps) {
  function formatDate(date: Date) {
    if (!date) return '';
    try {
      return new Date(date);
    } catch (e) {
      return '';
    }
  }

  const handleOnChange = (values, e) => {
    const returnValues = Array.isArray(values)
      ? values.map((el) => moment(el).toISOString())
      : moment(values).toISOString();
    onChange?.(returnValues, e);
  };

  return (
    <CalendarStyled
      locale="pt-BR"
      defaultValue={defaultValue}
      value={value}
      onChange={handleOnChange}
      minDate={formatDate(minDate)}
      maxDate={formatDate(maxDate)}
      selectRange={selectRange}
    />
  );
}
