import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Button } from '~/ui/components';

export const ActionButton = styled(Button).attrs({
  size: 'sm',
  iconOnly: 'true',
  variant: 'outline',
  icon: 'Pencil',
  title: 'Editar',
})`
  border-color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};

  svg {
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }

  :hover {
    border-color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};

    svg {
      color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    }
  }
`;
