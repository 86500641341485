import styled from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Button } from '~/ui/components';

export const ActionButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  rounded: true,
  size: 'sm',
  icon: 'Dots',
  customColor: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  max-height: 22px;
  height: 22px;
  min-width: 22px;
`;

export const Option = styled(Button).attrs({
  variant: 'text',
  rounded: true,
  size: 'sm',
  color: 'black',
})`
  padding: 0;
  height: 24px;

  .typography {
    font-size: ${({ theme }) => theme.typography.types.paragraphSmall.size};
    font-weight: ${({ theme }) => theme.typography.types.paragraphSmall.weight};
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  }

  svg {
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    width: 16px;
    height: 16px;
  }

  &:hover {
    svg {
      color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    }
  }
`;

export const TwilioOption = styled(Option).attrs({
  tooltip: 'Protocolo da Twilio',
})`
  cursor: default;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.error[600]};
  }

  .typography {
    color: ${({ theme }) => theme.colors.error[600]};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.error[600]};
    }

    .typography {
      color: ${({ theme }) => theme.colors.error[600]};
    }
  }
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0;
  gap: 12px;
`;
