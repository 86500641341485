import styled from 'styled-components';

import { Divider as DividerComponent } from 'antd';

export const SectionContainer = styled.div`
  border-radius: ${(props) => props.theme.cardBorderRadius};
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 114, 255, 0.2);
`;

export const ContentContainer = styled.div`
  padding: 26px 29px;
  
  > span {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
  }

  .ant-divider-horizontal {
    margin: -9px 0 !important;
  }
`;

export const Divider = styled(DividerComponent)`
  margin: -9px 0 !important;
`;
