/* @flow */

export const FETCH_PERSON_REGISTER_RESET_REASONS_REQUEST =
  'FETCH_PERSON_REGISTER_RESET_REASONS_REQUEST';
export const FETCH_PERSON_REGISTER_RESET_REASONS_SUCCESS =
  'FETCH_PERSON_REGISTER_RESET_REASONS_SUCCESS';
export const FETCH_PERSON_REGISTER_RESET_REASONS_FAILURE =
  'FETCH_PERSON_REGISTER_RESET_REASONS_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: FETCH_PERSON_REGISTER_RESET_REASONS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FETCH_PERSON_REGISTER_RESET_REASONS_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch() {
  return dispatch => {
    dispatch({
      type: FETCH_PERSON_REGISTER_RESET_REASONS_REQUEST,
      payload: {},
    });
  };
}
