import moment, { Moment } from 'moment';

import { PersonPaycheckFileType } from '~/typings/enums/PersonPaycheckFileType';

type HisconData = {
  id: number;
  referenceDate: Date;
  processedAt: Date;
  valid: boolean;
  processed: boolean;
  personPaycheckFileType: PersonPaycheckFileType;
}

export default class Hiscon {
  id: number;

  referenceDate: Moment;

  processedAt: Moment;

  valid: boolean;

  processed: boolean;

  personPaycheckFileType: PersonPaycheckFileType;

  constructor(data: HisconData) {
    this.id = data.id;
    this.referenceDate = moment(data.referenceDate);
    this.processedAt = moment(data.processedAt);
    this.valid = data.valid;
    this.processed = data.processed;
    this.personPaycheckFileType = data.personPaycheckFileType;
  }
}
