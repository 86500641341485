const LoanTypes = {
  NEW: 'NEW',
  REFIN: 'REFIN',
  PORTABILITY: 'PORTABILITY',
  PORTABILITYWITHOUTREFIN: 'PORTABILITYWITHOUTREFIN',
  FUTUREMARGIN: 'FUTUREMARGIN',
  FGTS_NEW: 'FGTS_NEW',
};

export default LoanTypes;
