import { put, takeLatest, takeEvery } from 'redux-saga/effects';

import takeRequest from '~/store/takeRequest';

import * as ContractActions from './actions';

function* updateConferenceQueue({ payload }): Generator {
  yield put(ContractActions.getContractQueueInformation.start(payload));
  yield put(ContractActions.listQueueContracts.start());
}

export function* reloadContract({ meta: { payload } }: any) {
  yield put(ContractActions.getContractDetails.start({ id: payload?.contractId }));
}

function* forceSendToQueueSuccess({ meta: { payload } }: any) {
  yield put(ContractActions.checkContractInConference.start(payload));
}

function* updateQueueStatus({ meta: { payload } }): Generator {
  yield put(ContractActions.getUserActiveToQueue.success(payload));
}

export function* contractsSaga(): Generator {
  yield takeRequest(ContractActions.listQueueContracts);
  yield takeRequest(ContractActions.getContractQueueInformation);
  yield takeRequest(ContractActions.getContractToQueue);
  yield takeRequest(ContractActions.simulateRefin);
  yield takeRequest(ContractActions.updateContractValues);
  yield takeRequest(ContractActions.checkQueueContract);
  yield takeRequest(ContractActions.updateEventReturn);
  yield takeRequest(ContractActions.checkContractInConference);
  yield takeRequest(ContractActions.forceSendToQueue);
  yield takeRequest(ContractActions.clearQueue);
  yield takeRequest(ContractActions.listReturnsParati);
  yield takeRequest(ContractActions.getContractDetails);
  yield takeRequest(ContractActions.generateDED);
  yield takeRequest(ContractActions.resendToPayment);
  yield takeRequest(ContractActions.getContractBundleFiles);
  yield takeRequest(ContractActions.updateFinancialInstitutionKey);
  yield takeRequest(ContractActions.updateFinancialInstitutionKeyPort);
  yield takeRequest(ContractActions.updateOperationStep);
  yield takeRequest(ContractActions.getContractInactiveFiles);
  yield takeRequest(ContractActions.advanceRequestQueue, {
    retry: 150,
    retryDelay: 3_000,
  });
  yield takeRequest(ContractActions.getContractLogs);
  yield takeRequest(ContractActions.getUserActiveToQueue);
  yield takeRequest(ContractActions.checkUserContractQueueStatus);
  yield takeRequest(ContractActions.activateUserContractQueueStatus);
  yield takeRequest(ContractActions.deactivateUserContractQueueStatus);
  yield takeRequest(ContractActions.updateActiveUserQueue);
  yield takeRequest(ContractActions.fetchLoanTypes);
  yield takeRequest(ContractActions.fetchLoanStatuses);
  yield takeRequest(ContractActions.fetchOperationStatusTypes);
  yield takeRequest(ContractActions.fetchOperationStepsTypes);
  yield takeRequest(ContractActions.cancelPreProposal);
  yield takeRequest(ContractActions.uploadContractFile);
  yield takeRequest(ContractActions.searchContracts);
  yield takeRequest(ContractActions.getContractInsurance);
  yield takeRequest(ContractActions.validateKYC);
  yield takeRequest(ContractActions.downloadAuditTrail);
  yield takeRequest(ContractActions.downloadPortabilityTerm);

  yield takeLatest(ContractActions.getContractToQueue.success.toString(), updateConferenceQueue);
  yield takeLatest(ContractActions.advanceRequestQueue.success.toString(), updateConferenceQueue);
  yield takeEvery(ContractActions.getContractDetails.success.toString(), forceSendToQueueSuccess);
  yield takeLatest(ContractActions.forceSendToQueue.success.toString(), forceSendToQueueSuccess);
  yield takeLatest(ContractActions.updateOperationStep.success.toString(), reloadContract);
  yield takeLatest(ContractActions.resendToPayment.success.toString(), reloadContract);

  yield takeLatest(
    ContractActions.deactivateUserContractQueueStatus.success.toString(),
    updateQueueStatus,
  );
  yield takeLatest(
    ContractActions.activateUserContractQueueStatus.success.toString(),
    updateQueueStatus,
  );
}
