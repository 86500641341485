import styled from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';
import { fadeIn } from '~/ui/theme/animations';

export const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px 0;

  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const SectionTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  weight: 500,
  element: 'h3',
}))``;

export const TagsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

type TicketInfoProps = {
  noBorder?: boolean;
};

export const TicketInfoContainer = styled.div<TicketInfoProps>`
  padding: 24px 0;
  display: flex;
  gap: 22px;
  flex-wrap: wrap;

  border-bottom: ${({ noBorder }) => (noBorder ? 0 : '1px')} dotted
    ${themeColor('divider.brand', { dark: 'divider.secondary' })};
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;

  svg {
    height: 24px;
    width: 24px;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
