import styled from 'styled-components';
import { Tags as DefaultTags } from '~/ui/components';

export const Tags = styled(DefaultTags).attrs({
  editable: true,
  rounded: true,
  small: true,
})`
  margin-top: 4px;
`;
