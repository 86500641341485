import React, { useMemo } from 'react';
import { Button, Icon } from 'antd';

type ButtonClickProps = {
  onClick?: (...args: any) => void;
}

type IconProps = {
  type?: 'default' | 'button';
  mood: 'frown' | 'meh' | 'smile';
  active?: boolean;
} & ButtonClickProps;

type WrapperIconProps = React.PropsWithChildren<Omit<IconProps, 'active' | 'mood'>>;

function WrapperIcon({ type = 'default', onClick, children }: WrapperIconProps) {
  if (type === 'button') {
    return (
      <Button onClick={onClick} type="link" ghost>
        {children}
      </Button>
    );
  }

  return <>{children}</>;
}

const moodIconStyle = {
  fontSize: '21px',
  padding: '0 5px !important',
};

const moodColors = {
  frown: '#F5222D',
  meh: '#FFC069',
  smile: '#A0D911',
};

function Mood({ active, mood, ...rest }: IconProps) {
  const style = useMemo(() => ({ ...moodIconStyle, color: active ? moodColors[mood] : '#BFBFBF' }), [active]);
  return (
    <WrapperIcon {...rest}>
      <Icon type={mood} style={style} />
    </WrapperIcon>
  );
}

const closeIconStyle = {
  fontSize: '16px',
  color: 'black',
  padding: '0 !important',
};

function Close({ onClick }: ButtonClickProps) {
  return (
    <WrapperIcon type="button" onClick={onClick}>
      <Icon type="close" style={closeIconStyle} />
    </WrapperIcon>
  );
}

export default {
  Meh: (props) => <Mood mood="meh" {...props} />,
  Frown: (props) => <Mood mood="frown" {...props} />,
  Smile: (props) => <Mood mood="smile" {...props} />,
  Close,
};
