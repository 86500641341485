import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { isEmpty, mapObject } from 'underscore';

type FilterTableContextProps = {
  changeValue?: (keyValue: string, value: any) => void;
  getValue?: (keyValue: string) => any;
  submit?: () => void;
  validate?: (data: any) => void;
  onValidationError?: (e: Error) => void;
};

export const TableFilterContext = React.createContext<FilterTableContextProps>({});

export function TableFilterContextProvider({
  children, onSubmit, validate, onValidationError,
}: any) {
  const [values, setValues] = useState({});

  const history = useHistory();
  const location = useLocation();

  const changeValue = useCallback((keyValue, value) => {
    setValues((currentValues) => ({
      ...currentValues,
      [keyValue]: value,
    }));
  }, [values]);

  const getValue = useCallback((keyValue) => values[keyValue], [values]);

  const submit = useCallback(() => {
    const params = new URLSearchParams(location.search);

    mapObject(
      values,
      (value, key) => {
        if (isEmpty(value)) {
          return params.delete(key);
        }
        return params.set(key, value);
      },
    );

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });

    try {
      validate?.(values);
      onSubmit(params.toString());
    } catch (e) {
      onValidationError?.(e);
    }
  }, [values]);

  return (
    <TableFilterContext.Provider value={{ changeValue, getValue, submit }}>
      {children}
    </TableFilterContext.Provider>
  );
}
