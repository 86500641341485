/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
/* global fetch, localStorage */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { isEmpty } from 'underscore';

import { SOLVE_DOCUMENT_REQUEST, success, failure } from './action';

const events = (action$, state, { AjaxRequest }) =>
  action$
    .ofType(SOLVE_DOCUMENT_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(({ id, files }) => {
      const formData = new FormData();

      files.forEach(f => {
        formData.append(f.name, f.file);
      });

      const res = Array.from(formData.entries(), ([key, prop]) => ({
        [key]: {
          ContentLength: typeof prop === 'string' ? prop.length : prop.size,
        },
      }));

      console.log(res, isEmpty(formData));

      return AjaxRequest.open(
        'post',
        `/admin/pendencycustom/pendencies/${id}/uploaddocument`,
        formData,
        {
          noContentType: true,
        },
      )('tudo')
        .flatMap(response => Observable.of(success(response)))
        .catch(err => Observable.of(failure(err)));
    });

export default combineEpics(events);
