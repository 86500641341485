/* @flow */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { CANCEL_CONTRACT_SIGNATURE_REQUEST, success, failure } from './action';

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(CANCEL_CONTRACT_SIGNATURE_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(({ contractId, password }) =>
      AjaxRequest.open(
        'post',
        `/admin/contractcustom/contracts/${contractId}/cancelcontractsignature`,
        {
          password,
        },
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
