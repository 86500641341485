export enum TicketPriorities {
  HIGH = 'Prioridade Alta',
  MEDIUM = 'Prioridade Média',
  LOW = 'Prioridade Baixa',
  WAITING_DEFINITION = 'Aguardando definição',
}

export const TicketPrioritiesPalette = {
  HIGH: 'negative',
  MEDIUM: 'alert',
  LOW: 'positive',
  WAITING_DEFINITION: 'neutral',
};
