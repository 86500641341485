import React from 'react';
import { Icon } from 'antd';

import StyledButton from './styled';

export type ButtonProps = {
  title?: string;
  children: React.ReactChildren;
  size?: 'small' | 'default' | 'large';
  type?: 'primary' | 'danger' | 'default' | 'link' | 'dashed';
  disabled?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  ghost?: boolean;
  fullWidth?: boolean;
  propsIcon?: {};
  onClick?: (e: React.MouseEventHandler<HTMLElement>) => void;
  onPress?: (e: React.MouseEventHandler<HTMLElement>) => void;
};

const defineIcon = (icon, propsIcon) => icon && (
<Icon
  type={typeof icon === 'string' && icon}
  component={typeof icon !== 'string' && icon}
  {...propsIcon}
/>
);

function Button(props: ButtonProps) {
  const onPress = (e) => {
    if (props.onPress) {
      props.onPress(e);
      return;
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };
  const {
    title, children, iconLeft, iconRight, propsIcon,
  } = props;
  return (
    <StyledButton {...props} onClick={onPress}>
      {defineIcon(iconLeft, propsIcon)}
      {title || children}
      {defineIcon(iconRight, propsIcon)}
    </StyledButton>
  );
}

Button.defaultProps = {
  type: 'primary',
  onClick: () => {},
};

export default Button;
