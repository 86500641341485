import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';
import { message } from 'antd';

export const getFileHistory = createAsyncAction('GET_FILE_HISTORY_LIST', {
  saveResult: true,
  initialValue: [],
  context: {
    onRequestError: () => message.error('Não foi possível carregar histórico do arquivo'),
    errorParser: () => [],
    responseParser: ({ data }) => data.resource,
    request: (data) => telli.post('/admin/filehistory/list', data),
  },
});

export const getHistoryFile = createAsyncAction('GET_HISTORY_FILE', {
  saveResult: true,
  initialValue: null,
  context: {
    request: (data) => telli.post('/admin/filehistory/file', data, { responseType: 'blob' }),
    errorParser: () => null,
    responseParser: ({ data }) => ({
      type: data.type,
      size: data.size,
      url: URL.createObjectURL(data),
      blob: data,
    }),
  },
});

export const getFiles = createAsyncAction('GET_FILES_LIST', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ id, type }) =>
      telli.get('/admin/files', {
        params: { id, type },
      }),
    errorParser: () => [],
    onRequestError: () => {},
    responseParser: ({ data }) => data.resource?.filter((el) => el) || [],
  },
});
