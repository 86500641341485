/* @flow */

import { message as showMessage } from '~/common';

export const ADJUST_CONTRACT_REQUEST = 'ADJUST_CONTRACT_REQUEST';
export const ADJUST_CONTRACT_SUCCESS = 'ADJUST_CONTRACT_SUCCESS';
export const ADJUST_CONTRACT_FAILURE = 'ADJUST_CONTRACT_FAILURE';

export function success(payload) {
  return (dispatch) => {
    dispatch({
      type: ADJUST_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure({ resource, messages }) {
  return (dispatch) => {
    dispatch({
      type: ADJUST_CONTRACT_FAILURE,
      payload: resource,
    });

    if (messages && typeof messages === 'string') return showMessage.error(messages);

    const message = messages && messages.length ? messages[0].text : 'Falha ao enviar contrato';

    return showMessage.error(message);
  };
}

export default function sendContract(contractId, payload) {
  return (dispatch) => {
    dispatch({
      type: ADJUST_CONTRACT_REQUEST,
      payload: {
        contractId,
        ...payload,
      },
    });
  };
}
