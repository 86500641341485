import styled from 'styled-components';

export const InstallmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .installment {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
    justify-content: space-around;
    padding: 4px;

    &:nth-child(odd) {
      background-color: #f7f7f7;
    }
  }

  .value {
    color: black;
    font-weight: bold;
  }
`;
