// @flow

import React, { useState } from 'react';
import { Input } from 'antd';

type SelectionProps = {
  onPressEnter: (number: string) => void,
};

function Selection(props: SelectionProps) {
  const [value, setValue] = useState('');
  const onChangeText = e => {
    setValue('');
  };
  const onEnter = () => {
    props.onPressEnter(value);
  };
  return (
    <div>
      <Input
        type='text'
        placeholder='RG'
        onChange={onChangeText}
        onPressEnter={onEnter}
        value={value}
        autoFocus
      />
    </div>
  );
}

export default Selection;
