import React, { Component } from 'react';

import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { mapObject } from 'underscore';
import theme from '~/themes/aphroditeTheme/theme';

import getMedicalCertificate, {
  GET_MEDICAL_CERTIFICATE_SUCCESS,
  GET_MEDICAL_CERTIFICATE_FAILURE,
} from '~/store/getMedicalCertificate/action';

import message from '~/common/message';
import { Spinner, OldActions } from '~/components';
import styles from './styles';

const { css, withStyles } = theme;

class DownloadMedicalCertificate extends Component {
  onPress = () => {
    this.props.isDownloading(true);
    this.props.onPressDownloadMedicalCertificate();
  };

  render() {
    const { downloading, agreementName, title, styles, type } = this.props;

    if (agreementName !== 'INSS') return null;

    const content = downloading ? 'Preparando...' : title;

    return [
      <Spinner spinning={downloading} />,

      <OldActions
        type={type}
        key="buttonMedicalCertificate"
        onPress={this.onPress}
        style={css(styles.action)}
        title={`${content}`}
      />,
    ];
  }
}

DownloadMedicalCertificate.defaultProps = {
  onPressDownloadMedicalCertificate: () => {},
  success: false,
  title: 'Abrir',
};

function onPressDownloadMedicalCertificate({ dispatch, personId }) {
  return () => {
    dispatch(getMedicalCertificate(personId));
  };
}

function mapStateToProps(state) {
  return {
    medicalCertificate: state.getIn(['getMedicalCertificate']),
  };
}

function receiveChanges(prevProps, { medicalCertificate, isDownloading }) {
  if (Immutable.is(prevProps.medicalCertificate, medicalCertificate) === false) {
    switch (medicalCertificate.getIn(['type'])) {
      case GET_MEDICAL_CERTIFICATE_SUCCESS: {
        const { uri } = medicalCertificate.getIn(['payload']).toJS();

        window.open(uri); // Abre o arquivo pdf em outra aba.
        isDownloading(false);

        return true;
      }
      case GET_MEDICAL_CERTIFICATE_FAILURE:
        isDownloading(false);
        message.error('Falha ao Baixar Arquivo.');
        return true;
      default:
        return false;
    }
  }

  return false;
}

function propagateStateChangeToProps({ medicalCertificate }) {
  const props = mapObject({ medicalCertificate }, (state) => state.getIn(['payload']));
  return Object.assign(props, { success: true });
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  withState('downloading', 'isDownloading', false),
  withHandlers({
    onPressDownloadMedicalCertificate,
  }),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
)(DownloadMedicalCertificate);
