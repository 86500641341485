import styled from 'styled-components';
import get from 'lodash/get';

import { Flex } from '~/ui/components';
import { themeColor } from '~/common/utils/theme';

export const HistoryItem = styled(Flex)`
  border-radius: 4px;
  border-left: 3px solid ${({ color, theme }) => get(theme.colors, color, 'alert.secondaryAlt')};
  box-shadow: 0 0 8px 0 ${themeColor('divider.primary', { dark: 'divider.invert' })};
`;

export const HistoryContainer = styled(Flex)`
  min-height: max(calc(100vh - 530px), 350px);
`;
