import { createAsyncAction } from 'react-redux-await-control';
import { message } from 'antd';
import { mapObject } from 'underscore';

import { handleErrors } from '~/common/utils/errorHandler';
import { gateway, telli } from '~/services';

export const fetchAgreements = createAsyncAction('FETCH_AGREEMENTS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: (params) => telli.get('/admin/agreements', { params }),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Não foi possível obter os convênios.' }),
    errorParser: () => [],
  },
});

export const fetchFinancialInstitutions = createAsyncAction('FETCH_FINANCIAL_INSTITUTIONS', {
  saveResult: true,
  initialValue: [],
  context: {
    errorParser: () => [],
    request: (params) => {
      const search = new URLSearchParams();
      mapObject(params, (value, key) => search.set(key, value));

      return telli.get(
        `/admin/financialinstitutioncustom/financialinstitutions?${search.toString()}`,
      );
    },
  },
});

export const fetchFinancialInstitutionsForDeposit = createAsyncAction(
  'FETCH_FINANCIAL_INSTITUTIONS_FOR_DEPOSIT',
  {
    saveResult: true,
    initialValue: [],
    context: {
      errorParser: () => [],
      request: (params) =>
        telli.get(`/admin/financialinstitutioncustom/availablefordeposit`, { params }),
    },
  },
);

export const fetchAddressFromZipCode = createAsyncAction('FETCH_ADDRESS_FROM_ZIPCODE', {
  saveResult: true,
  context: {
    onRequestError: () => message.warning('Não foi possível encontrar este CEP'),
    request: (data) => telli.post(`/openservices/addressfromzipcode`, data),
  },
});

export const getUserNotifications = createAsyncAction('GET_USER_NOTIFICATIONS', {
  saveResult: true,
  initialValue: [],
  context: {
    errorParser: () => [],
    request: () => telli.get(`/admin/notificationusers`),
  },
});

export const getTableauToken = createAsyncAction('GET_TABLEAU_TOKEN', {
  saveResult: true,
  initialValue: null,
  context: {
    errorParser: () => null,
    responseParser: ({ data }) => data?.token,
    request: () => gateway.get(`/backoffice/auth/tableau/token`),
  },
});

export const getReports = createAsyncAction('GET_REPORTS_TABLEAU', {
  saveResult: true,
  initialValue: [],
  context: {
    errorParser: () => null,
    responseParser: ({ data }) => data?.resource?.reports,
    request: () => telli.get(`/admin/usercustom/reports-tableau`),
  },
});

export const markNotificationsAsRead = createAsyncAction('MARK_NOTIFICATIONS_AS_READ', {
  context: {
    errorParser: () => [],
    request: ({ ids }) => telli.put(`/admin/notificationusers/markasreaded`, { ids }),
  },
});

export const getParameter = createAsyncAction('GET_PARAMETER', {
  saveResult: true,
  initialValue: {},
  context: {
    errorParser: () => null,
    responseParser: ({ data }) => data?.resource,
    request: (name) => telli.get(`/admin/parameter`, { params: { name } }),
  },
});
