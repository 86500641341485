// @flow

import React, { useEffect, useState } from 'react';
import Modal from '~/components/Modal';
import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { compose } from 'recompose';
import { Money } from '~/common';

const { Item: FormItem } = Form;

type ModalEditProps = {
  visible: boolean,
  financialInstitutionKey: string,
  bankNumber: number,
  installmentValue: number,
  numberOfInstallments: number,
  onPressClose: () => void,
  onSubmit: (fields: any) => () => void,
};

const fieldsArray = [
  {
    key: 'financialInstitutionKey',
    label: 'Código do contrato',
  },
  {
    key: 'bankNumber',
    label: 'Número do banco',
    rules: [
      {
        required: true,
        type: 'regexp',
        pattern: new RegExp(/\d+/g),
        message: 'Apenas números!',
      },
    ],
    type: 'number',
  },
  {
    key: 'installmentValue',
    label: 'Valor de parcela',
    formatter: value => Money(Number.parseFloat(value), true),
    parser: value =>
      Number.parseFloat(
        Money(value)
          .replace('R$ ', '')
          .replace('.', '')
          .replace(',', '.'),
      ),
    isNumber: true,
    isMoney: true,
  },
  {
    key: 'numberOfInstallments',
    label: 'Número de parcelas',
    isNumber: true,
  },
];

function enableSendButton(fields, initialFields) {
  let enable = false;
  Object.keys(fields).forEach(key => {
    if (fields[key] !== initialFields[key]) {
      enable = true;
    }
  });
  return enable;
}

function ModalContent(props: ModalEditProps) {
  const { form } = props;
  const { getFieldDecorator } = form;
  const [fields, setFields] = useState({});

  const onChange = field => event => {
    let value = event && event.target ? event.target.value : event;
    if (value !== props[field]) {
      setFields({ ...fields, [field]: value });
    } else {
      if (fields[field]) {
        delete fields[field];
        setFields({ ...fields });
      }
    }
  };

  const onSubmit = () => {
    props.onPressClose();
    props.onSubmit(fields);
  };

  return (
    <Form>
      <Row>
        {fieldsArray.map(field => {
          const InputComponent = field.isNumber ? InputNumber : Input;
          return (
            <Col xs={{ span: 12 }}>
              <div style={{ padding: 10 }}>
                <span>{field.label}</span>
                <FormItem name={field.key}>
                  {getFieldDecorator(field.key, {
                    rules: [
                      {
                        required: true,
                        message: 'Digite o ' + field.label,
                      },
                      ...(field.rules || []),
                    ],
                  })(
                    <InputComponent
                      formatter={field.formatter}
                      type={field.type}
                      parser={field.parser}
                      onChange={onChange(field.key, field.isMoney)}
                      style={{ width: '100%' }}
                    />,
                  )}
                </FormItem>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col xs={{ offset: 19 }}>
          <Button
            disabled={!enableSendButton(fields, props)}
            type='primary'
            style={{ marginTop: 20 }}
            onClick={onSubmit}
          >
            Atualizar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

function ModalEdit(props: ModalEditProps) {
  const {
    financialInstitutionKey,
    bankNumber,
    installmentValue,
    numberOfInstallments,
  } = props;
  useEffect(() => {
    props.form.setFieldsValue({
      financialInstitutionKey,
      bankNumber,
      installmentValue,
      numberOfInstallments,
    });
  }, [
    financialInstitutionKey,
    bankNumber,
    installmentValue,
    numberOfInstallments,
  ]);
  return (
    <div>
      <Modal
        keyboard
        maskClosable
        visible={props.visible}
        contentLabel='Confirm'
        key='modal'
        okText='Atualizar'
        onCancel={props.onPressClose}
        title='Editar campos'
      >
        <ModalContent {...props} />
      </Modal>
    </div>
  );
}

export default compose(
  Form.create({
    mapPropsToFields(props) {
      return {
        financialInstitutionKey: Form.createFormField({
          value: props.financialInstitutionKey,
        }),
        installmentValue: Form.createFormField({
          value: props.installmentValue,
        }),
        bankNumber: Form.createFormField({
          value: props.bankNumber,
        }),
        numberOfInstallments: Form.createFormField({
          value: props.numberOfInstallments,
        }),
      };
    },
  }),
)(ModalEdit);
