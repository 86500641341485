import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const TicketAccompanimentLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  p {
    text-overflow: ellipsis;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
  }

  svg {
    min-width: 14px;
    min-height: 14px;
  }

  .action-icon {
    cursor: pointer;
  }
`;

export const PopOverContentContainer = styled.div`
  padding: 1rem 0 0 2px;
  max-height: 300px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .ant-timeline-item-head {
    background-color: transparent !important;
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;

  /* h6 {
    font-family: 'Libre Franklin';
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #393939;
  }

  p {
    font-family: 'Libre Franklin';
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    color: #727272;
  } */
`;

export const MessageWrapper = styled.div`
  min-height: 64px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Text = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  weight: 500,
}))``;

export const SmallText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const TriggerText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.primary' }),
}))`
  text-overflow: ellipsis;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
`;

export const TableTriggerText = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'p',
  color: theme.colors.element.primary,
}))`
  text-overflow: ellipsis;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
`;
