import React from 'react';
import { Tooltip } from 'antd';

import { Item } from './Resets.styled';

type ItemWrapperProps = {
  value: string;
};

export function ItemWrapper({ value }: ItemWrapperProps) {
  return (
    <Tooltip placement="topLeft" title={value}>
      <Item>{value}</Item>
    </Tooltip>
  );
}
