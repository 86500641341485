import React, { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { CollapseProps } from 'antd/es/collapse';
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel';

import { themeToggleColor } from '~/common/utils/theme';
import { CaretDown } from '~/ui/assets/icons';
import {
  Collapsible as StyledCollapsible,
  CollapsiblePanel as StyledCollapsiblePanel,
} from './Collapsible.styled';

export type CollapsibleProps = PropsWithChildren<CollapseProps> & {};
export type CollapsiblePanelProps = PropsWithChildren<CollapsePanelProps> & {};

export function Collapsible(props: CollapsibleProps) {
  return <StyledCollapsible {...props} />;
}

export function CollapsiblePanel(props: CollapsiblePanelProps) {
  const theme = useTheme();

  return (
    <StyledCollapsiblePanel
      {...props}
      expandIcon={({ isActive }) => (
        <CaretDown
          width={12}
          height={12}
          color={themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' })}
          style={{ rotate: isActive ? '0deg' : '180deg' }}
        />
      )}
    />
  );
}

Collapsible.Panel = CollapsiblePanel;
