import { Map } from 'immutable';

import {
  TAGTYPES_DELETE_REQUEST,
  TAGTYPES_DELETE_FAILURE,
  TAGTYPES_DELETE_SUCCESS,
} from './action';

const initialState = Map({
  type: '',
  data: {},
  payload: [],
});

function reducer(state = initialState, action) {
  const { type, data } = action;

  if (type === TAGTYPES_DELETE_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => true)
      .updateIn(['isLoaded'], () => false)
      .updateIn(['data'], () => data)
      .setIn(['payload'], []);
  }

  if (type === TAGTYPES_DELETE_FAILURE) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], payload);
  }

  if (type === TAGTYPES_DELETE_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => true)
      .setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
