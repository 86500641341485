import styled, { css } from 'styled-components';
import { Button, Typography } from '~/ui/components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Timeline } from 'antd';

export const TransactionDetailsWrapper = styled.div`
  padding: 8px 0 8px 8px;
`;

export const StyledTimeline = styled(Timeline)`
  padding: 8px 0 0 2px;
  max-height: 300px;
  overflow: auto;

  .ant-timeline-item-content {
    display: inline-grid;
  }
`;

export const TransactionHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  padding: 18px 0;
  gap: 8px;
  width: 100%;
  justify-content: space-between;

  .close-icon {
    cursor: pointer;
    fill: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const TransactionTitle = styled(Typography).attrs({
  type: 'paragraphLarge',
  element: 'h4',
  weight: 600,
})``;

export const TopAlertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 14px;
`;

export const RecipientAlertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 14px;
`;

export const TransactionDate = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  weight: 400,
  color: theme.colors.element.secondary,
}))``;

export const Information = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  weight: 400,
  color: theme.colors.element.secondary,
}))`
  font-size: 12px;
  gap: 8px;
`;

export const InfoData = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  weight: 400,
  color: theme.colors.element.primary,
}))`
  font-size: 12px;
`;

export const Section = styled.div`
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  display: flex;
  flex-direction: column;
  padding: 18px 0;
  gap: 8px;
`;

export const SectionTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  element: 'h3',
  weight: 500,
  color: theme.colors.neutral.primaryAlt,
}))``;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
`;

export const InfoRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: baseline;
  justify-content: center;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InfoLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'label',
  weight: 500,
  color: themeToggleColor(theme, 'neutral.primaryAlt', { dark: 'element.primary' }),
}))``;

export const InfoValue = styled(Typography).attrs(({ theme, color, weight, type, pointer }) => ({
  type: type || 'caption',
  element: 'span',
  weight: weight || 400,
  color: color || theme.colors.neutral.primaryAlt,
}))`
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
      color: ${themeColor('brand.primary')};
    `}
`;

export const TransactionOrigin = styled(Section)`
  gap: 14px;
`;

export const TransactionRecipient = styled(Section)`
  gap: 18px;
`;

export const InstallmentsInfo = styled(Section)`
  gap: 18px;

  h3 {
    margin-bottom: 0;
  }
`;

export const TotalValue = styled(Typography).attrs({
  type: 'subtitleSmall',
  element: 'h4',
  weight: 600,
})`
  small {
    font-size: 60%;
    font-weight: 500;
  }
`;

export const CircleIcon = styled.div`
  width: 24px;
  height: 24px;
  border: 0.5px solid ${({ theme }) => theme.colors.element.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    color: ${({ theme }) => theme.colors.element.primary};
  }
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 0;
  gap: 24px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.15);

  button {
    padding: 12px 16px;
  }
  span {
    display: flex;
  }
`;

export const ActionButton = styled(Button).attrs(({ theme }) => ({
  rounded: true,
  fullWidth: true,
  customColor: themeToggleColor(theme, 'element.primary', { dark: 'brand.secondary' }),
}))``;
