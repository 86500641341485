const ContractType = (props: any) => {
  switch (props.value) {
    case 'LOAN':
      return 'Empréstimo';
    case 'CARD':
      return 'Cartão';
    default:
      return null;
  }
};

export default ContractType;
