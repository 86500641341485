import React from 'react';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: relative;
`;

export default Container;
