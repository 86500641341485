/* @flow */

export const SET_REFINANCED_AKEY_REQUEST = 'SET_REFINANCED_AKEY_REQUEST';
export const SET_REFINANCED_AKEY_SUCCESS = 'SET_REFINANCED_AKEY_SUCCESS';
export const SET_REFINANCED_AKEY_FAILURE = 'SET_REFINANCED_AKEY_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: SET_REFINANCED_AKEY_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: SET_REFINANCED_AKEY_FAILURE,
      payload: resource,
    });
  };
}

export default function setRefinancedAKey(refinancedContractId, loanHistoryId) {
  return dispatch => {
    dispatch({
      type: SET_REFINANCED_AKEY_REQUEST,
      payload: {
        refinancedContractId,
        loanHistoryId,
      },
    });
  };
}
