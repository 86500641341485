import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';

import { telli } from '~/services';

export const getIN100Process = createAsyncAction('GET_IN100_PROCESS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ id }) => telli.get(`/admin/in100/${id}/process`),
    onRequestSuccess: () => message.success('In100 reprocessada com sucesso.'),
    onRequestError: () => message.error('Falha ao reprocessar In100.'),
  },
});
