import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pick } from 'underscore';
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
  mapProps,
} from 'recompose';

import ConfirmRequest from '~/components/RequestAction/Button/ConfirmRequest';

import theme from '~/themes/aphroditeTheme/theme';

import { Form } from 'antd';

import message from '~/common/message';

import { getContractDetails } from '~/store/contracts/actions';

import confirmRequestRefusalContract, {
  CONFIRM_REQUEST_REFUSAL_CONTRACT_REQUEST,
  CONFIRM_REQUEST_REFUSAL_CONTRACT_SUCCESS,
  CONFIRM_REQUEST_REFUSAL_CONTRACT_FAILURE,
} from '~/store/confirmRequestRefusalContract/action';

import undoRequestRefusal, {
  UNDO_REFUSAL_REQUEST,
  UNDO_REFUSAL_SUCCESS,
  UNDO_REFUSAL_FAILURE,
} from '~/store/undoRequestRefusal/action';

import fetchContractRefusalReasons from '~/store/fetchContractRefusalReasons/action';

import { getLoggedUser } from '~/store/user/actions';
import styles from './styles';

const { withStyles } = theme;

function View(props: { isRequestable: any, isRequested: any }) {
  return (
    <ConfirmRequest
      isRequestable={props.isRequestable}
      isRequested={props.isRequested}
      hideReason
      confirmButtonTitle="Cancelar Contrato"
      secundaryButtonTitle="Desfazer solicitação"
      titleText="Confirme o cancelamento do contrato com sua senha"
      {...props}
    />
  );
}

function componentDidMount() {
  const { id } = this.props.match.params;

  this.props.dispatch(fetchContractRefusalReasons(id));
}

export function onPressConfirmRequestRefusalContract({ dispatch, match }) {
  return (password) => {
    const { id } = match.params;
    dispatch(confirmRequestRefusalContract(id, password));
  };
}

export function onPressUndoRequestRefusal({ dispatch, match }) {
  return (password) => {
    const { id } = match.params;
    dispatch(undoRequestRefusal(id, password));
  };
}

export function mapStateToProps(state) {
  return {
    details: getContractDetails.getResult()(state),
    detailsSuccess: getContractDetails.isSuccessful()(state),
    detailsFailure: getContractDetails.hasFailure()(state),
    confirmRequestRefusalContract: state.getIn([
      'confirmRequestRefusalContract',
    ]),
    undoRequestRefusal: state.getIn(['undoRequestRefusal']),
    refusalReasons: state.getIn(['fetchContractRefusalReasons', 'payload']),
    loggedUser: getLoggedUser.getResult()(state),
  };
}

export function receiveChanges(
  prevProps,
  {
    setIsRequestable,
    setIsRequestd,
    details,
    detailsSuccess,
    detailsFailure,
    confirmRequestRefusalContract,
    undoRequestRefusal,
    dispatch,
    match,
    isModalVisible,
    isLoading,
  },
) {
  if (prevProps.detailsSuccess !== detailsSuccess || prevProps.detailsFailure !== detailsFailure) {
    const { isRefusable, isRefusalRequested } = details;
    setIsRequestable(isRefusable || false);
    setIsRequestd(isRefusalRequested || false);
    return false;
  }

  if (
    Immutable.is(
      prevProps.confirmRequestRefusalContract,
      confirmRequestRefusalContract,
    ) === false
  ) {
    switch (confirmRequestRefusalContract.getIn(['type'])) {
      case CONFIRM_REQUEST_REFUSAL_CONTRACT_REQUEST: {
        isLoading(true);
        return true;
      }
      case CONFIRM_REQUEST_REFUSAL_CONTRACT_SUCCESS: {
        isLoading(false);
        isModalVisible(false);
        message.success('Cancelamento realizado com sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails(id));

        return true;
      }
      case CONFIRM_REQUEST_REFUSAL_CONTRACT_FAILURE: {
        const errorStatus = confirmRequestRefusalContract.getIn([
          'payload',
          'status',
        ]);
        const errorMessage = confirmRequestRefusalContract.getIn([
          'payload',
          'message',
        ]);

        isLoading(false);
        isModalVisible(false);

        if (errorStatus === 403) {
          message.error(errorMessage);
        } else {
          message.error('Falha ao confirmar cancelamento do contrato.');
        }

        return true;
      }
      default:
        return false;
    }
  }

  if (
    Immutable.is(prevProps.undoRequestRefusal, undoRequestRefusal) === false
  ) {
    switch (undoRequestRefusal.getIn(['type'])) {
      case UNDO_REFUSAL_REQUEST: {
        isLoading(true);
        return true;
      }
      case UNDO_REFUSAL_SUCCESS: {
        isLoading(false);
        isModalVisible(false);

        message.success('Solicitação de cancelamento desfeita com sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails(id));

        return true;
      }

      case UNDO_REFUSAL_FAILURE: {
        isLoading(false);
        isModalVisible(false);

        message.error('Falha ao desfazer a solicitação de cancelamento.');
        return true;
      }
      default:
        return false;
    }
  }

  return {};
}

export function propagateStateChangeToProps({
  isRequestable,
  isRequested,
  loggedUser,
}) {
  return {
    isRequestable,
    isRequested,
    loggedUser,
  };
}

const WrappedRequestRefusal = Form.create()(View);

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  lifecycle({
    componentDidMount,
  }),
  withState('loading', 'isLoading', false),
  withState('modalVisible', 'isModalVisible', false),
  withState('isRequestable', 'setIsRequestable', false),
  withState('isRequested', 'setIsRequestd', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onPressRequest: onPressConfirmRequestRefusalContract,
    onPressScundaryButton: onPressUndoRequestRefusal,
  }),
  mapProps((props) => pick(
    props,
    ...[
      'loggedUser',
      'reasons',
      'modalVisible',
      'isModalVisible',
      'loading',
      'isLoading',
      'isRequestable',
      'isRequested',
      'onPressRequest',
      'onPressScundaryButton',
      'styles',
    ],
  )),
)(WrappedRequestRefusal);
