import React, { useEffect, useState } from 'react';

import { Drawer } from './ChildrenDrawer.styled';

type getContainerFunc = () => HTMLElement;
type Container = string | HTMLElement | getContainerFunc | false;

export type ChildrenDrawerProps = {
  title?: React.ReactNode;
  visible?: boolean;
  children?: React.ReactNode;
  container?: Container;
};

export function ChildrenDrawer({ title, visible, children, container }: ChildrenDrawerProps) {
  const [position, setPosition] = useState('absolute');

  const getContainer = (currentContainer) => {
    if (currentContainer) {
      if (typeof currentContainer === 'string') {
        return document.querySelector(currentContainer);
      }
      return currentContainer;
    }
    return false;
  };

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setPosition('fixed');
      }, 300);
    } else {
      setPosition('absolute');
    }
  }, [visible]);

  return (
    <Drawer
      title={title}
      placement="bottom"
      closable={false}
      visible={visible}
      getContainer={getContainer(container)}
      height="auto"
      maskClosable={false}
      className="children-drawer"
      position={position}
    >
      {children}
    </Drawer>
  );
}
