const ColorPalettes = {
  success: { textColor: 'positive.primaryAlt', bgColor: 'positive.secondary' },
  danger: { textColor: 'negative.primaryAlt', bgColor: 'negative.secondary' },
  warning: { textColor: 'alert.primaryAlt', bgColor: 'alert.secondary' },
  info: { textColor: 'info.primaryAlt', bgColor: 'info.secondary' },
};

export const FGTSOpportunityStatusColors = {
  OK: ColorPalettes.success,
  PENDING_AUTHORIZATION: ColorPalettes.warning,
  PROCESSING: ColorPalettes.warning,
  BLOCKED: ColorPalettes.danger,
};

export const INSSOpportunityStatusColors = {
  OK: ColorPalettes.success,
  PORT_OUT: ColorPalettes.success,
  PROCESSING: ColorPalettes.warning,
  BLOCKED: ColorPalettes.danger,
  DID_NOT_ACCEPT_THE_CONSENT_FORM: ColorPalettes.warning,
  AGREEMENT_PASSWORD_NOT_REGISTERED: ColorPalettes.warning,
  OUTDATED_HISCON: ColorPalettes.warning,
  UNKNOWN: ColorPalettes.info,
};

export const PortabilityStatusColors = {
  UPDATED_HISCON: ColorPalettes.success,
  OUTDATED_HISCON: ColorPalettes.warning,
  WITHOUT_HISCON: ColorPalettes.warning,
};

export enum FGTSOpportunityStatus {
  OK = 'Oportunidade disponível',
  PROCESSING = 'Processando consulta de saldo',
  PENDING_AUTHORIZATION = 'Consulta de saldo pendente',
  BLOCKED = 'Oportunidade indisponível',
}

export enum INSSOpportunityStatus {
  OK = 'Oportunidade disponível',
  PROCESSING = 'Processando oportunidade',
  BLOCKED = 'Benefício bloqueado',
  DID_NOT_ACCEPT_THE_CONSENT_FORM = 'Não aceitou os termos',
  AGREEMENT_PASSWORD_NOT_REGISTERED = 'Senha não cadastrada',
  OUTDATED_HISCON = 'Hiscon desatualizado',
  UNKNOWN = 'Status desconhecido',
  PORT_OUT = 'Oportunidade exclusiva',
}

export enum PortabilityStatus {
  UPDATED_HISCON = 'Oportunidade disponível',
  OUTDATED_HISCON = 'Hiscon desatualizado',
  WITHOUT_HISCON = 'Hiscon pendente',
}
