import styled from 'styled-components';
import { Button } from '~/ui/components';
import { RightContainer } from '~/ui/components/Menu/Menu.styled';
import { themeColor } from '~/common/utils/theme';

export const Container = styled(RightContainer).attrs({
  className: 'settings-menu',
})`
  .submenu {
    nav {
      a {
        &.logout {
          border-top: 1px solid #dddddd;
          padding-top: 16px;
          margin-bottom: 8px;

          svg {
            color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
          }
        }
      }
    }
  }
}
`;

export const SettingsButton = styled(Button).attrs({
  variant: 'text',
  onlyIcon: true,
  icon: 'Settings',
})`
  min-width: 24px;
  max-width: 24px;
  padding: 0;

  svg {
    color: ${({ theme }) => theme.colors.element.primary} !important;
  }

  &:hover {
    svg {
      opacity: 0.8;
    }
  }
`;
