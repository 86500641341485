/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { mapObject } from 'underscore';
import moment from 'moment';

import {
  SEND_CCB_FOR_USER_SIGNATURE_REQUEST,
  success,
  failure,
} from './action';

function stripMoneyValue(value) {
  // Divide por 100 devido a retirada da virgula
  return value.replace(/[.,\s]/g, '').replace(/^0+/, '') / 100;
}

const prepare = payload => {
  const { contractId } = payload;
  const values = mapObject(payload.values, (value, key) => {
    if (key === 'paymentDate') return moment(value, 'DD/MM/YYYY');
    if (key === 'numberOfInstallments') return value;
    return stripMoneyValue(value);
  });
  return {
    values,
    contractId,
  };
};

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(SEND_CCB_FOR_USER_SIGNATURE_REQUEST)
    .map(({ payload }) => prepare(payload))
    .mergeMap(({ contractId, values }) =>
      AjaxRequest.open(
        'post',
        `/admin/contractcustom/contracts/${contractId}/sendccbforusersignature`,
        values,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
