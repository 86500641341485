/* @flow */

import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, lifecycle, withPropsOnChange, withState } from 'recompose';
import { Button, Icon } from 'antd';
import Masker from 'vanilla-masker';
import theme from '~/themes/aphroditeTheme/theme';
import masked from '~/common/masked';
import { Spinner, Table } from '~/components';

import setRefinancedAkey, {
  SET_REFINANCED_AKEY_REQUEST,
  SET_REFINANCED_AKEY_SUCCESS,
  SET_REFINANCED_AKEY_FAILURE,
} from '~/store/setRefinancedAkey/action';

import fetchLoanHistories, {
  LOAN_HISTORIES_FETCH_REQUEST,
  LOAN_HISTORIES_FETCH_SUCCESS,
  LOAN_HISTORIES_FETCH_FAILURE,
} from '~/store/fetchLoanHistories/action';

import styles from './styles';

const { css, withStyles } = theme;

const { toDate } = masked;

const moneyFormatter = {
  unit: 'R$',
};

type Props = {
  styles: any,
  refinancedContractId: any,
  loanHistories: any,
  emptyMessage: any,
  isLoading: any,
};

class LinkContract extends Component<Props> {
  presentation = {
    columns: [
      {
        accessor: 'bankNumber',
        name: 'Banco',
        props: {
          width: 75,
        },
      },
      {
        accessor: 'financialInstitutionKey',
        name: 'Cod. Banco',
      },
      {
        accessor: 'reservationKey',
        name: 'Chave Reserva',
        props: {
          width: 130,
        },
      },
      {
        accessor: 'installmentValue',
        name: 'Parcela',
        props: {
          Cell: row => {
            if (row.original.installmentValue) {
              return Masker.toMoney(
                row.original.installmentValue.toFixed(2),
                moneyFormatter,
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'numberOfInstallments',
        name: 'Parcelas',
        props: {
          width: 94,
          Cell: row => (
            <div className='text-center'>
              {row.original.numberOfInstallments}
            </div>
          ),
        },
      },
      {
        accessor: 'contractValue',
        name: 'V. Contrato',
        props: {
          width: 105,
          Cell: row => {
            if (row.original.contractValue) {
              return Masker.toMoney(
                row.original.contractValue.toFixed(2),
                moneyFormatter,
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'contractDate',
        name: 'Data',
        props: {
          Cell: row => <div>{toDate(row.original.contractDate)}</div>,
        },
      },
      {
        name: 'Vincular',
        props: {
          width: 100,
          Cell: row => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() =>
                  this.onPressLinkContract(row.original.loanHistoryId)
                }
              >
                <Icon type='link' />
              </Button>
            </div>
          ),
        },
      },
    ],
  };

  onPressLinkContract = loanHistoryId => {
    const { dispatch, refinancedContractId } = this.props;
    dispatch(setRefinancedAkey(refinancedContractId, loanHistoryId));
  };

  renderTable() {
    const { loanHistories, styles } = this.props;

    return (
      <Table
        items={loanHistories}
        presentation={this.presentation}
        className={css(styles.table).className}
        hidePagination
      />
    );
  }

  renderEmpty() {
    const { emptyMessage, isLoading, styles } = this.props;

    if (isLoading || !emptyMessage) return null;

    return (
      <div {...css(styles.status)}>
        <i>{emptyMessage}</i>
      </div>
    );
  }

  render() {
    const { styles } = this.props;

    return (
      <div {...css(styles.container)}>
        <Spinner spinning={this.props.isLoading} />
        <div>
          {this.renderTable()}
          {this.renderEmpty()}
        </div>
      </div>
    );
  }
}

LinkContract.defaultProps = {
  loanHistories: [],
};

function componentDidMount() {
  const { personId, bankNumber, installmentValue } = this.props;

  const params = {
    personId,
    bankNumber,
    installmentValue,
  };

  this.props.dispatch(fetchLoanHistories(params));
}

function mapStateToProps(state) {
  return {
    loanHistories: state.getIn(['fetchLoanHistories']),
    setRefinancedAkey: state.getIn(['setRefinancedAkey']),
  };
}

function receiveChanges(
  prevProps,
  {
    loanHistories,
    setRefinancedAkey,
    isLoading,
    setIsLoading,
    setEmptyMessage,
  },
) {
  if (Immutable.is(prevProps.loanHistories, loanHistories) === false) {
    switch (loanHistories.getIn(['type'])) {
      case LOAN_HISTORIES_FETCH_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case LOAN_HISTORIES_FETCH_SUCCESS: {
        setIsLoading(false);
        if (loanHistories.getIn(['payload']).entries.length !== 0) {
          setEmptyMessage('');
        }
        return true;
      }
      case LOAN_HISTORIES_FETCH_FAILURE: {
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }
  if (Immutable.is(prevProps.setRefinancedAkey, setRefinancedAkey) === false) {
    switch (setRefinancedAkey.getIn(['type'])) {
      case SET_REFINANCED_AKEY_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case SET_REFINANCED_AKEY_SUCCESS: {
        setIsLoading(false);
        return true;
      }
      case SET_REFINANCED_AKEY_FAILURE: {
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  return false;
}

function propagateStateChangeToProps(state) {
  const { entries } = state.loanHistories.toJS().payload;
  return {
    loanHistories: entries,
  };
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  lifecycle({
    componentDidMount,
  }),
  withState('isLoading', 'setIsLoading', false),
  withState('emptyMessage', 'setEmptyMessage', 'Nenhum Registro Encontrado'),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
)(LinkContract);
