import styled from 'styled-components';
import { Divider as DividerComponent, Upload } from 'antd';

import { ColorsTypes } from '~/ui/theme/colors';
import { Typography } from '~/ui/components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

const { Dragger: DraggerComponent } = Upload;

interface PalettedProps {
  colorPalette?: ColorsTypes;
}

export const Info = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: 400,
  element: 'span',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const Dragger = styled(DraggerComponent)`
  margin-top: 10px;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })} !important;
  border-color: ${({ theme }) => theme.colors.divider.primary} !important;
  border-radius: 16px !important;
  padding: 0 16px;

  > span.ant-upload-btn {
    padding: 24px 0 !important;
  }

  :hover {
    background: ${({ theme }) => theme.colors.background.secondary} !important;
  }
`;

export const DraggerClickLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: 500,
  element: 'span',
  color: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
}))``;

export const DraggerLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: 400,
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const Caption = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  margin-top: 2px;
`;

export const FooterDivider = styled(DividerComponent)<PalettedProps>`
  margin: 15px 0 10px !important;
  height: 2px !important;
  background: ${({ theme }) => theme.colors.divider.primary} !important;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`;

export const AlertWrapper = styled.div`
  padding-top: 10px;
`;

export const ErrorMessage = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const DraggerContainer = styled.div`
  .ant-upload-list-item {
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    svg {
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};
      fill: ${themeColor('element.primary', { dark: 'element.secondary' })};
    }

    &:hover {
      .ant-upload-list-item-info {
        background: ${({ theme }) => theme.colors.background.transparent};
      }
    }
  }
`;
