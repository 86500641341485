import styled from 'styled-components';
import ReactCalendar from 'react-calendar';
import { themeColor } from '~/common/utils/theme';

export const CalendarStyled = styled(ReactCalendar)`
  border: none;
  background: ${({ theme }) => theme.colors.background.primary};

  * {
    font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.colors.background.transparent};
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    text-decoration: underline;
  }

  .react-calendar__tile--hover {
    background: ${({ theme }) => theme.colors.state.active};
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    text-decoration: underline;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: ${({ theme }) => theme.colors.brand.secondaryAlt};
    color: ${({ theme }) => theme.colors.element.invert};
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${({ theme }) => theme.colors.brand.secondaryAlt};
  }

  .react-calendar__tile {
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};

    &:disabled {
      opacity: 0.1;
      background: ${({ theme }) => theme.colors.background.transparent};
    }

    &:hover {
      background: ${({ theme }) => theme.colors.state.hover};
    }

    &:focus {
      background: ${({ theme }) => theme.colors.state.hover};
    }
  }

  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label,
  .react-calendar__month-view__weekdays__weekday {
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};

    &:disabled {
      opacity: 0.1;
      background: ${({ theme }) => theme.colors.background.transparent};
    }
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${({ theme }) => theme.colors.state.hover};
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
  }
`;
