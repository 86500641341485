import Immutable from 'immutable';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pick, isUndefined } from 'underscore';
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withState,
  mapProps,
} from 'recompose';
import { Button, Col, Form, Row, DatePicker } from 'antd';
import theme from '~/themes/aphroditeTheme/theme';
import { X } from 'react-feather';

import 'moment/locale/pt-br';
import locale from 'antd/lib/date-picker/locale/pt_BR';

import messageAlert from '~/common/message';

import uploadMedicalCertificate, {
  UPLOAD_MEDICAL_CERTIFICATE_REQUEST,
  UPLOAD_MEDICAL_CERTIFICATE_FAILURE,
  UPLOAD_MEDICAL_CERTIFICATE_SUCCESS,
} from '~/store/uploadMedicalCertificate/action';

import { Action } from '../Actions';
import styles from './styles';

const { css, withStyles } = theme;
const { Item: FormItem } = Form;
import { Spinner } from '~/components';
const dateFormat = 'DD/MM/YYYY';

class SendMedicalCertificate extends Component {
  state = {
    medicalCertificate: '',
    medicalCertificateDate: '',
  };

  onPressSubmit = e => {
    e.preventDefault();
    const { medicalCertificate, medicalCertificateDate } = this.state;
    this.props.onPressUploadMedicalCertificate(
      medicalCertificate,
      medicalCertificateDate,
    );
    this.resetState();
  };

  resetState = () =>
    this.setState({
      medicalCertificate: '',
      medicalCertificateDate: '',
      fileName: '',
    });

  onPressOpen = () => {
    this.props.isModalVisible(true);
  };

  onPressClose = () => {
    this.props.isModalVisible(false);
  };

  onPressInputFile = () => {
    this.inputFile.click();
  };

  onComplete = () => {
    const { medicalCertificate, medicalCertificateDate } = this.state;
    return medicalCertificate && medicalCertificateDate;
  };

  onMedicalCertificateDate = medicalCertificateDate => {
    this.setState({ medicalCertificateDate });
  };

  onPressUploadFile = (ev: Event) => {
    const reader = new FileReader();
    const file = ev.target.files[0];

    if (isUndefined(file)) {
      return;
    }
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.setState({ medicalCertificate: file, fileName: file.name });
    };
  };

  renderInputFile = () => {
    return (
      <input
        ref={ref => {
          this.inputFile = ref;
        }}
        type='file'
        accept='application/pdf'
        onChange={this.onPressUploadFile}
        style={{ display: 'none' }}
      />
    );
  };

  render() {
    const { agreementName, styles } = this.props;
    const { fileName } = this.state;
    const style = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s',
        background: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1000,
      },
    };

    if (agreementName !== 'INSS') return null;

    return [
      <Action
        key='button'
        onPress={this.onPressOpen}
        style={css(styles.action)}
        title='Upload'
      />,
      <Modal
        ariaHideApp={false}
        contentLabel='Confirm'
        isOpen={this.props.modalVisible}
        key='modal'
        style={style}
        {...css(styles.modal)}
      >
        <Spinner spinning={this.props.loading} />

        <div {...css(styles.modal__header)}>
          <span {...css(styles.modal__title)}>
            Enviar Arquivo de Perícia Médica
          </span>
          <button onClick={this.onPressClose} {...css(styles.modal__close)}>
            <X {...css(styles.closeButton)} size={20} />
          </button>
        </div>
        <Form>
          <Row>
            <Col xs={{ span: 12 }}>
              <span>Data:</span>
              <FormItem>
                <DatePicker
                  placeholder='Informe a data da perícia'
                  locale={locale}
                  format={dateFormat}
                  onChange={this.onMedicalCertificateDate}
                />
              </FormItem>
            </Col>
            <Col xs={{ span: 11, offset: 1 }}>
              <span>Arquivo:</span>
              <FormItem>
                <Button onClick={this.onPressInputFile} type='primary'>
                  {fileName
                    ? `${fileName.substring(0, 20)}...`
                    : 'Selecionar Arquivo'}
                </Button>
              </FormItem>
              {this.renderInputFile()}
            </Col>
          </Row>
          <Button
            onClick={this.onPressSubmit}
            disabled={!this.onComplete()}
            {...css(styles.buttonConfirm)}
          >
            Enviar
          </Button>
        </Form>
      </Modal>,
    ];
  }
}

SendMedicalCertificate.defaultProps = {
  loading: false,
  modalVisible: false,
};

export function onPressUploadMedicalCertificate({ dispatch, match }) {
  return (medicalCertificate, medicalCertificateDate) => {
    const { personId } = match.params;

    dispatch(
      uploadMedicalCertificate({
        personId,
        medicalCertificate,
        medicalCertificateDate,
      }),
    );
  };
}

export function mapStateToProps(state) {
  return {
    uploadMedicalCertificate: state.getIn(['uploadMedicalCertificate']),
  };
}

export function receiveChanges(
  prevProps,
  { uploadMedicalCertificate, isLoading, isModalVisible },
) {
  if (
    Immutable.is(
      prevProps.uploadMedicalCertificate,
      uploadMedicalCertificate,
    ) === false
  ) {
    switch (uploadMedicalCertificate.getIn(['type'])) {
      case UPLOAD_MEDICAL_CERTIFICATE_REQUEST: {
        isLoading(true);
        return true;
      }
      case UPLOAD_MEDICAL_CERTIFICATE_SUCCESS: {
        isLoading(false);
        isModalVisible(false);
        messageAlert.success('Arquivo Enviada com Sucesso.');
        return true;
      }
      case UPLOAD_MEDICAL_CERTIFICATE_FAILURE: {
        isLoading(false);
        isModalVisible(false);
        messageAlert.error('Falha ao Enviar a Arquivo.');
        return true;
      }
      default:
        return false;
    }
  }

  return {};
}

export function propagateStateChangeToProps({
  uploadMedicalCertificate,
  loading,
  modalVisible,
}) {
  return {
    uploadMedicalCertificate: uploadMedicalCertificate.getIn(['payload']),
    loading,
    modalVisible,
  };
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  withState('loading', 'isLoading', false),
  withState('modalVisible', 'isModalVisible', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onPressUploadMedicalCertificate,
  }),
  mapProps(props =>
    pick(
      props,
      ...[
        'loading',
        'isLoading',
        'modalVisible',
        'isModalVisible',
        'onPressUploadMedicalCertificate',
        'agreementName',
        'styles',
      ],
    ),
  ),
)(SendMedicalCertificate);
