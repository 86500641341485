import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const TicketFieldValue = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.main};

  p {
    margin: 0;
  }

  svg {
    margin-top: 2px;
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TicketLink = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
`;
