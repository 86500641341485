/* @flow */

export const REVERT_TO_CETELEM_REQUEST = 'REVERT_TO_CETELEM_REQUEST';
export const REVERT_TO_CETELEM_SUCCESS = 'REVERT_TO_CETELEM_SUCCESS';
export const REVERT_TO_CETELEM_FAILURE = 'REVERT_TO_CETELEM_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: REVERT_TO_CETELEM_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: REVERT_TO_CETELEM_FAILURE,
      payload: resource,
    });
  };
}

export default function get(contractId) {
  return dispatch => {
    dispatch({
      type: REVERT_TO_CETELEM_REQUEST,
      payload: {
        contractId,
      },
    });
  };
}
