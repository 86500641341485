import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isUndefined } from 'underscore';
import { useAwaitControl } from 'react-redux-await-control';

import { useModal } from '~/hooks/useModal';
import { DownloadHiscon } from '~/components';
import HisconHistoric from '~/components/HisconHistoric';
import { Action } from '~/components/PersonActions/Actions';
import {
  uploadHisconByEnrollment,
  requestHisconUpdate,
  getHisconByEnrollment,
} from '~/store/enrollments/actions';
import moment from 'moment/moment';
import useUserRoles from '~/hooks/useUserRoles';

const isAuthorized = true;

export const HisconContext = React.createContext({
  personId: undefined,
});

export default function HisconActions(props) {
  const {
    personId,
    agreementName,
    lastRequestHisconUpdate,
    hisconAutoUpdateFailure,
    contractDetails,
  } = props;
  const inputNewEl = useRef(null);
  const inputOldEl = useRef(null);
  const [hisconRequested, setHisconRequested] = useState(false);
  const { isAdmin } = useUserRoles();
  const dispatch = useDispatch();

  const getHisconControl = useAwaitControl(getHisconByEnrollment);
  const hisconLoaded = getHisconControl.isSuccessful();
  const hisconData = getHisconControl.result();

  const enrollmentId = contractDetails?.person?.enrollmentId;

  const { openModal } = useModal();

  const hisconLastUpdate = useMemo(() => {
    const format = 'DD/MM/YYYY [às] HH[h]mm';

    if (hisconRequested) {
      return moment().format(format);
    }

    return moment(lastRequestHisconUpdate).format(format);
  }, [lastRequestHisconUpdate, hisconRequested]);

  const canRequestHisconAutoUpdate = useMemo(() => {
    if (!lastRequestHisconUpdate || hisconAutoUpdateFailure || isAdmin || isAuthorized) return true;
    if (hisconRequested) return false;
    const date = moment(lastRequestHisconUpdate);
    return moment().diff(date, 'hours') >= 24;
  }, [lastRequestHisconUpdate, hisconAutoUpdateFailure, hisconRequested, isAdmin]);

  const opeHisconHistoric = () => {
    openModal(<HisconHistoric id={enrollmentId} />, {
      title: 'Histórico de extratos',
      closable: true,
    });
  };

  const onPressInputFile = useCallback(
    (version) => () => {
      if (inputNewEl.current && inputOldEl.current) {
        if (version === 'old') {
          inputOldEl.current.click();
        } else {
          inputNewEl.current.click();
        }
      }
    },
    [inputNewEl, inputOldEl],
  );

  const onPressRequestUpdate = useCallback(() => {
    setHisconRequested(true);
    dispatch(requestHisconUpdate.start({ enrollmentId }));
  }, [dispatch, personId, setHisconRequested, enrollmentId]);

  const onPressOpenHiscon = useCallback(() => {
    dispatch(getHisconByEnrollment.start({ enrollmentId }));
  }, [enrollmentId]);

  const onPressUploadHiscon = useCallback(
    (version: HisconVersion = 'old') =>
      (ev) => {
        const reader = new FileReader();
        const file = ev.target.files[0];

        if (isUndefined(file)) {
          return;
        }

        reader.readAsDataURL(file);
        reader.onload = () => {
          dispatch(uploadHisconByEnrollment.start({ enrollmentId, file, version }));

          if (version === 'old') {
            inputOldEl.current.value = null;
          } else {
            inputNewEl.current.value = null;
          }
        };
      },
    [inputNewEl, inputOldEl, enrollmentId],
  );

  useEffect(() => {
    if (hisconLoaded) {
      const downloadUrl = window.URL.createObjectURL(hisconData);

      window.open(downloadUrl, '__blank');
      window.URL.revokeObjectURL(downloadUrl);
    }

    return () => {
      getHisconControl.clear();
    };
  }, [hisconLoaded]);

  const subActions = useMemo(
    () => [
      {
        name: 'Abrir hiscon',
        onPress: onPressOpenHiscon,
      },
      !props.disableUpload && { name: 'Upload', onPress: onPressInputFile('old') },
      !props.disableUpload && {
        name: 'Upload (Novo modelo)',
        onPress: onPressInputFile('new'),
      },
      { name: 'Histórico', onPress: opeHisconHistoric },
      {
        name: 'Solicitar atualização',
        onPress: canRequestHisconAutoUpdate ? onPressRequestUpdate : null,
        disabled: !canRequestHisconAutoUpdate,
        tooltip: !canRequestHisconAutoUpdate
          ? `É necessário aguardar 24 horas a partir da última atualização. A última solicitação foi feita em: ${hisconLastUpdate}`
          : '',
      },
    ],
    [
      personId,
      agreementName,
      props.disableUpload,
      canRequestHisconAutoUpdate,
      hisconLastUpdate,
      hisconRequested,
      enrollmentId,
    ],
  );

  if (agreementName !== 'INSS') return null;

  return (
    <HisconContext.Provider
      value={{
        personId,
      }}
    >
      <Action icon="diff" title="Hiscon" placement="topRight" subActions={subActions} />
      <input
        ref={inputNewEl}
        type="file"
        accept="application/pdf"
        onChange={onPressUploadHiscon('new')}
        style={{ display: 'none' }}
      />
      <input
        ref={inputOldEl}
        type="file"
        accept="application/pdf"
        onChange={onPressUploadHiscon('old')}
        style={{ display: 'none' }}
      />
    </HisconContext.Provider>
  );
}
