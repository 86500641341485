import styled, { css } from 'styled-components';

const admin = 'Administrador';

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 5px;
  height: 20px;
  min-width: 20px;
  padding-inline: 6px;
  border-radius: 3px 10px 10px 3px;
  background-color: ${({ theme, user }) => (user === admin ? theme.primaryColor : '#FFD6E7')};

  :hover {
    background-color: ${({ theme, user }) => (user === admin ? theme.primaryColor : '#FFEAF3')};
  }

  ${({ addType, theme }) => addType
    && css`
      background-color: ${theme.grayDark};
      cursor: pointer;

      :hover {
        background-color: ${theme.primaryColor};
      }
    `}

  svg {
    fill: #fff;
  }

  span {
    color: ${({ user }) => ((user === admin || !user) ? '#FFF' : '#000')};;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
`;

export const TagRemoveContainer = styled.div`
  display: none;
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
  border-radius: 50%;
  background-color: ${({ theme, strong }) => (strong ? theme.primaryColor : '#FFD6E7')};
  align-items: center;
  justify-content: center;
  ${TagContainer}:hover & {
    display: flex;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  
  i {
    font-size: 12px;
  }
`;
