import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAwaitControl } from 'react-redux-await-control';

import ImmutableSelectorGenerator from '~/common/ImmutableSelectorGenerator';

import saveTagTypes from '~/store/tagTypesSave/action';

import { withRouter } from 'react-router';
import SearchBy from '~/common/SearchBy';
import { Icon, Popconfirm, Tooltip } from 'antd';
import { Spinner } from '~/components';
import { addTag, deleteTag } from '~/store/tags/actions';
import { listTagTypes } from '~/store/tagTypes/actions';
import { getLoggedUser } from '~/store/user/actions';
import {
  ActionContainer,
  Container,
  Input,
  NameContainer,
  TagTypeContainer,
  TypesContainer,
} from './AddContent.styles';

type AddContentProps = {
  type: 'CONTRACT' | 'PERSON',
  match: any,
  tags: any[],
};

type TagTypeItem = {
  name: string,
  id: number,
  isAdmin: boolean,
  onClick: () => void,
};

function TagType(props: TagTypeItem) {
  const { name, id, onClick, isAdmin } = props;
  const removeTagControl = useAwaitControl(deleteTag);

  const onRemove = useCallback((id) => {
    removeTagControl.start({ id });
  }, []);

  return (
    <TagTypeContainer key={id}>
      <NameContainer onClick={onClick}>
        <span>{name}</span>
      </NameContainer>
      {isAdmin ? (
        <ActionContainer>
          <Popconfirm
            placement="left"
            title="Deseja realmente excluir a tag?"
            okText="Sim"
            onConfirm={() => onRemove(id)}
            cancelText="Não"
          >
            <Icon type="delete" />
          </Popconfirm>
        </ActionContainer>
      ) : null}
    </TagTypeContainer>
  );
}

function AddContent(props: AddContentProps) {
  const { match, tags, type } = props;
  const [showCreateError, setShowError] = useState(false);
  const user = useSelector(getLoggedUser.getResult());
  const tagTypesDelete = useSelector(ImmutableSelectorGenerator.generate(['tagTypesDelete']));

  const { roles } = user;
  const isAdmin = roles.includes('ADMINISTRATOR');
  const { id: idParam, personId } = match.params;
  const externalId = idParam || personId;
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const [addTagControl, listTagTypesControl] = useAwaitControl([addTag, listTagTypes]);

  const listTagTypesResult = listTagTypesControl.result(type);

  const tagTypes = useMemo(() => {
    const filteredData = (listTagTypesResult || []).filter((i) => !tags.some((t) => t.id === i.id));
    return SearchBy(filteredData, search, 'name');
  }, [listTagTypesResult, search]);

  const onClick = (id: number) => () => {
    if (id) {
      addTagControl.start({ externalId, tagTypeId: id, type });
      return;
    }
    if (search) {
      if (isAdmin) {
        dispatch(saveTagTypes({ externalId, name: search, tagResourceType: type }));
      } else {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
      }
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && search) {
      const types = SearchBy(tagTypes, search, 'name');
      if (types.length === 1) {
        onClick(types[0].id)();
        setSearch('');
        return;
      }
      onClick()();
      setSearch('');
    }
  };

  return (
    <Container>
      <Spinner spinning={tagTypesDelete.getIn(['isLoading'])} size="sm" />
      <Tooltip
        title="Você não tem permissão para criar tags"
        placement="top"
        visible={showCreateError}
      >
        <Input
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onKeyDown={onKeyDown}
          placeholder={isAdmin ? 'Buscar ou criar tag...' : 'Buscar tag...'}
          autoFocus
        />
      </Tooltip>
      <TypesContainer>
        {tagTypes.map((tagType) => (
          <TagType key={tagType.id} isAdmin={isAdmin} onClick={onClick(tagType.id)} {...tagType} />
        ))}
      </TypesContainer>
    </Container>
  );
}

export default withRouter(AddContent);
