/* @flow */

export const OPERATION_STEPS_BY_CONTRACT_REQUEST =
  'OPERATION_STEPS_BY_CONTRACT_REQUEST';
export const OPERATION_STEPS_BY_CONTRACT_SUCCESS =
  'OPERATION_STEPS_BY_CONTRACT_SUCCESS';
export const OPERATION_STEPS_BY_CONTRACT_FAILURE =
  'OPERATION_STEPS_BY_CONTRACT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: OPERATION_STEPS_BY_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: OPERATION_STEPS_BY_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(id) {
  return dispatch => {
    dispatch({
      type: OPERATION_STEPS_BY_CONTRACT_REQUEST,
      payload: id,
    });
  };
}
