import React, { useEffect } from 'react';
import { AssessmentBody } from '~/screens/Tickets/Assessment/Assessment.styled';
import { Flex, Typography, withStepperProps } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import {
  getReasons,
  getTicketJourneys,
  getTicketResolutionReasons,
  getTicketStatus,
  selectAssessment,
} from '~/store/tickets/actions';

const WRONG_FIELDS = {
  JOURNEY: 'Jornada',
  REASON: 'Motivo',
  STATUS: 'Status',
  JUSTIFICATION: 'Justificativa de resolução',
};

export const Details = withStepperProps(() => {
  const getJourneysControl = useAwaitControl(getTicketJourneys);
  const journeys = getJourneysControl.result();

  const getReasonsControl = useAwaitControl(getReasons);
  const reasons = getReasonsControl.result();

  const getStatusControl = useAwaitControl(getTicketStatus);
  const status = getStatusControl.result();

  const getResolutionReasonsControl = useAwaitControl(getTicketResolutionReasons);
  const resolutionReasons = getResolutionReasonsControl.result();

  const selectAssessmentControl = useAwaitControl(selectAssessment);
  const selectedAssessment = selectAssessmentControl.result();

  const getBooleanValue = (value: boolean | string) => {
    if (value === true || value === 'true') return 'Sim';
    if (value === false || value === 'false') return 'Não';
    return '-';
  };

  const translateWrongFields = (fields: string[]) =>
    fields?.map((field: string) => WRONG_FIELDS[field]).join(', ') || '-';

  const getJourney = (journeyId: number) => {
    const journey = journeys?.find((j) => j.id === journeyId);
    return journey?.name || '-';
  };

  const getReason = (reasonId: number) => {
    const reason = reasons?.find((r) => r.id === reasonId);
    return reason?.name || '-';
  };

  const getStatus = (statusId: number) => {
    const statusItem = status?.find((s) => s.id === statusId);
    return statusItem?.name || '-';
  };

  const getResolutionReason = (resolutionReasonId: number) => {
    const resolutionReason = resolutionReasons?.find((r) => r.id === resolutionReasonId);
    return resolutionReason?.justification || '-';
  };

  useEffect(() => {
    if (!journeys.length) getJourneysControl.start();
    if (!reasons.length) getReasonsControl.start();
    if (!status.length) getStatusControl.start();
    if (!resolutionReasons.length) getResolutionReasonsControl.start();
  }, []);

  return (
    <AssessmentBody>
      <Flex direction="column" gap={32}>
        <Flex direction="column" gap={24}>
          <Typography type="bodyLarge" weight={700}>
            Bloco Ouvir
          </Typography>

          <Flex direction="column" gap={16}>
            <Flex direction="column" gap={4}>
              <Typography type="bodyMedium" weight={600}>
                Análise do histórico
              </Typography>
              <Typography type="bodyMedium">
                {getBooleanValue(selectedAssessment?.historyAnalyzed)}
              </Typography>
            </Flex>

            <Flex direction="column" gap={4}>
              <Typography type="bodyMedium" weight={600}>
                Escuta ativa
              </Typography>
              <Typography type="bodyMedium">
                {getBooleanValue(selectedAssessment?.activeListening)}
              </Typography>
            </Flex>

            <Flex direction="column" gap={4}>
              <Typography type="bodyMedium" weight={600}>
                Discovery de problemas ou melhorias
              </Typography>
              <Typography type="bodyMedium">
                {getBooleanValue(selectedAssessment?.problemDiscovery)}
              </Typography>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" gap={24}>
          <Typography type="bodyLarge" weight={700}>
            Bloco Resolver
          </Typography>

          <Flex direction="column" gap={16}>
            <Flex direction="column" gap={4}>
              <Typography type="bodyMedium" weight={600}>
                Proatividade e solução de dúvidas
              </Typography>
              <Typography type="bodyMedium">
                {getBooleanValue(selectedAssessment?.proactivity)}
              </Typography>
            </Flex>

            <Flex direction="column" gap={4}>
              <Typography type="bodyMedium" weight={600}>
                Resolução de objeções e inseguranças
              </Typography>
              <Typography type="bodyMedium">
                {getBooleanValue(selectedAssessment?.reassuring)}
              </Typography>
            </Flex>

            <Flex direction="column" gap={4}>
              <Typography type="bodyMedium" weight={600}>
                Execução dos procedimentos de atendimento
              </Typography>
              <Typography type="bodyMedium">
                {getBooleanValue(selectedAssessment?.proceduresExecuted)}
              </Typography>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" gap={24}>
          <Typography type="bodyLarge" weight={700}>
            Bloco Reportar
          </Typography>

          <Flex direction="column" gap={16}>
            {selectedAssessment?.wrongFields?.length ? (
              <Flex direction="column" gap={4}>
                <Typography type="bodyMedium" weight={600}>
                  Quais os campos incorretos?
                </Typography>
                <Typography type="bodyMedium">
                  {translateWrongFields(selectedAssessment?.wrongFields)}
                </Typography>
              </Flex>
            ) : (
              <Typography type="bodyMedium">Sem campos incorretos</Typography>
            )}

            {!!selectedAssessment?.correctJourneyId && (
              <Flex direction="column" gap={4}>
                <Typography type="bodyMedium" weight={600}>
                  Qual é a jornada correta?
                </Typography>
                <Typography type="bodyMedium">
                  {getJourney(selectedAssessment?.correctJourneyId)}
                </Typography>
              </Flex>
            )}

            {!!selectedAssessment?.correctReasonId && (
              <Flex direction="column" gap={4}>
                <Typography type="bodyMedium" weight={600}>
                  Qual é o motivo correto?
                </Typography>
                <Typography type="bodyMedium">
                  {getReason(selectedAssessment?.correctReasonId)}
                </Typography>
              </Flex>
            )}

            {!!selectedAssessment?.correctStatusId && (
              <Flex direction="column" gap={4}>
                <Typography type="bodyMedium" weight={600}>
                  Qual é o status de resolução correto?
                </Typography>
                <Typography type="bodyMedium">
                  {getStatus(selectedAssessment?.correctStatusId)}
                </Typography>
              </Flex>
            )}

            {!!selectedAssessment?.correctJustificationResolutionId && (
              <Flex direction="column" gap={4}>
                <Typography type="bodyMedium" weight={600}>
                  Qual é a justificativa de resolução correta?
                </Typography>
                <Typography type="bodyMedium">
                  {getResolutionReason(selectedAssessment?.correctJustificationResolutionId)}
                </Typography>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </AssessmentBody>
  );
});
