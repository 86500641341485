import React, { useMemo, useRef } from 'react';

import JsonToCSV from '~/common/jsonToCSV';
import useDimensions from '~/hooks/useDimensions';
import { GenericLog } from '~/typings/entities/genericLog';
import { Card } from '~/components';
import Table from '~/components/Table';

import usePresentation from './usePresentation';
import { Container, Footer, LogInfo, DownloadLogs } from './styled';

type TableProps = {
  data: GenericLog[],
  loading: boolean,
};

export default function LogTable({ data = [], loading = false }: TableProps) {
  const containerRef = useRef(null);

  const { width, height } = useDimensions(containerRef.current);
  const presentation = usePresentation(width);

  const fixedWidth = useMemo(
    () =>
      presentation.columns.reduce(
        (total, column) => total + column.props.width,
        20,
      ),
    [presentation],
  );

  const dataForDownload = useMemo(() => JsonToCSV(data, true, [], {}), [data]);

  return (
    <Container ref={containerRef}>
      <Card>
        <Table.Virtualized
          fixedHeight={height * 0.5}
          fixedWidth={fixedWidth}
          presentation={presentation}
          items={data}
          pageSize={999999}
          isLoading={loading}
          hidePagination
        />
        <Footer>
          <LogInfo>{data.length} registros</LogInfo>
          <DownloadLogs href={dataForDownload} disabled={!data.length}>
            baixar lista
          </DownloadLogs>
        </Footer>
      </Card>
    </Container>
  );
}
