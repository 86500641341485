import React from 'react';

import { Tag } from 'antd';

import { EnumMappers } from '~/common';

import { ProductTagsContainer } from './styled';

const { LoanType } = EnumMappers;

type ProductTagsData = {
  usedFutureMargin: boolean;
  loanType: string;
}

type ProductTagsProps = {
  data: ProductTagsData;
}

const colors = {
  PORTABILITYWITHOUTREFIN: 'magenta',
  FUTUREMARGIN: 'green',
  PORTABILITY: 'orange',
  REFIN: 'red',
  NEW: 'blue',
};

export default function ProductTags({ data: { usedFutureMargin, loanType } }: ProductTagsProps) {
  return (
    <ProductTagsContainer>
      <Tag color={colors[loanType]}>
        <span>{LoanType(loanType)}</span>
      </Tag>
      {
        usedFutureMargin && (
          <Tag>
            <span>Margem 35%</span>
          </Tag>
        )
      }
    </ProductTagsContainer>
  );
}
