const CREDIT_CARD_CASHOUT_STATUS = {
  INITIAL: 'Simulação iniciada',
  REQUESTED: 'Requerido',
  SIGNED: 'Requerido',
  PAID: 'Pago',
  CANCELLED: 'Cancelado',
  TYPED: 'Digitado',
};

export default (props: any) => {
  if (props) {
    return (
      CREDIT_CARD_CASHOUT_STATUS[props.value] ||
      CREDIT_CARD_CASHOUT_STATUS[props] ||
      null
    );
  }

  return null;
};
