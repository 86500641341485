import React, { forwardRef, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { CaretDown, CaretRight } from '~/ui/assets/icons';
import { Calendar, CalendarValue } from '~/ui/components';
import useOutsideClickEffect from '~/common/useOutsideClickEffect';

import {
  SelectButton,
  SelectContainer,
  SelectOptions,
  ActionIcons,
  CloseButton,
  ValueContainer,
  SelectLabel,
  SelectValue,
} from './SelectDate.styled';

export type SelectDateProps = {
  placeholder?: string;
  icon?: React.ReactElement;
  onChange?: (value: CalendarValue) => void;
  selectRange?: boolean;
  value?: CalendarValue;
  asFilter?: boolean;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  label?: string;
};

export const SelectDate = forwardRef(
  (
    {
      placeholder,
      icon,
      onChange,
      asFilter,
      selectRange,
      value,
      maxDate,
      minDate,
      disabled,
      label,
    }: SelectDateProps,
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState<CalendarValue>(value);
    const containerRef = useRef(ref);

    const handleToggleOpen = (e) => {
      const isCloseClick =
        e.target?.classList?.contains?.('close') ||
        e.target?.parentElement?.classList?.contains?.('close') ||
        e.target?.parentElement?.parentElement?.classList?.contains?.('close');

      setOpen(!isCloseClick);
    };

    const handleClearValue = () => {
      setCurrentValue(null);
      setOpen(false);
      onChange?.(null);
    };

    const handleCalendarChange = (value) => {
      setCurrentValue(value);
      onChange?.(value);
      setOpen(false);
    };

    const renderDate = (date) => moment(date).format('DD/MM/YYYY');

    const renderValue = () => {
      if (Array.isArray(currentValue)) {
        return `${renderDate(currentValue[0])} - ${renderDate(currentValue[1])}`;
      }

      return renderDate(currentValue);
    };

    const renderPlaceholder = () => {
      if (currentValue) return currentValue;

      if (!placeholder) return selectRange ? 'Selecione as datas' : 'Selecione uma data';

      return placeholder;
    };

    useOutsideClickEffect(containerRef, () => {
      setOpen(false);
    });

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    return (
      <SelectContainer ref={containerRef} disabled={disabled}>
        <SelectButton
          onClick={handleToggleOpen}
          open={open}
          withValue={!!currentValue}
          asFilter={asFilter}
        >
          <ValueContainer>
            {!asFilter && (
              <SelectLabel withValue={!!currentValue}>{label || placeholder}</SelectLabel>
            )}
            {icon && icon}
            {!currentValue && asFilter && renderPlaceholder()}{' '}
            {currentValue && <SelectValue asFilter={asFilter}>{renderValue()}</SelectValue>}
          </ValueContainer>

          <ActionIcons>
            <CloseButton className="close" onClick={handleClearValue} />
            {asFilter ? <CaretRight className="caret" /> : <CaretDown className="caret" />}
          </ActionIcons>
        </SelectButton>

        {open && (
          <SelectOptions>
            <Calendar
              maxDate={maxDate}
              minDate={minDate}
              selectRange={selectRange}
              defaultValue={currentValue}
              onChange={handleCalendarChange}
            />
          </SelectOptions>
        )}
      </SelectContainer>
    );
  },
);
