import React from 'react';

import { Pencil, UserCircle } from '~/ui/assets/icons';
import { Tooltip, TimeLine } from '~/ui/components';
import { UserType } from '~/typings/entities/user';
import {
  Container,
  Image,
  Pill,
  Title,
  Label,
  Info,
  Section,
  LabelContent,
} from './AgentInfo.styled';
import { EventType } from '../TimeLine/TimeLine';

interface IAgentInfoProps {
  image?: string;
  imageAlt?: string;
  user: UserType;
  informReason: string;
  agent: {
    label: string;
    role: string;
  };
  events?: EventType[];
}

export function AgentInfo({
  image,
  imageAlt = 'user-photo',
  user,
  informReason,
  agent,
  events,
}: IAgentInfoProps) {
  return (
    <Container>
      {image && <Image src={image} alt={imageAlt} />}
      <Section border>
        <Pill pillColor={user.status}>{user.status ? 'Ativo' : 'Inativo'}</Pill>
        <LabelContent>
          <Label marginBottom={5}>{user.fullName}</Label>
          <Tooltip content="Editar nome e email" small>
            <Pencil width={13} height={13} color="#D80073" />
          </Tooltip>
        </LabelContent>
        <Info color="#666666">{user.email}</Info>
        <Info color="#1A1A1A">{user.group}</Info>
      </Section>
      {!user.status && (
        <Section border>
          <Label marginBottom={24}>Motivo da inativação</Label>
          <Title>
            <UserCircle color="#EB2F96" width={24} height={24} />
            <Info color="#1A1A1A" fontWeight={600}>
              {agent.label}
            </Info>
          </Title>
          <Info color="#666666">{agent.role}</Info>
          <Info color="#1A1A1A">{informReason}</Info>
        </Section>
      )}
      {events && (
        <Section>
          <Label marginBottom={24}>Histórico de acessos</Label>
          <TimeLine events={events} />
        </Section>
      )}
    </Container>
  );
}
