/* @flow */

import React, { Component } from 'react';
import { Button, Icon, Divider } from 'antd';
import Modal from 'react-modal';
import Masker from 'vanilla-masker';
import { Flex, Box } from 'reflexbox';

import theme from '~/themes/aphroditeTheme/theme';
import { TableReact as Table, SimpleInfo } from '~/components';
import masked from '~/common/masked';

import LinkContract from './components';

import { Spinner } from '~/components';
import Card from '~/components/Card';
import RefinancedContract from '~/screens/ContractDetails/Panels/RefinancedContracts/components/Contract';
import ModalLink from '~/screens/ContractDetails/Panels/RefinancedContracts/components/Contract/ModalLink';

const { css } = theme;

const { toDate, toMoney } = masked;

const moneyFormatter = {
  unit: 'R$',
};

type Props = {
  styles: any,
  contracts: any,
  isLoading: boolean,
  contractDetails: any,
  onRefinancedContractUpdated: () => void,
};

class RefinancedContracts extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      personId: '',
      bankNumber: '',
      installmentValue: '',
      refinancedContractId: '',
      hasPaidInstallments: false,
      isModalVisible: false,
    };
  }

  presentation = {
    columns: [
      {
        accessor: 'financialInstitutionKey',
        name: 'Código',
        props: {
          width: 140,
        },
      },
      {
        accessor: 'bankNumber',
        name: 'Banco',
        props: {
          width: 70,
        },
      },
      {
        accessor: 'contractDate',
        name: 'Data',
        props: {
          width: 120,
          Cell: row => {
            if (row.original.contractDate) {
              return toDate(row.original.contractDate);
            }
            return null;
          },
        },
      },
      {
        accessor: 'installmentValue',
        name: 'V. Parcela',
        props: {
          width: 100,
          Cell: row => {
            if (row.original.installmentValue) {
              return Masker.toMoney(
                row.original.installmentValue.toFixed(2),
                moneyFormatter,
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'numberOfInstallments',
        name: 'N° Parcelas',
        props: {
          width: 140,
          Cell: row => (
            <div className='text-center'>
              {row.original.numberOfInstallments}
            </div>
          ),
        },
      },
      {
        accessor: 'paidInstallments',
        name: 'Parcelas Pagas',
        props: {
          width: 140,
          Cell: row => (
            <div className='text-center'>{row.original.paidInstallments}</div>
          ),
        },
      },
      {
        accessor: 'paidInstallmentsCalc',
        name: 'Estimativa de Parcelas Pagas',
        props: {
          width: 240,
          Cell: row => {
            if (row.original.paidInstallmentsCalc) {
              return (
                <div className='text-center'>
                  {row.original.paidInstallmentsCalc}
                </div>
              );
            }
            return 0;
          },
        },
      },
      {
        accessor: 'outstandingBalance',
        name: 'S. Devedor',
        props: {
          width: 97,
          Cell: row => {
            if (row.original.outstandingBalance) {
              return Masker.toMoney(
                row.original.outstandingBalance.toFixed(2),
                moneyFormatter,
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'outstandingBalanceCalc',
        name: 'Estimativa do S. Devedor',
        props: {
          width: 240,
          Cell: row => {
            if (row.original.outstandingBalanceCalc) {
              return (
                <div className='text-center'>
                  {Masker.toMoney(
                    row.original.outstandingBalanceCalc.toFixed(2),
                    moneyFormatter,
                  )}
                </div>
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'reservationKey',
        name: 'Chave Res',
        props: {
          width: 140,
        },
      },
      {
        name: 'Vincular',
        props: {
          width: 100,
          Cell: row => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() =>
                  this.onPressOpen(
                    row.original.personId,
                    row.original.bankNumber,
                    row.original.installmentValue,
                    row.original.refinancedContractId,
                  )
                }
              >
                <Icon type='link' />
              </Button>
            </div>
          ),
        },
      },
    ],
  };

  onPressOpen = ({
    personId,
    bankNumber,
    installmentValue,
    refinancedContractId,
  }) => () => {
    this.setState(
      { personId, bankNumber, installmentValue, refinancedContractId },
      () => this.openModal(),
    );
  };

  openModal = () => {
    this.setState({ isModalVisible: true });
  };

  onPressClose = () => {
    this.setState({ isModalVisible: false });
  };

  /*  renderPreviewText = () => {
    const { contracts } = this.props;
    const contract = contracts[0];
    if (contract && contract.paidInstallments)
      return <div style={{ marginTop: '20px' }}>{'*Estimativa'}</div>;
    return null;
  }; */

  isAbleToLinkContract = () => {
    const { contractDetails } = this.props;

    const loanStatus = contractDetails.loanStatus;
    const reservationStatus = contractDetails.reservationStatus;

    const isAbleToLinkContract =
      loanStatus === 'SIGNED' && reservationStatus !== 'RESERVED';

    return isAbleToLinkContract;
  };

  renderpPreProposalInstalmentNumber(data) {
    const { instalment } = data;

    if (!instalment || !instalment.number) return null;

    return <SimpleInfo label='PARCELAS' large value={instalment.number} />;
  }

  renderpPreProposalInstalmentFirstDate(data) {
    const { instalment } = data;

    if (!instalment || !instalment.first_date) return null;

    return (
      <SimpleInfo
        label='DATA DA PRIMEIRA PARCELA'
        large
        value={toDate(instalment.first_date)}
      />
    );
  }

  renderpPreProposalInstalmentLastDate(data) {
    const { instalment } = data;

    if (!instalment || !instalment.last_date) return null;

    return (
      <SimpleInfo
        label='DATA DA ÚLTIMA PARCELA'
        large
        value={toDate(instalment.last_date)}
      />
    );
  }

  renderpPreProposalInstalmentDue(data) {
    const { instalment } = data;

    if (!instalment || !instalment.due) return null;

    return (
      <SimpleInfo label='PARCELAS VENCIDAS' large value={instalment.due} />
    );
  }

  renderpPreProposalInstalmentOverDue(data) {
    const { instalment } = data;

    if (!instalment || !instalment.overdue) return null;

    return (
      <SimpleInfo label='PARCELAS ATRASADAS' large value={instalment.overdue} />
    );
  }

  renderpPreProposalOutstandingAmount(data) {
    const { outstanding_balance } = data;

    if (!outstanding_balance || !outstanding_balance.amount) return null;

    return (
      <SimpleInfo
        label='MONTANTE PENDENTE'
        large
        value={toMoney(outstanding_balance.amount)}
      />
    );
  }

  renderpPreProposalOutstandingDate(data) {
    const { outstanding_balance } = data;

    if (!outstanding_balance || !outstanding_balance.date) return null;

    return (
      <SimpleInfo label='DATA' large value={toDate(outstanding_balance.date)} />
    );
  }

  renderpPreProposalOutstandingOperationDate(data) {
    const { outstanding_balance } = data;

    if (!outstanding_balance || !outstanding_balance.operational_date)
      return null;

    return (
      <SimpleInfo
        label='DATA DA OPERAÇÃO'
        large
        value={toDate(outstanding_balance.operational_date)}
      />
    );
  }

  renderpPreProposalOutstandingRate(data) {
    const { outstanding_balance } = data;

    if (!outstanding_balance || !outstanding_balance.operational_date)
      return null;

    return (
      <SimpleInfo
        label='TAXA EFETIVA'
        large
        value={`${outstanding_balance.rate_effective}%`}
      />
    );
  }

  renderPreProposal = () => {
    const { contractDetails } = this.props;

    if (!contractDetails || !contractDetails.consigneeLastStatusPreProposal)
      return null;

    const getData = () => {
      try {
        return JSON.parse(contractDetails.consigneeLastStatusPreProposal);
      } catch (e) {
        return contractDetails.consigneeLastStatusPreProposal;
      }
    };

    return (
      <div style={{ margin: 16 }}>
        <Divider>Pré Proposta</Divider>
        {typeof getData() === 'object' ? (
          <Flex align='flex-start' wrap style={{ width: '100%' }}>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalOutstandingDate(getData())}
            </Box>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalOutstandingOperationDate(getData())}
            </Box>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalInstalmentNumber(getData())}
            </Box>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalInstalmentFirstDate(getData())}
            </Box>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalInstalmentLastDate(getData())}
            </Box>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalInstalmentDue(getData())}
            </Box>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalInstalmentOverDue(getData())}
            </Box>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalOutstandingAmount(getData())}
            </Box>
            <Box my={1} w={1 / 4}>
              {this.renderpPreProposalOutstandingRate(getData())}
            </Box>
          </Flex>
        ) : (
          <Flex align='flex-start' wrap style={{ width: '100%' }}>
            <Box my={1} w={1}>
              <SimpleInfo label='Status' large value={getData()} />
            </Box>
          </Flex>
        )}
      </div>
    );
  };

  render() {
    const { contracts, isLoading, styles } = this.props;
    const linkContractIndex = 9;

    if (!this.isAbleToLinkContract()) {
      this.presentation.columns.splice(linkContractIndex, 1);
    }

    if (!contracts || !Array.isArray(contracts)) return null;

    return (
      <div>
        <div {...css(styles.container)}>
          {contracts.map(contract => {
            return (
              <RefinancedContract
                {...contract}
                onPressLink={this.onPressOpen}
                onFieldsSubmitted={this.props.onRefinancedContractUpdated}
              />
            );
          })}
          <Spinner spinning={isLoading} />
          <ModalLink
            visible={this.state.isModalVisible}
            personId={this.state.personId}
            installmentValue={this.state.installmentValue}
            bankNumber={this.state.bankNumber}
            onPressClose={this.onPressClose}
            refinancedContractId={this.state.refinancedContractId}
          />
        </div>
        {this.renderPreProposal()}
        {/*  {this.renderPreviewText()} */}
      </div>
    );
  }
}

RefinancedContracts.defaultProps = {
  contracts: [],
};

export default RefinancedContracts;
