import styled from 'styled-components';
import { Typography } from '../Typography/Typography';

export const Container = styled.div`
  margin-left: 8px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  width: 84px;
  height: 84px;
  border-radius: 84px;
  margin-bottom: 24px;
`;

export const Pill = styled.div`
  background-color: ${({ pillColor }) => (pillColor === true ? '#E5FFE5' : '#FFE8E5')};
  color: ${({ pillColor }) => (pillColor === true ? '#005C00' : '#B00015')};
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  border-radius: 8px;
  display: inline-flex;
  padding-inline: 20px;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

export const LabelContent = styled.div`
  display: flex;
  gap: 8px;
`;

export const Label = styled(Typography).attrs({
  type: 'headingXSmall',
  weight: '600',
})`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

export const Info = styled(Typography).attrs(({ color, fontWeight }) => ({
  type: 'caption',
  element: 'p',
  color,
  weight: fontWeight || '400',
}))`
  margin-bottom: 5.5px;
`;

export const Section = styled.div`
  padding-bottom: 24px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: ${({ border }) => (border ? 1 : 0)}px;
  border-style: dashed;
  border-color: #9a9a9a;
  margin-bottom: 24px;
`;
