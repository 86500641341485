import React, { useEffect } from 'react';
import { WarrantyType } from '~/typings/enums/Clix';
import useQueryParams from '~/hooks/useQueryParams';
import { useAwaitControl } from 'react-redux-await-control';
import { getClientDetails, getInvoices, getTransactions } from '~/store/clix/actions';
import { getPersonDetail } from '~/store/people/actions';
import { Tabs } from '~/ui/components';
import { InfoContainer, InfoSection } from '..';
import { INSSInfo } from '../../Sections';
import { TransactionsTable } from '../../Tables';
import { InvoicesTable } from '../../Tables/InvoicesTable/InvoicesTable';

export type InssWarrantyProps = {
  personId: string;
};

export function InssWarranty({ personId }: InssWarrantyProps) {
  const query = useQueryParams();
  const getClixDetailsControl = useAwaitControl(getClientDetails);
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getInvoicesControl = useAwaitControl(getInvoices);

  const person = getPersonDetailsControl.result();
  const clixInfo = getClixDetailsControl.result(`${personId}_${WarrantyType.INSS}`);
  const invoices = getInvoicesControl.result(`${personId}_${WarrantyType.INSS}`);
  const clixInfoSuccess = getClixDetailsControl.isSuccessful(`${personId}_${WarrantyType.INSS}`);
  const personDetailsSuccessful = getPersonDetailsControl.isSuccessful();

  useEffect(() => {
    if (personDetailsSuccessful) {
      const { document } = person;

      getClixDetailsControl.start(
        { document, status: query.get('status') || 'CONTRACTED', warranty: WarrantyType.INSS },
        { actionId: `${personId}_${WarrantyType.INSS}` },
      );

      getInvoicesControl.start(
        { document, warranty: WarrantyType.INSS },
        { actionId: `${personId}_${WarrantyType.INSS}` },
      );
    }
  }, [personDetailsSuccessful]);

  return (
    <InfoContainer>
      <InfoSection>
        <INSSInfo info={clixInfo} loading={!clixInfoSuccess} />
      </InfoSection>

      <Tabs
        className="internal-tabs"
        tabs={[
          {
            key: 'inss-transaction-history',
            title: 'Histórico de transações',
            noPadding: true,
            component: <TransactionsTable personId={personId} warranty={WarrantyType.INSS} />,
          },
          {
            key: 'inss-invoice-history',
            title: 'Histórico de faturas',
            noPadding: true,
            component: <InvoicesTable data={invoices} warranty={WarrantyType.INSS} />,
          },
          {
            key: 'inss-discount-history',
            title: 'Histórico de descontos',
            noPadding: true,
            disabled: true,
            component: <h1>Histórico de descontos</h1>,
          },
        ]}
      />
    </InfoContainer>
  );
}
