import React, { useCallback, useEffect, useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import isEmpty from 'lodash/isEmpty';

import screenRegister from '~/hoc/screenRegister';
import { TableReact as Table, Spinner, Container } from '~/components';
import { TableCard } from '~/screens/People/People.Styles';
import TableFilter from '~/components/TableFilter';
import { listPeople } from '~/store/people/actions';
import { message } from 'antd';
import tablePresentation from './tablePresentation';
import filters from './filters';

function People() {
  const presentation = useRef(tablePresentation);

  const listPeopleControl = useAwaitControl(listPeople);
  const loading = listPeopleControl.isRunning();
  const { entries } = listPeopleControl.result();

  useEffect(() => () => listPeopleControl.clear(), []);

  const validateFilters = useCallback((filters) => {
    if (!filters || !Object.keys(filters).filter((key) => !isEmpty(filters[key])).length) {
      throw new Error('É necessário preencher pelo menos um campo dos filtros');
    }
  }, []);

  const onFiltersValidationError = useCallback((e) => {
    message.error(e.message);
  }, []);

  return (
    <Container>
      <Spinner spinning={loading} />
      <div style={{ minWidth: '420px' }}>
        <TableFilter
          onSubmit={listPeopleControl.start}
          validate={validateFilters}
          onValidationError={onFiltersValidationError}
          fields={filters}
        />
      </div>
      <TableCard hiddenOverflow style={{ marginTop: 16 }}>
        <Table items={entries} presentation={presentation.current} />
      </TableCard>
    </Container>
  );
}

export default screenRegister({
  screenName: 'People',
  path: '/backoffice/people2',
  headerTitle: 'Pessoas',
  headerMenu: true,
  headerMenuPosition: 3,
})(People);
