// @flow

import Immutable from 'immutable';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  compose, withHandlers, withPropsOnChange, withState,
} from 'recompose';
import { mapObject, pick, isEmpty } from 'underscore';
import { X } from 'react-feather';
import theme from '~/themes/aphroditeTheme/theme';
import masked from '~/common/masked';
import { message } from '~/common';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import {
  Col, DatePicker, Input, Form, Row, Select,
} from 'antd';
import { Button, Spinner } from '~/components';

import fetchContractCommisionings from '~/store/fetchContractCommisionings/action';

import payContract, {
  PAY_CONTRACT_REQUEST,
  PAY_CONTRACT_FAILURE,
  PAY_CONTRACT_SUCCESS,
} from '~/store/payContract/action';

import { REGISTER_COMMISSIONING_SUCCESS } from '~/store/registerCommissioning/action';
import { getContractDetails } from '~/store/contracts/actions';

import common from './styles';

const { css, withStyles } = theme;

const { Item: FormItem } = Form;
const { toMoneyForm } = masked;

const dateFormat = 'DD/MM/YYYY';
class Payment extends Component {
  state = {
    commissioningId: undefined,
    grossValueApproved: 0,
    netValueApproved: 0,
    valueForDepositApproved: 0,
    installmentValueApproved: 0,
    numberOfInstallments: 0,
    paymentDate: moment().utc(),
  };

  getCurrentValue = (field) => {
    const { details } = this.props;
    const fieldValue = details[`${field}`];

    return fieldValue ? fieldValue.toFixed(2) : fieldValue;
  };

  onPressOpen = () => {
    const { details } = this.props;
    const { paymentDate } = this.state;

    const { id } = this.props.match.params;
    this.props.dispatch(
      fetchContractCommisionings({
        id,
        paymentDate: paymentDate.format('L'),
      }),
    );

    this.setState(
      {
        grossValueApproved: this.getCurrentValue('grossValueApproved'),
        netValueApproved: this.getCurrentValue('netValueApproved'),
        valueForDepositApproved: this.getCurrentValue(
          'valueForDepositApproved',
        ),
        installmentValueApproved: this.getCurrentValue(
          'installmentValueApproved',
        ),
        numberOfInstallments: parseInt(details.numberOfInstallments),
        paymentDate: moment().utc(),
      },
      () => this.props.form.setFieldsValue({
        commissioningId: undefined,
        grossValueApproved: this.state.grossValueApproved,
        netValueApproved: this.state.netValueApproved,
        valueForDepositApproved: this.state.valueForDepositApproved,
        installmentValueApproved: this.state.installmentValueApproved,
        numberOfInstallments: this.state.numberOfInstallments,
        paymentDate: this.state.paymentDate,
      }),
    );

    this.props.setIsModalVisible(true);
  };

  onPressPayContract = () => {
    const state = pick(
      this.state,
      ...[
        'commissioningId',
        'grossValueApproved',
        'installmentValueApproved',
        'netValueApproved',
        'numberOfInstallments',
        'valueForDepositApproved',
        'paymentDate',
      ],
    );

    this.props.form.validateFields((err, values) => {
      if (!err || isEmpty(err)) {
        this.props.onPressPayContract(state);
      }
    });
  };

  getPaymentType = () => {
    const { details } = this.props;
    const { loanType, loanStatus } = details;

    switch (loanType) {
      case 'NEW':
        return 'novo';
      case 'PORTABILITY': {
        if (loanStatus !== 'PORTABILITY_PAID') return 'Portabilidade';
        return ' de refin da portabilidade';
      }
      case 'REFIN':
        return ' de refinanciamento';
      case 'FUTUREMARGIN':
        return ' de margem futura';
      default:
        return '';
    }
  };

  onChange = (value) => {
    this.setState(value);
  };

  onPaymentDateChange = (paymentDate) => {
    const { dispatch } = this.props;
    const { id } = this.props.match.params;

    this.setState({ paymentDate }, () => {
      dispatch(
        fetchContractCommisionings({
          id,
          paymentDate: paymentDate.format('L'),
        }),
      );
      this.setState({ commissioningId: undefined });
      this.props.form.setFieldsValue({
        commissioningId: undefined,
      });
    });
  };

  renderForm() {
    const { onChange } = this;
    const { styles } = this.props;

    const { getFieldDecorator } = this.props.form;

    const { commissionings } = this.props.commissionings;

    return (
      <Form>
        <Row>
          <Col xs={{ span: 7 }}>
            <span>Valor Bruto</span>
            <FormItem>
              {getFieldDecorator('grossValueApproved', {
                normalize: toMoneyForm,
              })(
                <Input
                  readOnly
                  addonBefore="R$"
                  onChange={(e) => this.onChange({ grossValueApproved: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 8, offset: 1 }}>
            <span>Valor Líquido</span>
            <FormItem>
              {getFieldDecorator('netValueApproved', {
                normalize: toMoneyForm,
              })(
                <Input
                  readOnly
                  addonBefore="R$"
                  onChange={(e) => this.onChange({ netValueApproved: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 7, offset: 1 }}>
            <span>Valor para Depósito</span>
            <FormItem>
              {getFieldDecorator('valueForDepositApproved', {
                normalize: toMoneyForm,
              })(
                <Input
                  readOnly
                  addonBefore="R$"
                  onChange={(e) => this.onChange({ valueForDepositApproved: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 7 }}>
            <span>Valor da Parcela</span>
            <FormItem>
              {getFieldDecorator('installmentValueApproved', {
                normalize: toMoneyForm,
              })(
                <Input
                  readOnly
                  addonBefore="R$"
                  onChange={(e) => this.onChange({ installmentValueApproved: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 8, offset: 1 }}>
            <span>Número de Parcelas</span>
            <FormItem>
              {getFieldDecorator('numberOfInstallments', {})(
                <Input
                  readOnly
                  onChange={(e) => onChange({ numberOfInstallments: e.target.value })}
                  style={css(styles.input)}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={{ span: 7, offset: 1 }}>
            <span>Data do Pagamento</span>
            <FormItem>
              {getFieldDecorator('paymentDate', {
                rules: [
                  {
                    required: true,
                    message: 'Informe a data de pagamento',
                  },
                ],
              })(
                <DatePicker
                  defaultValue={moment().utc()}
                  locale={locale}
                  format={dateFormat}
                  onChange={(e) => this.onPaymentDateChange(e)}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }

  canShowPayment = () => {
    const { isSigned, isPaid, isPortabilityPaid } = this.props;
    return (isPortabilityPaid || isSigned) && !isPaid;
  };

  render() {
    const { styles, isModalVisible } = this.props;

    if (!this.canShowPayment()) return null;

    const paymentType = this.getPaymentType();

    const style = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s',
        background: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1000,
      },
    };

    return [
      <Button
        key="button"
        type="success"
        fullWidth
        size="large"
        onPress={this.onPressOpen}
        style={{ marginBottom: 16 }}
        title="Pagar Contrato"
      />,
      <Modal
        ariaHideApp={false}
        contentLabel="Payment"
        isOpen={isModalVisible}
        key="modal"
        style={style}
        {...css(styles.modal)}
      >
        <Spinner spinning={this.props.isLoading} />

        <div {...css(styles.modal__header)}>
          <span
            {...css(styles.modal__title)}
          >
            {`Condições finais de contrato ${paymentType}`}
          </span>
          <button
            type="button"
            onClick={this.props.onPressClose}
            {...css(styles.modal__close)}
          >
            <X {...css(styles.closeButton)} size={20} />
          </button>
        </div>
        <div {...css(styles.modal__content)}>{this.renderForm()}</div>
        <Button
          type="primary"
          style={{ width: 163 }}
          {...css(styles.buttonConfirm)}
          onClick={this.onPressPayContract}
        >
          Informar Pagamento
        </Button>
      </Modal>,
    ];
  }
}

function onPressPayContract({ dispatch, match }) {
  return (values) => {
    const { id } = match.params;
    dispatch(payContract(id, values));
  };
}

function onPressClose({ setIsModalVisible }) {
  setIsModalVisible(false);
}

export function mapStateToProps(state) {
  return {
    commissionings: state.getIn(['fetchContractCommisionings']),
    details: getContractDetails.getResult()(state),
    detailsSuccess: getContractDetails.isSuccessful()(state),
    detailsFailure: getContractDetails.hasFailure()(state),
    payContract: state.getIn(['payContract']),
    registerCommissioning: state.getIn(['registerCommissioning']),
  };
}

export function receiveChanges(
  prevProps,
  {
    commissionings,
    changePaid,
    changePortabilityPaid,
    changeSigned,
    setIsModalVisible,
    details,
    detailsSuccess,
    detailsFailure,
    dispatch,
    match,
    payContract,
    registerCommissioning,
    setIsLoading,
  },
) {
  if (prevProps.detailsSuccess !== detailsSuccess) {
    if (detailsSuccess) {
      const { loanStatus } = details;
      changeSigned(loanStatus === 'CCBSIGNED');
      changePaid(loanStatus === 'PAID');
      changePortabilityPaid(loanStatus === 'PORTABILITY_PAID');

      return true;
    }
  }

  if (prevProps.detailsFailure !== detailsFailure) {
    return detailsFailure;
  }

  if (Immutable.is(prevProps.commissionings, commissionings) === false) {
    return true;
  }

  if (Immutable.is(prevProps.payContract, payContract) === false) {
    switch (payContract.getIn(['type'])) {
      case PAY_CONTRACT_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case PAY_CONTRACT_SUCCESS: {
        const loanStatus = payContract.getIn(['payload']);

        if (loanStatus === 'PORTABILITY_PAID') {
          message.success('Portabilidade paga com sucesso');
        }
        if (loanStatus === 'PAID') {
          message.success('Refin da portabilidade paga com sucesso');
        }

        const { id } = match.params;
        dispatch(getContractDetails(id));

        setIsLoading(false);
        setIsModalVisible(false);
        return true;
      }
      case PAY_CONTRACT_FAILURE: {
        message.error('Falha ao alterar status de pagamento do contrato.');
        setIsLoading(false);
        return true;
      }

      default:
        return false;
    }
  }

  if (
    Immutable.is(prevProps.registerCommissioning, registerCommissioning)
    === false
  ) {
    switch (registerCommissioning.getIn(['type'])) {
      case REGISTER_COMMISSIONING_SUCCESS: {
        const { id } = match.params;
        dispatch(
          fetchContractCommisionings({
            id,
            paymentDate: moment()
              .utc()
              .format('L'),
          }),
        );
        return true;
      }

      default:
        return false;
    }
  }

  return false;
}

export function propagateStateChangeToProps({ commissionings, details }) {
  return mapObject(
    {
      commissionings,
      details,
    },
    (state) => state.getIn?.(['payload']) || state,
  );
}

const styles = (props) => ({
  ...common(props),
  modal: {
    ...common(props).modal,
    width: 600,
  },
});

Payment.defaultProps = {
  isSigned: false,
  values: {
    grossValueApproved: 0,
    installmentValueApproved: 0,
    netValueApproved: 0,
    numberOfInstallments: 0,
    paymentDate: moment().utc(),
    valueForDepositApproved: 0,
  },
};

const WrappedPayment = Form.create()(Payment);

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  withState('isLoading', 'setIsLoading', false),
  withState('isPaid', 'changePaid', false),
  withState('isSigned', 'changeSigned', false),
  withState('isPortabilityPaid', 'changePortabilityPaid', false),
  withState('isModalVisible', 'setIsModalVisible', false),
  withState('isLoadedContractDetails', 'setIsLoadedContractDetails', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onPressPayContract,
    onPressClose,
  }),
)(WrappedPayment);
