/* @flow */

export const INSERT_SIGNATURE_REQUEST = 'INSERT_SIGNATURE_REQUEST';
export const INSERT_SIGNATURE_SUCCESS = 'INSERT_SIGNATURE_SUCCESS';
export const INSERT_SIGNATURE_FAILURE = 'INSERT_SIGNATURE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: INSERT_SIGNATURE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: INSERT_SIGNATURE_FAILURE,
      payload: resource,
    });
  };
}

export default function insertSignature(payload) {
  return dispatch => {
    dispatch({
      type: INSERT_SIGNATURE_REQUEST,
      payload,
    });
  };
}
