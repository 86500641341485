import styled from 'styled-components';
import { Input } from 'antd';
import { themeColor } from '~/common/utils/theme';

export const TagOptionsContainer = styled.div`
  background: ${themeColor('background.primary')};
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
`;

export const SearchInput = styled(Input.Search)`
  width: 100%;
  background: ${themeColor('background.primary')};

  .ant-input-suffix {
    svg {
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    }
  }

  input {
    background: ${themeColor('background.primary')};
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    outline: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid ${themeColor('divider.primary')};

    &:hover,
    &:active,
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
      border-bottom: 1px solid ${themeColor('divider.primary')} !important;
    }
  }
`;

export const Options = styled.ul`
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  margin: 0;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 4px;
  }
`;

export const Option = styled.li`
  padding: 5px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  border-bottom: 1px solid ${themeColor('divider.primary')};

  &:hover {
    background-color: ${themeColor('state.hover')};
  }
`;

export const NoTags = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #999;
  font-size: 12px;
`;
