import React, { useCallback } from 'react';
import moment from 'moment';

import { Invoice } from '~/typings/entities/Clix';
import { useDrawer } from '~/hooks/useDrawer';
import { Money } from '~/common';
import { Button, Table, TableColumns, Tag, Tooltip } from '~/ui/components';
import { useTheme } from 'styled-components';
import { Cancel } from '~/ui/assets/icons';
import { getInvoiceStatus } from '~/common/clix';
import { WarrantyType } from '~/typings/enums/Clix';
import { InvoiceDetails } from '../../Drawers';

interface TransactionsTableProps {
  data: Invoice[];
  loading?: boolean;
  warranty: WarrantyType;
}

export function InvoicesTable({ data, loading, warranty }: TransactionsTableProps) {
  const { openDrawer } = useDrawer();
  const { colors } = useTheme();

  const handleDetailsClick = (dueDate: string) => {
    openDrawer('invoiceDetails', <InvoiceDetails dueDate={dueDate} warranty={warranty} />, {
      width: 480,
    });
  };

  const formatDate = useCallback(
    (dateInput) => (dateInput ? moment(dateInput).format('DD/MM/YYYY') : '-'),
    [],
  );

  const columns: TableColumns<Invoice> = [
    {
      header: () => '',
      accessorKey: 'status',
      cellAlign: 'center',
      maxSize: 50,
      cell: (info) => {
        const { label, colorPalette } = getInvoiceStatus(info.row.original);
        const textColor = colors?.[colorPalette]?.primaryAlt;
        const bgColor = colors?.[colorPalette]?.secondary;
        return (
          <Tooltip content={label} small>
            <Tag small rounded textColor={textColor} bgColor={bgColor}>
              <Cancel />
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      header: () => 'Data de fechamento',
      accessorKey: 'closingDate',
      cell: (info) => formatDate(info.getValue()),
    },
    {
      header: () => 'Data de vencimento',
      accessorKey: 'dueDate',
      cell: (info) => formatDate(info.getValue()),
    },
    {
      header: () => 'Valor',
      accessorKey: 'value',
      cell: (info) => Money(info.getValue()),
    },
    {
      header: () => 'Detalhes',
      id: 'actions',
      cell: (info) => (
        <Button
          onClick={() => handleDetailsClick(info.row.original.dueDate)}
          customColor={colors.brand.primary}
          iconOnly
          icon="FileSearch"
          variant="text"
        >
          Exibir
        </Button>
      ),
    },
  ];

  return (
    <Table
      noItemsMessage="Nenhuma transação encontrada"
      responsiveCols={['status', 'closingDate', 'dueDate', 'value', 'actions']}
      loading={loading}
      columns={columns}
      data={data}
      noPadding
    />
  );
}
