import React from 'react';
import styled from 'styled-components';

import screenRegister from '~/hoc/screenRegister';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  > div {
    background-color: white;
    padding: 20px;
    width: 80%;
  }

  span > h1 {
    text-align: center;
  }

  span > ul {
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed black;
  }

  span > ul > li {
    font-size: 16px;
  }
`;

function Versions() {
  return (
    <Container>
      <div>
        <span dangerouslySetInnerHTML={{ __html: process.env.CHANGELOG }} />
      </div>
    </Container>
  );
}

export default screenRegister({
  screenName: 'Versions',
  path: '/backoffice/versions',
  headerTitle: 'Versões',
})(Versions);
