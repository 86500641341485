import takeRequest from '~/store/takeRequest';

import {
  fetchAddressFromZipCode,
  fetchAgreements,
  fetchFinancialInstitutions,
  fetchFinancialInstitutionsForDeposit,
  getUserNotifications,
  markNotificationsAsRead,
  getTableauToken,
  getReports,
  getParameter,
} from '~/store/utilities/actions';

export function* utilitiesSaga() {
  yield takeRequest(fetchAgreements);
  yield takeRequest(fetchFinancialInstitutions);
  yield takeRequest(fetchAddressFromZipCode);
  yield takeRequest(fetchFinancialInstitutionsForDeposit);
  yield takeRequest(getUserNotifications);
  yield takeRequest(markNotificationsAsRead);
  yield takeRequest(getTableauToken);
  yield takeRequest(getReports);
  yield takeRequest(getParameter);
}
