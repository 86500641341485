import React, { ElementType } from 'react';

import useCopyToClipboard from '~/hooks/useCopyToClipboard';
import useModals from '~/hooks/useModals';

export type ActionType = 'copy' | 'open_modal' | 'submit_file';

type BaseAction = {
  child: ElementType;
};

type CopyActionProps = {
  valueToCopy?: (value: any) => string;
  value: string | number;
} & BaseAction;

function CopyAction({ child: Child, valueToCopy = (value) => value, ...rest }: CopyActionProps) {
  const rawValue = rest.value ? rest.value.toString() : '';
  const copyToClipBoard = useCopyToClipboard(valueToCopy(rawValue));
  return <Child onClick={copyToClipBoard} {...rest} />;
}

type OpenModalProps = {
  modalName?: string;
  modalParams?: any;
} & BaseAction;

function OpenModalAction({
  child: Child, modalName, modalParams, ...rest
}: OpenModalProps) {
  const { openModal } = useModals() as any;
  return <Child onClick={openModal(modalName, modalParams)} {...rest} />;
}

type ConditionalProps = | CopyActionProps | OpenModalProps;

type ActionManagerProps = {
  type: ActionType;
  value: string | number;
} & ConditionalProps;

const ActionManagerMap = {
  copy: CopyAction,
  open_modal: OpenModalAction,
  submit_file: OpenModalAction,
};

export default function ActionManager({ type, child: Child, ...props }: ActionManagerProps) {
  const Action = ActionManagerMap[type];
  return Action ? <Action {...props} child={Child} /> : <Child {...props} />;
}
