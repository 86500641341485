import React, { useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { Alert, Button, Flex, Shimmer, Tag, Typography, withStepperProps } from '~/ui/components';
import { themeToggleColor } from '~/common/utils/theme';

import { useAwaitControl } from 'react-redux-await-control';
import { prepareContract, suggestedProposals } from '~/store/opportunities/actions';
import {
  ContractValueList,
  OpportunityListItem,
  SuggestedProposals,
} from '~/typings/entities/Opportunity';
import { getPersonDetail } from '~/store/people/actions';
import toMoney from '~/common/masked/toMoney';
import moment from 'moment';
import { uppercaseFirst } from '~/common';
import toPercentage from '~/common/masked/toPercentage';
import { useDrawer } from '~/hooks/useDrawer';
import formatName from '~/common/formatName';
import { DownloadNodeModal } from '~/components';
import { DetailsResume } from '~/screens/Person/components/Drawers/Opportunity/PORTABILITY/Resume/DetailsResume';
import { useModal } from '~/hooks/useModal';
import { getDeeplink } from '~/store/formalization/actions';
import { OpportunityBody, OpportunityContainer, OpportunityFooter } from '../../Opportunity.styled';

export type DetailsProps = {
  opportunity: OpportunityListItem;
  selectedContract: ContractValueList;
};

export const Details = withStepperProps<DetailsProps>(
  ({ stepper, selectedContract, opportunity }) => {
    const theme = useTheme();
    const { toStepById } = stepper;
    const { setConfig } = useDrawer();
    const { openModal } = useModal();
    const buttonColor = themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondary' });

    const getDeeplinkControl = useAwaitControl(getDeeplink);

    const proposalsControl = useAwaitControl(suggestedProposals);
    const proposalsLoading = proposalsControl.isRunning();
    const proposalsResult: SuggestedProposals = proposalsControl.result();
    const proposalsSuccess = proposalsControl.isSuccessful();
    const proposalsFailure = proposalsControl.hasFailure();

    const prepareContractControl = useAwaitControl(prepareContract);
    const prepareLoading = prepareContractControl.isRunning();
    const prepareResult = prepareContractControl.result();
    const prepareFailure = prepareContractControl.hasFailure();

    const personControl = useAwaitControl(getPersonDetail);
    const person = personControl.result();

    const loading = proposalsLoading || prepareLoading;

    const handleNextStep = () => {
      toStepById('success');
    };

    const handleDownload = useCallback(() => {
      const name = formatName(person?.name, 2);
      const date = moment().format('DD-MM-YYYY');
      const fileName = `Oportunidade Refinanciamento - ${name} - ${date}`;

      openModal(
        <DownloadNodeModal fileName={fileName}>
          <DetailsResume contract={prepareResult} />
        </DownloadNodeModal>,
        {
          closable: true,
          maskClosable: false,
          noPadding: true,
          width: 460,
        },
      );
    }, [prepareResult]);

    const formatDate = (date: string, format = 'DD/MM/YYYY') =>
      date ? moment(date).format(format) : '';

    const getFinancialInstitution = useCallback(
      (bankCode: string) => {
        const financialInstitution = opportunity?.portableContracts?.map(
          (contract: any) => contract?.financialInstitutionDTO,
        );
        return financialInstitution?.find((bank: any) => bank.bankNumber === bankCode);
      },
      [opportunity?.portableContracts],
    );

    useEffect(() => {
      if (selectedContract && !proposalsSuccess) {
        proposalsControl.start({
          agreementId: person?.agreementId,
          contractId: selectedContract?.contractId,
          enrollmentId: opportunity?.enrollmentId,
          loanType: opportunity?.loanType,
        });
      }
    }, [selectedContract]);

    useEffect(() => {
      if (proposalsSuccess) {
        const selectedProposal = proposalsResult?.suggestedProposalReturns?.find(
          (proposal) => proposal?.suggestedProposalType === 'MAX_VALUE_FOR_DEPOSIT',
        );

        const { financialInstitutionDTO: financialInstitution, ...proposal } = selectedProposal;
        const proposalPayload = {
          ...proposal,
          contractValue: proposal?.valueForDeposit,
          financialInstitution,
          personId: person?.id,
          enrollmentId: opportunity?.enrollmentId,
          agreementId: person?.agreementId,
          loanType: opportunity?.loanType,
          isPortability: true,
          contractsIds: [selectedContract.contractId],
          contract: selectedContract,
        };

        if (!prepareResult) {
          getDeeplinkControl.clear();

          prepareContractControl.start(proposalPayload);
        }
      }
    }, [proposalsSuccess]);

    useEffect(() => {
      setConfig('opportunity-details', {
        backButton: true,
        onBackClick: stepper.prevStep,
        extraAction: (
          <Button
            variant="text"
            size="sm"
            iconOnly
            icon="Download"
            title="Baixar resumo"
            disabled={loading}
            onClick={handleDownload}
          />
        ),
      });
    }, [prepareResult, loading]);

    useEffect(() => {
      if (prepareFailure || proposalsFailure) {
        toStepById('error');
      }
    }, [prepareFailure, proposalsFailure]);

    return (
      <OpportunityContainer loading={loading ? 1 : 0}>
        <OpportunityBody>
          <Flex direction="column" gap={16} mt={24}>
            <Alert status="error">
              O valor apresentado do troco é um valor aproximado, calculado com base em informações
              estimadas sobre o seu contrato de origem e, por isso, ele pode variar.
            </Alert>

            <Flex direction="column" gap={16} mt={8} width="100%">
              <Flex direction="column" gap={16} width="100%" mv={8}>
                <Tag
                  rounded
                  small
                  textColor={theme.colors.info.primaryAlt}
                  bgColor={theme.colors.info.secondary}
                >
                  Portabilidade
                </Tag>

                <Flex direction="column" gap={32} width="100%">
                  <Flex direction="column" gap={2} width="100%">
                    <Typography type="bodyMedium">Valor aproximado que vai receber</Typography>

                    {loading ? (
                      <Shimmer width={143} height={32} />
                    ) : (
                      <Typography type="headingH5" weight={600}>
                        {toMoney(prepareResult?.contractValue || 0)}
                      </Typography>
                    )}
                  </Flex>

                  <Flex direction="column" gap={8}>
                    <Typography type="bodyMedium" weight={600}>
                      Banco
                    </Typography>

                    {loading ? (
                      <Shimmer width={85} height={20} />
                    ) : (
                      <Typography type="bodySmall">
                        {getFinancialInstitution(selectedContract?.originalBankNumber)?.name || '-'}
                      </Typography>
                    )}
                  </Flex>

                  <Flex direction="column" gap={8}>
                    <Typography type="bodyMedium" weight={600}>
                      Parcelas do contrato
                    </Typography>

                    {loading ? (
                      <Shimmer width={85} height={20} />
                    ) : (
                      <Typography type="bodySmall">
                        {prepareResult?.numberOfInstallments}x de{' '}
                        {toMoney(prepareResult?.installmentValue || 0)}
                      </Typography>
                    )}
                  </Flex>

                  <Flex direction="column" gap={8}>
                    <Typography type="bodyMedium" weight={600}>
                      Previsão de Pagamento
                    </Typography>

                    {loading ? (
                      <Shimmer width={85} height={20} />
                    ) : (
                      <Typography type="bodySmall">
                        Entre {formatDate(prepareResult?.nearEstimatedEndDate) || '-'} e{' '}
                        {formatDate(prepareResult?.farEstimatedEndDate) || '-'}
                      </Typography>
                    )}
                  </Flex>

                  <Flex direction="column" gap={8}>
                    <Typography type="bodyMedium" weight={600}>
                      Previsão de início e fim dos descontos
                    </Typography>

                    {loading ? (
                      <Shimmer width={188} height={20} />
                    ) : (
                      <Typography type="bodySmall">
                        {uppercaseFirst(formatDate(prepareResult?.firstPaymentDate, 'MMMM/YYYY'))}{' '}
                        até{' '}
                        {uppercaseFirst(formatDate(prepareResult?.lastPaymentDate, 'MMMM/YYYY'))}
                      </Typography>
                    )}
                  </Flex>

                  <Flex direction="column" gap={8} width="100%">
                    <Typography type="bodyMedium" weight={600}>
                      IOF
                    </Typography>

                    {loading ? (
                      <Shimmer width={72} height={20} />
                    ) : (
                      <Typography type="bodySmall">{toMoney(prepareResult?.iofValue)}</Typography>
                    )}
                  </Flex>

                  <Flex direction="column" gap={8} width="100%">
                    <Typography type="bodyMedium" weight={600}>
                      Custo efetivo total (CET)
                    </Typography>

                    {loading ? (
                      <Shimmer width={152} height={20} />
                    ) : (
                      <Typography type="bodySmall">
                        {toPercentage(prepareResult?.effectiveRate)} a.m. |{' '}
                        {toPercentage(prepareResult?.totalEffectiveCost)} a.a.
                      </Typography>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </OpportunityBody>

        <OpportunityFooter>
          <Button
            onClick={handleNextStep}
            customColor={buttonColor}
            loading={loading}
            fullWidth
            rounded
          >
            Avançar
          </Button>
        </OpportunityFooter>
      </OpportunityContainer>
    );
  },
);
