import React, { useCallback, useMemo } from 'react';
import moment from 'moment';

import { ArrowRightCircle, CaretDown, User } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';
import { Person } from '~/typings/entities/person';

import { DeactivationReasons } from '~/typings/enums/DeactivationReason';
import { BasicListRow, BasicInfo, InfoTitle, InfoText } from './ProfileResume.styled';

type PronounsPopoverProps = {
  person: Person;
  canceledByClient?: boolean;
};

export function CancelPopover({ person, canceledByClient }: PronounsPopoverProps) {
  const formatDate = useCallback((dateToFormat) => {
    if (!dateToFormat) return 'Indisponível';
    return moment(dateToFormat).format('DD [de] MMMM [de] YYYY, HH[h]mm');
  }, []);

  const reason = useMemo(() => {
    const { deactivationReason, otherDeactivationReason } = person;
    return deactivationReason === 'OTHER_REASON'
      ? otherDeactivationReason
      : DeactivationReasons[deactivationReason];
  }, [person]);

  const popoverContent = (
    <>
      <BasicListRow>
        <User className="action-icon" width={14} height={14} />
        <BasicInfo>
          <InfoTitle>
            {canceledByClient ? 'Cancelado pelo cliente' : 'Reprovado pelo agente'}
          </InfoTitle>
          <InfoText>
            {!canceledByClient && `${person?.nameUserWhoCancel} - `}
            {formatDate(person?.deactivationDate)}
          </InfoText>
        </BasicInfo>
      </BasicListRow>

      {!!reason && (
        <BasicListRow>
          <ArrowRightCircle className="action-icon" width={14} height={14} />
          <BasicInfo>
            <InfoTitle>Motivo {canceledByClient ? 'do cancelamento' : 'da reprovação'}</InfoTitle>
            <InfoText>{reason}</InfoText>
          </BasicInfo>
        </BasicListRow>
      )}
    </>
  );

  return (
    <Popover
      placement="right"
      trigger="click"
      title={canceledByClient ? 'Motivo do cancelamento' : 'Motivo da reprovação'}
      content={popoverContent}
    >
      <CaretDown className="action-icon" width={14} height={14} />
    </Popover>
  );
}
