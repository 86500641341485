const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1024,
  xl: 1200,
  xxl: 1400,
};

export type BreakpointTypes = keyof typeof breakpoints;

export default breakpoints;
