import styled, { css } from 'styled-components';
import { Icon as AntIcon } from 'antd';
import { themeToggleValue } from '~/common/utils/theme';

export const Label = styled.span`
  color: #8c8c8c;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;

export const Icon = styled(AntIcon)`
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const Value = styled.div`
  display: flex;
  align-items: center;

  > span {
    display: block;
    font-size: 14px;
    color: ${({ theme }) =>
      themeToggleValue(theme, '#000000', { dark: theme.colors.element.secondary })};
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;

    &:active {
      opacity: 0.5;
    }

    @media (max-width: 520px) {
      font-size: 14px;
    }
  }

  ${({ color }) =>
    color &&
    css`
      > span {
        color: ${color};
      }
    `}

  ${({ action, theme, clickable }) =>
    action &&
    clickable &&
    css`
      cursor: pointer;
      background-color: ${theme.grayContainer};
      border-radius: 8px;
      width: 90%;
      display: flex;
      align-content: center;
      padding: 10px;

      > span {
        color: ${theme.secondaryColor};
        font-weight: 400;
        font-size: 14px;
      }
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  ${({ allClickable }) =>
    allClickable &&
    css`
      background-color: rgba(0, 0, 0, 0.09);
      min-height: 40px;
      padding: 4px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
