// @flow

import React, { useMemo } from 'react';
import File from '~/screens/FileHistory/components/File';
import { Get } from '~/common';
import { FilesContainer, CompareButtom } from '~/screens/FileHistory/FileHistory.styles';
import Viewer from 'react-viewer';
import _ from 'lodash';

type FileHistoryProps = {
  loading: boolean,
  history: [],
  files: {},
};

function VisualizationImagesModal(props) {
  return (
    <div
      style={{
        width: '50%',
        display: 'flex',
        justifyContent: 'space-around',
      }}
    >
      <Viewer
        noImgDetails
        noResetZoomAfterChange
        toolbar={{
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          reset: 0,
          prev: 4,
          play: {
            show: 4,
            size: 'large',
          },
          next: 4,
          rotateLeft: 4,
          rotateRight: 4,
          flipHorizontal: 0,
          flipVertical: 0,
        }}
        visible={props.visible}
        onClose={props.onClose}
        images={props.files}
      />
    </div>
  );
}

function FileHistory(props: FileHistoryProps) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [imagesToView, setImagesToView] = React.useState([]);

  const history = useMemo(() => _.sortBy(props.history, 'docDate'), [props.history]);

  function showModal(files) {
    setModalVisible(true);
    setImagesToView(files);
  }

  function showAllImages() {
    showModal(
      props.history.map((file) => ({
        src: props.files[file.id]?.uri,
      })),
    );
  }

  function onCloseModal() {
    setModalVisible(false);
  }
  return (
    <>
      {props.files && Object.keys(props.files).length > 0 ? (
        <CompareButtom onClick={showAllImages} title="Comparar imagens" />
      ) : null}
      <FilesContainer>
        {history &&
          Array.isArray(history) &&
          history.map((file) => (
            <File
              docDate={file.docDate}
              onClick={showModal}
              blob={Get(props.files[file.id], 'uri')}
            />
          ))}
        {props.files && Object.keys(props.files).length > 0 ? (
          <VisualizationImagesModal
            visible={modalVisible}
            onClose={onCloseModal}
            files={imagesToView}
          />
        ) : null}
      </FilesContainer>
    </>
  );
}

export default FileHistory;
