import takeRequest from '~/store/takeRequest';
import { put, takeLatest } from 'redux-saga/effects';
import * as PeopleActions from '~/store/people/actions';
import { requestHisconAutoUpdate } from './actions';

export function* reloadPeopleInfo({ meta }: any) {
  const { personId } = meta.payload;
  yield put(PeopleActions.getPersonDetail.start({ id: personId }));
}

export function* hisconUpdateSaga() {
  yield takeRequest(requestHisconAutoUpdate);

  yield takeLatest(requestHisconAutoUpdate.success.toString(), reloadPeopleInfo);
}
