import styled, { css } from 'styled-components';
import { Button, Typography } from '~/ui/components';
import { themeColor } from '~/common/utils/theme';

export const NotificationContainer = styled.div`
  min-width: 380px;
  padding: 8px 0;
`;

export const NotificationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'h4',
  weight: 600,
  color: theme.colors.element.primary,
}))``;

export const MarkAsRead = styled(Button).attrs({
  variant: 'text',
  size: 'sm',
})`
  padding: 0;

  .typography {
    font-size: 12px;
    line-height: 18px;
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    font-weight: 500;
  }
`;

export const MessagesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  overflow-y: auto;
  padding-right: 4px;
  max-height: 60vh;
  height: 100%;
`;

type MessageProps = {
  unread?: boolean;
};

export const Message = styled.div<MessageProps>`
  display: flex;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:last-of-type {
    border-bottom: none;
  }

  ${({ unread }) =>
    unread &&
    css`
      background: ${themeColor('background.secondary', { dark: 'background.tertiary' })};
    `}
`;

export const MessageBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const MessageIcon = styled.div`
  ${({ color }) => css`
    svg {
      color: ${color} !important;
      width: 24px;
      height: auto;
    }
  `}
`;

export const MessageText = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.element.primary,
  type: 'caption',
  element: 'p',
}))``;

export const MessageDate = styled(Typography).attrs(({ theme }) => ({
  color: theme.colors.gray[300],
  type: 'caption',
  element: 'span',
}))``;
