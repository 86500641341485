import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pick } from 'underscore';
import { compose } from 'recompose';
import { Button, Col, Form, Input, Row } from 'antd';
import theme from '~/themes/aphroditeTheme/theme';
import { X } from 'react-feather';

import { sendPushNotification } from '~/store/sendPushNotification/actions';

import { Action } from '../Actions';
import styles from './styles';

const { css, withStyles } = theme;
const { Item: FormItem } = Form;

class Refuse extends Component<{}> {
  constructor(props) {
    super(props);
    this.state = {
      messageTitle: '',
      message: '',
      visible: false,
    };
  }

  onPressSubmit = (e) => {
    e.preventDefault();
    const { messageTitle, message } = this.state;
    const personId = window.location.pathname.match('[^/]+(?=$|$)')[0];

    this.setState({ visible: false });
    this.props.dispatch(sendPushNotification.start({ personId, messageTitle, message }));
  };

  onPressOpen = () => {
    this.setState({ visible: true });
  };

  onPressClose = () => {
    this.setState({ visible: false });
  };

  render() {
    const { styles } = this.props;

    const style = {
      overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.3s',
        background: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1000,
      },
    };

    return [
      <Action
        key="button"
        onPress={this.onPressOpen}
        style={css(styles.action)}
        title="Enviar Notificação"
      />,
      <Modal
        ariaHideApp={false}
        contentLabel="Confirm"
        isOpen={this.state.visible}
        key="modal"
        style={style}
        {...css(styles.modal)}
      >
        <div {...css(styles.modal__header)}>
          <span {...css(styles.modal__title)}>Envio de Notificação:</span>
          <button onClick={this.onPressClose} {...css(styles.modal__close)}>
            <X {...css(styles.closeButton)} size={20} />
          </button>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={{ span: 24 }}>
              <span>Título:</span>
              <FormItem>
                <Input
                  onChange={(ev) => this.setState({ messageTitle: ev.target.value })}
                  placeholder="Título..."
                  type="input"
                />
              </FormItem>
              <span>Mensagem:</span>
              <FormItem>
                <Input.TextArea
                  rows="4"
                  onChange={(ev) => this.setState({ message: ev.target.value })}
                  placeholder="Mensagem..."
                  type="input"
                />
              </FormItem>
            </Col>
          </Row>
          <Button onClick={this.onPressSubmit} {...css(styles.buttonConfirm)}>
            Enviar
          </Button>
        </Form>
      </Modal>,
    ];
  }
}

Refuse.defaultProps = {
  isRefusable: false,
};

export function mapStateToProps(state) {
  return {
    sendPushNotification: sendPushNotification.getResult()(state),
    pushNotificationSent: sendPushNotification.isSuccessful()(state),
    sendPushNotificationFailed: sendPushNotification.hasFailure()(state),
  };
}

export default compose(withRouter, withStyles(styles), connect(mapStateToProps))(Refuse);
