import { useCallback } from 'react';

type FileInfo = {
  name: string;
  uri: string;
  extension?: string;
}

export default function useDownloadFile() {
  return useCallback((fileInfo: FileInfo) => {
    const { name, uri, extension } = fileInfo;
    const elem = document.createElement('a');

    elem.setAttribute('href', uri);
    elem.setAttribute('download', name + (extension || ''));
    elem.setAttribute('style', 'display: none');
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }, []);
}
