import styled from 'styled-components';

type TwilioInputContainerProps = {
  checked?: boolean;
};

export const TwilioInputContainer = styled.div<TwilioInputContainerProps>`
  .switch-container {
    //margin-bottom: ${({ checked }) => (checked ? '30px' : '12px')};
    margin-bottom: 12px;
    margin-top: 12px;

    label {
      font-size: 14px;
      margin-bottom: 2px;
      position: relative !important;
    }
  }
`;
