// @flow

import React from 'react';
import { Card } from 'antd';
import ClientName from '~/screens/components/ClientName';

export type FraudProfileProps = {
  id: number,
  name: string,
  document: string,
};

function FraudProfile({ name, id, document }: FraudProfileProps) {
  return (
    <Card>
      <ClientName type='small' name={name} id={id} />
      <span>{document}</span>
    </Card>
  );
}

export default FraudProfile;
