import React, { useCallback } from 'react';

import { ContractValueList, OpportunityListItem } from '~/typings/entities/Opportunity';
import { Flex, Typography } from '~/ui/components';

import {
  CheckboxContainer,
  ContractBox,
} from '~/screens/Person/components/Drawers/Opportunity/REFIN/Selection/Selection.styled';
import toMoney from '~/common/masked/toMoney';
import { ResumeBody } from './Resume.styled';

export type ResumeProps = {
  contracts: ContractValueList[];
  opportunity: OpportunityListItem;
};

export function SelectionResume({ contracts, opportunity }: ResumeProps) {
  const getFinancialInstitution = useCallback(
    (bankCode: string) => {
      const financialInstitution = opportunity?.portableContracts?.map(
        (contract: any) => contract?.financialInstitutionDTO,
      );
      return financialInstitution?.find((bank: any) => bank.bankNumber === bankCode);
    },
    [opportunity?.portableContracts],
  );

  return (
    <ResumeBody direction="column" gap={24} ph={32} pv={24} width="100%">
      <Flex align="center" justify="center" width="100%">
        <Typography type="paragraphLarge" weight={600} center fullWidth>
          Detalhes da oportunidade
        </Typography>
      </Flex>

      <Flex direction="column" gap={24} width="100%">
        {contracts?.map((contract) => (
          <ContractBox key={contract.contractId}>
            <CheckboxContainer rounded>
              <Flex width="100%" direction="column">
                <Typography type="bodyLarge" weight={600} fullWidth>
                  Receba até {toMoney(contract.valueForDeposit)}
                </Typography>
                <Typography type="bodySmall" color="element.secondary" fullWidth>
                  {getFinancialInstitution(contract?.originalBankNumber)?.name || '-'}
                </Typography>
              </Flex>
            </CheckboxContainer>

            <Flex width="100%" direction="column">
              <Flex gap={4} width="100%">
                <Typography type="bodyMedium" color="element.secondary" fullWidth>
                  {contract?.numberOfInstallments} parcelas de{' '}
                  <Typography.Bold color="element.primary" weight={600}>
                    {toMoney(contract?.installmentValue)}
                  </Typography.Bold>
                </Typography>
              </Flex>
            </Flex>
          </ContractBox>
        ))}
      </Flex>
    </ResumeBody>
  );
}
