export default function style({ unit }) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    table: {
      width: '100%',
      padding: `${unit * 1}px 0`,
    },
    status: {
      textAlign: 'center',
      padding: unit * 3,
    },
    heading: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    headingText: {
      margin: '0 !important',
      fontSize: unit * 1.4,
      fontWeight: 'bold',
      color: '#636878',
    },
  };
}
