import React, { useState } from 'react';

import { Button } from '~/ui/components';
import {
  Container,
  Title,
  Text,
  Image,
  CustomTag,
  TagContainer,
  ImageContainer,
  Span,
  CustomizedInput,
  InputContent,
} from './CustomizableModalContent.styled';

interface ICustomizableModalContentProps {
  title: string;
  text: string;
  tags?: string[];
  buttonLabel?: string;
  buttonColor?: 'white' | 'black';
  buttonVariant?: 'outline' | 'contained' | 'text';
  image?: string;
  imageAlt?: string;
  imageWidth?: string;
  imageMargin?: string;
  alignItems?: 'start' | 'center' | 'end';
  withInput?: boolean;
  inputPlaceholder?: string;
  onClick?: (value?: string) => void;
}

export function CustomizableModalContent({
  title,
  text,
  tags,
  buttonLabel,
  buttonColor = 'white',
  buttonVariant = 'outline',
  image,
  imageAlt = 'customizable-img-modal',
  imageWidth = '180px',
  imageMargin,
  alignItems = 'center',
  withInput = false,
  inputPlaceholder,
  onClick,
}: ICustomizableModalContentProps) {
  const [inputValue, setInputValue] = useState('');

  return (
    <Container $alignItems={alignItems}>
      {image && (
        <ImageContainer $imageWidth={imageWidth}>
          <Image src={image} alt={imageAlt} $imageMargin={imageMargin} />
        </ImageContainer>
      )}
      {tags && (
        <TagContainer>
          {tags.map((tag, i) => (
            <CustomTag rounded small key={i}>
              {tag}
            </CustomTag>
          ))}
        </TagContainer>
      )}
      <Title>{title}</Title>
      <Text>{text}</Text>
      {withInput && (
        <InputContent>
          <CustomizedInput
            placeholder={inputPlaceholder}
            value={inputValue}
            onChange={setInputValue}
          />
          <Span>Campo obrigatório</Span>
        </InputContent>
      )}
      {buttonLabel && (
        <Button
          onClick={() => onClick(inputValue)}
          rounded
          fullWidth
          color={buttonColor}
          variant={buttonVariant}
        >
          {buttonLabel}
        </Button>
      )}
    </Container>
  );
}
