export default function style({ unit }) {
  const fontFamily = 'Futura, sans-serif';
  const color = '#636878';
  const ease = {
    '0%': {
      opacity: 0,
      transform: 'scale(0.7) translate3d(0,0,0)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1) translate3d(0,0,0)',
    },
  };

  return {
    actions: {
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '56px',
      width: 250,
    },
    action: {
      cursor: 'pointer',
      fontFamily,
      lineHeight: 1.2,
      padding: `${unit * 1.5}px 0 0 0`,

      ':hover': {
        textDecoration: 'underline',
      },
    },
    modal: {
      animationName: ease,
      animationDuration: '150ms',
      animationTimingFunction: 'linear',
      animationFillMode: 'forwards',
      background: 'white',
      borderRadius: 3,
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1)',
      padding: unit * 2,
      position: 'relative',
      width: 600,
    },
    modal__header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: unit * 0.5,
      marginBottom: unit * 2,
    },
    modal__close: {
      border: 'none',
      outline: 'none',
      background: 'white',
      position: 'absolute',
      top: 5,
      right: 0,
    },
    closeButton: {
      color: '#777',
      transition: 'all 0.5s',

      ':hover': {
        color: 'black',
        background: '#dfdfdf',
        padding: '2px',
        borderRadius: 3,
      },
    },
    modal__title: {
      display: 'block',
      color: '#777',
      fontFamily,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    labelHover: {
      ':hover': {
        background: '#eee',
        width: '95%',
      },
      minHeight: '50px',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    key: {
      display: 'block',
      color: '#949494',
      fontFamily,
      fontSize: unit * 0.9,
      letterSpacing: '0',
    },
    preset: {
      color,
      fontSize: unit * 1.4,
      fontWeight: 'bold',
    },
  };
}
