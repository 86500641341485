import React, { useEffect, useMemo, useRef, useState } from 'react';

import { StepItem, Stepper, Steps, Typography } from '~/ui/components';

import {
  createTicketAssessment,
  selectAssessment,
  selectTicket,
  updateTicketAssessment,
} from '~/store/tickets/actions';
import { useAwaitControl } from 'react-redux-await-control';
import { getLoggedUser } from '~/store/user/actions';
import { Details } from '~/screens/Tickets/Assessment/Steps/Details';
import { ReportForm } from './Steps/ReportForm';
import { AnalysisForm } from './Steps/AnalysisForm';
import { Success } from './Steps/Success';

import {
  CloseButton,
  AssessmentContainer,
  AssessmentHeader,
  PrevButton,
} from './Assessment.styled';

export function Assessment() {
  const stepper = useRef<Stepper>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState<any>({});

  const selectTicketControl = useAwaitControl(selectTicket);
  const selectedTicket = selectTicketControl.result();

  const selectAssessmentControl = useAwaitControl(selectAssessment);
  const assessment = selectAssessmentControl.result();

  const createAssessmentControl = useAwaitControl(createTicketAssessment);
  const createAssessmentResult = createAssessmentControl.result();
  const createAssessmentLoading = createAssessmentControl.isRunning();
  const createAssessmentSuccess = createAssessmentControl.isSuccessful();

  const updateAssessmentControl = useAwaitControl(updateTicketAssessment);
  const updateAssessmentResult = updateAssessmentControl.result();
  const updateAssessmentLoading = updateAssessmentControl.isRunning();
  const updateAssessmentSuccess = updateAssessmentControl.isSuccessful();

  const loggedUserControl = useAwaitControl(getLoggedUser);
  const loggedUser = loggedUserControl.result();

  const loading = useMemo(
    () => createAssessmentLoading || updateAssessmentLoading,
    [createAssessmentLoading, updateAssessmentLoading],
  );

  const handleClose = () => {
    selectAssessmentControl.clear();
    stepper.current?.toStepById('analysisForm');
    setFormValues({});
  };

  const createAssessment = (values: any) => {
    if (!formValues.id) {
      const creatorId = loggedUser?.id;
      const creator = loggedUser?.name;
      const ticketId = selectedTicket?.id;

      const assessmentData = {
        ...formValues,
        ...values,
        creatorId,
        creator,
        ticketId,
        inProgress: true,
      };

      setFormValues(assessmentData);
      createAssessmentControl.start(assessmentData);
    } else {
      setFormValues((prev) => ({ ...prev, ...values }));
      stepper.current?.nextStep();
    }
  };

  const updateAssessment = (values) => {
    updateAssessmentControl.start({
      ...formValues,
      ...values,
      wrongFields:
        typeof values.wrongFields === 'string' ? [values.wrongFields] : values.wrongFields,
      inProgress: false,
    });
  };

  const steps: StepItem[] = [
    {
      id: 'analysisForm',
      component: <AnalysisForm onSubmit={createAssessment} />,
    },
    {
      id: 'reportForm',
      component: <ReportForm onSubmit={updateAssessment} />,
      metaData: { backButton: true },
    },
    {
      id: 'reportSuccess',
      component: <Success />,
      metaData: { resetOnClose: true },
    },
    {
      id: 'details',
      component: <Details />,
      metaData: { resetOnClose: true },
    },
  ];

  const currentMetaData: any = useMemo(() => steps?.[currentStep]?.metaData, [currentStep, steps]);

  useEffect(() => {
    if (createAssessmentResult) {
      setFormValues((prev) => ({ ...prev, ...createAssessmentResult }));
      stepper.current?.nextStep();
      createAssessmentControl.clear();
    }
  }, [createAssessmentSuccess]);

  useEffect(() => {
    if (updateAssessmentSuccess) {
      setFormValues(updateAssessmentResult);
      stepper.current?.toStepById('reportSuccess');
    }
  }, [updateAssessmentSuccess]);

  useEffect(() => {
    if (assessment?.id) {
      if (!assessment?.inProgress) {
        stepper.current?.toStepById('details');
      } else {
        setFormValues(assessment || {});
        stepper.current?.toStepById('analysisForm');
      }
    }
  }, [assessment]);

  useEffect(() => {
    if (selectedTicket) {
      setFormValues({});
    }
  }, [selectedTicket]);

  return (
    <>
      <AssessmentContainer visible={!!assessment}>
        <AssessmentHeader>
          {currentMetaData?.backButton && <PrevButton onClick={stepper?.current?.prevStep} />}
          <Typography type="headingH6" weight={600} center>
            Avaliação do ticket
          </Typography>
          <CloseButton onClick={handleClose} />
        </AssessmentHeader>

        {assessment && (
          <Steps
            ref={stepper}
            steps={steps}
            extraProps={{ onClose: handleClose, values: formValues, loading }}
            onStepChange={setCurrentStep}
          />
        )}
      </AssessmentContainer>
    </>
  );
}
