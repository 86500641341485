import styled from 'styled-components';
import { Divider as DividerComponent } from 'antd';

import { ColorsTypes } from '~/ui/theme/colors';
import { Typography } from '~/ui/components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

interface PalettedProps {
  colorPalette?: ColorsTypes;
}

export const PopoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;

  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  weight: 500,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const Divider = styled(DividerComponent)<PalettedProps>`
  margin: 2px 0 10px !important;
  height: 2px !important;
  background: ${(props) => props.theme.colors.secondary['50']} !important;
`;
