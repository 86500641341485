/* @flow */
import dialogError from '~/common/dialogErrors';

export const UPDATE_TRUST_FACE_REQUEST = 'UPDATE_TRUST_FACE_REQUEST';
export const UPDATE_TRUST_FACE_SUCCESS = 'UPDATE_TRUST_FACE_SUCCESS';
export const UPDATE_TRUST_FACE_FAILURE = 'UPDATE_TRUST_FACE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: UPDATE_TRUST_FACE_SUCCESS,
      payload,
    });
  };
}

export function failure({ messages }) {
  return dispatch => {
    dispatch({
      type: UPDATE_TRUST_FACE_FAILURE,
      payload: messages,
    });

    dialogError(messages || 'Falha ao Atualizar Foto Verdade.');
  };
}

export default function uploadHiscon(params) {
  return dispatch => {
    dispatch({
      type: UPDATE_TRUST_FACE_REQUEST,
      payload: params,
    });
  };
}
