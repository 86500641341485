import React, { useRef } from 'react';
import { useModal } from '~/hooks/useModal';
import { Alert, Button, FileUploader, Form, Input, Select } from '~/ui/components';
import validateFileName from '~/common/Validations/validateFileName';
import validateFileSize from '~/common/Validations/validateFileSize';
import {
  AlertContainer,
  Container,
  ModalHeader,
  ModalTitle,
  CloseButton,
  Divider,
} from '../UploadFileModal.styled';

type UploadFileFormProps = {
  onSubmit: (values: any) => void;
  data?: {
    uploadReason: {
      name: string;
      value: number;
    };
    comment?: string;
    file: {
      name: string;
      size: number;
    };
  };
};

const filesRules = [
  {
    value: '1',
    name: 'Boleto de quitação',
    fileName: 'boleto-quitação.pdf',
    minSize: 50,
    maxSize: 220,
  },
  {
    value: '2',
    name: 'Comprovante de CPF',
    fileName: 'comprovante situação cadastral.pdf',
    minSize: 60,
    maxSize: 70,
  },
];

export function UploadFileForm({ onSubmit, data }: UploadFileFormProps) {
  const formRef = useRef(null);
  const [disabledUpload, setDisabledUpload] = React.useState(!data.uploadReason);
  const [selectedReason, setSelectedReason] = React.useState(
    filesRules.find((reason) => Number(reason.value) === data?.uploadReason?.value),
  );
  const { closeAll } = useModal();

  const reasons = filesRules.map(({ name, value }) => ({ name, value }));

  const closeModal = (e: Event) => {
    e.preventDefault();
    closeAll();
  };

  const onFormChange = (value) => {
    const reason = filesRules.find((reason) => reason.value === value?.[0]);
    setSelectedReason(reason);
    setDisabledUpload(!value.length);
    formRef.current.setFieldsValue({ file: '' });
  };

  return (
    <Container>
      <ModalHeader>
        <ModalTitle>Insira novos Arquivos</ModalTitle>
        <CloseButton width={26} height={26} onClick={closeModal} />
      </ModalHeader>
      <Divider />
      <AlertContainer>
        <Alert
          status="warning"
          label="Essa funcionalidade deve ser usada somente por times pré-autorizados."
        />
      </AlertContainer>
      <Form
        ref={formRef}
        onSubmit={onSubmit}
        inputs={[
          {
            id: 'uploadReason',
            label: 'Motivo do upload',
            initialValue: data?.uploadReason?.value ? [String(data?.uploadReason?.value)] : [],
            input: (
              <Select
                allowClear
                onChange={onFormChange}
                options={reasons}
                fieldNames={{ label: 'name', value: 'value' }}
              />
            ),
            options: {
              rules: [{ required: true, message: 'Este campo é obrigatório' }],
            },
          },
          {
            id: 'comment',
            label: 'Comentário',
            initialValue: data?.comment,
            input: <Input placeholder="Informe o comentário" allowClear textArea rows={3} />,
          },
          {
            id: 'file',
            label: 'Arquivo',
            initialValue: data?.file,
            input: <FileUploader disabled={disabledUpload} accept="application/pdf" />,
            options: {
              rules: [
                { required: true, message: 'Este campo é obrigatório' },
                {
                  validator: validateFileName,
                  allowedName: selectedReason?.fileName,
                  message: (
                    <>
                      O nome do arquivo deve ser: <b>{selectedReason?.fileName}</b>
                    </>
                  ),
                },
                {
                  validator: validateFileSize,
                  min: selectedReason?.minSize,
                  max: selectedReason?.maxSize,
                  message: (
                    <>
                      O tamanho do arquivo deve ter entre: <b>{selectedReason?.minSize}kb</b> e{' '}
                      <b>{selectedReason?.maxSize}kb</b>
                    </>
                  ),
                },
              ],
            },
          },
        ]}
      >
        <Button rounded fullWidth color="black">
          Prosseguir
        </Button>
      </Form>
    </Container>
  );
}
