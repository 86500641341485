import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Wrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaginationContent = styled.ul`
  display: flex;
  list-style-type: none;
  gap: 16px;
  margin-bottom: 0;
`;

export const Item = styled.li`
  list-style-type: none;
  width: 40px;
  height: 40px;
  border: 1px solid ${themeColor('element.primary', { dark: 'element.secondary' })};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 100)};
  border-radius: 4px;
  display: flex;
  justify-content: center;

  ${({ currentPage }) =>
    currentPage &&
    css`
      border: 1px solid ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    `};
`;

export const Button = styled.button`
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const PageTypography = styled(Typography).attrs(() => ({
  type: 'headingXSmall',
  element: 'p',
  lineHeight: '19.3px',
  weight: '600',
}))`
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};

  ${({ currentPage }) =>
    currentPage &&
    css`
      color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    `};
`;
