import styled from 'styled-components';
import { Flex } from '~/ui/components';

export const ResumeBody = styled(Flex)`
  background: ${({ theme }) => theme.colors.background.primary};
  border-radius: 18px;

  .typography.right {
    text-align: right;
  }
`;
