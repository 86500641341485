import React, { ReactChildren, useCallback, useRef } from 'react';

import { useAwaitControl } from 'react-redux-await-control';

import { solveBiometry } from '~/store/pendencies/actions';
import pendencyTitle from '~/typings/enums/PendencyType';
import { UploadFile } from '~/components';

import { Pendency } from '~/screens/ContractDetails/Panels/Pendencies/styled';
import { getLoggedUser } from '~/store/user/actions';

type DocumentsProps = {
  id: string;
  contractId?: string;
  type?: string;
  reason: string;
  children: ReactChildren;
};

export default function Biometry({ id, contractId, reason, type, children }: DocumentsProps) {
  const inputEl = useRef(null);

  const solveBiometryControl = useAwaitControl(solveBiometry);
  const getLoggedUserControl = useAwaitControl(getLoggedUser);
  const roles = getLoggedUserControl.result()?.roles;

  const isAgent = roles?.includes('BACKOFFICE');

  const sendFile = useCallback(
    (file) => {
      solveBiometryControl.start({ file, id, contractId });
    },
    [id, contractId],
  );

  const click = useCallback(() => inputEl.current?.open(), [inputEl.current]);

  return (
    <>
      <Pendency onClick={!isAgent ? click : null} isDisabled={isAgent}>
        {pendencyTitle(type) || children}
        <span>{reason}</span>
      </Pendency>
      <UploadFile ref={inputEl} onFileOk={sendFile} />
    </>
  );
}
