import React from 'react';

import { Shimmer } from '~/ui/components';
import { LoadingSpinner } from '~/ui/assets/icons';
import { Container, TabHeader, TabPane, TabBody } from './TabShimmer.styled';

export type TabShimmerProps = {
  tabs?: number;
};

export function TabShimmer({ tabs = 3 }: TabShimmerProps) {
  return (
    <Container>
      <TabHeader>
        {Array.from(Array(tabs).keys()).map((tab) => (
          <TabPane key={tab}>
            <Shimmer height={20} />
          </TabPane>
        ))}
      </TabHeader>
      <TabBody>
        <LoadingSpinner />
      </TabBody>
    </Container>
  );
}
