import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';
import _ from 'lodash';
import moment from 'moment';

import { WalletCard } from '~/typings/entities/Wallet';
import { ClixClient } from '~/typings/entities/Clix';
import { getCards } from '~/store/wallet/actions';
import { InfoGrid, Tag } from '~/ui/components';

import { CardsPopover } from '../../Popovers';

type CreditInfoProps = {
  info: ClixClient;
  loading?: boolean;
};

const StatusColorMap = {
  Contratado: 'positive',
  Aprovado: 'positive',
  Reprovado: 'negative',
};

export function CreditInfo({ info, loading }: CreditInfoProps) {
  const getCardsControl = useAwaitControl(getCards);
  const cards: WalletCard[] = getCardsControl.result();
  const cardsLoading = getCardsControl.isRunning();
  const { colors } = useTheme();

  const validCards = cards?.filter((card) => card.status === 'ACTIVE');
  const otherCards = cards?.filter((card) => card.status !== 'ACTIVE');

  const accountStatus = useMemo(() => {
    const palette = StatusColorMap[info.status] || 'alert';
    const textColor = colors?.[palette]?.primaryAlt;
    const bgColor = colors?.[palette]?.secondary;

    if (info.status) {
      return (
        <Tag small rounded textColor={textColor} bgColor={bgColor}>
          {info.status.toUpperCase()}
        </Tag>
      );
    }

    return '-';
  }, [info]);

  const list = useMemo(() => {
    const facialBiometries = _.uniqBy(info?.facialBiometries, (e) => e.facialValidationType)?.map(
      ({ score, facialValidationType }) => ({
        name: `Verificado com ${facialValidationType}`,
        value: (
          <Tag rounded colorPalette="gray">
            {score}% compatível
          </Tag>
        ),
      }),
    );

    return [
      {
        name: 'Status da conta',
        value: accountStatus,
      },
      {
        name: 'Data da contratação',
        value: info?.signatureDate ? moment(info?.signatureDate).format('DD/MM/YYYY') : '-',
      },
      ...facialBiometries,
      {
        name: 'Cartões válidos',
        value: validCards ? <CardsPopover cards={validCards} /> : '-',
      },
      {
        name: 'Histórico de cartões',
        value: otherCards ? <CardsPopover cards={otherCards} /> : '-',
      },
    ];
  }, [info, validCards, otherCards, cardsLoading]);

  return (
    <InfoGrid
      loading={loading}
      list={list}
      grid="18% 18% 18% 18% 18%"
      shimmerLength={6}
      responsive={{ 1350: '30% 30% 30%', 1168: '48% 48%' }}
    />
  );
}
