// @flow

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Name = styled.h3`
  color: #636878;
  font-size: ${props => (props.type === 'small' ? '14px' : '24px')};
  font-weight: 500;
  letter-spacing: 0;
  margin: 0;
`;

type ClientNameProps = {
  name: string,
  id: string,
  type?: 'normal' | 'small',
};

const ClientName = ({ name, id, type = 'normal' }: ClientNameProps) => {
  return (
    <Name type={type}>
      {id ? (
        <Link to={'/backoffice/people/' + id} target='_blank'>
          {name}
        </Link>
      ) : (
        name
      )}
    </Name>
  );
};

export default ClientName;
