/* global ClipboardItem */

import React, { useState } from 'react';
import { Button, Flex } from '~/ui/components';
import dataURLToBlob from '~/common/blob';

export type DownloadImageModalProps = {
  url?: string;
  width?: string | number;
  fileName?: string;
};

export function DownloadImageModal({ url, width, fileName }: DownloadImageModalProps) {
  const [isCopied, setIsCopied] = useState(false);

  async function imageToClipboard(url?: string): Promise<boolean> {
    const blob = dataURLToBlob(url);

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);

      return true;
    } catch (error) {
      return false;
    }
  }

  const handleDownload = async () => {
    const link = document.createElement('a');
    link.download = `${fileName || 'noname'}.jpg`;
    link.href = url;
    link.click();
    link.remove();
  };

  const handleCopy = async () => {
    const success = await imageToClipboard(url);
    setIsCopied(success);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Flex direction="column" gap={8} align="center" justify="center" width="100%">
      <img width={width || '100%'} src={url} alt="Imagem" />

      <Button
        fullWidth
        rounded
        customColor={isCopied ? 'positive.primary' : 'black'}
        icon={isCopied ? 'Check' : 'Paste'}
        onClick={handleCopy}
      >
        {isCopied ? 'Imagem copiada!' : 'Copiar imagem'}
      </Button>

      <Button
        fullWidth
        rounded
        color="black"
        variant="outline"
        onClick={handleDownload}
        icon="Download"
      >
        Baixar imagem
      </Button>
    </Flex>
  );
}
