// @flow

import React, { useEffect, useState } from 'react';
import Card from '~/components/Card';
import { Box, Flex } from 'reflexbox';
import { SimpleInfo } from '~/components';
import toDate from '~/common/masked/toDate';
import { Money } from '~/common';
import ModalEdit from '~/screens/ContractDetails/Panels/RefinancedContracts/components/Contract/ModalEdit';

type RefinancedContractProps = {
  personId: number,
  refinancedContractId: string,
  //Info to be showed
  financialInstitutionKey: string,
  bankNumber: number,
  contractDate: string,
  installmentValue: number,
  numberOfInstallments: number,
  paidInstallments: number,
  paidInstallmentsCalc: number,
  outstandingBalance: number,
  outstandingBalanceCalc: number,
  reservationKey: string,
  // end info
  onPressLink: () => () => void,
  onFieldsSubmitted: () => void,
};

function getInfo(usedFields, props) {
  return [
    {
      label: 'Código',
      tooltip: props.fixedFinancialInstitutionKey
        ? 'Valor antigo: ' + props.financialInstitutionKey
        : undefined,
      value: usedFields.financialInstitutionKey,
    },
    {
      label: 'Banco',
      tooltip: props.fixedBankNumber
        ? 'Valor antigo: ' + props.bankNumber
        : undefined,
      value: usedFields.bankNumber,
    },
    {
      label: 'Data',
      value: toDate(props.contractDate),
    },
    {
      label: 'Valor da Parcela',
      tooltip: props.fixedInstallmentValue
        ? 'Valor antigo: ' + props.installmentValue
        : undefined,
      value: Money(usedFields.installmentValue),
    },
    {
      label: 'Número de Parcelas',
      tooltip: props.fixedNumberOfInstallments
        ? 'Valor antigo: ' + props.numberOfInstallments
        : undefined,
      value: usedFields.numberOfInstallments,
    },
    {
      label: 'Parcelas pagas',
      value: props.paidInstallments,
    },
    {
      label: 'Estimativa de parcelas pagas',
      value: props.paidInstallmentsCalc,
    },
    {
      label: 'Saldo devedor',
      value: Money(props.outstandingBalance),
    },
    {
      label: 'Estimativa de saldo devedor',
      value: Money(props.outstandingBalanceCalc),
    },
    {
      label: 'Chave de reserva',
      value: props.reservationKey,
    },
    {
      label: 'Taxa CET a.m.',
      value: Money(props.totalEffectiveCostPerMonthCalc * 100, true),
    },
    {
      label: 'Taxa CET a.a.',
      value: Money(props.totalEffectiveCostPerYearCalc * 100, true),
    },
  ];
}

function RefinancedContract(props: RefinancedContractProps) {
  const {
    financialInstitutionKey,
    bankNumber,
    installmentValue,
    numberOfInstallments,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [usedFields, setUsedFields] = useState({
    financialInstitutionKey:
      props.fixedFinancialInstitutionKey || financialInstitutionKey,
    bankNumber: props.fixedBankNumber || bankNumber,
    installmentValue: props.fixedInstallmentValue || installmentValue,
    numberOfInstallments:
      props.fixedNumberOfInstallments || numberOfInstallments,
  });

  useEffect(() => {
    setUsedFields({
      financialInstitutionKey:
        props.fixedFinancialInstitutionKey || financialInstitutionKey,
      bankNumber: props.fixedBankNumber || bankNumber,
      installmentValue: props.fixedInstallmentValue || installmentValue,
      numberOfInstallments:
        props.fixedNumberOfInstallments || numberOfInstallments,
    });
  }, [
    financialInstitutionKey,
    bankNumber,
    installmentValue,
    numberOfInstallments,
    props.fixedFinancialInstitutionKey,
    props.fixedBankNumber,
    props.fixedInstallmentValue,
    props.fixedNumberOfInstallments,
  ]);
  function openModalEditor() {
    setShowModal(true);
  }
  function onSubmitFields(fields) {
    props.onFieldsSubmitted(fields, props.refinancedContractId);
  }
  return (
    <>
      <Card
        title='Contrato'
        icon='file'
        actions={[
          {
            title: 'Editar',
            ghost: true,
            icon: 'edit',
            onPress: openModalEditor,
          },
          {
            title: 'Vincular',
            ghost: true,
            icon: 'link',
            onPress: props.onPressLink(props),
          },
        ]}
      >
        <Flex
          align='flex-start'
          wrap
          style={{ width: '100%', padding: '20px' }}
        >
          {getInfo(usedFields, props).map(info => {
            return (
              <Box my={1} w={1 / 4} style={{ paddingRight: 10 }}>
                <SimpleInfo {...info} />
              </Box>
            );
          })}
        </Flex>
      </Card>
      <ModalEdit
        {...props}
        {...usedFields}
        onSubmit={onSubmitFields}
        onPressClose={() => setShowModal(false)}
        visible={showModal}
      />
    </>
  );
}

RefinancedContract.defaultProps = {
  onPressLink: () => () => {},
  onFieldsSubmitted: () => {},
};

export default RefinancedContract;
