import React from 'react';
import { Input, Modal } from 'antd';
import { OldActions as MenuAction } from '~/components';
import { Label } from './styled';

const { TextArea } = Input;

class ReverseCancel extends React.Component {
  state = {
    reason: '',
    isShowModal: false,
  };

  toggleShowModal = () => {
    const { isShowModal } = this.state;
    this.setState({ isShowModal: !isShowModal });
  };

  onChangeReason = (e) => {
    this.setState({ reason: e.target.value });
  };

  onSucess = () => {
    const { reason } = this.state;
    this.props.onPress(reason);
    this.toggleShowModal();
    this.setState({
      reason: '',
    });
  };

  render() {
    const { isShowModal, reason } = this.state;
    return [
      <MenuAction title="Reverter Cancelamento" onPress={this.toggleShowModal} />,
      <Modal
        centered
        title="Reverter Cancelamento"
        okText="Reverter"
        onOk={this.onSucess}
        okButtonProps={{
          disabled: !reason,
        }}
        onCancel={this.toggleShowModal}
        visible={isShowModal}
      >
        <Label>Motivo:</Label>
        <TextArea
          value={reason}
          onChange={this.onChangeReason}
          placeholder="Digite o motivo para reverter o cancelamento"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Modal>,
    ];
  }
}

export default ReverseCancel;
