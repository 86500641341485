import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Tabs } from '~/ui/components';

import { getClientDetails } from '~/store/clix/actions';
import { getCards, getTransactions } from '~/store/wallet/actions';
import useQueryParams from '~/hooks/useQueryParams';
import { WarrantyType } from '~/typings/enums/Clix';
import { getPersonDetail } from '~/store/people/actions';
import { CreditTransactions } from '../../Tables';
import { CreditInfo } from '../../Sections';
import { InfoSection, InfoContainer } from '../index';

type CreditWarrantyProps = {
  personId: string;
};

export function CreditWarranty({ personId }: CreditWarrantyProps) {
  const query = useQueryParams();
  const getClixDetailsControl = useAwaitControl(getClientDetails);
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getTransactionsControl = useAwaitControl(getTransactions);
  const getCardsControl = useAwaitControl(getCards);

  const person = getPersonDetailsControl.result();
  const clixInfo = getClixDetailsControl.result(`${personId}_${WarrantyType.CREDIT}`);
  const clixInfoSuccess = getClixDetailsControl.isSuccessful(`${personId}_${WarrantyType.CREDIT}`);
  const transactionsSuccess = getTransactionsControl.isSuccessful(personId);
  const personDetailsSuccessful = getPersonDetailsControl.isSuccessful();

  useEffect(() => {
    if (personDetailsSuccessful) {
      const { document } = person;

      getClixDetailsControl.start(
        {
          document,
          status: query.get('status') || 'CONTRACTED',
          warranty: WarrantyType.CREDIT,
        },
        { actionId: `${personId}_${WarrantyType.CREDIT}` },
      );
    }
  }, [personDetailsSuccessful]);

  useEffect(() => {
    if (clixInfoSuccess && clixInfo?.wallet?.userId) {
      getCardsControl.start({ userId: clixInfo?.wallet?.userId });
    }
  }, [clixInfoSuccess, clixInfo]);

  useEffect(
    () => () => {
      getTransactionsControl.clear();
      getClixDetailsControl.clear();
      getCardsControl.clear();
    },
    [],
  );

  return (
    <InfoContainer>
      <InfoSection>
        <CreditInfo info={clixInfo} loading={!clixInfoSuccess} />
      </InfoSection>

      <Tabs
        className="internal-tabs"
        loading={!transactionsSuccess}
        tabs={[
          {
            key: 'credit-transaction-history',
            title: 'Histórico de transações',
            noPadding: true,
            component: <CreditTransactions personId={personId} />,
          },
        ]}
      />
    </InfoContainer>
  );
}
