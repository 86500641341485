import moment from 'moment';

export default function fromNow(date: string, initialDate?: string): string {
  const now = moment(initialDate);
  const past = moment(date);
  const years = now.diff(past, 'years');
  past.add(years, 'years');

  const months = now.diff(past, 'months');
  past.add(months, 'months');

  const days = now.diff(past, 'days');

  const result = [
    years ? `${years} ano${years > 1 ? 's' : ''}` : null,
    months ? `${months} ${months > 1 ? 'meses' : 'mês'}` : null,
    days ? `${days} dia${days > 1 ? 's' : ''}` : null,
  ];

  return result
    .filter((el) => el)
    .join(', ')
    .replace(/,(?=[^,]*$)/, ' e');
}
