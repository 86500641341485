import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CaretRight, CaretLeft } from '~/ui/assets/icons';
import { useTheme } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { PaginationContent, Item, Wrapper, Button, PageTypography } from './Pagination.styled';

interface IPaginationProps {
  totalPages: number;
  onClick: (page: number) => void;
  resetCurrentPage?: boolean;
}

const MAX_ITEMS = 9;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

export function Pagination({ totalPages, onClick, resetCurrentPage = false }: IPaginationProps) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const theme = useTheme();

  const caretColorActive = useMemo(
    () => themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
    [theme],
  );
  const caretColorDisabled = useMemo(() => themeToggleColor(theme, 'element.disabled'), [theme]);

  const maxFirstPage: number = useMemo(
    () => Math.max(totalPages - (MAX_ITEMS - 1), 1),
    [totalPages, MAX_ITEMS],
  );

  const firstPage: number = useMemo(
    () => Math.min(Math.max(currentPage - MAX_LEFT, 1), maxFirstPage),
    [currentPage, maxFirstPage],
  );

  const handleOnClick = useCallback((page) => {
    setCurrentPage(page);
    onClick(page);
  }, []);

  useEffect(() => {
    if (resetCurrentPage) {
      setCurrentPage(1);
    }
  }, [resetCurrentPage]);

  return (
    <Wrapper>
      <PaginationContent>
        <Item disabled={currentPage === 1}>
          <Button
            disabled={currentPage === 1}
            type="button"
            onClick={() => handleOnClick(currentPage - 1)}
          >
            <CaretLeft
              color={currentPage === 1 ? caretColorDisabled : caretColorActive}
              data-testid="previous-page-icon"
              width={24}
              height={24}
            />
          </Button>
        </Item>
        {Array.from({ length: Math.min(MAX_ITEMS, totalPages) }).map((_, index) => (
          <Item currentPage={currentPage === index + firstPage} key={index}>
            <Button type="button" onClick={() => handleOnClick(index + firstPage)}>
              <PageTypography currentPage={currentPage === index + firstPage}>
                {index + firstPage}
              </PageTypography>
            </Button>
          </Item>
        ))}
        <Item disabled={currentPage === totalPages}>
          <Button
            disabled={currentPage === totalPages}
            type="button"
            onClick={() => handleOnClick(currentPage + 1)}
          >
            <CaretRight
              color={currentPage === totalPages ? caretColorDisabled : caretColorActive}
              data-testid="next-page-icon"
            />
          </Button>
        </Item>
      </PaginationContent>
    </Wrapper>
  );
}
