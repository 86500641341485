import styled from 'styled-components';

import { Layout } from 'antd';
import { themeColor } from '~/common/utils/theme';

export const Header = styled(Layout.Header)`
  height: auto;
  padding: 0;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  line-height: normal;
  box-shadow: 0 2px 8px ${themeColor('background.secondary', { dark: 'background.transparent' })};
  z-index: 1000;
  position: sticky;
  top: 0;
  width: 100%;
`;
