import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';
import { gateway } from '~/services';
import moment from 'moment/moment';

const formatDate = (date) => {
  if (date && moment(date).isValid()) {
    return moment(date).format('YYYY-MM-DD');
  }
  return null;
};

export const listClixContracts = createAsyncAction('LIST_CLIX_CONTRACTS', {
  initialValue: [],
  saveResult: true,
  context: {
    onRequestError: () => message.error('Erro ao tentar carregar contratos clix'),
    errorParser: () => [],
    responseParser: ({ data }) => {
      const { content, ...pagination } = data;
      return { contracts: content, pagination };
    },
    request: (params) =>
      gateway.get('/backoffice/clix/contracts', {
        params: {
          ...params,
          page: params?.page || 0,
          size: params?.size || 50,
          document: params?.document?.replace(/\D/g, ''),
          signatureDate: formatDate(params?.signatureDate),
        },
      }),
  },
});

export const getClientDetails = createAsyncAction('GET_CLIX_CLIENT_DETAIL', {
  saveResult: true,
  initialValue: {},
  context: {
    responseParser: ({ data, config }) =>
      data.find((el) => el.warrantyType === config.params.warranty) || {},
    request: ({ document, status, warranty }) =>
      gateway.get('/backoffice/clix/general-information', {
        params: { document, status, warranty },
        headers: {
          'X-Api-Version': 'v2',
        },
      }),
    onRequestError: () => message.error('Erro ao tentar consultar dados do usuário clix'),
  },
});

export const getAvailableProducts = createAsyncAction('GET_CLIX_AVAILABLE_PRODUCTS', {
  saveResult: true,
  initialValue: [],
  context: {
    responseParser: ({ data }) => data.map((el) => el.warrantyType) || [],
    request: ({ document, status }) =>
      gateway.get('/backoffice/clix/general-information', {
        params: { document, status },
        headers: {
          'X-Api-Version': 'v2',
        },
      }),
    onRequestError: () => message.error('Erro ao tentar consultar dados do usuário clix'),
  },
});

export const getScores = createAsyncAction('GET_CLIX_SCORES', {
  saveResult: true,
  initialValue: {},
  context: {
    errorParser: () => [],
    onRequestError: () => {},
    responseParser: ({ data }) => data,
    request: ({ document }) => gateway.get('/backoffice/clix/score', { params: { document } }),
  },
});

export const getCurrentInvoice = createAsyncAction('GET_CLIX_CURRENT_INVOICE', {
  saveResult: true,
  initialValue: {},
  context: {
    onRequestError: () => message.error('Erro ao tentar consultar dados da fatura'),
    responseParser: ({ data }) => data,
    request: ({ document }) => gateway.get('/backoffice/clix/invoice', { params: { document } }),
  },
});

export const getInvoices = createAsyncAction('GET_CLIX_INVOICE', {
  saveResult: true,
  initialValue: [],
  context: {
    onRequestError: () => message.error('Erro ao tentar consultar a lista de faturas'),
    responseParser: ({ data }) => data,
    request: ({ document, warranty }) =>
      gateway.get('/backoffice/clix/invoices', { params: { document, warranty } }),
  },
});

export const getTransactions = createAsyncAction('GET_CLIX_TRANSACTIONS', {
  saveResult: true,
  initialValue: [],
  context: {
    onRequestError: () => message.error('Erro ao tentar carregar as transações'),
    responseParser: ({ data: { content, ...pagination } }) => ({
      transactions: content,
      pagination,
    }),
    request: (params) =>
      gateway.get('/backoffice/clix/transactions', {
        params,
      }),
  },
});

export const getTransactionDetails = createAsyncAction('GET_CLIX_TRANSACTION_DETAIL', {
  saveResult: true,
  initialValue: [],
  context: {
    onRequestError: () => message.error('Erro ao tentar carregar os detalhes da transação'),
    responseParser: ({ data }) => data,
    request: ({ document, id }) =>
      gateway.get(`/backoffice/clix/transactions/${id}/details`, { params: { document, id } }),
  },
});

export const getTransactionBojo = createAsyncAction('GET_CLIX_TRANSACTION_BOJO', {
  saveResult: true,
  context: {
    onRequestError: () => message.error('Erro ao baixar o bojo da transação'),
    responseParser: ({ data }) => data?.zipBase64,
    request: ({ document, id }) =>
      gateway.get(`/backoffice/clix/transactions/bojos`, {
        params: { document, transactionId: id },
      }),
  },
});

export const getContractBojo = createAsyncAction('GET_CLIX_CONTRACT_BOJO', {
  saveResult: true,
  context: {
    onRequestError: () => message.error('Erro ao baixar o bojo do contrato'),
    responseParser: ({ data }) => data?.zipBase64,
    request: ({ document }) => gateway.get(`/backoffice/clix/bojo`, { params: { document } }),
  },
});

export const getInvoiceDetails = createAsyncAction('GET_CLIX_INVOICE_DETAIL', {
  saveResult: true,
  initialValue: [],
  context: {
    onRequestError: () => message.error('Erro ao tentar carregar os detalhes da fatura'),
    responseParser: ({ data }) => data,
    request: ({ document, dueDate, warranty }) =>
      gateway.get('/backoffice/clix/invoices/details', { params: { document, dueDate, warranty } }),
  },
});

export const getTransactionFile = createAsyncAction('GET_CLIX_TRANSACTION_FILE', {
  saveResult: true,
  context: {
    onRequestError: () => message.error('Erro ao tentar carregar o arquivo da transação'),
    responseParser: ({ data, config }) => ({ type: config?.params?.type, ...data }),
    request: ({ document, id, type = 'document', warranty, byUrl }) =>
      gateway.get(`/backoffice/clix/transactions/${id}/file`, {
        params: { document, id, type, warranty, byUrl },
      }),
  },
});
