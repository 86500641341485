import styled from 'styled-components';
import { Button, Form, Popover } from '~/ui/components';

export const FilterButton = styled(Button).attrs(({ theme }) => ({
  size: 'sm',
  customColor: theme.colors.background.secondary,
  rounded: true,
  iconPosition: 'right',
  icon: 'Filter',
  fullWidth: true,
}))`
  border: 1px solid ${({ theme }) => theme.colors.divider.primary};
  gap: 8px;

  .typography {
    color: ${({ theme }) => theme.colors.element.primary};
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .tag {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      padding: 0;
      color: ${({ theme }) => theme.colors.brand.overPrimary};
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.element.primary};
  }
`;

export const FilterForm = styled(Form)`
  gap: 0;

  .extra-content {
    padding: 0 24px 24px 24px;

    button {
      max-width: calc(33.33% - 16px);
    }
  }

  .input-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    padding: 24px 8px 24px 24px;
  }

  .submit-button,
  .reset-button {
    width: 100%;
    max-width: 200px;
  }

  .ant-form-item {
    width: calc(33.33% - 16px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    .ant-form-item {
      width: calc(50% - 16px);
    }

    .extra-content {
      button {
        max-width: calc(50% - 16px);
      }
    }
  }

  @media (max-width: 767px) {
    .ant-form-item {
      width: 100%;
    }

    .submit-button,
    .reset-button {
      width: 100%;
      max-width: none;
    }

    .button-container {
      flex-direction: column;
    }

    .extra-content {
      button {
        max-width: 100%;
      }
    }
  }
`;

export const FilterPopover = styled(Popover).attrs({
  noHeader: true,
  noArrow: true,
  noPadding: true,
  trigger: 'click',
  width: 'min(988px, 90%)',
})`
  margin-bottom: 8px;
`;
