import React from 'react';

import { IconProps as IconComponentProps } from 'antd/lib/icon';
import { Icon as IconComponent } from 'antd';

type IconProps = IconComponentProps & {
  loading: boolean;
}

export default function Icon({ type, loading, ...rest }: IconProps) {
  return <IconComponent type={loading ? 'loading' : type} {...rest} spin={loading} />;
}
