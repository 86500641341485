import styled from 'styled-components';
import Attendance from '../Tabs/Attendance';

export const Comments = styled(Attendance)`
  padding: 0;

  .comments-list {
    max-height: none;
    overflow: auto;
  }
`;
