import { css } from 'styled-components';

export default css`
  .mt-time-picker {
    .ant-time-picker-panel-inner {
      margin-top: 36px;
      border-radius: 8px;
      overflow: hidden;
    }

    .ant-time-picker-panel-input-wrap {
      display: none;
    }

    .ant-time-picker-panel-select {
      li {
        color: ${({ theme }) => theme.colors.gray[900]};
        font-size: ${({ theme }) => theme.typography.types.caption.size};

        &.ant-time-picker-panel-select-option-selected {
          background: ${({ theme }) => theme.colors.primary[50]}20;
        }

        &:hover {
          background: ${({ theme }) => theme.colors.primary[50]}60;
        }
      }

      &::-webkit-scrollbar {
        width: 0.2em;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 4px;
      }
    }
  }
`;
