import moment from 'moment';
import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { uploadContractFile } from '~/store/contracts/actions';
import { getPersonDetail } from '~/store/people/actions';
import toHumanFileSize from '~/common/toHumanFileSize';
import { useModal } from '~/hooks/useModal';
import { getLoggedUser } from '~/store/user/actions';
import { Alert, Button } from '~/ui/components';
import {
  AlertContainer,
  BackButton,
  CloseButton,
  Container,
  Divider,
  FakeTextArea,
  FileIcon,
  FileInfoWrapper,
  FileSize,
  InfoWrapper,
  ModalHeader,
  ModalTitle,
  Text,
  Title,
} from '../UploadFileModal.styled';

type ConfirmDataProps = {
  backStep: () => void;
  id: string | number;
  data: {
    uploadReason: {
      name: string;
    };
    comment?: string;
    file: {
      name: string;
      size: number;
    };
  };
};

export function ConfirmData({ data, backStep, id }: ConfirmDataProps) {
  const getLoggedUserControl = useAwaitControl(getLoggedUser);
  const uploadFileControl = useAwaitControl(uploadContractFile);

  const { closeAll } = useModal();

  const { comment, file, uploadReason } = data;

  const userName = getLoggedUserControl.result()?.name;

  const isLoading = uploadFileControl.isRunning();
  const fileUploadSuccess = uploadFileControl.isSuccessful();

  const date = moment().format('DD/MM/YYYY');
  const hour = moment().format('HH[h]mm');

  const closeModal = (e: Event) => {
    e.preventDefault();
    closeAll();
  };

  const handleClick = () => {
    uploadFileControl.start({ ...data, id });
  };

  useEffect(() => {
    if (fileUploadSuccess) {
      closeAll();
    }

    return () => {
      uploadFileControl.clear();
    };
  }, [fileUploadSuccess]);

  return (
    <Container>
      <ModalHeader>
        <BackButton width={24} height={24} onClick={backStep} />
        <ModalTitle>Revise suas informações</ModalTitle>
        <CloseButton width={26} height={26} onClick={closeModal} />
      </ModalHeader>
      <Divider />
      <AlertContainer>
        <Alert
          status="warning"
          label="Em boletos de quitação a imagem também é exibida no app do cliente."
        />
      </AlertContainer>
      <Text>Verifique se as informações abaixo estão corretas para prosseguir.</Text>
      <InfoWrapper>
        <Title>Informações do Agente</Title>
        <Text>
          {userName} | {date} | {hour}
        </Text>
      </InfoWrapper>
      <InfoWrapper>
        <Title>Motivo de upload</Title>
        <Text>{uploadReason.name}</Text>
      </InfoWrapper>
      {comment && (
        <InfoWrapper>
          <Title>Comentário</Title>
          <FakeTextArea>
            <Text>{comment}</Text>
          </FakeTextArea>
        </InfoWrapper>
      )}
      <InfoWrapper>
        <FileInfoWrapper>
          <FileIcon width={20} height={20} />
          <Title>{file.name}</Title>
        </FileInfoWrapper>
        <FileSize>{toHumanFileSize(file?.size, true)}</FileSize>
      </InfoWrapper>
      <Button loading={isLoading} rounded fullWidth color="black" onClick={handleClick}>
        Confirmar
      </Button>
    </Container>
  );
}
