// @flow

import React, { useRef } from 'react';
import { Pendency } from '~/screens/ContractDetails/Panels/Pendencies/styled';
import pendencyTitle from '~/typings/enums/PendencyType';

type DefaultFileProps = {
  onSendFile: () => void,
  id?: number,
  type?: string,
};

function DefaultFile(props: DefaultFileProps) {
  const inputEl = useRef(null);

  const onOptionClicked = () => {
    inputEl.current.click();
  };

  const onFileOk = (event) => {
    const file = event.target.files[0];
    inputEl.current.value = null;
    props.onSendFile(file);
  };

  return (
    <>
      <Pendency onClick={onOptionClicked}>
        {pendencyTitle(props.type) || props.children}
        <span>{props.reason}</span>
      </Pendency>
      <input ref={inputEl} hidden type="file" accept="image/*" onChange={onFileOk} />
    </>
  );
}

export default DefaultFile;
