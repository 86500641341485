/* @flow */

import { message } from '~/common';

export const SOLVE_DOCUMENT_REQUEST = 'SOLVE_DOCUMENT_REQUEST';
export const SOLVE_DOCUMENT_SUCCESS = 'SOLVE_DOCUMENT_SUCCESS';
export const SOLVE_DOCUMENT_FAILURE = 'SOLVE_DOCUMENT_FAILURE';

export function success(payload) {
  message.success('Pendência de documentos resolvida');
  return dispatch => {
    dispatch({
      type: SOLVE_DOCUMENT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  message.error('Falha ao resolver pendência');
  return dispatch => {
    dispatch({
      type: SOLVE_DOCUMENT_FAILURE,
      payload: resource,
    });
  };
}

export default function uploadHiscon(payload) {
  return dispatch => {
    dispatch({
      type: SOLVE_DOCUMENT_REQUEST,
      payload,
    });
  };
}
