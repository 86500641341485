import { createAsyncAction } from 'react-redux-await-control';

import { perm } from '~/services';
import { UsersType, PermissionsType } from '~/typings/entities/user';

export const getUsers = createAsyncAction('PERM_LEVELS_GET_USERS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ limit, page = 1, email = '', name = '' }) =>
      perm.get('/user', {
        params: {
          first: (page - 1) * limit,
          max: limit,
          email,
          name,
        },
      }),
    responseParser: (response): UsersType => response.data,
  },
});

export const getRolesByUser = createAsyncAction('PERM_LEVELS_GET_ROLES_BY_USER', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ userId }) => perm.get(`/user/${userId}/roles`),
    responseParser: (response): PermissionsType => response.data,
  },
});
