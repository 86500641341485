import React, { useCallback, useMemo, useRef, useState } from 'react';

import theme from '~/ui/theme';
import { CaretDown, CaretRight } from '~/ui/assets/icons';
import { Typography, SearchBar } from '~/ui/components';
import useOutsideClickEffect from '~/common/useOutsideClickEffect';
import {
  CheckBox,
  Counter,
  FilterContainer,
  Item,
  Label,
  LabelContainer,
  ListContainer,
  Option,
  OptionLabel,
  SelectInput,
  Container,
  SearchBarContainer,
} from './Filter.styled';

type OptionValueType = number | string | boolean;

export type selectedFiltersType = {
  [key: string]: OptionValueType[];
};

type FilterOptionType = {
  label: string;
  value: any;
  items?: {
    label: string;
    value: OptionValueType;
  }[];
};

interface IFilterProps {
  label: string;
  options: FilterOptionType[] | string;
  onChange: (selectedFilters: selectedFiltersType | OptionValueType | undefined) => void;
  withCounter?: boolean;
  withSearchBar?: boolean;
  searchBarPlaceholder?: string;
  filterDirection?: 'right' | 'left';
  isDisabled?: boolean;
  singleSelect?: boolean;
  icon?: React.ReactElement;
}

export function Filter({
  label,
  options,
  withCounter = true,
  withSearchBar = false,
  searchBarPlaceholder,
  onChange,
  filterDirection = 'left',
  isDisabled = false,
  icon,
  singleSelect,
}: IFilterProps) {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [openedOptions, setOpenedOptions] = useState<OptionValueType[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<selectedFiltersType>({});
  const [searchValue, setSearchValue] = useState('');
  const containerRef = useRef(null);

  const handleToggleOption = useCallback(
    (value: number | string) => {
      const newOpenedOptions = [...openedOptions];

      if (newOpenedOptions.includes(value)) {
        newOpenedOptions.splice(newOpenedOptions.indexOf(value), 1);
      } else {
        newOpenedOptions.push(value);
      }

      setOpenedOptions(newOpenedOptions);
    },
    [openedOptions],
  );

  const handleChangeParent = useCallback(
    (parentValue: string) => {
      const newSelectedFilters = singleSelect ? {} : { ...selectedFilters };

      if (Array.isArray(options)) {
        // if option is already on the list, remove it
        if (selectedFilters[parentValue]) {
          delete newSelectedFilters[parentValue];
        } else {
          // if option is not on the list, add it
          newSelectedFilters[parentValue] = [];

          // Check all sub items
          options
            .find((option) => option.value === parentValue)
            ?.items?.forEach((item) => {
              newSelectedFilters[parentValue].push(item.value);
            });
        }
      }

      setSelectedFilters(newSelectedFilters);

      if (singleSelect) {
        onChange(newSelectedFilters[parentValue] ? parentValue : null);
      } else {
        onChange(newSelectedFilters);
      }
    },
    [onChange, options, selectedFilters],
  );

  const handleChangeItem = useCallback(
    (value: OptionValueType, parent: string) => {
      const newSelectedFilters = { ...selectedFilters };

      // if option is already on the list, remove it
      if (newSelectedFilters[parent]?.includes(value)) {
        newSelectedFilters[parent].splice(newSelectedFilters[parent].indexOf(value), 1);

        // if all sub items are unchecked, remove parent from list
        if (newSelectedFilters[parent].length === 0) {
          delete newSelectedFilters[parent];
        }
      } else {
        // if option is not on the list, add it
        if (!newSelectedFilters[parent]) {
          newSelectedFilters[parent] = [];
        }

        newSelectedFilters[parent].push(value);
      }

      setSelectedFilters(newSelectedFilters);
      onChange(newSelectedFilters);
    },
    [onChange, selectedFilters],
  );

  const selectCounter = useMemo(() => {
    let count = 0;

    if (Array.isArray(options)) {
      options.forEach((option) => {
        if (selectedFilters[option.value]) {
          count += 1;
        }
      });
    }

    return count;
  }, [selectedFilters, options]);

  const onChangeSearchBar = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const handleOnClickSelectInput = useCallback(() => {
    if (!isDisabled) {
      setIsSelectOpen(!isSelectOpen);
    }
  }, [isDisabled, isSelectOpen]);

  const renderOptions = useMemo(
    () =>
      Array.isArray(options) ? (
        options
          ?.filter((opt) => opt.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
          .map((option, i) => (
            <div key={option.value}>
              {option.items?.length > 0 ? (
                <>
                  <Option
                    isOptionOpen={
                      openedOptions.includes(option.value) ? '#f5f5f5' : theme.colors.white.main
                    }
                  >
                    <CheckBox
                      type="checkbox"
                      checked={!!selectedFilters[option.value]}
                      onChange={() => handleChangeParent(option.value)}
                      data-testid={`main-checkbox-${i}`}
                      data-selected={!!selectedFilters[option.value]}
                    />
                    <OptionLabel onClick={() => handleToggleOption(option.value)}>
                      <Typography type="caption" element="p">
                        {option.label}
                      </Typography>
                      <Typography type="footnote" weight={500}>
                        {openedOptions.includes(option.value) ? (
                          <CaretDown color="#D80073" data-testid="option-caretDown" />
                        ) : (
                          <CaretRight color="#CFCFCF" data-testid="option-caretRight" />
                        )}
                      </Typography>
                    </OptionLabel>
                  </Option>
                  {openedOptions.includes(option.value) && (
                    <div>
                      {option.items.map((item, index) => (
                        <Item key={item.value}>
                          <CheckBox
                            type="checkbox"
                            checked={!!selectedFilters[option.value]?.includes(item.value)}
                            onChange={() => handleChangeItem(item.value, option.value)}
                            data-testid={`main-checkbox-${i}-children-${index}`}
                            data-selected={selectedFilters[option.value]?.includes(item.value)}
                          />
                          <span>{item.label}</span>
                        </Item>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <Option>
                  <CheckBox
                    type="checkbox"
                    data-testid={`checkbox-${i}`}
                    checked={!!selectedFilters[option.value]}
                    onChange={() => handleChangeParent(option.value)}
                    id={option.value}
                  />
                  <OptionLabel htmlFor={option.value}>
                    <Typography type="caption" element="p">
                      {option.label}
                    </Typography>
                  </OptionLabel>
                </Option>
              )}
            </div>
          ))
      ) : (
        <Typography type="caption" element="p">
          {options}
        </Typography>
      ),
    [
      handleChangeItem,
      handleChangeParent,
      handleToggleOption,
      openedOptions,
      options,
      selectedFilters,
      searchValue,
    ],
  );

  useOutsideClickEffect(containerRef, () => {
    setIsSelectOpen(false);
  });

  return (
    <Container data-testid="filter-content" ref={containerRef}>
      <SelectInput
        isSelectOpen={isSelectOpen}
        onClick={handleOnClickSelectInput}
        isDisabled={isDisabled}
      >
        <LabelContainer>
          {icon && icon}
          <Label>{label}</Label>
          {withCounter && selectCounter > 0 && (
            <Counter data-testid="filter-counter">{selectCounter}</Counter>
          )}
        </LabelContainer>

        <CaretRight className="caret" data-testid="caret" width={12} height={12} />
      </SelectInput>
      {isSelectOpen && !isDisabled && (
        <FilterContainer filterDirection={filterDirection}>
          {withSearchBar && (
            <SearchBarContainer>
              <SearchBar placeholder={searchBarPlaceholder} onChange={onChangeSearchBar} />
            </SearchBarContainer>
          )}
          <ListContainer data-testid="filter-options-container">{renderOptions}</ListContainer>
        </FilterContainer>
      )}
    </Container>
  );
}
