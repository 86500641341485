/* @flow */
/* global localStorage */
/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { ajax } from 'rxjs/observable/dom/ajax';
import { isEmpty } from 'underscore';

import { store } from '~/MainApp';
import config from './config';
import { logout, getSessionToken } from './user/actions';

const initialOptions = {
  headers: {},
  onCloseError: () => {},
  responseType: 'json',
  retries: 3,
  timeout: 240000,
};

const ENDPOINT = config.endpoint;
const ENDPOINT_SDR = config.endpointSDR;

const onError = (err, onClose = () => {}) => Observable.throw({ ...err.xhr.response, onClose });

const onSuccess = (response) => {
  console.log('Ajax response', response);
  return response;
};

export const ajaxRequest = (method, uri, data = null, options = {}) => {
  const { headers, onCloseError, responseType, retries, timeout } = {
    ...initialOptions,
    ...options,
  };

  return ajax({
    method,
    url: uri,
    body: !data instanceof FormData && isEmpty(data) ? null : data,
    timeout,
    responseType,
    dataType: 'json',
    crossDomain: false,
    headers: {
      ...headers,
    },
  })
    .catch((err) => Observable.throw(err))
    .retryWhen((err) =>
      err
        .flatMap((error: any) => {
          if ([401].indexOf(error.status) !== -1) {
            store.dispatch(logout.start());
            store.dispatch(getSessionToken.start());
          }

          if ([403].indexOf(error.status) !== -1) {
            console.log('error', error);

            return Observable.throw({
              xhr: {
                response: {
                  message: 'Usuário ou Senha Incorretas.',
                  resource: {},
                  status: 403,
                },
              },
            });
          }

          if ([400, 401, 404, 500].indexOf(error.status) !== -1) {
            return Observable.throw(error);
          }

          return Observable.of(error.status).delay(1000);
        })
        .take(retries)
        .concat(
          Observable.throw({
            xhr: {
              response: {
                messages: [{ text: 'Erro desconhecido.' }],
                resource: {},
              },
            },
          }),
        ),
    )
    .catch((err) => onError(err, onCloseError));
};

export default class AjaxRequest {
  constructor() {
    this.options = [
      {
        name: 'authentication',
        uri: `${ENDPOINT}/logintoken`,
      },
      {
        name: 'tudo',
        uri: ENDPOINT,
      },
      {
        name: 'sdr_authentication',
        uri: `${ENDPOINT_SDR}/Home/AuthenticateApi`,
      },
      {
        name: 'sdr',
        uri: ENDPOINT_SDR,
      },
    ];
  }

  /**
   * @name add
   * @description Método para adicionar configurações para um novo Endpoint.
   * @param  {string} name Alias para ser envocado.
   * @param  {any} options Configurações a serem sobrescritas das regras padrões (timeout, etc).
   */
  add(name, options) {
    this.options.push({ name, ...options });
  }

  /**
   * @name open
   * @description Método para uso do ajax com retorno do tipo observable.
   * @param  {string} method Verbo à ser utilizado na conexão (GET|POST|UPDATE|DELETE).
   * @param  {string} uri Endereço relativo para a ação idealizada.
   * @param  {any} data=null Corpo ou parametros de requisição para a solicitação.
   * @param  {any} options={} Configurações a serem sobrescritas das regras padrões (timeout, etc).
   */
  open(method, uri = 'tudo', data = null, options = {}) {
    return (config: string) => {
      const endpoint = this.options.find(({ name }) => name === config);
      return Observable.of({})
        .map(() => localStorage.getItem('authenticationToken'))
        .mergeMap((token: string) => {
          const headers = options.headers || {};

          if (!options.noContentType && !headers['Content-Type']) {
            headers['Content-Type'] = 'application/json';
          }
          headers['Access-Control-Allow-Origin'] = '*';

          if (typeof token === 'string') {
            headers.Authorization = `Bearer ${token}`;
          }
          return ajaxRequest(
            method,
            `${endpoint.uri}${uri}`,
            data,
            Object.assign(options, { headers }),
          ).map(onSuccess);
        });
    };
  }
}
