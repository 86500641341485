import React from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { remoteValues } from '~/common/utils/firebase/remoteConfig';
import { getPersonDetail } from '~/store/people/actions';
import { UserCircle } from '~/ui/assets/icons';

import { Shimmer } from '~/ui/components';
import AgreementPasswordActions from '~/components/AgreementPasswordActions/AgreementPasswordActions';
import { ActionsTitle, MainButton, MenuItem, ShimmerContainer } from './OtherOptions.styled';
import { ResetPersonButton } from './components/ResetPersonButton';
import { DisapproveCustomer } from './components/DisapproveCustomer';

type OtherOptionsProps = {
  loading?: boolean;
  className?: string;
  onClick?: () => void;
};

export function OtherOptions({ loading, className, onClick }: OtherOptionsProps) {
  const personDetailControl = useAwaitControl(getPersonDetail);
  const personDetails = personDetailControl.result();
  const { enableResetAccount } = remoteValues;
  const { id, agreementName } = personDetails;

  if (loading) {
    return (
      <ShimmerContainer>
        <Shimmer width={220} height={30} mt={25} mb={10} ml={32} />
        <Shimmer width={200} height={25} ml={32} mb={8} />
      </ShimmerContainer>
    );
  }

  return (
    <div className={className}>
      <MainButton onClick={() => {}}>
        <UserCircle />
        <ActionsTitle>Outras opções</ActionsTitle>
      </MainButton>

      {agreementName === 'INSS' && (
        <MenuItem>
          <AgreementPasswordActions onClick={onClick} id={id} />
        </MenuItem>
      )}

      <DisapproveCustomer onClick={onClick} />

      {!personDetails?.disabled && enableResetAccount && <ResetPersonButton onClick={onClick} />}
    </div>
  );
}
