// @flow

import React from 'react';
import solveProofOfAddress, {
  SOLVE_PROOF_OF_ADDRESS_SUCCESS,
} from '~/store/solveProofOfAddress/action';
import ProofOfResidence from './ProofOfResidence';
import updatePendencies from '~/screens/ContractDetails/Panels/Pendencies/components/actions/updatePendencies';

type ProofOfResidenceContainerProps = {
  id: number,
  pendencyType: string,
};

const ProofOfResidenceContainer = (props: ProofOfResidenceContainerProps) => {
  const onPressSend = (file, type) => {
    props.dispatch(solveProofOfAddress({ id: props.id, file, type }));
  };

  return <ProofOfResidence onSendFile={onPressSend} {...props} />;
};

export default updatePendencies(
  'solveProofOfAddress',
  SOLVE_PROOF_OF_ADDRESS_SUCCESS,
)(ProofOfResidenceContainer);
