import React, { ReactElement, useRef, useState } from 'react';
import { domToClipboard, domToDownload } from '~/common/domToFile';

import { Button, Flex } from '~/ui/components';
import { Container, ImageContainer } from './DownloadNodeModal.styled';

export type DownloadNodeModalProps = {
  children: ReactElement;
  fileName: string;
};

export function DownloadNodeModal({ children, fileName }: DownloadNodeModalProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    const success = await domToClipboard(containerRef?.current);
    setIsCopied(success);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Container loading={isCopied}>
      <ImageContainer ref={containerRef}>{children}</ImageContainer>

      <Flex gap={8} direction="column" width="100%" ph={32} pb={16}>
        <Button
          fullWidth
          rounded
          customColor={isCopied ? 'positive.primary' : 'black'}
          icon={isCopied ? 'Check' : 'Paste'}
          onClick={handleCopy}
        >
          {isCopied ? 'Imagem copiada!' : 'Copiar imagem'}
        </Button>

        <Button
          rounded
          fullWidth
          color="black"
          variant="outline"
          icon="Download"
          onClick={() => domToDownload(containerRef?.current, fileName)}
        >
          Baixar imagem
        </Button>
      </Flex>
    </Container>
  );
}
