import React from 'react';
import {
  InfoContainer,
  Section,
  SectionTitle,
} from '~/screens/ContractDetails/Panels/Returns/Returns.styled';
import moment from 'moment';
import { InfoItem } from '~/components';
import { ReturnsTable } from '~/screens/ContractDetails/Panels/Returns/ParatiReturns/Account/ReturnsTable';
import { ParatiAccount, ParatiAccountReturn } from '~/typings/entities/Proposals';

export type AccountDetailsProps = {
  account: ParatiAccount;
  accountReturns: ParatiAccountReturn[];
  contractId: string;
};

export function AccountDetails({ contractId, account, accountReturns }: AccountDetailsProps) {
  return (
    <>
      <Section>
        <SectionTitle>Detalhes</SectionTitle>

        <InfoContainer>
          <InfoItem title="Código" value={account?.accountId} />
          <InfoItem title="status" value={account?.status} />
          <InfoItem
            title="Data"
            value={moment(account?.creationDate).format('DD/MM/YYYY [às] HH:mm:ss')}
          />
        </InfoContainer>
      </Section>

      <Section>
        <SectionTitle>Retornos</SectionTitle>
        <ReturnsTable contractId={contractId} accountReturns={accountReturns} />
      </Section>
    </>
  );
}
