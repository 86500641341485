import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import getBanks from '~/store/fetchFinancialInstitutionsForDeposit/action';
import sendContract from '~/store/sendContract/action';

import Immutable from 'immutable';
import { getContractDetails } from '~/store/contracts/actions';
import getContractFiles, {
  GET_CONTRACT_FILES_FAILURE,
  GET_CONTRACT_FILES_SUCCESS,
} from '~/store/getContractFiles/action';
import { message } from '~/common';
import updateConsigneeStatus from '~/store/updateConsigneeStatus/action';
import adjustContract, { ADJUST_CONTRACT_SUCCESS } from '~/store/adjustContract/action';
import { ContractInfo } from '~/typings/entities/ContractInfo';
import LoanTypes from '~/constants/LoanTypes';
import SendContractView from './SendContractView';

class SendContractContainer extends Component {
  componentDidMount() {
    const { id: contractId } = this.props.match.params;

    if (contractId) {
      this.props.dispatch(getBanks({ contractId }));
    }
  }

  componentDidUpdate(prevProps) {
    const { adjustContract, dispatch, match, sendContract, files } = this.props;
    if (Immutable.is(prevProps.files, files) === false) {
      switch (files.getIn(['type'])) {
        case GET_CONTRACT_FILES_FAILURE: {
          message.error('Falha ao Carregar Images do Documento.');
          return true;
        }
        default:
          return false;
      }
    }

    if (Immutable.is(prevProps.sendContract, sendContract) === false) {
      const sendContractPayload = sendContract.getIn(['payload']);

      if (sendContract.getIn(['type']) === 'SEND_CONTRACT_SUCCESS') {
        const { id } = match.params;

        if (sendContractPayload?.resource !== null) {
          dispatch(updateConsigneeStatus(id));
        }

        dispatch(getContractDetails.start({ id }));

        return true;
      }
      return false;
    }

    if (Immutable.is(prevProps.adjustContract, adjustContract) === false) {
      switch (adjustContract.getIn(['type'])) {
        case ADJUST_CONTRACT_SUCCESS: {
          const successMessage = adjustContract.getIn(['payload']);
          message.success(
            successMessage?.messages?.[0]?.text ?? 'Dados de contrato salvos com sucesso.',
          );

          const { id } = match.params;
          dispatch(updateConsigneeStatus(id));
          dispatch(getContractDetails.start({ id }));
          return true;
        }
        default:
          return false;
      }
    }
    return false;
  }

  onPressOpen = () => {
    const { id: contractId } = this.props.match.params;
    const { files, details } = this.props;
    const gotFiles = files && files.getIn(['type']) === GET_CONTRACT_FILES_SUCCESS;
    const filesPayload = files && files.getIn(['payload']);
    if (!gotFiles || !filesPayload[contractId]) {
      if (details.contractFiles) {
        this.props.dispatch(getContractFiles(details.contractFiles, contractId));
      }
    }
  };

  onSendValues = (valuesToSend) => {
    const { dispatch, match, details } = this.props;
    const usedFutureMargin = details.loanType === LoanTypes.FUTUREMARGIN;
    const { id } = match.params;

    const action = usedFutureMargin ? adjustContract : sendContract;

    dispatch(action(id, valuesToSend));
  };

  render() {
    const { id } = this.props.match.params;
    const { detailsLoading, detailsError, details, kycValidation } = this.props;
    const files = this.props.files.getIn(['payload']);
    const loadingSend = this.props.sendContract.getIn(['isLoading']);
    const loadingAdjustContract = this.props.adjustContract.getIn(['isLoading']);
    const usedFutureMargin = details.loanType === LoanTypes.FUTUREMARGIN;

    return (
      <SendContractView
        details={new ContractInfo(details)}
        files={files[id]}
        onPressOpen={this.onPressOpen}
        sendContract={this.props.sendContract}
        banks={this.props.banks}
        contractError={detailsError}
        onSubmit={this.onSendValues}
        isLoading={detailsLoading || loadingSend || loadingAdjustContract}
        usedFutureMargin={usedFutureMargin}
        id={id}
        kycValidation={kycValidation}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    sendContract: state.getIn(['sendContract']),
    adjustContract: state.getIn(['adjustContract']),
    details: getContractDetails.getResult()(state),
    detailsLoading: getContractDetails.isRunning()(state),
    detailsError: getContractDetails.hasFailure()(state),
    files: state.getIn(['getContractFiles']),
    banks: state.getIn(['fetchFinancialInstitutionsForDeposit', 'payload']),
  };
}

export default compose(withRouter, connect(mapStateToProps))(SendContractContainer);
