import { Button } from 'antd';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)`
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  ${({ type, ghost, theme }) => {
    if (ghost) {
      switch (type) {
        case 'primary':
          return css`
            color: ${({ theme }) => theme.primaryColor};
            background-color: ${({ theme }) => theme.primaryColorLightest};
            border-color: ${({ theme }) => theme.primaryColor};
          `;
        case 'danger':
          return css`
            color: ${({ theme }) => theme.dangerColor};
            background-color: ${({ theme }) => theme.dangerColorLightest};
            border-color: ${({ theme }) => theme.dangerColor};
          `;
        case 'success':
          return css`
            color: ${({ theme }) => theme.successColor};
            background-color: ${({ theme }) => theme.successColorLightest};
            border-color: ${({ theme }) => theme.successColor};
          `;
        case 'default':
        default:
          return css`
            color: ${({ theme }) => theme.grayDark};
            background-color: transparent;
            border-color: ${({ theme }) => theme.grayDark};
          `;
      }
    }
    switch (type) {
      case 'success':
        return css`
          background-color: ${({ theme }) => theme.successColor};
          border-color: ${({ theme }) => theme.successColor};
          color: white;

          &:focus {
            background-color: ${({ theme }) => theme.successColor};
            border-color: ${({ theme }) => theme.successColor};
            color: white;
          }

          &:hover {
            background-color: ${({ theme }) => theme.successColor};
            border-color: ${({ theme }) => theme.successColor};
            color: white;
          }
        `;
      case 'default':
      default:
        return css``;
    }
  }}
  ${props =>
    typeof props.iconLeft !== 'string' &&
    css`
      svg {
        width: 18px !important;
        height: 18px !important;
        margin-bottom: -2px;
        fill: ${({ type, ghost, theme }) => {
          if (ghost) {
            switch (type) {
              case 'primary':
                return theme.primaryColor + ' !important';
              case 'danger':
                return theme.dangerColor + ' !important';
              case 'success':
                return theme.successColor + ' !important';
              case 'default':
              default:
                return theme.gray + ' !important';
            }
          }
        }};
      }
    `}
`;

export default StyledButton;
