import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';
import { Divider } from 'antd';
import { useTheme } from 'styled-components';

import {
  createTicket,
  getReasons,
  getServiceChannels,
  getTicketJourneys,
  getTicketProducts,
  getTicketResolutionReasons,
  getTicketSubResolutionReasons,
  getTicketStatus,
  getTicketTeams,
  hasLinkedTicket,
  updateTicket,
} from '~/store/tickets/actions';

import { Calendar } from '~/ui/assets/icons';
import { Alert, Button, Flex, RenderRichText, withStepperProps } from '~/ui/components';
import { ChildrenDrawer, ModalDrawer, TicketData } from '~/components';

import { uppercaseFirst } from '~/common';
import {
  findNamesByIds,
  createSelectValues,
  findLastNameById,
  findNameById,
} from '~/common/selectValues';
import { DrawerText } from '~/components/ModalDrawer/ModalDrawer.styled';
import { TicketOrigin } from '~/typings/enums/Ticket/TicketOrigin';
import { themeToggleValue } from '~/common/utils/theme';
import { useDrawer } from '~/hooks/useDrawer';
import {
  Container,
  Title,
  Text,
  TagText,
  DateTimeViewer,
  ContractList,
  InfoContainer,
} from './TicketResume.styled';

type TicketResumeProps = {
  data?: TicketData;
  buttonText?: string;
  isUpdating?: boolean;
};

export const AWAITING_RESOLUTION = 63;

export const TicketResume = withStepperProps<TicketResumeProps>(
  ({ stepper, data, buttonText, isUpdating = false }) => {
    const { setConfig, closeDrawer, setLoading } = useDrawer();
    const theme = useTheme();

    const createTicketControl = useAwaitControl(createTicket);
    const getReasonsControl = useAwaitControl(getReasons);
    const getServiceChannelsControl = useAwaitControl(getServiceChannels);
    const getTeamsControl = useAwaitControl(getTicketTeams);
    const hasLinkedTickedControl = useAwaitControl(hasLinkedTicket);
    const updateTicketControl = useAwaitControl(updateTicket);
    const getJourneysControl = useAwaitControl(getTicketJourneys);
    const getProductsControl = useAwaitControl(getTicketProducts);
    const getTicketResolutionReasonsControl = useAwaitControl(getTicketResolutionReasons);
    const getTicketResolutionSubReasonsControl = useAwaitControl(getTicketSubResolutionReasons);
    const getTicketStatusControl = useAwaitControl(getTicketStatus);

    const channels = getServiceChannelsControl.result();
    const reasons = getReasonsControl.result();
    const teams = getTeamsControl.result();
    const ticketLink = hasLinkedTickedControl.result();
    const journeys = getJourneysControl.result();
    const products = getProductsControl.result();
    const resolutionReasons = getTicketResolutionReasonsControl.result();
    const resolutionSubReasons = getTicketResolutionSubReasonsControl.result();
    const status = getTicketStatusControl.result();

    const createTicketLoading = createTicketControl.isRunning();
    const checkTicketLinkLoading = hasLinkedTickedControl.isRunning();
    const createTicketSuccess = createTicketControl.isSuccessful();
    const ticketLinkSuccess = hasLinkedTickedControl.isSuccessful();

    const isUpdatingTicket = updateTicketControl.isRunning();
    const ticketSuccessfullyUpdated = updateTicketControl.isSuccessful();

    const checkLinkedTicked = () => {
      if (isUpdating) {
        const ticketData = {
          ticketStatusId: data?.ticket.ticketStatusId,
          resolvingAreaId: null,
          priorityTicket: data?.ticket.priorityTicket ?? 'WAITING_DEFINITION',
          ticketReasonId: data?.ticket.ticketReasonId,
          serviceChannelId: data?.ticket.serviceChannelId,
          userCreated: data?.ticket.userCreated,
          userCreatedId: data?.ticket.userCreatedId,
          teamId: data?.ticket.teamId,
          contracts: data?.ticket.contracts,
          journeyId: data?.ticket.journeyId,
          contactOrigin: data?.ticket.contactOrigin,
          personId: data?.ticket.personId,
          product: data?.ticket.product,
          description: data?.ticket.description,
          subJustificationResolutionId: data?.ticket.subJustificationResolutionId,
          justificationResolutionId: data?.ticket.justificationResolutionId,
        };

        updateTicketControl.start({
          ticketId: data?.ticket?.id,
          data: ticketData,
          personId: data?.ticket?.personId,
        });
      }
      if (!isUpdating) {
        hasLinkedTickedControl.start(data.ticket);
      }
    };

    const handleCreateTicket = () => {
      createTicketControl.start(data);
    };

    const renderNames = useCallback((list, id: number) => {
      const ids = createSelectValues(list, id);
      const names = findNamesByIds(list, ids);
      return names.join(' - ');
    }, []);

    const justificationResolution = useMemo(
      () =>
        resolutionReasons?.find((reason) => reason.id === data?.ticket?.justificationResolutionId)
          ?.justification,
      [resolutionReasons, data?.ticket?.justificationResolutionId],
    );

    const subJustificationResolution = useMemo(
      () =>
        resolutionSubReasons?.find(
          (reason) => reason.id === data?.ticket?.subJustificationResolutionId,
        )?.name,
      [resolutionSubReasons, data?.ticket?.subJustificationResolutionId],
    );

    const getAlertMessage = useMemo(
      () =>
        data?.ticket?.ticketStatusId === AWAITING_RESOLUTION ? (
          <>
            Seu ticket será encaminhado para a área <b>responsável</b>
          </>
        ) : (
          <>
            Esse ticket é considerado como um <b>atendimento concluído</b>, servindo para
            documentação do contato.
          </>
        ),
      [data?.ticket?.ticketStatusId],
    );

    useEffect(() => {
      setLoading('ticket-modal', createTicketLoading || checkTicketLinkLoading || isUpdatingTicket);
    }, [createTicketLoading, checkTicketLinkLoading, isUpdatingTicket]);

    useEffect(() => {
      if (createTicketSuccess || ticketSuccessfullyUpdated) {
        closeDrawer('ticket-modal');
      }
    }, [createTicketSuccess, ticketSuccessfullyUpdated]);

    useEffect(() => {
      if (ticketLinkSuccess && !ticketLink?.hasLink) {
        handleCreateTicket();
      }
    }, [ticketLink, ticketLinkSuccess]);

    useEffect(() => {
      setConfig('ticket-modal', {
        title: 'Revise suas informações',
        onBackClick: stepper.prevStep,
        backButton: true,
        maskClosable: false,
      });

      return () => {
        createTicketControl.clear();
        hasLinkedTickedControl.clear();
      };
    }, []);

    return (
      <>
        <Container>
          {isUpdating && (
            <Text>
              Verifique se as informações abaixo estão corretas para prosseguir com a atualização do
              ticket ou volte para revisa-lás.
            </Text>
          )}

          {!isUpdating && <Alert label={getAlertMessage} status="warning" />}

          {!!data?.ticket?.ticketScheduleDate && (
            <DateTimeViewer>
              <Calendar />
              {uppercaseFirst(moment(data?.ticket?.ticketScheduleDate).format('dddd, D [de] MMMM'))}
              <Divider type="vertical" />
              <span>{moment(data?.ticket?.ticketScheduleDate).format('HH:mm')}</span>
            </DateTimeViewer>
          )}

          <InfoContainer>
            <div>
              <Title>Agente</Title>
              <Text>{data?.ticket?.userCreated}</Text>
            </div>

            <div>
              <Title>Produto</Title>
              <TagText>
                {findNameById(products, data?.ticket?.product, {
                  value: 'name',
                  label: 'description',
                })}
              </TagText>
            </div>

            <div>
              <Title>Etapa da jornada</Title>
              <Text>{renderNames(journeys, data?.ticket?.journeyId)}</Text>
            </div>

            <div>
              <Title>Motivo da abertura</Title>
              <Text>{findLastNameById(reasons, data?.ticket?.ticketReasonId)}</Text>
            </div>

            <div>
              <Title>Canal</Title>
              <Text>{renderNames(channels, data?.ticket?.serviceChannelId)}</Text>
            </div>

            <div>
              <Title>Origem do contato</Title>
              <Text>{TicketOrigin[data?.ticket?.contactOrigin]}</Text>
            </div>

            <div>
              <Title>Time</Title>
              <Text>{renderNames(teams, data?.ticket?.teamId)}</Text>
            </div>

            <div>
              <Title>Status de resolução</Title>
              <Text>{renderNames(status, data?.ticket?.ticketStatusId)}</Text>
            </div>

            {!!data?.ticket?.contracts?.length && (
              <div>
                <Title>Contratos</Title>
                <ContractList>
                  {data?.ticket?.contracts.map(({ id }) => (
                    <TagText key={id}>{id}</TagText>
                  ))}
                </ContractList>
              </div>
            )}

            {!!data?.ticket?.justificationResolutionId && (
              <div>
                <Title>Justificativa da resolução</Title>
                <Text>{justificationResolution}</Text>
              </div>
            )}

            {!!data?.ticket?.subJustificationResolutionId && (
              <div>
                <Title>Sub-justificativa da resolução</Title>
                <Text>{subJustificationResolution}</Text>
              </div>
            )}

            {!!data?.ticket?.protocolTwilio && (
              <div>
                <Title>Protocolo Twilio</Title>
                <Text>{data?.ticket?.protocolTwilio}</Text>
              </div>
            )}
          </InfoContainer>

          <div>
            <Title>Descrição</Title>
            <Text>
              <RenderRichText bordered html={data?.ticket?.description} />
            </Text>
          </div>

          <ContractList>
            <Button
              variant="outline"
              customColor={themeToggleValue(theme, 'black', {
                dark: theme.colors.element.primary,
              })}
              rounded
              fullWidth
              onClick={() => closeDrawer('ticket-modal')}
              loading={isUpdatingTicket}
            >
              Cancelar ticket
            </Button>
            <Button
              customColor={themeToggleValue(theme, 'black', {
                dark: theme.colors.brand.secondaryAlt,
              })}
              rounded
              fullWidth
              onClick={checkLinkedTicked}
              loading={isUpdatingTicket}
            >
              {isUpdating ? 'Atualizar ticket' : buttonText || 'Abrir ticket'}
            </Button>
          </ContractList>
        </Container>

        <ChildrenDrawer
          title="Ticket de atrelamento"
          visible={!!ticketLink?.hasLink}
          container=".ant-drawer-body"
        >
          <Flex gap={8} ph={16} pv={16} direction="column">
            <DrawerText>
              Um ticket idêntico já foi criado. Ao clicar em &quot;Confirmar&quot;, você estará
              vinculando este ticket ao já existente.
            </DrawerText>

            <Button color="black" fullWidth rounded onClick={handleCreateTicket}>
              Confirmar
            </Button>

            <Button
              color="black"
              variant="outline"
              rounded
              fullWidth
              onClick={() => hasLinkedTickedControl.clear()}
            >
              Cancelar abertura
            </Button>
          </Flex>
        </ChildrenDrawer>
      </>
    );
  },
);
