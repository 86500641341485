// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { TagsContainer } from '~/components/Tags/Tags.styles';
import Tag from '~/components/Tag';
import { listTags, removeTag, deleteTag } from '~/store/tags/actions';
import { listTagTypes } from '~/store/tagTypes/actions';

type TagsProps = {
  externalId: number,
  type: string,
  allowsToAdd?: boolean,
  waitExternalId?: boolean,
};

const admin = 'Administrador';

export default function Tags({
  externalId,
  type,
  allowsToAdd = true,
  waitExternalId = false,
}: TagsProps) {
  const [items, setItems] = useState([]);
  const data = { externalId, type };

  const actionId = `${type}_${externalId}`;
  const [listTagsControl, listTagTypesControl, removeTagControl] = useAwaitControl([
    listTags,
    listTagTypes,
    removeTag,
  ]);
  const deleteTagControl = useAwaitControl(deleteTag);
  const deleteTagSuccessfully = deleteTagControl.isSuccessful();

  const tags: any[] = listTagsControl.result(actionId);
  const listTagsLoading = listTagsControl.isRunning(actionId);

  useEffect(() => {
    if ((waitExternalId && externalId) || !waitExternalId) {
      listTagsControl.start(data, { actionId });
      listTagTypesControl.start(data, { actionId: type });
    }

    return () => listTagsControl.clear({ actionId });
  }, [waitExternalId, externalId, deleteTagSuccessfully]);

  useEffect(() => {
    if (!listTagsLoading) {
      const allTags =
        tags?.sort((a, b) => {
          if (a.user === admin && b.user !== admin) {
            return -1;
          }
          return a.user !== admin && b.user === admin ? 1 : 0;
        }) || [];
      if (allowsToAdd) {
        setItems([...allTags, { tagType: { name: 'Adicionar tag', addType: true } }]);
      } else {
        setItems(allTags);
      }
    }
  }, [tags, allowsToAdd, listTagsLoading]);

  const removeItem = useCallback(
    (id) => {
      listTagsControl.success(
        (tags || []).filter((tag) => tag.id !== id),
        {
          actionId,
        },
      );
      removeTagControl.start({ id, data });
    },
    [tags],
  );

  return (
    <TagsContainer>
      {(items || []).map((item) => (
        <Tag
          onRemove={removeItem}
          {...item.tagType}
          {...item}
          tags={items.filter((i) => i.tagType && !i.tagType.addType).map((i) => i.tagType)}
          type={type}
        />
      ))}
    </TagsContainer>
  );
}
