import React, { useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Tag, Typography } from '~/ui/components';
import { Loading } from '~/ui/assets/icons';
import { getContractInsurance } from '~/store/contracts/actions';
import { InfoItem } from '~/components';
import { useDrawer } from '~/hooks/useDrawer';
import moment from 'moment';
import { Money } from '~/common';
import {
  Container,
  CoverageContent,
  InfoContainer,
  Link,
  LoadingContainer,
  SectionTitle,
  TagsRow,
} from './InsuranceDetails.styled';

const Palette = {
  Cancelado: 'error',
  'Cancelamento em andamento': 'error',
  'Em análise': 'warning',
  Ativo: 'success',
  Encerrado: 'gray',
};

interface Props {
  id: number;
}

export function InsuranceDetails({ id }: Props) {
  const { closeAllDrawers } = useDrawer();

  const getContractInsuranceControl = useAwaitControl(getContractInsurance);

  const isInsuranceLoading = getContractInsuranceControl.isRunning();

  const insurance = getContractInsuranceControl.result();

  const formatDate = (date: string, format = 'DD/MM/YY') => moment(date).format(format);

  const handleLinkCLick = useCallback(() => {
    closeAllDrawers();
  }, []);

  const isCanceled = ['Cancelado', 'Cancelamento em andamento'];

  useEffect(() => {
    if (id) {
      getContractInsuranceControl.start({ id });
    }
  }, [id]);

  if (isInsuranceLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <TagsRow>
        <Tag small rounded colorPalette={Palette[insurance?.validityStatus]}>
          {insurance?.validityStatus}
        </Tag>
      </TagsRow>

      {isCanceled.includes(insurance.validityStatus) && (
        <>
          <SectionTitle>Informações do cancelamento</SectionTitle>

          <CoverageContent>
            <Typography type="caption" weight={600}>
              Data do cancelamento
            </Typography>
            <Typography type="caption" weight={400}>
              {insurance?.cancellationInfo?.cancellationDateRequested
                ? formatDate(insurance?.cancellationInfo?.cancellationDateRequested)
                : '-'}
            </Typography>
          </CoverageContent>

          <CoverageContent hasBottomMargin>
            <Typography type="caption" weight={600}>
              Motivo do cancelamento
            </Typography>
            <Typography type="caption" weight={400}>
              {insurance?.cancellationInfo?.cancelReasonDescription || '-'}
            </Typography>
          </CoverageContent>
        </>
      )}

      <SectionTitle>Detalhes do Contrato</SectionTitle>

      <InfoContainer>
        <InfoItem title="Tipo" value={insurance?.typeDescription || '-'} />

        <InfoItem title="Vigência" value={`${insurance?.validityInMonths} meses`} />

        <InfoItem
          title="Contrato atrelado"
          value={
            <Link onClick={handleLinkCLick} to={`/backoffice/contracts/${insurance?.contractId}`}>
              #{insurance?.contractId}
            </Link>
          }
        />

        <InfoItem
          title="Início da contratação"
          value={insurance?.initialDate ? formatDate(insurance?.initialDate) : '-'}
        />

        <InfoItem
          title="Fim da contratação"
          value={insurance?.expirationDate ? formatDate(insurance?.expirationDate) : '-'}
        />

        <InfoItem title="Valor do Seguro" value={Money(insurance?.value) || '-'} />
      </InfoContainer>

      <SectionTitle>Cobertura</SectionTitle>

      <CoverageContent>
        <Typography type="caption" weight={600}>
          Morte acidental
        </Typography>
        <Typography type="caption" weight={400}>
          {Money(
            insurance?.insurancePolicies?.find(
              (policie) => policie.description === 'Morte por acidente',
            ).value,
          )}
        </Typography>
      </CoverageContent>

      <CoverageContent>
        <Typography type="caption" weight={600}>
          Invalidez Permanente Total ou Parcial por Acidente
        </Typography>
        <Typography type="caption" weight={400}>
          {`Até ${Money(
            insurance?.insurancePolicies?.find(
              (policie) =>
                policie.description === 'Invalidez Permanente Total ou Parcial por Acidente',
            ).value,
          )}`}
        </Typography>
      </CoverageContent>

      <CoverageContent>
        <Typography type="caption" weight={600}>
          Perda de Renda
        </Typography>
        <Typography type="caption" weight={400}>
          {`Até ${Money(
            insurance?.insurancePolicies?.find(
              (policie) => policie.description === 'Perda de Renda',
            ).value,
          )}`}
        </Typography>
      </CoverageContent>
    </Container>
  );
}
