import React, { useRef, useState } from 'react';
import { Button, Flex, FormInput, Tag } from '~/ui/components';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Filter as FilterIcon } from '~/ui/assets/icons';
import { FilterButton, FilterForm, FilterPopover } from './Filters.styled';

export type FilterProps = {
  onSubmit?: (values: any) => void;
  filters: FormInput[];
  width?: number;
};

export function Filters({ onSubmit, filters, width }: FilterProps) {
  const form = useRef<WrappedFormUtils>();

  const [visible, setVisible] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const handleOnSubmit = (values: any) => {
    const count = Object.keys(values).filter((el) =>
      Array.isArray(values[el]) ? !!values[el].length : !!values[el],
    ).length;
    onSubmit?.(values);
    setFilterCount(count);
    setVisible(false);
  };

  const handleOnReset = () => {
    form.current?.resetFields();
    setFilterCount(0);
  };

  return (
    <FilterPopover
      visible={visible}
      onVisibleChange={setVisible}
      width={width}
      content={
        <FilterForm ref={form} inputs={filters} onSubmit={handleOnSubmit}>
          <Flex gap={16} className="button-container">
            <Button
              type="button"
              rounded
              className="reset-button"
              customColor="black"
              variant="outline"
              onClick={handleOnReset}
            >
              Apagar filtros
            </Button>

            <Button rounded className="submit-button" customColor="black">
              Filtrar
            </Button>
          </Flex>
        </FilterForm>
      }
    >
      <FilterButton>
        {filterCount ? (
          <Tag small rounded bgColor="brand.primary" textColor="brand.overPrimary">
            {filterCount}
          </Tag>
        ) : (
          <FilterIcon className="filter-icon" />
        )}
        Filtros
      </FilterButton>
    </FilterPopover>
  );
}
