/* @flow */

import { checkQueueContract } from '~/store/contracts/actions';

export const REQUEST_REFUSAL_CONTRACT_REQUEST = 'REQUEST_REFUSAL_CONTRACT_REQUEST';
export const REQUEST_REFUSAL_CONTRACT_SUCCESS = 'REQUEST_REFUSAL_CONTRACT_SUCCESS';
export const REQUEST_REFUSAL_CONTRACT_FAILURE = 'REQUEST_REFUSAL_CONTRACT_FAILURE';

export function success(payload) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_REFUSAL_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_REFUSAL_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

export default function refuseContract(
  contractId,
  password,
  contractRefusalReasonId,
  openRefusalReasonDescription,
) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_REFUSAL_CONTRACT_REQUEST,
      payload: {
        contractId,
        password,
        contractRefusalReasonId,
        openRefusalReasonDescription,
      },
    });
  };
}
