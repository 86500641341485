const VALIDATION_STATUS = {
  PROCESSING: 'PROCESSANDO',
  ANALYSIS: 'ANALISANDO',
  VALID: 'VÁLIDO',
  INVALID: 'INVALIDO',
  BYPASS: 'UNICO DESATIVADO',
  EXPIREDBYTRIES: 'EXPIRADO POR TENTATIVAS',
  INCONCLUSIVE: 'INCONCLUSIVO',
  NOTVALIDATED: 'NÃO VALIDADO',
  ERROR: 'Com erro',
};

function ValidationStatusUnico(props: any) {
  if (props) {
    return VALIDATION_STATUS[props.value] || VALIDATION_STATUS[props] || null;
  }
  return null;
}

export default ValidationStatusUnico;
