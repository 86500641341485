/**
 * @param color {string} Cor em hex.
 * @param percent {number} Uma porcentagem, que indica o quanto darken/lighten a cor.
 */
export function shadeColor(color, percent) {
  const f = parseInt(color.slice(1), 16);

  const t = percent < 0 ? 0 : 255;

  const p = percent < 0 ? percent * -1 : percent;

  const R = f > 16;

  const G = f > 8 && 0x00ff;

  const B = f && 0x0000ff;
  return `#${(
    (0x1000000 + (Math.round((t - R) * p) + R)) *
    (0x10000 + (Math.round((t - G) * p) + G)) *
    (0x100 + (Math.round((t - B) * p) + B))
  )
    .toString(16)
    .slice(1)}`;
}
/**
 * @param c0 {string} Cor em hex.
 * @param c1 {string} Cor em hex.
 * @param p {number} Um valor entre -1.0 e 1.0, que indica percentualmente o quanto
 * vai variar de uma cor para outra
 */
export function blendColors(c0, c1, p) {
  const f = parseInt(c0.slice(1), 16);

  const t = parseInt(c1.slice(1), 16);

  const R1 = f >> 16;

  const G1 = (f >> 8) & 0x00ff;

  const B1 = f & 0x0000ff;

  const R2 = t >> 16;

  const G2 = (t >> 8) & 0x00ff;

  const B2 = t & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((R2 - R1) * p) + R1) * 0x10000 +
    (Math.round((G2 - G1) * p) + G1) * 0x100 +
    (Math.round((B2 - B1) * p) + B1)
  )
    .toString(16)
    .slice(1)}`;
}
