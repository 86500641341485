import styled from 'styled-components';

export const Container = styled.div``;

export const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TabPane = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  border: none;
  height: 60px;
  padding: 12px 32px;
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  background: ${({ theme }) => theme.colors.background.primary};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const TabBody = styled.div`
  background: ${({ theme }) => theme.colors.background.primary};
  height: calc(100vh - 510px);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.background.tertiary};
  }
`;
