import { Icon } from 'antd';
import styled from 'styled-components';
import { Typography } from '~/ui/components';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
`;

export const ButtonLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: theme.colors.gray[800],
}))``;

export const CancelPropoposalIcon = styled(Icon).attrs(({ theme }) => ({
  type: 'close-circle',
}))`
  margin: 0px 10px;
  color: ${({ theme }) => theme.colors.gray.main};
`;
