import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import screenRegister, { HelmetTitle } from '~/hoc/screenRegister';

import { Match } from '~/typings/components';
import {
  getContractsOfPerson,
  getFGTSOpportunity,
  getPersonDetail,
  getPersonImage,
  getPersonResetHistory,
  listPersonComments,
} from '~/store/people/actions';
import { listClixContracts } from '~/store/clix/actions';
import { getAllPendencies } from '~/store/pendencies/actions';
import {
  getReasons,
  getServiceChannels,
  getTicketsByUser,
  updateTicket,
} from '~/store/tickets/actions';
import { Content } from '~/ui/components';
import { ProfileWithActions, ResponsiveMainActions, PersonActions } from '~/components';
import { formatName } from '~/common';
import { OtherOptions } from '~/components/OtherOptions';
import { MobileActions } from '~/components/ProfileResume/MobileActions/MobileActions';
import { getEnrollmentByUserId } from '~/store/enrollments/actions';
import usePersistTicketParams from '~/hooks/usePersistTicketParams';
import { PersonInfo, AuxiliaryInfo } from './components';

interface PersonDetailsProps {
  match?: Match;
}

export function PersonDetails({ match }: PersonDetailsProps) {
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getFGTSOpportunityControl = useAwaitControl(getFGTSOpportunity);
  const getPersonImageControl = useAwaitControl(getPersonImage);
  const getContractsOfPersonControl = useAwaitControl(getContractsOfPerson);
  const listCommentsControl = useAwaitControl(listPersonComments);
  const getClixContractsControl = useAwaitControl(listClixContracts);
  const getPendenciesControl = useAwaitControl(getAllPendencies);
  const resetHistoryControl = useAwaitControl(getPersonResetHistory);
  const getTicketsByUserControl = useAwaitControl(getTicketsByUser);
  const getServiceChannelsControl = useAwaitControl(getServiceChannels);
  const getReasonsControl = useAwaitControl(getReasons);
  const updateTicketControl = useAwaitControl(updateTicket);
  const getEnrollmentByUserIdControl = useAwaitControl(getEnrollmentByUserId);

  const personId = match?.params?.personId;
  const personDetails = getPersonDetailsControl.result();
  const { contracts: clixContracts } = getClixContractsControl.result();
  const fgtsOpportunity = getFGTSOpportunityControl.result();
  const contracts = getContractsOfPersonControl.result();
  const pendencies = getPendenciesControl.result();
  const resets = resetHistoryControl.result();
  const tickets = getTicketsByUserControl.result();
  const enrollments = getEnrollmentByUserIdControl.result();

  const isPersonDetailsLoding = getPersonDetailsControl.isRunning();
  const isPersonClixDetailsLoading = getClixContractsControl.isRunning();
  const isFgtsOpportunityLoading = getFGTSOpportunityControl.isRunning();
  const isPersonImageLoading = getFGTSOpportunityControl.isRunning();
  const isResetHistoryLoading = resetHistoryControl.isRunning();
  const ticketSuccessfullyUpdated = updateTicketControl.isSuccessful();

  const avatarImage = getPersonImageControl.result('FACE_FRONT');

  const { persistTicketParams } = usePersistTicketParams(personId);

  const isLoading =
    isPersonDetailsLoding ||
    isPersonClixDetailsLoading ||
    isFgtsOpportunityLoading ||
    isPersonImageLoading ||
    isResetHistoryLoading;

  useEffect(() => {
    if (personDetails?.document) {
      getClixContractsControl.start({ document: personDetails.document });
    }
  }, [personDetails]);

  useEffect(() => {
    if (personId) {
      listCommentsControl.start({ personId });
      getFGTSOpportunityControl.start({ personId });
      getPersonDetailsControl.start({ id: personId });
      getPersonImageControl.start({ id: personId, type: 'FACE_FRONT' }, { actionId: 'FACE_FRONT' });
      getContractsOfPersonControl.start({ id: personId });
      getPendenciesControl.start({ personId });
      resetHistoryControl.start({ id: personId });
      getTicketsByUserControl.start({ personId });
      getServiceChannelsControl.start();
      getReasonsControl.start();
      getEnrollmentByUserIdControl.start({ personId });

      persistTicketParams();
    }

    return () => {
      getPersonDetailsControl.clear();
      getClixContractsControl.clear();
      getFGTSOpportunityControl.clear();
      listCommentsControl.clear();
      getContractsOfPersonControl.clear();
      getPendenciesControl.clear();
      resetHistoryControl.clear();
      getTicketsByUserControl.clear();
    };
  }, [personId]);

  useEffect(() => {
    getTicketsByUserControl.start({ personId });
    getEnrollmentByUserIdControl.start({ personId });
  }, [personId, ticketSuccessfullyUpdated]);

  return (
    <>
      <HelmetTitle title={formatName(personDetails?.name, 2) || 'Pessoa'} />

      <ProfileWithActions loading={isLoading} person={personDetails} avatarImage={avatarImage}>
        <PersonActions className="side-actions" loading={isLoading} />
        <OtherOptions className="other-options" loading={isLoading} />
      </ProfileWithActions>

      <Content>
        <ResponsiveMainActions
          person={personDetails}
          loading={isLoading}
          avatarImage={avatarImage}
          responsiveActions={<MobileActions />}
        />

        <PersonInfo
          loading={isLoading}
          person={personDetails}
          clixContracts={clixContracts}
          fgtsOpportunity={fgtsOpportunity}
        />

        <AuxiliaryInfo
          pendencies={pendencies}
          loading={isLoading}
          contracts={contracts}
          person={personDetails}
          resets={resets}
          tickets={tickets}
          enrollments={enrollments}
        />
      </Content>
    </>
  );
}

export default screenRegister({
  screenName: 'PersonDetailsNew',
  path: '/backoffice/people/:personId',
  customClass: 'no-padding',
  customLayout: true,
})(PersonDetails);
