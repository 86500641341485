import styled from 'styled-components';
import { Button as ButtonComponent, Icon } from 'antd';

export const OptionsButton = styled(ButtonComponent).attrs(() => ({
  ghost: true,
}))`
  border-color: transparent;
  color: ${({ theme }) => theme.accentColor};
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border-color: transparent;
    color: ${({ theme }) => theme.accentColor};
  }

  &:hover {
    border-color: transparent !important;
    color: ${({ theme }) => theme.accentColor} !important;
    background-color: ${({ theme }) => theme.grayExtraLight} !important;
  }
`;

export const EllipsisIcon = styled(Icon).attrs(() => ({
  type: 'ellipsis',
}))`
  height: 22px svg {
    font-size: 20px;
  }
`;

export const OptionLabel = styled.span`
  font-size: 14px;
`;
