// @flow

import React from 'react';
import solvePaycheck, {
  SOLVE_PAYCHECK_SUCCESS,
} from '~/store/solvePaycheck/action';
import Paycheck from './Paycheck';
import updatePendencies from '~/screens/ContractDetails/Panels/Pendencies/components/actions/updatePendencies';

type DocumentsContainerProps = {};

const PaycheckContainer = (props: DocumentsContainerProps) => {
  const onPressSend = file => {
    props.dispatch(solvePaycheck({ id: props.id, file }));
  };

  return <Paycheck onSendFile={onPressSend} {...props} />;
};

export default updatePendencies('solvePaycheck', SOLVE_PAYCHECK_SUCCESS)(
  PaycheckContainer,
);
