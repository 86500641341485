import React from 'react';
import moment from 'moment/moment';

import { InfoItem } from '~/components';

import { QiProposal } from '~/typings/entities/Proposals';
import { InfoContainer, Section, SectionTitle } from '../Returns.styled';
import { Actions } from './Actions';

export type DetailsDrawerProps = {
  proposal: QiProposal;
};

export function ReturnDetails({ proposal }: DetailsDrawerProps) {
  return (
    <>
      <Section>
        <SectionTitle>Detalhes</SectionTitle>

        <InfoContainer>
          <InfoItem title="Código" value={proposal?.externalId} />
          <InfoItem title="ID" value={proposal?.id} />
          <InfoItem title="Ação" value={proposal?.lastActionType} />
          <InfoItem title="Status" value={proposal?.lastStatus} />
          <InfoItem title="Baas" value="QiTech" />
          <InfoItem
            title="Data"
            value={moment(proposal?.creationDate).format('DD/MM/YYYY [às] HH:mm:ss')}
          />
          <InfoItem title="Tentativa" value={proposal?.attempt} />
          <InfoItem title="CCB Number" value={proposal?.ccbNumber} />
          <InfoItem title="Tipo" value={proposal?.type} />
        </InfoContainer>
      </Section>

      <Section>
        <SectionTitle>Retornos</SectionTitle>
        <Actions qiProposalId={proposal?.id} />
      </Section>
    </>
  );
}
