/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { LINK_CONTRACTOWNER_REQUEST, success, failure } from './action';

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(LINK_CONTRACTOWNER_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(id =>
      AjaxRequest.open(
        'post',
        `/admin/contractcustom/contracts/${id}/linkcontractowner`,
        {},
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
