import takeRequest from '~/store/takeRequest';
import * as EnrollmentsActions from './actions';

export function* enrollmentsSaga() {
  yield takeRequest(EnrollmentsActions.getEnrollmentByUserId);
  yield takeRequest(EnrollmentsActions.getHisconByEnrollment);
  yield takeRequest(EnrollmentsActions.requestHisconUpdate);
  yield takeRequest(EnrollmentsActions.uploadHisconByEnrollment);
  yield takeRequest(EnrollmentsActions.getHisconHistoryExtract);
  yield takeRequest(EnrollmentsActions.requestHisconDownload);
  yield takeRequest(EnrollmentsActions.getConcessionByEnrollment);
  yield takeRequest(EnrollmentsActions.uploadConcessionByEnrollment);
  yield takeRequest(EnrollmentsActions.getMedicalCertificateByEnrollment);
  yield takeRequest(EnrollmentsActions.uploadMedicalCertificateByEnrollment);
}
