import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pick } from 'underscore';
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withState,
  mapProps,
} from 'recompose';

import ConfirmRequest from '~/components/RequestAction/Button/ConfirmRequest';

import theme from '~/themes/aphroditeTheme/theme';

import { Form } from 'antd';

import message from '~/common/message';

import { getContractDetails } from '~/store/contracts/actions';

import cancelContractSignature, {
  CANCEL_CONTRACT_SIGNATURE_REQUEST,
  CANCEL_CONTRACT_SIGNATURE_SUCCESS,
  CANCEL_CONTRACT_SIGNATURE_FAILURE,
} from '~/store/cancelContractSignature/action';

import styles from './styles';

const { withStyles } = theme;

function View(props: { canConfirmRequest: any }) {
  return (
    <ConfirmRequest
      isRequested={props.canConfirmRequest}
      hideReason
      confirmButtonTitle="Invalidar Bojo Digital"
      titleText="Confirme com sua senha"
      {...props}
    />
  );
}

export function onPresscancelContractSignature({ dispatch, match }) {
  return (password) => {
    const { id } = match.params;
    dispatch(cancelContractSignature(id, password));
  };
}

export function mapStateToProps(state) {
  return {
    details: getContractDetails.getResult()(state),
    detailsSuccess: getContractDetails.isSuccessful()(state),
    detailsFailure: getContractDetails.hasFailure()(state),
    cancelContractSignature: state.getIn(['cancelContractSignature']),
  };
}

export function receiveChanges(
  prevProps,
  {
    setCanConfirmRequest,
    details,
    detailsSuccess,
    detailsFailure,
    cancelContractSignature,
    dispatch,
    match,
    isModalVisible,
    isLoading,
  },
) {
  if (
    Immutable.is(prevProps.detailsSuccess, detailsSuccess) === false
    || Immutable.is(prevProps.detailsFailure, detailsFailure) === false
  ) {
    if (detailsSuccess || detailsFailure) {
      const { loanStatus } = details;
      setCanConfirmRequest(loanStatus === 'CCBSIGNED');
      return false;
    }
  }

  if (
    Immutable.is(prevProps.cancelContractSignature, cancelContractSignature)
    === false
  ) {
    switch (cancelContractSignature.getIn(['type'])) {
      case CANCEL_CONTRACT_SIGNATURE_REQUEST: {
        isLoading(true);
        return true;
      }
      case CANCEL_CONTRACT_SIGNATURE_SUCCESS: {
        isLoading(false);
        isModalVisible(false);
        message.success('Cancelamento de assinatura realizado com sucesso.');

        const { id } = match.params;
        dispatch(getContractDetails(id));

        return true;
      }
      case CANCEL_CONTRACT_SIGNATURE_FAILURE: {
        const errorStatus = cancelContractSignature.getIn([
          'payload',
          'status',
        ]);
        const errorMessage = cancelContractSignature.getIn([
          'payload',
          'message',
        ]);

        isLoading(false);
        isModalVisible(false);

        if (errorStatus === 403) {
          message.error(errorMessage);
        } else {
          message.error('Falha ao confirmar cancelamento do contrato.');
        }

        return true;
      }
      default:
        return false;
    }
  }

  return {};
}

export function propagateStateChangeToProps({ isRequested }) {
  return {
    isRequested,
  };
}

const WrappedCancelContractSignature = Form.create()(View);

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  withState('loading', 'isLoading', false),
  withState('modalVisible', 'isModalVisible', false),
  withState('canConfirmRequest', 'setCanConfirmRequest', false),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onPressRequest: onPresscancelContractSignature,
  }),
  mapProps((props) => pick(
    props,
    ...[
      'reasons',
      'modalVisible',
      'isModalVisible',
      'loading',
      'isLoading',
      'canConfirmRequest',
      'onPressRequest',
      'onPressScundaryButton',
      'styles',
    ],
  )),
)(WrappedCancelContractSignature);
