import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';

export const getDeeplink = createAsyncAction('FORMALIZATION_GET_DEEPLINK', {
  initialValue: [],
  saveResult: true,
  context: {
    onRequestError: () => message.error('Erro ao criar deeplink'),
    errorParser: () => [],
    responseParser: ({ data }) => {
      const { content, ...pagination } = data;
      return { contracts: content, pagination };
    },
    request: ({ document, contractId }) =>
      telli.post('/admin/personcustom/people/opportunities/deeplink', {
        document,
        contractId,
        origin: 'DEEPLINK_BACKOFFICE',
      }),
  },
});
