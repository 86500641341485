import type { UserRole } from '~/typings/entities/user';
import { useAwaitControl } from 'react-redux-await-control';
import { getLoggedUser } from '~/store/user/actions';
import { useMemo } from 'react';

type UserRolesProps = {
  roles: UserRole[];
  isAdmin: boolean;
  isSupervisor: boolean;
  loading: boolean;
  hasRole: (role: UserRole) => boolean;
  ready: boolean;
};

export default function useUserRoles(): UserRolesProps {
  const getLoggedUserControl = useAwaitControl(getLoggedUser);
  const loading = getLoggedUserControl.isRunning();
  const roles = getLoggedUserControl.result()?.roles;

  const isAdmin = useMemo(() => roles?.includes('ADMINISTRATOR'), [roles]);

  const isSupervisor = useMemo(() => roles?.includes('BACKOFFICE_SUPERVISOR'), [roles]);

  const hasRole = useMemo(() => (role: UserRole) => roles?.includes(role), [roles]);

  const ready = !loading && roles;

  return { roles, isAdmin, isSupervisor, hasRole, loading, ready };
}
