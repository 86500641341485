import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import Masker from 'vanilla-masker';
import moment from 'moment';

import toPhone from '~/common/masked/toPhone';
import validateDate from '~/common/Validations/validateDate';
import { getPersonDetail, updatePersonInfo } from '~/store/people/actions';
import { Input, Select, Form } from '~/ui/components';
import { useModal } from '~/hooks/useModal';
import { UFs } from '~/constants';
import useUserRoles from '~/hooks/useUserRoles';
import validateMaxDate from '~/common/Validations/validateMaxDate';
import { Button } from './EditPersonInfoModal.styled';

const genders = [
  { value: 'MALE', label: 'Masculino' },
  { value: 'FEMALE', label: 'Feminino' },
];

export function EditPersonInfoModal() {
  const { setLoading, closeModal } = useModal();
  const { hasRole } = useUserRoles();
  const getPersonDetailControl = useAwaitControl(getPersonDetail);
  const updatePersonInfoControl = useAwaitControl(updatePersonInfo);

  const person = getPersonDetailControl.result();
  const updatePersonInfoLoading = updatePersonInfoControl.isRunning();
  const updatePersonInfoSuccess = updatePersonInfoControl.isSuccessful();

  const showAgreementPassword = hasRole('AGREEMENT_PASSWORD');

  const formatInputBirthDate = useCallback(
    (date) => (date ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''),
    [],
  );

  const formatOutputBirthDate = useCallback(
    (date) => (date ? moment(date, 'DD/MM/YYYY').format() : ''),
    [],
  );

  const formatOutputDateOfIssue = useCallback(
    (date) => (date ? moment(date, 'DD/MM/YYYY').format() : ''),
    [],
  );

  const onSubmit = (values) => {
    const formattedValues = {
      ...values,
      registerNumberDateOfIssue: formatOutputDateOfIssue(values.registerNumberDateOfIssue),
      birthDate: formatOutputBirthDate(values.birthDate),
    };

    updatePersonInfoControl.start({ id: person?.id, ...formattedValues });
  };

  useEffect(() => {
    setLoading('edit-person-info', updatePersonInfoLoading);
  }, [updatePersonInfoLoading]);

  useEffect(() => {
    if (updatePersonInfoSuccess) {
      closeModal('edit-person-info');
    }

    return () => {
      updatePersonInfoControl.clear();
    };
  }, [updatePersonInfoSuccess]);

  const inputs = useMemo(() => {
    const formInputs = [
      {
        id: 'name',
        label: 'Nome',
        initialValue: person?.name,
        input: <Input placeholder="Informe o nome do cliente" allowClear />,
      },
      {
        id: 'mothersName',
        label: 'Nome da mãe',
        initialValue: person?.motherName,
        input: <Input placeholder="Informe o nome da mãe do cliente" allowClear />,
      },
      {
        id: 'email',
        label: 'Email',
        initialValue: person?.email,
        width: showAgreementPassword ? '48%' : '100%',
        input: <Input placeholder="Informe o email" allowClear />,
        options: {
          rules: [{ type: 'email', message: 'Este campo deve ser um email!' }],
        },
      },
      {
        id: 'nickname',
        label: 'Apelido',
        width: '48%',
        initialValue: person?.nickname,
        input: <Input placeholder="Informe o apelido" allowClear />,
      },
      {
        id: 'registerNumber',
        initialValue: person?.registrationNumber,
        label: 'Número do documento',
        width: '48%',
        input: <Input placeholder="Informe o número do documento" allowClear />,
      },
      {
        id: 'registerNumberDateOfIssue',
        initialValue: person?.registerNumberDateOfIssue,
        label: 'Data de Emissão',
        width: '48%',
        input: <Input placeholder="Ex.: dd/mm/aaaa" allowClear />,
        options: {
          normalize: (value) => value && Masker.toPattern(value, '99/99/9999'),
          rules: [
            { validator: validateDate, message: 'Data inválida! Ex.: dd/mm/aaaa' },
            { validator: validateMaxDate, message: 'Data não pode ser maior que a data atual' },
          ],
        },
      },
      {
        id: 'registerNumberEmitter',
        initialValue: person?.registerNumberEmitter,
        label: 'Orgão Emissor',
        width: '48%',
        input: <Input placeholder="Informe o orgão emissor" allowClear />,
      },
      {
        id: 'registerNumberState',
        label: 'UF',
        initialValue: [person?.registerNumberState],
        width: '48%',
        input: <Select allowClear options={UFs} fieldNames={{ label: 'name', value: 'value' }} />,
      },
      {
        id: 'gender',
        label: 'Sexo',
        initialValue: [person?.gender],
        width: '48%',
        input: <Select allowClear options={genders} />,
      },
      {
        id: 'birthDate',
        label: 'Data de Nascimento',
        initialValue: formatInputBirthDate(person?.birthDate),
        width: '48%',
        input: <Input placeholder="Ex.: dd/mm/aaaa" allowClear />,
        options: {
          normalize: (value) => value && Masker.toPattern(value, '99/99/9999'),
          rules: [{ validator: validateDate, message: 'Data inválida! Ex.: dd/mm/aaaa' }],
        },
      },
      {
        id: 'phoneNumber1',
        label: 'Telefone',
        initialValue: toPhone(person?.phone),
        width: '48%',
        input: <Input placeholder="Informe o telefone" allowClear />,
        options: {
          normalize: toPhone,
          rules: [{ min: 14, message: 'Telefone inválido!' }],
        },
      },
    ];

    if (showAgreementPassword) {
      formInputs.push({
        id: 'agreementPassword',
        label: 'Senha do convênio',
        initialValue: person?.agreementPassword,
        width: '48%',
        input: <Input placeholder="Informe a senha do convênio" allowClear />,
      });
    }

    return formInputs;
  }, [person, showAgreementPassword]);

  return (
    <Form onSubmit={onSubmit} inputs={inputs}>
      <Button rounded fullWidth loading={updatePersonInfoLoading}>
        Alterar dados do usuário
      </Button>
    </Form>
  );
}
