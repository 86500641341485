import React, { useEffect } from 'react';

import { Steps } from '~/ui/components';

import { useAwaitControl } from 'react-redux-await-control';
import {
  revokeAgreementPassword,
  sendRevokeAgreementPasswordToken,
  validateRevokeAgreementPasswordToken,
} from '~/store/people/actions';
import { ConfirmInfoStep } from './Steps/ConfirmInfoStep';
import { ResultStep } from './Steps/ResultStep';

export function ExcludeAgreementPasswordModal() {
  const revokePasswordControl = useAwaitControl(revokeAgreementPassword);
  const sendTokenControl = useAwaitControl(sendRevokeAgreementPasswordToken);
  const validateTokenControl = useAwaitControl(validateRevokeAgreementPasswordToken);

  const steps = [
    { id: 'confirm-info', component: <ConfirmInfoStep /> },
    { id: 'result', component: <ResultStep /> },
  ];

  useEffect(
    () => () => {
      sendTokenControl.clear();
      validateTokenControl.clear();
      revokePasswordControl.clear();
    },
    [],
  );

  return <Steps steps={steps} />;
}
