import styled from 'styled-components';
import { Timeline, Icon } from 'antd';
import {
  FileDownload as FileDownloadIcon,
  LoadingSpinner as LoadingSpinnerIcon,
} from '~/ui/assets/icons';

export const TimelineContainer = styled.div`
  max-height: 600px;
  overflow: auto;
  padding: 10px;
  animation: fadeIn 0.5s;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background: #ddd;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export const HistoryItem = styled(Timeline.Item).attrs(({ theme }) => ({
  color: theme.colors.primary.main,
}))`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  padding: 0 0 40px !important;

  font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray['900']};

  b {
    font-weight: 500;
  }

  .ant-timeline-item-content {
    top: -2px;
    margin: 0 0 0 35px;
  }

  .ant-timeline-item-head {
    width: 20px;
    height: 20px;
  }

  .ant-timeline-item-tail {
    top: 10px;
    left: 9px;
  }
`;

export const HistoryRow = styled.div`
  display: flex;

  &:last-child {
    .history-item {
      padding: 0 !important;
    }
  }
`;

export const HistoryDate = styled.div`
  width: 100%;
  max-width: 105px;
  flex: 1;
  font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray['900']};
  text-align: right;
  padding-right: 15px;
`;

export const LoadingIcon = styled(Icon).attrs({
  type: 'loading',
})`
  font-size: 35px;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const HistoryImage = styled.img`
  width: 100%;
  height: 246px;
  object-fit: cover;
`;

type LoadingImageProps = {
  loading: boolean;
};

export const ImageContainer = styled.div<LoadingImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
  cursor: pointer;
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
`;

export const FilePDF = styled(FileDownloadIcon).attrs(({ theme }) => ({
  fill: theme.colors.primary.main,
}))``;

export const LoadingSpinner = styled(LoadingSpinnerIcon).attrs(({ theme }) => ({
  color: theme.colors.primary.main,
  width: 24,
  height: 24,
}))``;
