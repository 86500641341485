import React from 'react';
import { InfoGrid } from '~/ui/components';
import { Person } from '~/typings/entities/person';
import toPersonDocument from '~/common/masked/toPersonDocument';
import toNumber from '~/common/masked/toNumber';
import toDate from '~/common/masked/toDate';
import toPhone from '~/common/masked/toPhone';

import { Container } from './ClientInfo.styled';

export type ClientInfoProps = {
  person: Person;
  loading?: boolean;
};

export function ClientInfo({ person, loading }: ClientInfoProps) {
  const list = [
    {
      name: 'CPF',
      copyable: true,
      value: person.document ? toPersonDocument(person.document) : '-',
      copyText: toNumber(person.document),
    },

    {
      name: 'EMAIL',
      copyable: !!person?.email,
      value: person?.email || '-',
    },
    {
      name: 'TELEFONE',
      copyable: true,
      value: person.phone ? toPhone(person.phone) : '-',
      copyText: toNumber(person.phone),
    },
    {
      name: 'DATA DE NASCIMENTO',
      copyable: true,
      value: toDate(person?.birthDate),
    },
    {
      name: 'NOME DA MÃE',
      copyable: true,
      value: person?.motherName || '-',
    },
  ];

  return (
    <Container>
      <InfoGrid
        loading={loading}
        list={list}
        shimmerLength={5}
        grid="16% 16% 16% 16% 16%"
        responsive={{ 1350: '30% 30% 30%', 1168: '48% 48%' }}
      />
    </Container>
  );
}
