import React, { useEffect, useState } from 'react';
import { formatAgency } from '~/common';
import moment from 'moment';
import { isEmpty } from 'underscore';
import theme from '~/themes/aphroditeTheme/theme';
import { OldActions as MenuAction, ControlImage, Spinner } from '~/components';
import { Button, Col, Form, Row } from 'antd';
import Modal from '~/components/Modal';
import { compose } from 'recompose';
import common from '~/screens/ContractDetails/Actions/components/styles';
import styled from 'styled-components';
import { SEND_CONTRACT_SUCCESS } from '~/store/sendContract/action';
import Fields from '~/screens/ContractDetails/Actions/components/SendContractToConsignee/Fields';
import { useAwaitControl } from 'react-redux-await-control';
import { getFiles } from '~/store/files/actions';
import _ from 'lodash';
import { validateKYC } from '~/store/contracts/actions';

const { css, withStyles } = theme;

const StyledForm = styled(Form)`
  z-index: 3;
  background: white;
  padding: 16px;
  border-radius: 10px;
`;

type Props = {
  isLoading: boolean;
  details: any;
  banks: [];
  onSubmit: (fields: {}) => void;
  contractError: boolean;
  form?: any;
  onPressOpen?: () => void;
  styles?: any;
  usedFutureMargin?: boolean;
  id?: number;
  sendContract?: any;
  kycValidation?: boolean;
};

function updateFormFields(form, details) {
  const {
    agency,
    agencyDigit,
    checkingAccount,
    checkingAccountDigit,
    factorCode,
    bankNumber,
    gender,
    registerNumberDateOfIssue,
    registerNumberEmitter,
    registerNumberState,
    person,
  } = details.object;

  const {
    document,
    name,
    birthDate,
    motherName,
    registrationNumber,
    maritalStatus,
    nationality,
    stateOfBirth,
    cityOfBirth,
    zipCode,
    city,
    district,
    state,
    street,
    addressNumber,
  } = person;

  const canEditBankInfo = details.canUpdateBankInfo();

  const accountDigitIsLetter = checkingAccountDigit && checkingAccountDigit.match(/[A-z]/);

  const handledAgencyDigit = agencyDigit || '0';

  form.setFieldsValue({
    document,
    name,
    bankNumber,
    agency: formatAgency(agency),
    agencyDigit: !canEditBankInfo ? handledAgencyDigit : '0',
    checkingAccount,
    checkingAccountDigit: accountDigitIsLetter ? '0' : checkingAccountDigit,
    birthDate: birthDate ? moment(birthDate) : '',
    motherName,
    gender,
    registrationNumber,
    registerNumberDateOfIssue: registerNumberDateOfIssue ? moment(registerNumberDateOfIssue) : '',
    registerNumberEmitter,
    registerNumberState,
    factorCode,
    maritalStatus,
    nationality: nationality || 'Brasileira',
    stateOfBirth,
    cityOfBirth,
    zipCode,
    city,
    district,
    state,
    street,
    addressNumber,
  });
}

function SendContractView(props: Props) {
  const getFilesControl = useAwaitControl(getFiles);
  const validateKYCControl = useAwaitControl(validateKYC);

  const files = getFilesControl.result(`CONTRACT_${props.id}`);

  const [state, setState] = useState({
    agency: '',
    agencyDigit: '',
    checkingAccount: '',
    checkingAccountDigit: '',
    birthDate: '',
    motherName: '',
    gender: '',
    registrationNumber: '',
    registerNumberDateOfIssue: '',
    registerNumberEmitter: '',
    registerNumberState: '',
    factorCode: '',
    maritalStatus: '',
    nationality: '',
    stateOfBirth: '',
    cityOfBirth: '',
    zipCode: '',
    bankNumber: '',
    city: '',
    district: '',
    state: '',
    street: '',
    addressNumber: '',
    isFrontImage: true,
    setIsModalVisible: false,
    isModalVisible: false,
  });

  useEffect(() => {
    if (props.sendContract.getIn(['type']) === SEND_CONTRACT_SUCCESS) {
      setState((prev) => ({ ...prev, isModalVisible: false }));
    }
  }, [props.sendContract]);

  const onPressClose = () => {
    setState((prev) => ({ ...prev, isModalVisible: false }));
  };

  const onPressOpen = () => {
    props?.onPressOpen?.();

    setState((prev) => ({ ...prev, isModalVisible: true }));
    setTimeout(() => {
      updateFormFields(props.form, props.details);
    }, 500);
  };

  const onSubmit = (e) => {
    const { form, usedFutureMargin } = props;
    const fieldsToOmit = ['document'];
    const values = form.getFieldsValue();
    const valuesToSend = _.omit(values, fieldsToOmit);

    e.preventDefault();

    form.validateFields((err) => {
      if (!err || isEmpty(err)) {
        props.onSubmit(valuesToSend);
      }

      if (!usedFutureMargin && !err) {
        validateKYCControl.start({ contractId: props.id });
      }
    });
  };

  const onChange = (field) => (event) => {
    const value = event && event.target ? event.target.value : event;
    setState((prev) => ({ ...prev, [field]: value }));
  };

  const toggleFrontImage = () => {
    const { isFrontImage } = state;
    setState((prev) => ({ ...prev, isFrontImage: !isFrontImage }));
  };

  function getImage(filesList) {
    const { isFrontImage } = state;
    let image = { url: '' };
    const DOCUMENTFRONTRG = filesList.find((file) => file.type === 'DOCUMENTFRONT');
    const DOCUMENTBACKRG = filesList.find((file) => file.type === 'DOCUMENTBACK');

    if (DOCUMENTFRONTRG) {
      image = isFrontImage ? DOCUMENTFRONTRG : DOCUMENTBACKRG;
    }
    return image.url;
  }

  function renderImages() {
    const { isFrontImage } = state;
    const { styles } = props;

    if (!files) return null;

    return (
      <div {...css(styles.preview__container)}>
        <ControlImage alt="Documento" imagePath={getImage(files)} />
        <Button onClick={toggleFrontImage}>{isFrontImage ? 'Ver Verso' : 'Ver Frente'}</Button>
      </div>
    );
  }

  function renderForm() {
    const { form, details, banks, usedFutureMargin } = props;
    const { loanType, person } = details.object;
    const agreementId = person?.agreementId;

    const { getFieldDecorator } = form;

    const cantEditBankInfo = details.canUpdateBankInfo();
    const disabledFields = {
      bankNumber: !cantEditBankInfo,
      checkingAccount: !cantEditBankInfo,
      checkingAccountDigit: !cantEditBankInfo,
      agency: !cantEditBankInfo,
      agencyDigit: !cantEditBankInfo,
      document: true,
    };

    const fieldsData = {
      bankNumber: banks?.map((b: any) => ({ name: b.name, value: b.bankNumber })),
    };

    return (
      <StyledForm>
        <Row>
          <Fields
            loanType={loanType}
            agreementId={agreementId}
            onChange={onChange}
            disabledFields={disabledFields}
            fieldsData={fieldsData}
            getFieldDecorator={getFieldDecorator}
          />
          <Col xs={{ span: details.isFGTS ? 3 : 6, offset: details.isFGTS ? 21 : 6 }}>
            <Button type="primary" style={{ marginTop: 20 }} onClick={onSubmit}>
              {usedFutureMargin ? 'Salvar informações' : 'Enviar Contrato'}
            </Button>
          </Col>
        </Row>
      </StyledForm>
    );
  }

  const { styles, isLoading, contractError, details } = props;

  useEffect(() => {
    const { id } = props;
    const type = 'CONTRACT';
    getFilesControl.start({ type, id }, { actionId: `${type}_${id}` });
  }, []);

  if (contractError || !details.canSendContractToConsignee()) return null;

  return (
    <>
      <MenuAction
        key="sendContract"
        onPress={onPressOpen}
        style={css(styles.action)}
        title="Enviar para Consignataria"
      />
      <Modal
        ariaHideApp={false}
        keyboard
        contentLabel="SendContract"
        visible={state?.isModalVisible}
        onCancel={onPressClose}
        width="calc(98vw)"
        title="Envio de contrato para consignatária"
        key="modal"
      >
        <Spinner spinning={isLoading} />
        <div {...css(styles.modal__content_row)}>
          {renderForm()}
          {renderImages()}
        </div>
      </Modal>
    </>
  );
}

const styles = (props) => ({
  ...common(props),
  modal: {
    ...common(props).modal,
    width: 600,
  },
});

export default compose(withStyles(styles), Form.create())(SendContractView);
