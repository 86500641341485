import React, { useEffect, useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import { UserCircle } from '~/ui/assets/icons';
import { Form, RenderRichText, RichText, Tooltip } from '~/ui/components';
import { TicketComment as IComment } from '~/typings/entities/Ticket';
import { getLoggedUser } from '~/store/user/actions';
import { updateTicketComment } from '~/store/tickets/actions';
import {
  BackButton,
  Comment,
  CommentAuthor,
  CommentAuthorRow,
  CommentDate,
  EditButton,
  EditText,
} from './TicketComments.styled';

type TicketCommentProps = {
  comment: IComment;
  ticketId: string;
};

export function TicketComment({ comment, ticketId }: TicketCommentProps) {
  const formRef = useRef(null);
  const [editMode, setEditMode] = React.useState(false);
  const getLoggedUserControl = useAwaitControl(getLoggedUser);
  const updateTicketCommentControl = useAwaitControl(updateTicketComment);
  const loggedUser = getLoggedUserControl.result();

  const editCommentLoading = updateTicketCommentControl.isRunning(comment?.id);
  const editCommentSuccess = updateTicketCommentControl.isSuccessful(comment?.id);

  const isOwnComment = (comment: IComment) => comment.authorNameId === loggedUser.id;
  const formatDate = (date: string, format = 'DD/MM/YYYY [às] HH:mm') =>
    moment(date).format(format);

  const toggleEditMode = () => setEditMode(!editMode);
  const cancelEditMode = () => setEditMode(false);

  const handleEditComment = ({ description }) => {
    const { id } = comment;
    const newComment = {
      ...comment,
      ticketId,
      description,
    };

    updateTicketCommentControl.start({ ...newComment, id }, { actionId: id });
  };

  useEffect(() => {
    if (editCommentSuccess) {
      setEditMode(false);
    }
  }, [editCommentSuccess]);

  return (
    <Comment key={comment.id}>
      <CommentAuthorRow>
        <UserCircle />
        <CommentAuthor>{comment.authorName}</CommentAuthor>
        {isOwnComment(comment) && !editMode && <EditButton onClick={toggleEditMode} />}
        {isOwnComment(comment) && editMode && <BackButton onClick={cancelEditMode} />}
      </CommentAuthorRow>

      {editMode ? (
        <Form
          ref={formRef}
          onSubmit={handleEditComment}
          loading={editCommentLoading ? 1 : 0}
          inputs={[
            {
              id: 'description',
              initialValue: comment.description,
              input: (
                <RichText
                  small
                  showActionButton
                  placeholder="Escreva um novo comentário"
                  controlsVisible
                />
              ),
              options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
            },
          ]}
        />
      ) : (
        <>
          <CommentDate>{formatDate(comment?.createdAt)}</CommentDate>

          <RenderRichText html={`${comment.description}`}>
            {comment?.edited && (
              <Tooltip
                placement="bottomRight"
                content={`Última atualização: ${formatDate(comment?.updatedAt)}`}
              >
                <EditText>(editado)</EditText>
              </Tooltip>
            )}
          </RenderRichText>
        </>
      )}
    </Comment>
  );
}
