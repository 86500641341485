// @flow
import React, { useCallback, useState } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { Card } from '~/components';
import { disableToFutureDates } from '~/common';

import {
  Content,
  Label,
  Select,
  Input,
  Button,
  SearchButton,
  DateButton,
  ButtonLabel,
} from './styled';

type FiltersProps = {
  updateSearch: (params: any) => void,
};

const OPTIONS = ['CONTRACT', 'QIPROPOSAL', 'PERSON'];

export default function Filters({ updateSearch }: FiltersProps) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rangeDate, setRangeDate] = useState();
  const [genericId, setGenericId] = useState();
  const [logType, setLogType] = useState();
  const [interval, setInterval] = useState();

  const updateRangeDate = useCallback(([start, end]) => {
    setRangeDate([start, end]);
    setInterval();

    if (start) {
      setStartDate(moment(start).format('YYYY-MM-DD'));
    }

    if (end) {
      setEndDate(moment(end).format('YYYY-MM-DD'));
    }
  }, []);

  const search = useCallback(() => {
    updateSearch(
      omitBy({ startDate, endDate, genericId, logType, interval }, isNil),
    );
  }, [updateSearch, startDate, endDate, genericId, logType, interval]);

  const onChangeId = useCallback(e => {
    setGenericId(e.target.value);
  }, []);

  const onChangeInterval = useCallback(
    (interval: number) => () => {
      setRangeDate([undefined, undefined]);
      setInterval(interval);
      setStartDate();
      setEndDate();
    },
    [],
  );

  return (
    <Card title='Filtros'>
      <Content>
        <Row type='flex' justify='start'>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 5 }}>
            <Label>Categoria</Label>
            <Select
              placeholder='Selecione a categoria'
              style={{ width: '95%' }}
              onChange={setLogType}
            >
              {OPTIONS.map(opt => (
                <Select.Option key={opt}>{opt}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 4 }}>
            <Label>Id</Label>
            <Input placeholder='Digite o id' onChange={onChangeId} />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 15 }}>
            <Label>Período</Label>
            <div>
              <Button
                onClick={onChangeInterval(15 * 60)}
                active={interval === 15 * 60}
              >
                <ButtonLabel>15 min</ButtonLabel>
              </Button>
              <Button
                onClick={onChangeInterval(30 * 60)}
                active={interval === 30 * 60}
              >
                <ButtonLabel>30 min</ButtonLabel>
              </Button>
              <Button
                onClick={onChangeInterval(60 * 60)}
                active={interval === 60 * 60}
              >
                <ButtonLabel>1 hora</ButtonLabel>
              </Button>
              <Button
                onClick={onChangeInterval(6 * 60 * 60)}
                active={interval === 6 * 60 * 60}
              >
                <ButtonLabel>6 horas</ButtonLabel>
              </Button>
              <DateButton
                placeholder={['Início', 'Fim']}
                disabledDate={disableToFutureDates}
                onChange={updateRangeDate}
                format='DD/MM/YYYY'
                value={rangeDate}
                defaultPickerValue={[moment().utc(), moment().utc()]}
              />
              <SearchButton type='primary' onClick={search}>
                Buscar
              </SearchButton>
            </div>
          </Col>
        </Row>
      </Content>
    </Card>
  );
}
