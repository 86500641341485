const FGTSIntegrationStatus = {
  '-1': {
    label: 'Caixa indisponível',
    description:
      'Ocorreu um erro nos servidores da caixa e o serviço está indisponível. Te avisaremos quando voltar',
    color: 'negative',
  },
  '-2': {
    label: 'Atualizando dados FGTS',
    description:
      'Houve mudanças cadastrais no seu FGTS e a Caixa está atualizando seus dados. Tente novamente mais tarde.',
    color: 'alert',
  },
  '5': {
    label: 'Operação em andamento',
    description: 'Você possui uma operação aguardando liberação no FGTS. Tente mais tarde :)',
    color: 'alert',
  },
  '7': { label: 'Autorizar a Qi', description: 'Autorizar a Qi', color: 'alert' },
  '8': {
    label: 'Não possui FGTS',
    description:
      'Você ainda não possui uma conta ativa de FGTS por isso não pode contratar esse produto :(',
    color: 'negative',
  },
  '9': {
    label: 'Pendente habilitar',
    description: 'Habilitar a modalidade do saque',
    color: 'alert',
  },
  '10': {
    label: 'Saque bloqueado',
    description:
      'Aguarde. Você está bloqueado por conta do seu aniversário. Assim que liberar te avisaremos.',
    color: 'negative',
  },
  '18': {
    label: 'Saque aniversário desabilitado',
    description:
      'Ei, habilite novamente a modalidade do Saque-Aniversário no aplicativo do FGTS para prosseguir conosco na antecipação',
    color: 'negative',
  },
  '35': {
    label: 'Atualização dados FGTS pendente',
    description:
      'Atualize seus dados do FGTS entrando em contato com o atendimento da Caixa. Após isso, volte para conferir suas oportunidades conosco.',
    color: 'alert',
  },
  '1001': {
    label: 'Serviço indisponível',
    description:
      'Ocorreu um erro nos servidores da caixa e o serviço está indisponível. Te avisaremos quando voltar',
    color: 'negative',
  },
  '-3': {
    label: 'Oportunidade indisponível',
    description:
      'De acordo com nossa política interna, analisamos o seu perfil e atualmente não temos oportunidades disponíveis para você. Nosso canal de atendimento está pronto para explicar melhor.',
    color: 'negative',
  },
  OK: {
    label: 'Habilitado',
    description: 'OK',
    color: 'positive',
  },
};

export default FGTSIntegrationStatus;
