// @flow

import React, { useState } from 'react';
import { Pendency } from '~/screens/ContractDetails/Panels/Pendencies/styled';
import pendencyTitle from '~/typings/enums/PendencyType';
import Selection from './components/Selection';

type DocumentsProps = {
  onEnter: (number: string) => void,
  id?: number,
  type?: string,
};

function Account(props: DocumentsProps) {
  const [showModal, setStateShowModal] = useState(false);
  const setShowModal = (show) => () => {
    setStateShowModal(show);
  };

  const onEnter = (fields) => {
    setShowModal(false)();
    props.onEnter(fields);
  };

  return (
    <>
      <Pendency onClick={setShowModal(true)}>
        {pendencyTitle(props.pendingType) || props.children}
        <span>{props.reason}</span>
      </Pendency>
      <Selection
        showModal={showModal}
        onModalClose={setShowModal(false)}
        onSend={onEnter}
      />
    </>
  );
}

export default Account;
