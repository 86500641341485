/* @flow */
/* global document */

export const GET_CONTRACT_IMAGE_FACE_FRONT_REQUEST =
  'GET_CONTRACT_IMAGE_FACE_FRONT_REQUEST';
export const GET_CONTRACT_IMAGE_FACE_FRONT_SUCCESS =
  'GET_CONTRACT_IMAGE_FACE_FRONT_SUCCESS';
export const GET_CONTRACT_IMAGE_FACE_FRONT_FAILURE =
  'GET_CONTRACT_IMAGE_FACE_FRONT_FAILURE';

// Get informatios of width and height.
function prepare(image) {
  return new Promise(resolve => {
    if (image.exist === false) resolve(image);
    const img = document.createElement('img');
    img.src = image.uri;
    img.onload = () => {
      const { height, width } = img;
      resolve({ ...image, height, width });
    };
  });
}

export function success(payload) {
  return dispatch => {
    prepare(payload).then(image => {
      dispatch({
        type: GET_CONTRACT_IMAGE_FACE_FRONT_SUCCESS,
        payload: image,
      });
    });
  };
}

export function failure(err) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_IMAGE_FACE_FRONT_FAILURE,
      payload: [],
      message: err,
    });
  };
}

export default function fetch(id) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_IMAGE_FACE_FRONT_REQUEST,
      payload: {
        id,
      },
    });
  };
}
