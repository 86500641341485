import React from 'react';
import { ChevronDown } from 'react-feather';
import { useTheme } from 'styled-components';

import { WalletCard } from '~/typings/entities/Wallet';
import { Popover } from '~/ui/components';

import { CardItem, CardList } from './CardsPopover.styled';

type CardsPopoverProps = {
  cards: WalletCard[];
};

export function CardsPopover({ cards }: CardsPopoverProps) {
  const { colors } = useTheme();

  if (!cards.length) {
    return <>-</>;
  }

  return (
    <Popover
      noHeader
      width={150}
      trigger="click"
      placement="bottomRight"
      noPadding
      content={
        <CardList>
          {cards.map((card) => (
            <CardItem key={card.cardId}>
              <span>{card.brand}</span>
              Final {card.last4}
            </CardItem>
          ))}
        </CardList>
      }
    >
      {cards?.length} cartões <ChevronDown width={22} height={22} color={colors.brand.primary} />
    </Popover>
  );
}
