import React, { memo, useCallback, useState } from 'react';

import { InfoItem } from '~/components';
import toMoney from '~/common/masked/toMoney';
import { Caret, Popover, Tooltip } from '~/ui/components';
import { formatDate } from '~/common/formatDate';
import { Enrollment } from '~/typings/entities/Enrollment';
import { Person } from '~/typings/entities/person';
import fromNow from '~/common/masked/fromNow';
import { consignee } from '~/common/constants';
import { CellWrapper, Container, FlexWrapper, Label } from './Details.styled';
import LastUpdates from '../Popovers/LastUpdates';

interface DetailsProps {
  enrollment: Enrollment;
  personDetails: Person;
}

function Details({ enrollment, personDetails }: DetailsProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalVisibilityChange = useCallback((e: boolean) => setIsModalVisible(e), []);

  const renderLabelWithInfo = useCallback(
    (label, info) => (
      <FlexWrapper>
        <CellWrapper>{label ?? '-'}</CellWrapper>
        {info && <Tooltip content={info} placement="bottomLeft" maxWidth="350px" />}
      </FlexWrapper>
    ),
    [],
  );

  const INSS = enrollment?.product === 'INSS' || 'PAB';
  const FGTS = enrollment?.product === 'FGTS';

  return (
    <Container>
      <InfoItem title="Margem de cartão" value={toMoney(enrollment?.marginInfo.marginCard)} />

      <InfoItem title="Margem empréstimo" value={toMoney(enrollment?.marginInfo.marginLoan)} />

      <InfoItem
        title="Margem calculada"
        value={toMoney(enrollment?.marginInfo.computedMarginLoan)}
      />

      {INSS && (
        <InfoItem
          title="Código do benefício"
          value={renderLabelWithInfo(enrollment?.workCode, enrollment?.workName)}
        />
      )}

      {FGTS && <InfoItem title="Nome do benefício" value={enrollment?.workName} />}

      <InfoItem title="Número de matrícula" value={enrollment?.enrollment} />

      {INSS && <InfoItem title="Senha convênio" value={enrollment?.agreementPassword} />}

      {INSS && (
        <InfoItem title="Tempo de recebimento" value={fromNow(enrollment?.benefitStartDate)} />
      )}

      {INSS && <InfoItem title="Validade do benefício" value={enrollment?.benefitValidateDate} />}

      {INSS && (
        <InfoItem
          title="Atualizações"
          value={<LastUpdates enrollment={enrollment} person={personDetails} />}
        />
      )}

      {INSS && (
        <InfoItem
          title="Senha cadastrada"
          value={
            <Popover
              placement="bottom"
              trigger="click"
              content={<>{formatDate(enrollment?.agreementPasswordUpdateDate)}</>}
              onVisibleChange={handleModalVisibilityChange}
              visible={enrollment?.registeredAgreementPassword ? isModalVisible : false}
            >
              <Label>{enrollment?.registeredAgreementPassword ? 'Sim' : 'Não'}</Label>
              {enrollment?.registeredAgreementPassword && <Caret caretOpen={isModalVisible} />}
            </Popover>
          }
        />
      )}

      {FGTS && <InfoItem title="Status de integração" value={enrollment?.integrationStatus} />}

      <InfoItem title="Consignatária" value={consignee[enrollment?.financialInstitution] || '-'} />
    </Container>
  );
}

export default memo(Details);
