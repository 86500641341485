export const LOAN_TYPE_NAMES = {
  NEW: 'Empréstimo Novo',
  FGTS_NEW: 'FGTS',
  PAB: 'PAB',
  CLIX: 'Clix',
  CLIX_CLEAN: 'Clix Clean',
  CLIX_BENEFITS_CARD: 'Clix Cartão Benefício',
  CLIX_CREDIT_CARD: 'Clix Cartão de crédito',
  PORTABILITY: 'Portabilidade',
  PORTABILITYWITHOUTREFIN: 'Portabilidade sem refin',
  REFIN: 'Refinanciamento',
  FUTUREMARGIN: 'Margem Futura',
  GENERAL_STATUS: 'Status Gerais',
  INSURANCE: 'Seguro',
  NEW_CREDIT_LINES: 'Novas linhas de crédito',
  SHOPPING: 'Loja Meutudo',
  INCOME: 'Seguro Renda',
};

const LOAN_TYPES = {
  NEW: 'NEW', // INSS
  PORTABILITY: 'PORTABILITY', // INSS
  PORTABILITYWITHOUTREFIN: 'PORTABILITYWITHOUTREFIN', // INSS
  REFIN: 'REFIN', // INSS
  FUTUREMARGIN: 'FUTUREMARGIN', // INSS
  FGTS_NEW: 'FGTS_NEW', // FGTS
  CLIX: 'CLIX',
  PAB: 'PAB',
};

function LoanType(props: any) {
  if (props) {
    return LOAN_TYPE_NAMES[props.value] || LOAN_TYPE_NAMES[props] || null;
  }
  return null;
}

LoanType.LOAN_TYPES = LOAN_TYPES;

export default LoanType;
