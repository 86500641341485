import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Container = styled.div`
  padding: 24px 0;
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
`;

export const SectionTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  weight: 500,
  element: 'h3',
}))``;

export const CellWrapper = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ theme }) => theme.colors.element.primary};
`;

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: theme.colors.element.primary,
}))`
  text-overflow: ellipsis;
  margin-bottom: 0;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 4px;
`;
