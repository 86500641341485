enum CommentMotives {
  PROGRESS_OF_CONTRACT = 'Andamento de Contrato',
  SLA_DELAY = 'Atraso de SLA',
  LATE_PAYMENT = 'Atraso no pagamento',
  REGISTER = 'Cadastro',
  CANCELLATION_REQUESTED = 'Cancelamento Solicitado',
  BENEFIT_CARD = 'Cartão Benefício',
  CONSIGNED_CREDIT_CARD = 'Cartão de Crédit Consignado',
  RETAINED_CUSTOMER = 'Cliente Retido',
  UNDO_ENDORSEMENT = 'Desaverbação',
  CONTRACT_ERROR = 'Erro no Contrato',
  FEEDBACK = 'Feedback',
  FORMALIZATION = 'Formalização',
  INTEGRATED = 'Integrado',
  NOT_FORMALIZED = 'Não Formalizado',
  NOT_INTEGRATED = 'Não integrado',
  OTHERS = 'Outros',
  PAYMENT_RESUBMITTED = 'Pagamento Reapresentado',
  PAID_OUT = 'Pago',
  PAID_NOT_RECEIVED = 'Pago - Não recebeu',
  PENDENCY_UNSOLVED = 'Pendência Não Resolvida',
  PENDENCY_RESOLVED = 'Pendência Resolvida',
  PLATFORM = 'Plataforma',
  INCOMPLETE_PORTABILITY = 'Portabilidade Incompleta',
  REIMBURSEMENT = 'Reembolso',
  REGISTRATION_RESET = 'Reset de Cadastro',
  BLOCKED_BALANCE = 'Saldo Bloqueado',
  NO_OPPORTUNITY = 'Sem Oportunidade',
  ACQUITTANCE_REQUEST = 'Solicitação de Quitação',
  GENERAL_REQUEST = 'Solicitação Geral',
  CHANGE_VARIATION = 'Variação de Troco',
}

export enum ClixCommentMotives {
  CLIX_SLA_DELAY = 'Atraso de SLA - Andamento',
  CLIX_ENDORSED_NO_OPPORTUNITY = 'Averbado/Sem Oportunidade',
  CLIX_CANCELLED = 'Cancelamento do Clix',
  CLIX_TABLEAU_CANCELLED_NO_OPPORTUNITY = 'Cancelado Tableu/Sem Oportunidade',
  CLIX_UNDO_ENDORSEMENT = 'Desaverbação',
  CLIX_WITHDRAW_ERROR = 'Erro Saque',
  CLIX_INVOICE_ERROR = 'Erro Fatura',
  CLIX_PAYMENT_ERROR = 'Erro Pagamento',
  CLIX_CONTRACT_SOLICITATION = 'Solicitação de Contrato',
  CLIX_CASHBACK_PAYMENT = 'Pagamento Cashback',
  CLIX_DIVERGENT_RATE = 'Taxa divergente App/Contrato',
  CLIX_REIMBURSEMENT = 'Reembolso Clix',
}

export default CommentMotives;
