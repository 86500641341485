/* @flow */
import { Map } from 'immutable';

import { SET_HIDDEN_CONTRACT_FIELDS } from './action';

const initialState = Map({
  type: SET_HIDDEN_CONTRACT_FIELDS,
  payload: [],
});

function reducer(state = initialState, action): any {
  const { type } = action;

  if (type === SET_HIDDEN_CONTRACT_FIELDS) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
