/* @flow */
import { message } from 'antd';
import dialogError from '~/common/dialogErrors';

export const SEND_BIOMETRY_REQUEST = 'SEND_BIOMETRY_REQUEST';
export const SEND_BIOMETRY_SUCCESS = 'SEND_BIOMETRY_SUCCESS';
export const SEND_BIOMETRY_FAILURE = 'SEND_BIOMETRY_FAILURE';

export function success(payload) {
  message.success('Foto enviada com sucesso!');
  return dispatch => {
    dispatch({
      type: SEND_BIOMETRY_SUCCESS,
      payload,
    });
  };
}

export function failure({ messages }) {
  return dispatch => {
    dispatch({
      type: SEND_BIOMETRY_FAILURE,
      payload: messages,
    });

    dialogError(messages || 'Falha ao enviar foto.');
  };
}

export default function uploadHiscon(params) {
  return dispatch => {
    dispatch({
      type: SEND_BIOMETRY_REQUEST,
      payload: params,
    });
  };
}
