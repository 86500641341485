import styled from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Button, Typography } from '~/ui/components';

export const CommentsContainer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const CommentsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;

  .ant-divider {
    margin: 16px 0;

    &:last-child {
      display: none;
    }
  }
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  .editor-preview {
    font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
    font-size: ${({ theme }) => theme.typography.types.caption.size};
    font-weight: ${({ theme }) => theme.typography.types.caption.weight};
    line-height: ${({ theme }) => theme.typography.types.caption.lineHeight};
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  }
`;

export const CommentAuthor = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  weight: 500,
  element: 'h3',
}))`
  flex: 1;
`;

export const CommentDate = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: themeToggleColor(theme, 'element.placeholder', { dark: 'element.secondary' }),
  element: 'span',
}))``;

export const NoContentMessage = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  element: 'p',
}))`
  text-align: center;
  margin-top: 16px;
`;

export const CommentAuthorRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > svg {
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    width: 20px;
    height: 20px;
  }
`;

export const EditButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  size: 'sm',
  customColor: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
  icon: 'Pencil',
  iconOnly: true,
  title: 'Editar comentário',
}))`
  width: 20px;
  min-width: 20px;
`;

export const BackButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  size: 'sm',
  customColor: theme.colors.element.secondary,
  icon: 'Close',
  iconOnly: true,
  title: 'Cancelar edição',
}))`
  width: 20px;
  min-width: 20px;
`;

export const EditText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: themeToggleColor(theme, 'element.placeholder', { dark: 'element.secondary' }),
  weight: 500,
  element: 'span',
}))`
  cursor: default;
`;
