export default function style({ colors, unit }) {
  const fontFamily = 'Rubik, sans-serif';
  const button = {
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'inset 0 -1px 0 0 rgba(0,0,0,0.10)',
    color: 'white',
    cursor: 'pointer',
    fontFamily,
    fontSize: '12px',
    fontWeight: 'bold',
    height: '39px',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: 1.1,
    padding: `${unit * 1.2}px 0`,
    width: '180px',

    ':hover': {
      textDecoration: 'underline',
    },
  };
  const input = {
    border: 'none',
    color: '#B1B8C6',
    display: 'flex',
    fontFamily,
    fontSize: 14,
    letterSpacing: '-0.19px',
    outline: 'none',
    padding: unit * 1.5,
    width: '100%',

    '::placeholder': {
      color: '#B1B8C6',
    },
  };

  const ease = {
    '0%': {
      opacity: 0,
      transform: 'scale(0.7) translate3d(0,0,0)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1) translate3d(0,0,0)',
    },
  };

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.grayContainer,
      paddingTop: unit * 2,
    },
    table: {
      margin: 0,
      width: '100%',
    },
    textCenter: {
      textAlign: 'center !important',
    },
    actions: {
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '90px',
      width: 200,
    },
    action: {
      cursor: 'pointer',
      fontFamily,
      lineHeight: 1.2,
      padding: `${unit * 1.5}px 0 0 0`,

      ':hover': {
        textDecoration: 'underline',
      },
    },
    approveButton: {
      ...button,
      background: '#52B771',
      marginTop: unit * 1.5,
    },
    refuseButton: {
      color: '#C0392B',
      cursor: 'pointer',
      fontFamily,
      padding: `${unit * 2}px 0 0 0`,
      textDecoration: 'underline',
    },
    key: {
      display: 'block',
      color: '#949494',
      fontFamily,
      fontSize: unit * 0.9,
      letterSpacing: '0',
      textTransform: 'uppercase',
    },
    modal: {
      animationName: ease,
      animationDuration: '150ms',
      animationTimingFunction: 'linear',
      animationFillMode: 'forwards',
      background: 'white',
      borderRadius: 3,
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1)',
      padding: unit * 2,
      position: 'relative',
      width: 870,
    },
    modal__header: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: unit,
    },
    closeButton: {
      marginTop: '5px',
    },
    closeButtonContainer: {
      border: 'none',
      marginLeft: unit * 2,
    },
    modal__title: {
      display: 'block',
      color: '#949494',
      fontFamily,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 1.2,
      marginBottom: unit * 2,
    },
    modal__close: {
      border: 'none',
      outline: 'none',
      background: 'white',
    },
    modal__content: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    input: {
      ...input,

      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1) inset, 0 1px 1px rgba(0, 0, 0, 0.1) inset',
      marginTop: unit * 0.5,
      marginBottom: unit,
    },
    buttonConfirm: {
      ...button,

      background: '#F5A623',
    },
  };
}
