import React from 'react';
import { useTheme } from 'styled-components';

import { CaretDown } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';

import { ClearSaleScoreItem } from '~/typings/entities/person';
import { ScoreWrapper } from './ClearSaleScore.styled';
import { ClearSaleHistory } from './ClearSaleHistory';

export type ClearSaleScoreProps = {
  score: number;
  scoreHistory: ClearSaleScoreItem[];
};

export function ClearSaleScore({ score, scoreHistory }: ClearSaleScoreProps) {
  const theme = useTheme();

  if (scoreHistory?.length) {
    return (
      <Popover
        placement="bottomLeft"
        trigger="hover"
        noHeader
        width={330}
        content={<ClearSaleHistory scoreHistory={scoreHistory} />}
      >
        <ScoreWrapper>
          {score || score === 0 ? `${score}%` : '-'}{' '}
          <CaretDown
            className="action-icon"
            color={theme.colors.primary.main}
            width={12}
            height={12}
          />
        </ScoreWrapper>
      </Popover>
    );
  }

  return <>{score || score === 0 ? `${score}%` : '-'}</>;
}
