import React, { useContext, useState } from 'react';
import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';
import ProfileFilter from './ProfileFilter';
import {
  ConfigContent,
  ConfigLabel,
  ConfigTitle,
  HoursConfigContainer,
  LabelContainer,
  LabelDisabledTag,
  LabelSuccessTag,
  LabelTag,
  Popover,
} from './styled';

export const PROFILE_FILTER_NAMES = {
  TRANSACTIONAL: 'KYC validado',
  ONBOARDING: 'KYC pendente',
};

export function ProfileConfig() {
  const [visible, setVisible] = useState(false);
  const { isDistributionActive, queueFilter, allStepsConcluded } =
    useContext(ContractDetailsContext);

  const closePopover = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    if (isDistributionActive && !allStepsConcluded) {
      setVisible(visible);
    }
  };

  return (
    <Popover
      placement="bottomLeft"
      content={<ProfileFilter closePopover={closePopover} />}
      trigger="click"
      visible={isDistributionActive && !allStepsConcluded && visible}
      onVisibleChange={handleVisibleChange}
    >
      <ConfigContent>
        <HoursConfigContainer disabled={isDistributionActive && allStepsConcluded}>
          <LabelContainer>
            <ConfigTitle enabled={isDistributionActive}>Filtro de perfil</ConfigTitle>
            {!isDistributionActive && <LabelDisabledTag>Ative seu status</LabelDisabledTag>}
            {isDistributionActive && !queueFilter && <LabelTag>Selecione o perfil</LabelTag>}
            {queueFilter && (
              <LabelSuccessTag>{PROFILE_FILTER_NAMES[queueFilter].toUpperCase()}</LabelSuccessTag>
            )}
          </LabelContainer>
          <ConfigLabel enabled={isDistributionActive}>
            Escolha qual o perfil do cliente para sua fila
          </ConfigLabel>
        </HoursConfigContainer>
      </ConfigContent>
    </Popover>
  );
}
