import React, { useEffect, useMemo, useState } from 'react';
import { Pagination } from '~/ui/components';

export type UsePaginationProps = {
  total: number;
  data: any[];
  pageSize?: number;
};

export type UsePaginationReturn = {
  page: number;
  paginatedData: any[];
  renderPagination: () => React.ReactNode;
  clear: () => void;
  size: number;
};

export default function usePagination({
  data,
  total,
  pageSize = 10,
}: UsePaginationProps): UsePaginationReturn {
  const [page, setPage] = useState<number>(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(false);

  function renderPagination() {
    const totalPages = Math.ceil(total / pageSize);

    if (!totalPages || totalPages <= 1) return <></>;
    return (
      <Pagination
        totalPages={totalPages}
        onClick={(page) => setPage(page - 1)}
        resetCurrentPage={currentPage}
      />
    );
  }

  function clear() {
    setPaginatedData([]);
    setPage(0);
    setCurrentPage(true);

    setTimeout(() => setCurrentPage(false), 1000);
  }

  useEffect(() => {
    setPaginatedData((currentValue) => ({ ...currentValue, [page]: data }));
  }, [data]);

  return useMemo(
    () => ({
      page,
      size: pageSize,
      renderPagination,
      clear,
      paginatedData: paginatedData?.[page] || [],
    }),
    [page, paginatedData, renderPagination],
  );
}
