import { message } from 'antd';
import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';
import { endpoint } from '~/store';

export const getMedicalCertificate = createAsyncAction('GET_MEDICAL_CERTIFICATE', {
  saveResult: true,
  context: {
    request: ({ personId }) =>
      telli.get(`${endpoint}/admin/personcustom/people/${personId}/medicalcertificate`, {
        responseType: 'blob',
      }),
    responseParser: (response) => {
      if (response.status !== 200) {
        throw new Error('Bad response from server.');
      }

      return response.data;
    },
    onRequestSuccess: () => message.success('Perícia médica requisitada com sucesso.'),
    onRequestError: () => message.error('Falha ao requisitar perícia médica.'),
  },
});
