import styled from 'styled-components';

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin-bottom: 14px;
`;

const TextBase = styled.span`
  font-size: 16px;
  line-height: 20px;

  color: #000000;
`;

export const Label = styled(TextBase)`
  font-weight: 600;
`;

export const Value = styled(TextBase)`
  margin-left: 5px;
`;

export const ValueHighlighted = styled(Value)`
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 600;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 21px;
`;

export const DescriptionTextWrapper = styled.div`
  background-color: #ececec;
  border-radius: 15px;
  height: 200px;

  padding: 17px 26px;
  margin-top: 10px;
`;

export const Description = styled.div`
  overflow: auto;
  max-height: 166px;

  span {
    font-size: 12px;
  }
`;
