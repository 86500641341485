import React, { useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { OpportunityListItem, SuggestedProposalReturn } from '~/typings/entities/Opportunity';
import { prepareContract, suggestedProposals } from '~/store/opportunities/actions';
import { Steps } from '~/ui/components';
import { getContractsOfPerson } from '~/store/people/actions';
import { Selection } from './Selection/Selection';
import { Details } from './Details/Details';
import { Error } from './Error/Error';
import { Success } from './Success/Success';

export type RefinStepsProps = {
  opportunity: OpportunityListItem;
};

export function RefinSteps({ opportunity }: RefinStepsProps) {
  const [simulationAlert, setSimulationAlert] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState<SuggestedProposalReturn>(null);

  const proposalsControl = useAwaitControl(suggestedProposals);
  const getContractsOfPersonControl = useAwaitControl(getContractsOfPerson);
  const prepareContractControl = useAwaitControl(prepareContract);
  const { entries: contracts } = getContractsOfPersonControl.result();

  const previousSimulation = useMemo(
    () =>
      contracts?.find((contract) => ['INITIAL', 'REQUESTED'].includes(contract?.contractSituation)),
    [contracts],
  );

  useEffect(
    () => () => {
      proposalsControl.clear();
      prepareContractControl.clear();
    },
    [],
  );
  return (
    <Steps
      extraProps={{
        opportunity,
        previousSimulation,
        simulationAlert,
        selectedProposal,
        setSimulationAlert,
        setSelectedProposal,
      }}
      steps={[
        { id: 'selection', component: <Selection /> },
        { id: 'details', component: <Details /> },
        { id: 'success', component: <Success /> },
        { id: 'error', component: <Error /> },
      ]}
    />
  );
}
