// @flow

import React, { useEffect, useState } from 'react';
import Address from './Address';
import solveAddress, {
  SOLVE_ADDRESS_SUCCESS,
} from '~/store/solveAddress/action';
import updatePendencies from '~/screens/ContractDetails/Panels/Pendencies/components/actions/updatePendencies';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import getAddress, {
  ADDRESS_FROM_ZIPCODE_SUCCESS,
} from '~/store/addressFromZipcode/action';
type DocumentsContainerProps = {};

const AddressContainer = (props: DocumentsContainerProps) => {
  const [address, setAddress] = useState({});
  const onPressSend = fields => {
    props.dispatch(solveAddress({ ...fields, id: props.id }));
  };

  const onZipCodeSuccess = zipCode => {
    props.dispatch(getAddress(zipCode));
  };

  useEffect(() => {
    if (props.addressType === ADDRESS_FROM_ZIPCODE_SUCCESS) {
      setAddress(props.address.toJS());
    }
  }, [props.address, props.addressType]);

  return (
    <Address
      onEntryZipcodeSuccess={onZipCodeSuccess}
      {...props}
      address={address}
      loading={props.findLoading}
      onEnter={onPressSend}
    />
  );
};

function mapStateToProps(state) {
  return {
    address: state.getIn(['addressFromZipcode', 'payload']),
    addressType: state.getIn(['addressFromZipcode', 'type']),
    findLoading: state.getIn(['addressFromZipcode', 'isLoading']),
  };
}

export default compose(
  connect(mapStateToProps),
  updatePendencies('solveAddress', SOLVE_ADDRESS_SUCCESS),
)(AddressContainer);
