import React, { useMemo } from 'react';

import moment from 'moment';

import { Container } from './styled';

type UpdateDatesProps = {
  portabilityUpdateDate: string;
  marginUpdateDate: string;
  benefitStartDate?: string;
  medicalCertificateDate?: string;
}

type InfoDateProps = {
  label: string;
  formattedDate: string;
}

function InfoDate({ label, formattedDate }: InfoDateProps) {
  return (
    <span>
      {label}
      :
      {' '}
      <span>{formattedDate}</span>
    </span>
  );
}

const defaultDateFormat = 'DD [de] MMMM [de] YYYY, HH:mm[h]';

export default function UpdateDates({
  portabilityUpdateDate,
  marginUpdateDate,
  benefitStartDate,
  medicalCertificateDate,
}: UpdateDatesProps) {
  const diffNowAndBenefitStartDate = useMemo(() => {
    if (benefitStartDate) {
      const now = moment();
      const duration = moment.duration(now.diff(moment(benefitStartDate)));
      const years = duration.years();
      const months = duration.months();

      let formattedDiff = years && `${years} anos`;

      if (formattedDiff) {
        if (months) {
          formattedDiff += ` e ${months} meses`;
        }

        return formattedDiff;
      }

      return `${months} meses`;
    }

    return '';
  }, [benefitStartDate]);

  return (
    <Container>
      <InfoDate
        label="Últ. atualização margem"
        formattedDate={marginUpdateDate ? moment(marginUpdateDate).format(defaultDateFormat) : 'Indisponível'}
      />
      <InfoDate
        label="Últ. atualização portabilidade"
        formattedDate={portabilityUpdateDate ? moment(portabilityUpdateDate).format(defaultDateFormat) : 'Indisponível'}
      />
      {
        medicalCertificateDate && (
          <InfoDate
            label="Data da última perícia"
            formattedDate={moment(medicalCertificateDate).format('DD/MM/YYYY')}
          />
        )
      }
      {
        benefitStartDate && (
          <InfoDate
            label="Tempo de recebimento do benefício"
            formattedDate={diffNowAndBenefitStartDate}
          />
        )
      }
    </Container>
  );
}
