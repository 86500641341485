/* @flow */

export const TAGS_GET_REQUEST = 'TAGS_GET_REQUEST';
export const TAGS_GET_SUCCESS = 'TAGS_GET_SUCCESS';
export const TAGS_GET_FAILURE = 'TAGS_GET_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: TAGS_GET_SUCCESS,
      payload,
    });
  };
}

export function removeLocalTag(tag) {
  return (dispatch, getState) => {
    const tags = getState().getIn(['tagsGet', 'payload']);

    dispatch({
      type: TAGS_GET_SUCCESS,
      payload: tags.filter(t => {
        let id = tag.id || tag.tagTypeId;
        if (id) {
          return t.id !== id;
        }
        if (tag.name) {
          return t.name !== tag.name;
        }
        return true;
      }),
    });
  };
}

export function setLocalTag(tag) {
  return (dispatch, getState) => {
    let localTag = Object.assign({}, tag);
    const tags = getState().getIn(['tagsGet', 'payload']);
    const tagTypes = getState().getIn(['tagTypesGet', 'payload']);
    if (tag.id) {
      localTag.id = tag.id;
      localTag.tagType = tag.tagType || tagTypes.find(tt => tt.id === tag.id);
    } else {
      localTag.id = tag.name;
      localTag.tagType = { name: tag.name };
    }
    dispatch({
      type: TAGS_GET_SUCCESS,
      payload: [...tags, localTag],
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: TAGS_GET_FAILURE,
      payload: resource,
    });
  };
}

type Payload = {
  type: 'CONTRACT' | 'PERSON',
  externalId: number,
};

export default function getTags(data: Payload) {
  return (dispatch, getState) => {
    if (!data) {
      data = getState().getIn(['tagsGet', 'data']);
    }
    dispatch({
      type: TAGS_GET_REQUEST,
      data,
    });
  };
}
