/* @flow */

export const UPDATE_PDF_FILE_REQUEST = 'UPDATE_PDF_FILE_REQUEST';
export const UPDATE_PDF_FILE_SUCCESS = 'UPDATE_PDF_FILE_SUCCESS';
export const UPDATE_PDF_FILE_FAILURE = 'UPDATE_PDF_FILE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: UPDATE_PDF_FILE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: UPDATE_PDF_FILE_FAILURE,
      payload: resource,
    });
  };
}

export default function updateFile(params, data) {
  return dispatch => {
    dispatch({
      type: UPDATE_PDF_FILE_REQUEST,
      payload: params,
      data,
    });
  };
}
