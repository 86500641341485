import { uppercaseFirst } from '~/common';
import { toLower } from 'lodash';

export default (nameToFormat: string, wordsLimit?) => {
  if (!nameToFormat) return '';

  const splitName = nameToFormat?.split(' ');
  const finalWordsLimit =
    wordsLimit + splitName.slice(0, wordsLimit).filter((el) => el.length <= 2).length;
  const formattedName = splitName.map((el) => (el.length > 2 ? uppercaseFirst(el) : toLower(el)));
  return wordsLimit ? formattedName?.slice(0, finalWordsLimit).join(' ') : formattedName?.join(' ');
};
