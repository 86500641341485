const CREDIT_CARD_STATUS = {
  INITIAL: 'Simulação Iniciada',
  REQUESTED: 'Requerido',
  SIGNED: 'Assinado',
  PAID: 'Pago',
  CCBSIGNED: 'CCB Assinada',
  CANCELLED: 'Cancelado',
};

const CreditCardStatus = (props: any) => {
  if (props) {
    return CREDIT_CARD_STATUS[props.value] || CREDIT_CARD_STATUS[props] || null;
  }
  return null;
};

export default CreditCardStatus;
