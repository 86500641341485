import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: -16px;

  > div:not(.ant-collapse) {
    margin: 26px 29px 0;
    width: 65%;
  }
`;

export const Column = styled.div`
  flex: 1;
  flex-direction: column;
  margin: 0 10px;

  > div {
    margin-bottom: 10px;
    min-height: 40px;
  }

  > div:last-child {
    margin-bottom: 0;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: row;

  ${Column}:first-child {
    margin: 0 10px 0 0 !important;
  }
`;

export const ProductTagsContainer = styled.div`
  flex-direction: row;
  display: flex;

  > span {
    font-weight: 500;
    font-size: ${({ theme }) => theme.unit * 1.5}px;
  }
`;
