import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { endpoint } from '..';
import { HISCON_GET_REQUEST, success, failure } from './action';

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(HISCON_GET_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(id =>
      Observable.fromPromise(
        fetch(`${endpoint}/admin/personcustom/people/${id}/hiscon`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              'authenticationToken',
            )}`,
          },
        }),
      )
        .map(response => {
          switch (response.status) {
            case 200:
              return response.blob();
            case 404:
            case 204:
            default:
              throw new Error('Bad response from server');
          }
        })
        .mergeMap(response => Observable.fromPromise(response))
        .mergeMap(response =>
          Observable.of(
            success({
              uri: URL.createObjectURL(response),
            }),
          ),
        )
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
