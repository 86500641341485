import { message as showMessage } from 'antd';
import { first } from 'underscore';
import errors from '~/constants/Errors';

function content(parse) {
  return parse.reduce(
    (o, x) => {
      return typeof o === 'undefined' || o === null ? o : o[x];
    },
    { errors },
  );
}

function parseType(parse) {
  return ((res = content(parse)) => {
    switch (typeof res) {
      case 'object':
        return typeof res.default === 'undefined' ? parse.pop() : res.default;
      case 'undefined':
        return parse.pop();
      case 'string':
      default:
        return res;
    }
  })();
}

export default function dialogErrors(message) {
  if (typeof message === 'string') {
    showMessage.error(message);
    return;
  }

  if (typeof message !== 'object') return;
  if (message.length === 0) return;

  showMessage.error(parseType(['errors', first(message).text]));
}
