import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useAwaitControl } from 'react-redux-await-control';
import { compose } from 'recompose';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import useModals from '~/hooks/useModals';
import fetchOperationStepsByContract from '~/store/fetchOperationStepsByContract/action';
import { getContractDetails, updateOperationStep } from '~/store/contracts/actions';

import theme from '~/themes/aphroditeTheme/theme';
import { EnumMappers, SearchSelect } from '~/common';

import modalBuilder from '~/hoc/modalBuilder';
import styles from './ModalStep.Styles';

const { css, withStyles } = theme;
const { OperationStepType } = EnumMappers;
const { Option } = Select;

const MODAL_NAME = 'STEP_MODAL';

function ModalStep() {
  const [comment, setComment] = useState('');
  const [step, setStep] = useState(null);

  const { closeModal } = useModals() as any;

  const { id: contractId } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const steps = useSelector((state: any) =>
    state.getIn(['fetchOperationStepsByContract']).getIn(['payload']),
  );
  const [getContractControl, updateOperationStepControl] = useAwaitControl([
    getContractDetails,
    updateOperationStep,
  ]);
  const details = getContractControl.result();

  useEffect(() => {
    if (details?.operationStep) {
      setStep(details?.operationStep);
    }
  }, [details?.operationStep]);

  useEffect(() => {
    dispatch(fetchOperationStepsByContract(contractId));
  }, []);

  const handleSubmit = useCallback(() => {
    updateOperationStepControl.start({ contractId, operationStepType: step, comment });
    closeModal(MODAL_NAME)();
    getContractControl.start({ id: contractId });
  }, [step, comment]);

  const onValidation = useCallback(() => step !== details?.operationStep, [step, details]);

  return (
    <>
      <div {...css(styles.modal__header)}>
        <span {...css(styles.modal__title)}>Alterar passo da esteira</span>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={{ span: 24 }}>
            Passo da esteira:
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Selecione o novo passo"
              defaultValue={OperationStepType(details?.operationStep)}
              onChange={setStep}
              allowClear
              filterOption={(input, option) => SearchSelect(input, option.props.children)}
              optionFilterProp="children"
            >
              {steps.map((item) => (
                <Option key={item}>{OperationStepType(item)}</Option>
              ))}
            </Select>
          </Col>
          <Col xs={{ span: 24 }} style={{ marginTop: '18px' }}>
            Comentário:
            <Input.TextArea
              rows={2}
              placeholder="Escreva um comentário"
              onChange={(e) => setComment(e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '18px' }}>
          <Col xs={{ span: 4 }}>
            <Button type="primary" disabled={!onValidation()} onClick={handleSubmit}>
              Alterar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default modalBuilder(compose(withStyles(styles))(ModalStep), MODAL_NAME, {});
