import React, { useEffect, useMemo, useState } from 'react';

import { Steps } from '~/ui/components';
import { ContractValueList, OpportunityListItem } from '~/typings/entities/Opportunity';
import { useAwaitControl } from 'react-redux-await-control';
import { prepareContract, suggestedProposals } from '~/store/opportunities/actions';
import { getContractsOfPerson } from '~/store/people/actions';
import { Selection } from './Selection/Selection';
import { Details } from './Details/Details';
import { Error } from './Error/Error';
import { Success } from './Success/Success';

export type PortStepsProps = {
  opportunity: OpportunityListItem;
};

export function PortSteps({ opportunity }: PortStepsProps) {
  const [simulationAlert, setSimulationAlert] = useState(false);
  const [selectedContract, setSelectedContract] = useState<ContractValueList>(null);
  const proposalsControl = useAwaitControl(suggestedProposals);
  const getContractsOfPersonControl = useAwaitControl(getContractsOfPerson);
  const prepareContractControl = useAwaitControl(prepareContract);
  const { entries: contracts } = getContractsOfPersonControl.result();

  const previousSimulation = useMemo(
    () =>
      contracts?.find((contract) => ['INITIAL', 'REQUESTED'].includes(contract?.contractSituation)),
    [contracts],
  );

  useEffect(
    () => () => {
      proposalsControl.clear();
      prepareContractControl.clear();
    },
    [],
  );

  return (
    <Steps
      extraProps={{
        opportunity,
        selectedContract,
        setSelectedContract,
        previousSimulation,
        simulationAlert,
        setSimulationAlert,
      }}
      steps={[
        { id: 'selection', component: <Selection /> },
        { id: 'details', component: <Details /> },
        { id: 'success', component: <Success /> },
        { id: 'error', component: <Error /> },
      ]}
    />
  );
}
