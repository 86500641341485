const COMMISSIONING_TYPE = {
  NEW: 'Novo',
  REFIN: 'Refinanciamento',
  CREDITCARD: 'Cartão de Crédito',
  PORTABILITY: 'Portabilidade',
  REFIN_PORTABILITY: 'Refin da Portabilidade',
  PORTABILITY_WITHOUT_REFIN: 'Portabilidade sem Refin',
};

const CommissioningType = (props: any) => {
  if (props) {
    return COMMISSIONING_TYPE[props.value] || COMMISSIONING_TYPE[props] || null;
  }

  return null;
};

export default CommissioningType;
