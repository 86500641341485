import styled from 'styled-components';

export * from './CreditTransactions/CreditTransactions';
export * from './TransactionsTable/TransactionsTable';

export const TableContainer = styled.div`
  padding: 16px 0;
  min-height: calc(100vh - 600px);
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
