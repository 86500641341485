import styled, { css } from 'styled-components';
import { Layout } from 'antd';
import { SiderProps as AntSiderProps } from 'antd/lib/layout/Sider';
import { themeColor } from '~/common/utils/theme';

export type SiderProps = AntSiderProps & {
  fixed?: boolean | 1 | 0;
  side?: 'left' | 'right';
};

const getSiblingMargin = (
  width: number | string,
  collapsed?: boolean,
  collapsedWidth?: number | string,
) => {
  if (collapsed) {
    return typeof collapsedWidth === 'string' ? collapsedWidth : `${collapsedWidth || 80}px`;
  }

  return typeof width === 'string' ? width : `${width || 200}px`;
};

export const Sider = styled(Layout.Sider)<SiderProps>`
  z-index: 999;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  transition: all 0.2s;

  .ant-layout-sider-trigger {
    width: 32px !important;
    height: 32px !important;
    border-radius: 8px;
    position: absolute;
    ${({ side }) => (side === 'right' ? 'left' : 'right')}: -18px;
    top: 40px;
    z-index: 99;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.white.main};

    svg {
      color: #1a1a1a;
      width: 12px;
      height: 12px;
    }
  }

  ${({ fixed, side, width, collapsed, collapsedWidth }) =>
    fixed &&
    css`
      height: calc(100vh - 73px);
      ${side || 'left'}: 0;
      position: fixed;

      & ~ .ant-layout-content {
        margin-${side || 'left'}: ${getSiblingMargin(width, collapsed, collapsedWidth)};
      }
    `}
  
  &.ant-layout-sider-zero-width {
    & ~ .ant-layout-content {
      margin-${({ side }) => side || 'left'}: 0;
    }
  }
`;
