/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { GET_CONTRACT_COMMENTS_REQUEST, success, failure } from './action';

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(GET_CONTRACT_COMMENTS_REQUEST)
    .map(({ payload: { contractId } }) => contractId)
    .mergeMap((id) =>
      AjaxRequest.open(
        'get',
        `/admin/contractcustom/contracts/${id}/commentary`,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource || [])))
        .catch((err) => Observable.of(failure(err))),
    );

export default combineEpics(events);
