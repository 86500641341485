import React from 'react';

import { Item, ListContainer } from './ListGroup.styled';

type ItemType = {
  id: string;
  label: string;
};

interface IListGroupProps {
  items: ItemType[];
  onClickItem?: (item: ItemType) => void;
}

export function ListGroup({ items, onClickItem }: IListGroupProps) {
  return (
    <ListContainer>
      {items.map((item) => (
        <Item key={item.id} onClick={() => onClickItem(item)}>
          {item.label}
        </Item>
      ))}
    </ListContainer>
  );
}
