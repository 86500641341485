import React from 'react';

import {
  Container,
  Label,
  Info,
  EventList,
  Event,
  Dot,
  Line,
  IconContent,
} from './TimeLine.styled';

export type EventType = {
  label: string;
  agent: string;
  date: string;
};

interface ITimeLineProps {
  events: EventType[];
}

export function TimeLine({ events }: ITimeLineProps) {
  return (
    <Container>
      <EventList data-testid="time-line-events">
        {events.map((event, index) => (
          <Event key={index}>
            <IconContent>
              <Dot />
              <Line $isConected={index + 1 < events.length} />
            </IconContent>
            <div>
              <Label>{event.label}</Label>
              <Info>{`${event.agent} - ${event.date}`}</Info>
            </div>
          </Event>
        ))}
      </EventList>
    </Container>
  );
}
