import React from 'react';
import styled, { css } from 'styled-components';

export const Label = styled.span`
  color: ${props => {
    switch (props.type) {
      case 'FORMALIZATION':
        return props.theme.primaryColor;
      case 'PENDING':
        return props.theme.dangerColor;
      case 'RETENTION':
        return props.theme.warnningColorDark;
      default:
        break;
    }
  }};
`;
