import React, { useCallback } from 'react';
import { Button } from 'antd';

import useTimer from '~/hooks/useTimer';
import { TimerText, Timer } from '../styled';

type UpdateTimerProps = {
  nextUpdate: string;
  load: () => any;
  isLoading: boolean;
  disabled: boolean;
  updateEndTime: () => void;
};

export function UpdateTimer({
  nextUpdate,
  load,
  isLoading,
  updateEndTime,
  disabled,
}: UpdateTimerProps) {
  const timer = useTimer(nextUpdate, true, 1_000, load);

  const runLoad = useCallback(() => {
    updateEndTime();
    load();
  }, [load]);

  return (
    <Timer>
      <TimerText disabled={!!disabled}>{disabled ? '00:00:00' : timer}</TimerText>
      <Button
        loading={isLoading}
        disabled={isLoading || disabled}
        onClick={runLoad}
        type="primary"
        ghost
      >
        <span>Atualizar</span>
      </Button>
    </Timer>
  );
}
