import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.gray};
  padding: 14px 20px 12px 8px;

  :focus {
    outline: none;
  }
`;

export const TypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 200px;
`;

export const TagTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.gray5};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.gray5};
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  padding: 8px;
`;

export const ActionContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 3px;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ theme }) => theme.primaryColorLight};
    svg {
      fill: #fff;
    }
  }
`;
