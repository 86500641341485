import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { combineEpics } from 'redux-observable';
import keyBy from 'lodash/keyBy';

import { GET_CONTRACT_FILES_REQUEST, success, failure } from './action';

const downloadBlob = (imageUrl) => new Promise((resolve, reject) => {
  fetch(imageUrl, {
    method: 'GET',
    redirect: 'follow',
  })
    .then((response) => response.blob())
    .then((blob) => {
      resolve(blob);
    })
    .catch((err) => reject(err));
});

const getFile = (ajax, file, id, document = 'image') => {
  const getDocument = () => ajax
    .open(
      'get',
      `/admin/contractcustom/contracts/${id}/${document}?type=${file.type}&documenttype=${file.documentType}`,
      {},
      { responseType: document === 'pdf' ? 'blob' : 'application/json' },
    )('tudo')
    .mergeMap(({ response }) => Observable.of(response));

  let documentPipe = file.url ? Observable.of(file.url) : getDocument();

  if (file.url || document === 'imageurl') {
    documentPipe = documentPipe.mergeMap((response) => {
      let documentUrl;
      if (file.url) {
        documentUrl = file.url;
      } else {
        const responseJson = JSON.parse(response);
        documentUrl = responseJson.imageUrl;
      }
      return Observable.from(downloadBlob(documentUrl));
    });
  }

  return documentPipe
    .flatMap((mainBlob) => {
      const blob = mainBlob.slice(
        0,
        mainBlob.size,
        file.extension.includes('pdf')
          ? 'application/pdf'
          : `image/${file.extension.substring(1)}`,
      );
      blob.lastModifiedDate = new Date();
      blob.name = file.name + file.extension;
      return Observable.of({
        type: file.type,
        uri: URL.createObjectURL(blob),
        documentType: file.documentType,
        size: blob.size,
        exist: blob.size !== 0,
      });
    })
    .pipe(catchError((error) => Observable.of(error)));
};

const getFiles = (ajax, files, id) => forkJoin(
  ...files.map((file) => getFile(
    ajax,
    file,
    id,
    file.extension.includes('pdf') ? 'pdf' : 'imageurl',
  )),
);

const events = (action$, state$, { AjaxRequest }) => action$
  .ofType(GET_CONTRACT_FILES_REQUEST)
  .map(({ data: { files, id } }) => ({ files, id }))
  .mergeMap(({ files, id }) => getFiles(AjaxRequest, files, id)
    .flatMap((response) => Observable.of(
      success(
        {
          [id]: keyBy(response, (elem) => (elem.documentType ? elem.type + elem.documentType : elem.type)),
        },
        id,
      ),
    ))
    .catch((err) => Observable.of(failure(err))));

export default combineEpics(events);
