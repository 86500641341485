import styled, { css } from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { themeColor } from '~/common/utils/theme';

export const CommentContainer = styled.div`
  margin-top: 18px;
  padding: 16px 0;
  border-bottom: 1px dashed ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};

  .comment-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0;
      color: ${({ theme }) => theme.colors.element.secondary};

      &:first-child {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0;
        color: ${({ theme }) => theme.colors.element.secondary};
      }
    }
  }

  .info-box {
    background-color: ${themeColor('background.secondary', { dark: 'background.tertiary' })};
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 8px 6px;
    margin: 14px 0;
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.element.secondary};

    > * {
      margin-right: 5px;

      &:last-child {
        color: ${({ theme }) => theme.colors.element.secondary};
      }
    }

    > span {
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0;
    }
  }

  .comment-tags {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};
      height: 20px;
      display: flex;
      align-items: center;
    }
  }

  .contract-list {
    background-color: #fbfbfb;
    height: 27px;
    display: flex;
    align-items: center;
    padding: 4px;

    svg {
      width: 18px;
      height: 18px;
    }
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #4b4b4b;
    }
  }
`;

export const AgentComment = styled.div`
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
`;

export const FormCommentContainer = styled.div`
  padding-bottom: 24px;
  border-bottom: 0.5px solid #e3e3e390;
  width: 100%;

  & .actions-section {
    display: flex;
    gap: 8px;
    align-items: center;
    padding-top: 12px;
    border-top: 0.5px solid #e3e3e390;

    > div {
      background-color: #fdf3fd;
      border-radius: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0 12px;
      height: 32px;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  & .preview {
    display: flex;
    gap: 8px;
    margin: 12px 0;
    align-items: center;
    flex-wrap: wrap;

    > span {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      color: #1d9bf0;
    }

    svg {
      color: #1d9bf0;
      width: 14px;
      height: 14px;
    }
  }

  textarea {
    border: none;
    resize: none;
    margin-bottom: 20px;
  }
`;

interface MenuItemProps {
  noPadding?: boolean;
}

export const MenuItem = styled(Menu.Item)<MenuItemProps>`
  display: flex;
  align-items: center;
  border-bottom: 0.602484px solid rgba(217, 217, 217, 0.43);
  min-width: 170px;
  font-style: normal;
  font-weight: 500 !important;

  &:last-of-type {
    border: none;
  }

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0 !important;
    `}
`;

export const SubMenu = styled(Menu.SubMenu)`
  span:first-child {
    font-weight: 500;
  }

  .ant-dropdown-menu-submenu-arrow > i {
    color: ${({ theme }) => theme.colors.primary.main};
    font-size: 14px !important;
  }
`;

export const InfoTooltip = styled.div`
  color: #ffffff;
  padding: 5px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  font-weight: 400;

  > h3 {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    svg {
      color: #ffffff !important;
      height: 18px;
      width: 18px;
      margin-right: 6px;
    }
  }
`;

export const InfoPopover = styled.div`
  padding: 5px;
  min-width: 211px;

  > h3 {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #4b4b4b;

    svg {
      color: #595959 !important;
      height: 14px;
      width: 14px;
      margin-right: 6px;
    }
  }

  > div {
    height: 32px;
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0;
      font-weight: 400;
      color: #4b4b4b;
    }

    svg {
      height: 16px;
      width: 16px;
      margin-right: 16px;
      color: transparent;
    }

    &:first-of-type {
      span {
        background: #bae7ff;
        border-radius: 0 10px 10px 0;
        color: #002766;
        font-weight: 600;
        padding: 2px 10px 0 4px;
        height: 20px;
      }
    }
  }
`;

export const CommentWrapper = styled.div`
  max-height: calc(100vh - 430px);
  overflow-y: scroll;
  padding-right: 16px;
  min-height: 550px;

  ::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 16px;
    width: 16px;
  }
`;

export const CustomDropdown = styled(Dropdown).attrs({
  overlayStyle: {
    overflow: 'auto',
    maxHeight: 500,
  },
})``;

export const MenuSearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 4px 12px;
`;
