import React, { PureComponent } from 'react';
import { Button, Tooltip } from 'antd';

import { ContainerControl, Title, ControlBar } from './styled';

export type ControlImageProps = {
  imagePath: String,
  title?: string,
  tabIndex: number,
};

const SCALE_DEAFAULT = 1;
const ROTATE_DEFAULT = 0;
const RIGHT_ROTATION = 90;
const LEFT_ROTATION = -90;
const ZOOM_IN = 1.1;
const ZOOM_OUT = 0.9;

class ControlImage extends PureComponent<ControlImageProps> {
  state = {
    scaleDim: SCALE_DEAFAULT,
    rotateDeg: ROTATE_DEFAULT,
    tempRotate: ROTATE_DEFAULT,
  };

  componentDidMount(): void {
    setTimeout(() => {
      if (this.img) {
        this.img.style.height = getComputedStyle(this.img).width;
      }
    }, 500);
  }

  handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        this.scale(ZOOM_OUT);
        break;
      case 'ArrowUp':
        this.scale(ZOOM_IN);
        break;
      case 'ArrowLeft':
        this.rotate(LEFT_ROTATION);
        break;
      case 'ArrowRight':
        this.rotate(RIGHT_ROTATION);
        break;
      case 'Backspace':
        this.setDefault();
        break;
      default:
    }
  };

  handleScrolling = (e) => {
    if (e.deltaY > 0) {
      this.scale(ZOOM_OUT);
    }
    if (e.deltaY < 0) {
      this.scale(ZOOM_IN);
    }
  };

  rotate = (deg) => {
    clearTimeout(this.rotateTimeout);

    this.setState({ tempRotate: this.state.tempRotate + deg });

    this.rotateTimeout = setTimeout(() => {
      this.setState({
        rotateDeg: this.state.tempRotate,
      });
    }, 250);
  };

  // Limita o tamanho da imagem
  canScale = (dim) => {
    const { scaleDim } = this.state;
    return (scaleDim < 4 && dim > 1) || (scaleDim > 0.5 && dim < 1);
  };

  scale = (dim) => {
    if (this.canScale(dim)) {
      this.setState({
        scaleDim: this.state.scaleDim * dim,
      });
    }
  };

  setDefault = () => {
    this.setState({
      scaleDim: SCALE_DEAFAULT,
      rotateDeg: ROTATE_DEFAULT,
    });
  };

  render() {
    const { title, imagePath, tabIndex } = this.props;

    const { scaleDim, rotateDeg } = this.state;

    const actions = [
      {
        title: 'Girar para esquerda',
        shape: 'circle',
        icon: 'undo',
        action: () => this.rotate(LEFT_ROTATION),
      },
      {
        title: '- zoom',
        shape: 'circle',
        icon: 'zoom-out',
        action: () => this.scale(ZOOM_OUT),
      },
      {
        title: 'Desfazer',
        shape: 'circle',
        icon: 'rollback',
        action: () => this.setDefault(),
      },
      {
        title: '+ zoom',
        shape: 'circle',
        icon: 'zoom-in',
        action: () => this.scale(ZOOM_IN),
      },
      {
        title: 'Girar para direita',
        shape: 'circle',
        icon: 'redo',
        action: () => this.rotate(RIGHT_ROTATION),
      },
    ];

    const zIndex = 10000;

    return (
      <ContainerControl
        onKeyDown={this.handleKeyDown}
        onWheel={this.handleScrolling}
        tabIndex={tabIndex}
      >
        <Title>
          <span>{title}</span>
        </Title>
        <img
          ref={(ref) => {
            this.img = ref;
          }}
          style={{ transform: `rotate(${rotateDeg}deg) scale(${scaleDim})` }}
          src={imagePath}
          alt={title}
        />
        <ControlBar>
          {actions.map((item, i) => (
            <Tooltip placement="bottom" title={item.title} overlayStyle={{ zIndex }} key={i}>
              <Button shape={item.shape} icon={item.icon} onClick={item.action} />
            </Tooltip>
          ))}
        </ControlBar>
      </ContainerControl>
    );
  }
}

export default ControlImage;
