import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { getTheme } from '~/common/utils/theme';

type ThemeContextData = {
  setTheme(name: string): void;
  themeName: string;
  theme: any;
  themeConditional(variable: string, altVariables?: { [key: string]: string }): string;
};

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

function ThemeProvider({ children }: PropsWithChildren<{}>) {
  const [themeName, setThemeName] = useState<string>(localStorage.getItem('theme'));

  const theme = useMemo(() => getTheme(themeName), [themeName]);

  const handleSetThemeName = useCallback((name) => {
    localStorage.setItem('theme', name);
    setThemeName(name);
  }, []);

  const themeConditional = useCallback(
    (variable: string, altVariables?: { [key: string]: string }) =>
      altVariables?.[theme?.name] || variable,
    [theme],
  );

  return (
    <ThemeContext.Provider
      value={{ theme, themeName, setTheme: handleSetThemeName, themeConditional }}
    >
      <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  );
}

function useTheme(): ThemeContextData {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used inside of ThemeProvider');
  }

  return context;
}

export { ThemeProvider, useTheme };
