/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { CONTRACT_OFFERS_FETCH_REQUEST, success, failure } from './action';

const fetchContractsOffers = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(CONTRACT_OFFERS_FETCH_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(payload =>
      AjaxRequest.open('get', '/integrations/vendingmachine/values', payload)(
        'tudo',
      )
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(fetchContractsOffers);
