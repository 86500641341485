import styled from 'styled-components';

export const Wrapper = styled.div`
  > div {
    margin-bottom: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TagsWrapper = styled.div`
  > div {
    padding: 0;
    margin: -5px;
  }
`;

export const PersonPresentationContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  > a {
    color: #EB2F96;
    font-size: 24px;
    font-weight: bold;
  }
  
  > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #595959;
  }
  
  div:last-child {
    margin: 5px -2px 0;
  }
`;
