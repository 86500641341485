import { Map, List } from 'immutable';

import {
  FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_REQUEST,
  FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_FAILURE,
  FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_SUCCESS,
} from './action';

const initialState = Map({
  type: '',
  payload: [{ name: 'Banco do Brasil', bankNumber: '001' }],
});

function reducer(state = initialState, action) {
  const { type } = action;

  if (type === FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => true)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], initialState.getIn(['payload']));
  }

  if (type === FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_FAILURE) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], payload);
  }

  if (type === FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_SUCCESS) {
    const { payload } = action;
    return state.updateIn(['type'], () => type).setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
