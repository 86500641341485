/* @flow */

export const CANCEL_CONTRACT_SIGNATURE_REQUEST =
  'CANCEL_CONTRACT_SIGNATURE_REQUEST';
export const CANCEL_CONTRACT_SIGNATURE_SUCCESS =
  'CANCEL_CONTRACT_SIGNATURE_SUCCESS';
export const CANCEL_CONTRACT_SIGNATURE_FAILURE =
  'CANCEL_CONTRACT_SIGNATURE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: CANCEL_CONTRACT_SIGNATURE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: CANCEL_CONTRACT_SIGNATURE_FAILURE,
      payload: resource,
    });
  };
}

export default function refuseContract(contractId, password) {
  return dispatch => {
    dispatch({
      type: CANCEL_CONTRACT_SIGNATURE_REQUEST,
      payload: {
        contractId,
        password,
      },
    });
  };
}
