// @flow
import React from 'react';
import { Wizard } from 'react-use-wizard';

import modalBuilder from '~/hoc/modalBuilder';
import type { ModalBuilderProps } from '~/hoc/modalBuilder';

import type { Contract } from '~/typings/entities/contract';

import Calculator from './Calculator';
import FeedBack from './FeedBack';
import PreventError from './PreventError';
import Result from './Result';

type RefinAdjustmentProps = {
  contract: Contract,
  externalId: number,
} & ModalBuilderProps;

function RefinAdjustment({
  contract,
  externalId,
  closeModal,
}: RefinAdjustmentProps) {
  return (
    <Wizard>
      <Calculator id={externalId} contract={contract} />
      <Result id={externalId} />
      <PreventError id={externalId} contract={contract} cancel={closeModal} />
      <FeedBack id={externalId} cancel={closeModal} />
    </Wizard>
  );
}

export default modalBuilder(RefinAdjustment, 'REFIN_ADJUSTMENT', {
  height: 287,
  okText: 'Criar',
});
