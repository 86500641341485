import React, { useCallback, useState } from 'react';

import { Search } from '~/ui/assets/icons';
import { Container, Input } from './SearchBar.styled';

interface ISearchBarProps {
  placeholder?: string;
  onChange?: (value: string) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function SearchBar({ placeholder = 'Buscar', onChange, onKeyPress }: ISearchBarProps) {
  const [value, setValue] = useState('');

  const handleOnChange = useCallback((e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  }, []);

  return (
    <Container>
      <Search
        className="action-icon"
        width={20}
        height={20}
        color="#727272"
        data-testid="search-bar-icon"
      />
      <Input
        placeholder={placeholder}
        onChange={handleOnChange}
        value={value}
        onKeyPress={onKeyPress}
      />
    </Container>
  );
}
