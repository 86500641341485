import styled from 'styled-components';
import { Button } from '~/ui/components';

export const Wrapper = styled.div`
  margin-top: 28px;
  justify-content: end;
  display: none;
  width: -webkit-fill-available;

  @media (max-width: 1200px) {
    display: flex;
    gap: 16px;
  }
`;

export const DotsButton = styled(Button).attrs({
  variant: 'text',
  color: 'primary',
  iconOnly: true,
  icon: 'Dots',
  size: 'sm',
})`
  align-items: start;
`;

export const ContentWrapper = styled.div`
  min-width: 240px;

  .side-actions {
    .main-button {
      pointer-events: none;
    }

    .ant-collapse-header {
      display: none;
    }

    .ant-collapse-content-box {
      padding: 0;
      background-color: white;

      > div {
        margin: 0;

        &:last-of-type {
          margin-top: 10px;
        }
      }
    }

    .ant-collapse-content {
      padding: 0;
      background-color: white;
    }
  }

  .other-options {
    & > div {
      margin: 0;
      padding: 6px 24px 12px 22px;
    }

    & > div:nth-of-type(1) {
      padding: 10px 5px 5px 22px;
      margin: 10px 0;
      pointer-events: none;
    }
  }
`;
