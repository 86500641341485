import React, { useCallback } from 'react';

import { useModal } from '~/hooks/useModal';
import { PreviewModal } from '~/components';
import { HistoryImage, HistoryItem, ImageContainer, FilePDF } from './FileHistoryModal.styled';

type ImageHistoryProps = {
  file: any;
};

export function ImageHistory({ file }: ImageHistoryProps) {
  const { openModal } = useModal();

  const isPdf = useCallback((file) => file?.extension === '.pdf', []);

  const handlePreviewImage = () => {
    openModal(<PreviewModal url={file.url} isPdf={isPdf(file)} withControls />, {
      width: 'auto',
      noBackground: true,
    });
  };

  return (
    <HistoryItem className="history-item">
      <ImageContainer onClick={handlePreviewImage}>
        {file.extension === '.pdf' ? (
          <FilePDF width={38} height={48} />
        ) : (
          <HistoryImage src={file?.url} />
        )}
      </ImageContainer>
    </HistoryItem>
  );
}
