import React, { useMemo } from 'react';
import { InfoGrid, Tag } from '~/ui/components';

import { ClixClient } from '~/typings/entities/Clix';
import _ from 'lodash';
import { useAwaitControl } from 'react-redux-await-control';
import { getScores } from '~/store/clix/actions';
import moment from 'moment';
import { Money } from '~/common';
import masked from '~/common/masked';
import { useTheme } from 'styled-components';

type CleanInfoProps = {
  info: ClixClient;
  loading?: boolean;
};

const StatusColorMap = {
  Contratado: 'positive',
  Aprovado: 'positive',
  Reprovado: 'negative',
};

export function CleanInfo({ info, loading }: CleanInfoProps) {
  const getScoresControl = useAwaitControl(getScores);
  const score = getScoresControl.result();
  const { colors } = useTheme();

  const accountStatus = useMemo(() => {
    const palette = StatusColorMap[info.status] || 'alert';
    const textColor = colors?.[palette]?.primaryAlt;
    const bgColor = colors?.[palette]?.secondary;

    if (info.status) {
      return (
        <Tag small rounded textColor={textColor} bgColor={bgColor}>
          {info.status.toUpperCase()}
        </Tag>
      );
    }

    return '-';
  }, [info]);

  const list = useMemo(() => {
    const facialBiometries = _.uniqBy(info?.facialBiometries, (e) => e.facialValidationType)?.map(
      ({ score, facialValidationType }) => ({
        name: `Verificado com ${facialValidationType}`,
        value: (
          <Tag rounded colorPalette="gray">
            {score}% compatível
          </Tag>
        ),
      }),
    );

    const renderScoreInfo = (score) => (
      <div>
        <p>
          <strong>Data da última consulta:</strong>{' '}
          {score?.dateSearch ? moment(score?.dateSearch).format('DD/MM/YY') : '-'}
        </p>
        <p>
          <strong>Origem do dado:</strong> {score?.employer || '-'}
        </p>
      </div>
    );

    return [
      {
        name: 'Status da conta',
        value: accountStatus,
      },
      {
        name: 'Limite total',
        copyable: true,
        value: info?.total ? Money(info?.total) : '-',
        copyText: info?.total ? Money(info?.total) : '-',
      },
      {
        name: 'Saldo disponível',
        copyable: true,
        value: info?.avaliable ? Money(info?.avaliable) : '-',
        copyText: info?.avaliable ? Money(info?.avaliable) : '-',
      },
      {
        name: 'Dias de atraso',
        copyable: true,
        value: info?.daysOfDelay ? info?.daysOfDelay : '-',
        copyText: info?.daysOfDelay || '-',
      },
      {
        name: 'Data da contratação',
        value: info?.signatureDate ? moment(info?.signatureDate).format('DD/MM/YYYY') : '-',
      },
      {
        name: 'Score de aprovação',
        value: score?.score || '-',
        info: score?.score
          ? {
              title: 'Score de aprovação',
              content: score?.score ? renderScoreInfo(score) : 'Sem score',
            }
          : null,
      },
      ...facialBiometries,
      {
        name: 'Vencimento',
        copyable: true,
        value: info?.paymentDay ? masked.toDate(info?.paymentDay) : '-',
        copyText: info?.paymentDay ? masked.toDate(info?.paymentDay) : '-',
      },
    ];
  }, [info, score, accountStatus]);

  return (
    <InfoGrid
      loading={loading}
      list={list}
      grid="24% 24% 24% 24%"
      shimmerLength={4}
      responsive={{ 1350: '30% 30% 30%', 1168: '48% 48%' }}
    />
  );
}
