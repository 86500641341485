export default function style({ unit }) {
  // const color = "#636878";
  // const fontFamily = "Rubik, sans-serif";

  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: unit * 2,
    },
    table: {
      margin: 0,
      width: '100%',
    },
    textCenter: {
      textAlign: 'center !important',
    },
    status: {
      textAlign: 'center',
      padding: unit * 3,
    },
  };
}
