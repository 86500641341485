const LOAN_STATUS = {
  INITIAL: 'Simulação iniciada',
  REQUESTED: 'Requerido',
  UNDERANALYSIS_BO: 'Sob análise do backoffice',
  PORTABILITY_PAID: 'Portabilidade Paga',
  PAID: 'Pago',
  UNDERANALYSIS_BO_II: 'Sob análise do backoffice II',
  CANCELLED_BY_SYSTEM: 'Cancelado automaticamente',
  CANCELLED_BY_USER: 'Cancelado pelo usuário',
  CANCELLED_BO: 'Cancelado pelo backoffice',
  READYFORTYPING: 'Pronto para digitação',
  TYPED: 'Digitado',
  REFUSEDBANK: 'Recusada pelo banco',
  WAITINGCCBSIGNATURE: 'Aguardando assinatura da CCB',
  UNDERANALYSIS_BANK: 'Em análise pelo banco',
};

function LoanStatus(props: any) {
  if (props) {
    return LOAN_STATUS[props.value] || LOAN_STATUS[props] || null;
  }

  return null;
}

export default LoanStatus;
