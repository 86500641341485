import React from 'react';
import { Tooltip } from '~/ui/components';

import { Info, InfoTitle, InfoValue } from './InfoItem.styled';

type TicketInfoItemProps = {
  title: string;
  value: any;
  fullWidth?: boolean;
};

export function InfoItem({ title, value, fullWidth }: TicketInfoItemProps) {
  return (
    <Info fullWidth={fullWidth}>
      <InfoTitle>{title}</InfoTitle>
      <InfoValue fullWidth={fullWidth}>
        {typeof value === 'string' && value?.length > 21 && !fullWidth ? (
          <Tooltip content={value} placement="bottomLeft">
            {value}
          </Tooltip>
        ) : (
          value || '-'
        )}
      </InfoValue>
    </Info>
  );
}
