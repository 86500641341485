import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PersonsTableContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const LinkButton = styled(Link)``;
