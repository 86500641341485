import { put, takeLatest } from 'redux-saga/effects';
import takeRequest from '~/store/takeRequest';

import * as PeopleActions from './actions';

export function* reloadPersonDetail({ payload, meta }: any) {
  const id = meta?.payload?.id || payload?.id;
  yield put(PeopleActions.getPersonDetail.start({ id }));
}

export function* reloadPersonImage({ meta }: any) {
  yield put(
    PeopleActions.getPersonImage.start(
      { id: meta.payload?.id, type: 'DOCUMENT_FRONT' },
      { actionId: 'DOCUMENT_FRONT' },
    ),
  );

  yield put(
    PeopleActions.getPersonImage.start(
      { id: meta.payload?.id, type: 'DOCUMENT_BACK' },
      { actionId: 'DOCUMENT_BACK' },
    ),
  );

  yield put(
    PeopleActions.getPersonImage.start(
      { id: meta.payload?.id, type: 'FACE_FRONT' },
      { actionId: 'FACE_FRONT' },
    ),
  );
}

export function* reloadComments({ payload: { personId } }: any) {
  yield put(PeopleActions.listPersonComments.start({ personId }));
}

export function* peopleSaga() {
  yield takeRequest(PeopleActions.updatePersonInfo);
  yield takeRequest(PeopleActions.getPersonDetail);
  yield takeRequest(PeopleActions.listPeople);
  yield takeRequest(PeopleActions.listRegistrationStatus);
  yield takeRequest(PeopleActions.getPersonResetHistory);
  yield takeRequest(PeopleActions.getResetReasons);
  yield takeRequest(PeopleActions.requestResetPerson);
  yield takeRequest(PeopleActions.resetPerson);
  yield takeRequest(PeopleActions.uploadHiscon);
  yield takeRequest(PeopleActions.uploadConcession);
  yield takeRequest(PeopleActions.uploadMedicalCertificate);
  yield takeRequest(PeopleActions.getPersonImage);
  yield takeRequest(PeopleActions.updateHumanizePerson);
  yield takeRequest(PeopleActions.getContractsOfPerson);
  yield takeRequest(PeopleActions.addCommentToPerson);
  yield takeRequest(PeopleActions.updateCommentToPerson);
  yield takeRequest(PeopleActions.listPersonComments);
  yield takeRequest(PeopleActions.getINSSOpportunity);
  yield takeRequest(PeopleActions.getFGTSOpportunity);
  yield takeRequest(PeopleActions.getDatavalid);
  yield takeRequest(PeopleActions.getPersonPaychecks);
  yield takeRequest(PeopleActions.getPersonPaycheckFile);
  yield takeRequest(PeopleActions.getPersonRegistryFiles);
  yield takeRequest(PeopleActions.approvePersonRegistry);
  yield takeRequest(PeopleActions.reprovePersonRegistry);
  yield takeRequest(PeopleActions.requestAgreementPassword);
  yield takeRequest(PeopleActions.updateHumanizePersonComment, {
    type: 'debounce',
    debounceTime: 2_500,
  });
  yield takeRequest(PeopleActions.getPersonRegistrationLogs);
  yield takeRequest(PeopleActions.sendRevokeAgreementPasswordToken);
  yield takeRequest(PeopleActions.validateRevokeAgreementPasswordToken);
  yield takeRequest(PeopleActions.revokeAgreementPassword);
  yield takeRequest(PeopleActions.getAgreementPasswordHistory);
  yield takeRequest(PeopleActions.getJourneyHistory);

  yield takeLatest(PeopleActions.updatePersonInfo.success.toString(), reloadPersonDetail);
  yield takeLatest(PeopleActions.requestResetPerson.success.toString(), reloadPersonDetail);
  yield takeLatest(PeopleActions.resetPerson.success.toString(), reloadPersonDetail);
  yield takeLatest(PeopleActions.resetPerson.success.toString(), reloadPersonImage);
  yield takeLatest(PeopleActions.addCommentToPerson.success.toString(), reloadComments);
  yield takeLatest(PeopleActions.updateCommentToPerson.success.toString(), reloadComments);
  yield takeLatest(PeopleActions.reprovePersonRegistry.success.toString(), reloadPersonDetail);
  yield takeLatest(PeopleActions.approvePersonRegistry.success.toString(), reloadPersonDetail);
}
