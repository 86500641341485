import styled, { css } from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

interface Disabled {
  disabled?: boolean;
}

export type GridProps = {
  grid?: string;
  lg?: string;
  md?: string;
  sm?: string;
  responsive?: { [key: number]: string };
};

export const Container = styled.div<GridProps>`
  ${({ grid, lg, md, sm }) => css`
    display: grid;
    grid-template-columns: ${grid || '20% 20% 20% 20%'};
    gap: 34px;

    a {
      text-decoration: underline;
    }

    ${({ responsive }) =>
      Object.keys(responsive || {})
        .reverse()
        .map(
          (key) => css`
            @media (max-width: ${key}px) {
              grid-template-columns: ${responsive[key]};
            }
          `,
        )}
  `}
`;

export const Group = styled.div<Disabled>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const GroupName = styled(Typography).attrs({
  type: 'caption',
  element: 'span',
  weight: 500,
})`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
`;

export const GroupValue = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: theme.colors.element.secondary,
  element: 'div',
}))`
  display: flex;
  align-items: center;
  gap: 4px;
  word-break: break-word;
`;
