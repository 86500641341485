import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useParams } from 'react-router';

import { Col, Row } from 'antd';

import modalBuilder from '~/hoc/modalBuilder';
import {
  getContractDetails,
  updateFinancialInstitutionKey,
  updateFinancialInstitutionKeyPort,
} from '~/store/contracts/actions';
import InputLabel from '~/components/InputLabel';

const MODAL_NAME = 'UPDATE_CONTRACT_NUMBER';

type DataValidProps = {
  type: 'REFIN_PORT' | 'OTHERS';
  initialValue: string;
  closeModal: () => void;
};

const REFIN_PORT = 'REFIN_PORT';
const OTHERS = 'OTHERS';

const ContractTypes = {
  REFIN_PORT: {
    title: 'N do contrato (refin da port)',
    fieldName: 'financialInstitutionKeyPort',
  },
  OTHERS: {
    title: 'N do contrato',
    fieldName: 'financialInstitutionKey',
  },
};

function UpdateContractNumber({ type = 'OTHERS', initialValue, closeModal }: DataValidProps) {
  const { id: contractId } = useParams<{ id: string }>();

  const { title, fieldName } = useMemo(() => ContractTypes[type], [type]);

  const [updateContractControl, updateContractPortControl, getContractControl] = useAwaitControl([
    updateFinancialInstitutionKey,
    updateFinancialInstitutionKeyPort,
    getContractDetails,
  ]);

  const update = useCallback(
    (value) => {
      const params = { contractId, [fieldName]: value };
      if (type === REFIN_PORT) {
        updateContractPortControl.start(params);
      } else {
        updateContractControl.start(params);
      }
    },
    [type, contractId, fieldName],
  );

  const updateContractOk = updateContractControl.isSuccessful();
  const updateContractPortOk = updateContractPortControl.isSuccessful();

  useEffect(() => {
    if (updateContractOk && type === OTHERS) {
      closeModal?.();
      getContractControl.start({ id: contractId });
      updateContractControl.clear();
    }

    if (updateContractPortOk && type === REFIN_PORT) {
      closeModal?.();
      getContractControl.start({ id: contractId });
      updateContractPortControl.clear();
    }
  }, [updateContractOk, updateContractPortOk]);

  return (
    <Row>
      <Col xs={{ span: 12 }}>
        <InputLabel title={title} initialValue={initialValue} onSubmit={update} />
      </Col>
    </Row>
  );
}

export default modalBuilder(UpdateContractNumber, MODAL_NAME, {
  title: 'Alterar número do contrato',
  centered: true,
});
