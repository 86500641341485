import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';
import { Icon, Modal } from 'antd';

import { CloseCircle, Loading } from '~/ui/assets/icons';
import * as Icons from '~/ui/assets/icons';
import { useDrawer } from '~/hooks/useDrawer';
import {
  TransactionStatusPalette,
  TransactionStatusString,
  TransactionType,
  TransactionTypeIcon,
} from '~/typings/enums/Clix';
import { formatName, Money } from '~/common';
import masked from '~/common/masked';

import { Button, Tag } from '~/ui/components';
import {
  getTransactionBojo,
  getTransactionDetails,
  getTransactionFile,
} from '~/store/clix/actions';
import { getPersonDetail } from '~/store/people/actions';
import { useTheme } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { useModal } from '~/hooks/useModal';
import { DownloadImageModal } from '~/components';
import {
  TransactionHeader,
  TransactionTitle,
  ActionButtons,
  TransactionDate,
  SectionTitle,
  InfoGroup,
  InfoLabel,
  InfoValue,
  TransactionRecipient,
  InstallmentsInfo,
  TotalValue,
  AdditionalInfo,
  LoadingContainer,
  CircleIcon,
  TransactionDetailsWrapper,
  TopAlertsWrapper,
  Section,
  InfoRow,
  RecipientValue,
  AdditionalInfoTitle,
  CancelledDetails,
  InfoButton,
} from './TransactionDetails.styled';

interface TransactionDetailsProps {
  id: string;
}

export function TransactionDetails({ id }: TransactionDetailsProps) {
  const { closeDrawer } = useDrawer();
  const { toPercentage } = masked;
  const theme = useTheme();
  const { openModal } = useModal();

  const getTransactionBojoControl = useAwaitControl(getTransactionBojo);
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getClixTransactionDetailsControl = useAwaitControl(getTransactionDetails);
  const getClixTransactionFile = useAwaitControl(getTransactionFile);

  const personDetails = getPersonDetailsControl.result();
  const transactionDetails = getClixTransactionDetailsControl.result();
  const personDetailsLoading = getPersonDetailsControl.isRunning();
  const transactionLoading = getClixTransactionDetailsControl.isRunning();
  const fileLoading = getClixTransactionFile.isRunning();
  const fileResult = getClixTransactionFile.result();
  const fileError = getClixTransactionFile.hasFailure();
  const bojo = getTransactionBojoControl.result();
  const bojoLoading = getTransactionBojoControl.isRunning();
  const bojoSuccess = getTransactionBojoControl.isSuccessful();

  const loading = useMemo(
    () => personDetailsLoading || transactionLoading,
    [personDetailsLoading, transactionLoading],
  );

  const getTransactionBiometryFile = useCallback(() => {
    getClixTransactionFile.start({ id, document: personDetails.document, type: 'BIOMETRY' });
  }, [id, personDetails]);

  const getTransactionCCBFile = useCallback(() => {
    getClixTransactionFile.start({ id, document: personDetails.document, type: 'CCB' });
  }, [id, personDetails]);

  const handleDownloadBojo = useCallback(() => {
    getTransactionBojoControl.start({ document: personDetails.document, id });
  }, [personDetails]);

  const openFile = useCallback((fileResult: any) => {
    if (fileResult?.type === 'CCB') {
      const fileName = `ccb-transaction-${id}.pdf`;
      const fileData = `data:application/pdf;base64,${fileResult?.base64}`;

      openModal(
        <a download={fileName} href={fileData} title="Baixar arquivo">
          Baixar arquivo ({fileName})
        </a>,
      );
    } else {
      const name = formatName(personDetails?.name, 2);

      openModal(
        <DownloadImageModal
          url={`data:image/png;base64,${fileResult?.base64}`}
          fileName={`Foto assinatura - ${name} - #${id}`}
        />,
        {
          width: 450,
        },
      );
    }
  }, []);

  const renderTransactionStatus = useCallback(() => {
    const colorPalette = TransactionStatusPalette[transactionDetails.status];
    const textColor = theme.colors?.[colorPalette]?.primaryAlt;
    const bgColor = theme.colors?.[colorPalette]?.secondary;
    return (
      <Tag small rounded textColor={textColor} bgColor={bgColor}>
        {TransactionStatusString[transactionDetails.status] || '-'}
      </Tag>
    );
  }, [transactionDetails]);

  const IconComponent = useMemo(() => {
    const iconName = TransactionTypeIcon[transactionDetails.type];
    return iconName && Icons[iconName] ? Icons[iconName] : Icons.Paste;
  }, [transactionDetails]);

  useEffect(() => {
    if (fileResult?.url && !fileError) {
      window.open(fileResult, '_blank');
    }

    if (fileResult?.base64 && !fileError) {
      openFile(fileResult);
    }
  }, [fileResult]);

  useEffect(() => {
    if (bojoSuccess) {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${bojo}`;
      a.download = `bojo-transaction-${transactionDetails.id}.zip`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [bojoSuccess]);

  useEffect(() => {
    if (personDetails.document && id) {
      getClixTransactionDetailsControl.start({ id, document: personDetails.document });
    }

    return () => {
      getClixTransactionFile.clear();
      getTransactionBojoControl.clear();
    };
  }, [id, personDetails]);

  return (
    <>
      {!!loading && (
        <LoadingContainer>
          <Loading fill={theme.colors.brand.primary} height={45} width={45} />
        </LoadingContainer>
      )}

      {!!transactionDetails && (
        <TransactionDetailsWrapper>
          <TransactionHeader>
            <CircleIcon>
              <IconComponent width={12} height={12} />
            </CircleIcon>

            <TransactionTitle>{TransactionType[transactionDetails?.type]}</TransactionTitle>

            <CloseCircle
              width={25}
              height={25}
              className="close-icon"
              onClick={() => closeDrawer('transactionDetails')}
            />
          </TransactionHeader>

          <TopAlertsWrapper>
            {renderTransactionStatus()}

            {!!transactionDetails?.transactionCancelledDetails && (
              <CancelledDetails label={transactionDetails?.transactionCancelledDetails} />
            )}
          </TopAlertsWrapper>

          <ActionButtons>
            <Button
              customColor={themeToggleColor(theme, 'element.primary', {
                dark: 'brand.secondary',
              })}
              rounded
              fullWidth
              disabled={fileLoading}
              onClick={getTransactionBiometryFile}
            >
              {fileLoading ? <Loading /> : 'Foto assinatura'}
            </Button>

            <Button
              customColor={themeToggleColor(theme, 'element.primary', {
                dark: 'brand.secondary',
              })}
              rounded
              fullWidth
              variant="outline"
              disabled={fileLoading}
              onClick={getTransactionCCBFile}
            >
              {fileLoading ? <Loading fill={theme.colors.brand.primary} /> : 'Ccb de transação'}
            </Button>
          </ActionButtons>

          <Section>
            <SectionTitle>Opções de download</SectionTitle>

            <InfoRow>
              <InfoGroup>
                <InfoLabel>Bojo da transação</InfoLabel>
                <InfoButton onClick={handleDownloadBojo} disabled={!!bojoLoading}>
                  Clique para baixar
                </InfoButton>
              </InfoGroup>

              {/* <InfoGroup> */}
              {/*  <InfoLabel>Bojo do contrato</InfoLabel> */}
              {/*  <InfoButton>Clique para baixar</InfoButton> */}
              {/* </InfoGroup> */}
            </InfoRow>
          </Section>

          <Section>
            <SectionTitle>Dados de origem</SectionTitle>

            <TransactionDate>
              {moment(transactionDetails?.processedDate).format('DD/MM/YYYY - HH:mm:ss')}
            </TransactionDate>

            <InfoRow>
              <InfoGroup>
                <InfoLabel>Instituição de pagamento</InfoLabel>
                <InfoValue>{transactionDetails?.paymentInstitution}</InfoValue>
              </InfoGroup>

              <InfoGroup>
                <InfoLabel>Número do Contrato</InfoLabel>
                <InfoValue>{transactionDetails?.contractNumber || '-'}</InfoValue>
              </InfoGroup>
            </InfoRow>
          </Section>

          <TransactionRecipient>
            <RecipientValue>{Money(transactionDetails?.principalValue)}</RecipientValue>

            <InfoRow>
              <InfoGroup>
                <InfoLabel>Instituição de pagamento</InfoLabel>
                <InfoValue weight={600}>{transactionDetails?.recipientName}</InfoValue>
              </InfoGroup>
            </InfoRow>

            <InfoRow>
              <InfoGroup>
                <InfoLabel>Cpf</InfoLabel>
                <InfoValue>{transactionDetails?.recipientDocument}</InfoValue>
              </InfoGroup>

              <InfoGroup>
                <InfoLabel>Instituição</InfoLabel>
                <InfoValue>{transactionDetails?.recipientInstitution}</InfoValue>
              </InfoGroup>

              {!!transactionDetails?.recipientAgency && (
                <InfoGroup>
                  <InfoLabel>Agência</InfoLabel>
                  <InfoValue>{transactionDetails?.recipientAgency}</InfoValue>
                </InfoGroup>
              )}
            </InfoRow>

            {!!transactionDetails?.recipientAccount && (
              <InfoRow>
                <InfoGroup>
                  <InfoLabel>Conta</InfoLabel>
                  <InfoValue>{transactionDetails?.recipientAccount}</InfoValue>
                </InfoGroup>
              </InfoRow>
            )}
          </TransactionRecipient>

          <InstallmentsInfo>
            <SectionTitle>Detalhes da transação</SectionTitle>

            <InfoRow>
              <InfoGroup>
                <InfoLabel>Crédito utilizado</InfoLabel>
                <InfoValue>
                  {transactionDetails?.installmentsNumber} parcelas de{' '}
                  {Money(transactionDetails?.installmentValue)}
                </InfoValue>
              </InfoGroup>
            </InfoRow>

            <InfoRow>
              <InfoGroup>
                <InfoLabel>Total a pagar</InfoLabel>
                <TotalValue>{Money(transactionDetails?.totalValue)}</TotalValue>
              </InfoGroup>
            </InfoRow>
          </InstallmentsInfo>

          <AdditionalInfo>
            <AdditionalInfoTitle>Informações adicionais</AdditionalInfoTitle>

            <small>
              IOF <b>{Money(transactionDetails?.iof)}</b>
            </small>

            <small>
              Taxa de juros <b>{toPercentage(transactionDetails?.nominalRate)} ao mês.</b>
            </small>
            <small>
              Custo efetivo total{' '}
              <b>
                (CET) {toPercentage(transactionDetails?.effectiveCost)} a.m. |{' '}
                {toPercentage(transactionDetails?.anualEffectiveCost)} a.a.
              </b>
            </small>
          </AdditionalInfo>
        </TransactionDetailsWrapper>
      )}
    </>
  );
}
