import styled, { css } from 'styled-components';
import { Popover as PopoverComponent } from 'antd';

const getWidth = (width) => {
  if (width) {
    return typeof width === 'number' ? `${width}px` : width;
  }

  return '350px';
};

export const Popover = styled(PopoverComponent).attrs((props) => ({
  placement: props.placement || 'bottomLeft',
  overlayClassName: ['mt-custom-popover', props.overlayClassName].join(' '),
  overlayStyle: {
    width: getWidth(props?.width),
  },
}))`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};

  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
`;
