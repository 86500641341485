/* @flow */

export const FETCH_COMMISIONINGS_TYPES_REQUEST =
  'FETCH_COMMISIONINGS_TYPES_REQUEST';
export const FETCH_COMMISIONINGS_TYPES_SUCCESS =
  'FETCH_COMMISIONINGS_TYPES_SUCCESS';
export const FETCH_COMMISIONINGS_TYPES_FAILURE =
  'FETCH_COMMISIONINGS_TYPES_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: FETCH_COMMISIONINGS_TYPES_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FETCH_COMMISIONINGS_TYPES_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(params = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_COMMISIONINGS_TYPES_REQUEST,
      payload: {
        params,
      },
    });
  };
}
