/* @flow */

export const FETCH_CONTRACT_COMMISIONINGS_REQUEST =
  'FETCH_CONTRACT_COMMISIONINGS_REQUEST';
export const FETCH_CONTRACT_COMMISIONINGS_SUCCESS =
  'FETCH_CONTRACT_COMMISIONINGS_SUCCESS';
export const FETCH_CONTRACT_COMMISIONINGS_FAILURE =
  'FETCH_CONTRACT_COMMISIONINGS_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: FETCH_CONTRACT_COMMISIONINGS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FETCH_CONTRACT_COMMISIONINGS_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(params = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_CONTRACT_COMMISIONINGS_REQUEST,
      payload: {
        params,
      },
    });
  };
}
