import React, { ChangeEvent } from 'react';
import VMasker from 'vanilla-masker';

import { HelpText, InputWrapper, StyledInput } from './CurrencyInput.styled';

export interface CurrencyInputProps {
  onChange?: (value: number) => void;
  value?: number;
  error?: string;
}

export function CurrencyInput({ onChange, value, error }: CurrencyInputProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    const rawValue = parseFloat(targetValue.replace(/\D/g, ''));
    const transformValue = rawValue / 100;

    const parsedValue = parseFloat(transformValue.toFixed(2));
    onChange?.(parsedValue);
  };

  const parseValue = () => {
    const rawValue = value || 0;
    return parseFloat((rawValue * 100).toFixed(2));
  };

  return (
    <InputWrapper>
      <StyledInput
        type="text"
        placeholder="0,00"
        withError={!!error}
        value={VMasker.toMoney(parseValue(), {
          unit: 'R$',
          delimiter: '.',
          separator: ',',
          precision: 2,
        })}
        onChange={handleChange}
      />
      {error && <HelpText>{error}</HelpText>}
    </InputWrapper>
  );
}
