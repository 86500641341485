import { Icon as Iconn } from 'antd';
import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const Container = styled.div`
  border-radius: ${(props) => props.theme.cardBorderRadius};
  background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
  ${({ full }) =>
    full &&
    css`
      display: flex;
      flex-direction: column;
      flex: 1;
    `}
  ${({ hiddenOverflow }) =>
    hiddenOverflow &&
    css`
      overflow: hidden;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const Header = styled.div`
  padding: 20px 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ full }) =>
    full &&
    css`
      display: flex;
      flex: 1;
    `}
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.element.secondary};
  ${({ theme, icon }) =>
    icon &&
    css`
    color: ${theme.colors.element.secondary}
    fill: ${theme.colors.element.secondary}
  `};

  svg {
    width: 20px !important;
    height: 20px !important;
    margin-right: 6px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;

  > * {
    margin-left: 10px;
  }
`;

export const Child = styled.div`
  display: ${(props) => (props.isShowChild ? 'block' : 'none')};
`;

export const Icon = styled(Iconn)`
  font-size: 18px;
  svg {
    fill: #006196;
  }
`;
