// @flow

import React from 'react';
import { connect } from 'react-redux';
import solveDocuments from '~/store/solveDocument/action';
import DocumentNumber from './DocumentNumber';

type DocumentsContainerProps = {};

const fileNames = {
  DrivesLicense__Front: 'cnhFront',
  DrivesLicense__Back: 'cnhBack',
  PersonDocument__Front: 'rgFront',
  PersonDocument__Back: 'rgBack',
  Document: 'cpf',
};

const DocumentNumberContainer = (props: DocumentsContainerProps) => {
  const onPressSend = files => {
    props.dispatch(
      solveDocuments(
        files.map(file => ({ name: fileNames[file.type], file: file.file })),
      ),
    );
  };

  return <DocumentNumber onPressSend={onPressSend} {...props} />;
};

function mapStateToProps(state) {
  return {
    documents: state.getIn(['solveDocument']),
  };
}

export default connect(mapStateToProps)(DocumentNumberContainer);
