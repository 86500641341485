import uiTheme from '~/ui/theme/ui-theme';
import newTheme from '~/ui/theme';
import { defaultTheme } from '~/themes/themes';

import get from 'lodash/get';

export const getTheme = (selectedTheme: string) => {
  const uiColors = uiTheme.colors[selectedTheme] || uiTheme.colors.light;
  const colors = { ...newTheme.colors, ...uiColors };

  return {
    ...defaultTheme,
    ...newTheme,
    ...uiTheme,
    colors,
    typography: { ...newTheme.typography, ...uiTheme.typography },
    name: selectedTheme,
  };
};

type CustomVariable = { [key: string]: string };

export const themeToggleValue = (theme: any, value: string, altValues?: CustomVariable) =>
  altValues?.[theme?.name] || value;

export const themeToggleColor = (theme: any, color: string, altColors?: CustomVariable) => {
  const colorName = altColors?.[theme?.name] || color;
  return get(theme?.colors, colorName);
};

export const themeColor =
  (color: string, altColors?: CustomVariable) =>
  ({ theme }) => {
    const colorName = altColors?.[theme?.name] || color;
    return get(theme?.colors, colorName);
  };
