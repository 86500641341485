import styled, { css } from 'styled-components';

import { themeColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Container = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

export const HisconContainer = styled.div`
  align-items: center;
  border-top: 1px solid #eee;
  border-left: 2px solid transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;

  &:first-child {
    border-top: none;
  }

  &:hover {
    background: #ffebf8;
    border-left: 2px solid ${(props) => props.theme.primaryColor};

    p,
    span {
      color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })} !important;
    }
  }
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: theme.colors.element.primary,
}))`
  margin-bottom: 0;
`;

type InfoProps = {
  marginBottom: number;
};

export const Info = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  weight: 600,
  color: theme.colors.element.primary,
}))<InfoProps>`
  ${({ marginBottom }) =>
    css`
      margin-bottom: ${marginBottom ?? 0}px;
    `}
`;

export const Tip = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'span',
  color: theme.colors.element.primary,
}))`
  margin-bottom: 0;
`;
