import React, { useCallback } from 'react';
import { Select } from 'antd';

import { SearchSelect } from '~/common';
import useTableFilterContext from '../useTableFilterContext';
import { FieldProps, SelectionType } from '../Props';
import FieldWrapper from './FieldWrapper';

const { Option } = Select;

type SelectFieldProps = FieldProps<SelectionType> & {
  options?: any[];
}

export default function SelectField({
  definition: {
    title,
    placeholder,
    selectValueKey,
    extractKey = (item) => item,
    displayNormalize = (displayValue) => displayValue,
    extra = { mode: 'default' },
  },
  options,
  index,
}: SelectFieldProps) {
  const { changeValue } = useTableFilterContext();

  const onChange = useCallback((value) => {
    changeValue(selectValueKey, value);
  }, []);

  const filterOpt = useCallback((input, option) => SearchSelect(input, option.props.children), []);

  return (
    <FieldWrapper index={index}>
      {title}
      <Select
        mode={extra.mode}
        style={{ width: '100%' }}
        placeholder={placeholder}
        allowClear
        showArrow
        onChange={onChange}
        optionFilterProp="children"
        filterOption={filterOpt}
      >
        {options?.map?.((item) => (
          <Option key={extractKey(item)}>
            {displayNormalize(item)}
          </Option>
        ))}
      </Select>
    </FieldWrapper>
  );
}
