import React from 'react';
import styled, { css } from 'styled-components';

export const StepContainer = styled.div`
  cursor: pointer;
  border-radius: 8px;
  padding: 3px 10px;
  margin-right: 10px;
  font-size: 13px;
  background-color: #fff;
  border: 1px solid ${props => props.theme.borderGrayColor};
  color: ${props => props.theme.titleGray};
  ${props =>
    props.done &&
    css`
      border: 1px solid ${props => props.theme.successColor}
      color: ${props => props.theme.successColor}
      background-color: ${props => props.theme.successColorLightest}
  `};

  ${props =>
    props.active &&
    css`
      border: 1px solid ${props => props.theme.primaryColor}
      color: ${props => props.theme.primaryColor}
      background-color: ${props => props.theme.primaryColorLightest}
    `};

  &:active {
    opacity: 0.5;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
`;
