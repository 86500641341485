import { createAsyncAction } from 'react-redux-await-control';
import { telli } from '~/services';

export const getOpportunities = createAsyncAction('GET_OPPORTUNITIES', {
  initialValue: [],
  saveResult: true,
  context: {
    responseParser: ({ data }) => data.resource,
    errorParser: () => [],
    onRequestError: () => {},
    request: ({ personId, agreementId }) =>
      telli.get('/admin/personcustom/people/opportunities', {
        params: { personId, agreementId },
      }),
  },
});

export const simulateOpportunity = createAsyncAction('SIMULATE_OPPORTUNITY', {
  initialValue: null,
  saveResult: true,
  context: {
    responseParser: ({ data }) => data.resource?.[0],
    onRequestError: () => {},
    request: (data) => telli.post('/admin/personcustom/people/simulate', data),
  },
});

export const prepareContract = createAsyncAction('PREPARE_CONTRACT', {
  initialValue: null,
  saveResult: true,
  context: {
    responseParser: ({ data }) => data.resource,
    onRequestError: () => {},
    request: (data) => telli.post('/admin/personcustom/people/prepareContract', data),
  },
});

export const suggestedProposals = createAsyncAction('GET_SUGGESTED_PROPOSALS', {
  initialValue: {},
  saveResult: true,
  context: {
    responseParser: ({ data }) => data.resource,
    errorParser: (err) => ({ statusCode: err.response.status, suggestedProposalReturns: [] }),
    onRequestError: () => {},
    request: (data) => telli.post('/admin/personcustom/people/suggestedproposals', data),
  },
});
