import styled from 'styled-components';

export const EmbedContainer = styled.div`
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const LoadingContainer = styled.div`
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
`;
