import styled from 'styled-components';
import { Button } from '~/ui/components';
import { RightContainer } from '~/ui/components/Menu/Menu.styled';
import { themeColor } from '~/common/utils/theme';

export const Container = styled(RightContainer).attrs({
  className: 'settings-menu',
})`
  .submenu {
    nav {
      max-height: 360px;
      overflow: overlay;

      a {
        &.logout {
          height: 56px;
          border-top: 1px solid #dddddd;

          svg {
            color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
          }
        }
      }
    }
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    border: solid ${({ theme }) => theme.colors.element.invert} !important;
  }
`;

export const ReportButton = styled(Button).attrs({
  variant: 'text',
  onlyIcon: true,
  icon: 'GraphBars',
})`
  min-width: 24px;
  max-width: 24px;
  padding: 0;

  svg {
    color: ${({ theme }) => theme.colors.element.primary} !important;
  }

  &:hover {
    svg {
      opacity: 0.8;
    }
  }
`;
