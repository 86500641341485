import styled from 'styled-components';

import theme from '~/ui/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 56px;
  border: 2px solid ${theme.colors.gray[200]};
  border-radius: 15px;
  background-color: ${theme.colors.gray[50]};
`;

export const Input = styled.input`
  border: none;
  background-color: transparent;
  padding: 10px;
  size: 16px;
  outline: none;
  width: 100%;
`;
