import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { useModal } from '~/hooks/useModal';
import {
  approvePersonRegistry,
  getPersonDetail,
  reprovePersonRegistry,
} from '~/store/people/actions';
import useUserRoles from '~/hooks/useUserRoles';
import { ItemText, MenuItem, Text, ConfirmButton } from '../OtherOptions.styled';

type Props = {
  onClick?: () => void;
};

export function DisapproveCustomer({ onClick }: Props) {
  const { openModal, closeModal } = useModal();
  const { isAdmin } = useUserRoles();

  const personDetailControl = useAwaitControl(getPersonDetail);
  const approvePersonRegistryControl = useAwaitControl(approvePersonRegistry);
  const reprovePersonRegistryControl = useAwaitControl(reprovePersonRegistry);

  const person = personDetailControl.result();
  const approveSuccess = approvePersonRegistryControl.isSuccessful();
  const reproveSuccess = reprovePersonRegistryControl.isSuccessful();
  const reproveLoading = reprovePersonRegistryControl.isRunning();

  const showApproveButton = useMemo(
    () => ['REFUSED'].includes(person?.registrationStatus) && isAdmin,
    [person?.registrationStatus],
  );

  const showReproveButton = useMemo(
    () => ['APPROVED', 'COMPLETE'].includes(person?.registrationStatus),
    [person?.registrationStatus],
  );

  const showApproveActions = useMemo(
    () => showApproveButton || showReproveButton,
    [person?.registrationStatus],
  );

  const handleReprove = useCallback(() => {
    reprovePersonRegistryControl.start({ id: person?.id });
  }, [person?.id]);

  const handleOnClick = useCallback(
    (type) => {
      onClick?.();
      if (type === 'REPROVAR') {
        openModal(
          <Text>
            Confirme a reprovação do seu cliente. Ao reprovar um usuário, sua conta será desativada
            e não poderá mais acessar o sistema.
            <ConfirmButton onClick={handleReprove} loading={reproveLoading}>
              Confirmar reprovação
            </ConfirmButton>
          </Text>,
          {
            id: 'reprove-modal',
            title: 'Reprovação do cliente',
            width: 344,
            closable: true,
          },
        );
      }

      if (type === 'REATIVAR') {
        approvePersonRegistryControl.start({ id: person?.id });
      }
    },
    [openModal, reproveLoading, handleReprove],
  );

  useEffect(() => {
    if (reproveSuccess) {
      closeModal('reprove-modal');
      reprovePersonRegistryControl.clear();
    }
  }, [reproveSuccess]);

  useEffect(() => {
    if (approveSuccess) {
      approvePersonRegistryControl.clear();
    }
  }, [approveSuccess]);

  if (!person?.id || !showApproveActions || person?.disabled) {
    return null;
  }

  if (showApproveButton) {
    return (
      <MenuItem onClick={() => handleOnClick('REATIVAR')}>
        <ItemText>Reativar cadastro</ItemText>
      </MenuItem>
    );
  }

  if (showReproveButton) {
    return (
      <MenuItem onClick={() => handleOnClick('REPROVAR')}>
        <ItemText>Reprovar cliente</ItemText>
      </MenuItem>
    );
  }

  return null;
}
