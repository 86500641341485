export const MOCKED_FILTER_OPTIONS = [
  {
    label: 'Filtrar por status',
    value: 'status',
    items: [
      { label: 'Ativo', value: 'ativo' },
      { label: 'Excluído', value: 'excluído' },
    ],
  },
  {
    label: 'Filtrar por cargo',
    value: 'office',
    items: [
      { label: 'Desenvolvedores', value: 'desenvolvedores' },
      { label: 'Coordenador', value: 'coordenador' },
      { label: 'Agente nível 1', value: 'agente nível 1' },
    ],
  },
  {
    label: 'Filtrar por time',
    value: 'group',
    items: [
      { label: 'Time Tecnologia', value: 'time tecnologia' },
      { label: '0800', value: '0800' },
      { label: 'Backoffice | KYC', value: 'backoffice | kyc' },
    ],
  },
];

export const MOCKED_AGENT = {
  label: 'Robson Vieira',
  role: 'Head Costumer',
};

export const MOCKED_TIME_LINE = [
  {
    label: 'Usuário Criado',
    agent: 'Robson Vieira',
    date: '12 de Janeiro, 15h10',
  },
  {
    label: 'Nova permissão concedida',
    agent: 'Robson Vieira',
    date: '18 de Janeiro, 15h20',
  },
  {
    label: 'Permissão removida',
    agent: 'Robson Vieira',
    date: '18 de Janeiro, 15h20',
  },
];
