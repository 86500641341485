import React from 'react';
import styled from 'styled-components';
import { Link as RouterDomLink, LinkProps } from 'react-router-dom';

import { themeColor } from '~/common/utils/theme';

export const ContractFieldValue = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.main};

  p {
    margin: 0;
  }

  svg {
    margin-top: 2px;
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Link = styled((props: LinkProps) => <RouterDomLink {...props} />)`
  color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
`;
