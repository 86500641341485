// @flow
import * as React from 'react';
import { useState, useMemo, useCallback } from 'react';

export interface Modals {
  openModal(key: string, props?: { [key: string]: any }): void;
  closeModal(key?: string): void;
}

type ModalItem = {
  key: string,
  Component: React.Element,
  props: { [string]: any },
};

const initialState: Modals = {
  closeModal: () => {
    throw new Error('Not implemented');
  },
  openModal: () => {
    throw new Error('Not implemented');
  },
};

export const ModalsContext = React.createContext(initialState);

export const ModalsProvider: React.Element<Props> = ({ children }: Props) => {
  const modalsComponents = React.Children.toArray(children).filter(
    child => child.type.__type === 'modal',
  );

  const nonModalsComponents = React.Children.toArray(children).filter(
    child => child.type.__type !== 'modal',
  );

  const [modal, setModal] = useState<ModalItem[]>([]);

  const modals = useMemo(
    () =>
      modalsComponents.reduce(
        (ms, _modal) => ({ ...ms, [_modal.type.alias]: _modal.type }),
        {},
      ),
    [modalsComponents],
  );

  const openModal: Modals = useCallback(
    (key, props = {}) => () => {
      const Component = modals[key];

      if (Component) {
        setModal(state => [
          ...state,
          {
            key,
            Component,
            props,
          },
        ]);
      }
    },
    [modals],
  );

  const closeModal = useCallback(
    (key?: string) => () => {
      if (key === undefined) {
        setModal([]);
      } else {
        setModal(state => state.filter(item => item.key !== key));
      }
    },
    [],
  );

  return (
    <ModalsContext.Provider value={{ openModal, closeModal }}>
      {modal.map(item => (
        <item.Component {...item.props} open key={item.key} />
      ))}
      {nonModalsComponents}
    </ModalsContext.Provider>
  );
};
