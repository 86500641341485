/* @flow */

export const VALIDATION_BIOMETRY_FETCH_REQUEST =
  'VALIDATION_BIOMETRY_FETCH_REQUEST';
export const VALIDATION_BIOMETRY_FETCH_SUCCESS =
  'VALIDATION_BIOMETRY_FETCH_SUCCESS';
export const VALIDATION_BIOMETRY_FETCH_FAILURE =
  'VALIDATION_BIOMETRY_FETCH_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: VALIDATION_BIOMETRY_FETCH_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: VALIDATION_BIOMETRY_FETCH_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(personId, forceUpdate = false) {
  return dispatch => {
    dispatch({
      type: VALIDATION_BIOMETRY_FETCH_REQUEST,
      payload: {
        personId,
        forceUpdate,
      },
    });
  };
}
