/* @flow */

import { message } from '~/common';

export const SAVE_TRUSTWORTHY_BIOMETRY_REQUEST =
  'SAVE_TRUSTWORTHY_BIOMETRY_REQUEST';
export const SAVE_TRUSTWORTHY_BIOMETRY_SUCCESS =
  'SAVE_TRUSTWORTHY_BIOMETRY_SUCCESS';
export const SAVE_TRUSTWORTHY_BIOMETRY_FAILURE =
  'SAVE_TRUSTWORTHY_BIOMETRY_FAILURE';

export function success(payload) {
  message.success('Foto enviada com sucesso!');
  return dispatch => {
    dispatch({
      type: SAVE_TRUSTWORTHY_BIOMETRY_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  message.error('Falha ao enviar foto');
  return dispatch => {
    dispatch({
      type: SAVE_TRUSTWORTHY_BIOMETRY_FAILURE,
      payload: resource,
    });
  };
}

export default function sendBiometry(payload) {
  return dispatch => {
    dispatch({
      type: SAVE_TRUSTWORTHY_BIOMETRY_REQUEST,
      payload,
    });
  };
}
