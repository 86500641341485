import React from 'react';
import styled from 'styled-components';
import { Button } from '~/components';

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CompareButtom = styled(Button)`
  margin: 0 auto;
  display: flex;
`;
