import { Map, List } from 'immutable';

import {
  OPERATION_STEPS_BY_CONTRACT_REQUEST,
  OPERATION_STEPS_BY_CONTRACT_FAILURE,
  OPERATION_STEPS_BY_CONTRACT_SUCCESS,
} from './action';

const initialState = Map({
  type: '',
  payload: List([]),
});

// eslint-disable-next-line default-param-last
function reducer(state = initialState, action) {
  const { type } = action;

  if (type === OPERATION_STEPS_BY_CONTRACT_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => true)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], List([]));
  }

  if (type === OPERATION_STEPS_BY_CONTRACT_FAILURE) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], List([]));
  }

  if (type === OPERATION_STEPS_BY_CONTRACT_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => true)
      .setIn(['payload'], List(payload));
  }

  return state;
}

export default reducer;
