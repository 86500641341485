/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { VALIDATION_BIOMETRY_FETCH_REQUEST, success, failure } from './action';

const fetch = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(VALIDATION_BIOMETRY_FETCH_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(({ personId, forceUpdate }) =>
      AjaxRequest.open(
        'get',
        `/admin/personcustom/serprofacialbiometry?personId=${personId}&forceUpdate=${forceUpdate}`,
      )('tudo')
        .flatMap(data => Observable.of(success(data.response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(fetch);
