import styled from 'styled-components';
import { Icon as AntdIcon } from 'antd';

export const ConfigLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.accentColor};
  margin-bottom: 0;
`;

export const LabelAlert = styled.div`
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'warning':
        return theme.colors[status]['50'];
      case 'error':
        return theme.colors[status]['50'];
      case 'info':
        return theme.colors.gray['50'];
      default:
        return theme.colors.success['50'];
    }
  }};
  border-radius: 16px;
  min-width: 302px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : width || 'auto')};
`;

export const Icon = styled(AntdIcon)`
  color: ${({ theme, status }) => {
    switch (status) {
      case 'warning':
        return theme.alertColorDark;
      case 'error':
        return theme.dangerColorDark;
      case 'info':
        return theme.colors.gray['900'];
      default:
        return theme.successColorDarkest;
    }
  }};
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  svg {
    width: 18px;
    height: 18px;
  }
`;
