import React, { useContext } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';

import { getLoggedUser } from '~/store/user/actions';
import { Shimmer } from '~/ui/components';
import {
  ConfigContent,
  ConfigLabel,
  ConfigSetup,
  ConfigTitle,
  EnableContractSwitch,
  IconStyled,
  UserConfig,
} from './styled';

export function ActivateDistributionConfig() {
  const [getLoggedUserControl] = useAwaitControl([getLoggedUser]);
  const { isDistributionActive, toggleDistributionStatus } = useContext(ContractDetailsContext);

  const { name } = getLoggedUserControl.result();
  const isLoading = getLoggedUserControl.isRunning();

  return (
    <ConfigContent>
      <IconStyled active={isDistributionActive} type="user" />
      <UserConfig>
        {isLoading ? <Shimmer width={175} height={20} mb={6} /> : <ConfigTitle>{name}</ConfigTitle>}
        <ConfigSetup>
          <ConfigLabel>Ative para receber contratos</ConfigLabel>
          <EnableContractSwitch
            checked={isDistributionActive}
            onChange={toggleDistributionStatus}
          />
        </ConfigSetup>
      </UserConfig>
    </ConfigContent>
  );
}
