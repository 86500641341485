import StyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import { css, withStyles } from 'react-with-styles';
import { blendColors } from '~/common/colors';
import { mergeDeep } from '~/common/mergeDeep';

const { registerTheme } = StyleSheet;

const unit = 10;
const breakpoints = {
  small: '@media (max-width: 639px)',
  medium: '@media (max-width: 1047px)',
  large: '@media (min-width: 1048px)',
};

const colors = {
  primary: 'rgba(0,0,0,0.98)',
  complementary: '#323335',
  accent: '#f200b5',
  grayExtraLight: '#ededed',
  grayLight: '#c6c6c6',
  gray: '#7a869a',
  grayDark: '#505f79',
  grayExtraDark: '#343a40',
};

const chartColors = [
  blendColors(colors.primary, colors.complementary, 0.1),
  blendColors(colors.primary, colors.complementary, 1),
  blendColors(colors.primary, colors.complementary, 0.3),
  blendColors(colors.primary, colors.complementary, 0.8),
  blendColors(colors.primary, colors.complementary, 0.5),
  blendColors(colors.primary, colors.complementary, 0.2),
  blendColors(colors.primary, colors.complementary, 0.9),
  blendColors(colors.primary, colors.complementary, 0.4),
  blendColors(colors.primary, colors.complementary, 0.7),
  blendColors(colors.primary, colors.complementary, 0.6),
];

const fontFamily = 'Rubik, sans-serif';

const components = {
  form: {},
  input: {
    borderColor: colors.grayLight,
    borderRadius: 4,
    borderSize: 1,
    fontFamily,
    fontSize: 12,
    boxShadow: {
      color: colors.grayExtraLight,
      offset: 'inset 0 1px 3px',
    },
  },
  label: {
    color: '#949494',
    display: 'block',
    fontFamily,
    fontSize: unit * 0.9,
    letterSpacing: '0',
    textTransform: 'uppercase',
  },
  breadcrumb: {
    separator: {
      color: colors.gray,
    },
    link: {
      color: colors.grayLight,

      current: {
        color: colors.grayExtraDark,
      },
      disabled: {
        color: colors.grayExtraLight,
      },
    },
  },
  breadcrumbToolbar: {
    backgroundColor: 'white',
    borderRadius: 2,
    padding: '0.65em',
  },
};

StyleSheet.registerTheme({
  breakpoints,
  chartColors,
  colors,
  components: {
    ...components,
    Aside: {
      background: colors.grayExtraLight,
      width: 216,
      minWidth: 216, // FIX: implica em sempre manter sua largura.
      screen: {
        color: '#383838',
      },
      subject: {
        color: '#474545',
      },
    },
    Header: {
      background: 'white',
      height: 64,
    },
    Sidebar: {
      width: 54,
    },
    Login: {
      container: {},
      box: {},
      inputWrapper: {},
      inputLabel: {},
      input: {},
      footer: {},
      center: {},
      logo: {},
      message: {},
      actions: {},
      button: {},
    },
  },
  fontFamily,
  text: {
    color: '#2D3436',
    size: 12,
  },
  unit,
});

StyleSheet.registerInterface(aphroditeInterface);

StyleSheet.registerTheme = async theme => {
  const currentTheme = StyleSheet.get();
  const newTheme = await mergeDeep(currentTheme, theme);

  registerTheme(newTheme);
};

export default {
  colors,
  css,
  withStyles,
  StyleSheet,
};
