/* global ClipboardItem */

import domToImage from 'dom-to-image';
import dataURLToBlob from '~/common/blob';

export async function domToDownload(
  node?: Element,
  fileName?: string,
): Promise<string | undefined> {
  const dataUrl = await domToImage.toJpeg(node);
  const link = document.createElement('a');
  link.download = `${fileName || 'noname'}.jpg`;
  link.href = dataUrl;
  link.click();

  return dataUrl;
}

export async function domToClipboard(node?: Element): Promise<boolean> {
  const dataUrl = await domToImage.toPng(node);
  const blob = dataURLToBlob(dataUrl);

  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        [blob.type]: blob,
      }),
    ]);

    return true;
  } catch (error) {
    return false;
  }
}
