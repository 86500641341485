import React from 'react';

import toDateAndHour from '~/common/masked/toDateAndHour';
import modalBuilder, { ModalBuilderProps } from '~/hoc/modalBuilder';
import type { GenericLog } from '~/typings/entities/genericLog';

import {
  Line,
  Label,
  Value,
  ValueHighlighted,
  DescriptionContainer,
  Description,
  DescriptionTextWrapper,
} from './styled';

type LogDetailProps = GenericLog & ModalBuilderProps;

function LogDetail({ genericId, log, logDate, logType, user }: LogDetailProps) {
  return (
    <>
      <Line>
        <Label>ID:</Label>
        <ValueHighlighted>{genericId}</ValueHighlighted>
      </Line>
      <Line>
        <Label>Data:</Label>
        <Value>{toDateAndHour(logDate)}</Value>
      </Line>
      <Line>
        <Label>Tipo:</Label>
        <Value>{logType}</Value>
      </Line>
      <Line>
        <Label>Agente:</Label>
        <Value>{user}</Value>
      </Line>
      <DescriptionContainer>
        <Label>Descrição:</Label>
        <DescriptionTextWrapper>
          <Description>
            <span>{log}</span>
          </Description>
        </DescriptionTextWrapper>
      </DescriptionContainer>
    </>
  );
}

export default modalBuilder(LogDetail, 'LOG_DETAIL', { height: 437 });
