/* @flow */

export const TAGTYPES_GET_REQUEST = 'TAGTYPES_GET_REQUEST';
export const TAGTYPES_GET_SUCCESS = 'TAGTYPES_GET_SUCCESS';
export const TAGTYPES_GET_FAILURE = 'TAGTYPES_GET_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: TAGTYPES_GET_SUCCESS,
      payload,
    });
  };
}

export function addBack(tagType) {
  return (dispatch, getState) => {
    const tagTypes = getState().getIn(['tagTypesGet', 'payload']);
    dispatch({
      type: TAGTYPES_GET_SUCCESS,
      payload: [...tagTypes, tagType],
    });
  };
}

export function removeFromTagTypes(id) {
  return (dispatch, getState) => {
    const tagTypes = getState().getIn(['tagTypesGet', 'payload']);
    dispatch({
      type: TAGTYPES_GET_SUCCESS,
      payload: tagTypes.filter(t => t !== id),
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: TAGTYPES_GET_FAILURE,
      payload: resource,
    });
  };
}

type Payload = {
  type: 'CONTRACT' | 'PERSON',
};

export default function getTagTypes(data: Payload) {
  return (dispatch, getState) => {
    if (!data) {
      data = getState().getIn(['tagTypesGet', 'data']);
    }
    dispatch({
      type: TAGTYPES_GET_REQUEST,
      data,
    });
  };
}
