// @flow
import React, { Fragment } from 'react';
import './tag.css';
import { TagContainer, TagRemoveContainer } from '~/components/Tag/Tag.styles';
import { Icon, Popover } from 'antd';
import AddContent from '~/components/Tag/AddContent/AddContent';
import useUserRoles from '~/hooks/useUserRoles';

type TagProps = {
  name: string,
  id: number,
  type: 'CONTRACT' | 'PERSON',
  user: string,
  tags: [],
  addType?: boolean,
  private?: boolean,
  onRemove: (tagId: number) => void,
};

function Name({ name }: TagProps) {
  return <span>{name}</span>;
}

function Remove({ addType, onRemove, id, user }: TagProps) {
  if (addType) return null;

  const onRemoveTag = () => {
    if (onRemove) {
      onRemove(id);
    }
  };

  return (
    <TagRemoveContainer onClick={onRemoveTag} strong={user === 'Administrador'}>
      <Icon type="close" />
    </TagRemoveContainer>
  );
}

function Add({ addType }: TagProps) {
  if (!addType) return null;
  return <Icon type="plus" style={{ fontSize: '12px' }} />;
}

function Tag(props: TagProps) {
  const { addType } = props;

  const { roles } = useUserRoles();

  const Wrapper = addType ? Popover : Fragment;

  const popOverOptions = {
    placement: 'bottom',
    trigger: 'click',
    arrowPointAtCenter: true,
    overlayClassName: 'tag-popover',
    overlayInnerStyle: { padding: 0 },
    content: <AddContent tags={props.tags} type={props.type} />,
    title: 'Adicionar tag',
  };

  return (
    <Wrapper {...(addType ? popOverOptions : undefined)}>
      <TagContainer {...props}>
        <Name {...props} />
        {(props.user !== 'Administrador' ||
          roles?.includes('ADMINISTRATOR') ||
          roles?.includes('BACKOFFICE_SUPERVISOR')) && <Remove {...props} />}
        <Add {...props} />
      </TagContainer>
    </Wrapper>
  );
}

Tag.defaultProps = {
  tagTypes: [],
};

export default Tag;
