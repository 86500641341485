/* @flow */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, withState } from 'recompose';

import { getContractDetails } from '~/store/contracts/actions';
import theme from '~/themes/aphroditeTheme/theme';
import MoreInfo from '~/screens/components/MoreInfo';

import Styles from './More.Styles';

const { withStyles } = theme;

type Props = {
  styles: any,
  details: {
    id: number,
    agency: string,
    approvementDate: string,
    checkingAccount: string,
    checkingAccountDigit: string,
    consigneeContractKey: string,
    contractDate: string,
    consigneeName: string,
    createdLocation: string,
    embeddedIof: number,
    extraInformation: any,
    installmentValue: number,
    interestRate: number,
    financialInstitutionBankNumber: number,
    financedValue: number,
    hasRefinancedContracts: Boolean,
    numberOfInstallments: number,
    outstandingBalance: number,
    paidInstallments: number,
    person: {
      document: string,
      email: string,
      enrollment: string,
      name: string,
      phone: string,
      registrationNumber: string,
      work: string,
    },
    remainingInstallments: number,
    situation: string,
    termsAccepted: string,
    type: string,
  },
};

class More extends Component<Props> {
  render() {
    const { styles } = this.props;
    return <MoreInfo styles={styles} details={this.props.details} />;
  }
}

More.defaultProps = {
  details: {
    person: {
      addressComplement: '',
      addressNumber: '',
      city: '',
      income: '',
      maritalStatus: '',
      registrationNumber: 'Não Informado',
      street: '',
      state: '',
    },
  },
};

function mapStateToProps(state) {
  return {
    details: getContractDetails.getResult()(state),
  };
}

export default compose(
  withRouter,
  withStyles(Styles),
  connect(mapStateToProps),
  withState('isLoading', 'loading', true),
)(More);
