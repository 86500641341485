import styled from 'styled-components';

import { Button, Typography } from '~/ui/components';
import { MenuOptions } from '~/components';
import { fadeIn } from '~/ui/theme/animations';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

export const Dots = styled(Button).attrs(({ theme }) => ({
  iconOnly: true,
  variant: 'text',
  size: 'sm',
  customColor: themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
  icon: 'DotsVertical',
}))`
  max-height: 20px;
`;

export const Options = styled(MenuOptions)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 14px;
  }

  &:hover {
    font-weight: 500;
  }

  b {
    font-weight: 600;
  }
`;

export const Text = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  element: 'p',
}))``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  gap: 16px;
  width: 100%;
`;

export const Content = styled.div<{ center?: boolean; loading?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};

  transition: all 0.3s ease-in-out;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'headingXSmall',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  weight: 500,
}))``;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
`;

export const Divider = styled.div<{ center?: boolean }>`
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
`;
