import React from 'react';
import styled, { css } from 'styled-components';

export const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin: 16px 8px 8px;
  color: black;
`;

export const Container = styled.div`
  padding: 10px 29px 26px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
`;

export const Pendency = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.gray10};
  padding: 8px;
  min-height: 50px;
  border-radius: 5px;
  margin: 8px;
  color: black;
  transition: all 0.5s linear;

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      :hover {
        background-color: ${({ theme }) => theme.primaryColorLightest};
        border: 1px solid ${({ theme }) => theme.primaryColor};
        color: ${({ theme }) => theme.primaryColorDark};
        cursor: pointer;
      }
    `}

  span {
    color: ${({ theme }) => theme.gray60};
    font-size: 13px;
  }
`;
