import React, {
  cloneElement,
  useCallback,
  useMemo,
  useState,
  ComponentType,
  forwardRef,
  useImperativeHandle,
  ReactNode,
  useEffect,
} from 'react';

import { Step, StepperContainer } from './Steps.styled';

export type StepItem = {
  id: string;
  component: ReactNode;
  metaData?: Object;
};

export type StepsProps = {
  steps: StepItem[];
  onStepChange?: (step: number) => void;
  extraProps?: { [key: string]: any };
};

export type Stepper = {
  prevStep: () => void;
  nextStep: () => void;
  toStep: (step: number) => void;
  toStepById: (id: string) => void;
  activeStep: number;
  firstStep: boolean;
  lastStep: boolean;
};

export type StepperProps<P> = P & {
  stepper: Stepper;
};

const DURATION = 300;

export function withStepperProps<P>(Component: ComponentType<StepperProps<P>>) {
  return function returnedComponent(props: any) {
    return <Component {...props} />;
  };
}

export const Steps = forwardRef(({ steps, onStepChange, extraProps }: StepsProps, ref) => {
  const [activeStep, setActiveStep] = useState(0);
  const [animation, setAnimation] = useState<string>();

  const changeStep = (step: number) => {
    const toNextStep = activeStep < step;

    setAnimation(toNextStep ? 'slideLeftExit' : 'slideRightExit');

    setTimeout(() => {
      setActiveStep(step);
      setAnimation(toNextStep ? 'slideLeft' : 'slideRight');
      setTimeout(() => {
        setAnimation(toNextStep ? 'slideLeftEnter' : 'slideRightEnter');
      }, 100);
    }, DURATION);
  };

  const handleNextStep = () => {
    if (activeStep === steps.length - 1) return;
    changeStep(activeStep + 1);
  };

  const handleToStep = (step: number) => {
    if (step !== activeStep) {
      changeStep(step);
    }
  };

  const handleToStepById = (id: string) => {
    const step = steps.findIndex((step) => step.id === id);

    if (step !== activeStep) {
      changeStep(step);
    }
  };

  const handlePrevStep = () => {
    if (activeStep === 0) return;
    changeStep(activeStep - 1);
  };

  const componentWithProps = useCallback(
    (component, props?: any) => component && cloneElement(component, props),
    [cloneElement],
  );

  const stepper = useMemo(
    () => ({
      prevStep: handlePrevStep,
      nextStep: handleNextStep,
      toStep: handleToStep,
      toStepById: handleToStepById,
      firstStep: activeStep === 0,
      lastStep: activeStep === steps.length - 1,
      activeStep,
    }),
    [handlePrevStep, handleNextStep, activeStep],
  );

  useEffect(() => {
    onStepChange?.(activeStep);
  }, [activeStep]);

  useImperativeHandle(ref, () => stepper);

  return (
    <StepperContainer>
      <Step duration={DURATION} className={animation}>
        {componentWithProps(steps[activeStep]?.component, { stepper, ...extraProps })}
      </Step>
    </StepperContainer>
  );
});
