import { Map } from 'immutable';

import {
  CONCESSION_GET_REQUEST,
  CONCESSION_GET_FAILURE,
  CONCESSION_GET_SUCCESS,
} from './action';

const initialState = Map({
  type: '',
  payload: Map({}),
});

function reducer(state = initialState, action) {
  const { type } = action;

  if (type === CONCESSION_GET_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => true)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], Map({}));
  }

  if (type === CONCESSION_GET_FAILURE) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .setIn(['payload'], Map(payload));
  }

  if (type === CONCESSION_GET_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .setIn(['payload'], Map(payload));
  }

  return state;
}

export default reducer;
