import React from 'react';
import { ShimmerContainer, Skeleton, ShimmerProps } from './Shimmer.styled';

export type { ShimmerProps } from './Shimmer.styled';

export function Shimmer(props: ShimmerProps) {
  return (
    <ShimmerContainer data-testid="skeleton-component" {...props}>
      <Skeleton />
    </ShimmerContainer>
  );
}

export default Shimmer;
