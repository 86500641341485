/* @flow */

import React, { Component } from 'react';
import theme from '~/themes/aphroditeTheme/theme';
import { Popconfirm } from 'antd';
import {
  OldActions as CompAction,
  Button,
  Flex,
  DownloadMedicalCertificate,
  InvalidateAgreementPassword,
} from '~/components';

import Card from '~/components/Card';
import type { ActionProps } from '~/components/Actions/Actions';
import HisconActions from '~/components/HisconActions/HisconActions';
import ConcessionActions from '~/components/ConcessionActions/ConcessionActions';
import { AGREEMENT } from '~/common/constants';
import { AccessByCustomer, Notification, SendMedicalCertificate, SendBiometry } from './components';

const { css } = theme;

const REGISTRATION_STATUS_ALLOWED_FOR_REPROCESS_IN100 = [
  'PASSWORD',
  'PENDING_IN100',
  'VALIDATION',
  'COMPLETE',
  'APPROVED',
];

export function Action(props: ActionProps) {
  return <CompAction {...props} />;
}

class Actions extends Component {
  state = {};

  onPressUploadHiscon = (ev: Event) => {
    this.props.onPressDispatchUploadHiscon(ev);
  };

  onPressInputFile = () => {
    this.inputFile.click();
  };

  renderApprovePersonRegistry = () => {
    const { onPressApprovePersonRegistry } = this.props;
    return (
      <Button
        fullWidth
        type="success"
        onPress={onPressApprovePersonRegistry}
        title="Sim"
        style={{ marginLeft: 8 }}
      />
    );
  };

  renderRequestIN100 = () => {
    const { personDetails, onPressRequestIN100, styles } = this.props;
    const { agreementName, registrationStatus } = personDetails;

    if (agreementName !== 'INSS') return null;

    if (!REGISTRATION_STATUS_ALLOWED_FOR_REPROCESS_IN100.includes(registrationStatus)) {
      return null;
    }

    return (
      <Action
        icon="diff"
        title="Reprocessar In100"
        placement="topRight"
        onPress={onPressRequestIN100}
        style={css(styles.action)}
      />
    );
  };

  renderMedicalCertificate = () => {
    const { personDetails } = this.props;
    const { agreementName } = personDetails;
    const { personId } = this.props.match.params;

    if (agreementName !== 'INSS') return null;

    return (
      <Action
        icon="diff"
        title="Perícia Médica"
        subActions={[
          <DownloadMedicalCertificate personId={personId} agreementName={agreementName} />,
          <SendMedicalCertificate agreementName={agreementName} />,
        ]}
      />
    );
  };

  renderRegisterOfferPerson = () => {
    const { match, history } = this.props;
    const { personId } = match.params;
    return (
      <Action
        title="Nova Oferta"
        onPress={() =>
          history.push({
            pathname: `/backoffice/people/${personId}/offer-person/register`,
            query: { showAge: true },
          })
        }
      />
    );
  };

  renderFillContract = () => {
    const { personAgreementId, history, match } = this.props;
    const { personId } = match.params;
    return (
      <Action
        title="Preencher Contrato"
        onPress={() =>
          history.push({
            pathname: `/backoffice/people/${personId}/fill-contract`,
            state: { personAgreementId },
          })
        }
      />
    );
  };

  /* RENDER APPROVE OR REFUSE --------------------------------------------------- */
  renderRequestAgreementPassword = () => {
    const { onPressRequestAgreementPassword, styles, personDetails } = this.props;
    if (personDetails.agreementId !== AGREEMENT.INSS) {
      return null;
    }
    return (
      <Popconfirm
        title="Você realmente deseja requisitar a senha do Meu INSS?"
        onConfirm={onPressRequestAgreementPassword}
        okText="Sim"
        cancelText="Não"
        placement="leftBottom"
      >
        <div>
          <Action title="Requisitar senha do Meu INSS" style={css(styles.action)} />
        </div>
      </Popconfirm>
    );
  };

  renderRefusePersonRegistry = () => {
    const { onPressRefusePersonRegistry } = this.props;
    return (
      <Popconfirm
        style={{ marginBottom: 16 }}
        title="Você realmente deseja recusar o cadastro deste usuário?"
        onConfirm={onPressRefusePersonRegistry}
        okText="Sim"
        cancelText="Não"
        placement="leftBottom"
      >
        <Button style={{ marginRight: 8 }} title="Não" type="danger" fullWidth />
      </Popconfirm>
    );
  };

  renderApproveSection() {
    const { registrationStatus } = this.props.personDetails;

    if (registrationStatus !== 'COMPLETE') {
      return null;
    }

    return (
      <Card title="Aprovar cadastro" style={{ marginBottom: 16 }}>
        <Flex direction="row" style={{ padding: 16 }}>
          {this.renderRefusePersonRegistry()}
          {this.renderApprovePersonRegistry()}
        </Flex>
      </Card>
    );
  }
  /* -------------------------------------------------------------- */

  renderInvalidateAgreementPassword = () => {
    const { personDetails, match } = this.props;
    const { personId } = match.params;

    if (!personDetails.agreementPassword) return null;

    return <InvalidateAgreementPassword personId={personId} />;
  };

  renderPersonContractExtract = () => {
    const { personDetails, match, history } = this.props;
    const { personId } = match.params;

    const { marginCard, marginLoan, marginExpirationDate } = personDetails;

    return (
      <Action
        onPress={() =>
          history.push({
            pathname: `/backoffice/people/${personId}/contract-extract`,
            state: { marginCard, marginLoan, marginExpirationDate },
          })
        }
        title="Extrato de Contratos"
      />
    );
  };

  renderInputFile = () => (
    <input
      ref={(ref) => {
        this.inputFile = ref;
      }}
      type="file"
      accept="application/pdf"
      onChange={this.onPressUploadHiscon}
      style={{ display: 'none' }}
    />
  );

  render() {
    const { personDetails, match, onPressSendBiometry } = this.props;
    const { personId } = match.params;
    const { agreementName } = personDetails;

    return (
      <div style={{ marginLeft: 16 }}>
        {this.renderApproveSection()}
        <Card title="Ações" hiddenOverflow>
          <SendBiometry onPressSendBiometry={onPressSendBiometry} />
          <AccessByCustomer />
          {this.renderPersonContractExtract()}
          <Notification />
          {this.renderRegisterOfferPerson()}
          {this.renderFillContract()}
          {this.renderInvalidateAgreementPassword()}
          {this.renderRequestIN100()}
          <HisconActions agreementName={agreementName} personId={personId} />
          <ConcessionActions agreementName={agreementName} personId={personId} />
          {this.renderMedicalCertificate()}
          {this.renderInputFile()}
        </Card>
        <Card title="Pendências" style={{ marginTop: 16 }} hiddenOverflow shouldHideIfIsEmpty>
          {this.renderRequestAgreementPassword()}
          {this.renderInputFile()}
        </Card>
      </div>
    );
  }
}

Actions.defaultProps = {
  showLinkContractOwner: false,
};

export default Actions;
