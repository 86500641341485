import React, { useEffect, useRef } from 'react';
import { Form, RichText, Shimmer } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import { createTicketComment } from '~/store/tickets/actions';
import { TicketComment as IComment } from '~/typings/entities/Ticket';
import { getLoggedUser } from '~/store/user/actions';
import { Divider } from 'antd';
import { SectionTitle } from '../TicketDetails.styled';
import {
  CommentDate,
  CommentsContainer,
  CommentsList,
  Comment,
  NoContentMessage,
  CommentAuthorRow,
} from './TicketComments.styled';
import { TicketComment } from './TicketComment';

type TicketCommentsProps = {
  ticketId: string;
  comments?: IComment[];
  loading?: boolean;
};

export function TicketComments({ ticketId, comments, loading }: TicketCommentsProps) {
  const formRef = useRef(null);
  const createCommentControl = useAwaitControl(createTicketComment);
  const getLoggedUserControl = useAwaitControl(getLoggedUser);

  const loggedUser = getLoggedUserControl.result();

  const createCommentLoading = createCommentControl.isRunning();
  const createCommentSuccess = createCommentControl.isSuccessful();

  const handlePublishComment = ({ comment }) => {
    const commentData: IComment = {
      ticketId,
      description: comment,
      authorNameId: loggedUser.id,
      authorName: loggedUser.name,
      publicComment: true,
    };

    createCommentControl.start(commentData);
  };

  useEffect(() => {
    if (createCommentSuccess) {
      formRef.current.setFieldsValue({ comment: null });
    }
  }, [createCommentSuccess]);

  return (
    <CommentsContainer>
      <SectionTitle>Comentários</SectionTitle>

      <Form
        ref={formRef}
        onSubmit={handlePublishComment}
        loading={createCommentLoading || loading ? 1 : 0}
        inputs={[
          {
            id: 'comment',
            input: <RichText small showActionButton placeholder="Escreva um novo comentário" />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
        ]}
      />

      {!loading && !comments?.length && (
        <NoContentMessage>Nenhum comentário encontrado</NoContentMessage>
      )}

      {!comments?.length &&
        loading &&
        Array.from({ length: 2 }).map((_, i) => (
          <Comment key={i}>
            <CommentAuthorRow>
              <Shimmer height={20} width={20} circular />
              <Shimmer height={20} width={100} />
            </CommentAuthorRow>

            <CommentDate>
              <Shimmer height={15} width={200} />
            </CommentDate>

            <Shimmer height={20} width={2000} />
            <Shimmer height={20} width={2000} />
            <Shimmer height={20} width={400} />
          </Comment>
        ))}

      <CommentsList>
        {!loading &&
          comments?.map((comment) => (
            <React.Fragment key={comment.id}>
              <TicketComment ticketId={ticketId} comment={comment} />
              <Divider />
            </React.Fragment>
          ))}
      </CommentsList>
    </CommentsContainer>
  );
}
