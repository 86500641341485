import React from 'react';
import { Row } from 'antd';

import { Card } from '~/components';
import Fields from './fields';
import FilterButton from './FilterButton';
import { TableFilterProps } from './Props';
import { TableFilterContextProvider } from './TableFilterContext';

export default function TableFilter({
  fields,
  onSubmit,
  validate,
  onValidationError,
}: TableFilterProps) {
  return (
    <TableFilterContextProvider
      onSubmit={onSubmit}
      validate={validate}
      onValidationError={onValidationError}
    >
      <Card title="Filtros">
        <div style={{ padding: '16px 6px' }}>
          <Row type="flex" justify="start">
            {fields.map((field, index) => {
              const Wrapper = field.wrapperComponent || React.Fragment;
              const Field = Fields[field.type];
              return (
                <Wrapper key={index}>
                  <Field definition={field} index={index} />
                </Wrapper>
              );
            })}
          </Row>
          <Row style={{ marginTop: '21px' }}>
            <FilterButton />
          </Row>
        </div>
      </Card>
    </TableFilterContextProvider>
  );
}
