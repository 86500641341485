import { put, takeLatest } from 'redux-saga/effects';

import takeRequest from '~/store/takeRequest';

import * as ProposalsActions from './actions';
import { updateAccountParati } from './actions';

export function* reloadProposals({ meta: { payload } }: any) {
  yield put(ProposalsActions.listContractProposals.start({ ...payload }));
}

function* reloadParatiReturns({ meta: { payload } }: any) {
  yield put(ProposalsActions.listReturnsParati.start(payload));
}

export function* proposalsSaga() {
  yield takeRequest(ProposalsActions.listActionHistoric);
  yield takeRequest(ProposalsActions.cancelQiProposal);
  yield takeRequest(ProposalsActions.revokeCancelQiProposal);
  yield takeRequest(ProposalsActions.signContract);
  yield takeRequest(ProposalsActions.forceResubmitPaymentQiContract);
  yield takeRequest(ProposalsActions.cancelQiReservation);
  yield takeRequest(ProposalsActions.listQiActionHistoric);
  yield takeRequest(ProposalsActions.listActionProposals);
  yield takeRequest(ProposalsActions.listQIActionProposals);
  yield takeRequest(ProposalsActions.listContractProposals);
  yield takeRequest(ProposalsActions.cancelInternalProposal);
  yield takeRequest(ProposalsActions.removePreProposal);
  yield takeRequest(ProposalsActions.removeProposal);
  yield takeRequest(ProposalsActions.listStubbornReturnsParati);
  yield takeRequest(ProposalsActions.updateAccountParati);

  yield takeLatest(ProposalsActions.cancelInternalProposal.success.toString(), reloadProposals);
  yield takeLatest(ProposalsActions.updateAccountParati.success.toString(), reloadParatiReturns);
  yield takeLatest(ProposalsActions.removePreProposal.success.toString(), reloadParatiReturns);
  yield takeLatest(ProposalsActions.removeProposal.success.toString(), reloadParatiReturns);
}
