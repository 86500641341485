import styled from 'styled-components';

export const Ol = styled.ol`
  list-style: none;

  li {
    position: relative;
    counter-increment: inst;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.element.primary};

    &::before {
      content: counter(inst);
      background: ${({ theme }) => theme.colors.background.tertiary};
      color: ${({ theme }) => theme.colors.element.primary};
      text-align: center;
      height: 24px;
      width: 24px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      margin-right: 16px;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }
  }
`;
