import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';

import getInitials from '~/common/getInitials';
import masked from '~/common/masked';
import { Shimmer, Tag, Tooltip } from '~/ui/components';
import { Person, PersonRegistrationLog } from '~/typings/entities/person';
import { ProfileTags } from '~/components';
import { UpdatesPopover } from '~/components/ProfileResume/UpdatesPopover';
import { CancelPopover } from '~/components/ProfileResume/CancelPopover';
import { PronounsPopover } from '~/components/ProfileResume/PronounsPopover';
import { updatePersonInfo, getPersonRegistrationLogs } from '~/store/people/actions';
import { formatName } from '~/common';
import { ClixClient } from '~/typings/entities/Clix';
import getAccountType from '~/typings/enums/AccountType';
import {
  ProfileResumeContainer,
  ProfileInitials,
  ProfileImageContainer,
  ProfileName,
  ProfileInfo,
  ProfileAge,
  ProfileInfoWithPopover,
  ExtraTagsWrapper,
  CopyButton,
  Copied,
  NameContent,
  ProfileWrapper,
  Icon,
} from './ProfileResume.styled';
import { RefusedPopover } from './RefusedPopover';
import { TicketButton } from './TicketButton';

const PRONOUNS = ['Ela/Dela', 'Ele/Dele', 'Elu/Delu'];

interface ProfileResumeProps {
  person: Person;
  clixDetails?: ClixClient;
  loading?: boolean;
  image?: any;
  responsiveActions?: React.ReactNode;
  className?: string;
}

export function ProfileResume({
  person,
  loading,
  image,
  responsiveActions,
  ...props
}: ProfileResumeProps) {
  const [copied, setCopied] = useState(false);

  const history = useHistory();
  const theme = useTheme();

  const updatePersonInfoControl = useAwaitControl(updatePersonInfo);
  const listPersonRegistrationLogControl = useAwaitControl(getPersonRegistrationLogs);

  const isPersonPage = history.location.pathname.startsWith('/backoffice/people');

  const canceledByClient = useMemo(() => person?.nameUserWhoCancel === person?.name, [person]);

  const formatDate = useCallback((dateToFormat, isDateAndHour = true) => {
    if (!dateToFormat) return 'Indisponível';

    if (isDateAndHour) {
      return moment(dateToFormat).format('DD/MM/YYYY [às] HH:mm');
    }

    return moment(dateToFormat).format('DD/MM/YYYY');
  }, []);

  const humanizeDate = useCallback((dateToFormat) => {
    if (!dateToFormat) return 'Indisponível';

    return masked.toAge(dateToFormat);
  }, []);

  const imageOrInitials = useMemo(
    () =>
      image && image.uri ? (
        <Avatar className="avatar" shape="circle" src={image?.uri} />
      ) : (
        <ProfileInitials>{getInitials(person.name)}</ProfileInitials>
      ),
    [image, person.name],
  );

  const registerTag = useMemo(() => {
    if (['REFUSED'].includes(person?.registrationStatus)) {
      return {
        label: 'CADASTRO REPROVADO',
        textColor: theme.colors?.negative?.primaryAlt,
        bgColor: theme.colors?.negative?.secondary,
      };
    }

    if (['APPROVED', 'COMPLETE'].includes(person?.registrationStatus)) {
      return {
        label: 'CADASTRO APROVADO',
        textColor: theme.colors?.positive?.primaryAlt,
        bgColor: theme.colors?.positive?.secondary,
      };
    }

    return null;
  }, [person]);

  const handlePronounChange = useCallback(
    (pronounTreatment: string) => {
      updatePersonInfoControl.start({ pronounTreatment, id: person?.id });
    },
    [person?.id],
  );

  const handleCopy = useCallback(() => {
    const clipBoard = navigator.clipboard;

    clipBoard.writeText(window.location.href).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  }, [window.location.href]);

  const copyButton = useMemo(
    () => (
      <Tooltip className="copyButton" content={copied ? 'COPIADO!' : 'COPIAR LINK DO CLIENTE'}>
        {copied ? (
          <Copied data-testid="copied-check-icon" />
        ) : (
          <CopyButton data-testid="copy-button" onClick={handleCopy} />
        )}
      </Tooltip>
    ),
    [copied],
  );

  useEffect(() => {
    if (person.id) {
      listPersonRegistrationLogControl.start({ personId: person.id });
    }
  }, [person]);

  const personRegistrationLog =
    listPersonRegistrationLogControl.result() as PersonRegistrationLog[];

  return (
    <ProfileResumeContainer {...props}>
      {loading ? (
        <>
          <Shimmer circular width={88} height={88} />
          <ProfileInfo>
            <Shimmer width={220} height={20} />
            <Shimmer width={185} height={20} />
            <Shimmer width={160} height={20} />
            <Shimmer width={160} height={20} />
            <Shimmer width={100} height={20} />
          </ProfileInfo>
        </>
      ) : (
        <>
          {!responsiveActions && <TicketButton />}

          <ProfileImageContainer>{imageOrInitials}</ProfileImageContainer>

          <ProfileWrapper>
            <ProfileInfo>
              {registerTag && (
                <Tag
                  className="tag"
                  textColor={registerTag.textColor}
                  bgColor={registerTag.bgColor}
                  small
                  rounded
                >
                  {registerTag.label}
                </Tag>
              )}

              <NameContent>
                {!isPersonPage ? (
                  <>
                    <Link to={`/backoffice/people/${person?.id}`}>
                      <ProfileName withLink>{formatName(person.name)}</ProfileName>
                    </Link>
                    <Icon>{copyButton}</Icon>
                  </>
                ) : (
                  <>
                    <ProfileName>{formatName(person.name)}</ProfileName>
                    <Icon>{copyButton}</Icon>
                  </>
                )}
              </NameContent>

              {person?.birthDate ? (
                <ProfileAge>{humanizeDate(person?.birthDate)} de idade</ProfileAge>
              ) : (
                <ProfileAge>Idade indisponível</ProfileAge>
              )}

              <ProfileInfoWithPopover>
                Últimas atualizações <UpdatesPopover lastAccess={formatDate(person?.lastAcess)} />
              </ProfileInfoWithPopover>

              <ProfileInfoWithPopover>
                Pronomes: <b>{person?.pronounTreatment}</b>{' '}
                <PronounsPopover
                  pronouns={PRONOUNS}
                  selected={person?.pronounTreatment}
                  onChange={handlePronounChange}
                />
              </ProfileInfoWithPopover>

              {!!person?.disabled && (
                <ProfileInfoWithPopover>
                  {canceledByClient ? (
                    'Cancelado pelo cliente'
                  ) : (
                    <>
                      Agente que reprovou: <b>{person?.nameUserWhoCancel}</b>
                    </>
                  )}
                  <CancelPopover canceledByClient={canceledByClient} person={person} />
                </ProfileInfoWithPopover>
              )}

              {personRegistrationLog?.length > 0 && (
                <ProfileInfoWithPopover>
                  Histórico de reprovação:
                  <RefusedPopover registrationLog={personRegistrationLog} />
                </ProfileInfoWithPopover>
              )}

              <ExtraTagsWrapper>
                {person?.accountType === 'MAGNETIC_CARD' && (
                  <Tag small rounded>
                    {getAccountType(person?.accountType)}
                  </Tag>
                )}
              </ExtraTagsWrapper>

              <ProfileTags externalId={person?.id} type="PERSON" />
            </ProfileInfo>
            {responsiveActions}
          </ProfileWrapper>
        </>
      )}
    </ProfileResumeContainer>
  );
}
