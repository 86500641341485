import React from 'react';
import { Popover } from '~/ui/components';
import { Ticket } from '~/typings/entities/Ticket';

import { TicketResume } from '~/components/Ticket/TicketDetails/TicketResume/TicketResume';

import { CellLink } from './Tickets.styled';

type TicketResumePopoverProps = {
  ticket: Ticket;
  disabled?: boolean;
};

export function TicketResumePopover({ ticket, disabled }: TicketResumePopoverProps) {
  return (
    <Popover
      title="Resumo do Ticket"
      width={560}
      placement="bottomRight"
      content={<TicketResume ticket={ticket} noBorder />}
    >
      <CellLink disabled={disabled}>{`#${ticket.id}`}</CellLink>
    </Popover>
  );
}
