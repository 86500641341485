const LOAN_TYPE = {
  NEW: 'Novo',
  REFIN: 'Refinanciamento',
  PORTABLE: 'Portabilidade',
  FUTUREMARGIN: 'Margem Futura',
  PORTABILITYWITHOUTREFIN: 'Portabilidade sem Refin',
};

const LoanFactorType = (props: any) => {
  if (props) {
    return LOAN_TYPE[props.value] || LOAN_TYPE[props] || null;
  }

  return null;
};

export default LoanFactorType;
