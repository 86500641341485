import React, { useEffect } from 'react';
import { Icon, Timeline } from 'antd';
import { useAwaitControl } from 'react-redux-await-control';
import { getContractLogs } from '~/store/contracts/actions';
import colors from '~/ui/theme/colors';
import {
  HistoryDate,
  HistoryItem,
  HistoryItemTitle,
  HistoryRow,
  LoadingContainer,
  TimelineContainer,
} from '~/screens/Person/components/Modals/ContractHistory/ContractHistory.styled';
import moment from 'moment';

interface ContractHistoryProps {
  id: string;
}

export function ContractHistory({ id }: ContractHistoryProps) {
  const logsControl = useAwaitControl(getContractLogs);
  const logsLoading = logsControl.isRunning();
  const logs = logsControl.result();

  const keysFilter = (el) => !['Data', 'Motivo'].includes(el);

  const formatDate = (date) =>
    moment(date, 'DD/MM/YYYY hh:mm:ss').format('DD [de] MMM YYYY HH[h]mm');

  useEffect(() => {
    if (id) {
      logsControl.start({ id });
    }

    return () => {
      logsControl.clear();
    };
  }, []);

  if (logsLoading) {
    return (
      <LoadingContainer>
        <Icon type="loading" style={{ fontSize: '35px', color: colors.primary.main }} />
      </LoadingContainer>
    );
  }

  return (
    <TimelineContainer>
      <Timeline>
        {logs?.map((log, key) => (
          <HistoryRow key={key}>
            <HistoryDate>{formatDate(log.Data)}</HistoryDate>
            <HistoryItem>
              <HistoryItemTitle>{log.Motivo}</HistoryItemTitle>
              {Object.keys(log)
                .filter(keysFilter)
                .map((key) => (
                  <div key={key}>
                    <b>{key}: </b>
                    <span>{log[key]}</span>
                  </div>
                ))}
            </HistoryItem>
          </HistoryRow>
        ))}
      </Timeline>
    </TimelineContainer>
  );
}
