const CREDIT_CARD_TYPE = {
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
};

const CreditCardType = (props: any) => {
  if (props) {
    return CREDIT_CARD_TYPE[props.value] || CREDIT_CARD_TYPE[props] || null;
  }
  return null;
};

export default CreditCardType;
