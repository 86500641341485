/* @flow */

import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, lifecycle, withPropsOnChange, withState } from 'recompose';
import { Radio } from 'antd';
import Masker from 'vanilla-masker';
import theme from '~/themes/aphroditeTheme/theme';
import masked from '~/common/masked';
import { TableReact as Table, Container, Spinner } from '~/components';

import fetchAvailableLoanHistories, {
  FETCH_AVAILABLE_LOAN_HISTORIES_REQUEST,
  FETCH_AVAILABLE_LOAN_HISTORIES_SUCCESS,
  FETCH_AVAILABLE_LOAN_HISTORIES_FAILURE,
} from '~/store/fetchAvailableLoanHistories/action';

import '~/common/styles/radiobutton.css';
import '~/common/styles/textAlignment.css';

import styles from './styles';

const { css, withStyles } = theme;

const RadioGroup = Radio.Group;
const { toDate } = masked;

const moneyFormatter = {
  unit: 'R$',
};

type Props = {
  styles: any,
};

class LoanHistoryTable extends Component<Props> {
  state = {
    selectedLoanHistory: undefined,
  };

  presentation = {
    columns: [
      {
        accessor: 'loanHistoryId',
        name: 'Código',
        props: {
          width: 130,
          Cell: row => (
            <div className='text-center'>{row.original.loanHistoryId}</div>
          ),
        },
      },
      {
        accessor: 'installmentValue',
        name: 'Valor da Parcela',
        props: {
          width: 150,
          Cell: row => {
            if (row.original.installmentValue) {
              return Masker.toMoney(
                row.original.installmentValue.toFixed(2),
                moneyFormatter,
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'numberOfInstallments',
        name: 'N° de Parcelas',
        props: {
          width: 170,
        },
      },
      {
        accessor: 'reservationKey',
        name: 'Chave de Reserva',
        props: {
          width: 170,
        },
      },
      {
        accessor: 'contractValue',
        name: 'Valor',
        props: {
          Cell: row => {
            if (row.original.contractValue) {
              return Masker.toMoney(
                row.original.contractValue.toFixed(2),
                moneyFormatter,
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'contractDate',
        name: 'Data',
        props: {
          width: 100,
          Cell: row => {
            if (row.original.contractDate) {
              return toDate(row.original.contractDate);
            }
            return null;
          },
        },
      },
      {
        name: 'Vincular',
        props: {
          width: 80,
          Cell: row => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <RadioGroup
                value={this.state.selectedLoanHistory}
                onChange={e =>
                  this.onPressSelectedLoanHistory(row.original.loanHistoryId, e)
                }
              >
                <Radio value={row.original.loanHistoryId} />
              </RadioGroup>
            </div>
          ),
        },
      },
    ],
  };

  onPressSelectedLoanHistory = (loanHistoryId, e) => {
    if (e.target.value) {
      this.setState({ selectedLoanHistory: loanHistoryId }, () => {
        this.props.getSelectedLoanHistoryFromTable(
          this.state.selectedLoanHistory,
        );
      });
    }
  };

  renderHeading(text) {
    const { styles } = this.props;
    return (
      <div {...css(styles.heading)}>
        <h3 {...css(styles.headingText)}>{text}</h3>
      </div>
    );
  }

  renderTable() {
    const { availableLoanHistories, styles } = this.props;

    return (
      <div>
        {this.renderHeading('Contratos Disponíveis para Portabilidade')}
        <Table
          items={availableLoanHistories}
          presentation={this.presentation}
          className={css(styles.table).className}
          hidePagination
        />
      </div>
    );
  }

  renderEmpty() {
    const { emptyMessage, isLoading, styles } = this.props;

    if (isLoading || !emptyMessage) return null;

    return (
      <div {...css(styles.status)}>
        <i>{emptyMessage}</i>
      </div>
    );
  }

  render() {
    return (
      <Container>
        <Spinner spinning={this.props.isLoading} />
        <div>
          {this.renderTable()}
          {this.renderEmpty()}
        </div>
      </Container>
    );
  }
}

//Adapitar para a nova tabela
LoanHistoryTable.defaultProps = {
  availableLoanHistories: [],
};

function componentDidMount() {
  const { personId, bankNumber } = this.props;

  const params = { personId, bankNumber };

  this.props.dispatch(fetchAvailableLoanHistories(params));
}

function mapStateToProps(state) {
  return {
    availableLoanHistories: state.getIn(['fetchAvailableLoanHistories']),
  };
}

function receiveChanges(
  prevProps,
  { availableLoanHistories, isLoading, setIsLoading, setEmptyMessage },
) {
  if (
    Immutable.is(prevProps.availableLoanHistories, availableLoanHistories) ===
    false
  ) {
    switch (availableLoanHistories.getIn(['type'])) {
      case FETCH_AVAILABLE_LOAN_HISTORIES_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case FETCH_AVAILABLE_LOAN_HISTORIES_SUCCESS: {
        setIsLoading(false);
        if (availableLoanHistories.getIn(['payload']).entries.length !== 0) {
          setEmptyMessage('');
        }
        return true;
      }
      case FETCH_AVAILABLE_LOAN_HISTORIES_FAILURE: {
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  return false;
}

function propagateStateChangeToProps(state) {
  const { entries } = state.availableLoanHistories.toJS().payload;
  return {
    availableLoanHistories: entries,
  };
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  lifecycle({
    componentDidMount,
  }),
  withState('isLoading', 'setIsLoading', true),
  withState('emptyMessage', 'setEmptyMessage', 'Nenhum Registro Encontrado'),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
)(LoanHistoryTable);
