import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PdfPreview } from '~/components/PreviewModal/PdfPreview';
import { ImageControl } from '~/ui/components';
import { useModal } from '~/hooks/useModal';
import { PreviewContainer } from './PreviewModal.styled';

type PreviewModalProps = {
  file?: File | null;
  url?: string;
  isPdf?: boolean;
  name?: string;
  withControls?: boolean;
};

export function PreviewModal({ file, url, name, isPdf, withControls }: PreviewModalProps) {
  const [filePreview, setFilePreview] = useState<string | ArrayBuffer | null>();
  const { closeModal } = useModal();

  const handleOnClose = useCallback(() => {
    closeModal('preview-modal');
  }, []);

  const imagePreview = useMemo(() => {
    if (withControls) {
      return (
        <ImageControl
          file={{ url: (filePreview as string) || url, name }}
          onClose={handleOnClose}
        />
      );
    }

    return <img src={(filePreview as string) || url} alt="document" />;
  }, [filePreview, url, name]);

  useEffect(() => {
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
    }

    return () => {
      reader.abort();
    };
  }, [file]);

  return (
    <PreviewContainer withControls={withControls}>
      {isPdf ? <PdfPreview file={filePreview} url={url} /> : imagePreview}
    </PreviewContainer>
  );
}
