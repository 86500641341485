import styled from 'styled-components';

import { breath } from '~/ui/theme/animations';

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 48px;
    animation: 1.42857s linear 0s infinite reverse forwards running ${breath};
  }
`;
