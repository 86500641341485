// @flow
import React from 'react';

import useTimer from '~/hooks/useTimer';

import { TimerIcon, TimerValue } from './styled';

type TimerProps = {
  endTime: string,
};

export default function Timer({ endTime }: TimerProps) {
  const value = useTimer(endTime, false, 30_000);

  return (
    <div className="text-center">
      <TimerIcon />
      {value && (
        <TimerValue>
          {value}
        </TimerValue>
      )}
    </div>
  );
}
