import React, { useEffect, useState } from 'react';
import { ConfirmData } from './components/ConfirmData';
import { UploadFileForm } from './components/UploadFileForm';
import { StepsContainer } from './UploadFileModal.styled';

const reasons = [
  { value: '1', name: 'Boleto de quitação', enum: 'PAYMENTSLIP' },
  { value: '2', name: 'Comprovante de CPF', enum: 'CPFPROOF' },
];

export type UploadFileModalProps = {
  id: string | number;
};

export function UploadFileModal({ id }: UploadFileModalProps) {
  const [step, setStep] = useState(1);
  const [animationClass, setAnimationClass] = useState('');
  const [data, setData] = useState({
    uploadReason: null,
    comment: '',
    file: null,
  });

  const onSubmit = (values) => {
    const { uploadReason, comment, file } = values;
    const uploadReasonIndex = Number(uploadReason) - 1;
    const { name, enum: reasonEnum } = reasons[uploadReasonIndex];

    setData({
      uploadReason: {
        name,
        enum: reasonEnum,
        value: Number(uploadReason),
      },
      comment,
      file,
    });

    setAnimationClass('slideOut');
    setTimeout(() => {
      setStep(2);
    }, 250);
  };

  const backStep = () => {
    setAnimationClass('slideOut slideRight');
    setTimeout(() => {
      setStep(1);
    }, 250);
  };

  useEffect(() => {
    if (step === 1) {
      setAnimationClass('slideRight');
      setTimeout(() => setAnimationClass('slideIn'), 100);
      return;
    }

    setAnimationClass('slideOut slideLeft');
    setTimeout(() => setAnimationClass('slideLeft slideIn'), 100);
  }, [step]);

  if (step === 1) {
    return (
      <StepsContainer className={animationClass}>
        <UploadFileForm data={data} onSubmit={onSubmit} />
      </StepsContainer>
    );
  }

  return <ConfirmData id={id} backStep={backStep} data={data} />;
}
