// @flow

import React, { useState } from 'react';
import { Pendency } from '~/screens/ContractDetails/Panels/Pendencies/styled';
import pendencyTitle from '~/typings/enums/PendencyType';
import { Button, Modal } from '~/components';
import Selection from '~/screens/ContractDetails/Panels/Pendencies/components/actions/Documents/components/Selection';

type DocumentsProps = {
  onPressSend: () => void,
  id?: number,
  type?: string,
};

function Documents(props: DocumentsProps) {
  const [showModal, setStateShowModal] = useState(false);
  const [files, setFiles] = useState([]);
  const setShowModal = (show) => () => {
    setStateShowModal(show);
  };
  const onFilesChange = (files) => {
    setFiles(files);
  };

  const onPressSend = () => {
    setShowModal(false)();
    props.onPressSend(files);
  };

  return (
    <>
      <Pendency onClick={setShowModal(true)}>
        {pendencyTitle(props.type) || props.children}
        <span>{props.reason}</span>
      </Pendency>
      <Modal
        title="Enviar documentos"
        maskClosable
        width={800}
        visible={showModal}
        key="modal"
        okText="Enviar"
        onCancel={setShowModal(false)}
        onOk={onPressSend}
        footer={[<Button title="Enviar" onClick={onPressSend} />]}
      >
        <Selection onFilesChange={onFilesChange} />
      </Modal>
    </>
  );
}

export default Documents;
