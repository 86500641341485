import React from 'react';
import { Col } from 'antd';
import { ColProps } from 'antd/lib/grid/col';

import { FieldWrapperProps } from '~/components/TableFilter/Props';

const sizeProps: ColProps = { xs: { span: 24 }, md: { span: 12 }, lg: { span: 8 } };
const styles = {
  col: {
    marginBottom: '16px',
    padding: '0px 10px',
    display: 'flex',
    'flex-direction': 'column',
    gap: 8,
  },
};

export default function FieldWrapper({ children }: FieldWrapperProps) {
  return (
    <Col style={styles.col} {...sizeProps}>
      {children}
    </Col>
  );
}
