import React from 'react';

import moment from 'moment';

import modalBuilder from '~/hoc/modalBuilder';
import { Money } from '~/common';

import { InstallmentsContainer } from './styled';

const MODAL_NAME = 'FGTS_INSTALLMENTS';

type DataValidProps = {
  installments: any[];
}

function FGTSInstallments({ installments }: DataValidProps) {
  return (
    <InstallmentsContainer>
      {installments.map((i) => (
        <div className="installment" key={i.installmentNumber}>
          <span>{moment(i.dueDate).format('DD/MM/YYYY')}</span>
          <span className="value">{Money(i.totalAmount)}</span>
        </div>
      ))}
    </InstallmentsContainer>
  );
}

export default modalBuilder(FGTSInstallments, MODAL_NAME, {
  title: 'Como será descontado',
  okText: 'Entendi',
  customFooter: false,
  centered: true,
});
