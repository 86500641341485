import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getContractDetails } from '~/store/contracts/actions';
import { solveBiometry, getPendencies } from '~/store/pendencies/actions';
import getBanks from '~/store/fetchFinancialInstitutionsForDeposit/action';
import Pendencies from '~/screens/ContractDetails/Panels/Pendencies/Pendencies';
import { useAwaitControl } from 'react-redux-await-control';

type PendenciesContainerProps = {
  contractId?: number,
  personId?: number,
};

export const PendenciesContext = React.createContext({
  getPendencies: () => {},
});

export function PendenciesContainer(props: PendenciesContainerProps) {
  const getPendenciesControl = useAwaitControl(getPendencies);
  const pendencies = getPendenciesControl.result();
  const loading = getPendenciesControl.isRunning();

  const fetchPendencies = () => {
    const { contractId, personId } = props;

    getPendenciesControl.start({
      contractId,
      personId: contractId ? undefined : personId,
    });
  };

  const isLoading = () => {
    const {
      bankAccountLoading,
      biometryLoading,
      documentLoading,
      paycheckLoading,
      addressLoading,
      personalDataLoading,
      proofOfAddressLoading,
    } = props;
    return (
      loading ||
      bankAccountLoading ||
      addressLoading ||
      biometryLoading ||
      documentLoading ||
      paycheckLoading ||
      personalDataLoading ||
      proofOfAddressLoading
    );
  };

  useEffect(() => {
    const { contractId } = props;

    if (contractId) {
      props.dispatch(getBanks({ contractId }));
    }

    fetchPendencies();
  }, []);

  return (
    <PendenciesContext.Provider
      value={{
        getPendencies: fetchPendencies,
      }}
    >
      <Pendencies loading={isLoading()} pendencies={pendencies} />
    </PendenciesContext.Provider>
  );
}

function mapStateToProps(state) {
  return {
    details: getContractDetails.getResult()(state),
    bankAccountLoading: state.getIn(['solveBankAccount', 'isLoading']),
    addressLoading: state.getIn(['solveAddress', 'isLoading']),
    biometryLoading: solveBiometry.isRunning()(state),
    documentLoading: state.getIn(['solveDocument', 'isLoading']),
    paycheckLoading: state.getIn(['solvePaycheck', 'isLoading']),
    proofOfAddressLoading: state.getIn(['solveProofOfAddress', 'isLoading']),
  };
}

export default compose(connect(mapStateToProps), withRouter)(PendenciesContainer);
