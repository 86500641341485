import React from 'react';

import { ContractStatus, WarrantyTypeNames } from '~/typings/enums/Clix';
import toDate from '~/common/masked/toDate';
import { ClixContract } from '~/typings/entities/Clix';
import { FileSearch } from '~/ui/assets/icons';
import { Table, TableColumns } from '~/ui/components';

import { LinkButton, PersonsTableContainer } from './ContractsTable.styled';

export type PersonsTableProps = {
  loading?: boolean;
  data: ClixContract[];
};

export function ContractsTable({ loading, data }: PersonsTableProps) {
  const columns: TableColumns<ClixContract> = [
    {
      accessorKey: 'customerName',
      header: () => 'NOME',
    },
    {
      accessorKey: 'signatureDate',
      header: () => 'DATA DE ASSINATURA',
      cell: ({ getValue }) => toDate(getValue()) || '-',
    },
    {
      accessorKey: 'warrantyType',
      header: () => 'TIPO DE CONTRATO',
      cell: (info) => {
        const warrantyType = info.getValue();
        return WarrantyTypeNames[warrantyType as string] || warrantyType || '-';
      },
    },
    {
      accessorKey: 'status',
      header: () => 'STATUS',
      cell: ({ getValue }) => {
        const status = getValue();
        return ContractStatus[status as string] || status || '-';
      },
    },
    {
      id: 'actions',
      cellAlign: 'center',
      headerAlign: 'center',
      header: () => 'DETALHES',
      cell: ({ row }) => (
        <LinkButton
          to={`/backoffice/clix/${row.original.personId}?status=${row.original.status}&warranty=${row.original.warrantyType}`}
          title="Detalhes"
        >
          <FileSearch width={24} height={24} />
        </LinkButton>
      ),
    },
  ];

  return (
    <PersonsTableContainer>
      <Table
        noItemsMessage="Nenhum contrato encontrado"
        loading={loading}
        columns={columns}
        data={data}
        maxHeight={500}
        responsiveCols={['customerName', 'signatureDate', 'warrantyType', 'status', 'actions']}
        isTHeadFixed
      />
    </PersonsTableContainer>
  );
}
