import styled from 'styled-components';
import { Button } from '~/ui/components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

export const ActionButton = styled(Button).attrs({
  variant: 'text',
  rounded: true,
  size: 'sm',
  icon: 'Dots',
  color: 'black',
})`
  max-height: 22px;
  height: 22px;
  min-width: 22px;
`;

export const Option = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  size: 'sm',
  customColor: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  fullWidth: true,
}))`
  padding: 12px;
  height: 32px;
  justify-content: start;

  .typography {
    font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
    font-weight: ${({ theme }) => theme.typography.types.paragraphSmall.weight};
  }

  b {
    font-weight: 600;
  }

  svg {
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: ${themeColor('state.hover')};
    svg {
      color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    }
  }
`;

export const TwilioOption = styled(Option).attrs({
  tooltip: 'Protocolo da Twilio',
})`
  cursor: default;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.error[600]};
  }

  .typography {
    color: ${({ theme }) => theme.colors.error[600]};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.error[600]};
    }

    .typography {
      color: ${({ theme }) => theme.colors.error[600]};
    }
  }
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  gap: 4px;
`;
