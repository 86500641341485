/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { REGISTER_LOAN_HISTORY_REQUEST, success, failure } from './action';

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(REGISTER_LOAN_HISTORY_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(payload =>
      AjaxRequest.open('post', '/admin/loanhistorycustom/register', payload)(
        'tudo',
      )
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
