import React from 'react';
import moment from 'moment';

import CommissioningType from '~/typings/enums/CommissioningType';
import { InfoItem } from '~/components';

import { ParatiProposal, ParatiProposalReturn } from '~/typings/entities/Proposals';
import { InfoContainer, Section, SectionTitle } from '../../Returns.styled';
import { ReturnsTable } from './ReturnsTable';

export type ProposalDetailsProps = {
  proposal: ParatiProposal;
  proposalReturns: ParatiProposalReturn[];
  contractId: string;
};

export function ProposalDetails({ contractId, proposal, proposalReturns }: ProposalDetailsProps) {
  return (
    <>
      <Section>
        <SectionTitle>Detalhes</SectionTitle>

        <InfoContainer>
          <InfoItem title="Código" value={proposal.proposalNumber} />
          <InfoItem title="Tentativa" value={proposal.attempt} />
          <InfoItem title="CCB Number" value={proposal.ccbNumber} />
          <InfoItem title="ID" value={proposal.id} />
          <InfoItem
            title="Data"
            value={moment(proposal.creationDate).format('DD/MM/YYYY [às] HH:mm:ss')}
          />
          <InfoItem title="Status" value={proposal.status} />
          <InfoItem title="Tipo" value={CommissioningType(proposal.type)} />
        </InfoContainer>
      </Section>

      <Section>
        <SectionTitle>Retornos</SectionTitle>
        <ReturnsTable contractId={contractId} accountReturns={proposalReturns} />
      </Section>
    </>
  );
}
