export default function style({ colors, unit }) {
  const fontFamily = 'Rubik, sans-serif';
  const color = '#636878';
  const button = {
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'inset 0 -1px 0 0 rgba(0,0,0,0.10)',
    color: 'white',
    cursor: 'pointer',
    fontFamily,
    fontSize: '12px',
    fontWeight: 'bold',
    height: '39px',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: 1.1,
    padding: `${unit * 1.2}px 0`,
    width: '180px',

    ':hover': {
      textDecoration: 'underline',
    },
  };
  const input = {
    border: 'none',
    color: '#B1B8C6',
    display: 'flex',
    fontFamily,
    fontSize: 14,
    letterSpacing: '-0.19px',
    outline: 'none',
    padding: unit * 1.5,
    width: '100%',

    '::placeholder': {
      color: '#B1B8C6',
    },
  };

  const ease = {
    '0%': {
      opacity: 0,
      transform: 'scale(0.7) translate3d(0,0,0)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1) translate3d(0,0,0)',
    },
  };

  const internalHeaders = {
    display: 'block',
    color: '#777',
    fontFamily,
    letterSpacing: 0,
    lineHeight: 1.2,
  };

  return {
    actions: {
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '56px',
      width: 250,
    },
    action: {
      cursor: 'pointer',
      fontFamily,
      lineHeight: 1.2,
      padding: `${unit * 1.5}px 0 0 0`,

      ':hover': {
        textDecoration: 'underline',
      },
    },
    payButton: {
      ...button,
      background: '#52B771',
      marginTop: unit * 1.5,
    },
    preset__medium: {
      color,
      display: 'block',
      fontFamily,
      fontSize: unit * 1.7,
      fontWeight: 'bold',
    },
    newCommissioning: {
      ...button,
      background: '#52B771',
      borderRadius: '4px',
      width: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    refuseButton: {
      color: '#C0392B',
      cursor: 'pointer',
      fontFamily,
      padding: `${unit * 2}px 0 0 0`,
      textDecoration: 'underline',
    },
    key: {
      display: 'block',
      color: '#949494',
      fontFamily,
      fontSize: unit * 0.9,
      letterSpacing: '0',
      textTransform: 'uppercase',
    },
    modal: {
      animationDuration: '150ms',
      animationTimingFunction: 'linear',
      animationFillMode: 'forwards',
      background: 'white',
      borderRadius: 3,
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1)',
      padding: unit * 2,
      position: 'relative',
      maxHeight: 'calc(100vh)',
      overflowY: 'auto',
    },
    modal_image: {
      animationName: ease,
      animationDuration: '150ms',
      animationTimingFunction: 'linear',
      animationFillMode: 'forwards',
      background: 'white',
      borderRadius: 3,
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1)',
      padding: unit * 2,
      position: 'relative',
      maxHeight: 'calc(100vh)',
      overflowY: 'hide',
      width: 'calc(98vw) !important',
    },
    modal__header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: unit * 1.2,
      marginBottom: unit * 2,
    },
    modal__header__image: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    modal__close: {
      border: 'none',
      outline: 'none',
      background: 'white',
      position: 'absolute',
      top: 5,
      right: 0,
    },
    closeButton: {
      color: '#777',
      transition: 'all 0.5s',

      ':hover': {
        color: 'black',
        background: '#dfdfdf',
        padding: '2px',
        borderRadius: 3,
      },
    },
    modal__title: {
      fontSize: 20,
      ...internalHeaders,
    },
    modal__section: {
      fontSize: 16,
      ...internalHeaders,
    },
    modal__content: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    modal__content_row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    input: {
      ...input,

      boxShadow:
        '0 1px 3px rgba(0, 0, 0, 0.1) inset, 0 1px 1px rgba(0, 0, 0, 0.1) inset',
      marginTop: unit * 0.5,
      marginBottom: unit,
    },
    buttonConfirm: {
      minWidth: '147px',
      marginTop: '15px',
    },
    preview__container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginBottom: '10px',
    },
    divider: {
      fontSize: '13px',
      margin: '0 0 10px 0',
    },
    buttonConfirmSend: {
      minWidth: '147px',
    },
  };
}
