import styled, { css } from 'styled-components';

import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-inline: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  margin-bottom: 25px;
  cursor: pointer;
  padding: 10px 0px;
  position: sticky;
  top: 0;
  background-color: ${themeColor('background.primary')};
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 25px;
  margin: 0 24px;
  border-radius: 10px;
  padding: 15px;
  background-color: ${themeColor('neutral.secondary')};
  max-height: calc(100vh - 275px);
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
`;

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-inline: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${themeColor('brand.secondary', { dark: 'brand.primaryAlt' })};
`;

export const ClientCard = styled.div`
  padding: 16px 26px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  background-color: ${themeColor('brand.secondary', { dark: 'brand.primaryAlt' })};
  border-radius: 10px;
`;

export const AgentCard = styled.div`
  padding: 16px 26px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  background-color: ${themeColor('element.disabledBackground', { dark: 'element.secondary' })};
  border-radius: 10px;
`;

export const DateCard = styled.div`
  padding: 10px 20px;
  background-color: ${themeColor('neutral.secondaryAlt')};
  border-radius: 10px;
`;

type DateWapperType = {
  marginTop: boolean;
};

export const DateWrapper = styled.div<DateWapperType>`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  height: 40px;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}
`;

export const Date = styled(Typography).attrs(({ theme, hour }) => ({
  type: 'footnote',
  element: 'label',
  weight: 400,
  color: hour
    ? themeToggleColor(theme, 'element.secondary', { dark: 'element.secondary' })
    : themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const Text = styled(Typography).attrs(({ theme, weight }) => ({
  type: 'caption',
  element: 'label',
  weight: weight || 600,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.invert' }),
}))``;

export const Name = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'label',
  weight: 600,
  color: themeToggleColor(theme, 'element.primary'),
}))``;

type PositionType = {
  justifyContent: string;
};

export const Position = styled.div<PositionType>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const Info = styled.div`
  display: flex;
  gap: 4px;
`;

type CardWrapperType = {
  alignItems: string;
  marginTop: boolean;
};

export const CardWrapper = styled.div<CardWrapperType>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: ${({ alignItems }) => alignItems};
  max-width: 80%;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 25px;
    `}
`;

type MessageWrapperType = {
  hasIcon: boolean;
};

export const MessageWrapper = styled.div<MessageWrapperType>`
  display: flex;
  gap: 8px;
  align-items: end;

  ${({ hasIcon }) =>
    !hasIcon &&
    css`
      margin-right: 44px;
    `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: end;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeColor('brand.secondary', { dark: 'brand.primaryAlt' })};
  border-radius: 36px;
  min-width: 36px;
  width: 36px;
  height: 36px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Title = styled(Typography).attrs(({ theme, size }) => ({
  type: 'paragraphSmall',
  element: 'label',
  size: size || 16,
  weight: 600,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;
