import { fetchAndActivate, getValue, RemoteConfig } from 'firebase/remote-config';
import { remoteConfig } from '../firebase';
import defaultValues from './defaultValues';

export const remoteValues = defaultValues;

export const remoteConfigSettings = {
  minimumFetchIntervalMillis: 21600000, // 6 hours
  fetchTimeoutMillis: 10000, // 10 seconds
};

const parseRemoteValue = (rc: RemoteConfig, key: string) => {
  const value = getValue(rc, key).asString();

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const normalizeRemoteValues = (values: any) => {
  const keys = Object.keys(values);
  const valuesCopy = { ...values };

  keys.forEach((key) => {
    if (typeof values[key] === 'object') valuesCopy[key] = JSON.stringify(values[key]);
  });

  return valuesCopy;
};

const updateRemoteValues = (rc: RemoteConfig) => {
  Object.keys(remoteValues).forEach((key) => {
    if (typeof remoteValues[key] === 'boolean') remoteValues[key] = getValue(rc, key).asBoolean();
    if (typeof remoteValues[key] === 'string') remoteValues[key] = getValue(rc, key).asString();
    if (typeof remoteValues[key] === 'number') remoteValues[key] = getValue(rc, key).asNumber();
    if (typeof remoteValues[key] === 'object') remoteValues[key] = parseRemoteValue(rc, key);
  });
};

export async function initRemoteConfig() {
  const rc = await remoteConfig();

  if (rc) {
    rc.settings = remoteConfigSettings;
    rc.defaultConfig = normalizeRemoteValues(remoteValues);

    await fetchAndActivate(rc);
    updateRemoteValues(rc);
  }
}
