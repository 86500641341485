function getSentryConfig(release, authToken, env = 'development') {
    if (env === 'production') {
      return {
        include: './build',
        environment: 'production',
        org: 'meutudo',
        project: 'backoffice-web',
        url: 'https://sentry.meutudo.app',
        dsn: 'https://8a1ae6699dc54b0485966d1da07e6070@sentry.meutudo.app/21',
        authToken,
        release,
        ignore: ['node_modules', 'webpack.config.js'],
      };
    }
  
    return {
      include: './build',
      environment: 'homolog',
      org: 'meutudo',
      project: 'backoffice-web',
      url: 'https://sentry.homolog.meutudo.app',
      dsn: 'https://f4d27e95167245f1b411d81150548581@sentry.homolog.meutudo.app/27',
      authToken,
      release,
      ignore: ['node_modules', 'webpack.config.js'],
    };
  }
  
  module.exports = { getSentryConfig };