import takeRequest from '~/store/takeRequest';

import * as walletActions from './actions';

export function* walletSaga() {
  yield takeRequest(walletActions.getCards);
  yield takeRequest(walletActions.getTransactions);
  yield takeRequest(walletActions.getTransactionHistory);
  yield takeRequest(walletActions.getTransactionReceipt);
}
