import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import toMoney from '~/common/masked/toMoney';
import toPercentage from '~/common/masked/toPercentage';
import { themeToggleColor } from '~/common/utils/theme';
import { OpportunityListItem, OpportunitySimulation } from '~/typings/entities/Opportunity';
import { prepareContract } from '~/store/opportunities/actions';
import { getPersonDetail } from '~/store/people/actions';
import { useDrawer } from '~/hooks/useDrawer';
import {
  Alert,
  Button,
  Divider,
  Flex,
  Shimmer,
  Typography,
  withStepperProps,
} from '~/ui/components';
import { ChildrenDrawer, DownloadNodeModal, Loading } from '~/components';
import LoanType from '~/typings/enums/LoanType';
import formatName from '~/common/formatName';
import { useModal } from '~/hooks/useModal';
import { Resume } from '~/screens/Person/components/Drawers/Opportunity/FUTUREMARGIN/Resume/Resume';
import { getDeeplink } from '~/store/formalization/actions';
import { OpportunityBody, OpportunityContainer, OpportunityFooter } from '../../Opportunity.styled';

export type DetailsProps = {
  opportunity: OpportunityListItem;
  simulationAlert?: boolean;
  setSimulationAlert?: (value: boolean) => void;
  previousSimulation?: { contractValue: number; loanType: string };
};

export const Details = withStepperProps<DetailsProps>(
  ({ opportunity, stepper, simulationAlert, setSimulationAlert, previousSimulation }) => {
    const { setConfig, closeDrawer } = useDrawer();
    const { openModal } = useModal();

    const theme = useTheme();
    const buttonColor = themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondary' });

    const getDeeplinkControl = useAwaitControl(getDeeplink);

    const prepareContractControl = useAwaitControl(prepareContract);
    const prepareContractLoading = prepareContractControl.isRunning();
    const prepareContractSuccess = prepareContractControl.isSuccessful();
    const prepareContractFailure = prepareContractControl.hasFailure();
    const prepareContractResult: OpportunitySimulation = prepareContractControl.result();

    const personControl = useAwaitControl(getPersonDetail);
    const person = personControl.result();

    const loading = prepareContractLoading;

    const handleNextStep = () => {
      stepper.toStepById('success');
    };

    const handleDownload = () => {
      const name = formatName(person?.name, 2);
      const date = moment().format('DD-MM-YYYY');
      const fileName = `Oportunidade Margem Futura - ${name} - ${date}`;

      openModal(
        <DownloadNodeModal fileName={fileName}>
          <Resume contract={prepareContractResult} />
        </DownloadNodeModal>,
        {
          closable: true,
          maskClosable: false,
          noPadding: true,
          width: 460,
        },
      );
    };

    const formatDate = (date: string, format = 'DD/MM/YYYY') =>
      date ? moment(date).format(format) : '';

    const getTotalValue = (installmentValue: number, numberOfInstallments: number) => {
      const totalValue = installmentValue * numberOfInstallments;
      return toMoney(totalValue.toFixed(2));
    };

    useEffect(() => {
      setConfig('opportunity-details', {
        maskClosable: !loading,
        backButton: false,
        extraAction: (
          <Button
            variant="text"
            size="sm"
            iconOnly
            icon="Download"
            title="Baixar resumo"
            disabled={loading}
            onClick={handleDownload}
          />
        ),
      });
    }, [loading]);

    useEffect(() => {
      if (prepareContractFailure) {
        stepper.toStepById('error');
      }
    }, [prepareContractFailure]);

    useEffect(() => {
      if (!prepareContractSuccess && !simulationAlert) {
        getDeeplinkControl.clear();

        prepareContractControl.start({
          agreementId: person?.agreementId,
          enrollmentId: opportunity?.enrollmentId,
          loanType: opportunity?.loanType,
        });
      }
    }, [simulationAlert]);

    return (
      <>
        {simulationAlert ? (
          <OpportunityContainer loading={loading ? 1 : 0} className="opportunity-container">
            <OpportunityBody>
              <Loading />
            </OpportunityBody>
          </OpportunityContainer>
        ) : (
          <OpportunityContainer loading={loading ? 1 : 0} className="opportunity-container">
            <OpportunityBody>
              <Flex direction="column" gap={16} pv={22}>
                <Alert status="warning">
                  Este valor é apenas uma estimativa. Ele poderá ser recalculado no momento do
                  pagamento e poderá variar de acordo com a margem disponível.
                </Alert>

                <Typography type="bodyLarge" weight={700}>
                  Pré-contrato
                </Typography>

                <div>
                  <Typography type="bodyMedium">Valor que o cliente vai receber</Typography>

                  <Flex gap={8} align="center" width="100%">
                    {loading ? (
                      <Shimmer width={160} height={32} />
                    ) : (
                      <Typography type="headingH5" weight={600}>
                        {prepareContractResult?.contractValue
                          ? toMoney(prepareContractResult?.contractValue || 0)
                          : '-'}
                      </Typography>
                    )}
                  </Flex>
                </div>

                <div>
                  <Typography type="bodyMedium">Valor total com juros</Typography>

                  <Flex gap={8} align="center" width="100%">
                    {loading ? (
                      <Shimmer width={130} height={32} />
                    ) : (
                      <Typography type="headingH5" weight={600}>
                        {getTotalValue(
                          prepareContractResult?.installmentValue,
                          prepareContractResult?.numberOfInstallments,
                        )}
                      </Typography>
                    )}
                  </Flex>
                </div>

                <Divider />

                <Alert status="info">
                  A data de previsão do pagamento pode variar de acordo com as decisões do governo.
                </Alert>

                <Flex gap={8} width="100%" direction="column">
                  <Flex gap={8} direction="column" mv={8} width="100%">
                    <Typography type="bodyMedium" weight={600}>
                      Parcelas do contrato
                    </Typography>

                    {loading ? (
                      <Shimmer width={85} height={20} />
                    ) : (
                      <Typography type="bodySmall">
                        {prepareContractResult?.numberOfInstallments}x de{' '}
                        {toMoney(prepareContractResult?.installmentValue || 0)}
                      </Typography>
                    )}
                  </Flex>

                  <Flex gap={8} direction="column" mv={8} width="100%">
                    <Typography type="bodyMedium" weight={600}>
                      Previsão de pagamento
                    </Typography>

                    {loading ? (
                      <Shimmer width={85} height={20} />
                    ) : (
                      <Typography type="bodySmall">
                        Entre {formatDate(prepareContractResult?.nearEstimatedEndDate) || '-'} e{' '}
                        {formatDate(prepareContractResult?.farEstimatedEndDate) || '-'}
                      </Typography>
                    )}
                  </Flex>

                  <Flex gap={8} direction="column" mv={8} width="100%">
                    <Typography type="bodyMedium" weight={600}>
                      Previsão de início dos descontos
                    </Typography>

                    {loading ? (
                      <Shimmer width={85} height={20} />
                    ) : (
                      <Typography type="bodySmall">
                        {formatDate(prepareContractResult?.firstPaymentDate)}
                      </Typography>
                    )}
                  </Flex>
                </Flex>

                <Divider />

                <Flex direction="column" gap={4} width="100%">
                  <Flex align="center" gap={8} width="100%">
                    <Typography type="bodySmall">IOF:</Typography>

                    {loading ? (
                      <Shimmer width={65} height={18} />
                    ) : (
                      <Typography type="bodySmall" weight={600}>
                        {toMoney(prepareContractResult?.iofValue || 0)}
                      </Typography>
                    )}
                  </Flex>

                  <Flex align="center" gap={8} width="100%">
                    <Typography type="bodySmall">Taxa de juros:</Typography>

                    {loading ? (
                      <Shimmer width={170} height={18} />
                    ) : (
                      <Typography type="bodySmall" weight={600}>
                        {toPercentage(prepareContractResult?.nominalRate) || '-'} (a.m) |{' '}
                        {toPercentage(prepareContractResult?.annualNominalRate) || '-'} (a.a)
                      </Typography>
                    )}
                  </Flex>

                  <Flex align="center" gap={8} width="100%">
                    <Typography type="bodySmall">Custo efetivo total (CET):</Typography>

                    {loading ? (
                      <Shimmer width={172} height={18} />
                    ) : (
                      <Typography type="bodySmall" weight={600}>
                        {toPercentage(prepareContractResult?.effectiveRate) || '-'} (a.m) |{' '}
                        {toPercentage(prepareContractResult?.totalEffectiveCost) || '-'} (a.a)
                      </Typography>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </OpportunityBody>

            <OpportunityFooter>
              <Flex gap={8} direction="column" width="100%">
                <Button
                  rounded
                  fullWidth
                  customColor={buttonColor}
                  loading={loading}
                  onClick={handleNextStep}
                >
                  Avançar
                </Button>
              </Flex>
            </OpportunityFooter>
          </OpportunityContainer>
        )}

        <ChildrenDrawer
          title="Uma simulação já foi iniciada anteriormente"
          visible={simulationAlert}
          container=".ant-drawer-body"
        >
          <Flex direction="column" gap={24} pv={24} ph={24}>
            <Typography type="bodyMedium" center color="element.secondary" element="p">
              Já existe uma simulação de{' '}
              <Typography.Bold weight={600} color="element.primary">
                {LoanType(previousSimulation?.loanType)}
              </Typography.Bold>
              {', '}
              que foi iniciada anteriormente com um valor de
              <Typography.Bold weight={600} color="element.primary">
                {' '}
                {toMoney(previousSimulation?.contractValue)}
              </Typography.Bold>
              .
            </Typography>

            <Alert status="warning">
              Ao optar por uma nova simulação, a anterior será substituída pela nova.
            </Alert>

            <Flex gap={8} direction="column" width="100%">
              <Button color="black" fullWidth rounded onClick={() => setSimulationAlert(false)}>
                Refazer simulação
              </Button>

              <Button
                color="black"
                variant="outline"
                rounded
                fullWidth
                onClick={() => closeDrawer('opportunity-details')}
              >
                Fechar
              </Button>
            </Flex>
          </Flex>
        </ChildrenDrawer>
      </>
    );
  },
);
