const OPERATION_STATUS_TYPES = {
  BACKOFFICE: 'Análise backoffice',
  PENDING: 'Com pendência',
  BANK: 'Análise banco',
  PAID: 'Pago',
  CANCELLED: 'Cancelado',
};

const OperationStatusType = (props: any) => {
  if (props) {
    return (
      OPERATION_STATUS_TYPES[props.value] ||
      OPERATION_STATUS_TYPES[props] ||
      props.value ||
      null
    );
  }
  return null;
};

export default OperationStatusType;
