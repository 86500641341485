// @flow
import React, { useMemo } from 'react';
import { Icon } from 'antd';

import useModals from '~/hooks/useModals';
import toDateAndHour from '~/common/masked/toDateAndHour';
import LogDetail from '../LogDetail/LogDetail';

const styles = width => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: width * 0.35,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textWrapper: {
    maxWidth: width * 0.3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    width: 50,
    height: 40,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default function usePresentation(width: number) {
  const { openModal } = useModals();

  return useMemo(
    () => ({
      columns: [
        {
          accessor: 'date',
          name: 'Data',
          props: {
            width: width * 0.2,
            Cell: row => (
              <div className='text-center'>
                {toDateAndHour(row.original.logDate)}
              </div>
            ),
          },
        },
        {
          accessor: 'type',
          name: 'Tipo',
          props: {
            width: width * 0.15,
            Cell: row => (
              <div className='text-center'>{row.original.logType}</div>
            ),
          },
        },
        {
          accessor: 'agent',
          name: 'Agente',
          props: {
            width: width * 0.2,
            Cell: row => <div className='text-center'>{row.original.user}</div>,
          },
        },
        {
          accessor: 'log',
          name: 'Registro',
          props: {
            width: width * 0.35,
            Cell: row => (
              <div style={styles(width).container}>
                <div style={styles(width).textWrapper}>{row.original.log}</div>
                <a
                  style={styles(width).button}
                  onClick={openModal(LogDetail.alias, row.original)}
                >
                  <Icon type='plus-circle' theme='outlined' />
                </a>
              </div>
            ),
          },
        },
      ],
    }),
    [width],
  );
}
