import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 125px);
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.background.primary};
  overflow: hidden;
  position: relative;

  * *::-webkit-scrollbar {
    width: 4px;
  }
`;
