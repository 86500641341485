import styled, { css } from 'styled-components';
import { Button, Typography } from '~/ui/components';
import { Collapse as CollapseComponent, Dropdown } from 'antd';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

interface CollapseProps {
  collapsed?: boolean;
}

export const ActionsContainer = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}

  &.side-actions {
    .ant-collapse {
      .ant-collapse-item:last-of-type {
        padding-bottom: 8px;
      }
    }
  }
`;

export const ShimmerContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray['100']};
  margin: 20px 32px 10px;
`;

export const CollapseButton = styled<CollapseProps>(Button).attrs(({ collapsed }) => ({
  size: 'sm',
  iconOnly: true,
  variant: 'text',
  icon: 'CaretDown',
  title: collapsed ? 'Expandir' : 'Recolher',
}))`
  width: 36px;
  height: 36px;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};

  svg {
    width: 14px;
    height: 14px;
    fill: none !important;
    transition: transform 0.24s, -webkit-transform 0.24s;
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      svg {
        transform: rotate(-90deg);
      }
    `}

  &:disabled {
    opacity: 1;

    svg {
      opacity: 0.4;
    }
  }
`;

export const MainButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  padding: 10px 5px 5px 22px;
`;

export const ActionsTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  element: 'h4',
  weight: 600,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const Collapse = styled(CollapseComponent)`
  .ant-collapse-item.ant-collapse-no-arrow {
    margin-bottom: 0 !important;
  }

  &,
  .ant-collapse-item {
    background: ${themeColor('background.primary', { dark: 'background.secondary' })} !important;
  }
`;

export const Panel = styled(Collapse.Panel)`
  background: #f7f7f7;
  border-radius: 4px;
  border: 0;
  overflow: hidden;
`;

export const SubActions = styled(Dropdown).attrs({
  trigger: ['click'],
  placement: 'bottomRight',
})`
  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px 32px 6px 24px;
  margin-left: 5px;
  border-left: 3px solid transparent;
  cursor: pointer;
  transition: all 0.18s;

  :hover {
    p {
      font-weight: 600 !important;
    }

    background-color: ${({ theme }) => theme.colors.state.hover};
    border-color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const ItemText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const ActionsWrapper = styled.div`
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SubActionItem = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: theme.colors.gray[900],
}))`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
