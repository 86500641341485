import { Map } from 'immutable';
import {
  LIST_ROLES_REQUEST,
  LIST_ROLES_FAILURE,
  LIST_ROLES_SUCCESS,
} from './action';

const initialState = Map({
  type: '',
  payload: [],
});

function reducer(state = initialState, action) {
  const { type } = action;

  if (type === LIST_ROLES_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => true)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], []);
  }

  if (type === LIST_ROLES_FAILURE) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], payload);
  }

  if (type === LIST_ROLES_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => true)
      .setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
