import { Typography } from '~/ui/components';
import styled from 'styled-components';

export const Container = styled.div``;

export const Text = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: theme.colors.gray['900'],
}))``;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

export const EnvironmentList = styled(Typography).attrs({
  type: 'bodyXSmall',
  element: 'div',
})`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const EnvironmentText = styled(Typography).attrs({
  type: 'caption',
  element: 'div',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
