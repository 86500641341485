import styled from 'styled-components';

import { Layout } from 'antd';

export const Content = styled(Layout.Content)`
  padding: 24px;
  gap: 16px;
  flex-direction: column;
  display: flex;
  transition: all 0.2s;
`;
