import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavbarItem, Submenu, SubmenuWrapper } from '~/ui/components/Menu/Menu.styled';
import useOutsideClickEffect from '~/common/useOutsideClickEffect';
import { getUserNotifications } from '~/store/utilities/actions';
import { useAwaitControl } from 'react-redux-await-control';
import { NotificationMessages } from './NotificationMessages/NotificationMessages';
import { Container, NotificationIcon, Badge } from './Notification.styled';

type NotificationProps = {
  trigger?: 'click' | 'hover';
};

const NOTIFICATION_INTERVAL = 60 * 1000; // 1 minute

export function Notification({ trigger }: NotificationProps) {
  const containerRef = useRef(null);
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(false);
  const notificationsControl = useAwaitControl(getUserNotifications);
  const notifications = notificationsControl.result();

  const unreadNotifications = useMemo(() => {
    if (!notifications.length) return 0;
    return notifications?.filter((m) => !m.readed)?.length;
  }, [notifications]);

  const handleItemClick = useCallback(() => {
    if (trigger === 'click') {
      setOpened(true);
    }
  }, [opened, setOpened]);

  const close = useCallback(() => {
    setClosing(true);
    setTimeout(() => {
      setOpened(false);
      setClosing(false);
    }, 300);
  }, [opened, trigger]);

  const handleMessageClick = () => {
    close();
  };

  useOutsideClickEffect(containerRef, () => {
    if (trigger === 'click' && opened) {
      close();
    }
  });

  useEffect(() => {
    notificationsControl.start();

    const interval = setInterval(() => {
      notificationsControl.start();
    }, NOTIFICATION_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container trigger={trigger} ref={containerRef}>
      <NavbarItem
        className={`nav-item ${opened ? 'open' : ''} ${closing ? 'closing' : ''}`}
        trigger={trigger}
      >
        <NotificationIcon onClick={handleItemClick} />
        {!!unreadNotifications && (
          <Badge>{unreadNotifications > 9 ? '9+' : unreadNotifications}</Badge>
        )}

        <SubmenuWrapper>
          <Submenu>
            <NotificationMessages messages={notifications} onMessageClick={handleMessageClick} />
          </Submenu>
        </SubmenuWrapper>
      </NavbarItem>
    </Container>
  );
}
