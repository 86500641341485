import React from 'react';

import { Person } from '~/typings/entities/person';
import { InfoGrid, InfoGridItem } from '~/ui/components';
import masked from '~/common/masked';

interface PersonalInfoProps {
  personDetails: Person;
  loading?: boolean;
}

const nicknameTooltip = {
  title: 'Apelido do cliente',
  content: (
    <>
      Para criar um atendimento mais humanizado é possível registrar como o cliente gosta de ser
      chamado. Esse campo é editável caso a informação mude ao longo do tempo.
    </>
  ),
};

export function PersonalInfo({ personDetails, loading }: PersonalInfoProps) {
  const list: InfoGridItem[] = [
    {
      name: 'RG',
      copyable: !!personDetails?.registrationNumber,
      value: personDetails?.registrationNumber || '-',
    },
    {
      name: 'Data de emissão',
      copyable: !!personDetails?.registerNumberDateOfIssue,
      value: personDetails?.registerNumberDateOfIssue
        ? masked.toDate(personDetails?.registerNumberDateOfIssue)
        : '-',
    },
    {
      name: 'Orgão emissor',
      copyable: !!personDetails?.registerNumberEmitter,
      value: personDetails?.registerNumberEmitter || '-',
    },
    {
      name: 'UF ',
      copyable: !!personDetails?.registerNumberState,
      value: personDetails?.registerNumberState || '-',
    },
    {
      name: 'Nome da mãe',
      copyable: !!personDetails?.motherName,
      value: personDetails?.motherName || '-',
    },
    {
      name: 'Apelido do cliente',
      copyable: true,
      value: personDetails.nickname || '-',
      info: nicknameTooltip,
    },
  ];

  return <InfoGrid loading={loading} list={list} />;
}
