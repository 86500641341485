import styled from 'styled-components';

import { Layout as LayoutAnt } from 'antd';

export const Layout = styled(LayoutAnt)`
  &.layout {
    height: auto;
    min-height: 100vh;
    min-width: 680px;
  }
`;
