import Keycloak from 'keycloak-js';

const realm = process.env.REACT_APP_KEYCLOAK_REALM;
const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT;
const url = process.env.REACT_APP_KEYCLOAK_URL;

export const isKeycloakAuthMethod = process.env.REACT_APP_LOGIN_METHOD === 'keycloak';

const keycloakConf = {
  realm,
  url,
  clientId,
  resource: clientId,
  'ssl-required': 'external',
  'public-client': true,
  'confidential-port': 0,
};

const keycloak = new (Keycloak as any)(keycloakConf);

export const keycloakInit = async () => {
  if (isKeycloakAuthMethod) {
    await keycloak.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
      responseMode: 'fragment',
    });
  }
};

(window as any).kcLogout = keycloak.logout; // TODO: Esta função serve apenas para o QA testar o logout. Remover após validação!

export default keycloak;
