const moneyFormatter = {
  style: 'currency',
  currency: 'BRL',
};

export default function toMoney(entry: number | string | unknown): string {
  if (entry !== undefined && entry !== null) {
    if (typeof entry === 'string' && entry.length === 0) {
      return '';
    }

    const numberValue = Number(entry);
    if (!Number.isNaN(numberValue)) {
      return new Intl.NumberFormat('pt-BR', moneyFormatter).format(numberValue);
    }
  }
  return '';
}
