/* @flow */

import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, lifecycle, withPropsOnChange, withState } from 'recompose';
import { Checkbox } from 'antd';
import Masker from 'vanilla-masker';
import theme from '~/themes/aphroditeTheme/theme';

import { TableReact as Table, Container, Spinner } from '~/components';

import fetchAvailableExistingLoans, {
  FETCH_AVAILABLE_EXISTING_LOANS_REQUEST,
  FETCH_AVAILABLE_EXISTING_LOANS_SUCCESS,
  FETCH_AVAILABLE_EXISTING_LOANS_FAILURE,
} from '~/store/fetchAvailableExistingLoans/action';

import '~/common/styles/checkbox.css';

import styles from './styles';

const { css, withStyles } = theme;

const moneyFormatter = {
  unit: 'R$',
};

type Props = {
  styles: any,
};

class ExistingLoanTable extends Component<Props> {
  state = {
    selectedExistingLoansId: [],
  };

  presentation = {
    columns: [
      {
        accessor: 'existingLoanId',
        name: 'Código',
        props: {
          width: 200,
        },
      },
      {
        accessor: 'bankNumber',
        name: 'Banco',
        props: {
          width: 72,
        },
      },
      {
        accessor: 'installmentValue',
        name: 'Valor da Parcela',
        props: {
          width: 155,
          Cell: row => {
            if (row.original.installmentValue) {
              return Masker.toMoney(
                row.original.installmentValue.toFixed(2),
                moneyFormatter,
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'numberOfInstallments',
        name: 'Parcelas',
        props: {
          width: 90,
        },
      },
      {
        accessor: 'outstandingBalance',
        name: 'Saldo Devedor',
        props: {
          width: 140,
          Cell: row => {
            if (row.original.outstandingBalance) {
              return Masker.toMoney(
                row.original.outstandingBalance.toFixed(2),
                moneyFormatter,
              );
            }
            return Masker.toMoney(0, moneyFormatter);
          },
        },
      },
      {
        accessor: 'consigneeContractKey',
        name: 'C. Consignatária',
        props: {
          width: 145,
        },
      },
      {
        name: 'Vincular',
        props: {
          Cell: row => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                onChange={e =>
                  this.onPressCheckExistingLoan(row.original.existingLoanId, e)
                }
              />
            </div>
          ),
        },
      },
    ],
  };

  onPressCheckExistingLoan = (existingLoanId, e) => {
    const selectedExistingLoan = {
      checked: e.target.checked,
      existingLoanId: existingLoanId,
    };

    let selectedExistingLoansId;

    if (selectedExistingLoan.checked) {
      selectedExistingLoansId = [
        ...this.state.selectedExistingLoansId,
        selectedExistingLoan,
      ];
    } else {
      selectedExistingLoansId = this.state.selectedExistingLoansId.filter(
        selectedExistingLoan =>
          selectedExistingLoan.existingLoanId !== existingLoanId,
      );
    }

    this.setState({ selectedExistingLoansId }, () => {
      this.props.getSelectedExistingLoansIdFromTable(
        this.state.selectedExistingLoansId,
      );
    });
  };

  renderHeading(text) {
    const { styles } = this.props;
    return (
      <div {...css(styles.heading)}>
        <h3 {...css(styles.headingText)}>{text}</h3>
      </div>
    );
  }

  renderTable() {
    const { availableExistingLoans, styles } = this.props;

    return (
      <div>
        {this.renderHeading('Contratos Disponíveis para Refinanciamento')}
        <Table
          items={availableExistingLoans}
          presentation={this.presentation}
          className={css(styles.table).className}
          hidePagination
        />
      </div>
    );
  }

  renderEmpty() {
    const { emptyMessage, isLoading, styles } = this.props;

    if (isLoading || !emptyMessage) return null;

    return (
      <div {...css(styles.status)}>
        <i>{emptyMessage}</i>
      </div>
    );
  }

  render() {
    return (
      <Container>
        <Spinner spinning={this.props.isLoading} />
        <div>
          {this.renderTable()}
          {this.renderEmpty()}
        </div>
      </Container>
    );
  }
}

ExistingLoanTable.defaultProps = {
  availableExistingLoans: [],
};

function componentDidMount() {
  const { personId, bankNumber } = this.props;

  const params = { personId, bankNumber };

  this.props.dispatch(fetchAvailableExistingLoans(params));
}

function mapStateToProps(state) {
  return {
    availableExistingLoans: state.getIn(['fetchAvailableExistingLoans']),
  };
}

function receiveChanges(
  prevProps,
  { availableExistingLoans, isLoading, setIsLoading, setEmptyMessage },
) {
  if (
    Immutable.is(prevProps.availableExistingLoans, availableExistingLoans) ===
    false
  ) {
    switch (availableExistingLoans.getIn(['type'])) {
      case FETCH_AVAILABLE_EXISTING_LOANS_REQUEST: {
        setIsLoading(true);
        return true;
      }
      case FETCH_AVAILABLE_EXISTING_LOANS_SUCCESS: {
        setIsLoading(false);
        if (availableExistingLoans.getIn(['payload']).entries.length !== 0) {
          setEmptyMessage('');
        }
        return true;
      }
      case FETCH_AVAILABLE_EXISTING_LOANS_FAILURE: {
        setIsLoading(false);
        return true;
      }
      default:
        return false;
    }
  }

  return false;
}

function propagateStateChangeToProps(state) {
  const { entries } = state.availableExistingLoans.toJS().payload;
  return {
    availableExistingLoans: entries,
  };
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
  lifecycle({
    componentDidMount,
  }),
  withState('isLoading', 'setIsLoading', true),
  withState('emptyMessage', 'setEmptyMessage', 'Nenhum Registro Encontrado'),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
)(ExistingLoanTable);
