import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { Caret, Typography } from '~/ui/components';
import { CaretRight } from '~/ui/assets/icons';
import { RatedTicket } from '~/typings/entities/Ticket';
import {
  Button,
  Content,
  Header,
  Item,
  Label,
  Value,
  Title,
  Wrapper,
  TextWrapper,
  IconWrapper,
} from './AssessmentHistory.styled';

interface EvaluationHistoryProps {
  ratedTickets: RatedTicket[];
  ticket: any;
  onClick: (assessment: any) => void;
}

export function EvaluationHistory({ ratedTickets, ticket, onClick }: EvaluationHistoryProps) {
  const [isVisible, setIsVisible] = useState(false);

  const formatDate = (date: string, format = 'DD/MM/YYYY [às] HH:mm') =>
    moment(date).format(format);

  useEffect(() => {
    setIsVisible(false);
  }, [ticket]);

  return (
    <Wrapper>
      <Header onClick={() => setIsVisible(!isVisible)}>
        <Typography type="bodyLarge" weight={600}>
          Histórico de avaliação
        </Typography>
        <Button>
          <Caret caretOpen={!isVisible} />
        </Button>
      </Header>

      {isVisible && ratedTickets && (
        <>
          {ratedTickets.map((ticket) => {
            const score =
              Number(ticket?.scoreListening) +
              Number(ticket?.scoreResolution) +
              Number(ticket?.scoreReporting);
            return (
              <Content
                key={ticket.id}
                onClick={() => onClick(ticket)}
                inProgress={ticket.inProgress}
              >
                <Item>
                  <TextWrapper>
                    <Title>Ticket {ticket.inProgress ? 'em avaliação' : 'avaliado'}</Title>
                    <Label>Nota total</Label>
                    <Label>{ticket.inProgress ? 'Sendo avaliado' : 'Avaliado'} por</Label>
                  </TextWrapper>
                  <IconWrapper>
                    <TextWrapper position="end">
                      <Value>{formatDate(ticket?.createdAt)}</Value>
                      <Value score={score}>{score}%</Value>
                      <Value>{ticket?.creator}</Value>
                    </TextWrapper>
                    <CaretRight width={10} height={10} />
                  </IconWrapper>
                </Item>
              </Content>
            );
          })}
        </>
      )}

      {isVisible && !ratedTickets.length && (
        <Content>
          <Title size={14}>Esse ticket não possui nenhuma avaliação</Title>
          <Value>Finalize uma avaliação para ficar registrado aqui </Value>
        </Content>
      )}
    </Wrapper>
  );
}
