export default (file, accept = '') => {
  if (!file) {
    return false;
  }

  const types = accept.split(',').map((e) => String(e).trim());
  const fileType = file.type.match(/(.+)\/(.+)$/)?.[1];
  const fileExt = file.name.match(/\.(.+)$/)?.[1];
  const acceptedExt = types.filter((el) => el.startsWith('.'));
  const acceptedGenericMimeTypes = types
    .filter((el) => el.includes('/*'))
    .map((el) => el.replace('/*', ''));
  const acceptedMimeTypes = types.filter((el) => !el.includes('/*') && !el.startsWith('.'));

  const isAcceptedExt = acceptedExt.includes(`.${fileExt}`);
  const isAcceptedGenericMimeType = acceptedGenericMimeTypes.includes(fileType);
  const isAcceptedMimeType = acceptedMimeTypes.includes(file.type);

  return isAcceptedExt || isAcceptedGenericMimeType || isAcceptedMimeType;
};
