import styled from 'styled-components';
import { Table } from '../Table/Table';
import { Filter } from '../Filter/Filter';
import { Typography } from '../Typography/Typography';

export const Container = styled.div`
  display: flex;
`;

export const FilterContent = styled.div`
  min-width: 250px;
`;

export const LeftContent = styled.div`
  width: 25%;
  border-right: 1px #9a9a9a dotted;
  padding-right: 24px;
  margin-right: 24px;
`;

export const RightContent = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  width: 75%;
`;

export const SearchSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
`;

export const CustomizedTable = styled(Table)`
  padding: 0 16px 0 0;

  thead {
    height: 18px !important;
  }

  th {
    padding: 9px 0 !important;
    height: 18px !important;
  }
`;

export const PermFilter = styled(Filter)`
  min-width: 210px;
`;
