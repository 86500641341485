import styled from 'styled-components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';
import { Check, Copy } from '~/ui/assets/icons';
import { Tag, Typography } from '~/ui/components';

export const ProfileResumeContainer = styled.div`
  padding: 0 24px 0;
  display: flex;
  flex-direction: column;
  background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding: 0 18px 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
    border-radius: 8px;
  }
`;

export const ProfileImageContainer = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  .avatar {
    width: 88px;
    height: 88px;
    background: ${themeColor('element.placeholder', { dark: 'element.disabled' })};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 112px;
    height: 112px;
    min-width: 112px;
    min-height: 112px;

    .avatar {
      width: 112px;
      height: 112px;
    }
  }
`;

export const ProfileInitials = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'div',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${themeColor('element.placeholder', { dark: 'element.disabled' })};
`;

export const ProfileName = styled(Typography).attrs(({ theme, withLink }) => ({
  type: 'paragraphSmall',
  weight: 700,
  element: 'span',
  color: themeToggleColor(theme, withLink ? 'brand.primary' : 'element.primary', {
    dark: 'element.secondary',
  }),
}))`
  text-decoration: ${({ withLink }) => (withLink ? 'underline' : 'none')};
`;

export const ProfileAge = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const ProfileInfoWithPopover = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;

  .action-icon {
    cursor: pointer;
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const ProfileInfo = styled.div`
  padding: 20px 0 22px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    border: none;
    padding: 27px 0;
    width: 100%;
  }

  .tag {
    width: fit-content;
  }
`;

type ProfileInfoItemProps = {
  selectable?: boolean;
};

export const ListWithArrow = styled.ul<ProfileInfoItemProps>`
  li {
    list-style: none;
    display: flex;
    align-items: center;
    line-height: normal;
    color: ${themeColor('element.secondary')};
    cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};

    &:hover {
      color: ${({ theme, selectable }) => selectable && theme.colors.primary['500']};
    }

    &.selected {
      color: ${({ theme, selectable }) => selectable && theme.colors.primary['500']};
      pointer-events: none;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    svg {
      margin-right: 8px;
      color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    }
  }
`;

export const BasicListRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .action-icon {
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'span',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const InfoText = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'span',
  color: themeToggleColor(theme, 'element.secondary'),
}))``;

export const AccountStatusTag = styled(Tag).attrs({
  bgColor: '#FFE8E5',
  rounded: true,
  small: true,
})`
  font-weight: 700;
  margin-top: 22px;
  font-size: 12px;
  width: fit-content;
  color: #b00015;
`;

export const ExtraTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

export const CopyButton = styled(Copy)`
  cursor: pointer;
  color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  width: 27px;
  height: 27px;

  :hover {
    color: ${themeColor('brand.primaryAlt')} !important;
  }
`;

export const Copied = styled(Check)`
  color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  width: 20px;
  height: 15px;
`;

export const Icon = styled.div`
  min-width: 27px;
`;

export const NameContent = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-right: 12px;

  .copyButton {
    @media (min-width: 1201px) {
      display: none;
    }

    display: block;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  width: -webkit-fill-available;
`;
