import React, { useEffect, useRef } from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';

import { Form, Input, Button, Select } from '~/ui/components';
import { useModal } from '~/hooks/useModal';
import { useAwaitControl } from 'react-redux-await-control';
import { createUser } from '~/store/user/actions';

const roles = [
  { value: 'ADMINISTRATOR', label: 'Administrador' },
  { value: 'BACKOFFICE', label: 'Agente' },
  { value: 'BACKOFFICE_SUPERVISOR', label: 'Supervisor' },
];

export function CreateUserModal() {
  const { setLoading, closeModal } = useModal();
  const form = useRef<WrappedFormUtils>();
  const createUserControl = useAwaitControl(createUser);
  const createUserLoading = createUserControl.isRunning();
  const createUserSuccess = createUserControl.isSuccessful();

  const onSubmit = ({ role, ...values }) => {
    createUserControl.start({
      roles: [role],
      ...values,
    });
  };

  useEffect(() => {
    setLoading('create-user', createUserLoading);
  }, [createUserLoading]);

  useEffect(() => {
    if (createUserSuccess) {
      closeModal('create-user');
    }

    return () => {
      createUserControl.clear();
    };
  }, [createUserSuccess]);

  return (
    <Form
      ref={form}
      onSubmit={onSubmit}
      inputs={[
        {
          id: 'name',
          label: 'Nome',
          input: <Input placeholder="Informe o nome" allowClear />,
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
        {
          id: 'login',
          label: 'Nome de usuário',
          input: <Input placeholder="Informe o nome de usuário" allowClear />,
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
        {
          id: 'email',
          label: 'Email',
          input: <Input placeholder="Informe o email" allowClear />,
          options: {
            rules: [
              { required: true, message: 'Este campo é obrigatório!' },
              { type: 'email', message: 'Este campo deve ser um email!' },
            ],
          },
        },
        {
          id: 'role',
          label: 'Perfil',
          input: <Select placeholder="Informe o perfil do usuário" options={roles} />,
          options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
        },
      ]}
    >
      <Button rounded fullWidth loading={createUserLoading} color="black">
        Criar usuário
      </Button>
    </Form>
  );
}
