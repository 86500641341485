import { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useSelector } from 'react-redux';

import { isEmpty } from 'underscore';

import useCombineLoadings from '~/hooks/useCombineLoadings';
import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';

import * as ContractsActions from '~/store/contracts/actions';
import { getLoggedUser } from '~/store/user/actions';
import { updatePersonInfo, getDatavalid } from '~/store/people/actions';
import { getPendingReasons, requestPendingData } from '~/store/pendencies/actions';
import { getParameter } from '~/store/utilities/actions';
import { CONFERENCE_BY_PERSON_OPERATION_STEPS } from '~/typings/enums/OperationStepType';

export default function useContractDetails(id: string) {
  const { result: details, loading: detailsLoading } = useAsyncActionStatus(
    ContractsActions.getContractDetails,
  );
  const inConference = useAsyncActionStatus(ContractsActions.checkContractInConference);
  const { loading: sendToQueueLoading } = useAsyncActionStatus(ContractsActions.forceSendToQueue);
  const { loading: requestPendingLoading } = useAsyncActionStatus(requestPendingData);
  const { loading: getDatavalidLoading } = useAsyncActionStatus(getDatavalid);
  const { loading: resendToPaymentLoading } = useAsyncActionStatus(
    ContractsActions.resendToPayment,
  );
  const { loading: updateInfoLoading } = useAsyncActionStatus(updatePersonInfo);
  const { result: loggedUser } = useAsyncActionStatus(getLoggedUser);
  const { result: conferenceByPerson, loading: getParameterLoading } =
    useAsyncActionStatus(getParameter);

  const getContractDetailsControl = useAwaitControl(ContractsActions.getContractDetails);
  const getPendingReasonsControl = useAwaitControl(getPendingReasons);
  const getParameterControl = useAwaitControl(getParameter);

  const validationBiometryLoading = useSelector((state: any) =>
    state.getIn(['getValidationBiometry', 'isLoading']),
  );

  const isContractOwnerNotNull = useMemo(
    () => (!isEmpty(details) ? !details.contractOwner : false),
    [details],
  );

  const isSentContract = useMemo(
    () => (!isEmpty(details) ? !details.sentToConsignee : false),
    [details],
  );

  const isCancelled = useMemo(() => details?.loanStatus?.startsWith('CANCELLED'), [details]);

  const isConferenceByPersonEnabled = useMemo(
    () =>
      conferenceByPerson?.value === 'true' &&
      CONFERENCE_BY_PERSON_OPERATION_STEPS.includes(details?.operationStep),
    [conferenceByPerson, details],
  );

  const needKycValidation = useMemo(() => details?.person?.statusKyc === 'ONBOARDING', [details]);

  const loading = useCombineLoadings(
    updateInfoLoading,
    inConference.loading,
    detailsLoading,
    sendToQueueLoading,
    resendToPaymentLoading,
    validationBiometryLoading,
    requestPendingLoading,
    getDatavalidLoading,
    getParameterLoading,
  );

  useEffect(() => {
    getContractDetailsControl.start({ id });
    getPendingReasonsControl.start();
    getParameterControl.start('CONFERENCE_BY_PERSON');

    return () => {
      getContractDetailsControl.clear();
      getParameterControl.clear();
    };
  }, [id]);

  return {
    conferenceData: inConference.result,
    details: { ...details, contractId: id },
    loggedUser,
    loading,
    isContractOwnerNotNull,
    isSentContract,
    isCancelled,
    needKycValidation,
    isConferenceByPersonEnabled,
  };
}
