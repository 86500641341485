const ACCOUNT_TYPE = {
  CHECKING_ACCOUNT: 'Conta Corrente',
  SAVINGS_ACCOUNT: 'Conta Poupança',
  MAGNETIC_CARD: 'Cartão Magnético',
  UNDEFINED: 'Não Definido',
};

export default function getAccountType(props: any) {
  if (props) {
    return ACCOUNT_TYPE[props.value] || ACCOUNT_TYPE[props] || null;
  }

  return null;
}
