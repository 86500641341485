import React, { useCallback } from 'react';
import { TableColumns } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import { listReturnsParati, removePreProposal } from '~/store/proposals/actions';
import { useDrawer } from '~/hooks/useDrawer';
import { MenuOptions } from '~/components';

import { ParatiPreProposal } from '~/typings/entities/Proposals';
import { ActionButton, CustomTable, TableTitle } from '../../Returns.styled';
import { PreProposalDetails } from './PreProposalDetails';

export type PreProposalsTableProps = {
  contractId: string;
};

export function PreProposalsTable({ contractId }: PreProposalsTableProps) {
  const { openDrawer } = useDrawer();

  const listReturnsParatiControl = useAwaitControl(listReturnsParati);
  const removePreProposalControl = useAwaitControl(removePreProposal);

  const returnsParatiLoading = listReturnsParatiControl.isRunning(contractId);

  const { paratiPreProposals, paratiPreProposalReturns } =
    listReturnsParatiControl.result(contractId);

  const handleDeleteProposal = useCallback(
    (id) => () => {
      removePreProposalControl.start({ id, contractId });
    },
    [contractId],
  );

  const handleDetailsClick = (preProposal) => () => {
    const preProposalReturns = paratiPreProposalReturns.filter(
      (paratipreProposalReturn) =>
        paratipreProposalReturn.paratiPreProposal === preProposal.preProposalNumber,
    );

    openDrawer(
      'account-details',
      <PreProposalDetails
        contractId={contractId}
        preProposal={preProposal}
        preProposalReturns={preProposalReturns}
      />,
      {
        title: 'Retornos da pré-proposta',
        closable: true,
        width: 900,
      },
    );
  };

  const getOptions = useCallback(
    (preProposal) => [{ label: 'Cancelar', onClick: handleDeleteProposal(preProposal?.id) }],
    [handleDeleteProposal],
  );

  const columns: TableColumns<ParatiPreProposal> = [
    { accessorKey: 'preProposalNumber', header: () => 'COD' },
    { accessorKey: 'status', header: () => 'STATUS' },
    {
      accessorKey: 'creationDate',
      header: () => 'DATA',
      maxSize: 100,
      cell: (info) => {
        const date = info.getValue();
        return date ? moment(date).format('DD/MM/YYYY [às] HH:mm:ss') : '-';
      },
    },
    {
      id: 'details',
      header: () => 'DETALHES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: ({ row }) => (
        <ActionButton onClick={handleDetailsClick(row.original)} icon="FileSearch" />
      ),
    },
    {
      id: 'actions',
      header: () => 'AÇÕES',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: ({ row }) => (
        <MenuOptions options={getOptions(row.original)} width="auto">
          <ActionButton icon="Dots" />
        </MenuOptions>
      ),
    },
  ];

  if (!paratiPreProposals?.length) {
    return null;
  }

  return (
    <>
      <TableTitle>Pré-propostas</TableTitle>
      <CustomTable
        noItemsMessage="Nenhum retorno de conta encontrado"
        loading={returnsParatiLoading}
        data={paratiPreProposals}
        columns={columns}
      />
    </>
  );
}
