/* @flow */

export const GET_CONTRACT_COMMENTS_REQUEST = 'GET_CONTRACT_COMMENTS_REQUEST';
export const GET_CONTRACT_COMMENTS_SUCCESS = 'GET_CONTRACT_COMMENTS_SUCCESS';
export const GET_CONTRACT_COMMENTS_FAILURE = 'GET_CONTRACT_COMMENTS_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_COMMENTS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_COMMENTS_FAILURE,
      payload: resource,
    });
  };
}

export default function sendContractComment(contractId) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_COMMENTS_REQUEST,
      payload: {
        contractId,
      },
    });
  };
}
