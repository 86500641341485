import Masker from 'vanilla-masker';

const moneyFormatter = {
  precision: 2,
  separator: ',',
  delimiter: '.',
  unit: 'R$',
};

export default function(entry) {
  const value =
    entry > 0 ? parseFloat(entry - 0.005).toFixed(2) : entry.toFixed(2);

  if (value) {
    const valueFormated = Masker.toMoney(value, moneyFormatter);
    return valueFormated;
  }
  return null;
}
