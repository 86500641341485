import { put, takeLatest } from 'redux-saga/effects';

import takeRequest from '~/store/takeRequest';
import { reloadContract } from '~/store/contracts/sagas';

import {
  getAllPendencies,
  getPendencies,
  getPendingReasons,
  requestPendingData,
  solveAddress,
  solveBankAccount,
  solveBiometry,
  solveDocument,
  solvePaycheck,
  solveProofOfAddress,
} from './actions';

function* loadPendencies({ meta: { payload } }: any) {
  yield put(getPendencies.start(payload));
}

export function* pendenciesSaga() {
  yield takeRequest(getPendencies);
  yield takeRequest(getAllPendencies);
  yield takeRequest(getPendingReasons);
  yield takeRequest(requestPendingData);
  yield takeRequest(solveBiometry);
  yield takeRequest(solveDocument);
  yield takeRequest(solvePaycheck);
  yield takeRequest(solveBankAccount);
  yield takeRequest(solveProofOfAddress);
  yield takeRequest(solveAddress);

  yield takeLatest(requestPendingData.success.toString(), reloadContract);
  yield takeLatest(solveBiometry.success.toString(), loadPendencies);
  yield takeLatest(solveDocument.success.toString(), loadPendencies);
  yield takeLatest(solvePaycheck.success.toString(), loadPendencies);
  yield takeLatest(solveBankAccount.success.toString(), loadPendencies);
  yield takeLatest(solveProofOfAddress.success.toString(), loadPendencies);
  yield takeLatest(solveAddress.success.toString(), loadPendencies);
}
