import styled, { css } from 'styled-components';
import { Button, Typography } from '~/ui/components';

type AssessmentContainerProps = {
  visible: boolean;
};

export const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
  padding: 0 24px;

  .typography {
    width: 100%;
    min-width: 180px;
  }
`;

export const AssessmentContainer = styled.div<AssessmentContainerProps>`
  flex: 1;
  height: 100%;
  width: 0;
  overflow: hidden;
  max-width: 0;
  border: none;
  transition: max-width 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.background.primary};

  ${({ visible }) =>
    visible &&
    css`
      max-width: 420px;
      border-left: 1px solid ${({ theme }) => theme.colors.divider.primary};
    `};
`;

export const AssessmentBody = styled.div<AssessmentContainerProps>`
  padding: 16px 24px;
  min-width: 300px;
  height: calc(100% - 80px);
  overflow: scroll;
`;

export const CloseButton = styled(Button).attrs(({ theme }) => ({
  size: 'sm',
  iconOnly: true,
  icon: 'CloseCircle',
  variant: 'text',
  customColor: theme.colors.brand.primary,
}))``;

export const PrevButton = styled(Button).attrs(({ theme }) => ({
  size: 'sm',
  iconOnly: true,
  icon: 'Backwards',
  variant: 'text',
  customColor: theme.colors.brand.primary,
}))``;

export const SectionTitle = styled(Typography).attrs({
  type: 'bodyLarge',
  weight: 700,
})``;
