// @flow
import styled from 'styled-components';

import {
  Select as SelectComponent,
  Input as InputComponent,
  Button as ButtonComponent,
  DatePicker,
} from 'antd';

const { RangePicker } = DatePicker;

export const Content = styled.div`
  margin: 20px 20px 45px;
`;

export const Label = styled.span`
  font-weight: 600;
  font-size: 14px;
  padding-right: 30px;
`;

export const Select = styled(SelectComponent)`
  width: 95%;
`;

export const Input = styled(InputComponent)`
  width: 95%;
`;

export const Button = styled.button`
  width: 11%;
  margin-right: 1%;
  border: 1.2px solid
    ${props => (props.active ? '#d22688' : 'rgba(0, 0, 0, 0.2)')};
  box-sizing: border-box;
  height: 32px;
  padding: 0 15px;
  border-radius: 4px;
  line-height: 1.5;
  background-color: #fff;
  cursor: pointer;
`;

export const ButtonLabel = styled.span`
  font-size: 11px;
  font-weight: 400;
  color: #000000;
`;

export const DateButton = styled(RangePicker)`
  width: 30%;
  margin-right: 1%;
`;

export const SearchButton = styled(ButtonComponent)`
  margin-left: 1%;
  width: 18%;
`;
