import { useAwaitControl } from 'react-redux-await-control';
import AsyncActionControl from 'react-redux-await-control/dist/AsyncActionControl';

export default function useAsyncActionStatus(
  asyncAction: AsyncActionControl,
  actionId?: string | number,
) {
  const control = useAwaitControl(asyncAction);

  const result = control.result(actionId);
  const loading = control.isRunning(actionId);
  const success = control.isSuccessful(actionId);
  const failure = control.hasFailure(actionId);
  const cancelled = control.wasCancelled(actionId);

  return {
    result, loading, success, failure, cancelled,
  };
}
