import React from 'react';

import { InfoItem } from '~/components';

import { MtProposal } from '~/typings/entities/Proposals';
import { InfoContainer, Section, SectionTitle } from '../Returns.styled';
import { Actions } from './Actions';

export type DetailsDrawerProps = {
  proposal: MtProposal;
};

export function ReturnDetails({ proposal }: DetailsDrawerProps) {
  return (
    <>
      <Section>
        <SectionTitle>Detalhes</SectionTitle>

        <InfoContainer>
          <InfoItem title="ID Externo" value={proposal?.id_extern} />
          <InfoItem title="ID" value={proposal?.id} />
          <InfoItem title="Banquier" value={proposal?.banquier} />
          <InfoItem title="Produto" value={proposal?.product || '-'} />
          <InfoItem title="Tentativa" value={proposal?.attempt} />
          <InfoItem title="Purchaser" value={proposal?.purchaser || '-'} />
          <InfoItem title="Borrower" value={proposal?.borrower || '-'} />
          <InfoItem title="Simulate" value={proposal?.simulate || '-'} />
        </InfoContainer>
      </Section>

      <Section>
        <SectionTitle>Retornos</SectionTitle>
        <Actions proposalId={proposal?.id} />
      </Section>
    </>
  );
}
