export default {
  main: '#F5222D',
  50: '#FFE8E5',
  100: '#FF9783',
  200: '#FF7F6F',
  300: '#FF675B',
  400: '#FF4E47',
  500: '#FF3135',
  600: '#E50022',
  700: '#CB0010',
  800: '#B10000',
  900: '#970000',
};
