/* @flow */
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal } from 'antd';

import useModals from '~/hooks/useModals';

export type ModalBuilderProps = {
  alias: string,
  okText: string,
};

export type ConfigBuilderProps = {
  height: number,
  width: number,
  okText: string,
};

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    height: ${(props) => `${props.height}px`};
    width: ${(props) => `${props.width}px`};
  }

  .ant-modal-body {
    height: ${(props) => `${props.height}px`};
  }

  ${({ footer }) =>
    footer !== undefined &&
    css`
      .ant-modal-footer {
        display: none;
      }
    `}
`;

export default function modalBuilder(
  Wrapped,
  alias: string,
  config: ConfigBuilderProps = { height: 500 },
) {
  const Wrapper = function (props) {
    const { closeModal } = useModals();

    const [onOk, setOnOk] = useState();

    const { height, customFooter = true, ...configProps } = config;

    const onOkClicked = useCallback(() => {
      onOk?.();
      closeModal(alias)();
    }, [onOk]);

    return (
      <CustomModal
        visible
        onCancel={closeModal(alias)}
        footer={!customFooter ? undefined : null}
        height={config.height}
        onOk={onOkClicked}
        {...configProps}
      >
        <Wrapped alias={alias} closeModal={closeModal(alias)} setOnOkClicked={setOnOk} {...props} />
      </CustomModal>
    );
  };

  Wrapper.alias = alias;
  Wrapper.__type = 'modal';

  return Wrapper;
}
