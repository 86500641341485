import React, { Component } from 'react';
import { Modal, SimpleInfo, DownloadMedicalCertificate } from '~/components';
import masked from '~/common/masked';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/lib/date-picker/locale/pt_BR';

import {
  Text,
  TextPink,
  HighlightedText,
  Button,
  ContainerModal,
  ContainerButton,
  DatePicker,
  DownloadMedical,
  TextUppercase,
} from './styled';

const { toDate } = masked;

type Props = {
  lifeLong: Boolean,
  extinctionDate: string,
  personName: string,
  personId: number,
  hideLabel?: boolean,
};

class ExtinctionDateBenefit extends Component<Props> {
  state = {
    showModal: false,
    newExtinctionDate: null,
    showConfirmNewDate: false,
    lifelong: this.props.lifeLong,
  };

  onChangeDate = (value) => {
    this.setState({ newExtinctionDate: value });
  };

  toggleShowModal = () => {
    this.setState({ showModal: !this.state.showModal }, () => {
      if (this.state.showModal === false) {
        this.setState({
          newExtinctionDate: null,
          showConfirmNewDate: false,
        });
      }
    });
  };

  toggleShowConfirm = () => {
    this.setState({ showConfirmNewDate: !this.state.showConfirmNewDate }, () => {
      if (this.state.showConfirmNewDate === false) {
        this.setState({
          newExtinctionDate: null,
        });
      }
    });
  };

  onSend = () => {
    const { lifelong, newExtinctionDate: extinctionDate } = this.state;

    const save = {
      extinctionDate,
      lifelong,
    };
    this.props.onSave(save);
    this.toggleShowModal();
  };

  getShowValue = () => {
    const { lifeLong, extinctionDate } = this.props;
    if (lifeLong) {
      return 'Vitalício';
    }
    return extinctionDate ? (
      toDate(extinctionDate)
    ) : (
      <HighlightedText>Falha ao registrar</HighlightedText>
    );
  };

  changeLifeLong = () => {
    this.setState({
      newExtinctionDate: null,
      showConfirmNewDate: true,
      lifelong: true,
    });
  };

  render() {
    const { extinctionDate, personName, personId, hideLabel } = this.props;
    const { showModal, showConfirmNewDate, newExtinctionDate, lifelong } = this.state;
    const isClickable = !(lifelong || extinctionDate);
    return (
      <>
        <SimpleInfo
          label={!hideLabel && 'VALIDADE DO BENEFÍCIO'}
          onClick={isClickable ? this.toggleShowModal : undefined}
          value={this.getShowValue()}
        />
        <Modal
          onCancel={this.toggleShowModal}
          visible={showModal}
          title="Informe a validade do benefício"
        >
          <ContainerModal>
            {showConfirmNewDate ? (
              <Text>
                {lifelong ? (
                  <TextUppercase>Vitalício</TextUppercase>
                ) : (
                  <b>{toDate(newExtinctionDate)}</b>
                )}{' '}
                é a data de extinção do benefício de {personName}?
              </Text>
            ) : (
              <>
                <Text>
                  Selecione ou digite a data de extinção que consta na{' '}
                  <DownloadMedicalCertificate
                    title="carta de concessão"
                    personId={personId}
                    agreementName="INSS"
                    type="link"
                  />{' '}
                  do cliente
                </Text>
                <DatePicker
                  defaultPickerValue={moment().utc()}
                  locale={locale}
                  onChange={this.onChangeDate}
                  format="DD/MM/YYYY"
                />
              </>
            )}
            <ContainerButton>
              {showConfirmNewDate && <Button onClick={this.toggleShowConfirm}>Não</Button>}
              <Button
                type="primary"
                disabled={!newExtinctionDate && !lifelong}
                onClick={showConfirmNewDate ? this.onSend : this.toggleShowConfirm}
              >
                {showConfirmNewDate ? 'Sim' : 'Salvar'}
              </Button>
            </ContainerButton>
            {!showConfirmNewDate && (
              <Text>
                <TextPink onClick={this.changeLifeLong}>clique aqui</TextPink> para benefício
                vitalício
              </Text>
            )}
          </ContainerModal>
        </Modal>
      </>
    );
  }
}

export default ExtinctionDateBenefit;
