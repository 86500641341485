/* @flow */

export const RESERVE_MARGIN_REQUEST = 'RESERVE_MARGIN_REQUEST';
export const RESERVE_MARGIN_SUCCESS = 'RESERVE_MARGIN_SUCCESS';
export const RESERVE_MARGIN_FAILURE = 'RESERVE_MARGIN_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: RESERVE_MARGIN_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: RESERVE_MARGIN_FAILURE,
      payload: resource,
    });
  };
}

export default function reserveMargin(params) {
  return dispatch => {
    dispatch({
      type: RESERVE_MARGIN_REQUEST,
      payload: params,
    });
  };
}
