import React, { useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import screenRegister from '~/hoc/screenRegister';
import { listGenericLogs } from '~/store/logs/actions';
import Filters from '~/screens/Logs/components/Filters';
import LogTable from '~/screens/Logs/components/LogTable';

import LogDetail from './components/LogDetail/LogDetail';

import { Container } from './styled';

function Logs() {
  const containerRef = useRef();
  const listLogsControl = useAwaitControl(listGenericLogs);

  const logs = listLogsControl.result();
  const loading = listLogsControl.isRunning();

  return (
    <Container ref={containerRef}>
      <Filters updateSearch={listLogsControl.start} />
      <LogTable
        data={logs}
        loading={loading}
        tableContainerRef={containerRef.current}
      />
    </Container>
  );
}

export default screenRegister({
  screenName: 'Logs',
  path: '/backoffice/logs',
  headerTitle: 'Registros',
  modals: [LogDetail],
  headerMenu: true,
  headerMenuPosition: 5,
})(Logs);
