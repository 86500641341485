/* @flow */
/* global document, FormData */
import { connect } from 'react-redux';
import Immutable from 'immutable';
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
} from 'recompose';

import theme from '~/themes/aphroditeTheme/theme';
import { message } from '~/common';
import screenRegister from '~/hoc/screenRegister';
import updateMargin, {
  UPDATE_MARGIN_REQUEST,
  UPDATE_MARGIN_SUCCESS,
  UPDATE_MARGIN_FAILURE,
} from '~/store/updateMargin/action';
import { getPersonDetail } from '~/store/people/actions';

import PersonContractExtract from './PersonContractExtract';

const { withStyles } = theme;

PersonContractExtract.defaultProps = {};

const Styles = () => ({});

function onSubmit({ dispatch }) {
  return (params) => {
    dispatch(updateMargin(params));
  };
}

function componentDidMount() {
  this.props.dispatch(getPersonDetail.start({ id: this.props.match.params.id }));
}

function mapStateToProps(state) {
  return {
    updateMarginState: state.getIn(['updateMargin']),
    details: getPersonDetail.getResult()(state),
    getDetailsSuccess: getPersonDetail.isSuccessful()(state),
  };
}

function receiveChanges(
  prevProps,
  { updateMarginState, isUpdatingMargin, history },
) {
  if (Immutable.is(prevProps.updateMarginState, updateMarginState) === false) {
    switch (updateMarginState.getIn(['type'])) {
      case UPDATE_MARGIN_REQUEST: {
        isUpdatingMargin(true);
        return true;
      }
      case UPDATE_MARGIN_SUCCESS: {
        const {
          marginLoan,
          marginCard,
          marginExpirationDate,
        } = updateMarginState.getIn(['payload']);

        history.replace({
          state: { marginLoan, marginCard, marginExpirationDate },
        });

        isUpdatingMargin(false);
        message.success('Margem atualizada com sucesso');
        return true;
      }
      case UPDATE_MARGIN_FAILURE: {
        isUpdatingMargin(false);
        message.error('Falha ao atualizar margem');
        break;
      }
      default:
        return false;
    }
  }

  return false;
}

function propagateStateChangeToProps() {}

export default compose(
  screenRegister({
    screenName: 'PersonContractExtract',
    path: '/backoffice/people/:id/contract-extract',
    headerTitle: 'Extrato de contratos',
  }),
  withStyles(Styles),
  connect(mapStateToProps),
  lifecycle({
    componentDidMount,
  }),
  withState('updatingMargin', 'isUpdatingMargin', false),
  withState('personId', 'setPersonId', null),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  withHandlers({
    onSubmit,
  }),
)(PersonContractExtract);
