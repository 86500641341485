import { message } from 'antd';
import { GENERAL_ERRORS, HISCON_ERRORS } from '~/typings/enums/ErrorsType';

const getErrorMessage = (errors: Record<string, string>, type: string) => errors[type] || type;

type ErrorHandlerOptions = {
  defaultMessage?: string;
  duration?: number;
};

const errorHandlerCreate =
  (errors: Record<string, string>) => (err: any, options?: ErrorHandlerOptions) => {
    const userMessages = err?.response?.data?.userMessage;
    const systemMessages = err?.response?.data?.messages;
    const messages = userMessages ? [userMessages] : systemMessages;

    if (messages?.length) {
      messages.map((msg) =>
        message.error(
          getErrorMessage(errors, msg.text) ||
            getErrorMessage(errors, msg) ||
            options?.defaultMessage ||
            'Ocorreu um erro',
          options?.duration,
        ),
      );
    } else {
      message.error(options?.defaultMessage || 'Ocorreu um erro', options?.duration);
    }
  };

export const handleErrors = errorHandlerCreate(GENERAL_ERRORS);
export const handleHisconErrors = errorHandlerCreate(HISCON_ERRORS);
