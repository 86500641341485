import { useCallback } from 'react';

import { message } from 'antd';

export default function useCopyToClipboard(value: string) {
  return useCallback(() => {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.success('Copiado!');
  }, [value]);
}
