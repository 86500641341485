import styled, { css } from 'styled-components';
import { Alert, Typography } from '~/ui/components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

export const TransactionDetailsWrapper = styled.div`
  padding: 8px 0 8px 8px;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
`;

export const TransactionHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  padding: 12px 0 24px 0;
  gap: 8px;

  .close-icon {
    cursor: pointer;
    fill: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const TransactionTitle = styled(Typography).attrs({
  type: 'paragraphLarge',
  element: 'h4',
  weight: 600,
})`
  flex: 1;
`;

export const TopAlertsWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 14px;
`;

export const CancelledDetails = styled(Alert).attrs(() => ({
  status: 'error',
}))`
  border-radius: 16px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 0;
  gap: 24px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.15);

  button {
    padding: 12px 16px;
  }
  span {
    display: flex;
  }
`;

export const TransactionDate = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  weight: 400,
  color: theme.colors.element.secondary,
}))``;

export const Section = styled.div`
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  display: flex;
  flex-direction: column;
  padding: 22px 0;
  gap: 8px;
`;

export const SectionTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  element: 'h3',
  weight: 500,
  color: theme.colors.neutral.primaryAlt,
}))`
  margin-bottom: 12px;
`;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
`;

export const InfoRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: baseline;
  justify-content: center;
`;

export const InfoLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'label',
  weight: 500,
  color: themeToggleColor(theme, 'neutral.primaryAlt', { dark: 'element.primary' }),
}))``;

export const InfoValue = styled(Typography).attrs(({ theme, color, weight }) => ({
  type: 'caption',
  element: 'span',
  weight: weight || 400,
  color: color || theme.colors.neutral.primaryAlt,
}))``;

type InfoButtonProps = {
  disabled?: boolean;
};

export const InfoButton = styled(Typography).attrs(({ theme, color, weight }) => ({
  type: 'caption',
  element: 'span',
  weight: weight || 400,
  color: color || themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
}))<InfoButtonProps>`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const TransactionRecipient = styled(Section)`
  gap: 18px;
`;

export const RecipientValue = styled(Typography).attrs(({ theme }) => ({
  type: 'subtitleSmall',
  element: 'h3',
  weight: 600,
}))``;

export const InstallmentsInfo = styled(Section)`
  gap: 18px;

  h3 {
    margin-bottom: 0;
  }
`;

export const TotalValue = styled(Typography).attrs(({ theme }) => ({
  type: 'subtitleSmall',
  element: 'h4',
  weight: 600,
}))``;

export const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  padding-bottom: 12px;
  gap: 4px;
  color: ${themeColor('element.secondary')};
`;

export const AdditionalInfoTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'h4',
  weight: 500,
}))`
  margin-bottom: 12px;
`;

export const CircleIcon = styled.div`
  width: 24px;
  height: 24px;
  border: 0.5px solid ${({ theme }) => theme.colors.element.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    color: ${({ theme }) => theme.colors.element.primary};
  }
`;
