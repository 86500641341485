import styled from 'styled-components';
import { Button } from '~/ui/components';
import { RightContainer } from '~/ui/components/Menu/Menu.styled';
import { themeColor } from '~/common/utils/theme';

export const Container = styled(RightContainer).attrs({
  className: 'notification-menu',
})``;

export const NotificationIcon = styled(Button).attrs(({ theme }) => ({
  type: 'button',
  variant: 'text',
  onlyIcon: true,
  icon: 'Bell',
  customColor: theme.colors.element.primary,
}))`
  min-width: 22px;
  max-width: 22px;
  padding: 0;

  svg {
    color: ${({ theme }) => theme.colors.element.primary} !important;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const Badge = styled.div`
  width: 14px;
  min-width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 8px;
  background: ${themeColor('brand.secondaryAlt', { dark: 'brand.primary' })};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: 8px;
  left: 12px;
`;
