import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';
import { Form, Typography } from '~/ui/components';

export const FilterContainer = styled.div`
  padding: 20px;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;

export const FilterForm = styled(Form)`
  .input-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
  }

  .submit-button {
    width: 100%;
    max-width: 200px;
  }

  .ant-form-item {
    width: calc(33.33% - 16px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    .ant-form-item {
      width: calc(50% - 16px);
    }
  }

  @media (max-width: 767px) {
    .ant-form-item {
      width: calc(100%);
    }

    .submit-button {
      width: 100%;
      max-width: none;
    }
  }
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography).attrs({
  type: 'paragraphLarge',
  weight: 500,
  element: 'div',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
