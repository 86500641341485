import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 25px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid rgba(0, 40, 100, 0.12);
`;

export const LogInfo = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 10px 36px 10px 26px;
`;

export const DownloadLogs = styled.a`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
`;
