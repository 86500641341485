import { put, takeEvery } from 'redux-saga/effects';

import * as httpUtils from '~/common/utils/http';
import keycloak, { isKeycloakAuthMethod } from '~/common/utils/keycloak';
import takeRequest from '~/store/takeRequest';
import * as services from '~/services';
import { SET_HIDDEN_CONTRACT_FIELDS } from '~/store/hiddenContractFields/action';

import * as UserActions from './actions';

export function* getSessionTokenStart(): Generator {
  const token = localStorage.getItem('authenticationToken');
  const hiddenContractFieldsStr = localStorage.getItem('hiddenContractFields');

  if (
    hiddenContractFieldsStr &&
    hiddenContractFieldsStr !== 'null' &&
    hiddenContractFieldsStr !== 'undefined'
  ) {
    const hiddenContractFields = JSON.parse(hiddenContractFieldsStr);
    yield put({
      type: SET_HIDDEN_CONTRACT_FIELDS,
      loading: false,
      payload: hiddenContractFields,
    });
  }

  if (!token || token === 'null') {
    yield put(UserActions.getSessionToken.failure(false));
  } else {
    httpUtils.reloadAllTokens(services, token);
    yield put(UserActions.getLoggedUser.start());
    yield put(UserActions.getSessionToken.success({ token }));
  }
}

export function* loginSuccess({ payload: response }): Generator {
  const authenticationToken = response.authenticationToken || response.token;
  localStorage.setItem('authenticationToken', authenticationToken);
  const hiddenFields = response.hiddenContractFilters;
  localStorage.setItem('hiddenContractFields', JSON.stringify(hiddenFields));
  yield put(UserActions.getSessionToken.start());
  yield put({
    type: SET_HIDDEN_CONTRACT_FIELDS,
    loading: false,
    payload: hiddenFields,
  });
}

export function* kcLoginSuccess({ payload: response }): Generator {
  const authenticationToken = response.authenticationToken || response.token;
  localStorage.setItem('authenticationToken', authenticationToken);
  yield put(UserActions.getSessionToken.start());
}

export function* logoutStart() {
  localStorage.removeItem('authenticationToken');
  localStorage.setItem('@activeItemMenu', '');
  httpUtils.reloadAllTokens(services, null);
  yield put(UserActions.logout.success());
}

function logoutSuccess() {
  if (isKeycloakAuthMethod) {
    keycloak.logout();
  } else {
    window.location.href = '/login';
  }
}

export function* authenticationSaga(): Generator {
  yield takeRequest(UserActions.login, { retry: 1 });
  yield takeRequest(UserActions.getLoggedUser);
  yield takeRequest(UserActions.fetchUsers);
  yield takeRequest(UserActions.createUser);
  yield takeRequest(UserActions.keycloakLogin);
  yield takeRequest(UserActions.refreshKeycloakLogin);

  yield takeEvery(UserActions.login.success.toString(), loginSuccess);
  yield takeEvery(UserActions.getSessionToken.start.toString(), getSessionTokenStart);
  yield takeEvery(UserActions.logout.start.toString(), logoutStart);
  yield takeEvery(UserActions.keycloakLogin.success.toString(), kcLoginSuccess);
  yield takeEvery(UserActions.refreshKeycloakLogin.success.toString(), kcLoginSuccess);
  yield takeEvery(UserActions.logout.success.toString(), logoutSuccess);
}
