import styled from 'styled-components';

import { Collapse as AntdCollapse } from 'antd';

export const Collapse = styled(AntdCollapse)`
  background-color: #fff;

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
  }

  .ant-collapse-item {
    padding: 26px 29px;
    border-bottom: 0 !important;
  }

  .ant-collapse-item:not(:last-child) {
    border-bottom: 0.7px solid #D9D9D9 !important;
  }
  
  .ant-collapse-header {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 0 0 12px 0;
  }

  .ant-collapse-header .ant-collapse-arrow {
    position: unset !important;
  }
`;

export const CollapseIconWrapper = styled.div`
  width: 23px;
  height: 23px;
  
  background: #FFFFFF;
  box-shadow: 0px 0px 2.65385px rgba(0, 0, 0, 0.25);
  
  display: flex !important;
  justify-content: center;
  
  margin-left: 12px;
  margin-bottom: -20px;
  
  > i {
    text-align: center;
    align-self: center;
    color: #EB2F96;
  }
`;
