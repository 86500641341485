/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import moment from 'moment';
import { ADJUST_CONTRACT_REQUEST, success, failure } from './action';

const prepare = (payload) => ({
  ...payload,
  birthDate: moment(payload.birthDate).utc().toDate(),
  registerNumberDateOfIssue: moment(payload.registerNumberDateOfIssue).utc().toDate(),
});

const events = (action$, _, { AjaxRequest }) =>
  action$
    .ofType(ADJUST_CONTRACT_REQUEST)
    .map(({ payload }) => payload)
    .map(prepare)
    .mergeMap((payload) =>
      AjaxRequest.open(
        'post',
        `/admin/contractcustom/contracts/${payload.contractId}/adjustcontract`,
        payload,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response)))
        .catch((err) => {
          // TODO: aplicar como definitivo quando backend virar completamente
          if (
            (err?.message || '').includes(
              'JSON parse error: Can not deserialize value of type java.time.LocalDate from String',
            )
          ) {
            return AjaxRequest.open(
              'post',
              `/admin/contractcustom/contracts/${payload.contractId}/adjustcontract`,
              {
                ...payload,
                birthDate: moment(payload.birthDate).format('YYYY-MM-DD'),
              },
            )('tudo')
              .flatMap(() => Observable.of(success(payload)))
              .catch((err) => Observable.of(failure(err)));
          }

          return Observable.of(failure(err));
        }),
    );

export default combineEpics(events);
