/* @flow */
import dialogError from '~/common/dialogErrors';
export const ADDRESS_FROM_ZIPCODE_REQUEST = 'ADDRESS_FROM_ZIPCODE_REQUEST';
export const ADDRESS_FROM_ZIPCODE_SUCCESS = 'ADDRESS_FROM_ZIPCODE_SUCCESS';
export const ADDRESS_FROM_ZIPCODE_FAILURE = 'ADDRESS_FROM_ZIPCODE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: ADDRESS_FROM_ZIPCODE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: ADDRESS_FROM_ZIPCODE_FAILURE,
      payload: resource,
    });

    dialogError('CEP não encontrato ou erro de serviço.');
  };
}

export default function fetch(zipCode) {
  return dispatch => {
    dispatch({
      type: ADDRESS_FROM_ZIPCODE_REQUEST,
      payload: {
        zipCode,
      },
    });
  };
}
