/* @flow */
import { message as showMessage } from '~/common';

export const SEND_CONTRACT_REQUEST = 'SEND_CONTRACT_REQUEST';
export const SEND_CONTRACT_SUCCESS = 'SEND_CONTRACT_SUCCESS';
export const SEND_CONTRACT_FAILURE = 'SEND_CONTRACT_FAILURE';

const messageLevel = {
  OK: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

const DURATION_IN_SECONDS = 6;

const showMessageByLevel = (messages) => {
  if (typeof messages === 'string') {
    showMessage[messageLevel.OK](messages, DURATION_IN_SECONDS);
  } else if (Array.isArray(messages) && messages?.length) {
    const parsedLevel = messageLevel[messages[0]?.level] || messageLevel.OK;
    const message = messages[0]?.text || 'Enviado para a consignatária com sucesso.';
    showMessage[parsedLevel](message, DURATION_IN_SECONDS);
  } else {
    showMessage[messageLevel.OK]('Enviado para a consignatária com sucesso.', DURATION_IN_SECONDS);
  }
};

export function success(payload, res) {
  return (dispatch) => {
    showMessageByLevel(res?.messages);

    dispatch({
      type: SEND_CONTRACT_SUCCESS,
      payload: {
        resource: res?.resource,
        ...payload,
      },
    });
  };
}

export function failure({ resource, messages }) {
  return (dispatch) => {
    dispatch({
      type: SEND_CONTRACT_FAILURE,
      payload: resource,
    });

    if (messages && typeof messages === 'string') return showMessage.error(messages);

    const message = messages && messages.length ? messages[0].text : 'Falha ao enviar contrato';
    showMessage.error(message);
  };
}

export default function sendContract(contractId, payload) {
  return (dispatch) => {
    dispatch({
      type: SEND_CONTRACT_REQUEST,
      payload: {
        contractId,
        ...payload,
      },
    });
  };
}
