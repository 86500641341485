import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AlertCircle } from '~/ui/assets/icons';
import { Button, Typography } from '~/ui/components';
import { themeToggleColor } from '~/common/utils/theme';
import { useTheme } from 'styled-components';
import {
  Code,
  CodeContainer,
  MessageBox,
  HelpText,
  InputContainer,
  IntroText,
} from './CodeInput.styled';

export type CodeInputProps = {
  limit?: number;
  onValidate?: (code: string) => void;
  onResend?: () => void;
  loading?: boolean;
  resendLoading?: boolean;
  introText?: string;
  buttonText?: string;
  helpText?: string;
  ref?: any;
};

export const CodeInput = forwardRef(
  (
    {
      limit = 4,
      onValidate,
      onResend,
      loading,
      resendLoading,
      introText,
      helpText,
    }: CodeInputProps,
    ref,
  ) => {
    const btnRef = useRef(null);
    const [code, setCode] = useState();
    const inputList = useMemo<any>(() => document.getElementsByClassName('code-input'), []);
    const [counter, setCounter] = useState(0);
    const theme = useTheme();

    const clear = useCallback(
      (i = 0) => {
        setCode(null);
        const inputsToClear = Array.from(inputList).slice(i);

        inputsToClear.map((input: HTMLInputElement) => {
          input.value = '';
        });
      },
      [inputList],
    );

    const finish = useCallback(() => {
      const inputs: any = Array.from(inputList);
      setCode(inputs.map((el) => el.value).join(''));
      setTimeout(() => btnRef?.current?.focus(), 100);
    }, [inputList]);

    const moveToNext = useCallback(
      (i) => (e) => {
        const { value } = e.target;

        if (value === ' ' || value === '') {
          inputList[i].value = '';
          inputList[i]?.focus();
        } else if (i === inputList.length - 1) {
          finish();
        } else if (inputList[i].value !== '') {
          inputList[i + 1]?.focus();
        } else if (inputList[i] === '') {
          inputList[i - 1]?.focus();
        }
      },
      [inputList],
    );

    const preventKeyCodes = useCallback((e) => {
      const disabledKeyCodes = [9];

      if (disabledKeyCodes.includes(e.keyCode)) {
        e.preventDefault();
      }
    }, []);

    const handleValidate = useCallback(() => {
      onValidate?.(code);
    }, [code]);

    const handleCodeInputClick = useCallback(
      (i) => () => {
        clear(i);
        const inputs: HTMLInputElement[] = Array.from(inputList);
        const notClearedInputs = inputs.filter((el) => el.value === '');
        notClearedInputs[0].focus();
      },
      [inputList],
    );

    const handleResendCode = useCallback(() => {
      clear();
      onResend?.();
      setCounter(60);
    }, [clear]);

    useEffect(() => {
      let intervalId;

      if (counter > 0) {
        intervalId = setInterval(() => {
          setCounter((prevContador) => prevContador - 1);
        }, 1000);
      } else if (counter === 0) {
        clearInterval(intervalId);
      }

      return () => {
        clearInterval(intervalId);
      };
    }, [counter]);

    return (
      <InputContainer ref={ref} loading={loading || resendLoading ? 1 : 0}>
        <IntroText>{introText || 'Insira o código que seu cliente recebeu'}</IntroText>

        <CodeContainer>
          {[...Array(limit || 4)].map((e, i) => (
            <Code
              className="code-input"
              key={i}
              onKeyUp={moveToNext(i)}
              onKeyDown={preventKeyCodes}
              tabindex="-1"
              onClick={handleCodeInputClick(i)}
            />
          ))}
        </CodeContainer>

        <MessageBox>
          <AlertCircle width={24} height={24} />
          <HelpText>
            {helpText ||
              `O cliente receberá um SMS com o código de ${limit} dígitos. Solicite o código e insira acima.`}
          </HelpText>
        </MessageBox>

        <Button
          ref={btnRef}
          disabled={!code}
          loading={loading}
          onClick={handleValidate}
          customColor={themeToggleColor(theme, 'element.primary', { dark: 'brand.secondaryAlt' })}
          rounded
        >
          Validar código
        </Button>

        <Button
          customColor={themeToggleColor(theme, 'element.primary', { dark: 'brand.secondaryAlt' })}
          variant="text"
          loading={resendLoading}
          onClick={handleResendCode}
          disabled={counter > 0}
          underline
        >
          Reenviar código
        </Button>

        {counter > 0 && (
          <MessageBox slim type="alert">
            <Typography type="footnote" weight={700}>
              Aguarde {counter} segundos para reenviar o código
            </Typography>
          </MessageBox>
        )}
      </InputContainer>
    );
  },
);
