import { createSelectorCreator, defaultMemoize } from 'reselect';
import Immutable from 'immutable';

const createImmutableSelector = createSelectorCreator(
  defaultMemoize,
  Immutable.is,
);

function generate(data) {
  if (!data) {
    throw new Error('You need to provide an array of string as data to get');
  }
  if (!Array.isArray(data)) {
    throw new Error('You need to provide an array of string as data to get');
  }
  return createImmutableSelector([state => state.getIn(data)], state => state);
}

export default {
  generate,
};
