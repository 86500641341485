/* @flow */

import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Timeline } from 'antd';
import {
  compose, lifecycle, withPropsOnChange, withState,
} from 'recompose';
import { Flex, Box } from 'reflexbox';

import theme from '~/themes/aphroditeTheme/theme';

import getContractLogs, {
  GET_CONTRACT_LOGS_FAILURE,
  GET_CONTRACT_LOGS_SUCCESS,
} from '~/store/getContractLogs/action';
import { Spinner } from '~/components';

import Styles from './Historical.Styles';

const { css, withStyles } = theme;

type Props = {
  styles: any,
};

class Historical extends Component<void, Props> {
  renderTimeline() {
    return (
      <Timeline mode="alternate">
        {this.props.logs.map((log, index) => (
          <Timeline.Item key={`${log.Data}-${index}`}>
            {Object.keys(log).map((key) => (
              <div key={key}>
                <b>
                  {key}
                  :
                  {' '}
                </b>
                <i>{log[key]}</i>
              </div>
            ))}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }

  render() {
    const {
      styles, isLoading, logs,
    } = this.props;

    return (
      <Flex style={{ width: '100%' }}>
        <Spinner spinning={isLoading} />
        <Box my={1} w={3 / 3}>
          <div {...css(styles.container__timeline)}>
            {logs.length ? (
              this.renderTimeline()
            ) : (
              <div {...css(styles.empty)}>
                <i>Não há o histórico deste contrato</i>
              </div>
            )}
          </div>
        </Box>
      </Flex>
    );
  }
}

Historical.defaultProps = {};

function componentDidMount() {
  const { dispatch, match } = this.props;
  const { id } = match.params;
  dispatch(getContractLogs(id));
}

function mapStateToProps(state) {
  return {
    contractLogs: state.getIn(['getContractLogs']),
  };
}

function receiveChanges(prevProps, { contractLogs, setIsLoading, setLogs }) {
  if (Immutable.is(prevProps.contractLogs, contractLogs) === false) {
    switch (contractLogs.getIn(['type'])) {
      case GET_CONTRACT_LOGS_SUCCESS: {
        setIsLoading(false);
        setLogs(contractLogs.getIn(['payload']));
        return true;
      }
      case GET_CONTRACT_LOGS_FAILURE: {
        setIsLoading(false);
        return false;
      }
      default:
        return false;
    }
  }
  return false;
}

function propagateStateChangeToProps() {}

export default compose(
  withRouter,
  withStyles(Styles),
  connect(mapStateToProps),
  lifecycle({ componentDidMount }),
  withState('isLoading', 'setIsLoading', true),
  withState('logs', 'setLogs', []),
  withPropsOnChange(receiveChanges, propagateStateChangeToProps),
)(Historical);
