/* @flow */

import getTags from '~/store/tagsGet/action';
import getTagTypes from '~/store/tagTypesGet/action';
import { message } from '~/common';

export const TAGTYPES_DELETE_REQUEST = 'TAGTYPES_DELETE_REQUEST';
export const TAGTYPES_DELETE_SUCCESS = 'TAGTYPES_DELETE_SUCCESS';
export const TAGTYPES_DELETE_FAILURE = 'TAGTYPES_DELETE_FAILURE';

export function success(payload) {
  message.success('Tag excluída');
  return (dispatch) => {
    dispatch(getTags());
    dispatch(getTagTypes());
    dispatch({
      type: TAGTYPES_DELETE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  message.error('Falha ao excluir tag');
  return (dispatch) => {
    dispatch({
      type: TAGTYPES_DELETE_FAILURE,
      payload: resource,
    });
  };
}

type Payload = {
  type: 'CONTRACT' | 'PERSON',
};

export default function deleteTagType(data: Payload) {
  return (dispatch, getState) => {
    const types = getState().getIn(['tagTypesGet', 'payload']);
    const type = types.find((t) => t.id === data.id);
    dispatch({
      type: TAGTYPES_DELETE_REQUEST,
      data: type,
    });
  };
}
