import React, { ReactNode, useCallback } from 'react';

import { Tooltip } from 'antd';

import useCopyToClipboard from '~/hooks/useCopyToClipboard';
import { defaultTheme } from '~/themes/themes';

import { Container, Label, Value, Icon } from './styled';

type SimpleInfoProps = {
  label?: string;
  value?: string;
  onClick?: (value: any) => void;
  large?: boolean;
  valueColor?: string;
  renderValue?: ReactNode;
  clickable?: boolean;
  disabled?: boolean;
  valueToCopy?: any;
  tooltip?: string;
};

export default function SimpleInfo({
  label,
  value,
  onClick,
  large,
  valueColor,
  renderValue,
  clickable,
  tooltip,
  disabled,
  ...rest
}: SimpleInfoProps) {
  const onClickValue = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  const copyToClipBoard = useCopyToClipboard(rest.valueToCopy || value?.toString());

  return (
    <Container disabled={disabled}>
      {label && <Label>{label}</Label>}
      {renderValue}
      {!renderValue && (
        <Value
          large={large}
          action={onClick}
          color={valueColor}
          clickable={clickable}
          onClick={onClick ? onClickValue : copyToClipBoard}
          {...rest}
        >
          <span>{value?.toString().length ? value : '-'}</span>
          {tooltip ? (
            <Tooltip placement="top" title={tooltip}>
              <Icon
                type="info-circle"
                theme="twoTone"
                twoToneColor={defaultTheme.primaryColor}
                style={{ marginLeft: 10 }}
              />
            </Tooltip>
          ) : null}
        </Value>
      )}
    </Container>
  );
}
