/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { SET_UNCHECKED_CONTRACT_REQUEST, success, failure } from './action';

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(SET_UNCHECKED_CONTRACT_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(payload =>
      AjaxRequest.open(
        'post',
        `/admin/contractcustom/contracts/${payload}/markasunchecked`,
        payload,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
