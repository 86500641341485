import React, { Component } from 'react';
import { Box, Flex } from 'reflexbox';
import theme from '~/themes/aphroditeTheme/theme';
import { SimpleInfo } from '~/components';

const { css } = theme;

type MoreInfoProps = {
  styles: any,
  details: any,
};

class MoreInfo extends Component<MoreInfoProps> {
  renderAddressComplement() {
    const { addressComplement } = this.props.details.person;
    return <SimpleInfo label="COMPLEMENTO" value={addressComplement} />;
  }

  renderDistrict() {
    const { district } = this.props.details.person;
    return <SimpleInfo label="BAIRRO" value={district} />;
  }

  renderIncome() {
    const { income } = this.props.details.person;
    return <SimpleInfo label="RENDA" value={income} />;
  }

  renderMaritalStatus() {
    const { maritalStatus } = this.props.details.person;

    const preset = [];

    switch (maritalStatus) {
      case 'MARRIED':
        preset.push('Casado');
        break;
      case 'SEPARATED':
        preset.push('Separado');
        break;
      case 'PAID':
        preset.push('Divorciado');
        break;
      case 'DIVORCED':
        preset.push('Cancelado');
        break;
      case 'SINGLE':
        preset.push('Solteiro');
        break;
      case 'WIDOWER':
        preset.push('Viúvo');
        break;
      default:
        preset.push('Não Informado');
    }

    return <SimpleInfo label="ESTADO CIVIL" value={preset.join('')} />;
  }

  renderCity() {
    const { city } = this.props.details.person;
    return <SimpleInfo label="Cidade" value={city} />;
  }

  renderState() {
    const { state } = this.props.details.person;
    return <SimpleInfo label="Estado" value={state} />;
  }

  renderStreet() {
    const { addressNumber, street } = this.props.details.person;
    return (
      <SimpleInfo
        label="LOGRADOURO"
        value={`${street || 'Não Informado'}, ${addressNumber || 'S/N'}`}
      />
    );
  }

  renderZipCode() {
    const { zipCode } = this.props.details.person;
    return <SimpleInfo label="CÓDIGO POSTAL" value={zipCode} />;
  }

  render() {
    const { styles } = this.props;
    return (
      <div {...css(styles.container)}>
        <Flex wrap style={{ width: '100%' }}>
          <Box {...css(styles.item)} my={1} w={2 / 3}>
            {this.renderStreet()}
          </Box>
          <Box {...css(styles.item)} my={1} w={1 / 3}>
            {this.renderZipCode()}
          </Box>
          <Box {...css(styles.item)} my={1} w={1 / 3}>
            {this.renderDistrict()}
          </Box>
          <Box {...css(styles.item)} my={1} w={1 / 3}>
            {this.renderCity()}
          </Box>
          <Box {...css(styles.item)} my={1} w={1 / 3}>
            {this.renderState()}
          </Box>
          <Box {...css(styles.item)} my={1} w={1 / 3}>
            {this.renderAddressComplement()}
          </Box>
          <Box {...css(styles.item)} my={1} w={1 / 3}>
            {this.renderIncome()}
          </Box>
          <Box {...css(styles.item)} my={1} w={1 / 3}>
            {this.renderMaritalStatus()}
          </Box>
        </Flex>
      </div>
    );
  }
}

export default MoreInfo;
