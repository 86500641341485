import { css } from 'styled-components';
import { themeColor, themeToggleValue } from '~/common/utils/theme';

export default css`
  .mt-drawer {
    transform: none !important;

    .ant-drawer-header {
      display: none;
    }

    .mt-drawer-body {
      padding: 0 24px;
    }

    .ant-drawer-body {
      padding: 0;
    }

    .ant-drawer-wrapper-body {
      &::-webkit-scrollbar {
        width: 4px;
      }
    }

    .ant-drawer-content {
      background: ${themeColor('background.primary', { dark: 'background.secondary' })};
    }

    &.ant-drawer-left {
      .ant-drawer-content-wrapper,
      .ant-drawer-content {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    &.ant-drawer-right {
      .ant-drawer-content-wrapper,
      .ant-drawer-content {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }

    &.ant-drawer-top {
      .ant-drawer-content-wrapper,
      .ant-drawer-content {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }

    &.ant-drawer-bottom {
      .ant-drawer-content-wrapper,
      .ant-drawer-content {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
      }
    }

    &.no-padding {
      .ant-drawer-content-wrapper,
      .mt-drawer-body {
        padding: 0;
      }
    }

    .children-drawer {
      .ant-drawer-header {
        display: block;
      }
    }

    &.loading {
      .ant-drawer-body {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }

  .ant-drawer-mask {
    background-color: ${({ theme }) =>
      themeToggleValue(theme, 'rgba(0, 0, 0, 0.35)', { dark: 'rgba(0, 0, 0, 0.80)' })};
  }
`;
