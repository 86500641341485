import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Button, Typography } from '~/ui/components';

export const Container = styled.div``;

export const Text = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  margin-bottom: 16px;
`;

export const CustomButton = styled(Button).attrs(({ theme }) => ({
  customColor: themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondaryAlt' }),
}))``;
