import React, { useCallback } from 'react';
import { Button, Tooltip } from 'antd';
import { FileItem } from './CompareImages';
import { ImageContainer, ControlBar, Img } from './ImageControl.styled';

type ImageControlProps = {
  file: FileItem;
  onClose?: () => void;
};

const Z_INDEX = 10000;
const ZOOM_STEP = 0.3;
const ROTATION_STEP = 90;

export function ImageControl({ file, onClose }: ImageControlProps) {
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);
  const [invert, setInvert] = React.useState({ vertical: false, horizontal: false });

  const handleOnClose = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget === e.target) {
      onClose?.();
    }
  }, []);

  const handleChangeZoom = useCallback(
    (direction: 1 | -1) => {
      if (zoom > 0.2) {
        setZoom(zoom + ZOOM_STEP * direction);
      }
    },
    [zoom, setZoom],
  );

  const handleChangeRotation = useCallback(
    (direction: 1 | -1) => {
      setRotation(rotation + ROTATION_STEP * direction);
    },
    [rotation, setRotation],
  );

  const handleInvertImage = useCallback(
    (direction: 'vertical' | 'horizontal') => {
      setInvert({ ...invert, [direction]: !invert[direction] });
    },
    [invert, setInvert],
  );

  const handleReset = useCallback(() => {
    setZoom(1);
    setRotation(0);
  }, [setZoom, setRotation]);

  const handleScrolling = (e) => {
    if (e.deltaY > 0 && zoom > 0.2) {
      setZoom(zoom - ZOOM_STEP);
    }

    if (e.deltaY < 0) {
      setZoom(zoom + ZOOM_STEP);
    }
  };

  return (
    <ImageContainer
      key={file.name}
      hide={file.hide}
      onWheel={handleScrolling}
      className="image-control"
      zoom={zoom}
      onClick={handleOnClose}
    >
      <Img src={file.url} alt={file.name} zoom={zoom} rotation={rotation} invert={invert} />

      <ControlBar>
        <Tooltip
          placement="bottom"
          title="Inverter horizontalmente"
          overlayStyle={{ zIndex: Z_INDEX }}
        >
          <Button
            shape="circle"
            icon="column-width"
            onClick={() => handleInvertImage('horizontal')}
          />
        </Tooltip>
        <Tooltip placement="bottom" title="Girar para esquerda" overlayStyle={{ zIndex: Z_INDEX }}>
          <Button shape="circle" icon="undo" onClick={() => handleChangeRotation(-1)} />
        </Tooltip>
        <Tooltip placement="bottom" title="- zoom" overlayStyle={{ zIndex: Z_INDEX }}>
          <Button shape="circle" icon="zoom-out" onClick={() => handleChangeZoom(-1)} />
        </Tooltip>
        <Tooltip placement="bottom" title="Desfazer" overlayStyle={{ zIndex: Z_INDEX }}>
          <Button shape="circle" icon="rollback" onClick={handleReset} />
        </Tooltip>
        <Tooltip placement="bottom" title="+ zoom" overlayStyle={{ zIndex: Z_INDEX }}>
          <Button shape="circle" icon="zoom-in" onClick={() => handleChangeZoom(1)} />
        </Tooltip>
        <Tooltip placement="bottom" title="Girar para direita" overlayStyle={{ zIndex: Z_INDEX }}>
          <Button shape="circle" icon="redo" onClick={() => handleChangeRotation(1)} />
        </Tooltip>
        <Tooltip
          placement="bottom"
          title="Inverter verticalmente"
          overlayStyle={{ zIndex: Z_INDEX }}
        >
          <Button
            shape="circle"
            icon="column-height"
            onClick={() => handleInvertImage('vertical')}
          />
        </Tooltip>
      </ControlBar>
    </ImageContainer>
  );
}
