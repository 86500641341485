import React from 'react';

import Icon from '~/components/Icon';
import { CloseCircle } from '~/ui/assets/icons';
import { Divider, PopoverHeader, Title } from './PopoverCard.styled';

export interface PopoverCardProps {
  setVisible?: (visibility: boolean) => void;
  noHeader?: boolean;
  title: string;
  children: React.ReactNode;
}

export function PopoverCard({ setVisible, children, title, noHeader = false }: PopoverCardProps) {
  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      {!noHeader && (
        <>
          <PopoverHeader>
            <Title>{title}</Title>
            <CloseCircle onClick={handleClose} />
          </PopoverHeader>
          <Divider />
        </>
      )}
      {children}
    </>
  );
}
