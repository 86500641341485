/* @flow */

export const CONTRACT_OFFERS_FETCH_REQUEST = 'CONTRACT_OFFERS_FETCH_REQUEST';
export const CONTRACT_OFFERS_FETCH_SUCCESS = 'CONTRACT_OFFERS_FETCH_SUCCESS';
export const CONTRACT_OFFERS_FETCH_FAILURE = 'CONTRACT_OFFERS_FETCH_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: CONTRACT_OFFERS_FETCH_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: CONTRACT_OFFERS_FETCH_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(document, enrollment) {
  return dispatch => {
    dispatch({
      type: CONTRACT_OFFERS_FETCH_REQUEST,
      payload: {
        document,
        enrollment,
      },
    });
  };
}
