import styled, { css } from 'styled-components';
import { Button, Typography } from '~/ui/components';

type WrapperProps = {
  isDragOver?: boolean;
  disabled?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isDragOver, theme }) =>
    isDragOver ? theme.colors.gray[50] : 'transparent'};
  border: 1px dashed #d9d9d9;
  padding: 24px 0;
  border-radius: 10px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

export const Text = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  weight: 400,
  color: theme.colors.gray[800],
}))`
  b {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const FileItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  margin: 12px 0;

  .file-plus {
    margin-top: 3px;
    height: 20px;
    width: 20px;

    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TrashButton = styled(Button).attrs(({ theme }) => ({
  variant: 'text',
  customColor: theme.colors.gray[600],
  size: 'sm',
  iconOnly: true,
  icon: 'Trash',
}))`
  height: auto;
`;

export const FileName = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'span',
  weight: 500,
  color: theme.colors.gray[900],
}))``;

export const FileSize = styled(Typography).attrs(({ theme }) => ({
  type: 'footnote',
  element: 'span',
  weight: 500,
  color: theme.colors.gray[700],
}))``;
