import styled from 'styled-components';

import { Select } from '../Select/Select';
import { Input } from '../Input/Input';

export const SelectModal = styled(Select)`
  border-radius: 12px;
  border: 2px solid !important;
  border-color: ${({ borderColor }) => borderColor} !important;

  input {
    border-bottom: none !important;
  }

  &.ant-cascader-picker-focused {
    border-color: #d80073 !important;
  }
`;

export const InputModal = styled((props) => <Input {...props} />)`
  border-color: ${({ borderColor }) => borderColor} !important;

  input:focus {
    border-color: #d80073 !important;
  }
`;
