/* @flow */

export const REVERSE_CANCEL_CONTRACT_REQUEST =
  'REVERSE_CANCEL_CONTRACT_REQUEST';
export const REVERSE_CANCEL_CONTRACT_SUCCESS =
  'REVERSE_CANCEL_CONTRACT_SUCCESS';
export const REVERSE_CANCEL_CONTRACT_FAILURE =
  'REVERSE_CANCEL_CONTRACT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: REVERSE_CANCEL_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: REVERSE_CANCEL_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

export default function reverseCancelContrac(contractId, reason) {
  return dispatch => {
    dispatch({
      type: REVERSE_CANCEL_CONTRACT_REQUEST,
      payload: {
        contractId,
        reason,
      },
    });
  };
}
