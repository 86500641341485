import styled from 'styled-components';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoSection = styled.div`
  padding: 16px 24px;
  margin-bottom: 32px;
`;

export * from './CleanWarranty/CleanWarranty';
export * from './CreditWarranty/CreditWarranty';
export * from './InssWarranty/InssWarranty';
