import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import get from 'lodash/get';

import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getContractDetails } from '~/store/contracts/actions';
import { getDatavalid, requestAgreementPassword } from '~/store/people/actions';
import FraudAlert from '~/components/FraudAlert';
import ActionManager, { ActionType } from '~/components/ActionManager';
import CollapsePanel from '~/components/CollapsePanel';
import { SimpleInfo } from '~/components';

import { REQUEST_ADDRESS_SUCCESS } from '~/store/requestAddress/action';
import { REQUEST_NEW_CONTRACT_BANK_ACCOUNT_SUCCESS } from '~/store/requestNewContractBankAccount/action';
import { REQUEST_NEW_IMAGE_SUCCESS } from '~/store/requestNewImage/action';
import { requestPendingData } from '~/store/pendencies/actions';
import { SOLVE_DOCUMENT_SUCCESS } from '~/store/solveDocument/action';
import { SOLVE_PAYCHECK_SUCCESS } from '~/store/solvePaycheck/action';
import { SOLVE_PROOF_OF_ADDRESS_SUCCESS } from '~/store/solveProofOfAddress/action';
import { SOLVE_ADDRESS_SUCCESS } from '~/store/solveAddress/action';
import { SOLVE_BANK_ACCOUNT_SUCCESS } from '~/store/solveBankAccount/action';

import useUserRoles from '~/hooks/useUserRoles';
import { useAwaitControl } from 'react-redux-await-control';
import { Flex } from '~/ui/components';
import { Link } from 'react-router-dom';
import ProductTags from './ProductTags';
import fields from './defaultFields';
import { requestCancelFields } from './extraFields';
import { Block, Column, Wrapper } from './styled';

type ResumeProps = {
  isConferenceByPersonEnabled?: boolean;
};

export default function Resume({ isConferenceByPersonEnabled }: ResumeProps) {
  const dispatch = useDispatch();
  const { hasRole } = useUserRoles();
  const { id } = useParams<{ id: string }>();

  const { result: contract } = useAsyncActionStatus(getContractDetails);
  const { result: infos } = useAsyncActionStatus(getDatavalid);

  const requestPendingDataControl = useAwaitControl(requestPendingData);
  const isSuccessfullyRequested = requestPendingDataControl.isSuccessful();

  const requestAgreementControl = useAwaitControl(requestAgreementPassword);
  const isSuccessfullyRequestedAgreement = requestAgreementControl.isSuccessful();

  useEffect(() => {
    if (contract?.person?.id) {
      dispatch(getDatavalid.start({ personId: contract?.person?.id, forceUpdate: false }));
    }
  }, [contract]);

  const activeFields = useMemo(() => {
    const { isRefusalRequested, openRefusalReason } = contract;

    if (isRefusalRequested || openRefusalReason) {
      return [...fields, ...requestCancelFields];
    }

    return fields;
  }, [contract?.isRefusalRequested, contract?.openRefusalReason]);

  const defaultActiveKey = useMemo(() => activeFields.map((field) => field.title), [activeFields]);
  const showAgreementPassword = hasRole('AGREEMENT_PASSWORD');

  const requestBankAccountSuccess = useSelector(
    (state: any) =>
      state.getIn(['requestNewContractBankAccount']).getIn(['type']) ===
      REQUEST_NEW_CONTRACT_BANK_ACCOUNT_SUCCESS,
  );

  const requestAddressSuccess = useSelector(
    (state: any) => state.getIn(['requestAddress']).getIn(['type']) === REQUEST_ADDRESS_SUCCESS,
  );

  const requestNewImageSuccess = useSelector(
    (state: any) => state.getIn(['requestNewImage']).getIn(['type']) === REQUEST_NEW_IMAGE_SUCCESS,
  );

  const solveDocumentsSuccessfully = useSelector(
    (state: any) => state.getIn(['solveDocument']).getIn(['type']) === SOLVE_DOCUMENT_SUCCESS,
  );

  const solvePaycheckSuccessfully = useSelector(
    (state: any) => state.getIn(['solvePaycheck']).getIn(['type']) === SOLVE_PAYCHECK_SUCCESS,
  );

  const solveProofOfAddressSuccessfully = useSelector(
    (state: any) =>
      state.getIn(['solveProofOfAddress']).getIn(['type']) === SOLVE_PROOF_OF_ADDRESS_SUCCESS,
  );

  const solveAddressSuccessfully = useSelector(
    (state: any) => state.getIn(['solveAddress']).getIn(['type']) === SOLVE_ADDRESS_SUCCESS,
  );

  const solveAccountSuccessfully = useSelector(
    (state: any) =>
      state.getIn(['solveBankAccount']).getIn(['type']) === SOLVE_BANK_ACCOUNT_SUCCESS,
  );

  const fieldsData = useMemo(
    () => ({ ...contract, infos, showAgreementPassword }),
    [
      contract,
      infos,
      requestAddressSuccess,
      requestBankAccountSuccess,
      requestNewImageSuccess,
      isSuccessfullyRequested,
      isSuccessfullyRequestedAgreement,
      solveDocumentsSuccessfully,
      solvePaycheckSuccessfully,
      solveProofOfAddressSuccessfully,
      solveAddressSuccessfully,
      solveAccountSuccessfully,
    ],
  );

  useEffect(() => {
    dispatch(getContractDetails.start({ id }));
  }, [
    requestAddressSuccess,
    requestBankAccountSuccess,
    requestNewImageSuccess,
    isSuccessfullyRequested,
    isSuccessfullyRequestedAgreement,
    solveDocumentsSuccessfully,
    solvePaycheckSuccessfully,
    solveProofOfAddressSuccessfully,
    solveAddressSuccessfully,
    solveAccountSuccessfully,
  ]);

  if (!contract.id) {
    return null;
  }

  return (
    <Wrapper>
      <FraudAlert fraudInfo={contract.personFraudInfo} />
      <CollapsePanel defaultActiveKey={defaultActiveKey}>
        {activeFields.map((block) => (
          <CollapsePanel.Panel header={block.title} key={block.title}>
            <Block>
              {block.columns.map((items, columnIndex) => (
                <Column key={columnIndex} my={1} w={1 / 4}>
                  {items.map(
                    (
                      {
                        actionType,
                        value,
                        valueColor,
                        modalParams,
                        clickable,
                        tooltip,
                        ...itemProps
                      },
                      itemIndex,
                    ) => {
                      const actionValue =
                        typeof value === 'string' ? get(fieldsData, value, '-') : value(fieldsData);
                      const color =
                        typeof valueColor === 'function'
                          ? valueColor(actionValue, fieldsData)
                          : valueColor;
                      const type =
                        typeof actionType === 'function'
                          ? actionType(fieldsData)
                          : (actionType as ActionType);
                      const isClickable =
                        typeof clickable === 'function' ? !!clickable(fieldsData) : clickable;
                      const tooltipValue =
                        typeof tooltip === 'function' ? tooltip(fieldsData) : fieldsData?.tooltip;

                      if (type === 'product_tags') {
                        return (
                          <SimpleInfo
                            label={itemProps.label}
                            renderValue={<ProductTags data={actionValue} />}
                          />
                        );
                      }

                      if (type === 'group_contracts') {
                        if (!actionValue?.length || actionValue === '-') {
                          return <SimpleInfo label={itemProps.label} renderValue="-" />;
                        }

                        return (
                          <SimpleInfo
                            label={itemProps.label}
                            renderValue={
                              <Flex wrap="wrap" gap={4}>
                                {actionValue.map((groupContract: any) => (
                                  <>
                                    {groupContract.contractId === Number(id) ? (
                                      <span key={groupContract.contractId}>
                                        #{groupContract.contractId}{' '}
                                      </span>
                                    ) : (
                                      <>
                                        <Link
                                          key={groupContract.contractId}
                                          to={`/backoffice/contracts/${groupContract.contractId}`}
                                        >
                                          #{groupContract.contractId}
                                        </Link>
                                      </>
                                    )}
                                  </>
                                ))}
                              </Flex>
                            }
                          />
                        );
                      }

                      return (
                        <ActionManager
                          {...{
                            ...itemProps,
                            valueColor: color,
                            modalParams: modalParams?.(fieldsData),
                            clickable: isClickable,
                            tooltip: tooltipValue,
                            disabled:
                              isConferenceByPersonEnabled &&
                              contract.isGroupContract &&
                              !contract.isGroupLead,
                          }}
                          value={actionValue}
                          key={itemIndex}
                          type={type}
                          child={SimpleInfo}
                        />
                      );
                    },
                  )}
                </Column>
              ))}
            </Block>
          </CollapsePanel.Panel>
        ))}
      </CollapsePanel>
    </Wrapper>
  );
}
