// @flow
import React, { useCallback } from 'react';
import { useWizard } from 'react-use-wizard';
import { useAwaitControl } from 'react-redux-await-control';
import { FormProps } from 'antd/lib/form/Form';
import {
  Col, Form, Input, Row,
} from 'antd';

import masked from '~/common/masked';
import { simulateRefin } from '~/store/contracts/actions';
import type { Contract } from '~/typings/entities/contract';
import { CustomFormButton, StepTitle } from './styled';

const { Item: FormItem } = Form;

type CalculatorProps = FormProps & {
  id: number,
  contract: Contract,
};

const { toMoneyForm, toNumber } = masked;

function Calculator({ form, id, contract }: CalculatorProps) {
  const { getFieldDecorator } = form;
  const {
    installmentValue,
    numberOfInstallments,
    nominalRatePerMonth,
  } = contract;

  const { nextStep } = useWizard();
  const simulateRefinControl = useAwaitControl(simulateRefin);

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = { ...values, id };
        const transformStringToNumber = (value) => parseFloat((value || '0').replace(',', '.'));
        data.nominalRatePerMonth = transformStringToNumber(data.nominalRatePerMonth) / 100;
        data.installmentValue = transformStringToNumber(data.installmentValue);
        data.numberOfInstallments = parseInt(data.numberOfInstallments);
        simulateRefinControl.start(data, { actionId: id });
        nextStep();
      }
    });
  };

  const handleNumberOfInstallments = useCallback((rule, value, callback) => {
    const min = 6;
    const max = 84;
    const parsedValue = parseInt(value);

    if (min > parsedValue || max < parsedValue) {
      callback('O n° de parcelas deve ser no mínimo 6 e no máximo 84');
    }

    callback();
  }, []);

  const handleNonZero = useCallback((rule, value, callback) => {
    const parsedValue = parseInt(value);

    if (parsedValue === 0) {
      callback('O valor não pode ser zero');
    }

    callback();
  }, []);

  return (
    <div>
      <StepTitle>
        Preencha os campos para simular o ajuste no contrato de refin
      </StepTitle>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col span={24}>
            <Col span={9}>
              <span>valor de parcela</span>
              <FormItem>
                {getFieldDecorator('installmentValue', {
                  rules: [
                    {
                      required: true,
                      message: 'O valor da parcela é obrigatório',
                    },
                    {
                      validator: handleNonZero,
                    },
                  ],
                  initialValue: toMoneyForm(
                    (installmentValue * 100).toString(),
                  ),
                  normalize: toMoneyForm,
                })(<Input placeholder="999,99" />)}
              </FormItem>
            </Col>
            <Col span={7} offset={1}>
              <span>n° de parcelas</span>
              <FormItem>
                {getFieldDecorator('numberOfInstallments', {
                  rules: [
                    {
                      required: true,
                      message: 'O número de parcelas é obrigatório',
                    },
                    {
                      validator: handleNumberOfInstallments,
                    },
                  ],
                  initialValue: numberOfInstallments,
                  normalize: toNumber,
                })(<Input placeholder="99" type="number" />)}
              </FormItem>
            </Col>
            <Col span={6} offset={1}>
              <span>taxa</span>
              <FormItem>
                {getFieldDecorator('nominalRatePerMonth', {
                  rules: [
                    {
                      required: true,
                      message: 'A taxa de juros é obrigatória',
                    },
                  ],
                  normalize: toMoneyForm,
                  initialValue: toMoneyForm(
                    (nominalRatePerMonth * 100 * 100).toString(),
                  ),
                })(<Input placeholder="9,99" />)}
              </FormItem>
            </Col>
          </Col>
        </Row>
        <Form.Item colon={false} style={{ textAlign: 'center' }}>
          <CustomFormButton type="primary" htmlType="submit" size="large">
            Simular
          </CustomFormButton>
        </Form.Item>
      </Form>
    </div>
  );
}

const CalculatorWrapped = Form.create()(Calculator);

export default CalculatorWrapped;
