/* @flow */

export const PAY_CONTRACT_REQUEST = 'PAY_CONTRACT_REQUEST';
export const PAY_CONTRACT_SUCCESS = 'PAY_CONTRACT_SUCCESS';
export const PAY_CONTRACT_FAILURE = 'PAY_CONTRACT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: PAY_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: PAY_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

export default function payContract(contractId, values) {
  return dispatch => {
    dispatch({
      type: PAY_CONTRACT_REQUEST,
      payload: {
        contractId,
        values,
      },
    });
  };
}
