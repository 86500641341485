import styled, { css } from 'styled-components';
import { Typography } from '~/ui/components';

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 32px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};

  &:hover {
    background: ${({ theme }) => theme.colors.state.hover};
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${({ theme }) => theme.colors.state.active};

      &:hover {
        background: ${({ theme }) => theme.colors.state.active};
      }
    `}
`;

export const InfoValue = styled(Typography).attrs({
  type: 'bodySmall',
  element: 'span',
})`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const InfoLabel = styled(Typography).attrs({
  type: 'bodyXSmall',
  element: 'span',
  weight: 600,
})``;

type StatusIndicatorProps = {
  statusColor?: string;
};

export const StatusIndicator = styled.div<StatusIndicatorProps>`
  ${({ theme, statusColor }) => css`
    background: ${theme.colors[statusColor].primary};
    border: 4px solid ${theme.colors[statusColor].secondaryAlt};
    width: 16px;
    height: 16px;
    border-radius: 50%;
  `}
`;

export const TicketId = styled(Typography).attrs({
  type: 'bodyMedium',
  element: 'span',
  weight: 700,
  color: 'brand.primary',
})`
  margin-left: 4px;
`;
