import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { notification } from 'antd';
import moment from 'moment';

import { Clock, LoadingSpinner } from '~/ui/assets/icons';
import useIsAuthenticated from '~/hooks/useIsAuthenticated';
import keycloak, { isKeycloakAuthMethod } from '~/common/utils/keycloak';
import { remoteValues } from '~/common/utils/firebase/remoteConfig';

import { logout, refreshKeycloakLogin } from '~/store/user/actions';
import { TimerContainer, Timer, NotificationButton } from './SessionTimer.styled';

export function SessionTimer() {
  const { token, refreshToken } = useIsAuthenticated();
  const [timeLeft, setTimeLeft] = useState<string>('00:00');
  const [isCloseToExpire, setIsCloseToExpire] = useState<boolean>(false);
  const keycloakLoginControl = useAwaitControl(refreshKeycloakLogin);
  const logoutControl = useAwaitControl(logout);
  const keycloakLoginSuccess = keycloakLoginControl.isSuccessful();
  const keycloakLoginFailure = keycloakLoginControl.hasFailure();
  const { allowRefreshSession, expirationAlertLimit } = remoteValues;

  const expiration = useMemo(() => {
    try {
      const parsedExp = JSON.parse(atob(token.split('.')[1]))?.exp;
      return parsedExp ? parsedExp * 1000 : null;
    } catch (e) {
      return null;
    }
  }, [token]);

  const handleRefreshSession = useCallback(() => {
    notification.destroy();
    notification.open({
      message: 'Renovando sessão...',
      top: 80,
      duration: 0,
      icon: <LoadingSpinner color="#D80073" />,
    });

    keycloak
      .updateToken(5)
      .then(() => {
        keycloakLoginControl.start({ token: keycloak.token });
      })
      .catch(() => {
        notification.error({ message: 'Não foi possível renovar a sessão', top: 80 });
        logoutControl.start();
      });
  }, [keycloak]);

  useEffect(() => {
    if (keycloakLoginSuccess || keycloakLoginFailure) {
      notification.destroy();

      if (keycloakLoginSuccess) {
        notification.success({ message: 'Sessão renovada com sucesso', top: 80 });
        refreshToken();
      }

      if (keycloakLoginFailure) {
        notification.error({ message: 'Não foi possível renovar a sessão', top: 80 });
      }
    }
  }, [keycloakLoginSuccess, keycloakLoginFailure]);

  const description = useMemo(
    () => (
      <>
        <p>Você será desconectado em breve. </p>
        {allowRefreshSession && isKeycloakAuthMethod && (
          <NotificationButton onClick={handleRefreshSession}>Renovar sessão</NotificationButton>
        )}
      </>
    ),
    [allowRefreshSession, isKeycloakAuthMethod],
  );

  useEffect(() => {
    if (isCloseToExpire) {
      notification.warning({
        message: 'Sua sessão está prestes a expirar',
        description,
        duration: 0,
        top: 80,
      });
    }

    return () => {
      notification.destroy();
    };
  }, [isCloseToExpire]);

  useEffect(() => {
    const futureDate = moment(expiration);
    const interval = setInterval(() => {
      const now = moment();
      const duration = moment.duration(futureDate.diff(now));
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;

      if (!isCloseToExpire && duration.asMinutes() <= (expirationAlertLimit || 5)) {
        setIsCloseToExpire(true);
      }

      if (duration.asSeconds() <= 0) {
        clearInterval(interval);
      } else {
        setTimeLeft(formattedTime);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiration]);

  return (
    <TimerContainer>
      <Clock /> <Timer>{timeLeft}</Timer>
    </TimerContainer>
  );
}
