import React, { useEffect } from 'react';
import moment from 'moment/moment';
import { useAwaitControl } from 'react-redux-await-control';
import { Ticket } from '~/typings/entities/Ticket';
import { RenderRichText } from '~/ui/components';
import { findNameById } from '~/common/selectValues';
import {
  getReasons,
  getTicketJourneys,
  getTicketProducts,
  getTicketResolvingAreas,
} from '~/store/tickets/actions';
import { InfoItem, TicketAccompaniment } from '~/components';

import { TicketOrigin } from '~/typings/enums/Ticket/TicketOrigin';
import { TicketLinkPopover } from '../TicketLinkPopover/TicketLinkPopover';
import { TicketContractsPopover } from '../TicketContractsPopover/TicketContractsPopover';
import { TicketInfoContainer } from '../TicketDetails.styled';

type TicketResumeProps = {
  ticket: Ticket;
  noBorder?: boolean;
};

export function TicketResume({ ticket, noBorder }: TicketResumeProps) {
  const getTicketAreasControl = useAwaitControl(getTicketResolvingAreas);
  const getJourneysControl = useAwaitControl(getTicketJourneys);
  const getReasonsControl = useAwaitControl(getReasons);
  const getProductsControl = useAwaitControl(getTicketProducts);
  const areas = getTicketAreasControl.result();
  const journeys = getJourneysControl.result();
  const products = getProductsControl.result();
  const reasons = getReasonsControl.result();

  const formatDate = (date: string, format = 'DD/MM/YYYY [às] HH:mm') =>
    moment(date).format(format);

  useEffect(() => {
    if (!areas?.length) getTicketAreasControl.start();
    if (!journeys?.length) getJourneysControl.start();
    if (!products?.length) getProductsControl.start();
    if (!reasons?.length) getReasonsControl.start();
  }, [areas, journeys, products, reasons]);

  return (
    <TicketInfoContainer noBorder={noBorder}>
      <InfoItem title="Data de abertura" value={formatDate(ticket?.ticketOpeningDate)} />

      <InfoItem
        title="Produto"
        value={findNameById(products, ticket?.product, { value: 'name', label: 'description' })}
      />

      <InfoItem
        title={ticket?.contracts?.length > 1 ? 'Contratos' : 'Contrato'}
        value={
          ticket?.contracts?.length > 0 ? (
            <TicketContractsPopover contracts={ticket.contracts} />
          ) : (
            <p>Não informado</p>
          )
        }
      />

      {!!ticket?.children?.length && (
        <InfoItem
          title="Tickets atrelados"
          value={<TicketLinkPopover tickets={ticket.children} />}
        />
      )}

      <InfoItem title="Criado por" value={ticket?.userCreated} />

      <InfoItem
        title="Acompanhamento"
        value={
          <TicketAccompaniment
            triggerText={ticket?.resolvingArea || ticket?.team}
            showStatusIcon
            ticket={ticket}
            fetchBeforeOpen
          />
        }
      />

      {!!ticket?.resolvingArea && (
        <InfoItem title="Área resolvedora" value={findNameById(areas, ticket?.resolvingAreaId)} />
      )}

      {!!ticket?.team && <InfoItem title="Time" value={ticket?.team} />}

      {!!ticket?.ticketScheduleDate && (
        <InfoItem title="Agendado para" value={formatDate(ticket?.ticketScheduleDate)} />
      )}

      {!!ticket?.journeyId && (
        <InfoItem title="Etapa da jornada" value={findNameById(journeys, ticket?.journeyId)} />
      )}

      {!!ticket?.ticketReasonId && (
        <InfoItem
          title="Motivo da abertura"
          value={findNameById(reasons, ticket?.ticketReasonId)}
        />
      )}

      {!!ticket?.contactOrigin && (
        <InfoItem title="Origem do contato" value={TicketOrigin[ticket?.contactOrigin]} />
      )}

      {!!ticket?.justification && (
        <InfoItem title="Justificativa da resolução" value={ticket?.justification} />
      )}

      {!!ticket?.subJustification && (
        <InfoItem title="Sub-justificativa da resolução" value={ticket?.subJustification} />
      )}

      <InfoItem
        title="Descrição"
        value={
          ticket?.description ? <RenderRichText html={ticket?.description} /> : 'Sem descrição'
        }
        fullWidth
      />
    </TicketInfoContainer>
  );
}
