import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import pendencyTitle from '~/typings/enums/PendencyType';
import { getPersonDetail } from '~/store/people/actions';
import { fetchFinancialInstitutionsForDeposit } from '~/store/utilities/actions';
import { Form, Input, Select, Tag } from '~/ui/components';

import { solveBankAccount } from '~/store/pendencies/actions';
import { useModal } from '~/hooks/useModal';
import { getContractDetails } from '~/store/contracts/actions';
import { Container, CustomButton, InfoRow, InfoTitle, InfoValue } from '../Pendency.styled';

const options = [
  { value: 'CHECKING_ACCOUNT', label: 'Conta corrente' },
  { value: 'SAVINGS_ACCOUNT', label: 'Conta poupança' },
];

interface BankAccountProps {
  pendency?: any;
}

export function BankAccount({ pendency }: BankAccountProps) {
  const { setLoading, closeModal } = useModal();
  const fetchInstitutionsForDepositControl = useAwaitControl(fetchFinancialInstitutionsForDeposit);
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const solveBankAccountControl = useAwaitControl(solveBankAccount);
  const getContractDetailsControl = useAwaitControl(getContractDetails);

  const institutionsForDeposit = fetchInstitutionsForDepositControl.result();
  const institutionsForDepositIsLoading = fetchInstitutionsForDepositControl.isRunning();
  const solveBankAccountLoading = solveBankAccountControl.isRunning();
  const solveBankAccountSuccess = solveBankAccountControl.isSuccessful();
  const person = getPersonDetailsControl.result();
  const contract = getContractDetailsControl.result();

  const onSubmit = (data) => {
    const idKey = contract?.id ? 'contractId' : 'personId';
    const idValue = contract?.id || person?.id;
    solveBankAccountControl.start({ id: pendency?.id, data, [idKey]: idValue });
  };

  useEffect(() => {
    setLoading('pendency-details', solveBankAccountLoading);
  }, [solveBankAccountLoading]);

  useEffect(() => {
    if (solveBankAccountSuccess) {
      closeModal('pendency-details');
    }
  }, [solveBankAccountSuccess]);

  useEffect(() => {
    if (pendency?.contractId) {
      fetchInstitutionsForDepositControl.start({ contractId: pendency.contractId });
    }

    return () => {
      fetchInstitutionsForDepositControl.clear();
    };
  }, []);

  return (
    <Container width={550}>
      <Tag small rounded colorPalette="warning">
        Pendência aberta
      </Tag>

      <InfoRow>
        <InfoValue>Preencha os dados bancários do cliente para prosseguir.</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoTitle>Tipo da pendência</InfoTitle>
        <InfoValue>{pendencyTitle(pendency?.pendingType)}</InfoValue>
      </InfoRow>

      <Form
        onSubmit={onSubmit}
        inputs={[
          {
            id: 'accountType',
            label: 'Escolha o tipo da conta',
            input: <Select options={options} />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'bankNumber',
            label: 'Escolha o banco',
            input: (
              <Select
                loading={institutionsForDepositIsLoading}
                fieldNames={{ label: 'name', value: 'bankNumber' }}
                options={institutionsForDeposit}
              />
            ),
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'agency',
            label: 'Agência',
            width: '48%',
            input: <Input number placeholder="Informe o n° da agência" allowClear />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'agencyDigit',
            label: 'Dígito',
            width: '48%',
            input: (
              <Input number placeholder="Informe o dígito da agência" allowClear maxLength={1} />
            ),
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'checkingAccount',
            label: 'Conta',
            width: '48%',
            input: <Input number placeholder="Informe o n° da conta" allowClear />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'checkingAccountDigit',
            label: 'Dígito',
            width: '48%',
            input: (
              <Input number placeholder="Informe o dígito da conta" allowClear maxLength={1} />
            ),
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
        ]}
      >
        <CustomButton rounded fullWidth type="submit">
          Confirmar resolução
        </CustomButton>
      </Form>
    </Container>
  );
}
