import React, { useEffect, useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useModal } from '~/hooks/useModal';

import { getPersonDetail, getResetReasons, requestResetPerson } from '~/store/people/actions';
import { Form, Input, Select } from '~/ui/components';
import { CustomButton, Wrapper } from '../OtherOptions.styled';

export type Reason = {
  id: number;
  description: string;
};

export function RequestResetModal() {
  const formRef = useRef(null);
  const { closeAll } = useModal();
  const [getResetReasonsControl, requestResetPersonControl, getPersonDetailControl] =
    useAwaitControl([getResetReasons, requestResetPerson, getPersonDetail]);

  const personId = getPersonDetailControl.result()?.id;
  const reasons = getResetReasonsControl.result();
  const resetRequestIsLoading = requestResetPersonControl.isRunning();
  const requestSuccessful = requestResetPersonControl.isSuccessful();
  const requestFailed = requestResetPersonControl.hasFailure();

  const handleChange = (value) => {
    if (value[0] !== 1) {
      removeTextArea();
    } else {
      addTextArea();
    }
  };

  const inputs = [
    {
      id: 'selectReason',
      label: 'Motivo do reset',
      input: (
        <Select
          fieldNames={{ label: 'description', value: 'id' }}
          loading={getResetReasonsControl.isRunning()}
          showSearch={false}
          onChange={handleChange}
          options={reasons}
          placeholder="Selecione"
        />
      ),
      options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
    },
    {
      id: 'comment',
      label: 'Comentário',
      input: <Input textArea rows={3} />,
    },
  ];

  const removeTextArea = () => {
    const index = inputs.findIndex((input) => input.id === 'comment');

    if (index > -1) {
      inputs.splice(index, 1);
    }
  };

  const addTextArea = () => {
    const hasCommentComponent = inputs.some((input) => input.id === 'comment');

    if (!hasCommentComponent) {
      inputs.push({
        id: 'comment',
        label: 'Comentário',
        input: <Input textArea rows={3} />,
      });
    }
  };

  const handleSubmit = (values: any) => {
    if (values.selectReason !== 1 || !values?.comment) {
      values.comment = '';
    }

    requestResetPersonControl.start({
      id: String(personId),
      personResetReasonId: String(values.selectReason),
      openResetReasonDescription: values.comment,
    });
  };

  useEffect(() => {
    getResetReasonsControl.start();
  }, []);

  useEffect(() => {
    if (requestSuccessful) {
      closeAll();
    }
  }, [requestSuccessful]);

  useEffect(() => {
    if (requestFailed) {
      formRef.current?.setFieldsValue({ selectReason: [1] });
    }
  }, [requestFailed]);

  return (
    <Wrapper>
      <Form ref={formRef} name="request-reset-form" inputs={inputs} onSubmit={handleSubmit}>
        <CustomButton loading={resetRequestIsLoading} rounded fullWidth type="submit">
          Solicitar reset de cadastro
        </CustomButton>
      </Form>
    </Wrapper>
  );
}
