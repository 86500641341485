import styled, { css } from 'styled-components';

export const Action = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 12px;
  height: 45px;
  width: 100%;
  cursor: pointer;
  transition: 0.5s;
  :hover {
    background-color: ${(props) => props.theme.primaryColorLightHover};
    color: ${(props) => props.theme.primaryColor};
  }

  ${({ type }) =>
    type === 'link' &&
    css`
      display: inline;
      padding: 0;
      height: auto;
      width: auto;
      :hover {
        background-color: transparent;
        color: transparent;
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;

      &:hover {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.65);
      }
    `};
`;

export const ActionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.subActions ? 'start' : 'center')};
  line-height: 15px;

  ${({ type }) =>
    type === 'link' &&
    css`
      display: inline;
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: underline;
    `};
`;

export const Subtitle = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.grayMid};
`;
