/* eslint-disable react/button-has-type */
import React from 'react';

import useAsyncActionStatus from '~/hooks/useAsyncActionStatus';
import { getContractDetails } from '~/store/contracts/actions';

import type { RefusalViewProps } from '../RequestActionView';
import RequestActionView from '../RequestActionView';

type Props = {
  isRequested?: boolean,
  isRequestable?: boolean,
};

type RequestProps = Props & RefusalViewProps;

function Request(props: RequestProps) {
  const { result: details } = useAsyncActionStatus(getContractDetails);
  const { isRefusalRequested } = details;
  const { isRequested, reasons } = props;
  if (!reasons || !Array.isArray(reasons)) return null;
  if (isRefusalRequested && !isRequested) return null;
  return <RequestActionView {...props} />;
}

Request.defaultProps = {
  isRequested: false,
  isRequestable: false,
};

export default Request;
