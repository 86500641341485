export default {
  main: '#6699CC',
  50: '#D6E4FF',
  100: '#B0E1FF',
  200: '#99CBFF',
  300: '#83B5E9',
  400: '#6D9FD3',
  500: '#568ABC',
  600: '#3F76A7',
  700: '#266291',
  800: '#004F7D',
  900: '#003D68',
};
