import React from 'react';
import { List, Tag } from 'antd';
import { EnumMappers } from '~/common';
import masked from '~/common/masked';
import { Infos, Date } from './styled';

const { Item } = List;
const { OperationStepType } = EnumMappers;
const { toDateAndHour } = masked;

function ContractComment(props) {
  const {
    userName,
    commentary,
    contractStatus,
    commentaryDate,
  } = props.comment;

  //Nome de variável não corresponde com valor. contractStus na verdade é operationStep
  const operationStep = OperationStepType(contractStatus);

  return (
    <Item>
      <Item.Meta
        title={userName}
        description={
          <Infos>
            <Date>{toDateAndHour(commentaryDate)}</Date>
            <Tag color='blue'>{operationStep}</Tag>
          </Infos>
        }
      />
      {commentary}
    </Item>
  );
}

export default ContractComment;
