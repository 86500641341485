const BANK_NAME = {
  bank033: 'Banco Santander',
  bank001: 'Banco do Brasil',
  bank237: 'Banco Bradesco',
  bank422: 'Banco Safra',
  bank707: 'Banco Daycoval',
  bank739: 'Banco Cetelem',
  bank623: 'Banco PAN',
  bank955: 'Banco Olé',
  bank318: 'Banco BMG',
  bank320: 'Banco CCB',
  bank326: 'MEU TUDO',
};

const GetBankName = (value: any) => {
  return BANK_NAME[`bank${value}`] || value;
};

export default GetBankName;
