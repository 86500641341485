import downloadPdfFile from '~/components/DownloadPDFFile';
import getHiscon, {
  HISCON_GET_FAILURE,
  HISCON_GET_SUCCESS,
} from '~/store/getHiscon/action';

export default downloadPdfFile({
  method: getHiscon,
  title: 'Abrir hiscon',
  stateName: 'getHiscon',
  successType: HISCON_GET_SUCCESS,
  errorType: HISCON_GET_FAILURE,
});
