import { Map, List } from 'immutable';

import {
  ADDRESS_FROM_ZIPCODE_REQUEST,
  ADDRESS_FROM_ZIPCODE_FAILURE,
  ADDRESS_FROM_ZIPCODE_SUCCESS,
} from './action';

const initialState = Map({
  type: '',
  payload: Map({}),
});

function reducer(state = initialState, action) {
  const { type } = action;

  if (type === ADDRESS_FROM_ZIPCODE_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => true)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], Map({}));
  }

  if (type === ADDRESS_FROM_ZIPCODE_FAILURE) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], Map(payload));
  }

  if (type === ADDRESS_FROM_ZIPCODE_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], Map(payload));
  }

  return state;
}

export default reducer;
