// @flow

import React from 'react';
import { Progress } from 'antd';
import { ProgressProps } from 'antd/lib/progress';

type Props = {
  total: number,
  current: number,
};

function ProgressBar(props: Props & ProgressProps) {
  const { total, current } = props;

  return (
    <Progress
      {...props}
      percent={(current / total) * 100}
      showInfo={current === total}
      size="small"
    />
  );
}

ProgressBar.defaultProps = {
  total: 10,
  current: 6,
};

export default ProgressBar;
