import React, { useCallback } from 'react';
import moment from 'moment';
import { Row } from 'antd';

import toHumanFileSize from '~/common/toHumanFileSize';
import { InfoCircle2 } from '~/ui/assets/icons';
import ImageOrigin from '~/typings/enums/ImageOrigin';
import { Tooltip } from '~/ui/components';
import { FileItem } from './FilesList';
import {
  ActionButton,
  FileContainer,
  FileDate,
  FileDetails,
  FileDownload,
  FileName,
  FileSize,
  ImageOriginTag,
  ImagePreview,
  Loading,
} from './FilesList.styled';

type FileProps = {
  file: FileItem;
  noImageText?: string;
  handleCompareFiles?: (file: FileItem) => void;
  handleShowHistory?: (file: FileItem) => void;
  handleFilePreview?: (file: FileItem) => void;
  handleDownload?: (file: FileItem) => void;
  inactive?: boolean;
};

export function FileSingle({
  file,
  noImageText,
  handleCompareFiles,
  handleShowHistory,
  handleFilePreview,
  handleDownload,
  inactive,
}: FileProps) {
  const formatDate = (date: string) => moment(date).format('DD/MM/YYYY [às] HH:mm');

  const isPdf = useCallback((file) => file?.extension === '.pdf', []);

  const renderImageOriginTag = useCallback(
    (imageOrigin: string) => (
      <Tooltip
        title="Análise de fotos"
        placement="bottomLeft"
        width={300}
        content={
          <>
            <p>
              Com essa funcionalidade, dá pra saber se a foto que o cliente mandou foi tirada pelo
              Facetec, Sorriso ou Meutudo.
            </p>
            <p>As tags servem pra indicar essa análise de envio.</p>
          </>
        }
      >
        <ImageOriginTag>
          <InfoCircle2 width={14} height={14} />
          {ImageOrigin[imageOrigin]}
        </ImageOriginTag>
      </Tooltip>
    ),
    [],
  );

  const renderPreview = useCallback(
    (file: FileItem) => {
      if (file.loading) {
        return (
          <ImagePreview loading={file.loading ? 1 : 0}>
            <Loading />
          </ImagePreview>
        );
      }

      if (!file.url) {
        return (
          <ImagePreview loading={!file.url || isPdf(file) ? 1 : 0}>
            <p>{noImageText || 'Sem imagem'}</p>
          </ImagePreview>
        );
      }

      return (
        <ImagePreview
          loading={!file.url || isPdf(file) ? 1 : 0}
          onClick={() => handleFilePreview?.(file)}
        >
          {isPdf(file) ? (
            <FileDownload width={38} height={48} />
          ) : (
            <img src={file.url} alt={file.name} />
          )}
          {!file.url && <p>{noImageText || 'Sem imagem'}</p>}
          {file.personImageOrigin && renderImageOriginTag(file.personImageOrigin)}
        </ImagePreview>
      );
    },
    [noImageText, isPdf],
  );

  return (
    <FileContainer loading={file.loading || !file.url ? 1 : 0} inactive={inactive}>
      {renderPreview(file)}

      <FileDetails>
        <Row type="flex" justify="start" align="middle">
          <ActionButton
            icon="FilesLandscapes"
            tooltip="Comparar"
            disabled={isPdf(file) || inactive}
            onClick={() => handleCompareFiles?.(file)}
          />

          <ActionButton
            icon="FileSearch"
            tooltip="Visualizar histórico"
            onClick={() => handleShowHistory?.(file)}
          />

          <ActionButton
            icon="Eye"
            tooltip={`Exibir ${isPdf(file) ? 'pdf' : 'foto'}`}
            onClick={() => handleFilePreview?.(file)}
          />

          <ActionButton
            icon="Download"
            tooltip={`Baixar ${isPdf(file) ? 'pdf' : 'foto'}`}
            onClick={() => handleDownload?.(file)}
          />
        </Row>

        <Row type="flex" justify="space-between" align="middle">
          <FileName>
            {file.name}.{isPdf(file) ? 'pdf' : 'jpg'}
          </FileName>
          {file.size && <FileSize>{toHumanFileSize(file.size)}</FileSize>}
        </Row>

        {file.uploadDate && <FileDate>{formatDate(file.uploadDate)}</FileDate>}
      </FileDetails>
    </FileContainer>
  );
}
