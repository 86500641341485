import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const EnvironmentInfoText = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background: ${themeColor('negative.primary', { dark: 'negative.primaryAlt' })};
  padding: 2px 8px;
  color: ${themeColor('element.invert')};
  font-size: 12px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  z-index: 999;

  button {
    background: transparent;
    border: none;
    font-size: 12px;
    padding: 0;
    cursor: pointer;
    margin-left: 4px;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
`;
