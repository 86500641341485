/* @flow */
/* global document, FormData */

import Immutable from 'immutable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OldActions, Spinner } from '~/components';
import { withRouter } from 'react-router';
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { mapObject } from 'underscore';
import theme from '~/themes/aphroditeTheme/theme';

import message from '~/common/message';
import styles from './styles';

const { css, withStyles } = theme;

type Props = {
  isDownloading: any,
  onPressDownloadHiscon: any,
  downloading: any,
  agreementName: any,
  styles: any,
};

export default function downloadPdfFile({ method, successType, errorType, stateName, title }) {
  class DownloadHiscon extends Component<Props> {
    onPress = () => {
      this.props.isDownloading(true);
      this.props.onPressDownloadHiscon();
    };

    render() {
      const { downloading, agreementName, styles } = this.props;

      if (agreementName !== 'INSS') return null;

      const content = downloading ? 'Preparando...' : title;

      return [
        <Spinner spinning={downloading} />,

        <OldActions
          key="button"
          onPress={this.onPress}
          style={css(styles.action)}
          title={`${content}`}
        />,
      ];
    }
  }

  DownloadHiscon.defaultProps = {
    onPressDownloadHiscon: () => {},
    success: false,
  };

  function onPressDownloadHiscon({ dispatch, personId }) {
    return () => {
      dispatch(method(personId));
    };
  }

  function mapStateToProps(state) {
    return {
      hisconFile: state.getIn([stateName]),
    };
  }

  function receiveChanges(prevProps, { hisconFile, isDownloading }) {
    if (Immutable.is(prevProps.hisconFile, hisconFile) === false) {
      switch (hisconFile.getIn(['type'])) {
        case successType: {
          const { uri } = hisconFile.getIn(['payload']).toJS();

          window.open(uri); // Abre o arquivo pdf em outra aba.
          isDownloading(false);

          return true;
        }
        case errorType:
          isDownloading(false);
          message.error('Falha ao baixar arquivo.');
          return true;
        default:
          return false;
      }
    }

    return false;
  }

  function propagateStateChangeToProps({ hisconFile }) {
    const props = mapObject({ hisconFile }, (state) => state.getIn(['payload']));
    return Object.assign(props, { success: true });
  }

  return compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps),
    withState('downloading', 'isDownloading', false),
    withHandlers({
      onPressDownloadHiscon,
    }),
    withPropsOnChange(receiveChanges, propagateStateChangeToProps),
  )(DownloadHiscon);
}
