/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
/* global fetch, localStorage */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { SOLVE_PAYCHECK_REQUEST, success, failure } from './action';

const events = (action$, state, { AjaxRequest }) =>
  action$
    .ofType(SOLVE_PAYCHECK_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(({ id, file }) => {
      const formData = new FormData();

      formData.append('file', file);

      return AjaxRequest.open(
        'post',
        `/admin/pendencycustom/pendencies/${id}/paycheck`,
        formData,
        {
          noContentType: true,
        },
      )('tudo')
        .flatMap(response => Observable.of(success(response)))
        .catch(err => Observable.of(failure(err)));
    });

export default combineEpics(events);
