/* @flow */

export const SEND_CCB_FOR_USER_SIGNATURE_REQUEST =
  'SEND_CCB_FOR_USER_SIGNATURE_REQUEST';
export const SEND_CCB_FOR_USER_SIGNATURE_SUCCESS =
  'SEND_CCB_FOR_USER_SIGNATURE_SUCCESS';
export const SEND_CCB_FOR_USER_SIGNATURE_FAILURE =
  'SEND_CCB_FOR_USER_SIGNATURE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: SEND_CCB_FOR_USER_SIGNATURE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: SEND_CCB_FOR_USER_SIGNATURE_FAILURE,
      payload: resource,
    });
  };
}

export default function sendCCBForUserSignature(contractId, values) {
  return dispatch => {
    dispatch({
      type: SEND_CCB_FOR_USER_SIGNATURE_REQUEST,
      payload: {
        contractId,
        values,
      },
    });
  };
}
