import moment from 'moment';

export default function getCustumerAge(custumerBirthDate) {
  const currentDate = moment();
  let birthDate = moment(custumerBirthDate, ['DD-MM-YYYY', 'YYYY-MM-DD']);

  const yearsLife = currentDate.diff(birthDate, 'years');
  const monthsLife = currentDate.diff(birthDate, 'months') - yearsLife * 12;

  return {
    yearsLife,
    monthsLife,
  };
}
