import React from 'react';
import styled, { css } from 'styled-components';

export const ContainerControl = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;
  margin: 10px 20px;
  outline: none;

  img {
    height: 100px;
    width: 100%;
    max-width: 300px;
    object-fit: contain;
    transition: all 0.25s ease-in-out;
  }
`;

export const Title = styled.div`
  color: #fff;
  text-align: center;
  letter-spacing: 0.5px;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  border-radius: 8px;
  width: auto;
  padding: 2px 16px;
  max-width: 100%;
  margin-bottom: 6px;
`;

export const ControlBar = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  
  button {
      border-radius: 100%;
      margin: 0 5px;
      width: 34px;
      height: 34px;
      background-color: rgba(0, 0, 0, 0.5);
      border: transparent;
      color: #fff;

      &:hover {
        background-color: rgba(0, 0, 0, 0.75);
        color: #fff;
      },

      &:focus {
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
      },
  }
`;
