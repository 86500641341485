import React, { FormEventHandler, useCallback, useMemo, useState } from 'react';

import { TagItem } from '~/ui/components';
import _ from 'lodash';
import includesStringSearch from '~/common/includesStringSearch';
import { TagOptionsContainer, SearchInput, Options, Option, NoTags } from './TagOptions.styled';

interface TagOptionsProps {
  currentTags?: TagItem[];
  options?: TagItem[];
  onAdd?: (tag: TagItem) => void;
  onCreate?: (tagName: string) => void;
}

export function TagOptions({ options, currentTags, onAdd, onCreate }: TagOptionsProps) {
  const [filterText, setFilterText] = useState('');

  const filterOptionsList = useCallback(() => {
    const formattedOptions = _.differenceBy(options, currentTags, 'id').filter((option) =>
      includesStringSearch(option.name, filterText),
    );
    return _.sortBy(formattedOptions, ['name']);
  }, [options, currentTags, filterText]);

  const handleSearchChange = useCallback((e) => {
    setFilterText(e.target.value);
  }, []);

  const clearFilterInput = () => {
    setTimeout(() => setFilterText(''), 100);
  };

  const handleClickOption = useCallback((selected) => {
    onAdd?.(selected);
    clearFilterInput();
  }, []);

  const filteredOptions = useMemo(() => filterOptionsList(), [options, currentTags, filterText]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (filteredOptions?.length > 0) {
      onAdd?.(filteredOptions[0]);
    } else if (filterText) {
      onCreate?.(filterText);
    }

    clearFilterInput();
  };

  return (
    <TagOptionsContainer>
      <form onSubmit={handleSubmit}>
        <SearchInput placeholder="Pesquisar tag" value={filterText} onChange={handleSearchChange} />
        <Options>
          {filteredOptions?.map((option) => (
            <Option onClick={() => handleClickOption(option)} key={option.id}>
              {option.name}
            </Option>
          ))}
          {!filteredOptions.length && <NoTags>Nenhuma tag encontrada</NoTags>}
        </Options>
      </form>
    </TagOptionsContainer>
  );
}
