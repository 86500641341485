import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { Modal } from 'antd';
import { ModalProps as AntModalProps } from 'antd/lib/modal';
import { ArrowLeftCircle, CloseCircle } from '~/ui/assets/icons';

type ModalConfig = AntModalProps & {
  id?: string;
  backButton?: boolean;
  onBackClick?: () => void;
  noBackground?: boolean;
  noPadding?: boolean;
};

type ModalProps = {
  id: string;
  config?: ModalConfig;
  opened: boolean;
  component: any;
  loading?: boolean;
};

type ModalContextData = {
  openModal(component: any, config?: ModalConfig): { id: string; close: () => void };
  closeModal(id: string): void;
  setLoading(id: string, loading: boolean): void;
  setConfig(id: string, config?: ModalConfig, replace?: boolean): void;
  closeAll(): void;
};

const defaultConfig: AntModalProps = {
  closeIcon: <CloseCircle width={22} height={22} />,
  destroyOnClose: true,
  closable: false,
  centered: true,
  footer: null,
};

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

function ModalProvider({ children }: PropsWithChildren<{}>) {
  const [modals, setModals] = useState<ModalProps[]>([]);

  const clearClosedModals = () => {
    setTimeout(() => {
      if (modals.some((el) => el.opened === false)) {
        setModals((currentValue) => currentValue.filter((el) => el.opened !== false));
      }
    }, 250);
  };

  const closeAll = () => {
    setModals((currentModals) => currentModals.map((modal) => ({ ...modal, opened: false })));
    clearClosedModals();
  };

  const closeModal = (id: string) => {
    setModals((currentModals) => {
      const restOfModals = currentModals.filter((el) => el.id !== id);
      const selectedModal = currentModals.find((el) => el.id === id);

      if (selectedModal) {
        selectedModal.opened = false;
        return [...restOfModals, selectedModal];
      }

      return currentModals;
    });

    clearClosedModals();
  };

  const setLoading = (id: string, loading: boolean) => {
    setModals((currentModals) => {
      const restOfModals = currentModals.filter((el) => el.id !== id);
      const selectedModal = currentModals.find((el) => el.id === id);

      if (selectedModal) {
        selectedModal.loading = loading;

        selectedModal.config = {
          ...selectedModal.config,
          maskClosable: loading ? false : selectedModal.config?.maskClosable,
          keyboard: loading ? false : selectedModal.config?.keyboard,
        };
        return [...restOfModals, selectedModal];
      }

      return currentModals;
    });

    clearClosedModals();
  };

  const setConfig = (id: string, config?: ModalConfig, replace?: boolean) => {
    setModals((currentModals) => {
      const restOfModals = currentModals.filter((el) => el.id !== id);
      const selectedModal = currentModals.find((el) => el.id === id);

      if (selectedModal) {
        selectedModal.config = replace ? config : { ...selectedModal.config, ...config };
        return [...restOfModals, selectedModal];
      }

      return currentModals;
    });
  };

  const openModal = useCallback(
    (component, config?: ModalConfig) => {
      const id = config?.id || Math.random().toString(36).substring(7);

      setModals((currentModals) => {
        const restOfModals = currentModals.filter((el) => el.id !== id);
        let selectedModal = currentModals.find((el) => el.id === id);

        if (selectedModal) {
          selectedModal.opened = true;
        } else {
          selectedModal = {
            id,
            opened: true,
            component,
            config: config || {},
          };
        }
        return [...restOfModals, selectedModal];
      });

      return {
        id,
        close: () => closeModal(id),
        loading: (loading: boolean) => setLoading(id, loading),
        config: (config: ModalConfig) => setConfig(id, config),
      };
    },
    [setModals],
  );

  const handleOnClose = (modal) => {
    if (modal?.config?.onClose) {
      modal?.config?.onClose();
    }

    closeModal(modal?.id);
  };

  const renderTitle = useCallback((config: ModalConfig) => {
    const { title, backButton, onBackClick } = config;

    if (!title) return null;

    return (
      <>
        {backButton && <ArrowLeftCircle width={24} height={24} onClick={onBackClick} />}
        {title}
      </>
    );
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal, closeAll, setLoading, setConfig }}>
      {children}
      {modals.map((modal) => (
        <Modal
          key={modal.id}
          {...defaultConfig}
          {...modal.config}
          title={renderTitle(modal.config)}
          visible={modal.opened}
          className={`mt-modal ${modal.loading ? 'loading' : ''} ${modal.config?.className || ''} ${
            modal.config.backButton ? 'with-back-button' : ''
          } ${modal.config?.noBackground ? 'bg-transparent' : ''} ${
            modal.config?.noPadding ? 'no-padding' : ''
          }`}
          onCancel={() => handleOnClose(modal)}
          onOk={() => handleOnClose(modal)}
        >
          {modal.component}
        </Modal>
      ))}
    </ModalContext.Provider>
  );
}

function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used inside of ModalProvider');
  }

  return context;
}

export { ModalProvider, useModal };
