import { Divider as DividerComponent, Menu } from 'antd';
import styled from 'styled-components';
import { Button, Typography } from '~/ui/components';
import { ColorsTypes } from '~/ui/theme/colors';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

interface PalettedProps {
  colorPalette?: ColorsTypes;
}

export const MainButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  padding: 10px 5px 5px 32px;
  margin-bottom: 10px;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
    fill: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const ActionsTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'h4',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  weight: 600,
}))``;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px 10px 6px 24px;
  margin-left: 5px;
  border-left: 3px solid transparent;
  cursor: pointer;
  transition: all 0.18s;

  :hover {
    p {
      font-weight: 600 !important;
    }

    background-color: ${themeColor('state.hover')};
    border-color: ${themeColor('bran.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const ItemText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const Divider = styled(DividerComponent)<PalettedProps>`
  margin: 2px 0 10px !important;
  height: 2px !important;
  background: ${themeColor('divider.primary')} !important;
  width: 90% !important;
  min-width: 90% !important;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphLarge',
  weight: 500,
  color: theme.colors.element.primary,
}))``;

export const ItemWrapper = styled(Menu).attrs({
  selectable: false,
})`
  width: 200px;
  margin: 0;
  padding: 4px 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%) !important;
`;

export const Option = styled(Menu.Item).attrs({})`
  padding: 0;
  margin: 0;

  :hover {
    background-color: ${({ theme }) => theme.colors.primary['50']}30;
  }
`;

export const CustomButton = styled(Button).attrs(({ theme }) => ({
  customColor: theme.colors.gray.main,
}))`
  margin-top: 8px;
`;

export const Wrapper = styled.div`
  width: auto;
`;

export const ShimmerContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ModalText = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  element: 'p',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  padding: 8px 0 16px;

  b {
    font-weight: 600;
    display: block;
    padding: 8px 0 0;
  }
`;

export const ConfirmButton = styled(Button).attrs(({ theme }) => ({
  customColor: theme.colors.gray.main,
  rounded: true,
  fullWidth: true,
}))`
  margin-top: 16px;
`;

export const Text = styled.div`
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
`;
