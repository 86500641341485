import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const StyledInput = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  max-width: 185px;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  background: transparent;

  ${({ withError, theme }) =>
    withError &&
    css`
      color: ${theme.colors.negative.primary};
    `}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

export const HelpText = styled.span`
  color: ${({ theme }) => theme.colors.negative.primary};
  font-size: 12px;
  position: absolute;
  bottom: -20px;
`;
