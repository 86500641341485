/* global localStorage */

import showMessage from '~/common/message';

export const AUTHENTICATION_CHAT_FETCH_REQUEST = 'AUTHENTICATION_CHAT_FETCH_REQUEST';
export const AUTHENTICATION_CHAT_FETCH_SUCCESS = 'AUTHENTICATION_CHAT_FETCH_SUCCESS';
export const AUTHENTICATION_CHAT_FETCH_FAILURE = 'AUTHENTICATION_CHAT_FETCH_FAILURE';

export function success(response) {
  return (dispatch) => {
    const { authenticationToken } = response;
    localStorage.setItem('authenticationToken', authenticationToken);
    dispatch({
      type: AUTHENTICATION_CHAT_FETCH_SUCCESS,
      loading: false,
      payload: response.authenticationToken,
    });
  };
}

export function failure(resource) {
  return (dispatch) => {
    dispatch({
      type: AUTHENTICATION_CHAT_FETCH_FAILURE,
      loading: false,
      payload: resource,
    });

    showMessage.error('Falha na Autenticação.');
  };
}

export default function auth(entityId, acessToken) {
  return (dispatch) => {
    localStorage.removeItem('authenticationToken');
    dispatch({
      type: AUTHENTICATION_CHAT_FETCH_REQUEST,
      loading: true,
      payload: {
        entityId,
        acessToken,
      },
    });
  };
}
