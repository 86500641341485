import React, { useMemo } from 'react';
import { Timeline } from 'antd';
import { EllipsisStatusActive, EllipsisStatusConcluded, Loading } from '~/ui/assets/icons';
import { Accompaniment } from '~/typings/entities/Accompaniment';
import {
  MessageWrapper,
  PopOverContentContainer,
  Text,
  SmallText,
  StepWrapper,
  TriggerText,
} from './TicketAccompaniment.styled';

interface TicketAccompanimentModalContentProps {
  accompaniments: Accompaniment[];
  isLoading: boolean;
  hasError: boolean;
}

const dateTimeFormat: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const dateTimeFormater = new Intl.DateTimeFormat('pt-BR', dateTimeFormat);

export function TicketAccompanimentModalContent({
  accompaniments,
  isLoading,
  hasError,
}: TicketAccompanimentModalContentProps) {
  if (isLoading) {
    return (
      <MessageWrapper>
        <Loading />
      </MessageWrapper>
    );
  }

  if (hasError) {
    return (
      <MessageWrapper>
        <TriggerText>Não disponível.</TriggerText>
      </MessageWrapper>
    );
  }

  function getAccompanimentStep(accompaniment: Accompaniment[]) {
    return accompaniment.map(({ description, status, autor, date, groupStatusId }) => {
      const descriptionAndStatus = status ? `${description} - ${status}` : description;
      const authorAndDate = `${autor} - ${dateTimeFormater.format(new Date(date))}`;
      const timelineItemId = `${autor}${date}${description}`;
      return (
        <Timeline.Item
          key={timelineItemId}
          dot={
            groupStatusId && groupStatusId === 3 ? (
              <EllipsisStatusConcluded />
            ) : (
              <EllipsisStatusActive />
            )
          }
        >
          <StepWrapper>
            <Text>{descriptionAndStatus}</Text>
            <SmallText>{authorAndDate}</SmallText>
          </StepWrapper>
        </Timeline.Item>
      );
    });
  }

  const accompanimentStep = useMemo(() => getAccompanimentStep(accompaniments), [accompaniments]);

  return (
    <PopOverContentContainer>
      <Timeline>{accompanimentStep}</Timeline>
    </PopOverContentContainer>
  );
}
