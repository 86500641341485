import React from 'react';
import { Button, Flex, Typography, withStepperProps } from '~/ui/components';

import images from '~/assets/images';
import { useAwaitControl } from 'react-redux-await-control';
import { selectAssessment, selectTicket } from '~/store/tickets/actions';
import { CloseButton, AssessmentBody, AssessmentHeader } from '../Assessment.styled';

export type SuccessProps = {
  onClose: () => void;
  values: any;
};

export const Success = withStepperProps<SuccessProps>(({ values, onClose }) => {
  const selectAssessmentControl = useAwaitControl(selectAssessment);

  const handleFinish = () => {
    selectAssessmentControl.clear();
    onClose?.();
  };

  const handleViewAssessment = () => {
    selectAssessmentControl.start(values);
  };

  return (
    <AssessmentBody>
      <Flex direction="column" align="center" justify="center" gap={24} height="100%">
        <Flex direction="column" align="center" justify="center" mv={30}>
          <img width={236} src={images.noComments} alt="Sucesso" />
        </Flex>

        <Flex direction="column" align="center" justify="flex-start" gap={4} grow={1}>
          <Typography type="headingH5" center weight={700}>
            Avaliação finalizada!
          </Typography>

          <Typography type="bodyMedium" center color="element.secondary" width={312}>
            Recebemos a sua avaliação. Você pode avaliar outro ticket.
          </Typography>
        </Flex>

        <Flex direction="column" align="center" justify="center" gap={8} ph={24}>
          <Button rounded customColor="black" fullWidth onClick={handleFinish}>
            Fechar
          </Button>

          <Button
            rounded
            customColor="black"
            fullWidth
            variant="outline"
            onClick={handleViewAssessment}
          >
            Ver avaliação
          </Button>
        </Flex>
      </Flex>
    </AssessmentBody>
  );
});
