/* @flow */

export const REQUEST_NEW_CONTRACT_BANK_ACCOUNT_REQUEST =
  'REQUEST_NEW_CONTRACT_BANK_ACCOUNT_REQUEST';
export const REQUEST_NEW_CONTRACT_BANK_ACCOUNT_SUCCESS =
  'REQUEST_NEW_CONTRACT_BANK_ACCOUNT_SUCCESS';
export const REQUEST_NEW_CONTRACT_BANK_ACCOUNT_FAILURE =
  'REQUEST_NEW_CONTRACT_BANK_ACCOUNT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: REQUEST_NEW_CONTRACT_BANK_ACCOUNT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: REQUEST_NEW_CONTRACT_BANK_ACCOUNT_FAILURE,
      payload: resource,
    });
  };
}

export default function requestNewContractBankAccount(params) {
  return dispatch => {
    dispatch({
      type: REQUEST_NEW_CONTRACT_BANK_ACCOUNT_REQUEST,
      payload: params,
    });
  };
}
