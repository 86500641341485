export const GENERAL_ERRORS = {
  USER_NOT_FOUND: 'Usuário não encontrado',
  NOFACES_FOUND: 'Nenhum rosto encontrado',
  FACEALREADYREGISTERED_FOUND: 'Rosto já registrado',
  MULTIPLEFACES_FOUND: 'Vários rostos encontrados',
  'Service Channel not found in database': 'Canal de atendimento não encontrado',
};

export const HISCON_ERRORS = {
  ...GENERAL_ERRORS,
  DIFF_ENROLLMENT_IN_FILE:
    'Extrato desatualizado: O extrato de empréstimo enviado não corresponde ao número do benefício informado no cadastro. Envie um extrato próprio do beneficiário cadastrado na meutudo',
  EXPIRED_FILE:
    'Extrato inválido: O extrato de empréstimo enviado foi emitido há mais de 30 dias. Envie um extrato mais recente.',
  INVALID_FILE:
    'Arquivo inválido! O arquivo enviado não é um HISCON, por favor envie novamente o documento correto em formato pdf.',
};
