import React, { useEffect, useMemo, useState } from 'react';
import { Popover } from '~/ui/components';
import { useTheme } from 'styled-components';
import {
  CaretDown,
  EllipsisStatusActive,
  EllipsisStatusConcluded,
  Loading,
} from '~/ui/assets/icons';

import { isTicketStatusComplete } from '~/common/tickets';
import { useAwaitControl } from 'react-redux-await-control';
import { getTicketAccompaniments, getTicketStatus } from '~/store/tickets/actions';
import { Accompaniment } from '~/typings/entities/Accompaniment';

import { Ticket } from '~/typings/entities/Ticket';
import { themeToggleValue } from '~/common/utils/theme';
import {
  TableTriggerText,
  TicketAccompanimentLabelContainer,
  TriggerText,
} from './TicketAccompaniment.styled';
import { TicketAccompanimentModalContent } from './TicketAccompanimentModalContent';

interface TicketAccompanimentProps {
  showStatusIcon?: boolean;
  triggerText?: string;
  fetchBeforeOpen?: boolean;
  ticket: Ticket;
}

export function TicketAccompaniment({
  showStatusIcon,
  triggerText,
  fetchBeforeOpen,
  ticket,
}: TicketAccompanimentProps) {
  const getTicketHistoryControl = useAwaitControl(getTicketAccompaniments);
  const getTicketStatusControl = useAwaitControl(getTicketStatus);
  const theme = useTheme();

  const accompaniments: Accompaniment[] = getTicketHistoryControl.result(
    fetchBeforeOpen ? undefined : ticket?.id,
  );
  const isLoading = getTicketHistoryControl.isRunning(fetchBeforeOpen ? undefined : ticket?.id);
  const hasError = getTicketHistoryControl.hasFailure(fetchBeforeOpen ? undefined : ticket?.id);

  const status = getTicketStatusControl.result();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const isConcluded = useMemo(
    () => isTicketStatusComplete(status, ticket?.ticketStatusId),
    [status, ticket?.ticketStatusId],
  );

  useEffect(() => {
    if (ticket?.id && isModalVisible && !fetchBeforeOpen) {
      getTicketHistoryControl.start({ id: ticket.id }, { actionId: ticket.id });
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (ticket?.id && fetchBeforeOpen) {
      getTicketHistoryControl.start({ id: ticket.id });
    }
  }, [ticket]);

  const handleModalVisibilityChange = (ev: boolean) => setIsModalVisible(ev);

  const getPopoverLabel = () => {
    const { brand } = theme.colors;

    if (!fetchBeforeOpen) {
      return (
        <TicketAccompanimentLabelContainer>
          <TableTriggerText>{triggerText}</TableTriggerText>
          <CaretDown
            color={themeToggleValue(theme, brand.primary, { dark: brand.primaryAlt })}
            className="action-icon"
            width={14}
            height={14}
          />
        </TicketAccompanimentLabelContainer>
      );
    }

    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return <TriggerText>Não obtido.</TriggerText>;
    }

    return (
      <TicketAccompanimentLabelContainer>
        {showStatusIcon && (isConcluded ? <EllipsisStatusConcluded /> : <EllipsisStatusActive />)}
        <TriggerText>{triggerText}</TriggerText>
        <CaretDown
          color={themeToggleValue(theme, brand.primary, { dark: brand.primaryAlt })}
          className="action-icon"
          width={14}
          height={14}
        />
      </TicketAccompanimentLabelContainer>
    );
  };

  return (
    <Popover
      placement="bottomLeft"
      trigger="hover"
      title="Status de acompanhamento"
      content={
        <TicketAccompanimentModalContent
          accompaniments={accompaniments}
          isLoading={isLoading}
          hasError={hasError}
        />
      }
      onVisibleChange={handleModalVisibilityChange}
      visible={isModalVisible}
    >
      {getPopoverLabel()}
    </Popover>
  );
}
