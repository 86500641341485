/* @flow */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import {
  GET_CONTRACT_IMAGE_FACE_FRONT_REQUEST,
  success,
  failure,
} from './action';

const epicSuccess = (response, type) => ({
  type,
  exist: response.size !== 0,
  size: response.size,
  uri: URL.createObjectURL(response),
});

const contractImageFaceFront = (action$, state$, { AjaxRequest }) => {
  const imageType = 'FACE_FRONT';
  return action$
    .ofType(GET_CONTRACT_IMAGE_FACE_FRONT_REQUEST)
    .map(({ payload: { id } }) => id)
    .mergeMap(id => {
      const uri = `/admin/contractcustom/contracts/${id}/personimage`;
      return AjaxRequest.open('get', uri, {}, { responseType: 'blob' })('tudo')
        .flatMap(({ response }) =>
          Observable.of(success(epicSuccess(response, imageType))),
        )
        .catch(err => Observable.of(failure(err)));
    });
};

export default combineEpics(contractImageFaceFront);
