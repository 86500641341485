import React from 'react';
import { ListWithArrow } from '~/components/ProfileResume/ProfileResume.styled';
import { ArrowRightCircle, CaretDown } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';

type PronounsPopoverProps = {
  pronouns: string[];
  selected?: string;
  onChange?: (pronoun: string) => void;
};

export function PronounsPopover({ pronouns, selected, onChange }: PronounsPopoverProps) {
  return (
    <Popover
      placement="right"
      title="Pronomes do seu cliente"
      trigger="click"
      content={
        <ListWithArrow selectable>
          {pronouns?.map((pronoun) => (
            <li
              key={pronoun}
              className={pronoun === selected ? 'selected' : ''}
              onClick={() => onChange?.(pronoun)}
            >
              <ArrowRightCircle width={16} height={16} />
              {pronoun}
            </li>
          ))}
        </ListWithArrow>
      }
    >
      <CaretDown className="action-icon" width={14} height={14} />
    </Popover>
  );
}
