import styled from 'styled-components';

import { Modal, Button } from 'antd';

export const StepTitle = styled.p`
  font-size: 16px;
  text-align: center;
  padding: 20px 30px 5px;
`;

export const StepFooter = styled.div`
  text-align: center;
  position: absolute;
  bottom: 28px;
  width: 472px;
`;

export const ResultContent = styled.div`
  margin-top: 35px;
`;

const BaseContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const LoadingContent = styled(BaseContent)`
  height: 100%;
`;

export const FeedbackContent = styled(BaseContent)`
  height: 100px;
  align-items: start;
  margin-top: 20px;
`;

export const FeedbackImg = styled.img`
  height: 60%;
  width: auto;
`;

export const CustomFormButton = styled(Button)`
  padding: 0 35px;
  margin: 15px 5px 0px;
`;

export const Label = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  text-align: ${props => props.align || 'start'};
`;

export const Value = styled(Label)`
  font-weight: bold;

  ${props => props.negative && { color: '#EF9424' }}

  i {
    color: ${props => props.theme.primaryColor};
  }
`;

export const ContractNumber = styled.span`
  color: ${props => props.theme.primaryColor};
  font-weight: bold;
  text-decoration: underline;
`;
