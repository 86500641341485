import styled, { css } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Flex } from '~/ui/components';

export const CheckboxContainer = styled.div<{ rounded?: boolean; checked?: boolean }>`
  background: ${({ theme, checked }) =>
    checked
      ? themeToggleColor(theme, 'brand.secondary', { dark: 'brand.secondary' })
      : themeToggleColor(theme, 'background.secondary', { dark: 'background.primary' })};
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: ${({ rounded }) => (rounded ? '8px' : '0px')};
`;

export const ContractBox = styled.div<{ disabled?: boolean }>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.element.disabled};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const Informations = styled(Flex)`
  cursor: pointer;
`;
