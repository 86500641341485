/* @flow */
export const CONFIRM_REQUEST_REFUSAL_CONTRACT_REQUEST =
  'CONFIRM_REQUEST_REFUSAL_CONTRACT_REQUEST';
export const CONFIRM_REQUEST_REFUSAL_CONTRACT_SUCCESS =
  'CONFIRM_REQUEST_REFUSAL_CONTRACT_SUCCESS';
export const CONFIRM_REQUEST_REFUSAL_CONTRACT_FAILURE =
  'CONFIRM_REQUEST_REFUSAL_CONTRACT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: CONFIRM_REQUEST_REFUSAL_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: CONFIRM_REQUEST_REFUSAL_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

export default function refuseContract(contractId, password) {
  return dispatch => {
    dispatch({
      type: CONFIRM_REQUEST_REFUSAL_CONTRACT_REQUEST,
      payload: {
        contractId,
        password,
      },
    });
  };
}
