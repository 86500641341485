import styled from 'styled-components';

export const ContentContainer = styled.div`
  flex: 1;
  height: 100%;
  min-width: 360px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.primary};
  padding: 0 24px;
  margin-bottom: 25px;
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 230px);
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 4px;
  }
`;

export const NoTicketsContainer = styled.div`
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
