import { Collapse as AntCollapse } from 'antd';
import styled from 'styled-components';

export const Collapsible = styled(AntCollapse).attrs({
  expandIconPosition: 'right',
  bordered: false,
  defaultActiveKey: ['1'],
})`
  background-color: transparent;

  .ant-collapse-item {
    border: none;

    .ant-collapse-content {
      padding: 0 !important;
    }

    .ant-collapse-content-box {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0 0 !important;
    }
  }
`;

export const CollapsiblePanel = styled(AntCollapse.Panel)`
  background-color: transparent;
  border: none;

  .ant-collapse-header {
    padding: 0 !important;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
  }
`;

Collapsible.Panel = CollapsiblePanel;
