import AwaitControl from 'react-redux-await-control';
import { combineReducers } from 'redux-immutable';

import message from '~/common/message';

import addressFromZipcode from './addressFromZipcode/reducer';
import adjustContract from './adjustContract/reducer';
import approvePersonRegistry from './approvePersonRegistry/reducer';
import authenticationChat from './authenticationChat/reducer';
import cancelContractSignature from './cancelContractSignature/reducer';
import concludeContractSignature from './concludeContractSignature/reducer';
import confirmRequestRefusalContract from './confirmRequestRefusalContract/reducer';
import createUser from './createUser/reducer';
import deleteLoanHistory from './deleteLoanHistory/reducer';
import fetchAvailableExistingLoans from './fetchAvailableExistingLoans/reducer';
import fetchAvailableLoanHistories from './fetchAvailableLoanHistories/reducer';
import fetchCommissioningTypes from './fetchCommissioningTypes/reducer';
import fetchContractCommisionings from './fetchContractCommisionings/reducer';
import fetchContractRefusalReasons from './fetchContractRefusalReasons/reducer';
import fetchContracts from './fetchContracts/reducer';
import fetchContractsOffers from './fetchContractsOffers/reducer';
import fetchFinancialInstitutionsForDeposit from './fetchFinancialInstitutionsForDeposit/reducer';
import fetchLoanHistories from './fetchLoanHistories/reducer';
import fetchOperationStepsByContract from './fetchOperationStepsByContract/reducer';
import fetchPersonRegisterResetReasons from './fetchPersonRegisterResetReasons/reducer';
import fetchRefinancedContracts from './fetchRefinancedContracts/reducer';
import fillContract from './fillContract/reducer';
import getChatInfo from './getChatInfo/reducer';
import getConcession from './getConcession/reducer';
import getContractComments from './getContractComments/reducer';
import getContractDocument from './getContractDocument/reducer';
import getContractFiles from './getContractFiles/reducer';
import getContractImageFaceFront from './getContractImageFaceFront/reducer';
import getContractLogs from './getContractLogs/reducer';
import getFileHistory from './getFileHistory/reducer';
import getFileHistoryFiles from './getFileHistoryFiles/reducer';
import getValidationBiometry from './getValidationBiometry/reducer';
import getHiscon from './getHiscon/reducer';
import getMedicalCertificate from './getMedicalCertificate/reducer';
import getPersonRegistryFiles from './getPersonRegistryFiles/reducer';
import hiddenContractFields from './hiddenContractFields/reducer';
import linkContractOwner from './linkContractOwner/reducer';
import listRolesOfUser from './listRolesOfUser/reducer';
import payContract from './payContract/reducer';
import refusePersonRegistry from './refusePersonRegistry/reducer';
import registerCommissioning from './registerCommissioning/reducer';
import registerLoanHistory from './registerLoanHistory/reducer';
import requestAddress from './requestAddress/reducer';
import requestNewContractBankAccount from './requestNewContractBankAccount/reducer';
import requestNewImage from './requestNewImage/reducer';
import requestNewSignature from './requestNewSignature/reducer';
import requestRefusalContract from './requestRefusalContract/reducer';
import requestResetPerson from './requestResetPerson/reducer';
import revertCetelem from './revertCetelem/reducer';
import reserveMargin from './reserveMargin/reducer';
import resetPerson from './resetPerson/reducer';
import reverseCancelContract from './reverseCancelContract/reducer';
import saveTrustworthyBiometry from './saveTrustworthyBiometry/reducer';
import sendCCBForUserSignature from './sendCCBForUserSignature/reducer';
import sendContract from './sendContract/reducer';
import sendContractComment from './sendContractComment/reducer';
import sendSignatureToContract from './sendSignatureToContract/reducer';
import setCheckedContract from './setCheckedContract/reducer';
import setRefinancedAkey from './setRefinancedAkey/reducer';
import setUncheckedContract from './setUncheckedContract/reducer';
import sendBiometry from './sendBiometry/reducer';
import solveAddress from './solveAddress/reducer';
import solveBankAccount from './solveBankAccount/reducer';
import solveDocument from './solveDocument/reducer';
import solvePaycheck from './solvePaycheck/reducer';
import solveProofOfAddress from './solveProofOfAddress/reducer';
import tagsGet from './tagsGet/reducer';
import tagTypesDelete from './tagTypesDelete/reducer';
import tagTypesGet from './tagTypesGet/reducer';
import tagTypesSave from './tagTypesSave/reducer';
import undoRequestRefusal from './undoRequestRefusal/reducer';
import updateConsigneeStatus from './updateConsigneeStatus/reducer';
import updateContractValues from './updateContractValues/reducer';
import updateLoanHistory from './updateLoanHistory/reducer';
import updateMargin from './updateMargin/reducer';
import updatePDFFile from './updatePDFFile/reducer';
import updateRefinancedContract from './updateRefinancedContract/reducer';
import updateTrustFace from './updateTrustFace/reducer';
import uploadMedicalCertificate from './uploadMedicalCertificate/reducer';

const reducers = AwaitControl.init({
  extractState: (e) => (key) => e.getIn([key]),
  baseContext: {
    responseParser: (response) => response.data.resource,
    onRequestError: (err, _, meta) => {
      const { avoidErrorMessage, errorMessage } = meta;

      if (!avoidErrorMessage && err.response?.status !== 401) {
        message.error(errorMessage || err.message);
      }
    },
  },
}).mix({
  addressFromZipcode,
  adjustContract,
  approvePersonRegistry,
  authenticationChat,
  cancelContractSignature,
  concludeContractSignature,
  confirmRequestRefusalContract,
  createUser,
  deleteLoanHistory,
  fetchAvailableExistingLoans,
  fetchAvailableLoanHistories,
  fetchCommissioningTypes,
  fetchContractCommisionings,
  fetchContractRefusalReasons,
  fetchContracts,
  fetchContractsOffers,
  fetchFinancialInstitutionsForDeposit,
  fetchLoanHistories,
  fetchOperationStepsByContract,
  fetchPersonRegisterResetReasons,
  fetchRefinancedContracts,
  fillContract,
  getChatInfo,
  getConcession,
  getContractComments,
  getContractDocument,
  getContractFiles,
  getContractImageFaceFront,
  getContractLogs,
  getFileHistory,
  getFileHistoryFiles,
  getHiscon,
  getMedicalCertificate,
  getPersonRegistryFiles,
  getValidationBiometry,
  hiddenContractFields,
  linkContractOwner,
  listRolesOfUser,
  payContract,
  refusePersonRegistry,
  registerCommissioning,
  registerLoanHistory,
  requestAddress,
  requestNewContractBankAccount,
  requestNewImage,
  requestNewSignature,
  requestRefusalContract,
  requestResetPerson,
  revertCetelem,
  reserveMargin,
  resetPerson,
  reverseCancelContract,
  saveTrustworthyBiometry,
  sendBiometry,
  sendCCBForUserSignature,
  sendContract,
  sendContractComment,
  sendSignatureToContract,
  setCheckedContract,
  setRefinancedAkey,
  setUncheckedContract,
  solveAddress,
  solveBankAccount,
  solveDocument,
  solvePaycheck,
  solveProofOfAddress,
  tagsGet,
  tagTypesDelete,
  tagTypesGet,
  tagTypesSave,
  undoRequestRefusal,
  updateConsigneeStatus,
  updateContractValues,
  updateLoanHistory,
  updateMargin,
  updatePDFFile,
  updateRefinancedContract,
  updateTrustFace,
  uploadMedicalCertificate,
});

export default combineReducers({ ...reducers });
