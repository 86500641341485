import styled, { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';

interface DisplayProps {
  desktopOnly?: boolean;
}

export interface ShimmerProps extends DisplayProps {
  height?: number;
  width?: number;
  m?: number; // margin
  mt?: number; // margin top
  mb?: number; // margin bottom
  ml?: number; // margin left
  mr?: number; // margin right
  br?: number; // border radius
  circular?: boolean; // circular border radius
}

export const ShimmerContainer = styled.div.attrs({
  className: 'shimmer',
})<ShimmerProps>`
  ${({ height, width, m, mb, ml, mr, mt, br, desktopOnly, circular }) => css`
    max-width: ${width ? `${width}px` : 'none'};
    height: ${height}px;
    width: 100%;
    overflow: hidden;

    ${m && `margin: ${m}`};
    ${mb && `margin-bottom: ${mb}px`};
    ${mt && `margin-top: ${mt}px`};
    ${ml && `margin-left: ${ml}px`};
    ${mr && `margin-right: ${mr}px`};
    ${br && `border-radius: ${br}px`};

    ${desktopOnly &&
    css`
      display: none;
      @media (min-width: 1200px) {
        display: block;
      }
    `}

    ${circular &&
    css`
      border-radius: 50%;
    `}
  `}
`;

export const Skeleton = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    -90deg,
    ${themeColor('background.secondary', { dark: 'background.tertiary' })} 0%,
    ${themeColor('background.tertiary', { dark: 'background.secondary' })} 50%,
    ${themeColor('background.secondary', { dark: 'background.tertiary' })} 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;

  @keyframes shimmer {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -135% 0;
    }
  }
`;
