/* @flow */

import { message } from '~/common';

export const SOLVE_PROOF_OF_ADDRESS_REQUEST = 'SOLVE_PROOF_OF_ADDRESS_REQUEST';
export const SOLVE_PROOF_OF_ADDRESS_SUCCESS = 'SOLVE_PROOF_OF_ADDRESS_SUCCESS';
export const SOLVE_PROOF_OF_ADDRESS_FAILURE = 'SOLVE_PROOF_OF_ADDRESS_FAILURE';

export function success(payload) {
  message.success('Pendência de comprovante de residência resolvida');
  return dispatch => {
    dispatch({
      type: SOLVE_PROOF_OF_ADDRESS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  message.error('Falha ao resolver pendência');
  return dispatch => {
    dispatch({
      type: SOLVE_PROOF_OF_ADDRESS_FAILURE,
      payload: resource,
    });
  };
}

export default function uploadHiscon(params) {
  return dispatch => {
    dispatch({
      type: SOLVE_PROOF_OF_ADDRESS_REQUEST,
      payload: params,
    });
  };
}
