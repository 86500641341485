import { css } from 'styled-components';

export default css`
  .ant-layout-header {
  }

  .layout {
    //&.ant-layout {
    //  height: auto;
    //  min-height: 100vh;
    //  min-width: 680px;
    //}

    .ant-layout-content {
    }

    .ant-layout-sider {
      //overflow: auto;
      //height: calc(100vh - 73px);
      //position: fixed;
      //left: 0;
      //z-index: 999;
    }
  }
`;
