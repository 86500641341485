import React, { useCallback, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { Flex, Box } from 'reflexbox';

import { TabPanel } from '~/components';
import { Person } from '~/typings/entities/person';
import EmptyPanel, { PanelKindTypes } from '~/components/EmptyPanel/EmptyPanel';
import { listPersonComments } from '~/store/people/actions';
import { Shimmer } from '~/ui/components';
import { Divider } from 'antd';
import Comment from './Comment';
import { CommentWrapper } from './styled';

type AttendanceProps = {
  contracts: any;
  person: Person;
  loading?: boolean;
};

export default function Attendance({ contracts, person, loading, ...props }: AttendanceProps) {
  const [visible, setVisible] = useState(false);
  const listCommentsControl = useAwaitControl(listPersonComments);
  const comments = listCommentsControl.result();
  const commentsIsLoading = listCommentsControl.isRunning();

  const setVisibility = useCallback(() => {
    setVisible((value) => !value);
  }, []);

  if ((!comments?.length && commentsIsLoading) || loading) {
    return (
      <>
        <Shimmer height={20} width={400} mb={12} />
        <Shimmer height={20} width={100} mb={5} />
        <Shimmer height={20} width={500} mb={5} />
        <Shimmer height={20} width={450} mb={20} />
        <Shimmer height={20} width={100} mb={5} />
        <Shimmer height={20} width={500} mb={5} />
        <Shimmer height={20} width={450} mb={20} />

        <Divider type="horizontal" />

        <Shimmer height={20} width={400} mb={12} mt={20} />
        <Shimmer height={20} width={100} mb={5} />
        <Shimmer height={20} width={500} mb={5} />
        <Shimmer height={20} width={450} mb={20} />
        <Shimmer height={20} width={100} mb={5} />
        <Shimmer height={20} width={500} mb={5} />
        <Shimmer height={20} width={450} mb={20} />
      </>
    );
  }

  return (
    <TabPanel {...props}>
      <Flex wrap align="flex-start" style={{ width: '100%' }}>
        <Box my={1} w={4 / 4}>
          {!comments?.length && (
            <EmptyPanel btnAction={setVisibility} kind={PanelKindTypes.PERSON_COMMENT} />
          )}

          {!!comments?.length && (
            <CommentWrapper className="comments-list">
              {comments.map((c) => (
                <Comment key={c.id} contracts={contracts.entries} person={person} comment={c} />
              ))}
            </CommentWrapper>
          )}
        </Box>
      </Flex>
    </TabPanel>
  );
}
