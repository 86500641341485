/* @flow */

export const REQUEST_NEW_SIGNATURE_REQUEST = 'REQUEST_NEW_SIGNATURE_REQUEST';
export const REQUEST_NEW_SIGNATURE_SUCCESS = 'REQUEST_NEW_SIGNATURE_SUCCESS';
export const REQUEST_NEW_SIGNATURE_FAILURE = 'REQUEST_NEW_SIGNATURE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: REQUEST_NEW_SIGNATURE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: REQUEST_NEW_SIGNATURE_FAILURE,
      payload: resource,
    });
  };
}

export default function requestNewSignature(params) {
  return dispatch => {
    dispatch({
      type: REQUEST_NEW_SIGNATURE_REQUEST,
      payload: params,
    });
  };
}
