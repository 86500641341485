import React from 'react';
import { MobileSidebar } from '~/components/Sidebars/ProfileWithActions/ProfileWithActions.styled';
import { ProfileResume } from '~/components';
import { Person } from '~/typings/entities/person';

type ResponsiveMainActionsProps = {
  person: Person;
  loading?: boolean;
  avatarImage?: string;
  responsiveActions?: React.ReactNode;
};

export function ResponsiveMainActions({
  person,
  loading,
  avatarImage,
  responsiveActions,
}: ResponsiveMainActionsProps) {
  return (
    <MobileSidebar>
      <ProfileResume
        person={person}
        loading={loading}
        image={avatarImage}
        className="profile-resume"
        responsiveActions={responsiveActions}
      />
    </MobileSidebar>
  );
}
