import React, { Component } from 'react';
import { Dropdown, Icon, Menu, Popconfirm, Tooltip } from 'antd';

import { Action, ActionInfo, Subtitle } from './styled';

class Actions extends Component {
  state = {
    isShowMenu: false,
  };

  toggleShowMenu = () => {
    this.setState({
      isShowMenu: !this.state.isShowMenu,
    });
  };

  renderActionInfo = () => {
    const { title, subtitle, popConfirmProps, onPress, subActions } = this.props;
    const ActionContent = (
      <ActionInfo subActions={subActions}>
        {title}
        <Subtitle>{subtitle}</Subtitle>
      </ActionInfo>
    );
    if (popConfirmProps && popConfirmProps.title) {
      return (
        <Popconfirm
          title={popConfirmProps.title}
          onConfirm={onPress}
          okText={popConfirmProps.okText || 'Sim'}
          cancelText={popConfirmProps.cancelText || 'Não'}
          placement={popConfirmProps.placement || 'leftBottom'}
        >
          {ActionContent}
        </Popconfirm>
      );
    }
    return ActionContent;
  };

  renderSubActionItem = (item, index) => {
    const { name, onPress, disabled, tooltip } = item;
    const content = name || item;

    return (
      <Action key={index} onClick={onPress} disabled={disabled}>
        {tooltip ? (
          <Tooltip placement="right" title={tooltip}>
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </Action>
    );
  };

  renderSubActions = () => {
    const { subActions } = this.props;
    return <Menu>{subActions.filter((sa) => !!sa).map(this.renderSubActionItem)}</Menu>;
  };

  onVisibleChange = (visible) => !visible && this.toggleShowMenu();

  render() {
    const { onPress, onClick, subActions, placement, popConfirmProps } = this.props;

    const handleOnClick = (() => {
      if (subActions) {
        return this.toggleShowMenu;
      }
      if (!popConfirmProps) return onPress || onClick;
      return undefined;
    })();

    return (
      <Action {...this.props} onClick={handleOnClick}>
        {this.renderActionInfo()}
        {subActions && (
          <Dropdown
            overlay={this.renderSubActions()}
            visible={this.state.isShowMenu}
            trigger="click"
            onVisibleChange={this.onVisibleChange}
            placement={placement}
          >
            <Icon type="more" />
          </Dropdown>
        )}
      </Action>
    );
  }
}

export default Actions;
