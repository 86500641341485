import React, { PropsWithChildren, useCallback, useState } from 'react';
import typography from '~/ui/theme/typography';

import { Icon, Tooltip } from 'antd';
import useCopyToClipboard from '~/hooks/useCopyToClipboard';
import {
  Bold,
  CopyButton,
  TypographyElements,
  TypographyElementsKeys,
  TypographyProps as StyledTypographyProps,
} from './Typography.styled';

export interface TypographyProps extends PropsWithChildren<StyledTypographyProps> {
  element?: TypographyElementsKeys;
  copyable?: boolean;
  copyText?: string;
  editable?: boolean;
  className?: string;
}

export function Typography({
  children,
  copyable,
  copyText,
  type = 'description',
  element,
  editable,
  className,
  ...props
}: TypographyProps) {
  const typographyTypeOptions = typography.types[type];
  const TypographyElement = TypographyElements[element || typographyTypeOptions?.element];

  const [copied, setCopied] = useState(false);
  const copyToClipBoard = useCopyToClipboard(copyText || (children as string));

  const handleCopyText = useCallback(() => {
    if (!copyable) return;
    copyToClipBoard();
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }, [copyable]);

  const renderCopyButton = useCallback(() => {
    if (!copyable) return null;
    return (
      <Tooltip title={copied ? 'Copiado!' : 'Copiar'}>
        <CopyButton className="copy-button" onClick={handleCopyText} copied={copied}>
          {copied ? <Icon type="check" /> : <Icon type="copy" />}
        </CopyButton>
      </Tooltip>
    );
  }, [copyable, copied, handleCopyText]);

  return (
    <TypographyElement
      className={['typography', className].join(' ')}
      type={type}
      copied={copied}
      contentEditable={editable}
      suppressContentEditableWarning
      {...typographyTypeOptions}
      {...props}
    >
      {children}
      {renderCopyButton()}
    </TypographyElement>
  );
}

Typography.Bold = Bold;
