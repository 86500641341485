enum CommentFilters {
  CS = 'CS',
  CX = 'CX',
  MARKETING = 'Marketing',
  OPERATION = 'Operação',
  OMBUDSMAN = 'Ouvidoria',
  SALES = 'Sales',
  PRODUCT = 'Produto',
  TI = 'TI',
}
export default CommentFilters;
