import styled, { css } from 'styled-components';
import {
  Tag,
  Popover as PopoverComponent,
  Divider as DividerComponent,
  Radio as RadioInput,
} from 'antd';

export const ConfigContent = styled.div`
  border-radius: ${(props) => props.theme.cardBorderRadius};
  background-color: #fff;
  max-width: 355px;
  width: 100%;
  height: 78px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
`;

export const FilterConfigContainer = styled.div`
  margin-left: 2px;
  flex: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const ConfigTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${({ enabled, theme }) => {
    if (enabled) {
      return theme.accentColor;
    }

    return theme.grayMid;
  }};
  margin-bottom: 0;
`;

export const ConfigSetup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ConfigLabel = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: ${({ enabled, theme }) => {
    if (enabled) {
      return theme.accentColor;
    }

    return theme.grayMid;
  }};
  margin-bottom: 0;
`;

export const LabelTag = styled(Tag).attrs(() => ({
  color: '#FFF1E0',
  style: {
    color: '#B74A01',
    fontWeight: 500,
    margin: '0 0 0 5px',
  },
}))``;

export const LabelSuccessTag = styled(Tag).attrs((props) => ({
  color: props.theme.successColorLightest,
  style: {
    color: props.theme.successColorDarkest,
    fontWeight: 500,
    margin: '0 0 0 5px',
  },
}))``;

export const LabelDisabledTag = styled(Tag).attrs((props) => ({
  color: props.theme.gray,
  style: {
    color: props.theme.grayMid,
    fontWeight: 500,
    margin: '0 0 0 5px',
  },
}))``;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const Popover = styled(PopoverComponent).attrs((props) => ({
  placement: 'bottomLeft',
  overlayStyle: {
    width: '480px',
    borderRadius: props.theme.cardBorderRadius,
  },
}))``;

export const PopoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 10px;
`;

export const Title = styled.p`
  font-size: 22px;
  color: ${(props) => props.theme.grayExtraDark};
  margin-bottom: 0;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.grayDark};
  margin-bottom: 0;
  margin-left: 24px;
`;

export const Divider = styled(DividerComponent).attrs((props) => ({
  style: {
    margin: '5px 0 10px',
    height: '2px',
    background: props.theme.primaryColor,
    opacity: 0.3,
  },
}))``;

export const RadioDivider = styled(DividerComponent).attrs((props) => ({
  style: {
    margin: '9px 0px 9px',
    height: '2px',
    background: props.theme.grayExtraLight,
  },
}))``;

export const Radio = styled(RadioInput).attrs(() => ({
  syze: 'large',
}))`
  &.ant-radio-wrapper .ant-radio {
    margin-bottom: 2px;
  }

  &.ant-radio-wrapper {
    color: ${(props) => props.theme.accentColor};
  }

  &.ant-radio-wrapper-checked span {
    font-weight: 500;
  }
`;

export const FilterConfigLabel = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.accentColor};
  margin-bottom: 10px;
  line-height: 1.4;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
