import AsyncActionControl from 'react-redux-await-control/dist/AsyncActionControl';
import { useAwaitControl } from 'react-redux-await-control';
import { useEffect } from 'react';

const EXTENSIONS = {
  'application/pdf': 'pdf',
  'text/csv': 'csv',
  'application/zip': 'zip',
};

export function useHandleAsyncDocumentDownload(
  downloadAction: AsyncActionControl,
  fileName: string,
) {
  const downloadActionControl = useAwaitControl(downloadAction);

  const result = downloadActionControl.result();
  const success = downloadActionControl.isSuccessful();

  useEffect(() => {
    const name = fileName.split('.')?.[0];
    const extension = fileName.split('.')?.[1];
    const inferredExtension = EXTENSIONS?.[result?.headers?.['content-type']];

    if (success) {
      const file = new Blob([result.data], { type: result?.headers?.['content-type'] });
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.${extension || inferredExtension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }, [success]);

  useEffect(
    () => () => {
      downloadActionControl.clear();
    },
    [],
  );
}
