import Masker from 'vanilla-masker';

const moneyFormatter = {
  precision: 2,
  separator: ',',
  delimiter: '.',
};

export default function(value) {
  if (!value) return value;
  return Masker.toMoney(value, moneyFormatter);
}
