import styled from 'styled-components';
import { Tooltip, Typography } from '~/ui/components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

export const TimerContainer = styled(Tooltip).attrs({
  placement: 'bottom',
  content: 'Tempo para encerrar sua sessão',
  small: true,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 16px;

  svg {
    width: 22px;
    height: 22px;
    color: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }

  @media (max-width: 1024px) {
    background: ${themeColor('background.primary', { dark: 'background.secondary' })};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 4px 8px;
    position: fixed;
    border-radius: 12px 0 0 12px;
    bottom: 4px;
    z-index: -9;
    right: 0;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const Timer = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  weight: '600',
  element: 'span',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))`
  width: 40px;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const NotificationButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;
