/*eslint-disable */
const typography = {
  defaultFamily: 'libreFranklin',
  fonts: {
    libreFranklin: '"Libre Franklin", sans-serif',
    roboto: '"Roboto", monospace;',
    robotoMono: '"Roboto Mono", monospace;',
    montserrat: '"Montserrat", monospace;',
  },
  types: {
    headingDisplay: { element: 'h1', size: '96px', lineHeight: '96px', weight: '700' },
    headingXLarge: { element: 'h2', size: '64px', lineHeight: '72px', weight: '700' },
    headingLarge: { element: 'h3', size: '48px', lineHeight: '60px', weight: '700' },
    headingMedium: { element: 'h4', size: '32px', lineHeight: '44px', weight: '700' },
    headingSmall: { element: 'h5', size: '24px', lineHeight: '32px', weight: '700' },
    headingXSmall: { element: 'h6', size: '16px', lineHeight: '24px', weight: '700' },
    subtitleLarge: { element: 'h6', size: '32px', lineHeight: '48px', weight: '500' },
    subtitleSmall: { element: 'h6', size: '24px', lineHeight: '36px', weight: '500' },
    paragraphLarge: { element: 'p', size: '20px', lineHeight: '28px', weight: '400' },
    paragraphSmall: { element: 'p', size: '16px', lineHeight: '24px', weight: '400' },
    caption: { element: 'caption', size: '14px', lineHeight: '20px', weight: '400' },
    footnote: { element: 'p', size: '12px', lineHeight: '20px', weight: '400' },
    description: { element: 'p', size: '10px', lineHeight: '16px', weight: '400' },
    headingH5: { element: 'h5', size: '24px', lineHeight: '32px', weight: '400' },
    headingH6: { element: 'h6', size: '20px', lineHeight: '28px', weight: '400' },
    bodyLarge: { element: 'div', size: '18px', lineHeight: '28px', weight: '400' },
    bodyMedium: { element: 'div', size: '16px', lineHeight: '24px', weight: '400' },
    bodySmall: { element: 'div', size: '14px', lineHeight: '20px', weight: '400' },
    bodyXSmall: { element: 'div', size: '12px', lineHeight: '18px', weight: '400' },
  },
};


export type TypographyTypes = keyof typeof typography.types;

export default typography;
