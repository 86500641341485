import styled from 'styled-components';
import { Icon as AntdIcon } from 'antd';

export const ConfigLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.accentColor};
  margin-bottom: 0;
`;

export const LabelAlertTag = styled.div`
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'warning':
        return theme.alertColorLight;
      case 'error':
        return theme.dangerColorLightest;
      default:
        return theme.successColorLightest;
    }
  }};
  border-radius: ${(props) => props.theme.cardBorderRadius};
  width: 100%;
  max-width: 355px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
`;

export const Icon = styled(AntdIcon)`
  color: ${({ theme, status }) => {
    switch (status) {
      case 'warning':
        return theme.alertColorDark;
      case 'error':
        return theme.dangerColorDark;
      default:
        return theme.successColorDarkest;
    }
  }};
  font-size: 28px;
  padding-right: 12px;
`;
