import React, { Component } from 'react';

import RequestActionView from '../RequestActionView';

class ConfirmRequest extends Component {
  isPrivilegedUserToCancelContract = () => {
    const { loggedUser } = this.props;
    if (!loggedUser?.roles) return false;

    return true;
  };

  render() {
    const { isRequested, kycValidation } = this.props;
    if (!this.isPrivilegedUserToCancelContract()) return null;
    if (!isRequested || kycValidation) return null;

    return (
      <RequestActionView
        {...this.props}
        onPressRequest={this.props.onPressConfirm || this.props.onPressRequest}
      />
    );
  }
}

ConfirmRequest.defaultProps = {
  isRequestable: false,
};

export default ConfirmRequest;
