// @flow
import React from 'react';
import { useWizard } from 'react-use-wizard';
import { useAwaitControl } from 'react-redux-await-control';
import { Col, Row } from 'antd';

import masked from '~/common/masked';
import { simulateRefin } from '~/store/contracts/actions';
import Loading from './Loading';
import {
  CustomFormButton,
  Label,
  ResultContent,
  StepFooter,
  StepTitle,
  Value,
} from './styled';

type ResultProps = {
  id: number,
};

const { toMoney, toPercentage } = masked;

export default function Result({ id }: ResultProps) {
  const { previousStep, nextStep } = useWizard();
  const simulateRefinControl = useAwaitControl(simulateRefin);

  const data = simulateRefinControl.result(id) || {};

  if (simulateRefinControl.isRunning(id)) {
    return <Loading />;
  }

  return (
    <div>
      <StepTitle>O contrato terá os seguintes valores:</StepTitle>
      <ResultContent>
        <Row>
          <Col span={24}>
            <Col span={6} offset={1}>
              <Label>valor de parcela</Label>
              <Label>qtd. de parcelas</Label>
            </Col>
            <Col span={6}>
              <Value>{toMoney(data.installmentValue)}</Value>
              <Value>{`x${data.numberOfInstallments}`}</Value>
            </Col>
            <Col span={3} offset={1}>
              <Label>troco</Label>
              <Label>taxa</Label>
            </Col>
            <Col span={7}>
              <Value negative={data.valueForDeposit < 0}>
                {toMoney(data.valueForDeposit)}
              </Value>
              <Value>
                {`${toPercentage(
                  data.nominalRatePerMonth / 100,
                )}  a.m.`}
              </Value>
            </Col>
          </Col>
        </Row>
      </ResultContent>
      <StepFooter>
        <Col>
          <CustomFormButton
            onClick={previousStep}
            type="secondary"
            size="large"
          >
            Simular novamente
          </CustomFormButton>
          <CustomFormButton
            onClick={nextStep}
            type="primary"
            size="large"
            disabled={data.valueForDeposit <= 0}
          >
            Alterar contrato
          </CustomFormButton>
        </Col>
      </StepFooter>
    </div>
  );
}
