import React from 'react';

import LogoImg from '~/ui/assets/imgs/Tponto.png';
import { LoadingWrapper } from './Loading.styled';

export function Loading() {
  return (
    <LoadingWrapper className="loading-wrapper">
      <img src={LogoImg} alt="loading" />
    </LoadingWrapper>
  );
}
