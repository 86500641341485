import React, { useCallback, useEffect } from 'react';
import { TableColumns } from '~/ui/components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import { listStubbornReturnsParati } from '~/store/proposals/actions';

import ReactJson from 'react-json-view';
import { useModal } from '~/hooks/useModal';
import { ParatiStubborn } from '~/typings/entities/Proposals';
import { ActionButton, SmallTable } from '../../Returns.styled';

export type StubbornTableProps = {
  contractId: string;
  returnId: string;
};

export function StubbornTable({ contractId, returnId }: StubbornTableProps) {
  const { openModal } = useModal();
  const listStubbornReturnsControl = useAwaitControl(listStubbornReturnsParati);

  const loading = listStubbornReturnsControl.isRunning(`${contractId}_${returnId}`);

  const returns = listStubbornReturnsControl.result(`${contractId}_${returnId}`);

  const renderRawMessage = useCallback((json, parsed = false) => {
    let jsonObj = { message: 'No response' };

    if (parsed) {
      jsonObj = json;
    } else {
      try {
        jsonObj = JSON.parse(json);
      } catch (e) {}
    }

    openModal(
      <ReactJson
        style={{ maxHeight: 600, overflow: 'scroll' }}
        displayDataTypes={false}
        src={jsonObj}
      />,
      {
        title: 'Resposta',
        width: 'auto',
        closable: true,
      },
    );
  }, []);

  const columns: TableColumns<ParatiStubborn> = [
    {
      header: () => 'DATA',
      accessorKey: 'eventDate',
      maxSize: 92,
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
    },
    {
      header: () => 'DATA RESP.',
      accessorKey: 'dateReply',
      maxSize: 92,
      cell: (info) => moment(info.getValue()).format('DD/MM/YYYY [às] HH:mm:ss'),
    },
    { header: () => 'MENSAGEM', accessorKey: 'message' },
    {
      header: () => 'STATUS',
      accessorKey: 'status',
      cell: (info) => info.getValue() || '-',
      cellAlign: 'center',
    },
    { header: () => 'STATUS PCRED', accessorKey: 'statusPcred', cellAlign: 'center' },
    { header: () => 'AÇÃO', accessorKey: 'action' },
    { header: () => 'COD DATAPREV', accessorKey: 'dataprevCode', cellAlign: 'center' },
    { header: () => 'DESC DATAPREV', accessorKey: 'dataprevDesc' },
    {
      id: 'resp',
      header: () => 'RESPOSTA',
      cellAlign: 'center',
      headerAlign: 'center',
      cell: ({ row }) => (
        <ActionButton icon="FileSearch" onClick={() => renderRawMessage(row.original, true)} />
      ),
    },
  ];

  useEffect(() => {
    if (contractId && returnId) {
      listStubbornReturnsControl.start(
        { contractId, returnId },
        { actionId: `${contractId}_${returnId}` },
      );
    }
  }, [contractId, returnId]);

  return (
    <SmallTable
      noItemsMessage="Nenhum retorno da teimosinha encontrado."
      loading={loading}
      data={returns}
      columns={columns}
    />
  );
}
