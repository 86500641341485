import React, { useMemo } from 'react';

import { Button } from 'antd';

import Images from '~/assets/images';

import { Container } from './styled';

type Panel = 'person' | 'contract';
type Kind = 'pendency' | 'person_comment' | 'contract_comment';

export const PanelTypes = {
  PERSON: 'person',
  CONTRACT: 'contract',
};

export const PanelKindTypes: { [key: string]: Kind } = {
  PENDENCY: 'pendency',
  CONTRACT_COMMENT: 'contract_comment',
  PERSON_COMMENT: 'person_comment',
};

type EmptyPanelProps = {
  btnAction?: () => void;
  panel?: Panel;
  kind: Kind;
};

type PanelInfo = {
  title: (...args: any) => string;
  description: (...args: any) => string;
  btnText?: string;
  image: any;
};

const EmptyPanelKinds: { [key: string]: PanelInfo } = {
  contract_comment: {
    title: () => 'Ops, ainda sem comentários ):',
    description: () => `Fale um pouco desse contrato escrevendo um novo comentário.`,
    btnText: 'Adicionar comentários',
    image: Images.noComments,
  },
  person_comment: {
    title: () => 'Ops, sem comentários ):',
    description: () =>
      `A funcionalidade de comentários foi descontinuada, apenas os comentários antigos podem ser visualizados.`,
    image: Images.noComments,
  },
  pendency: {
    title: () => 'Nenhuma pendência aberta',
    description: () =>
      'No momento seu cliente se encontra sem pendências abertas. Volte depois pra conferir.',
    image: Images.noPendencies,
  },
};

export default function EmptyPanel({
  btnAction,
  kind,
  panel = PanelTypes.PERSON as Panel,
}: EmptyPanelProps) {
  const panelInfo = useMemo(() => {
    const { title: getTitle, description: getDescription, btnText, image } = EmptyPanelKinds[kind];

    return {
      title: getTitle(panel),
      description: getDescription(panel),
      btnText,
      image,
    };
  }, [kind, panel]);

  return (
    <Container>
      <img src={panelInfo.image} alt="empty" />
      <span>{panelInfo.title}</span>
      <span>{panelInfo.description}</span>
      {panelInfo.btnText && (
        <Button type="primary" onClick={btnAction}>
          {panelInfo.btnText}
        </Button>
      )}
    </Container>
  );
}
