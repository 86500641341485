import React, { useEffect } from 'react';
import { Button, Col, Icon } from 'antd';

import useTableFilterContext from './useTableFilterContext';

const styles = {
  button: { minWidth: '100px', margin: '0 10px 10px' },
  buttonIcon: { margin: '0 5px 0 -21px' },
};

export default function FilterButton() {
  const { submit: onSubmit } = useTableFilterContext();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        onSubmit();
      }
    };

    document.addEventListener('keypress', handleKeyPress);
    return () => document.removeEventListener('keypress', handleKeyPress);
  }, [onSubmit]);

  return (
    <Col xs={{ span: 3 }}>
      <Button
        block
        style={styles.button}
        type="primary"
        onClick={onSubmit}
      >
        <Icon type="search" style={styles.buttonIcon} />
        Filtrar
      </Button>
    </Col>
  );
}
