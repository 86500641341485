/* @flow */

export const UNDO_REFUSAL_REQUEST = 'UNDO_REFUSAL_REQUEST';
export const UNDO_REFUSAL_SUCCESS = 'UNDO_REFUSAL_SUCCESS';
export const UNDO_REFUSAL_FAILURE = 'UNDO_REFUSAL_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: UNDO_REFUSAL_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: UNDO_REFUSAL_FAILURE,
      payload: resource,
    });
  };
}

export default function undoRequestRefusal(contractId, password) {
  return dispatch => {
    dispatch({
      type: UNDO_REFUSAL_REQUEST,
      payload: {
        contractId,
        password,
      },
    });
  };
}
