import React, { ReactElement, ReactNode } from 'react';

import { Icon } from 'antd';
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel';

import { Collapse, CollapseIconWrapper } from './styled';

const { Panel: AntdPanel } = Collapse;

type PanelProps = Omit<CollapsePanelProps, 'header'> & {
  header: string;
  children: ReactNode | any;
}

type CollapseProps = {
  children: ReactElement<PanelProps>[] | ReactElement<PanelProps>;
  defaultActiveKey: Array<string | number>;
}

export function Panel({ header, ...props }: PanelProps) {
  return (
    <AntdPanel {...props} header={<span>{header}</span>} />
  );
}

function CollapseIcon(panelProps) {
  return (
    <CollapseIconWrapper>
      <Icon type="up" rotate={panelProps.isActive ? 180 : undefined} />
    </CollapseIconWrapper>
  );
}

export default function CollapsePanel({ defaultActiveKey, children }: CollapseProps) {
  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      defaultActiveKey={defaultActiveKey}
      expandIcon={CollapseIcon}
    >
      {children}
    </Collapse>
  );
}

CollapsePanel.Panel = Panel;
