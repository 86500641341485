import React, { useCallback, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment/moment';

import { prepareContract } from '~/store/opportunities/actions';
import { getPersonDetail } from '~/store/people/actions';
import { OpportunityListItem, SuggestedProposalReturn } from '~/typings/entities/Opportunity';
import { themeToggleColor } from '~/common/utils/theme';
import toMoney from '~/common/masked/toMoney';
import { useDrawer } from '~/hooks/useDrawer';
import {
  Alert,
  Button,
  Divider,
  Flex,
  Shimmer,
  Tag,
  Typography,
  withStepperProps,
} from '~/ui/components';
import { uppercaseFirst } from '~/common';
import toPercentage from '~/common/masked/toPercentage';
import formatName from '~/common/formatName';
import { DownloadNodeModal } from '~/components';
import { useModal } from '~/hooks/useModal';
import { DetailsResume } from '~/screens/Person/components/Drawers/Opportunity/REFIN/Resume/DetailsResume';
import { getDeeplink } from '~/store/formalization/actions';
import { OpportunityBody, OpportunityContainer, OpportunityFooter } from '../../Opportunity.styled';

export type DetailsProps = {
  opportunity: OpportunityListItem;
  selectedProposal?: SuggestedProposalReturn;
};

export const Details = withStepperProps<DetailsProps>(
  ({ stepper, selectedProposal, opportunity }) => {
    const { toStepById } = stepper;
    const { setConfig } = useDrawer();
    const { openModal } = useModal();
    const theme = useTheme();
    const buttonColor = themeToggleColor(theme, 'neutral.primary', { dark: 'brand.secondary' });

    const getDeeplinkControl = useAwaitControl(getDeeplink);

    const prepareContractControl = useAwaitControl(prepareContract);
    const prepareLoading = prepareContractControl.isRunning();
    const prepareResult = prepareContractControl.result();
    const prepareFailure = prepareContractControl.hasFailure();

    const personControl = useAwaitControl(getPersonDetail);
    const person = personControl.result();

    const loading = prepareLoading;

    const formatDate = (date: string, format = 'DD/MM/YYYY') =>
      date ? moment(date).format(format) : '';

    const handleNextStep = () => {
      toStepById('success');
    };

    const handleDownload = useCallback(() => {
      const name = formatName(person?.name, 2);
      const date = moment().format('DD-MM-YYYY');
      const fileName = `Oportunidade Refinanciamento - ${name} - ${date}`;

      openModal(
        <DownloadNodeModal fileName={fileName}>
          <DetailsResume contract={prepareResult} />
        </DownloadNodeModal>,
        {
          closable: true,
          maskClosable: false,
          noPadding: true,
          width: 460,
        },
      );
    }, [prepareResult]);

    useEffect(() => {
      if (prepareFailure) {
        toStepById('error');
      }
    }, [prepareFailure]);

    useEffect(() => {
      setConfig('opportunity-details', {
        backButton: true,
        onBackClick: stepper.prevStep,
        extraAction: (
          <Button
            variant="text"
            size="sm"
            iconOnly
            icon="Download"
            title="Baixar resumo"
            disabled={loading}
            onClick={handleDownload}
          />
        ),
      });
    }, [prepareResult, loading]);

    useEffect(() => {
      const { financialInstitutionDTO: financialInstitution, ...proposal } = selectedProposal;
      const proposalPayload = {
        ...proposal,
        contractValue: proposal?.valueForDeposit,
        financialInstitution,
        agreementId: person?.agreementId,
        enrollmentId: opportunity?.enrollmentId,
        loanType: opportunity?.loanType,
      };

      if (!prepareResult) {
        getDeeplinkControl.clear();
        prepareContractControl.start(proposalPayload);
      }
    }, [selectedProposal]);

    return (
      <OpportunityContainer loading={loading ? 1 : 0}>
        <OpportunityBody>
          <Flex direction="column" gap={16} mt={24}>
            <Alert status="error">
              Este valor é apenas uma estimativa. Ele poderá ser recalculado no momento do pagamento
              e poderá variar de acordo com a margem disponível.
            </Alert>

            <Flex direction="column" gap={16} mt={8} width="100%">
              <>
                <Flex direction="column" gap={16} width="100%" mv={8}>
                  <Tag
                    rounded
                    small
                    textColor={theme.colors.info.primaryAlt}
                    bgColor={theme.colors.info.secondary}
                  >
                    Refinanciamento
                  </Tag>

                  {/* <Collapsible defaultActiveKey="1"> */}
                  {/*   <Collapsible.Panel */}
                  {/*     key="1" */}
                  {/*     header={ */}
                  <Typography type="bodyLarge" weight={700}>
                    Detalhes do novo contrato
                  </Typography>
                  {/* } */}
                  {/* > */}
                  <Flex direction="column" gap={32} width="100%">
                    <Flex direction="column" gap={2} width="100%">
                      <Typography type="bodyMedium">Valor que o cliente vai receber</Typography>

                      {loading ? (
                        <Shimmer width={143} height={32} />
                      ) : (
                        <Typography type="headingH5" weight={600}>
                          {toMoney(prepareResult?.contractValue || 0)}
                        </Typography>
                      )}
                    </Flex>

                    <Alert status="info">
                      A data de previsão do pagamento pode variar de acordo com as decisões do
                      governo.
                    </Alert>

                    <Flex direction="column" gap={8} width="100%">
                      <Typography type="bodyMedium" weight={600}>
                        Parcelas do contrato
                      </Typography>

                      {loading ? (
                        <Shimmer width={92} height={20} />
                      ) : (
                        <Typography type="bodySmall">
                          {prepareResult?.numberOfInstallments}x{' '}
                          {toMoney(prepareResult?.installmentValue)}
                        </Typography>
                      )}
                    </Flex>

                    <Flex direction="column" gap={8} width="100%">
                      <Typography type="bodyMedium" weight={600}>
                        Previsão de Pagamento
                      </Typography>

                      {loading ? (
                        <Shimmer width={77} height={20} />
                      ) : (
                        <Typography type="bodySmall">
                          Entre {formatDate(prepareResult?.nearEstimatedEndDate) || '-'} e{' '}
                          {formatDate(prepareResult?.farEstimatedEndDate) || '-'}
                        </Typography>
                      )}
                    </Flex>

                    <Flex direction="column" gap={8} width="100%">
                      <Typography type="bodyMedium" weight={600}>
                        Previsão de início e fim dos descontos
                      </Typography>

                      {loading ? (
                        <Shimmer width={188} height={20} />
                      ) : (
                        <Typography type="bodySmall">
                          {uppercaseFirst(formatDate(prepareResult?.firstPaymentDate, 'MMMM/YYYY'))}{' '}
                          até{' '}
                          {uppercaseFirst(formatDate(prepareResult?.lastPaymentDate, 'MMMM/YYYY'))}
                        </Typography>
                      )}
                    </Flex>

                    <Flex direction="column" gap={8} width="100%">
                      <Typography type="bodyMedium" weight={600}>
                        IOF
                      </Typography>

                      {loading ? (
                        <Shimmer width={72} height={20} />
                      ) : (
                        <Typography type="bodySmall">{toMoney(prepareResult?.iofValue)}</Typography>
                      )}
                    </Flex>

                    <Flex direction="column" gap={8} width="100%">
                      <Typography type="bodyMedium" weight={600}>
                        Custo efetivo total (CET)
                      </Typography>

                      {loading ? (
                        <Shimmer width={152} height={20} />
                      ) : (
                        <Typography type="bodySmall">
                          {toPercentage(prepareResult?.effectiveRate)} a.m. |{' '}
                          {toPercentage(prepareResult?.totalEffectiveCost)} a.a.
                        </Typography>
                      )}
                    </Flex>
                  </Flex>
                  {/* </Collapsible.Panel> */}
                  {/* </Collapsible> */}
                </Flex>

                <Divider />
              </>
            </Flex>
          </Flex>
        </OpportunityBody>

        <OpportunityFooter>
          <Button
            onClick={handleNextStep}
            customColor={buttonColor}
            loading={loading}
            fullWidth
            rounded
          >
            Avançar
          </Button>
        </OpportunityFooter>
      </OpportunityContainer>
    );
  },
);
