import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useTheme } from 'styled-components';

import SuccessImage from '~/ui/assets/imgs/success.png';
import FailureImage from '~/ui/assets/imgs/failure.png';

import {
  revokeAgreementPassword,
  sendRevokeAgreementPasswordToken,
  validateRevokeAgreementPasswordToken,
} from '~/store/people/actions';
import { themeToggleColor } from '~/common/utils/theme';
import { Button, Typography, withStepperProps } from '~/ui/components';
import { useModal } from '~/hooks/useModal';

import { Content, InfoRow } from '../../AgreementPasswordActions.styled';

export const ResultStep = withStepperProps(({ stepper }) => {
  const { setConfig, closeModal } = useModal();
  const theme = useTheme();
  const revokePasswordControl = useAwaitControl(revokeAgreementPassword);
  const sendTokenControl = useAwaitControl(sendRevokeAgreementPasswordToken);
  const validateTokenControl = useAwaitControl(validateRevokeAgreementPasswordToken);

  const revokePasswordSuccess = revokePasswordControl.isSuccessful();
  const revokePasswordFailure = revokePasswordControl.hasFailure();

  const success = useMemo(
    () => !revokePasswordFailure && !!revokePasswordSuccess,
    [revokePasswordFailure, revokePasswordSuccess],
  );

  const textColor = useMemo(
    () => themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
    [theme],
  );

  const handleTryAgain = () => {
    sendTokenControl.clear();
    validateTokenControl.clear();
    revokePasswordControl.clear();
    stepper?.prevStep();
  };

  const handleFinish = () => {
    closeModal('exclude-agreement-password-modal');
  };

  useEffect(() => {
    setConfig('exclude-agreement-password-modal', {
      title: success ? 'Sucesso na exclusão' : 'Falha na exclusão',
    });
  }, []);

  return (
    <Content center>
      <img width={162} src={success ? SuccessImage : FailureImage} alt="Result" />

      {success && (
        <>
          <InfoRow center>
            <Typography type="subtitleSmall" color={textColor}>
              Exclusão concluída
            </Typography>

            <Typography type="caption" element="p" center color={textColor}>
              Conseguimos excluir a senha do Meu INSS do seu cliente e a confirmação foi recebida
              por meio do e-mail do seu cliente.
            </Typography>
          </InfoRow>

          <Button
            customColor={themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' })}
            onClick={handleFinish}
            variant="outline"
            fullWidth
            rounded
          >
            Fechar
          </Button>
        </>
      )}

      {!success && (
        <>
          <InfoRow center>
            <Typography type="subtitleSmall" color={textColor}>
              Falha na exclusão
            </Typography>

            <Typography type="caption" element="p" center color={textColor}>
              Lamentamos, mas ocorreu um problema. Por favor, tente realizar o processo novamente.
              Se persistir, entre em contato com o time responsável.
            </Typography>
          </InfoRow>

          <Button
            customColor={themeToggleColor(theme, 'element.primary', { dark: 'brand.secondaryAlt' })}
            onClick={handleTryAgain}
            fullWidth
            rounded
          >
            Tentar novamente
          </Button>
        </>
      )}
    </Content>
  );
});
