import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  > img {
    width: 245px;
  }

  > span {
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  }

  > span:nth-of-type(1) {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;

    margin-top: 20px;
  }

  > span:nth-of-type(2) {
    width: 370px;
    margin-bottom: 18px;

    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }

  > button {
    margin-bottom: 20px;
  }
`;
