import React, { useCallback, useState } from 'react';

import { CaretDown, CaretRight } from '~/ui/assets/icons';
import { Label, Button, Wrapper, Item, SubItem } from './Collapse.styled';
import { Tooltip } from '..';

type ItemType = {
  label: string;
  id: string;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: string;
  children?: ItemType[];
};

export interface CollapseProps {
  items: ItemType[];
  maxHeight?: number;
}

export function Collapse({ items, maxHeight }: CollapseProps) {
  const [openItems, setOpenItems] = useState([]);

  const handleOnClick = useCallback(
    (item) => {
      const currentOpenedItems = [...openItems];

      if (currentOpenedItems.includes(item)) {
        currentOpenedItems.splice(currentOpenedItems.indexOf(item), 1);
      } else {
        currentOpenedItems.push(item);
      }

      setOpenItems(currentOpenedItems);
    },
    [openItems],
  );

  const renderSubItems = useCallback(
    (item) => (
      <>
        {item.children.map((child) => (
          <SubItem
            data-testid={child.id}
            key={child.id}
            onClick={child.onClick}
            disabled={child.disabled}
          >
            <Button variant="text">
              {child.tooltip && (
                <Tooltip content={child.tooltip}>
                  <Label>{child.label}</Label>
                </Tooltip>
              )}

              {!child.tooltip && <Label>{child.label}</Label>}
            </Button>
          </SubItem>
        ))}
      </>
    ),
    [],
  );

  return (
    <Wrapper data-testid="collapse-wrapper" maxHeight={maxHeight}>
      {items.map((item) => (
        <div key={item.id}>
          {item.children && (
            <>
              <Item
                data-testid={item.id}
                onClick={() => handleOnClick(item.id)}
                disabled={item.disabled}
              >
                <Button variant="text" onClick={() => handleOnClick(item.id)}>
                  {item.tooltip && (
                    <Tooltip content={item.tooltip}>
                      <Label>{item.label}</Label>
                      {item.children && (
                        <>{openItems.includes(item.id) ? <CaretDown /> : <CaretRight />}</>
                      )}
                    </Tooltip>
                  )}

                  {!item.tooltip && (
                    <>
                      <Label>{item.label}</Label>
                      {item.children && (
                        <>{openItems.includes(item.id) ? <CaretDown /> : <CaretRight />}</>
                      )}
                    </>
                  )}
                </Button>
              </Item>

              {item.children && openItems.includes(item.id) && renderSubItems(item)}
            </>
          )}

          {!item.children && (
            <Item data-testid={item.id} onClick={item.onClick} disabled={item.disabled}>
              <Button variant="text">
                {item.tooltip && (
                  <Tooltip content={item.tooltip}>
                    <Label>{item.label}</Label>
                  </Tooltip>
                )}

                {!item.tooltip && <Label>{item.label}</Label>}
              </Button>
            </Item>
          )}
        </div>
      ))}
    </Wrapper>
  );
}
