import React, { useCallback, useEffect } from 'react';
import { Input, Typography } from '~/ui/components';

import { useTheme } from '~/hooks/useTheme';
import useTableFilterContext from '../useTableFilterContext';
import { FieldProps, TextType } from '../Props';

import FieldWrapper from './FieldWrapper';

export default function TextField({
  definition: { title, placeholder, textValueKey, defaultValue = '', normalize = (value) => value },
  index,
}: FieldProps<TextType>) {
  const { changeValue, getValue } = useTableFilterContext();
  const { theme } = useTheme();

  const value = getValue(textValueKey);

  const onChange = useCallback((value) => {
    changeValue(textValueKey, normalize(value));
  }, []);

  useEffect(() => {
    changeValue(textValueKey, normalize(defaultValue));
  }, []);

  return (
    <FieldWrapper index={index}>
      <Typography type="headingXSmall" weight={500} color={theme.colors.element.secondary}>
        {title}
      </Typography>
      <Input placeholder={placeholder} value={value} onChange={onChange} />
    </FieldWrapper>
  );
}
