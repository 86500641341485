import styled from 'styled-components';
import { Icon, Input } from 'antd';

export const IconStyled = styled(Icon)`
  padding: 5px;
  border-radius: 100%;
  :hover {
    background: ${props => props.theme.primaryColorLightest};
    color: ${props => props.theme.primaryColor};
  }
`;

export const InputStyled = styled(Input)`
  border-radius: 4px 4px 0 0 !important;
  margin-top: -5px !important;
  border: none !important;
  border-bottom: 1px solid ${props => props.theme.grayContainer} !important;
`;

export const ActionItem = styled.div`
  background-color: #fff;
  padding: 8px 12px;
  width: 100%;
  cursor: pointer;
  transition: 0.5s;
  :hover {
    background-color: ${props => props.theme.primaryColorLightHover};
    color: ${props => props.theme.primaryColor};
  }
`;
