export default {
  example: false,
  allowRefreshSession: true,
  expirationAlertLimit: 10,
  ticketLimitHours: 48,
  allowDarkThemeButton: false,
  enableResetAccount: false,
  hiddeSubJustification: true,
  showCommentsInPerson: false,
  contractSearchColumns: '',
  logsMenu: false,
  assessmentAgents: null,
  offHoursText: null,
  onHoursText: [
    'Segunda a Sexta - 7hs às 19:55hs',
    'Sábado - 8hs às 17:45hs',
    'Feriados - 8hs às 17:45hs',
  ],
};
