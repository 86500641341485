/* @flow */

export const FETCH_CONTRACT_REFUSAL_REASONS_REQUEST =
  'FETCH_CONTRACT_REFUSAL_REASONS_REQUEST';
export const FETCH_CONTRACT_REFUSAL_REASONS_SUCCESS =
  'FETCH_CONTRACT_REFUSAL_REASONS_SUCCESS';
export const FETCH_CONTRACT_REFUSAL_REASONS_FAILURE =
  'FETCH_CONTRACT_REFUSAL_REASONS_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: FETCH_CONTRACT_REFUSAL_REASONS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FETCH_CONTRACT_REFUSAL_REASONS_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(params = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_CONTRACT_REFUSAL_REASONS_REQUEST,
      payload: {
        params,
      },
    });
  };
}
