import styled, { css } from 'styled-components';
import { Button, Typography } from '~/ui/components';
import { themeColor, themeToggleColor } from '~/common/utils/theme';

export const InvoiceDetailsWrapper = styled.div`
  padding: 8px 0 8px 8px;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
`;

export const InvoiceHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  padding: 12px 0 24px 0;
  gap: 8px;

  .close-icon {
    cursor: pointer;
    fill: ${themeColor('brand.primary', { dark: 'brand.primaryAlt' })};
  }
`;

export const InvoiceTitle = styled(Typography).attrs(() => ({
  type: 'paragraphLarge',
  element: 'h4',
  weight: 600,
}))`
  flex: 1;
`;

export const InvoiceWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
  padding-bottom: 22px;

  & > div {
    p:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  .alert-copy {
    background: ${themeColor('background.secondary', { dark: 'background.primary' })};

    * {
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    }
  }
`;

export const InvoiceValue = styled(Typography).attrs({
  type: 'subtitleSmall',
  element: 'h3',
  weight: 600,
})``;

export const ClosingDate = styled(Typography).attrs({
  type: 'paragraphSmall',
  element: 'p',
})`
  color: ${({ theme }) => theme.colors.element.secondary};

  b {
    font-weight: 500;
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  }
`;

export const DueDate = styled(Typography).attrs({
  type: 'paragraphSmall',
  element: 'p',
})`
  color: ${({ theme }) => theme.colors.element.secondary};

  b {
    font-weight: 500;
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  }
`;

export const CircleIcon = styled.div`
  width: 24px;
  height: 24px;
  border: 0.5px solid ${({ theme }) => theme.colors.element.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg {
    color: ${({ theme }) => theme.colors.element.primary};
  }
`;

export const InvoiceBody = styled.div`
  padding: 20px 0;
`;

export const InvoiceHistoryContainer = styled.div``;

export const Divider = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.divider.primary};
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background: ${({ theme }) => theme.colors.divider.primary};
  height: 1px;
`;

export const CopyableTextWrapper = styled.div`
  background: ${themeColor('background.primary', { dark: 'background.primary' })};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 8px 12px 6px 8px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  overflow-wrap: normal;
  overflow: hidden;

  *::-webkit-scrollbar {
    display: none;
  }
`;

export const CopyableText = styled(Typography).attrs({
  type: 'paragraphSmall',
  element: 'div',
})`
  overflow-x: scroll;
  text-align: center;
  width: calc(100% - 16px);
`;

type CopyButtonProps = {
  copied?: boolean;
};

export const CopyButton = styled(Button).attrs(({ theme, copied }) => ({
  customColor: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  rounded: true,
  fullWidth: true,
  variant: copied ? 'contained' : 'outline',
  icon: copied ? 'Check' : 'CopyText',
}))<CopyButtonProps>`
  gap: 12px;

  ${({ copied }) =>
    copied &&
    css`
      .typography {
        color: ${themeColor('element.invert')};
      }

      svg {
        color: ${themeColor('element.invert')};
        width: 17px;
      }
    `}
`;

export const NoContentMessage = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'p',
}))`
  text-align: center;
  margin-top: 24px;
`;
