import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { Form, Input, Button } from '~/ui/components';
import { Card, Spinner, EnvironmentInfo } from '~/components';

import { login, getSessionToken } from '~/store/user/actions';
import routes from '~/screens/routes';
import screenRegister from '~/hoc/screenRegister';
import { isKeycloakAuthMethod } from '~/common/utils/keycloak';
import BackofficeLogo from '~/ui/assets/imgs/backoffice.svg';
import LogoSmall from '~/ui/assets/imgs/Tponto.svg';
import LogoSmallOutline from '~/ui/assets/imgs/TpontoOutline.svg';
import { themeToggleValue } from '~/common/utils/theme';
import { useTheme } from 'styled-components';
import { Container, Content, LogoContainer } from './Login.styled';

type LoginContainerProps = {
  location: any;
  history: any;
};

function Login({ location, history }: LoginContainerProps) {
  const theme = useTheme();
  const loginControl = useAwaitControl(login);
  const getSessionTokenControl = useAwaitControl(getSessionToken);

  const loginSuccess = loginControl.isSuccessful();
  const getSessionTokenSuccess = getSessionTokenControl.isSuccessful();

  const loginLoading = loginControl.isRunning();
  const getSessionTokenLoading = getSessionTokenControl.isRunning();

  const loading = useMemo(
    () => loginLoading || getSessionTokenLoading,
    [loginLoading, getSessionTokenLoading],
  );

  const authToken = localStorage.getItem('authenticationToken');

  const Logo: any = themeToggleValue(theme, LogoSmall, { dark: LogoSmallOutline });

  const handleOnSubmit = ({ username, password }) => {
    loginControl.start({ username, password });
  };

  const backToFrom = useCallback(() => {
    const { from, search } = location.state || {};
    const fullPath = from + (search || '') || routes.manager.getPath('Contracts') + (search || '');
    history.replace(fullPath);
  }, [location, history]);

  useEffect(() => {
    if (loginSuccess || getSessionTokenSuccess) {
      backToFrom();
    }
  }, [loginSuccess, getSessionTokenSuccess]);

  if (isKeycloakAuthMethod || (authToken && authToken !== 'null')) {
    history.replace('/');
    return <></>;
  }

  return (
    <>
      <Container>
        <Spinner spinning={loading} />
        <Card full={false}>
          <Content>
            <LogoContainer>
              <Logo width={34} height={34} />
              <BackofficeLogo width={112} height={25} />
            </LogoContainer>

            <Form
              onSubmit={handleOnSubmit}
              width={350}
              inputs={[
                {
                  id: 'username',
                  label: 'Usuário',
                  input: <Input placeholder="Usuário" autoFocus />,
                },
                {
                  id: 'password',
                  label: 'Senha',
                  input: <Input password placeholder="Senha" />,
                },
              ]}
            >
              <Button title="Entrar" size="sm" rounded />
            </Form>
          </Content>
        </Card>
      </Container>

      <EnvironmentInfo removeButton />
    </>
  );
}

export default screenRegister({
  screenName: 'Login',
  headerTitle: 'Login',
})(Login);
