import React, { useMemo } from 'react';
import { ListWithArrow } from '~/components/ProfileResume/ProfileResume.styled';
import { ArrowRightCircle, Clock } from '~/ui/assets/icons';
import { Popover } from '~/ui/components';
import { useTheme } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';

type UpdatesPopoverProps = {
  lastAccess: string;
};

export function UpdatesPopover({ lastAccess }: UpdatesPopoverProps) {
  const theme = useTheme();

  const iconColor = useMemo(
    () => themeToggleColor(theme, 'brand.primary', { dark: 'brand.primaryAlt' }),
    [theme],
  );

  return (
    <Popover
      placement="right"
      title="Últimas atualizações"
      trigger="click"
      width={390}
      content={
        <ListWithArrow>
          <li>
            <ArrowRightCircle width={16} height={16} />
            Último acesso ao app/web: {lastAccess}
          </li>
        </ListWithArrow>
      }
    >
      <Clock className="action-icon" width={20} height={20} fill={iconColor} />
    </Popover>
  );
}
