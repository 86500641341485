import styled from 'styled-components';

import { Typography } from '~/ui/components';

export const PopoverWrapper = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: theme.colors.gray['900'],
}))`
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;

  .action-icon {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
