import { createAsyncAction } from 'react-redux-await-control';
import { message } from '~/common';

import { handleErrors } from '~/common/utils/errorHandler';
import { gateway } from '~/services';

export const getCards = createAsyncAction('GET_WALLET_CARDS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ status, userId }) =>
      gateway.get('/backoffice/wallet/cards', {
        params: { status },
        headers: {
          'x-user-id': userId,
        },
      }),
    errorParser: () => [],
    responseParser: ({ data }) => data,
    onRequestError: () => {},
  },
});

export const getTransactions = createAsyncAction('GET_WALLET_TRANSACTIONS', {
  saveResult: true,
  initialValue: [],
  context: {
    request: ({ userId, ...params }) =>
      gateway.get('/backoffice/wallet/transactions', {
        params,
        headers: {
          'x-user-id': userId,
          'x-api-version': 'v2',
        },
      }),
    errorParser: () => [],
    responseParser: ({ data: { content, ...pagination } }) => ({
      transactions: content,
      pagination,
    }),
    onRequestError: (err) =>
      handleErrors(err, { defaultMessage: 'Não foi possível obter as transações.' }),
  },
});

export const getTransactionHistory = createAsyncAction('GET_CLIX_TRANSACTION_HISTORY', {
  saveResult: true,
  context: {
    onRequestError: () => message.error('Erro ao tentar carregar histórico das transações'),
    responseParser: ({ data }) => data,
    request: ({ reference }) =>
      gateway.get(`/backoffice/wallet/operation-step-logs`, { params: { reference } }),
  },
});

export const getTransactionReceipt = createAsyncAction('GET_CLIX_TRANSACTION_RECEIPT', {
  saveResult: true,
  context: {
    onRequestError: () => message.error('Erro ao tentar carregar o comprovante da transação'),
    responseParser: ({ data }) => data,
    request: ({ reference }) =>
      gateway.get(`/backoffice/wallet/nfse-info`, { params: { reference } }),
  },
});
