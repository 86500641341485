// @flow

import React from 'react';
import Modal from '~/components/Modal';
import styles from '~/screens/ContractDetails/Panels/RefinancedContracts/styles';
import theme from '~/themes/aphroditeTheme/theme';
import { Button } from 'antd';
import LinkContract from '~/screens/ContractDetails/Panels/RefinancedContracts/components';

const { css, withStyles } = theme;

type ModalLinkProps = {
  visible: boolean,
  personId: number,
  bankNumber: number,
  installmentValue: number,
  refinancedContractId: string,
  onPressClose: () => void,
};

const modalStyle = {
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    transition: 'all 0.3s',
    zIndex: 1000,
    top: '-30%',
  },
};

function ModalContent(props: ModalLinkProps) {
  const { styles } = props;
  if (!props.personId) return null;
  return (
    <LinkContract
      personId={props.personId}
      bankNumber={props.bankNumber}
      installmentValue={props.installmentValue}
      refinancedContractId={props.refinancedContractId}
    />
  );
}

function ModalLink(props: ModalLinkProps) {
  const { styles } = props;
  return (
    <div>
      <Modal
        keyboard
        maskClosable
        onCancel={props.onPressClose}
        visible={props.visible}
        contentLabel='Confirm'
        title='Vincule o Contrato a uma Chave de Reserva'
        key='modal'
      >
        <ModalContent {...props} />
      </Modal>
    </div>
  );
}

export default withStyles(styles)(ModalLink);
