import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    color: #8C8C8C;
    font-weight: 500;
    
    > span {
      margin-left: 3px;
    }
  }
`;
