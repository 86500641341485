/* global localStorage */

export const SET_HIDDEN_CONTRACT_FIELDS = 'SET_HIDDEN_CONTRACT_FIELDS';

export default function fields(fields) {
  return dispatch => {
    // TODO: Até decidirem padronizar se chamam de authenticationToken ou token.
    dispatch({
      type: SET_HIDDEN_CONTRACT_FIELDS,
      loading: false,
      payload: fields,
    });
  };
}
