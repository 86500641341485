import styled from 'styled-components';
import { Typography } from '~/ui/components';

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 16px 8px;
  height: 100%;
  max-height: 400px;
  overflow: auto;
`;

export const CardItem = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'div',
  color: theme.colors.element.secondary,
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  span {
    color: ${({ theme }) => theme.colors.element.primary};
    font-weight: 600;
  }
`;
