/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { TAGTYPES_GET_REQUEST, success, failure } from './action';
import { getQueryParams } from '~/common';

const getTagTypes = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(TAGTYPES_GET_REQUEST)
    .map(({ data }) => data)
    .mergeMap(params =>
      AjaxRequest.open('get', `/admin/tag/tagtype?${getQueryParams(params)}`)(
        'tudo',
      )
        .flatMap(data => Observable.of(success(data.response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(getTagTypes);
