import React, { Component } from 'react';
import { Col, Input } from 'antd';
import { isEmpty } from 'underscore';

import masked from '~/common/masked';
import { EnumMappers, SearchSelect } from '~/common';
import { Card as Segment, Spinner } from '~/components';

import 'moment/locale/pt-br';
import LoanTypes from '~/constants/LoanTypes';
import { ExistingLoanTable, LoanHistoryTable } from './components';

import { Row, FirstRow, Button, Title, Form, Select } from './FillContract.styled';

const { Item: FormItem } = Form;
const { LoanType } = EnumMappers;
const { Option } = Select;
const { toMoneyForm, toNumber } = masked;
const { NEW, REFIN, PORTABILITY, FUTUREMARGIN } = LoanTypes;

type Props = {
  agreements: Array<any>,
  fetchAllFinancialInstitutions: () => void,
  fetchFinancialInstitutionsByAgreementId: () => void,
  form: {
    validateFields: () => any,
  },
  factorTypes: Array<any>,
  financialInstitutions: Array<any>,
  getFieldDecorator: any,
  loanTypes: Array<any>,
  fillingContract: Boolean,
  isResponseBackFromServer: Boolean,
  isResponseBackFromServerWithError: Boolean,
  onSubmit: () => void,
};

class FillContract extends Component<Props> {
  constructor(props) {
    super(props);

    this.financialInstitutionRef = React.createRef();
    this.state = {
      personId: this.props.match.params.id,
      personAgreementId: this.props.location.state.personAgreementId,
      financialInstitutions: [],
      installmentValue: '',
      loanType: '',
      numberOfInstallments: '',
      nominalRatePerMonth: '',
      netValue: '',
      iofValue: '',
      cetPerMonth: '',
      financialInstitutionId: undefined,
      bankNumber: '',
      selectedExistingLoansId: [],
      selectedPortableContractsId: [],
      selectedLoanHistory: undefined,
      isSelectedExistingLoansIdRequiredErrorVisible: false,
      isSelectedLoanHistoryRequiredErrorVisible: false,
    };
  }

  componentDidMount() {
    const { personAgreementId } = this.state;

    if (personAgreementId) {
      this.props.fetchFinancialInstitutionsByAgreementId({
        agreementId: personAgreementId,
      });
    }
  }

  stripMoneyValue = (value) =>
    // Divide por 100 devido a retirada da virgula
    value.replace(/[.,\s]/g, '').replace(/^0+/, '') / 100;

  stripPercentualValue = (value) =>
    // Alem do tratamento da virgula, o backend precisa do valor dividido por 100
    this.stripMoneyValue(value) / 100;

  removeleadingZeros = (value) => value.replace(/^0+/, '');

  onInstallmentValueChange = (installmentValue) => {
    this.setState({
      installmentValue: this.stripMoneyValue(installmentValue.target.value),
    });
  };

  onNominalRateValueChange = (e) => {
    this.setState({
      nominalRatePerMonth: this.stripPercentualValue(e.target.value),
    });
  };

  onNetValueChange = (e) => {
    this.setState({
      netValue: this.stripMoneyValue(e.target.value),
    });
  };

  onIofValueChange = (e) => {
    this.setState({
      iofValue: this.stripMoneyValue(e.target.value),
    });
  };

  onCetPerMonthValueChange = (e) => {
    this.setState({
      cetPerMonth: this.stripPercentualValue(e.target.value),
    });
  };

  onNumberOfInstallmentsValueChange = (e) => {
    if (!this.state.numberOfInstallmentsChanged) {
      this.setState({ numberOfInstallmentsChanged: true });
    }
    this.setState({
      numberOfInstallments: this.removeleadingZeros(e.target.value),
    });
  };

  onFinancialInstitutionChange = (financialInstitutions, financialInstitutionId) => {
    const financialInstitutionFound = financialInstitutions.find(
      (financialInstitutionObj) => parseInt(financialInstitutionId) === financialInstitutionObj.id,
    );

    this.setState(
      {
        financialInstitutionId,
        bankNumber: financialInstitutionFound ? financialInstitutionFound.bankNumber : null,
      },
      () => {
        this.setState({ loanType: undefined });
        this.props.form.setFieldsValue({
          loanType: undefined,
        });
      },
    );
  };

  onChangeLoanType = (loanType) => {
    this.setState({ loanType }, () => {
      this.setState({
        isSelectedExistingLoansIdRequiredErrorVisible: false,
        isSelectedLoanHistoryRequiredErrorVisible: false,
      });
    });
  };

  onClick = ({ match }) => {
    this.props.onSubmit(this.state);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      // Mostra mensagem de erro de arquivo programaticamente
      if (values.loanType !== NEW && values.loanType !== FUTUREMARGIN) {
        if (!this.getIsSelectedExistingLoansIdRequired(this.state)) {
          delete err.selectedExistingLoan;
        } else {
          this.setState({
            isSelectedExistingLoansIdRequiredErrorVisible: true,
          });
        }

        if (!this.getIsSelectedLoanHistoryRequired(this.state)) {
          delete err.selectedLoanHistory;
        } else {
          this.setState({ isSelectedLoanHistoryRequiredErrorVisible: true });
        }
      }

      if (!err || isEmpty(err)) {
        this.props.onSubmit(this.state);
      }
    });
  };

  reset = () => {
    this.props.form.resetFields();
  };

  getSelectedExistingLoansIdFromTable = (selectedExistingLoansId) => {
    selectedExistingLoansId.map((selectedExistingLoan) => delete selectedExistingLoan.checked);
    const selectedExistingLoansIdValues = selectedExistingLoansId.map(
      (selectedExistingLoan) => selectedExistingLoan.existingLoanId,
    );

    this.setState({ selectedExistingLoansId: selectedExistingLoansIdValues });
  };

  getSelectedLoanHistoryFromTable = (selectedLoanHistory) => {
    //  O BE espera o loanHistory selecionado dentro de uma lista chamada selectedPortableContractsId

    this.setState({ selectedLoanHistory }, () =>
      this.setState({ selectedPortableContractsId: [selectedLoanHistory] }),
    );
  };

  getIsSelectedExistingLoansIdRequired = (state) =>
    isEmpty(state.selectedExistingLoansId) && state.loanType === REFIN;

  getIsSelectedExistingLoansIdRequiredErrorVisible = (state) =>
    this.getIsSelectedExistingLoansIdRequired(state) &&
    state.isSelectedExistingLoansIdRequiredErrorVisible;

  getIsSelectedLoanHistoryRequired = (state) =>
    state.selectedLoanHistory === undefined && state.loanType === PORTABILITY;

  getIsSelectedLoanHistoryRequiredErrorVisible = (state) =>
    this.getIsSelectedLoanHistoryRequired(state) && state.isSelectedLoanHistoryRequiredErrorVisible;

  renderTable = (getFieldDecorator) => {
    if (this.state.loanType === REFIN) {
      return (
        <div>
          <ExistingLoanTable
            personId={this.state.personId}
            bankNumber={this.state.bankNumber}
            getSelectedExistingLoansIdFromTable={this.getSelectedExistingLoansIdFromTable}
          />
          <FormItem
            validateStatus={this.getIsSelectedExistingLoansIdRequired(this.state) ? 'error' : ''}
            help={
              this.getIsSelectedExistingLoansIdRequiredErrorVisible(this.state)
                ? 'Escolha um contrato'
                : ''
            }
          >
            {getFieldDecorator('selectedExistingLoan', {
              rules: [{ required: true }],
            })(<span />)}
          </FormItem>
        </div>
      );
    }

    if (this.state.loanType === PORTABILITY) {
      return (
        <div>
          <LoanHistoryTable
            personId={this.state.personId}
            bankNumber={this.state.bankNumber}
            getSelectedLoanHistoryFromTable={this.getSelectedLoanHistoryFromTable}
          />

          <FormItem
            validateStatus={this.getIsSelectedLoanHistoryRequired(this.state) ? 'error' : ''}
            help={
              this.getIsSelectedLoanHistoryRequiredErrorVisible(this.state)
                ? 'Escolha um contrato'
                : ''
            }
          >
            {getFieldDecorator('selectedLoanHistory', {
              rules: [{ required: true }],
            })(<div />)}
          </FormItem>
        </div>
      );
    }
    return null;
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Segment style={{ padding: 16 }}>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Spinner spinning={this.props.fillingContract} />
          <FirstRow>
            <Col xs={{ span: 24 }} lg={{ span: 7, offset: 0 }}>
              <Title>Valor Líquido:</Title>
              <FormItem>
                {getFieldDecorator('netValue', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite o valor líquido do contrato',
                    },
                  ],
                  normalize: toMoneyForm,
                })(<Input addonBefore="R$" type="text" onChange={this.onNetValueChange} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8, offset: 1 }}>
              <Title>Valor da Parcela:</Title>
              <FormItem>
                {getFieldDecorator('installmentValue', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite o valor da parcela',
                    },
                  ],
                  normalize: toMoneyForm,
                })(<Input addonBefore="R$" type="text" onChange={this.onInstallmentValueChange} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
              <Title>Número de Parcelas:</Title>
              <FormItem>
                {getFieldDecorator('numberOfInstallments', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite o número de parcelas do contrato',
                    },
                    {
                      pattern: /^([1-9]([0-9])*)$/g,
                      message: 'Digite um valor válido, sem iniciar com 0',
                    },
                  ],
                  normalize: toNumber,
                })(<Input type="text" onChange={this.onNumberOfInstallmentsValueChange} />)}
              </FormItem>
            </Col>
          </FirstRow>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 7, offset: 0 }}>
              <Title>IOF:</Title>
              <FormItem>
                {getFieldDecorator('iofValue', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite a taxa nominal por mês',
                    },
                  ],
                  normalize: toMoneyForm,
                })(<Input addonBefore="R$" type="text" onChange={this.onIofValueChange} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8, offset: 1 }}>
              <Title>Taxa Nominal:</Title>
              <FormItem>
                {getFieldDecorator('nominalRatePerMonth', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite a taxa nominal por mês',
                    },
                  ],
                  normalize: toMoneyForm,
                })(<Input addonAfter="%" type="text" onChange={this.onNominalRateValueChange} />)}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
              <Title>Custo Efetivo Total (Mês):</Title>
              <FormItem>
                {getFieldDecorator('cetPerMonth', {
                  rules: [
                    {
                      required: true,
                      message: 'Digite o custo efetivo total por mês',
                    },
                  ],
                  normalize: toMoneyForm,
                })(<Input addonAfter="%" type="text" onChange={this.onCetPerMonthValueChange} />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 7, offset: 0 }} style={{ marginBottom: '10px' }}>
              <Title>Instituição Financeira:</Title>
              <FormItem>
                {getFieldDecorator('financialInstitutionSelect', {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione uma instituição financeira',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Selecione"
                    onChange={(e) =>
                      this.onFinancialInstitutionChange(this.props.financialInstitutions.toJS(), e)
                    }
                    disabled={!this.state.personAgreementId}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => SearchSelect(input, option.props.children)}
                  >
                    {this.props.financialInstitutions.map((item) => (
                      <Option key={item.id}>{item.consignee.name}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8, offset: 1 }}>
              <Title>Tipo do Contrato:</Title>
              <FormItem>
                {getFieldDecorator('loanType', {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione o tipo de empréstimo',
                    },
                  ],
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Selecione"
                    onChange={this.onChangeLoanType}
                    disabled={!this.state.financialInstitutionId}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => SearchSelect(input, option.props.children)}
                  >
                    {this.props.loanTypes.map((item) => (
                      <Option key={item}>{LoanType({ value: item })}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <Title>{this.renderTable(getFieldDecorator)}</Title>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 3 }}>
              <Button
                block
                disabled={this.props.fillingContract}
                type="primary"
                style={{ width: '120px' }}
                onClick={this.handleSubmit}
              >
                {this.props.fillingContract ? 'Salvando...' : 'Cadastrar'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Segment>
    );
  }
}

FillContract.defaultProps = {
  agreements: [],
  factorTypes: [],
  financialInstitutions: [],
};

const WrappedFillContract = Form.create()(FillContract);

export default WrappedFillContract;
