import React, { useEffect, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import FormItem from 'antd/lib/form/FormItem';
import { Col, Row } from 'antd';

import { sendPushNotification } from '~/store/sendPushNotification/actions';
import CloseIcon from '~/assets/icons/close.svg';
import { getPersonDetail } from '~/store/people/actions';
import { Input } from '~/ui/components';
import {
  ItemText,
  MenuItem,
  ModalContainer,
  ModalHeader,
  NotificationForm,
  SubmitButton,
  Title,
  CloseModalIcon,
} from '../PersonActions.styled';

type SendNotificationButtonProps = {
  onClick?: () => void;
};

function SendNotificationButton({ onClick }: SendNotificationButtonProps) {
  const [visible, setVisible] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [message, setMessage] = useState('');

  const [sendPushNotificationControl, getPersonDetailControl] = useAwaitControl([
    sendPushNotification,
    getPersonDetail,
  ]);

  const notificationIsLoading = sendPushNotificationControl.isRunning();
  const notificationSent = sendPushNotificationControl.isSuccessful();
  const personDetails = getPersonDetailControl.result();

  const handleClick = () => {
    const personId = personDetails?.id;

    sendPushNotificationControl.start({ personId, messageTitle, message });
  };

  useEffect(() => {
    if (notificationSent) {
      setVisible(false);
    }
  }, [notificationSent]);

  const showModal = () => {
    onClick?.();
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <MenuItem onClick={showModal}>
        <ItemText>Enviar Notificação</ItemText>
      </MenuItem>
      <ModalContainer visible={visible} onOk={handleOk} onCancel={handleCancel}>
        <ModalHeader>
          <Title>Envio de Notificação</Title>
          <CloseModalIcon
            loading={false}
            component={CloseIcon}
            onClick={handleCancel}
            style={{ fontSize: '24px' }}
          />
        </ModalHeader>
        <NotificationForm>
          <Row>
            <Col xs={{ span: 24 }}>
              <span>Título:</span>
              <FormItem>
                <Input onChange={(e) => setMessageTitle(e)} placeholder="Título..." type="input" />
              </FormItem>
              <span>Mensagem:</span>
              <FormItem>
                <Input
                  rows={4}
                  textArea
                  onChange={(e) => setMessage(e)}
                  placeholder="Mensagem..."
                />
              </FormItem>
            </Col>
          </Row>
          <SubmitButton
            disabled={notificationIsLoading}
            icon={notificationIsLoading ? 'CircleLoading' : ''}
            onClick={handleClick}
          >
            Enviar
          </SubmitButton>
        </NotificationForm>
      </ModalContainer>
    </>
  );
}

export default SendNotificationButton;
