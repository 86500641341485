export default function style({ unit }) {
  const fontFamily = 'Rubik, sans-serif';

  const button = {
    border: 'none',
    borderRadius: '3px',
    boxShadow: 'inset 0 -1px 0 0 rgba(0,0,0,0.10)',
    color: 'white',
    cursor: 'pointer',
    fontFamily,
    fontSize: '12px',
    fontWeight: 'bold',
    height: '39px',
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: 1.2,
    padding: `${unit * 1.2}px 0`,
    width: '180px',

    ':hover': {
      textDecoration: 'underline',
    },
  };

  const action = {
    cursor: 'pointer',
    fontFamily,
    lineHeight: 1.2,
    padding: `${unit * 2}px 0 0 0`,

    ':hover': {
      textDecoration: 'underline',
    },
  };

  const ease = {
    '0%': {
      opacity: 0,
      transform: 'scale(0.7) translate3d(0,0,0)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1) translate3d(0,0,0)',
    },
  };

  return {
    actions: {
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '56px',
      width: 250,
    },
    action: {
      ...action,
      color: 'rgba(0, 0, 0, 0.65)',
    },
    refuse__action: {
      ...action,
      color: '#C0392B',
      padding: `${unit * 2}px 0 0 0`,
    },
    approve__button: {
      ...button,
      background: '#52B771',
      marginTop: unit * 2,
    },
    key: {
      display: 'block',
      color: '#949494',
      fontFamily,
      fontSize: unit * 0.9,
      letterSpacing: '0',
      textTransform: 'uppercase',
    },
    modal: {
      animationName: ease,
      animationDuration: '150ms',
      animationTimingFunction: 'linear',
      animationFillMode: 'forwards',
      background: 'white',
      borderRadius: 3,
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1)',
      padding: unit * 2,
      position: 'relative',
      width: 450,
    },
    modal__header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: unit * 0.5,
      marginBottom: unit * 2,
    },
    modal__close: {
      border: 'none',
      outline: 'none',
      background: 'white',
      position: 'absolute',
      top: 5,
      right: 0,
    },
    closeButton: {
      color: '#777',
      transition: 'all 0.5s',

      ':hover': {
        color: 'black',
        background: '#dfdfdf',
        padding: '2px',
        borderRadius: 3,
      },
    },
    modal__title: {
      display: 'block',
      color: '#777',
      fontFamily,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    modal__content: {
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  };
}
