export default {
  main: '#EB2F96',
  50: '#FFB3FF',
  100: '#FF9CF4',
  200: '#FF84DD',
  300: '#FF6CC7',
  400: '#FF53B1',
  500: '#F1379B',
  600: '#D90F87',
  700: '#C00072',
  800: '#A8005F',
  900: '#90004C',
};
