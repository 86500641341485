import React from 'react';
import { SiderProps as AntSiderProps } from 'antd/lib/layout/Sider';

import { Person } from '~/typings/entities/person';
import { ProfileResume } from '~/components';

import { SidebarContainer, SidebarContent } from './ProfileWithActions.styled';

type SidebarProps = AntSiderProps & {
  person: Person;
  loading?: boolean;
  avatarImage?: string;
  responsiveActions?: React.ReactNode;
};

export function ProfileWithActions({
  person,
  loading,
  avatarImage,
  children,
  responsiveActions,
  ...props
}: SidebarProps) {
  return (
    <SidebarContainer reverseArrow {...props}>
      <SidebarContent>
        <ProfileResume
          person={person}
          loading={loading}
          image={avatarImage}
          className="profile-resume"
          responsiveActions={responsiveActions}
        />
        {children}
      </SidebarContent>
    </SidebarContainer>
  );
}
