/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { mapObject } from 'underscore';

import {
  FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_REQUEST,
  success,
  failure,
} from './action';

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(FINANCIAL_INSTITUTIONS_FOR_DEPOSIT_FETCH_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(({ agreementId, params }) => {
      const search = new URLSearchParams();
      mapObject(params, (value, key) => search.set(key, value));

      return AjaxRequest.open(
        'get',
        `/admin/financialinstitutioncustom/availablefordeposit?${search.toString()}`,
        params,
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err)));
    });

export default combineEpics(events);
