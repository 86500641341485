export enum HistoryChannel {
  'APP' = 'Aplicativo',
  'BACKOFFICE' = 'Backoffice',
  'CRM' = 'CRM',
}

export enum HistoryChannelColor {
  'APP' = 'brand.secondaryAlt',
  'BACKOFFICE' = 'info.secondaryAlt',
  'CRM' = 'alert.secondaryAlt',
}
