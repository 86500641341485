import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useModal } from '~/hooks/useModal';
import { getPersonDetail, resetPerson } from '~/store/people/actions';
import useUserRoles from '~/hooks/useUserRoles';
import { CustomButton, ModalText, Wrapper } from '../OtherOptions.styled';

export type ResetModalProps = {
  closeModal?: () => void;
};

export type Reason = {
  id: number;
  description: string;
};

export function ResetModal() {
  const { closeAll } = useModal();
  const { isAdmin, isSupervisor, hasRole } = useUserRoles();
  const [resetPersonControl, getPersonDetailControl] = useAwaitControl([
    resetPerson,
    getPersonDetail,
  ]);

  const person = getPersonDetailControl.result();
  const isRunning = resetPersonControl.isRunning();
  const successReset = resetPersonControl.isSuccessful();

  const handleSubmit = () => {
    resetPersonControl.start({
      id: String(person?.id),
    });
  };

  useEffect(() => {
    if (successReset) {
      closeAll();
    }
  }, [successReset]);

  return (
    <Wrapper>
      {isAdmin || isSupervisor ? (
        <ModalText>
          Você tem certeza que deseja prosseguir com o reset do cadastro?
          <br />
          <b>Atenção! Esta ação não pode ser revertida!</b>
        </ModalText>
      ) : (
        <ModalText>
          Você não tem permissão para resetar o cadastro. <br />É necessário solicitar a um usuário
          com permissões adequadas para realizar esse procedimento.
        </ModalText>
      )}

      <CustomButton
        loading={isRunning}
        onClick={handleSubmit}
        rounded
        fullWidth
        type="submit"
        disabled={!isAdmin && !isSupervisor}
      >
        Resetar cadastro
      </CustomButton>
    </Wrapper>
  );
}
