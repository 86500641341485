export default {
  main: '#FF7F11',
  50: '#FFF1E0',
  100: '#FFB952',
  200: '#FFA23C',
  300: '#FF8D24',
  400: '#F67701',
  500: '#DC6200',
  600: '#C24E00',
  700: '#A93900',
  800: '#912300',
  900: '#7A0600',
};
