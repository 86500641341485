import React from 'react';

import { OpportunityListItem } from '~/typings/entities/Opportunity';

import { FgtsSteps } from './FGTS_NEW/FgtsNewSteps';
import { RefinSteps } from './REFIN/RefinSteps';
import { PortSteps } from './PORTABILITY/PortSteps';
import { NewSteps } from './NEW/NewSteps';
import { FutureMarginSteps } from './FUTUREMARGIN/FutureMargin';

export type OpportunityProps = {
  opportunity: OpportunityListItem;
};

export function Opportunity({ opportunity }: OpportunityProps) {
  const steps = {
    FGTS_NEW: FgtsSteps,
    NEW: NewSteps,
    REFIN: RefinSteps,
    PORTABILITY: PortSteps,
    FUTUREMARGIN: FutureMarginSteps,
  };

  const Component = steps[opportunity.loanType] || React.Fragment;

  return <Component opportunity={opportunity} />;
}
