// @flow

import React, { useCallback, useState } from 'react';
import { Pendency } from '~/screens/ContractDetails/Panels/Pendencies/styled';
import pendencyTitle from '~/typings/enums/PendencyType';
import Selection from '~/screens/ContractDetails/Panels/Pendencies/components/actions/ProofOfResidence/components/Selection';
import { Modal } from '~/components';

type DocumentsProps = {
  onSendFile: () => void,
  id?: number,
  type?: string,
};

function ProofOfResidence(props: DocumentsProps) {
  const [showModal, setStateShowModal] = useState(false);

  const setShowModal = useCallback((show) => () => {
    setStateShowModal(show);
  }, []);

  const onFileSelected = useCallback((file, type) => {
    setShowModal(false)();
    props.onSendFile(file, type);
  }, []);

  return (
    <>
      <Pendency onClick={setShowModal(true)}>
        {pendencyTitle(props.type) || props.children}
        <span>{props.reason}</span>
      </Pendency>
      <Modal
        title="Tipo de comprovante"
        maskClosable
        visible={showModal}
        onCancel={setShowModal(false)}
        key="modal"
      >
        <Selection onFileSelected={onFileSelected} />
      </Modal>
    </>
  );
}

export default ProofOfResidence;
