import React, { useCallback, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { useParams } from 'react-router';

import { Col, Row } from 'antd';
import moment from 'moment';

import modalBuilder from '~/hoc/modalBuilder';
import { updatePersonInfo } from '~/store/people/actions';
import { getContractDetails } from '~/store/contracts/actions';
import masked from '~/common/masked';
import InputLabel from '~/components/InputLabel';

const { toDate } = masked;

const MODAL_NAME = 'UPDATE_BIRTHDATE';

type DataValidProps = {
  birthDate: any;
  personId: number;
  closeModal: () => void;
};

function UpdateBirthDate({ birthDate, personId, closeModal }: DataValidProps) {
  const { id: contractId } = useParams<{ id: string }>();

  const [updatePersonInfoControl, getContractControl] = useAwaitControl([
    updatePersonInfo,
    getContractDetails,
  ]);

  const updatePersonOk = updatePersonInfoControl.isSuccessful();

  const onForceUpdateDatavalid = useCallback((newBirthDate) => {
    updatePersonInfoControl.start({
      birthDate: moment(newBirthDate, 'L', 'pt-br').toDate(),
      id: personId,
    });
  }, []);

  useEffect(() => {
    if (updatePersonOk) {
      closeModal?.();
      getContractControl.start({ id: contractId });
      updatePersonInfoControl.clear();
    }
  }, [updatePersonOk]);

  return (
    <>
      <Row>
        <Col xs={{ span: 12 }}>
          <InputLabel
            title="DATA DE NASCIMENTO"
            format="99/99/9999"
            initialValue={toDate(birthDate)}
            onSubmit={onForceUpdateDatavalid}
          />
        </Col>
      </Row>
    </>
  );
}

export default modalBuilder(UpdateBirthDate, MODAL_NAME, {
  title: 'Alterar data de nascimento',
  centered: true,
});
