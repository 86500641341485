import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: 0;
`;

export const Item = styled.li`
  padding: 1rem;
  border-radius: 8px;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};

  &:hover {
    background-color: ${themeColor('brand.secondary', { dark: 'state.hover' })};
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    cursor: pointer;
  }
`;
