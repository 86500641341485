import styled from 'styled-components';
import { Button, Typography } from '~/ui/components';
import { themeToggleColor } from '~/common/utils/theme';

export const HistoryList = styled.div`
  overflow: auto;
  max-height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &::-webkit-scrollbar {
    width: 4px;
  }
`;

export const HistoryRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const RowLabel = styled(Typography).attrs(({ theme }) => ({
  type: 'headingXSmall',
  color: theme.colors.element.primary,
  weight: 600,
}))``;

export const HistoryInfo = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: theme.colors.element.primary,
  element: 'span',
}))``;

export const LoadingWrapper = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const LoadMoreButton = styled(Button).attrs(({ theme }) => ({
  variant: 'outline',
  customColor: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  type: 'button',
  size: 'sm',
  fullWidth: true,
  rounded: true,
}))``;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.divider.primary};
`;
