import React from 'react';
import styled, { css } from 'styled-components';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 200px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.gray10};
  padding: 12px 0px;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.3s linear;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.primaryColorLightest};
    border-color: ${({ theme }) => theme.primaryColorLight};
  }

  .title {
    color: ${({ theme, success }) =>
      success ? theme.successColor : theme.primaryColor};
  }

  input {
    visibility: hidden;
  }

  .img_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;

    img {
      max-height: 80px;
      max-width: 80px;
    }
  }
`;
