import React from 'react';

import { find, propertyOf } from 'underscore';

export default function usePresentationColumns(presentation) {
  const header = id => find(presentation.columns, item => item.id === id);

  return presentation.columns.map(column => {
    switch (typeof column) {
      case 'string':
        return {
          accessor: column,
          Header: () => header(column),
        };
      case 'object':
        return {
          Header: () => {
            const content =
              column.name ||
              header(
                typeof column.property === 'string'
                  ? column.property
                  : column.accessor,
              ).name;
            return (
              <div className='header-container'>
                <div style={{ flex: 1 }} accessor={column.accessor}>
                  {content}
                </div>
              </div>
            );
          },
          ...(() => {
            if (typeof column.property === 'string') {
              return {
                accessor: item =>
                  propertyOf(item[column.property])(column.accessor),
                property: column.property,
                id: column.property,
              };
            }
            return {
              accessor: column.accessor,
            };
          })(),
          ...column.props,
        };
      default:
        return {};
    }
  });
}
