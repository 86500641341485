/* @flow */
import _ from 'lodash';

export const GET_CONTRACT_FILES_REQUEST = 'GET_CONTRACT_FILES_REQUEST';
export const GET_CONTRACT_FILES_SUCCESS = 'GET_CONTRACT_FILES_SUCCESS';
export const GET_CONTRACT_FILES_FAILURE = 'GET_CONTRACT_FILES_FAILURE';

export function success(payload, id) {
  return (dispatch, getState) => {
    const contractFiles = getState()
      .getIn(['getContractFiles'])
      .toJS();
    const allFiles = contractFiles.payload;
    const files = allFiles[id] || [];
    const newFiles = Object.assign({}, allFiles, {
      [id]: _.merge(files, payload[id]),
    });
    dispatch({
      type: GET_CONTRACT_FILES_SUCCESS,
      loading: false,
      payload: newFiles,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_FILES_FAILURE,
      loading: false,
      payload: resource,
    });
  };
}

export default function fetch(files, id) {
  return dispatch => {
    dispatch({
      type: GET_CONTRACT_FILES_REQUEST,
      loading: true,
      data: {
        files,
        id,
      },
    });
  };
}
