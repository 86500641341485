/* @flow */

export const GET_MEDICAL_CERTIFICATE_REQUEST =
  'GET_MEDICAL_CERTIFICATE_REQUEST';
export const GET_MEDICAL_CERTIFICATE_SUCCESS =
  'GET_MEDICAL_CERTIFICATE_SUCCESS';
export const GET_MEDICAL_CERTIFICATE_FAILURE =
  'GET_MEDICAL_CERTIFICATE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: GET_MEDICAL_CERTIFICATE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: GET_MEDICAL_CERTIFICATE_FAILURE,
      payload: resource,
    });
  };
}

export default function GET(params) {
  return dispatch => {
    dispatch({
      type: GET_MEDICAL_CERTIFICATE_REQUEST,
      payload: params,
    });
  };
}
