/* @flow */

export const UPDATE_CONTRACT_VALUES_REQUEST = 'UPDATE_CONTRACT_VALUES_REQUEST';
export const UPDATE_CONTRACT_VALUES_SUCCESS = 'UPDATE_CONTRACT_VALUES_SUCCESS';
export const UPDATE_CONTRACT_VALUES_FAILURE = 'UPDATE_CONTRACT_VALUES_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: UPDATE_CONTRACT_VALUES_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: UPDATE_CONTRACT_VALUES_FAILURE,
      payload: resource,
    });
  };
}

export default function updateContractValues(params) {
  return dispatch => {
    dispatch({
      type: UPDATE_CONTRACT_VALUES_REQUEST,
      payload: params,
    });
  };
}
