import React, { memo, useCallback, useState } from 'react';

import { ArrowRightCircle } from '~/ui/assets/icons';
import { Caret, Popover } from '~/ui/components';
import { formatDate } from '~/common/formatDate';
import { Enrollment } from '~/typings/entities/Enrollment';
import { Person } from '~/typings/entities/person';
import { Label, ListWithArrow } from './Popovers.styled';

type LastUpdatesProps = {
  enrollment: Enrollment;
  person: Person;
};

function LastUpdates({ enrollment, person }: LastUpdatesProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalVisibilityChange = useCallback((e: boolean) => setIsModalVisible(e), []);

  return (
    <Popover
      placement="bottom"
      title="Últimas atualizações do cliente"
      trigger="click"
      width={390}
      onVisibleChange={handleModalVisibilityChange}
      visible={isModalVisible}
      content={
        <ListWithArrow>
          <li>
            <ArrowRightCircle width={16} height={16} />
            <Label>Margem: {formatDate(enrollment?.marginInfo?.marginUpdateDate)}</Label>
          </li>
          <li>
            <ArrowRightCircle width={16} height={16} />
            <Label>
              Portabilidade: {formatDate(enrollment?.marginInfo?.portabilityUpdateDate)}
            </Label>
          </li>
          {enrollment?.product === 'INSS' && (
            <li>
              <ArrowRightCircle width={16} height={16} />
              <Label>
                Última perícia: {formatDate(person?.medicalExpertise?.medicalCertificateDate)}
              </Label>
            </li>
          )}
          {formatDate(person?.medicalExpertise?.benefitStartDate) !== 'Indisponível' && (
            <li>
              <ArrowRightCircle width={16} height={16} />
              <Label>
                Recebimento do benefício:{' '}
                {formatDate(person?.medicalExpertise?.benefitStartDate, false)}
              </Label>
            </li>
          )}
        </ListWithArrow>
      }
    >
      <Label>Últimas atualizações</Label>
      <Caret caretOpen={isModalVisible} />
    </Popover>
  );
}

export default memo(LastUpdates);
