import React, { useCallback, useState } from 'react';

import { ContractValueList, OpportunityListItem } from '~/typings/entities/Opportunity';
import toMoney from '~/common/masked/toMoney';
import { Caret, Checkbox, Flex, Typography } from '~/ui/components';
import toPercentage from '~/common/masked/toPercentage';
import { CheckboxContainer, ContractBox, Informations } from './Selection.styled';

type CardProps = {
  contract: ContractValueList;
  opportunity: OpportunityListItem;
  selectedContract: ContractValueList;
  setSelectedContract: (contract: ContractValueList) => void;
};

function Card({ contract, setSelectedContract, selectedContract, opportunity }: CardProps) {
  const [isOpen, setIsOpen] = useState(false);

  const isContractSelected = (contractId: number) => selectedContract?.contractId === contractId;

  const handleSelectContract = (contract: ContractValueList) => (checked) => {
    if (checked) {
      setSelectedContract(contract);
    } else {
      setSelectedContract(null);
    }
  };

  const getFinancialInstitution = useCallback(
    (bankCode: string) => {
      const financialInstitution = opportunity?.portableContracts?.map(
        (contract: any) => contract?.financialInstitutionDTO,
      );
      return financialInstitution?.find((bank: any) => bank.bankNumber === bankCode);
    },
    [opportunity?.portableContracts],
  );

  return (
    <ContractBox>
      <CheckboxContainer rounded checked={isContractSelected(contract.contractId)}>
        <Checkbox
          name={`contract_${contract.contractId}`}
          onChange={handleSelectContract(contract)}
          checked={isContractSelected(contract.contractId)}
          radio
        >
          <div>
            <Typography type="bodyLarge" weight={600}>
              Receba até {toMoney(contract.valueForDeposit)}
            </Typography>
            <Typography type="bodySmall" color="element.secondary">
              {getFinancialInstitution(contract?.originalBankNumber)?.name || '-'}
            </Typography>
          </div>
        </Checkbox>
      </CheckboxContainer>

      <div>
        <Flex gap={4}>
          <Typography type="bodyMedium" color="element.secondary">
            {contract?.numberOfInstallments} parcelas de{' '}
            <Typography.Bold color="element.primary" weight={600}>
              {toMoney(contract?.installmentValue)}
            </Typography.Bold>
          </Typography>
        </Flex>

        <Informations mt={8} gap={8} align="center" onClick={() => setIsOpen(!isOpen)}>
          <Typography type="bodyMedium" color="brand.primary" weight={700}>
            {!isOpen ? 'Mais informações' : 'Menos informações'}
          </Typography>
          <Caret caretOpen={isOpen} />
        </Informations>

        {isOpen && (
          <>
            <Flex gap={4}>
              <Typography type="bodySmall" color="element.secondary">
                Valor original do contrato
              </Typography>
              <Typography type="bodySmall" color="element.primary" weight={600}>
                {toMoney(
                  Number(contract?.numberOfInstallments) * Number(contract?.installmentValue),
                )}
              </Typography>
            </Flex>

            <Flex gap={4}>
              <Typography type="bodySmall" color="element.secondary">
                Parcelas restantes
              </Typography>
              <Typography type="bodySmall" color="element.primary" weight={600}>
                {Number(contract?.numberOfInstallments) - Number(contract?.paidInstallments)}X{' '}
                {toMoney(contract?.installmentValue) || '-'}
              </Typography>
            </Flex>

            <Flex gap={4}>
              <Typography type="bodySmall" color="element.secondary">
                Taxa contrato original
              </Typography>
              <Typography type="bodySmall" color="element.primary" weight={600}>
                {toPercentage(contract?.nominalRatePerMonth) || '-'} (a.m)
              </Typography>
            </Flex>
          </>
        )}
      </div>
    </ContractBox>
  );
}

export default Card;
