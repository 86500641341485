/* @flow */

export const FETCH_AVAILABLE_EXISTING_LOANS_REQUEST =
  'FETCH_AVAILABLE_EXISTING_LOANS_REQUEST';
export const FETCH_AVAILABLE_EXISTING_LOANS_SUCCESS =
  'FETCH_AVAILABLE_EXISTING_LOANS_SUCCESS';
export const FETCH_AVAILABLE_EXISTING_LOANS_FAILURE =
  'FETCH_AVAILABLE_EXISTING_LOANS_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: FETCH_AVAILABLE_EXISTING_LOANS_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FETCH_AVAILABLE_EXISTING_LOANS_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(params) {
  return dispatch => {
    dispatch({
      type: FETCH_AVAILABLE_EXISTING_LOANS_REQUEST,
      payload: {
        params,
      },
    });
  };
}
