const REGISTRATION_STATUS = {
  OPEN: 'Aberto',
  INITALDATA: 'Dados iniciais',
  PHONENUMBER: 'Telefone',
  SMSTOKEN: 'SMS token',
  ADDRESS: 'Endereço',
  FACEPICTURE: 'Foto da Face',
  DOCUMENTSPICTURES_FRONT: 'Fotos dos documentos - Frente',
  DOCUMENTSPICTURES_BACK: 'Fotos dos documentos - Verso',
  TERMS_OF_CONTRACT: 'Termos do contrato',
  PASSWORD: 'Senha',
  COMPLETE: 'Completo',
  PENDING_IN100: 'IN100 Pendente',
  NO_ENROLLMENT: 'Sem Matrícula',
  APPROVED: 'Aprovado',
  REFUSED: 'Recusado',
  BACKOFFICE_PENDING: 'Em análise pelo backoffice',
  BACKOFFICE_PUSH_ERROR: 'Erro ao enviar para o backoffice',
  EMAIL: 'Email',
  FACEBIOMETRY: 'Biometria facial',
  AGREEMENT_PASSWORD: 'Senha INSS',
  UNDER_ANALISYS: 'Requer Suporte',
  DOCUMENTSPICTURES: 'Fotos de Documentos',
  UNDER_ANALYSIS: 'Sob análise',
  REQUIRES_LEGAL_REPRESENTATIVE: 'Requer representante legal',
  VALIDATION: 'Validação',
};

function RegistrationStatus(value) {
  if (value) {
    return REGISTRATION_STATUS[value] || value || null;
  }
  return null;
}

export default RegistrationStatus;
