/* @flow */

export const SET_CHECKED_CONTRACT_REQUEST = 'SET_CHECKED_CONTRACT_REQUEST';
export const SET_CHECKED_CONTRACT_SUCCESS = 'SET_CHECKED_CONTRACT_SUCCESS';
export const SET_CHECKED_CONTRACT_FAILURE = 'SET_CHECKED_CONTRACT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: SET_CHECKED_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: SET_CHECKED_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

export default function setCheckedContract(contractId) {
  return dispatch => {
    dispatch({
      type: SET_CHECKED_CONTRACT_REQUEST,
      payload: contractId,
    });
  };
}
