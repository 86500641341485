/* @flow */

export const CHAT_INFO_REQUEST = 'CHAT_INFO_REQUEST';
export const CHAT_INFO_SUCCESS = 'CHAT_INFO_SUCCESS';
export const CHAT_INFO_FAILURE = 'CHAT_INFO_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: CHAT_INFO_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: CHAT_INFO_FAILURE,
      payload: resource,
    });
  };
}

export default function get(customId) {
  return dispatch => {
    dispatch({
      type: CHAT_INFO_REQUEST,
      payload: {
        customId,
      },
    });
  };
}
