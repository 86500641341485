import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useAwaitControl } from 'react-redux-await-control';
import moment from 'moment';

import { JourneyHistory } from '~/typings/entities/History';
import { HistoryChannel, HistoryChannelColor } from '~/typings/enums/History';
import { getJourneyHistory, getPersonDetail } from '~/store/people/actions';
import { themeToggleColor } from '~/common/utils/theme';
import { Calendar, Timeline } from '~/ui/assets/icons';
import {
  Flex,
  Divider,
  Tag,
  InfoGrid,
  SelectDate,
  Shimmer,
  Typography,
  Filter,
  Alert,
} from '~/ui/components';
import { HistoryContainer, HistoryItem } from './GeneralHistory.styled';

interface GeneralHistoryProps {
  loading?: boolean;
}

export function GeneralHistory({ loading }: GeneralHistoryProps) {
  const theme = useTheme();

  const [filters, setFilters] = useState({
    initDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    channel: '',
  });

  const historyControl = useAwaitControl(getJourneyHistory);
  const personControl = useAwaitControl(getPersonDetail);

  const history: JourneyHistory[] = historyControl.result();
  const person = personControl.result();

  const historyLoading = historyControl.isRunning();
  const personLoading = personControl.isRunning();

  const isLoading = historyLoading || personLoading || loading;

  const channels = [
    { value: 'BACKOFFICE', label: 'Backoffice' },
    { value: 'CRM', label: 'CRM' },
    { value: 'APP', label: 'Aplicativo' },
  ];

  const bgTagColor = themeToggleColor(theme, 'neutral.secondary', {
    dark: 'neutral.primaryAlt',
  });

  const textTagColor = themeToggleColor(theme, 'neutral.primaryAlt', {
    dark: 'neutral.secondary',
  });

  const infoGridInfo = useCallback((item: JourneyHistory) => {
    const items = [
      { name: 'Canal', value: HistoryChannel[item.channel] },
      { name: 'Data', value: moment(item.eventDate).format('DD/MM/YYYY [às] HH:mm') },
    ];

    if (item.interaction) {
      items.push({ name: 'Status da mensageria', value: item.interaction });
    }

    return items;
  }, []);

  const onDateFilterChange = useCallback(
    (dates) => {
      const initDate = dates?.[0]
        ? moment(dates?.[0]).format('YYYY-MM-DD')
        : moment().subtract(1, 'week').format('YYYY-MM-DD');

      const endDate = dates?.[1]
        ? moment(dates?.[1]).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');

      setFilters((prev) => ({
        ...prev,
        initDate,
        endDate,
      }));

      historyControl.start({
        personId: person?.id,
        ...filters,
        initDate,
        endDate,
      });
    },
    [person, filters],
  );

  const onChannelFilterChange = useCallback(
    (channel: string) => {
      setFilters((prev) => ({
        ...prev,
        channel,
      }));

      historyControl.start({
        personId: person?.id,
        ...filters,
        channel,
      });
    },
    [person, filters],
  );

  useEffect(() => {
    if (person?.id) {
      historyControl.start({
        personId: person.id,
        ...filters,
      });
    }
  }, [person]);

  return (
    <HistoryContainer direction="column" pv={32} pr={4} gap={32}>
      <Flex gap={12}>
        <Filter
          label="Filtrar por canal"
          options={channels}
          icon={<Timeline width={18} height={18} />}
          onChange={onChannelFilterChange}
          isDisabled={isLoading}
          singleSelect
        />

        <SelectDate
          icon={<Calendar width={18} height={18} />}
          minDate={moment().subtract(3, 'month').toDate()}
          maxDate={moment().toDate()}
          placeholder="Filtrar por data"
          onChange={onDateFilterChange}
          disabled={isLoading}
          selectRange
          asFilter
        />
      </Flex>

      <Flex direction="column" gap={20}>
        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <HistoryItem
              gap={16}
              ph={24}
              pv={24}
              direction="column"
              key={`shimmer_${index}`}
              className={`shimmer_${index}`}
              color="neutral.secondaryAlt"
            >
              <Flex gap={12}>
                <Shimmer width={170} height={24} />
                <Shimmer width={55} height={24} />
              </Flex>

              <Divider />

              <Flex gap={16} direction="column" align="stretch">
                <InfoGrid
                  loading
                  grid="14% 14% 14% 14% 14% 14%"
                  shimmerLength={6}
                  responsive={{ 1350: '30% 30% 30%', 1168: '48% 48%' }}
                  list={[]}
                />
              </Flex>
            </HistoryItem>
          ))}

        {!isLoading &&
          history?.map((item, index) => (
            <HistoryItem
              gap={16}
              ph={24}
              pv={24}
              key={index}
              direction="column"
              className="history-item"
              color={HistoryChannelColor[item.channel]}
            >
              <Flex gap={12}>
                <Tag small rounded bgColor={bgTagColor} textColor={textTagColor}>
                  {item.event}
                </Tag>

                {item?.source && (
                  <Tag small rounded bgColor={bgTagColor} textColor={textTagColor}>
                    {item?.source}
                  </Tag>
                )}
              </Flex>

              <Divider />

              <Flex gap={16} direction="column" align="stretch">
                <InfoGrid
                  loading={isLoading}
                  grid="70px clamp(150px,20px,150px) clamp(50%,20px,150px)"
                  shimmerLength={6}
                  responsive={{ 1350: '30% 30% 30%', 1168: '48% 48%' }}
                  list={infoGridInfo(item)}
                />
              </Flex>

              {item.channel === 'APP' && (
                <Alert status="info" fullWidth>
                  A interação refere-se a jornada do cliente dentro do aplicativo
                </Alert>
              )}
            </HistoryItem>
          ))}

        {!isLoading && !history?.length && (
          <Flex justify="center" align="center" direction="column" gap={16}>
            <Typography type="caption" element="span" data-testid="no-history-message">
              Nenhum registro encontrado
            </Typography>
          </Flex>
        )}
      </Flex>
    </HistoryContainer>
  );
}
