import styled from 'styled-components';

type ImageContainerProps = {
  selected: boolean;
  hide?: boolean;
};

export const ImageContainer = styled.div<ImageContainerProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  position: relative;
  height: 90vh;
  border-radius: 12px;
  animation-name: ${({ hide }) => (hide ? 'hide' : 'show')};
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  @keyframes show {
    from {
      scale: 0;
    }
    to {
      scale: 1;
    }
  }

  @keyframes hide {
    from {
      scale: 1;
    }
    to {
      scale: 0;
    }
  }

  img {
    border-radius: 8px;
  }

  &:hover {
    z-index: 9;
  }

  transition: all 0.2s ease-in-out;

  * {
    transition: all 0.2s ease-in-out;
  }
`;

export const ControlBar = styled.div`
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: absolute;
  display: none;

  ${ImageContainer}:hover & {
    display: flex;
  }
`;

type ImgProps = {
  zoom?: number;
  rotation?: number;
  invert?: { vertical?: boolean; horizontal?: boolean };
};

export const Img = styled.img<ImgProps>`
  scale: ${({ zoom }) => zoom};
  transform: rotate(${({ rotation }) => rotation}deg)
    scaleX(${({ invert }) => (invert?.horizontal ? -1 : 1)})
    scaleY(${({ invert }) => (invert?.vertical ? -1 : 1)});
`;
