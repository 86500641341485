import React, { forwardRef, useCallback, useRef } from 'react';

import { CaretDown, Loading } from '~/ui/assets/icons';
import { CascaderProps } from 'antd/lib/cascader';
import { createPortal } from 'react-dom';
import includesStringSearch from '~/common/includesStringSearch';
import colors from '~/ui/theme/colors';
import {
  CustomComponentsAreaProps,
  PortalSelect,
  SelectLabel,
  StyledCascader,
} from './Select.styled';

type SelectProps = Omit<CascaderProps, 'options'> & {
  options: any[];
  label?: string;
  loading?: boolean;
  backgroundColor?: string;
  popupPlacement?: 'left' | 'right';
};

const Portal = (props: CustomComponentsAreaProps) =>
  createPortal(
    <PortalSelect id="mt-select-portal" {...props} />,
    document.getElementById('portal'),
  );

export const Select = forwardRef(
  ({ label, loading, popupPlacement, placeholder, ...props }: SelectProps, ref) => {
    const selectRef = useRef(ref);

    function filter(inputValue, path) {
      const labelField = props.fieldNames?.label || 'label';
      return path.some((option) => includesStringSearch(option[labelField], inputValue));
    }

    const getPopupContainer = () => document.getElementById('mt-select-portal');

    const getElementSize = useCallback(
      (r) => r?.current?.input?.input?.parentElement.clientWidth || 'auto',
      [],
    );

    return (
      <>
        <SelectLabel>{label || placeholder}</SelectLabel>
        <StyledCascader
          ref={selectRef}
          notFoundContent="Nenhum resultado encontrado"
          size="large"
          suffixIcon={loading ? <Loading fill={colors.gray['800']} /> : <CaretDown />}
          showSearch={{ filter }}
          allowClear
          disabled={loading}
          {...props}
          label={null}
          placeholder={null}
          getPopupContainer={getPopupContainer}
          popupClassName={['mt-select-dropdown', props.popupClassName, popupPlacement]
            .filter((e) => e)
            .join(' ')}
        />

        <Portal width={getElementSize(selectRef)} />
      </>
    );
  },
);
