import styled, { css } from 'styled-components';
import { ColorsTypes } from '~/ui/theme/colors';
import get from 'lodash/get';

export type Sizes = 'sm' | 'md' | 'lg';

export interface StyledButtonProps {
  size?: Sizes;
  disabled?: boolean;
  iconOnly?: boolean;
  color?: ColorsTypes | 'black';
  customColor?: string;
  variant?: 'contained' | 'outline' | 'text';
  invert?: boolean;
  underline?: boolean;
  rounded?: boolean;
  fullWidth?: boolean;
}

const colorCSS = (theme, color, variant, custom) => {
  const defaultColor = color || 'primary';
  const customColor = get(theme.colors, custom) || custom;
  const darkColor = customColor || theme.colors[defaultColor]['700'];
  const mediumColor = customColor || theme.colors[defaultColor]['600'];
  const normalColor = customColor || theme.colors[defaultColor]['400'];
  const lightColor = theme.colors.white['50'];

  const variants = {
    contained: css`
      background-color: ${darkColor};
      color: ${lightColor};

      .typography {
        color: ${lightColor};
      }

      &:hover {
        background-color: ${normalColor};
        opacity: ${customColor ? 0.85 : 1};
      }

      &:active {
        background-color: ${mediumColor};
        opacity: 1;
      }

      svg {
        color: ${lightColor};
      }
    `,
    outline: css`
      background-color: transparent;
      color: ${darkColor};
      border: 1px solid ${darkColor};

      .typography {
        color: ${darkColor};
      }

      svg {
        color: ${darkColor};
      }

      &:hover {
        border: 1px solid ${normalColor};
        color: ${normalColor};
        opacity: ${customColor ? 0.85 : 1};

        svg {
          color: ${normalColor};
        }
      }

      &:active {
        border: 1px solid ${mediumColor};
        color: ${mediumColor};
        opacity: 1;

        svg {
          color: ${mediumColor};
        }
      }
    `,
    text: css`
      background-color: transparent;
      color: ${darkColor};

      .typography {
        color: ${darkColor};
      }

      svg {
        color: ${darkColor};
      }

      &:hover {
        color: ${normalColor};
        opacity: ${customColor ? 0.85 : 1};

        svg {
          color: ${normalColor};
        }
      }

      &:active {
        color: ${mediumColor};
        opacity: 1;

        svg {
          color: ${mediumColor};
        }
      }
    `,
  };

  return variants[variant || 'contained'];
};

const disabledCSS = (disabled, variant, customColor) => {
  const defaultCss = css`
    opacity: 0.8;
    pointer-events: none;
    color: ${({ theme }) => (customColor ? theme.colors.white.main : theme.colors.gray['300'])};

    // svg {
    //   color: ${({ theme }) =>
      customColor ? theme.colors.white.main : theme.colors.gray['300']};
    // }
  `;

  const variants = {
    contained: css`
      background-color: ${({ theme }) => theme.colors.gray['100']};
      color: ${({ theme }) => theme.colors.gray['300']};

      .typography {
        color: ${({ theme }) => theme.colors.gray['300']};
      }

      svg {
        color: ${({ theme }) => theme.colors.gray['300']};
      }
    `,
    outline: css`
      border: 1px solid ${({ theme }) => theme.colors.gray['300']};
      color: ${({ theme }) => theme.colors.gray['300']};

      .typography {
        color: ${({ theme }) => theme.colors.gray['300']};
      }

      svg {
        color: ${({ theme }) => theme.colors.gray['300']};
      }
    `,
    text: css`
      color: ${({ theme }) => theme.colors.gray['300']};

      .typography {
        color: ${({ theme }) => theme.colors.gray['300']};
      }

      svg {
        color: ${({ theme }) => theme.colors.gray['300']};
      }
    `,
  };

  return (
    disabled &&
    css`
      ${defaultCss}
      ${variants[variant || 'contained']}
    `
  );
};

const sizeCSS = (size, iconOnly) => {
  const sizes = {
    sm: css`
      padding: ${({ rounded }) => (rounded ? '12px 32px' : '0 8px')};
      height: 36px;
      min-width: 36px;
      svg {
        width: 20px;
        height: 20px;
      }

      ${iconOnly &&
      css`
        max-width: 36px;
        padding: 0;
      `}
    `,
    md: css`
      padding: ${({ rounded }) => (rounded ? '12px 32px' : '0 12px')};
      height: 48px;
      min-width: 48px;
      svg {
        width: 24px;
        height: 24px;
      }

      ${iconOnly &&
      css`
        max-width: 48px;
        padding: 0;
      `}
    `,
    lg: css`
      padding: ${({ rounded }) => (rounded ? '12px 32px' : '0 12px')};
      height: 56px;
      min-width: 56px;
      svg {
        width: 28px;
        height: 28px;
      }

      ${iconOnly &&
      css`
        max-width: 56px;
        padding: 0;
      `}
    `,
  };

  return sizes[size || 'md'];
};

export const StyledButton = styled.button<StyledButtonProps>`
  border: none;
  border-radius: ${({ rounded }) => (rounded ? 16 : 4)}px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  outline: 0;

  ${({ theme, size, color, disabled, iconOnly, invert, variant, customColor }) => css`
    flex-direction: ${invert ? 'row-reverse' : 'row'};

    ${sizeCSS(size, iconOnly)}
    ${colorCSS(theme, color, variant, customColor)}
    ${disabledCSS(disabled, variant, customColor)}
  `}
`;

export const CircleLoading = styled.div`
  width: 18px;
  height: 18px;

  @keyframes loadingCircle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: loadingCircle 1s linear infinite;

  svg {
    width: 18px;
    height: 18px;
  }
`;
