import React, { useMemo } from 'react';
import { Steps } from '~/ui/components';
import { CreateTicket } from '~/typings/entities/Ticket';

import { TicketForm } from './TicketForm/TicketForm';
import { TicketResume } from './TicketResume/TicketResume';

export type TicketData = {
  ticket: CreateTicket;
};

interface ITicketModalProps {
  isUpdating?: boolean;
  ticket?: CreateTicket;
}

export function TicketModal({ isUpdating, ticket }: ITicketModalProps) {
  const [data, setData] = React.useState<TicketData>({ ticket });

  const steps = useMemo(
    () => [
      {
        id: 'TICKET_FORM',
        component: (
          <TicketForm
            title="Registre seu ticket"
            setData={setData}
            data={data}
            isUpdating={isUpdating}
          />
        ),
      },
      {
        id: 'TICKET_RESUME',
        component: <TicketResume buttonText="Registrar" data={data} isUpdating={isUpdating} />,
      },
    ],
    [isUpdating, setData, data],
  );

  return <Steps steps={steps} />;
}
