import React from 'react';
import { connect } from 'react-redux';
import { OldActions } from '~/components';
import { Popconfirm } from 'antd';
import { useAwaitControl } from 'react-redux-await-control';
import { eraseAgreementPassword } from '~/store/agreements/actions';

function InvalidateAgreementPassword({ title, personId }) {
  const eraseAgreementPasswordControl = useAwaitControl(eraseAgreementPassword);

  const onPressInvalidateAgreementPassword = () => {
    eraseAgreementPasswordControl.start({ id: personId });
  };

  return (
    <Popconfirm
      title="Deseja realmente invalidar a senha?"
      placement="left"
      cancelText="Não"
      okText="Sim"
      onConfirm={onPressInvalidateAgreementPassword}
    >
      <OldActions key="button" title={title || 'Invalidar Senha do Convênio'} />
    </Popconfirm>
  );
}

export default connect()(InvalidateAgreementPassword);
