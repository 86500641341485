import {
  call, put, takeEvery, delay, race, take, debounce,
} from 'redux-saga/effects';
import AsyncActionControl from 'react-redux-await-control/dist/AsyncActionControl';

type RequestConfig = {
  retry?: number;
  type?: 'debounce' | 'default';
  debounceTime?: number;
  retryDelay?: number;
};

const RETRY_DELAY = 1_500;

export default function takeRequest(asyncAction: AsyncActionControl, config: RequestConfig = {}) {
  const {
    retry = 3, retryDelay = RETRY_DELAY, type = 'default', debounceTime = 300,
  } = config;

  function* request({ payload, meta = {} }) {
    const executor = asyncAction.getContext('request');
    const validator = asyncAction.getContext('resultValidator') || ((r) => !!r);
    const beforeRequest = asyncAction.getContext('beforeRequest');

    const additionalPayload = asyncAction.getContext('additionalPayload') || {};
    const additionalMeta = asyncAction.getContext('additionalMeta') || {};

    const onSuccess = asyncAction.getContext('onRequestSuccess');
    const onError = asyncAction.getContext('onRequestError');
    let responseParser = asyncAction.getContext('responseParser');
    let errorParser = asyncAction.getContext('errorParser');

    const customPayload = typeof payload === 'object' ? { ...payload, ...additionalPayload } : payload;
    const customMeta = typeof meta === 'object' ? { ...meta, ...additionalMeta, payload } : meta;

    if (beforeRequest) {
      beforeRequest();
    }

    if (!responseParser) {
      responseParser = (response) => response;
    }

    if (!errorParser) {
      errorParser = (error) => error;
    }

    let attempts = 0;
    let result = null;

    while (retry > attempts && !validator(result)) {
      try {
        result = yield call(executor, customPayload);
        if (!validator(result)) {
          attempts += 1;
          yield delay(retryDelay);
        }

        if (attempts === retry || validator(result)) {
          const parsedResult = responseParser(result, customPayload, customMeta);
          yield put(asyncAction.success(parsedResult, customMeta));
          if (onSuccess) {
            yield call(onSuccess, parsedResult, customPayload, customMeta);
          }
        }
      } catch (e) {
        const statusCode = e.response?.status;

        if (statusCode?.toString().startsWith('5')) {
          attempts += 1;
          yield delay(retryDelay);
        } else {
          attempts = retry;
        }

        if (attempts === retry) {
          yield put(asyncAction.failure(errorParser(e), { ...meta, statusCode }));
          if (onError) {
            yield call(onError, e, payload, meta);
          }
        }
      }
    }
  }

  function* sagaControl(...args) {
    yield race({
      task: call(request as any, ...args),
      cancel: take(asyncAction.cancel.toString()),
    });
  }

  if (type === 'debounce') {
    return debounce(debounceTime, asyncAction.start.toString(), sagaControl);
  }

  return takeEvery(asyncAction.start.toString(), sagaControl);
}
