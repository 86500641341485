import React, { useCallback } from 'react';
import { FixedSizeList } from 'react-window';

import classnames from 'classnames';

export default function VirtualizedBody({
  children,
  className,
  height,
  width,
  ...rest
}) {
  const RenderRow = useCallback(
    ({ index, style }) => {
      return <div style={style}>{children[0][index]}</div>;
    },
    [children],
  );

  return (
    <div className={classnames('rt-table', className)} role='grid' {...rest}>
      <FixedSizeList
        height={height}
        itemCount={children[0].length}
        itemSize={35}
        width={width}
      >
        {RenderRow}
      </FixedSizeList>
    </div>
  );
}
