import moment from 'moment';

export default function toAge(date: string) {
  const years = moment().diff(date, 'years', false);
  const months = moment().diff(date, 'month', false) % 12;

  let ageText = `${years} anos`;

  if (months) {
    ageText += ` e ${months} meses`;
  }

  return ageText;
}
