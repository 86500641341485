import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import {
  checkUserContractQueueStatus,
  clearQueue,
  deactivateUserContractQueueStatus,
  getUserActiveToQueue,
  listQueueContracts,
} from '~/store/contracts/actions';
import { getLoggedUser } from '~/store/user/actions';

export interface ContractDetailsContextProps {
  isDistributionActive: boolean;
  toggleDistributionStatus: () => void;
  contract: null | string;
  setContractType: (contractType: null | string) => void;
  hourFilter: null | string;
  setHourConfig: (hourFilter: null | string) => void;
  queueFilter: null | string;
  setQueueConfig: (queueFilter: null | string) => void;
  isInitialStatus: boolean;
  filterNotSelected: boolean;
  filterSelected: boolean;
  allStepsConcluded: boolean;
}

interface ContractDetailsContextProviderProps {
  children: React.ReactNode;
}

export const ContractDetailsContext = createContext({} as ContractDetailsContextProps);

export function ContractDetailsContextProvider({ children }: ContractDetailsContextProviderProps) {
  const [distributionStatus, setDistributionStatus] = useState(false);
  const [contract, setContract] = useState(null);
  const [hourFilter, setHourFilter] = useState(null);
  const [queueFilter, setQueueFilter] = useState(null);

  const [
    getLoggedUserControl,
    getUserActiveToQueueControl,
    checkUserContractQueueStatusControl,
    deactivateUserContractQueueStatusControl,
    clearQueueControl,
    listQueueContractsControl,
  ] = useAwaitControl([
    getLoggedUser,
    getUserActiveToQueue,
    checkUserContractQueueStatus,
    deactivateUserContractQueueStatus,
    clearQueue,
    listQueueContracts,
  ]);

  const userId = getLoggedUserControl.result()?.id;
  const userActiveToQueue = getUserActiveToQueueControl.result();

  const isInitialStatus = useMemo(
    () => !distributionStatus && hourFilter === null,
    [distributionStatus, hourFilter],
  );

  const filterSelected = useMemo(
    () => distributionStatus && typeof hourFilter === 'string',
    [distributionStatus, hourFilter],
  );

  const filterNotSelected = useMemo(
    () => distributionStatus && !hourFilter,
    [distributionStatus, hourFilter],
  );

  const allStepsConcluded = useMemo(
    () => distributionStatus && contract && hourFilter,
    [distributionStatus, contract, hourFilter],
  );

  const clear = () => {
    clearQueueControl.start();
    listQueueContractsControl.clear();
  };

  const toggleDistributionStatus = useCallback(() => {
    setDistributionStatus((isRadioChecked) => !isRadioChecked);
    setContract(null);
    setHourFilter(null);
    setQueueFilter(null);
    clear();

    if (allStepsConcluded) {
      deactivateUserContractQueueStatusControl.start({
        userId,
      });
    }
  }, [userId, allStepsConcluded]);

  const setContractType = useCallback(
    (contractType: null | string) => {
      setContract(contractType);
    },
    [contract],
  );

  const setHourConfig = useCallback(
    (hourFilterType: null | string) => {
      setHourFilter(hourFilterType);
    },
    [hourFilter],
  );

  const setQueueConfig = useCallback(
    (queueFilterType: null | string) => {
      setQueueFilter(queueFilterType);
    },
    [queueFilter],
  );

  const contextValue = useMemo(
    () => ({
      isDistributionActive: distributionStatus,
      toggleDistributionStatus,
      contract,
      setContractType,
      hourFilter,
      setHourConfig,
      allStepsConcluded,
      isInitialStatus,
      filterSelected,
      filterNotSelected,
      queueFilter,
      setQueueConfig,
    }),
    [distributionStatus, contract, hourFilter, queueFilter],
  );

  useEffect(() => {
    if (userId) {
      checkUserContractQueueStatusControl.start({ userId });
      getUserActiveToQueueControl.start({ userId });
    }
  }, [userId]);

  useEffect(() => {
    setDistributionStatus(userActiveToQueue?.queueStatus);
    setContract(userActiveToQueue?.queueStatus);
    setHourFilter(userActiveToQueue?.type);
    setQueueFilter(userActiveToQueue?.kycStatus);
  }, [userId, userActiveToQueue]);

  return (
    <ContractDetailsContext.Provider value={contextValue}>
      {children}
    </ContractDetailsContext.Provider>
  );
}
