import { useEffect } from 'react';

type LocalStorageChangeHandlerProps = { key: string; newValue: any };
type LocalStorageChangeHandler = (kayAndValue: LocalStorageChangeHandlerProps) => void;

const useLocalStorageObserver = (key: string, onChange: LocalStorageChangeHandler) => {
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        try {
          const newValue = JSON.parse(event.newValue || '');
          onChange?.({ key, newValue });
        } catch (error) {
          onChange?.({ key, newValue: event.newValue || '' });
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);
};

export default useLocalStorageObserver;
