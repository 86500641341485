import axios, { AxiosInstance } from 'axios';

type CreateHttpInstance = AxiosInstance & {
  reloadToken?: (token: string) => void;
  reloadBaseUrl?: () => void;
  getBaseWithoutPath?: () => string;
};

type Config = {
  disableCors?: boolean;
  path?: string;
};

const getStoredEndpoint = (name: string) => {
  const storedEndpoints = localStorage.getItem('@endpoints');

  try {
    const values = JSON.parse(storedEndpoints || '');
    return values?.[name];
  } catch (error) {
    return null;
  }
};

const getBaseUrl = (name: string, path?: string) => {
  const ENV_API_URL = process.env[`REACT_APP_BASE_URL_${name.toUpperCase()}`];
  const DEV_API_URL = getStoredEndpoint(name);

  return `${DEV_API_URL || ENV_API_URL}${path || ''}`;
};

export function createHttpInstance(name: string, config?: Config): CreateHttpInstance {
  const http: CreateHttpInstance = axios.create({
    baseURL: getBaseUrl(name, config?.path),
  });

  http.defaults.headers.common['Content-Type'] = 'application/json';

  if (!config?.disableCors) {
    http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    http.defaults.headers.common['Access-Control-Allow-Credentials'] = false;
  }

  http.reloadToken = (token: string) => {
    http.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  http.reloadBaseUrl = () => {
    http.defaults.baseURL = getBaseUrl(name, config?.path);
  };

  http.getBaseWithoutPath = () => getBaseUrl(name);

  http.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('authenticationToken')}`;
      return config;
    },
    (error) => Promise.reject(error),
  );

  return http;
}

export function reloadAllBaseUrls(services: any) {
  Object.keys(services).map((key) => services[key].reloadBaseUrl());
}

export function reloadAllTokens(services: any, token: string) {
  Object.keys(services).map((key) => services[key].reloadToken(token));
}
