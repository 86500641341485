/* @flow */
import { message } from '~/common';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export function success(payload) {
  return dispatch => {
    message.success('Usuário criado com sucesso!');
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    message.error('Falha ao tentar criar usuário!');
    dispatch({
      type: CREATE_USER_FAILURE,
      payload: resource,
    });
  };
}

export default function create(payload) {
  return dispatch => {
    dispatch({
      type: CREATE_USER_REQUEST,
      payload,
    });
  };
}
