export default function style({ unit }) {
  const fontFamily = 'Rubik, sans-serif';

  return {
    action: {
      cursor: 'pointer',
      fontFamily,
      lineHeight: 1.2,
      padding: `${unit * 1.5}px 0 0 0`,

      ':hover': {
        textDecoration: 'underline',
      },
    },
  };
}
