import 'antd/dist/antd.css';

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Redirect, Route } from 'react-router';
import { ThemeProvider } from '~/hooks/useTheme';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import 'moment/locale/pt-br';

import GlobalStyle from '~/ui/theme/global';
import configurations from '~/store/config';
import { ContractDetailsContextProvider } from '~/contexts/ContractDetailsContext';
import Login from '~/screens/Login/Login';

import { configureStore } from '~/store';

moment.locale('pt-br');

export const isProduction = () => configurations.endpoint.includes('prod');
export const store = configureStore();

const App = React.lazy(() => import('~/App'));
function DynamicApp() {
  return (
    <Suspense fallback={<div />}>
      <App />
    </Suspense>
  );
}

function MainApp() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <Provider store={store}>
          <ContractDetailsContextProvider>
            <ConfigProvider locale={ptBR}>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/backoffice" component={DynamicApp} />
                <Route path="/" component={() => <Redirect to="/backoffice/people" />} />
              </Switch>
              <GlobalStyle />
            </ConfigProvider>
          </ContractDetailsContextProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(MainApp);
