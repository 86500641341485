import React, { useContext, useState } from 'react';
import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';
import { parseContractQueueStatus } from '~/typings/enums/ContractQueueStatus';
import ProductFilter from './ProductFilter';
import {
  ConfigContent,
  ConfigLabel,
  ConfigTitle,
  FilterConfigContainer,
  LabelContainer,
  LabelDisabledTag,
  LabelSuccessTag,
  LabelTag,
  Popover,
} from './styled';

export function ProductConfig() {
  const { contract, hourFilter, allStepsConcluded, isDistributionActive, queueFilter } =
    useContext(ContractDetailsContext);
  const [visible, setVisible] = useState(false);

  const IS_ABBREVIATED = true;
  const abbreviatedContract = parseContractQueueStatus(contract, IS_ABBREVIATED);

  const closePopover = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    if (isDistributionActive && !allStepsConcluded) {
      setVisible(visible);
    }
  };

  return (
    <Popover
      placement="bottomLeft"
      content={<ProductFilter closePopover={closePopover} />}
      trigger="click"
      visible={hourFilter && !allStepsConcluded && visible}
      onVisibleChange={handleVisibleChange}
    >
      <ConfigContent>
        <FilterConfigContainer disabled={isDistributionActive && allStepsConcluded}>
          <LabelContainer>
            <ConfigTitle enabled={hourFilter}>Filtro de produto</ConfigTitle>

            {!isDistributionActive && <LabelDisabledTag>Ative seu status</LabelDisabledTag>}
            {isDistributionActive && !hourFilter && (
              <LabelDisabledTag>Selecione o horário</LabelDisabledTag>
            )}
            {hourFilter && !contract && <LabelTag>Selecione o produto</LabelTag>}
            {contract && <LabelSuccessTag>{abbreviatedContract}</LabelSuccessTag>}
          </LabelContainer>
          <ConfigLabel enabled={hourFilter}>Escolha qual tipo de produto para sua fila</ConfigLabel>
        </FilterConfigContainer>
      </ConfigContent>
    </Popover>
  );
}
