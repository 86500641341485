import downloadPdfFile from '~/components/DownloadPDFFile';
import getConcession, {
  CONCESSION_GET_FAILURE,
  CONCESSION_GET_SUCCESS,
} from '~/store/getConcession/action';

export default downloadPdfFile({
  method: getConcession,
  title: 'Abrir concessão',
  stateName: 'getConcession',
  successType: CONCESSION_GET_SUCCESS,
  errorType: CONCESSION_GET_FAILURE,
});
