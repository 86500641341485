import React, { useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';
import { message } from 'antd';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import moment from 'moment';
import { isEmpty } from 'lodash';

import screenRegister from '~/hoc/screenRegister';
import { Content } from '~/ui/components';
import { listPeople } from '~/store/people/actions';

import { Filters } from './Filters/Filters';
import { PersonsTable } from './PersonsTable/PersonsTable';

function Persons() {
  const history = useHistory();
  const listPeopleControl = useAwaitControl(listPeople);
  const loading = listPeopleControl.isRunning();
  const { entries } = listPeopleControl.result();
  const params = queryString.parse(history.location.search);

  const formatDate = (date) => {
    if (!date) return undefined;

    moment.locale('en');
    return moment(date).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  };

  const setQueryParams = (params) => {
    const { pathname } = history.location;
    const newSearch = queryString.stringify(params);
    history.replace({ pathname, search: newSearch });
  };

  const handleOnSubmit = (values) => {
    const hasSomeValue = Object.values(values).some((el) => el);
    const { dateRange, document, ...restValues } = values;

    const parsedValues = {
      ...restValues,
      document: document?.replace(/\D/g, ''),
      startDate: formatDate(dateRange?.[0]),
      endDate: formatDate(dateRange?.[1]),
    };

    if (hasSomeValue) {
      listPeopleControl.start(parsedValues);
      setQueryParams(parsedValues);
    } else {
      message.warning('É necessário preencher pelo menos um dos filtros');
    }
  };

  useEffect(() => {
    if (!isEmpty(params)) {
      listPeopleControl.start(params);
    }

    return () => listPeopleControl.clear();
  }, []);

  return (
    <Content>
      <Filters onSubmit={handleOnSubmit} loading={loading} />
      <PersonsTable data={entries} />
    </Content>
  );
}

export default screenRegister({
  screenName: 'Persons',
  headerTitle: 'Pessoas',
  path: '/backoffice/people',
  customLayout: true,
})(Persons);
