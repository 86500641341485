/* @flow */

export const RESET_PERSON_REQUEST = 'RESET_PERSON_REQUEST';
export const RESET_PERSON_SUCCESS = 'RESET_PERSON_SUCCESS';
export const RESET_PERSON_FAILURE = 'RESET_PERSON_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: RESET_PERSON_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: RESET_PERSON_FAILURE,
      payload: resource,
    });
  };
}

export default function resetPerson(personId, password) {
  return dispatch => {
    dispatch({
      type: RESET_PERSON_REQUEST,
      payload: {
        id: personId,
        password,
      },
    });
  };
}
