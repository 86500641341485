import styled from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Tag, Typography } from '~/ui/components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
  padding: 24px 0;
`;

export const Title = styled(Typography).attrs(({ theme }) => ({
  type: 'headingXSmall',
  weight: 500,
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
}))``;

export const Text = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: theme.colors.element.secondary,
}))`
  .editor-preview {
    margin-top: 6px;
  }
`;

export const TagText = styled(Tag).attrs(({ theme }) => ({
  bgColor: theme.colors.secondary[50],
  textColor: theme.colors.primary[800],
  small: true,
  rounded: true,
  noWrap: true,
}))`
  width: max-content;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
`;

export const DateTimeViewer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: underline;
  background: ${({ theme }) => theme.colors.gray[50]};
  padding: 14px 18px;
  border-radius: 8px;
  font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
  font-size: ${({ theme }) => theme.typography.types.caption.size};
  line-height: ${({ theme }) => theme.typography.types.caption.lineHeight};
  color: ${({ theme }) => theme.colors.gray[900]};
  font-weight: 500;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.success[500]};
  }

  span {
    color: ${({ theme }) => theme.colors.success[500]};
  }

  .ant-divider {
    margin: 0;
    background: ${({ theme }) => theme.colors.gray[900]};
  }
`;

export const ContractList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    flex: 1 1 45%;
  }
`;
