import React, { useCallback, useContext, useEffect } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import AskContracts from '~/screens/DistributedContracts/AskContracts';
import {
  clearQueue,
  getContractQueueInformation,
  listQueueContracts,
} from '~/store/contracts/actions';
import useModals from '~/hooks/useModals';

import { ContractDetailsContext } from '~/contexts/ContractDetailsContext';
import { Button } from '../styled';

interface QueueInfoProps {
  getNewContracts: (data: object) => void;
  loadQueueInformations: () => void;
}

export function QueueInfo({ getNewContracts, loadQueueInformations }: QueueInfoProps) {
  const { openModal, closeModal } = useModals() as any;
  const { hourFilter, isDistributionActive } = useContext(ContractDetailsContext);
  const close = closeModal?.();

  const [getContractQueueInformationControl, clearQueueControl, listQueueContractsControl] =
    useAwaitControl([getContractQueueInformation, clearQueue, listQueueContracts]);
  const clearQueueLoading = clearQueueControl.isRunning();

  const queues = getContractQueueInformationControl.result()?.queues || [];

  const askContracts = useCallback(
    ({ contractQueueStatus, requestedNumber }) => {
      close?.();
      getNewContracts({ contractQueueStatus, requestedNumber });
    },
    [close],
  );

  const handleClearQueue = useCallback(() => {
    clearQueueControl.start();
    listQueueContractsControl.clear();
    loadQueueInformations();
  }, [hourFilter]);

  const handleOpenModal = useCallback(() => {
    getContractQueueInformationControl.start();

    openModal(AskContracts.alias, {
      submit: askContracts,
      queues,
    })();
  }, [queues, askContracts]);

  useEffect(() => {
    getContractQueueInformationControl.start();
  }, []);

  return (
    <>
      <div>
        <Button onClick={handleClearQueue} loading={clearQueueLoading} type="primary" ghost>
          Limpar
        </Button>
        <Button disabled={isDistributionActive} onClick={() => handleOpenModal()}>
          Pedir contrato
        </Button>
      </div>
    </>
  );
}
