import AppStage from '~/typings/enums/AppStage';

export default (() => {
  let developerTestUrl;
  const currentAppStage = process.env.REACT_APP_STAGE;
  const storedEndpoints = localStorage.getItem('@endpoints');

  const config = {
    stage: AppStage.ALL.includes(currentAppStage) ? currentAppStage : AppStage.DEVELOPMENT,
    webApp: process.env.REACT_APP_WEB_APP || 'https://web.meutudo.app',
  };

  try {
    const parsedEndpoints = JSON.parse(storedEndpoints);
    developerTestUrl = parsedEndpoints?.telli;
  } catch (error) {
    developerTestUrl = null;
  }

  if (developerTestUrl) {
    return { ...config, endpoint: `${developerTestUrl}/tudo/v2` };
  }

  return {
    ...config,
    endpoint: `${process.env.REACT_APP_BASE_URL_TELLI}/tudo/v2`,
  };
})();
