import React, { ReactNode } from 'react';
import { BackButton, CloseButton, DrawerHeaderContainer, HeaderTitle } from './Drawer.styled';

type DrawerHeaderProps = {
  title?: ReactNode;
  closable?: boolean;
  onClose?: () => void;
  extraAction?: ReactNode;
  backButton?: boolean;
  onBackClick?: () => void;
};

export function DrawerHeader({
  title,
  closable,
  onClose,
  extraAction,
  backButton,
  onBackClick,
}: DrawerHeaderProps) {
  return (
    <DrawerHeaderContainer>
      {backButton && <BackButton onClick={onBackClick} />}
      <HeaderTitle>{title}</HeaderTitle>
      {extraAction}
      {closable && <CloseButton onClick={onClose} />}
    </DrawerHeaderContainer>
  );
}
