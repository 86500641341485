const UFs = [
  {
    name: 'AC',
    value: 'AC',
  },
  {
    name: 'AL',
    value: 'AL',
  },
  {
    name: 'AP',
    value: 'AP',
  },
  {
    name: 'AM',
    value: 'AM',
  },
  {
    name: 'BA',
    value: 'BA',
  },
  {
    name: 'CE',
    value: 'CE',
  },
  {
    name: 'DF',
    value: 'DF',
  },
  {
    name: 'ES',
    value: 'ES',
  },
  {
    name: 'GO',
    value: 'GO',
  },
  {
    name: 'MA',
    value: 'MA',
  },
  {
    name: 'MT',
    value: 'MT',
  },
  {
    name: 'MS',
    value: 'MS',
  },
  {
    name: 'MG',
    value: 'MG',
  },
  {
    name: 'PA',
    value: 'PA',
  },
  {
    name: 'PB',
    value: 'PB',
  },
  {
    name: 'PR',
    value: 'PR',
  },
  {
    name: 'PE',
    value: 'PE',
  },
  {
    name: 'PI',
    value: 'PI',
  },
  {
    name: 'RJ',
    value: 'RJ',
  },
  {
    name: 'RN',
    value: 'RN',
  },
  {
    name: 'RS',
    value: 'RS',
  },
  {
    name: 'RO',
    value: 'RO',
  },
  {
    name: 'RR',
    value: 'RR',
  },
  {
    name: 'SC',
    value: 'SC',
  },
  {
    name: 'SP',
    value: 'SP',
  },
  {
    name: 'SE',
    value: 'SE',
  },
  {
    name: 'TO',
    value: 'TO',
  },
];

export default UFs;
