import React, { forwardRef, InputHTMLAttributes, useEffect, useRef } from 'react';

import { FilePlus } from '~/ui/assets/icons';
import toHumanFileSize from '~/common/toHumanFileSize';

import {
  Text,
  Wrapper,
  FileItem,
  FileInfo,
  TrashButton,
  FileName,
  FileSize,
} from './FileUploader.styled';

export type FileUploaderProps = Partial<InputHTMLAttributes<HTMLInputElement>> & {
  onChange?: (value: any) => void;
  disabled?: boolean;
  value?: any;
}; // TODO: adicionar propriedade para múltiplos arquivos

export const FileUploader = forwardRef(
  ({ value = '', onChange, disabled, ...props }: FileUploaderProps, ref) => {
    const inputRef = useRef<any>(ref);
    const [isDragOver, setIsDragOver] = React.useState(false);
    const [file, setFile] = React.useState(value);

    const processFiles = (files) => {
      // TODO: aplicar validações se multiplos arquivos
      setFile(files?.[0]);
      onChange?.(files?.[0]);
    };

    const handleFileUpload = (event) => {
      processFiles(event.target.files);
    };

    const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragOver(true);
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragOver(false);
    };

    const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragOver(false);

      processFiles(event.dataTransfer.files);
    };

    const handleClick = () => {
      inputRef?.current?.click();
    };

    const handleRemoveFile = () => {
      setFile(null);
      onChange?.('');
    };

    useEffect(() => {
      setFile(value);
    }, [value]);

    if (file) {
      return (
        <FileItem>
          <FilePlus className="file-plus" />
          <FileInfo>
            <FileName>{file.name}</FileName>
            <FileSize>{toHumanFileSize(file.size, true)}</FileSize>
          </FileInfo>
          <TrashButton onClick={handleRemoveFile} />
        </FileItem>
      );
    }

    return (
      <Wrapper
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        isDragOver={isDragOver}
        disabled={disabled}
      >
        <input
          ref={inputRef}
          value={value}
          type="file"
          hidden
          {...props}
          onChange={handleFileUpload}
        />
        <Text>
          <b>Clique</b> ou arraste um arquivo aqui
        </Text>
      </Wrapper>
    );
  },
);
