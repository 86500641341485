import React from 'react';
import { useTheme } from 'styled-components';
import moment from 'moment';

import { Typography } from '~/ui/components';
import LogoSmall from '~/ui/assets/imgs/Tponto.svg';
import { themeToggleColor } from '~/common/utils/theme';
import { useAwaitControl } from 'react-redux-await-control';
import { TransactionGroup } from '~/typings/entities/Wallet';
import { Money, formatCPF, formatName } from '~/common';
import { getPersonDetail } from '~/store/people/actions';
import { Divider, InfoContent, Wrapper, Section, Header } from './Download.styled';

type DownloadProps = {
  transaction: TransactionGroup;
};

function Download({ transaction }: DownloadProps) {
  const theme = useTheme();

  const personControl = useAwaitControl(getPersonDetail);
  const person = personControl.result();

  return (
    <Wrapper>
      <Header>
        <LogoSmall />
        <Typography type="bodyLarge" element="h6" weight={700}>
          Pix enviado com sucesso!
        </Typography>
        <Typography
          type="subtitleLarge"
          weight={700}
          color={themeToggleColor(theme, 'brand.primary')}
        >
          {Money(transaction?.cashIn?.amount)}
        </Typography>
        <Typography
          type="paragraphSmall"
          weight={600}
          color={themeToggleColor(theme, 'element.secondary')}
        >
          {moment(transaction?.cashOut?.date).format('DD [de] MMMM YYYY, [às] HH:mm:ss')}
        </Typography>
      </Header>

      <Section>
        <Typography type="bodyLarge" weight={700}>
          Quem solicitou o Pix
        </Typography>
        <InfoContent>
          <Typography type="caption">Nome</Typography>
          <Typography type="paragraphSmall" weight={700}>
            {person?.name ? formatName(person?.name) : '-'}
          </Typography>
          <Divider />
          <Typography type="caption">CPF</Typography>
          <Typography type="paragraphSmall" weight={700}>
            {formatCPF(person?.document) ?? '-'}
          </Typography>
        </InfoContent>
      </Section>

      <Section>
        <Typography type="bodyLarge" weight={700}>
          Quem enviou
        </Typography>
        <InfoContent>
          <Typography type="caption">Pagador</Typography>
          <Typography type="paragraphSmall" weight={700}>
            Tudo Serviços LTDA.
          </Typography>
          <Divider />
          <Typography type="caption">CNPJ</Typography>
          <Typography type="paragraphSmall" weight={700}>
            85.245.348/0001-32
          </Typography>
        </InfoContent>
      </Section>

      <Section>
        <Typography type="bodyLarge" weight={700}>
          Quem recebeu
        </Typography>
        <InfoContent>
          <Typography type="caption">Nome</Typography>
          <Typography type="paragraphSmall" weight={700}>
            {transaction?.cashOut?.recipientName}
          </Typography>
          <Divider />
          <Typography type="caption">CPF</Typography>
          <Typography type="paragraphSmall" weight={700}>
            {formatCPF(transaction?.cashOut?.recipientDocument) || '-'}
          </Typography>
          <Divider />
          <Typography type="caption">Chave Pix</Typography>
          <Typography type="paragraphSmall" weight={700}>
            {transaction.cashIn?.pixKey || '-'}
          </Typography>
          <Divider />
          <Typography type="caption">Instituição bancária recebedora</Typography>
          <Typography type="paragraphSmall" weight={700}>
            {transaction?.cashOut?.recipientInstitution || '-'}
          </Typography>
        </InfoContent>
      </Section>

      <Section>
        <Typography type="bodyLarge" weight={700}>
          Dados da transação
        </Typography>
        <InfoContent>
          <Typography type="caption">ID da transação Pix</Typography>
          <Typography type="paragraphSmall" weight={700}>
            {transaction?.cashOut?.pixTransactionId || '-'}
          </Typography>
          <Divider />
          <Typography type="caption">Instituição bancária pagadora</Typography>
          <Typography type="paragraphSmall" weight={700}>
            {transaction?.cashOut?.payingInstitution || '-'}
          </Typography>
        </InfoContent>
      </Section>
    </Wrapper>
  );
}

export default Download;
