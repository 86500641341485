import _ from 'lodash';
import { ClearSaleScoreItem } from '~/typings/entities/person';
import moment from 'moment';

export enum ScoreSteps {
  initial = 'initial',
  emailValidated = 'emailValidated',
  emailNotValidated = 'emailNotValidated',
  smsValidated = 'smsValidated',
  smsNotValidated = 'smsNotValidated',
  change = 'change',
  tokenSentSMS = 'tokenSentSMS',
  tokenSentEmail = 'tokenSentEmail',
  tokenSentBoth = 'tokenSentBoth',
}

export type ScoreHistoryItem = {
  date?: string;
  score: number;
  step: ScoreSteps;
};

const checkSendTokenStep = (score: ClearSaleScoreItem) => {
  if (score.emailSentDate && score.smsSentDate) {
    return ScoreSteps.tokenSentBoth;
  }
  if (score.emailSentDate) {
    return ScoreSteps.tokenSentEmail;
  }
  if (score.smsSentDate) {
    return ScoreSteps.tokenSentSMS;
  }

  return null;
};

function detectScoreChanges(array: ClearSaleScoreItem[]) {
  const reversedArray = array.slice().reverse();
  return reversedArray.reduce((changes, current, index, arr) => {
    if (index > 0 && current.score !== arr[index - 1].score) {
      changes.push(current);
    }
    return changes;
  }, []);
}

function formatScores(scores: ClearSaleScoreItem[]) {
  const scoreHistory = [];

  // Token sent
  const tokenValidationsSent = scores
    .filter((el) => el.emailSentDate || el.smsSentDate)
    .map((el) => ({ date: el.emailSentDate || el.smsSentDate, step: checkSendTokenStep(el) }));
  const uniqueTokenValidationsSent = _.uniqBy(tokenValidationsSent, 'date');

  if (uniqueTokenValidationsSent) {
    scoreHistory.push(...uniqueTokenValidationsSent);
  }

  // Email validation
  const emailValidations = scores
    .filter((el) => el.validatedEmail !== null)
    .map((el) => ({
      date: el.validationDate,
      step: el.validatedEmail ? ScoreSteps.emailValidated : ScoreSteps.emailNotValidated,
    }));
  const uniqueEmailValidations = _.uniqBy(emailValidations, 'validationDate');

  if (uniqueEmailValidations) {
    scoreHistory.push(...uniqueEmailValidations);
  }

  // SMS validation
  const smsValidations = scores
    .filter((el) => el.validatedSms !== null)
    .map((el) => ({
      date: el.validationDate,
      step: el.validatedSms ? ScoreSteps.smsValidated : ScoreSteps.smsNotValidated,
    }));
  const uniqueSmsValidations = _.uniqBy(smsValidations, 'date');

  if (uniqueSmsValidations.length) {
    scoreHistory.push(...uniqueSmsValidations);
  }

  // Score changes
  const scoreChanges = detectScoreChanges(scores).map((el) => ({
    date: el.creationDate,
    score: el.score,
    step: ScoreSteps.change,
  }));

  if (scoreChanges.length) {
    scoreHistory.push(...scoreChanges);
  }

  const orderedHistory = _.orderBy(scoreHistory, (o) => moment(o.date), ['desc']);

  // Initial score
  const initial = scores[scores.length - 1];
  orderedHistory.push({
    score: initial.score,
    date: initial.creationDate,
    step: ScoreSteps.initial,
  });

  return orderedHistory;
}

export default formatScores;
