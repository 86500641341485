import React, { useMemo, useState } from 'react';
import { Popover } from 'antd';
import { ClixActions } from '~/components';
import { DotsButton, ContentWrapper, Wrapper } from './MobileActions.styled';
import { TicketButton } from '../TicketButton';

export function ClixMobileActions() {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const contentWrapper = useMemo(
    () => (
      <ContentWrapper>
        <ClixActions className="side-actions" onClick={handleClick} />
      </ContentWrapper>
    ),
    [],
  );

  return (
    <Wrapper>
      <TicketButton />
      <Popover
        visible={visible}
        content={contentWrapper}
        onVisibleChange={handleVisibleChange}
        overlayClassName="mt-custom-popover-no-padding"
        placement="bottomRight"
        trigger="click"
      >
        <DotsButton />
      </Popover>
    </Wrapper>
  );
}
