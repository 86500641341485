/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { mapObject } from 'underscore';
import moment from 'moment';

import { REGISTER_COMMISSIONING_REQUEST, success, failure } from './action';

function stripMoneyValue(value) {
  // Divide por 100 devido a retirada da virgula
  return (
    value
      .toString()
      .replace(/[.,\s]/g, '')
      .replace(/^0+/, '') / 100
  );
}

function stripPercentualValue(value) {
  // Alem do tratamento da virgula, o backend precisa do valor dividido por 100
  return stripMoneyValue(value) / 100;
}

const prepare = payload =>
  mapObject(payload, (value, key) => {
    if (key === 'startDate') return moment(value, 'DD/MM/YYYY');
    if (key === 'commissioningType') return value;
    if (key === 'name') return value;
    if (key === 'financialInstitution') return value;
    if (key === 'numberOfInstallments') return value;
    return stripPercentualValue(value);
  });

const registerCommissioning = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(REGISTER_COMMISSIONING_REQUEST)
    .map(({ payload }) => prepare(payload))
    .mergeMap(payload =>
      AjaxRequest.open(
        'post',
        '/admin/commissioningcustom/commissionings/register',
        {
          ...payload,
        },
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(registerCommissioning);
