/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import masked from '~/common/masked';

import { EnumMappers } from '~/common';

const { toDate, toPersonDocument } = masked;

const { ContractStatus, CreditCardStatus, LoanType, CreditCardType } = EnumMappers;

const LABELS = {
  id: 'COD',
  contractInitialDate: 'DATA INICIAL',
  consigneeName: 'BANCO',
  personName: 'CLIENTE',
  personDocument: 'CPF',
  operationStep: 'PASSO DA ESTEIRA',
  loanStatus: 'SITUAÇÃO',
  contractStatus: 'STATUS LÓGICO',
  contractValue: 'VALOR',
  installmentValue: 'VAL. PARCELA',
  numberOfInstallments: 'N° PARCELAS',
  loanType: 'TIPO',
  sentToConsignee: 'ENVIADO',
  marginSource: 'FONTE MARGEM',
  marginExpirationDate: 'VALIDADE DA MARGEM',
  creditCardStatus: 'SITUAÇÃO',
  creditCardType: 'CARTÃO',
  marginValueReserved: 'MARGERM RESERVADA',
  contractOwner: 'ATENDENTE',
  cashoutValue: 'VAL. SAQUE',
  netValue: 'VAL. LÍQUIDO',
  valueForDeposit: 'VAL. DEPÓSITO',
  financialInstitutionKey: 'Nº CONTRATO',
  financialInstitutionKeyPort: 'Nº CONTRATO REFIN PORT',
  paymentDate: 'DATA PAGAMENTO',
  paymentDateRefPort: 'DATA PAGAMENTO REFIN PORT',
  flatCommissioning: 'COMISSÃO FIXA',
  deferredCommissioning: 'COMISSÃO DIFERIDA',
  flatCommissioningRefinPortability: 'COMISSÃO FIXA PORT',
  deferredCommissioningRefinPortability: 'COMISSÃO DIFERIDA PORT',
  isPrime: 'CLIENTE PRIME',
};

function FormatValue(key, value) {
  let valueFormated = '';
  const valueUndefined = 'Não Informado';

  switch (key) {
    case 'contractInitialDate':
    case 'marginExpirationDate':
    case 'paymentDate':
    case 'paymentDateRefPort':
      valueFormated = value ? toDate(value) : '';
      break;
    case 'contractValue':
    case 'installmentValue':
    case 'marginValueReserved':
    case 'cashoutValue':
    case 'netValue':
    case 'valueForDeposit':
    case 'flatCommissioning':
    case 'deferredCommissioning':
    case 'flatCommissioningRefinPortability':
    case 'deferredCommissioningRefinPortability':
      valueFormated = `R$ ${(value || 0).toFixed(2)}`;
      break;
    case 'personDocument':
      valueFormated = toPersonDocument(value) || valueUndefined;
      break;
    case 'loanStatus':
      valueFormated = ContractStatus(value) || valueUndefined;
      break;
    case 'creditCardStatus':
      valueFormated = CreditCardStatus(value) || valueUndefined;
      break;
    case 'loanType':
      valueFormated = LoanType(value) || valueUndefined;
      break;
    case 'creditCardType':
      valueFormated = CreditCardType(value) || valueUndefined;
      break;
    case 'isPrime':
    case 'sentToConsignee':
      valueFormated = value ? 'Sim' : 'Não';
      break;
    default:
      valueFormated = value || '';
  }

  return valueFormated;
}

export default function JsonToCSVConvertor(
  JSONData,
  showLabel,
  labelsFilter = [],
  transcripts = LABELS,
  formatter = FormatValue,
) {
  const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

  let CSV = '';

  const attrs = Object.keys(arrData[0] || {}).filter((attr) => !labelsFilter.includes(attr));

  if (showLabel) {
    let rowLabels = '';
    for (const label of attrs) {
      rowLabels += transcripts[label] ? `${transcripts[label]};` : `${label};`;
    }

    CSV += `${rowLabels}\n`;
  }

  for (const obj of arrData) {
    let rowValues = '';

    for (const attr of attrs) {
      rowValues += `${formatter(attr, obj[attr])};`;
    }

    CSV += `${rowValues}\n`;
  }

  return `data:text/csv;charset=utf-8,${escape(CSV)}`;
}
