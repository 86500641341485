import React, { useEffect, useRef } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import pendencyTitle from '~/typings/enums/PendencyType';
import { getPersonDetail } from '~/store/people/actions';
import { Form, Input, Tag } from '~/ui/components';

import { solveAddress } from '~/store/pendencies/actions';
import { useModal } from '~/hooks/useModal';
import { getContractDetails } from '~/store/contracts/actions';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { fetchAddressFromZipCode } from '~/store/utilities/actions';
import { Container, CustomButton, InfoRow, InfoTitle, InfoValue } from '../Pendency.styled';

interface AddressProps {
  pendency?: any;
}

export function Address({ pendency }: AddressProps) {
  const form = useRef<WrappedFormUtils>();
  const { setLoading, closeModal } = useModal();
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const solveAddressControl = useAwaitControl(solveAddress);
  const getContractDetailsControl = useAwaitControl(getContractDetails);
  const getAddressFromZipCodeControl = useAwaitControl(fetchAddressFromZipCode);

  const solveAddressLoading = solveAddressControl.isRunning();
  const solveAddressSuccess = solveAddressControl.isSuccessful();

  const addressFromZipCodeLoading = getAddressFromZipCodeControl.isRunning();
  const addressFromZipCodeResult = getAddressFromZipCodeControl.result();
  const addressFromZipCodeSuccess = getAddressFromZipCodeControl.isSuccessful();
  const addressFromZipCodeError = getAddressFromZipCodeControl.hasFailure();

  const person = getPersonDetailsControl.result();
  const contract = getContractDetailsControl.result();

  const onSubmit = (values) => {
    const idKey = contract?.id ? 'contractId' : 'personId';
    const idValue = contract?.id || person?.id;
    const data = { ...values, complement: values.complement || '' };
    solveAddressControl.start({ id: pendency?.id, data, [idKey]: idValue });
  };

  const handleChangeCep = (zipCode) => {
    if (
      zipCode.length === 8 &&
      !addressFromZipCodeSuccess &&
      !addressFromZipCodeLoading &&
      !addressFromZipCodeError
    ) {
      getAddressFromZipCodeControl.start({ zipCode });
    } else if (zipCode.length < 8 && (addressFromZipCodeSuccess || addressFromZipCodeError)) {
      getAddressFromZipCodeControl.clear();
    }
  };

  useEffect(() => {
    if (addressFromZipCodeSuccess) {
      const { city, district, state, street } = addressFromZipCodeResult;
      form.current.setFieldsValue({
        city,
        district,
        state,
        street,
      });
    }
  }, [addressFromZipCodeSuccess]);

  useEffect(() => {
    if (addressFromZipCodeError) {
      form.current.resetFields(['city', 'district', 'state', 'street']);
    }
  }, [addressFromZipCodeError]);

  useEffect(() => {
    setLoading('pendency-details', solveAddressLoading);
  }, [solveAddressLoading]);

  useEffect(() => {
    if (solveAddressSuccess) {
      closeModal('pendency-details');
    }
  }, [solveAddressSuccess]);

  return (
    <Container width={550}>
      <Tag small rounded colorPalette="warning">
        Pendência aberta
      </Tag>

      <InfoRow>
        <InfoValue>Preencha o endereço do cliente para prosseguir.</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoTitle>Tipo da pendência</InfoTitle>
        <InfoValue>{pendencyTitle(pendency?.pendingType)}</InfoValue>
      </InfoRow>

      <Form
        ref={form}
        onSubmit={onSubmit}
        loading={addressFromZipCodeLoading ? 1 : 0}
        inputs={[
          {
            id: 'zipCode',
            label: 'CEP',
            width: '48%',
            input: (
              <Input
                placeholder="Informe o CEP"
                maxLength={8}
                onChange={handleChangeCep}
                number
                allowClear
              />
            ),
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'state',
            label: 'Estado',
            width: '48%',
            input: <Input placeholder="Informe o estado" allowClear />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'city',
            label: 'Cidade',
            width: '48%',
            input: <Input placeholder="Informe a cidade" allowClear />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'district',
            label: 'Bairro',
            width: '48%',
            input: <Input placeholder="Informe o bairro" allowClear />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'street',
            label: 'Logradouro',
            input: <Input placeholder="Informe o logradouro" allowClear />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'addressNumber',
            label: 'Número',
            width: '48%',
            input: <Input placeholder="Informe o número" allowClear />,
            options: { rules: [{ required: true, message: 'Este campo é obrigatório!' }] },
          },
          {
            id: 'complement',
            label: 'Complemento (opcional)',
            width: '48%',
            input: <Input placeholder="Informe o complemento" allowClear />,
          },
        ]}
      >
        <CustomButton rounded fullWidth type="submit">
          Confirmar resolução
        </CustomButton>
      </Form>
    </Container>
  );
}
