export default function style({ unit }) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: unit * 2,
    },

    container__timeline: {
      overflow: 'auto',
      maxHeight: '70vh',
      paddingTop: '16px',
      backgroundColor: 'fefefe',
    },

    item__status: {
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 0.5)',
    },
    empty: {
      textAlign: 'center',
      padding: unit * 2,
    },
  };
}
