import React, { useCallback, useState, useRef, InputHTMLAttributes, useEffect } from 'react';
import Check from '~/ui/assets/icons/Check.svg';
import colors, { ColorsTypes } from '~/ui/theme/colors';
import {
  Container,
  Label,
  InputRow,
  ErrorMessage,
  FakeInput,
  FakeInputContainer,
} from './Checkbox.styled';

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  name: string;
  error?: string;
  small?: boolean;
  defaultChecked?: boolean;
  colorPalette?: ColorsTypes;
  onChange?: (checked: boolean) => void;
  children?: React.ReactChildren | React.ReactChild;
  checked?: boolean;
  radio?: boolean;
};

export function Checkbox({
  children,
  onChange,
  name,
  value,
  disabled,
  error,
  small,
  defaultChecked,
  colorPalette = 'primary',
  checked,
  radio,
  ...rest
}: CheckboxProps) {
  const [isChecked, setChecked] = useState(checked || defaultChecked);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFakeClick = useCallback(() => {
    inputRef?.current?.click();
    setChecked(!isChecked);
  }, [isChecked, inputRef, setChecked, onChange]);

  const handleChange = useCallback(
    ({ target }: any) => {
      onChange?.(target.checked);
      setChecked(target.checked);
    },
    [onChange],
  );

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <Container className="checkbox" disabled={disabled}>
      <InputRow>
        <FakeInputContainer onClick={handleFakeClick} colorPalette={colorPalette} small={small}>
          <FakeInput
            checked={!!isChecked}
            colorPalette={colorPalette}
            small={small}
            disabled={disabled}
            radio={radio}
          >
            {isChecked ? (
              <Check
                width={radio ? 12 : 16}
                height={radio ? 12 : 16}
                fill={colors.gray[disabled ? '600' : '50']}
              />
            ) : null}
          </FakeInput>
        </FakeInputContainer>

        <input
          ref={inputRef}
          value={value}
          type="checkbox"
          defaultChecked={defaultChecked}
          id={name}
          checked={checked}
          {...rest}
          onChange={handleChange}
        />

        {children && (
          <Label colorPalette={colorPalette} small={small} key={name} onClick={handleFakeClick}>
            {children}
          </Label>
        )}
      </InputRow>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
}
