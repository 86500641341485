import styled, { css } from 'styled-components';
import { Typography } from '~/ui/components';
import { themeColor } from '~/common/utils/theme';

export interface LoadingProp {
  loading?: boolean | 1 | 0;
}

export const TableContainer = styled.table<LoadingProp>`
  width: 100%;

  ${({ loading }) =>
    loading &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const Thead = styled.thead`
  background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
  height: 34px;
  position: ${({ $isTHeadFixed }) => ($isTHeadFixed ? 'sticky' : 'none')};
  top: ${({ $isTHeadFixed }) => ($isTHeadFixed ? 0 : 'none')};
  z-index: ${({ $isTHeadFixed }) => ($isTHeadFixed ? 1 : 'none')};

  tr {
    th {
      font-size: ${({ theme }) => theme.typography.types.footnote.size};
      line-height: ${({ theme }) => theme.typography.types.footnote.lineHeight};
      font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
      font-weight: 500;
      color: ${themeColor('element.secondary', { dark: 'element.secondary' })};

      &:not(:last-child) {
        padding-right: 21px;
      }

      &.cursor-pointer {
        cursor: pointer;
      }

      .arrow {
        margin-left: 8px;
      }

      @media (max-width: 1280px) {
        font-size: ${({ theme }) => theme.typography.types.footnote.size};

        &:not(:last-child) {
          padding-right: 16px;
        }
      }
    }
  }
`;

type TbodyProps = {
  rowHeight?: number;
  clickable?: boolean;
};

export const Tbody = styled.tbody<TbodyProps>`
  tr {
    border-bottom: 1px ${({ $withoutBorders }) => ($withoutBorders ? 'none' : 'dashed')}
      ${({ theme }) => theme.colors.white['100']};
    height: ${({ rowHeight }) => rowHeight || 76}px;
    background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};

    .td-sub-rows {
      padding-right: 16px !important;
      button {
        cursor: pointer !important;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    ${({ clickable }) =>
      clickable &&
      css`
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      `}

    td {
      font-size: ${({ theme }) => theme.typography.types.caption.size};
      line-height: ${({ theme }) => theme.typography.types.caption.lineHeight};
      font-family: ${({ theme }) => theme.typography.fonts.libreFranklin};
      font-weight: 400;
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};

      &:not(:last-child) {
        padding-right: 21px;
      }

      &.td-sub-rows {
        padding-right: 8px;
        width: 40px;
        padding-left: 8px;
      }

      @media (max-width: 1280px) {
        font-size: ${({ theme }) => theme.typography.types.caption.size};

        &:not(:last-child) {
          padding-right: 16px;
        }
      }
    }

    &.sub-row {
      background-color: ${({ theme }) => theme.colors.background.tertiary};
    }
  }
`;

export const NotFoundMessage = styled.p`
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  background: ${themeColor('background.primary', { dark: 'background.secondary' })};
  gap: 8px;
`;

export type AlignType = 'left' | 'center' | 'right';

export type CellProps = {
  align?: AlignType;
  minSize?: number;
  maxSize?: number;
  ellipsis?: boolean;
};

export const Cell = styled.div<CellProps>`
  display: flex;
  justify-content: ${({ align }) => align || 'left'};
  align-items: center;
  gap: 8px;

  min-width: ${({ minSize }) => minSize || 0}px;
  max-width: ${({ maxSize }) => maxSize || 0}px;

  .depth-icon {
    color: #2f2ee6;
    width: 20px;
    height: 20px;
  }

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    `}
`;

export const TableWrapper = styled.div.attrs({
  className: 'table-wrapper',
})`
  padding: ${({ noPadding }) => (noPadding ? '24px 0' : '24px 28px')};
  background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
  max-height: ${({ $maxHeight }) => `${$maxHeight}px`};
  overflow: auto;
`;

export const ToggleButton = styled.button`
  height: 24px;
  min-width: 40px;
  width: 40px;
  background: #d6e4ff;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #0100b2;
    width: 12px;
    height: 12px;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 32px;
  padding: 16px 8px;
`;

export const RespCol = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RespHeader = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: theme.colors.element.secondary,
}))``;

export const RespValue = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  element: 'span',
  color: theme.colors.element.primary,
  weight: 600,
}))`
  min-height: 48px;
  display: flex;
  align-items: center;
`;
