/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { omit } from 'underscore';

import { RESET_PERSON_REQUEST, success, failure } from './action';

const events = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(RESET_PERSON_REQUEST)
    .map(({ payload }) => payload)
    .mergeMap(payload =>
      AjaxRequest.open(
        'post',
        `/admin/personcustom/people/${payload.id}/reset`,
        {
          ...omit(payload, 'id'),
        },
      )('tudo')
        .flatMap(({ response }) => Observable.of(success(response.resource)))
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(events);
