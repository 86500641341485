/* @flow */

export const REGISTER_COMMISSIONING_REQUEST = 'REGISTER_COMMISSIONING_REQUEST';
export const REGISTER_COMMISSIONING_SUCCESS = 'REGISTER_COMMISSIONING_SUCCESS';
export const REGISTER_COMMISSIONING_FAILURE = 'REGISTER_COMMISSIONING_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: REGISTER_COMMISSIONING_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: REGISTER_COMMISSIONING_FAILURE,
      payload: resource,
    });
  };
}

export default function registerCommissioning(payload) {
  return dispatch => {
    dispatch({
      type: REGISTER_COMMISSIONING_REQUEST,
      payload,
    });
  };
}
