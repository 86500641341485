import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import capitalize from 'lodash/capitalize';

import { GetCustumerAge } from '~/common';
import type { Person } from '~/typings/entities/person';
import Section from '~/components/Section';
import Tags from '~/components/Tags';
import UpdateDates from '~/components/UpdateDates';

import { Wrapper, PersonPresentationContainer, Content, TagsWrapper } from './styled';

type PresentationProps = {
  person: Person;
};

export default function Presentation({ person }: PresentationProps) {
  const name = useMemo(
    () => (person?.name || '').split(' ').map(capitalize).join(' '),
    [person?.name],
  );

  const { monthsLife, yearsLife } = useMemo(
    () => (person?.birthDate ? GetCustumerAge(person?.birthDate) : {}),
    [person?.birthDate],
  );

  return (
    <Wrapper>
      <Section>
        <Section.Content>
          {person && (
            <Content>
              <div>
                <PersonPresentationContainer>
                  <Link to={`/backoffice/people/${person?.id}`}>{name}</Link>
                  <span>{`${yearsLife} anos e ${monthsLife} meses`}</span>
                </PersonPresentationContainer>
              </div>
              <UpdateDates
                portabilityUpdateDate={person?.portabilityUpdateDate}
                marginUpdateDate={person?.marginUpdateDate}
                benefitStartDate={person?.medicalExpertise?.benefitStartDate}
                medicalCertificateDate={person?.medicalExpertise?.medicalCertificateDate}
              />
            </Content>
          )}
        </Section.Content>
        <Section.Divider />
        <Section.Content>
          <TagsWrapper>
            <Tags externalId={person?.id} type="PERSON" allowsToAdd={false} waitExternalId />
          </TagsWrapper>
        </Section.Content>
      </Section>
    </Wrapper>
  );
}
