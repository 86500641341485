export default function(agency) {
  if (agency && agency.length < 4) {
    let formattedAgency = agency;
    for (let i = 0; i < 4 - agency.length; i += 1) {
      formattedAgency = `0${formattedAgency}`;
    }
    return formattedAgency;
  }
  return agency;
}
