/* @flow */

export const UPDATE_LOAN_HISTORY_REQUEST = 'UPDATE_LOAN_HISTORY_REQUEST';
export const UPDATE_LOAN_HISTORY_SUCCESS = 'UPDATE_LOAN_HISTORY_SUCCESS';
export const UPDATE_LOAN_HISTORY_FAILURE = 'UPDATE_LOAN_HISTORY_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAN_HISTORY_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAN_HISTORY_FAILURE,
      payload: resource,
    });
  };
}

export default function updateLoanHistory(params) {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAN_HISTORY_REQUEST,
      payload: params,
    });
  };
}
