import { Map } from 'immutable';

import {
  SOLVE_DOCUMENT_REQUEST,
  SOLVE_DOCUMENT_FAILURE,
  SOLVE_DOCUMENT_SUCCESS,
} from './action';

const initialState = Map({
  type: '',
  payload: [],
});

function reducer(state = initialState, action) {
  const { type } = action;

  if (type === SOLVE_DOCUMENT_REQUEST) {
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => true)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], []);
  }

  if (type === SOLVE_DOCUMENT_FAILURE) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => false)
      .setIn(['payload'], payload);
  }

  if (type === SOLVE_DOCUMENT_SUCCESS) {
    const { payload } = action;
    return state
      .updateIn(['type'], () => type)
      .updateIn(['isLoading'], () => false)
      .updateIn(['isLoaded'], () => true)
      .setIn(['payload'], payload);
  }

  return state;
}

export default reducer;
