import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useAwaitControl } from 'react-redux-await-control';

import { Alert } from 'antd';
import { Box } from 'reflexbox';

import { checkContractInConference } from '~/store/contracts/actions';

import Timer from './Timer';

import { FlexWrapper } from './ContractDetails.Styles';

type ConferenceData = {
  inConference: boolean;
  user: any;
  contractQueue: any;
};

type ConferenceProps = {
  conferenceData: ConferenceData;
  loggedUser: any;
};

export default function Conference({ conferenceData, loggedUser }: ConferenceProps) {
  const { inConference, user, contractQueue } = conferenceData;

  const { id } = useParams<{ id: string }>();

  const checkControl = useAwaitControl(checkContractInConference);

  const onFinishTime = useCallback(() => {
    checkControl.start({ id });
  }, [id]);

  const conferencingByCurrentUser = useMemo(() => user?.id === loggedUser?.id, [user, loggedUser]);

  return (
    <FlexWrapper wrap align="flex-start">
      {inConference && conferencingByCurrentUser && (
        <Box w={3 / 4} style={{ marginBottom: '20px' }}>
          <Alert
            message="Em conferência"
            description="Esse contrato pertence a sua fila de conferência!"
            type="info"
            showIcon
          />
        </Box>
      )}
      {inConference && !conferencingByCurrentUser && (
        <Box w={3 / 4} style={{ marginBottom: '20px' }}>
          <Alert
            message="Em conferência"
            description={
              <span>
                Esse contrato está sendo conferido por <b>{`${user?.name}`}</b>.
              </span>
            }
            type="warning"
            showIcon
          />
        </Box>
      )}
      {inConference && (
        <Box w={1 / 4}>
          <Timer endTime={contractQueue?.endDate} onFinish={onFinishTime} />
        </Box>
      )}
    </FlexWrapper>
  );
}
