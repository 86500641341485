export default {
  rectangle: {
    active: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    hover: '0px 8px 15px rgba(0, 0, 0, 0.25)',
    pressed: '0px 12px 20px rgba(0, 0, 0, 0.25)',
    dialog: '0px 12px 24px rgba(0, 0, 0, 0.25)',
  },
  ellipsis: {
    active: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    hover: '0px 6px 16px rgba(0, 0, 0, 0.25)',
    pressed: '0px 10px 24px rgba(0, 0, 0, 0.25)',
  },
};
