// @flow

import React, { Component } from 'react';
import { isUndefined, mapObject } from 'underscore';
import fetchRefinancedContracts from '~/store/fetchRefinancedContracts/action';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import theme from '~/themes/aphroditeTheme/theme';
import { message } from '~/common';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import {
  SET_REFINANCED_AKEY_FAILURE,
  SET_REFINANCED_AKEY_SUCCESS,
} from '~/store/setRefinancedAkey/action';
import updateContract, {
  UPDATE_REFINANCED_CONTRACT_FAILURE,
  UPDATE_REFINANCED_CONTRACT_SUCCESS,
} from '~/store/updateRefinancedContract/action';
import styles from '~/screens/ContractDetails/Panels/RefinancedContracts/styles';
import { getContractDetails } from '~/store/contracts/actions';
import RefinancedContracts from './RefinancedContracts';

const { withStyles } = theme;

type RefinancedContractsProps = {
  refinancedContracts: any,
  contractDetailsLoading: boolean,
  contractDetails: any,
  setRefinancedAkey: any,
};

class RefinancedContractsContainer extends Component<RefinancedContractsProps> {
  componentDidMount() {
    this.requestRefinancedContract();
  }

  componentDidUpdate(prevProps) {
    const {
      setRefinancedAkey,
      match,
      dispatch,
      updateRefinContract,
    } = this.props;

    if (
      Immutable.is(prevProps.setRefinancedAkey, setRefinancedAkey) === false
    ) {
      switch (setRefinancedAkey.getIn(['type'])) {
        case SET_REFINANCED_AKEY_SUCCESS: {
          const contractId = match.params.id;
          dispatch(fetchRefinancedContracts(contractId), {});

          message.success('Chave de Reserva Vinculada com Sucesso');
          this.child.onPressClose();
          break;
        }
        case SET_REFINANCED_AKEY_FAILURE: {
          this.child.openModal();
          message.error('Erro ao Vincular Chave de Reserva');
          break;
        }
      }
    }

    if (
      Immutable.is(prevProps.updateRefinContract, updateRefinContract) === false
    ) {
      switch (updateRefinContract.getIn(['type'])) {
        case UPDATE_REFINANCED_CONTRACT_SUCCESS: {
          this.requestRefinancedContract();
          message.success('Contrato atualizado');
          this.child.onPressClose();
          break;
        }
        case UPDATE_REFINANCED_CONTRACT_FAILURE: {
          message.error('Erro ao atualizar contrato');
          break;
        }
      }
    }
  }

  requestRefinancedContract() {
    const contractId = this.props.match.params.id;
    const params = new URLSearchParams(this.props.location.search);

    mapObject(params, (value, key) => {
      if (isUndefined(value) || value === '') return params.delete(key);
      return params.set(key, value);
    });

    this.props.dispatch(fetchRefinancedContracts(contractId, params));
  }

  onRefinancedContractUpdated = (fields, refinancedContractId) => {
    const contractId = this.props.match.params.id;
    this.props.dispatch(
      updateContract({ fields, contractId, refinancedContractId }),
    );
  };

  render() {
    const isLoading = this.props.refinancedContracts.getIn(['isLoading'])
      || this.props.contractDetailsLoading
      || this.props.setRefinancedAkey.getIn(['isLoading']);

    return (
      <RefinancedContracts
        ref={(ref) => {
          this.child = ref;
        }}
        styles={this.props.styles}
        contracts={this.props.refinancedContracts.getIn(['payload'])?.entries}
        contractDetails={this.props.contractDetails}
        isLoading={isLoading}
        onRefinancedContractUpdated={this.onRefinancedContractUpdated}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    refinancedContracts: state.getIn(['fetchRefinancedContracts']),
    setRefinancedAkey: state.getIn(['setRefinancedAkey']),
    contractDetails: getContractDetails.getResult()(state),
    contractDetailsLoading: getContractDetails.isRunning()(state),
    updateRefinContract: state.getIn(['updateRefinancedContract']),
  };
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
)(RefinancedContractsContainer);
