import { css } from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export default css`
  .mt-custom-popover {
    .ant-popover-inner {
      box-shadow: 0 12px 24px 0 ${themeColor('divider.primary', { dark: 'background.tertiary' })};
      background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
    }

    .ant-popover-inner-content {
      border-radius: 8px;
      background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
    }

    .ant-popover-inner {
      border-radius: 16px;
    }

    .ant-popover-content {
      .ant-popover-arrow {
        border-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
      }
    }
  }

  .mt-custom-popover-no-padding {
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .mt-custom-popover-no-arrow {
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
    }
  }
`;
