/* @flow */
import React, { Component } from 'react';
import { Button, Input, List } from 'antd';
import { Flex, Box } from 'reflexbox';
import theme from '~/themes/aphroditeTheme/theme';

import { Spinner, ContractComment, EmptyPanel } from '~/components';
import { PanelTypes, PanelKindTypes } from '~/components/EmptyPanel/EmptyPanel';

const { css } = theme;

type Props = {
  styles: any,
};

class Comments extends Component<void, Props> {
  constructor(props) {
    super(props);
    this.state = {
      forcedForm: false,
    };
  }

  forceFormVisibility = () => {
    this.setState(({ forcedForm }) => ({ forcedForm: !forcedForm }));
  };

  handleSubmit = () => {
    const { comment, setComment, onPressSendComment } = this.props;
    onPressSendComment(comment);
    setComment('');
  };

  renderFormComment() {
    const { styles, comment, setComment } = this.props;
    return (
      <div>
        <div {...css(styles.label)}>Comentar:</div>
        <Input.TextArea
          rows={3}
          value={comment}
          placeholder="Escreva um comentário para esse contrato"
          onChange={(ev) => setComment(ev.target.value)}
        />
        <Button
          type="primary"
          disabled={!comment}
          onClick={this.handleSubmit}
          {...css(styles.button)}
        >
          Enviar
        </Button>
      </div>
    );
  }

  static renderComment = (item) => <ContractComment key={item.commentaryId} comment={item} />;

  renderCommentsList = () => {
    const { styles, comments, isLoadingComments } = this.props;
    if (isLoadingComments) return null;

    return (
      <div {...css(styles.container__list)}>
        {comments?.length ? (
          <List dataSource={comments} renderItem={Comments.renderComment} />
        ) : (
          <div {...css(styles.empty)}>
            <i>Não há comentários para este contrato</i>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { styles, isLoadingComments, comments } = this.props;

    return (
      <div {...css(styles.container)}>
        <Spinner spinning={isLoadingComments} />
        <Flex wrap w={1} justify="center">
          {comments?.length || this.state.forcedForm ? (
            <>
              <Box my={1} w={3 / 3}>
                {this.renderFormComment()}
              </Box>
              <Box my={1} w={3 / 3}>
                {this.renderCommentsList()}
              </Box>
            </>
          ) : (
            <EmptyPanel
              btnAction={this.forceFormVisibility}
              panel={PanelTypes.CONTRACT}
              kind={PanelKindTypes.CONTRACT_COMMENT}
            />
          )}
        </Flex>
      </div>
    );
  }
}

export default Comments;
