import React from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';

export default function updatePendencies(reducer, successCheck) {
  type Props = {
    getPendencies: () => void,
    check: any,
  };

  return (WrappedComponent) => {
    class PendenciesUpdater extends React.Component<Props> {
      componentDidUpdate({ check: checked }): void {
        const { check } = this.props;
        if (!Immutable.is(checked, check)) {
          if (check.getIn(['type']) === successCheck) {
            this.props.getPendencies();
          }
        }
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    function mapStateToProps(state) {
      return {
        check: state.getIn([`${reducer}`]),
      };
    }

    return connect(mapStateToProps)(PendenciesUpdater);
  };
}
