import { UploadProps } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { cancelPreProposal } from '~/store/contracts/actions';
import { Alert, Button } from '~/ui/components';
import {
  AlertWrapper,
  Caption,
  Dragger,
  DraggerClickLabel,
  DraggerContainer,
  DraggerLabel,
  ErrorMessage,
  Footer,
  FooterDivider,
  Info,
} from './CancelProposalModal.styled';

function Error() {
  return (
    <ErrorMessage status="error">
      Não conseguimos processar a seu cancelamento, <b>por favor tente novamente.</b>
    </ErrorMessage>
  );
}

export default function CancelProposalModal() {
  const [fileList, setFileList] = useState<UploadProps['fileList']>([]);

  const cancelPreProposalControl = useAwaitControl(cancelPreProposal);

  const cancelPreProposalSuccessfull = cancelPreProposalControl.isSuccessful();
  const cancelPreProposalFailed = cancelPreProposalControl.hasFailure();
  const isRunning = cancelPreProposalControl.isRunning();

  const handleProposalCancel = () => {
    cancelPreProposalControl.start(fileList);
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  useEffect(
    () => () => {
      if (isRunning) {
        cancelPreProposalControl.cancel();
      }

      cancelPreProposalControl.clear();
      setFileList([]);
    },
    [],
  );

  return (
    <DraggerContainer>
      <Info>
        Para prosseguir com o cancelamento é necessário subir um arquivo no formato CSV. Você só
        pode realizar um cancelamento por vez.
      </Info>
      <Dragger {...props}>
        <DraggerLabel>
          <DraggerClickLabel>Clique para subir </DraggerClickLabel>
          ou arraste para aqui
        </DraggerLabel>
        <Caption>Tamanho máximo de 45MB.</Caption>
      </Dragger>
      {cancelPreProposalSuccessfull && (
        <AlertWrapper>
          <Alert label="Cancelamento realizado com sucesso." />
        </AlertWrapper>
      )}
      {!cancelPreProposalSuccessfull && cancelPreProposalFailed && (
        <AlertWrapper>
          <Alert renderValue={<Error />} status="error" />
        </AlertWrapper>
      )}
      <FooterDivider />
      <Footer>
        <Button
          size="sm"
          rounded
          title="Confirmar cancelamento"
          disabled={fileList.length === 0 || isRunning}
          fullWidth
          onClick={handleProposalCancel}
          loading={!cancelPreProposalSuccessfull && isRunning}
        />
      </Footer>
    </DraggerContainer>
  );
}
