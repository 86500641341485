import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import pendencyTitle from '~/typings/enums/PendencyType';
import { Select, Tag } from '~/ui/components';
import { FileItem, FileUpload } from '~/screens/Person/components';

import { solveDocument } from '~/store/pendencies/actions';
import { useModal } from '~/hooks/useModal';
import { getPersonDetail } from '~/store/people/actions';
import { getContractDetails } from '~/store/contracts/actions';
import {
  Container,
  CustomButton,
  DocumentsList,
  InfoRow,
  InfoTitle,
  InfoValue,
} from '../Pendency.styled';

const options = [
  { value: 'cnh', label: 'CNH' },
  { value: 'rg-and-cpf', label: 'RG e CPF' },
  { value: 'rg-with-cpf', label: 'RG contendo CPF' },
];

const documents = {
  cnh: [
    { name: 'CNH Frente', id: 'cnhFront' },
    { name: 'CNH Verso', id: 'cnhBack' },
  ],
  'rg-and-cpf': [
    { name: 'RG Frente', id: 'rgFront' },
    { name: 'RG Verso', id: 'rgBack' },
    { name: 'CPF', id: 'cpf' },
  ],
  'rg-with-cpf': [
    { name: 'RG Frente', id: 'rgFront' },
    { name: 'RG Verso', id: 'rgBack' },
  ],
};

type PictureDocumentProps = {
  pendency?: any;
};

export function PictureDocument({ pendency }: PictureDocumentProps) {
  const [documentType, setDocumentType] = useState<string>();
  const [files, setFiles] = useState<FileItem[]>([]);

  const { closeModal, setLoading } = useModal();

  const solveDocumentControl = useAwaitControl(solveDocument);
  const getPersonDetailControl = useAwaitControl(getPersonDetail);
  const getContractDetailsControl = useAwaitControl(getContractDetails);

  const loadingSolveDocument = solveDocumentControl.isRunning();
  const solveDocumentSuccess = solveDocumentControl.isSuccessful();

  const person = getPersonDetailControl.result();
  const contract = getContractDetailsControl.result();

  const handleTypeChange = useCallback((value: string[]) => {
    setDocumentType(value?.[0]);
    setFiles([]);
  }, []);

  const handleFileRemoved = useCallback((file: FileItem) => {
    setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.id !== file.id));
  }, []);

  const handleFileChange = useCallback((file: FileItem) => {
    setFiles((prevFiles) => {
      const index = prevFiles?.findIndex((prevFile) => prevFile.id === file.id);
      if (index !== -1) {
        prevFiles[index] = file;
        return [...prevFiles];
      }
      return [...prevFiles, file];
    });
  }, []);

  const isValid = useMemo(() => {
    const requiredFiles = documents[documentType];
    return (
      documentType &&
      requiredFiles.every((requiredFile) => {
        const file = files.find((file) => file.id === requiredFile.id);
        return !!file?.file;
      })
    );
  }, [documentType, files, documents]);

  const handleSubmit = useCallback(() => {
    const idKey = contract?.id ? 'contractId' : 'personId';
    const idValue = contract?.id || person?.id;

    solveDocumentControl.start({
      id: pendency?.id,
      files,
      [idKey]: idValue,
    });
  }, [person, files, solveDocumentControl, contract, pendency]);

  useEffect(() => {
    setLoading('pendency-details', loadingSolveDocument);
  }, [loadingSolveDocument]);

  useEffect(() => {
    if (solveDocumentSuccess) {
      closeModal('pendency-details');
    }

    return () => solveDocumentControl.clear();
  }, [solveDocumentSuccess]);

  return (
    <Container>
      <Tag small rounded colorPalette="warning">
        Pendência aberta
      </Tag>

      <InfoRow>
        <InfoValue>Reenvie o documento do cliente para prosseguir.</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoTitle>Tipo da pendência</InfoTitle>
        <InfoValue>{pendencyTitle(pendency?.pendingType)}</InfoValue>
      </InfoRow>

      <InfoRow>
        <InfoTitle>Escolha qual documento enviar</InfoTitle>
        <Select options={options} onChange={handleTypeChange} />
      </InfoRow>

      {documentType && (
        <InfoRow>
          <DocumentsList>
            {documents[documentType].map((document) => (
              <FileUpload
                key={document.id}
                onChange={handleFileChange}
                onRemove={handleFileRemoved}
                name={document.name}
                id={document.id}
                accept="image/jpeg,image/png,image/webp"
              />
            ))}
          </DocumentsList>
        </InfoRow>
      )}

      <CustomButton
        loading={loadingSolveDocument}
        rounded
        fullWidth
        disabled={!isValid}
        onClick={handleSubmit}
      >
        Confirmar resolução
      </CustomButton>
    </Container>
  );
}
