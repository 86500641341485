// @flow

import React from 'react';
import DefaultFile from '~/screens/ContractDetails/Panels/Pendencies/components/actions/DefaultFile';

type DocumentsProps = {
  onSendFile: () => void,
  id?: number,
  type?: string,
};

function Paycheck(props: DocumentsProps) {
  return <DefaultFile {...props} />;
}

export default Paycheck;
