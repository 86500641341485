import styled from 'styled-components';
import { themeColor, themeToggleValue } from '~/common/utils/theme';

export const Content = styled.div`
  padding: 16px 30px;
  align-items: flex-end;

  .extra-content {
    justify-content: flex-end;
    display: flex;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  background-color: ${({ theme }) =>
    themeToggleValue(theme, '#e5eef6', { dark: theme.colors.background.primary })};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  color: ${themeColor('element.primary', { dark: 'element.secondary' })};
  gap: 8px;
`;
