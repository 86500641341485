import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ theme }) => theme.unit * 2}px;
  position: relative;
  display: flex;
  flex-Direction: row;
`;

type TabPanelProps = React.PropsWithChildren<{}>;

export default function TabPanel({ children, ...rest }: TabPanelProps) {
  return <Container {...rest}>{ children }</Container>;
}
