import React from 'react';
import { reloadAllBaseUrls } from '~/common/utils/http';
import * as services from '~/services';
import { SERVICES_ENVIRONMENT_OPTIONS } from '~/components';
import { Tooltip } from '~/ui/components';
import { EnvironmentInfoText } from './EnvironmentInfo.styled';

type EnvironmentInfoProps = {
  removeButton?: boolean;
};

export function EnvironmentInfo({ removeButton }: EnvironmentInfoProps) {
  const endpoints = localStorage.getItem('@endpoints');
  const environment = localStorage.getItem('@environment');

  const handleReset = () => {
    localStorage.removeItem('@endpoints');
    localStorage.removeItem('@environment');
    reloadAllBaseUrls(services);
    setTimeout(() => window.location.reload(), 200);
  };

  const getEnvironmentName = (name: string) =>
    SERVICES_ENVIRONMENT_OPTIONS.find((option) => option.value === name)?.label?.toUpperCase();

  const renderServices = () => {
    let services;

    try {
      services = JSON.parse(endpoints);
    } catch (e) {
      services = null;
    }

    if (!services) return null;

    return (
      <div>
        {Object.entries(services).map(([key, value]) => (
          <p key={key}>
            <b>{key.toUpperCase()}</b>: {value}
          </p>
        ))}
      </div>
    );
  };

  if (!endpoints) return null;

  return (
    <Tooltip title="Seviços atuais" content={renderServices()} placement="topRight">
      <EnvironmentInfoText>
        ATENÇÃO! Você está no ambiente <b>{getEnvironmentName(environment)}</b>
        {removeButton && (
          <button type="button" onClick={handleReset}>
            | Remover
          </button>
        )}
      </EnvironmentInfoText>
    </Tooltip>
  );
}
