export default {
  main: '#FFFFFF',
  50: '#F3F3F3',
  100: '#DDDDDD',
  200: '#C6C6C6',
  300: '#B0B0B0',
  400: '#9B9B9B',
  500: '#868686',
  600: '#727272',
  700: '#5E5E5E',
  800: '#4B4B4B',
  900: '#393939',
};
