/* @flow */

export const CONCLUDE_CONTRACT_SIGNATURE_REQUEST =
  'CONCLUDE_CONTRACT_SIGNATURE_REQUEST';
export const CONCLUDE_CONTRACT_SIGNATURE_SUCCESS =
  'CONCLUDE_CONTRACT_SIGNATURE_SUCCESS';
export const CONCLUDE_CONTRACT_SIGNATURE_FAILURE =
  'CONCLUDE_CONTRACT_SIGNATURE_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: CONCLUDE_CONTRACT_SIGNATURE_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: CONCLUDE_CONTRACT_SIGNATURE_FAILURE,
      payload: resource,
    });
  };
}

export default function updateContractValues(params) {
  return dispatch => {
    dispatch({
      type: CONCLUDE_CONTRACT_SIGNATURE_REQUEST,
      payload: params,
    });
  };
}
