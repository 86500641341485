import React, { useCallback, useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Button } from '~/ui/components';
import { useModal } from '~/hooks/useModal';
import { requestAgreementPassword } from '~/store/people/actions';

import { useTheme } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { ButtonWrapper, Content, Text } from '../AgreementPasswordActions.styled';

export type RequestAgreementPasswordModalProps = {
  id: string;
};

export function RequestAgreementPasswordModal({ id }: RequestAgreementPasswordModalProps) {
  const { closeModal, setLoading } = useModal();
  const theme = useTheme();
  const requestAgreementPasswordControl = useAwaitControl(requestAgreementPassword);
  const isAgreementPasswordRequestLoading = requestAgreementPasswordControl.isRunning();
  const passwordRequestSuccess = requestAgreementPasswordControl.isSuccessful();

  const handleCancel = () => {
    closeModal('request-agreement-password-modal');
  };

  const handleConfirm = () => {
    requestAgreementPasswordControl.start({ id });
  };

  useEffect(() => {
    setLoading('request-agreement-password-modal', isAgreementPasswordRequestLoading);
  }, [isAgreementPasswordRequestLoading]);

  useEffect(() => {
    if (passwordRequestSuccess) {
      closeModal('request-agreement-password-modal');
    }

    return () => {
      requestAgreementPasswordControl.clear();
    };
  }, [passwordRequestSuccess]);

  return (
    <Content>
      <Text>Por favor, confirme que deseja solicitar a senha do Meu INSS.</Text>

      <ButtonWrapper>
        <Button
          customColor={themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' })}
          fullWidth
          variant="outline"
          rounded
          onClick={handleCancel}
        >
          Não
        </Button>
        <Button
          customColor={themeToggleColor(theme, 'element.primary', { dark: 'brand.secondaryAlt' })}
          fullWidth
          rounded
          onClick={handleConfirm}
        >
          Sim
        </Button>
      </ButtonWrapper>
    </Content>
  );
}
