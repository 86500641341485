import React from 'react';
import { TableStyled, IconStyled } from './styled';

type Props = {
  columns: [],
  dataSource: [],
  expandedRowRender: ReactNode,
  expanded: bollean,
  onExpand: () => void,
  borderRadius: bollean,
};

const CustomExpandIcon = (props: Props) => {
  return (
    <IconStyled
      type={props.expanded ? 'up' : 'down'}
      onClick={props.onExpand}
      {...props}
    />
  );
};

const Table = (props: Props) => {
  const { columns, dataSource, expandedRowRender } = props;
  const locale = {
    /* filterTitle: '筛选',
    filterConfirm: '确定',
    filterReset: '重置', */
    emptyText: 'Nenhum registro',
  };
  return (
    <div>
      <TableStyled
        {...props}
        columns={columns}
        dataSource={dataSource}
        expandIcon={expandedRowRender && CustomExpandIcon}
        locale={locale}
      />
    </div>
  );
};

Table.defaultProps = {
  borderRadius: true,
};

export default Table;
