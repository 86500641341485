import React, { useEffect, useMemo } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import {
  getPersonDetail,
  revokeAgreementPassword,
  sendRevokeAgreementPasswordToken,
  validateRevokeAgreementPasswordToken,
} from '~/store/people/actions';
import { Button, CodeInput, withStepperProps } from '~/ui/components';

import { formatName } from '~/common';
import { Person } from '~/typings/entities/person';
import moment from 'moment';
import { useSpring } from '@react-spring/web';
import { Animate } from '~/ui/theme/animations';
import { useModal } from '~/hooks/useModal';
import useMeasure from 'react-use-measure';
import { themeToggleColor } from '~/common/utils/theme';
import { useTheme } from 'styled-components';
import { message } from 'antd';
import { Content, Divider, InfoRow, Label, Text } from '../../AgreementPasswordActions.styled';

export const ConfirmInfoStep = withStepperProps(({ stepper }) => {
  const { setLoading, setConfig } = useModal();
  const theme = useTheme();
  const personControl = useAwaitControl(getPersonDetail);
  const sendTokenControl = useAwaitControl(sendRevokeAgreementPasswordToken);
  const validateTokenControl = useAwaitControl(validateRevokeAgreementPasswordToken);
  const revokePasswordControl = useAwaitControl(revokeAgreementPassword);

  const person: Person = personControl.result();

  const sendTokenSuccess = sendTokenControl.isSuccessful();
  const sendTokenResult = sendTokenControl.result();
  const sendTokenLoading = sendTokenControl.isRunning();
  const sendTokenFailure = sendTokenControl.hasFailure();

  const validateTokenSuccess = validateTokenControl.isSuccessful();
  const validateTokenResult = validateTokenControl.result();
  const validateTokenLoading = validateTokenControl.isRunning();
  const validateTokenFailure = validateTokenControl.hasFailure();

  const revokePasswordSuccess = revokePasswordControl.isSuccessful();
  const revokePasswordLoading = revokePasswordControl.isRunning();
  const revokePasswordFailure = revokePasswordControl.hasFailure();

  const [measureRef, { height }] = useMeasure();
  const [currentHeight, setCurrentHeight] = React.useState(height);

  const codeSent = useMemo(
    () => !sendTokenFailure && !!sendTokenSuccess && !!sendTokenResult,
    [sendTokenFailure, sendTokenSuccess, sendTokenResult],
  );

  const codeValidated = useMemo(
    () => !validateTokenFailure && !!validateTokenSuccess && !!validateTokenResult,
    [validateTokenFailure, validateTokenSuccess, validateTokenResult],
  );

  const [codeInputAnimation, codeInputAnimationApi] = useSpring(() => ({
    from: { height: 0, opacity: 0, display: 'none' },
  }));

  const handleSendCode = () => {
    sendTokenControl.start({ personId: person.id });
  };

  const handleValidateCode = (token: string) => {
    validateTokenControl.start({ personId: person.id, token });
  };

  const handleExcludeAgreementPassword = () => {
    revokePasswordControl.start({ personId: person.id });
  };

  useEffect(() => {
    if (codeValidated) {
      codeInputAnimationApi.start({
        to: [{ height: 0, opacity: 0 }, { display: 'none' }],
      });
    }
  }, [codeValidated]);

  useEffect(() => {
    if (sendTokenFailure || (sendTokenSuccess && !sendTokenResult)) {
      message.error('Não foi possível enviar o código');
    }
  }, [sendTokenFailure, sendTokenSuccess, sendTokenResult]);

  useEffect(() => {
    if (validateTokenFailure || (validateTokenSuccess && !validateTokenResult)) {
      message.error('Não foi possível validar o código');
    }
  }, [validateTokenFailure, validateTokenSuccess, validateTokenResult]);

  useEffect(() => {
    if (codeSent && currentHeight === height) {
      codeInputAnimationApi.start({
        from: { height: 0, opacity: 0, display: 'none' },
        to: { height, opacity: 1, display: 'block' },
      });
    } else if (codeSent && currentHeight !== height) {
      setCurrentHeight(height);

      codeInputAnimationApi.start({
        from: { height: currentHeight },
        to: { height },
      });
    }
  }, [codeSent, height]);

  useEffect(() => {
    setLoading('exclude-agreement-password-modal', revokePasswordLoading);
  }, [revokePasswordLoading]);

  useEffect(() => {
    if (revokePasswordFailure || revokePasswordSuccess) {
      stepper.nextStep();
    }
  }, [revokePasswordSuccess, revokePasswordFailure]);

  useEffect(() => {
    setConfig('exclude-agreement-password-modal', {
      title: 'Confirme a exclusão da senha',
    });
  }, []);

  return (
    <Content>
      <Text>Por favor, confirme a exclusão da senha do Meu INSS da conta do seu cliente.</Text>
      <InfoRow>
        <Label>Nome completo</Label>
        <Text>{formatName(person?.name) || '-'}</Text>
      </InfoRow>
      <InfoRow>
        <Label>CPF</Label>
        <Text>{person?.document || '-'}</Text>
      </InfoRow>
      <InfoRow>
        <Label>RG</Label>
        <Text>{person?.registrationNumber || '-'}</Text>
      </InfoRow>
      <InfoRow>
        <Label>Data de nascimento</Label>
        <Text>{moment(person?.birthDate).format('DD/MM/YYYY')}</Text>
      </InfoRow>
      {!codeSent && (
        <Button
          loading={sendTokenLoading}
          onClick={handleSendCode}
          customColor={themeToggleColor(theme, 'element.primary', { dark: 'brand.secondaryAlt' })}
          fullWidth
          rounded
        >
          Enviar código
        </Button>
      )}
      {codeSent && <Divider />}
      {codeSent && codeValidated && (
        <Button
          loading={sendTokenLoading}
          onClick={handleExcludeAgreementPassword}
          customColor={themeToggleColor(theme, 'element.primary', { dark: 'brand.secondaryAlt' })}
          fullWidth
          rounded
        >
          Confirmar exclusão
        </Button>
      )}

      <Animate animation={codeInputAnimation}>
        <CodeInput
          ref={measureRef}
          onValidate={handleValidateCode}
          onResend={handleSendCode}
          loading={validateTokenLoading}
          resendLoading={sendTokenLoading}
        />
      </Animate>
    </Content>
  );
});
