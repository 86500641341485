// @flow

import React, { useState, useRef } from 'react';
import { SearchSelect } from '~/common';
import { Select, message } from 'antd';
import {
  OptionsContainer,
  Option as EntryOption,
} from '~/screens/ContractDetails/Panels/Pendencies/components/actions/Documents/components/styled';
import Images from '~/assets/images';
const { Option } = Select;

type SelectionProps = {
  onFilesChange: (Array<{ type: string, file: any }>) => void,
};

const types = [
  {
    key: 'cnh',
    label: 'CNH',
  },
  {
    key: 'rgPlusCPF',
    label: 'RG e CPF',
  },
  {
    key: 'rgAndCPF',
    label: 'RG contendo CPF',
  },
];

function getEntryTitle(entry) {
  switch (entry) {
    case 'DrivesLicense__Front':
      return 'CNH - Frente';
    case 'DrivesLicense__Back':
      return 'CNH - Verso';
    case 'PersonDocument__Front':
      return 'RG - Frente';
    case 'PersonDocument__Back':
      return 'RG - Verso';
    default:
      return 'Documento';
  }
}

const reader = new FileReader();

function Selection(props: SelectionProps) {
  const inputEl = useRef({});
  const [entries, setEntries] = useState([]);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [selectedEntries, setSelectedEntries] = useState({});
  const [files, setFiles] = useState([]);

  function onLoadReader(reader) {
    const file = reader.target.result;

    setSelectedEntries({
      ...selectedEntries,
      [`file${currentEntry}`]: file,
    });
  }

  reader.onload = onLoadReader;

  function onSelectionChange(type) {
    switch (type) {
      case 'cnh':
        setEntries(['DrivesLicense__Front', 'DrivesLicense__Back']);
        break;
      case 'rgPlusCPF':
        setEntries([
          'PersonDocument__Front',
          'PersonDocument__Back',
          'Document',
        ]);
        break;
      case 'rgAndCPF':
      default:
        setEntries(['PersonDocument__Front', 'PersonDocument__Back']);
        break;
    }
    setCurrentEntry(null);
    setSelectedEntries({});
    setFiles([]);
    inputEl.current = {};
  }

  const onOptionClicked = entry => () => {
    setCurrentEntry(entry);
    inputEl.current[entry].click();
  };

  const onFileOk = event => {
    const file = event.target.files[0];

    if (file) {
      if (checkFilesEquals(file)) {
        message.error('Selecione arquivos diferentes');
      } else {
        const fileEntry = files.find(o => o.type === currentEntry);
        if (fileEntry) {
          fileEntry.file = file;
        } else {
          files.push({ type: currentEntry, file });
        }
        props.onFilesChange(files);
        reader.readAsDataURL(file);
      }
    }
  };

  const checkFilesEquals = file => {
    let hasFileEquals = false;

    for (const obj of files) {
      if (file.name === obj.file.name) {
        hasFileEquals = true;
        break;
      }
    }

    return hasFileEquals;
  };

  return (
    <div>
      <Select
        style={{ width: '100%', marginBottom: 16 }}
        placeholder='Escolha'
        onChange={onSelectionChange}
        allowClear
        showSearch
        optionFilterProp='children'
        filterOption={(input, option) =>
          SearchSelect(input, option.props.children)
        }
      >
        {types.map(item => (
          <Option key={item.key}>{item.label}</Option>
        ))}
      </Select>
      <OptionsContainer>
        {entries.map(entry => (
          <>
            <EntryOption onClick={onOptionClicked(entry)}>
              <div className='img_container'>
                <img
                  alt={getEntryTitle(entry)}
                  src={
                    selectedEntries[`file${entry}`] ||
                    Images[`document${entry}`]
                  }
                />
              </div>
              <span className='title'>{getEntryTitle(entry)}</span>
            </EntryOption>
            <input
              ref={ref => (inputEl.current[entry] = ref)}
              type='file'
              accept='image/*'
              hidden
              onChange={onFileOk}
            />
          </>
        ))}
      </OptionsContainer>
    </div>
  );
}

export default Selection;
