/* @flow */

export const LINK_CONTRACTOWNER_REQUEST = 'LINK_CONTRACTOWNER_REQUEST';
export const LINK_CONTRACTOWNER_SUCCESS = 'LINK_CONTRACTOWNER_SUCCESS';
export const LINK_CONTRACTOWNER_FAILURE = 'LINK_CONTRACTOWNER_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: LINK_CONTRACTOWNER_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: LINK_CONTRACTOWNER_FAILURE,
      payload: resource,
    });
  };
}

export default function generateProofOfAddress(id) {
  return dispatch => {
    dispatch({
      type: LINK_CONTRACTOWNER_REQUEST,
      payload: id,
    });
  };
}
