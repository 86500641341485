import React, { useEffect } from 'react';
import { WarrantyType } from '~/typings/enums/Clix';
import useQueryParams from '~/hooks/useQueryParams';
import { useAwaitControl } from 'react-redux-await-control';
import { getClientDetails, getInvoices } from '~/store/clix/actions';
import { getPersonDetail } from '~/store/people/actions';
import { Tabs } from '~/ui/components';
import { InfoContainer, InfoSection } from '..';
import { CleanInfo } from '../../Sections';
import { TransactionsTable } from '../../Tables';
import { InvoicesTable } from '../../Tables/InvoicesTable/InvoicesTable';

export type CleanWarrantyProps = {
  personId: string;
};

export function CleanWarranty({ personId }: CleanWarrantyProps) {
  const query = useQueryParams();
  const getClixDetailsControl = useAwaitControl(getClientDetails);
  const getPersonDetailsControl = useAwaitControl(getPersonDetail);
  const getInvoicesControl = useAwaitControl(getInvoices);

  const person = getPersonDetailsControl.result();
  const clixInfo = getClixDetailsControl.result(`${personId}_${WarrantyType.CLEAN}`);
  const invoices = getInvoicesControl.result(`${personId}_${WarrantyType.CLEAN}`);
  const clixInfoSuccess = getClixDetailsControl.isSuccessful(`${personId}_${WarrantyType.CLEAN}`);
  const personDetailsSuccessful = getPersonDetailsControl.isSuccessful();

  useEffect(() => {
    if (personDetailsSuccessful) {
      const { document } = person;

      getClixDetailsControl.start(
        { document, status: query.get('status') || 'CONTRACTED', warranty: WarrantyType.CLEAN },
        { actionId: `${personId}_${WarrantyType.CLEAN}` },
      );
      getInvoicesControl.start(
        { document, warranty: WarrantyType.CLEAN },
        { actionId: `${personId}_${WarrantyType.CLEAN}` },
      );
    }
  }, [personDetailsSuccessful]);

  return (
    <InfoContainer>
      <InfoSection>
        <CleanInfo info={clixInfo} loading={!clixInfoSuccess} />
      </InfoSection>

      <Tabs
        className="internal-tabs"
        tabs={[
          {
            key: 'inss-transaction-history',
            title: 'Histórico de transações',
            noPadding: true,
            component: <TransactionsTable personId={personId} warranty={WarrantyType.CLEAN} />,
          },
          {
            key: 'inss-invoice-history',
            title: 'Histórico de faturas',
            noPadding: true,
            component: <InvoicesTable data={invoices} warranty={WarrantyType.CLEAN} />,
          },
        ]}
      />
    </InfoContainer>
  );
}
