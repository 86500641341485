const pendencies = {
  DOCUMENTS: 'Documentos',
  CONTRACT_SIGNATURE: 'Assinatura dos termos',
  PICTURE_FACE_FRONT_RECOGNITION: 'Assinatura com selfie',
  BANK_ACCOUNT_INVALID: 'Conta bancária',
  PICTURE_DOCUMENT: 'Documentos (RG, CPF, CNH)',
  PICTURE_FACE_FRONT: 'Registro da foto verdade',
  PROOF_OF_ADDRESS: 'Comprovante de residência',
  PAYCHECK: 'Contracheque',
  REGISTER_NUMBER: 'Número do RG',
  ADDRESS: 'Endereço',
  AGREEMENT_PASSWORD: 'Conexão com convênio',
  HISCON: 'Hiscon',
  CONCESSION_LETTER: 'Carta de concessão',
  EXPERTISE: 'Perícia',
  DATA: 'Dados',
};

export default function pendencyTitle(type) {
  return pendencies[type] || '';
}
