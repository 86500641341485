/* @flow */

export const LOAN_HISTORIES_FETCH_REQUEST = 'LOAN_HISTORIES_FETCH_REQUEST';
export const LOAN_HISTORIES_FETCH_SUCCESS = 'LOAN_HISTORIES_FETCH_SUCCESS';
export const LOAN_HISTORIES_FETCH_FAILURE = 'LOAN_HISTORIES_FETCH_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: LOAN_HISTORIES_FETCH_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: LOAN_HISTORIES_FETCH_FAILURE,
      payload: resource,
    });
  };
}

export default function fetch(params = {}) {
  return dispatch => {
    dispatch({
      type: LOAN_HISTORIES_FETCH_REQUEST,
      payload: {
        params,
      },
    });
  };
}
