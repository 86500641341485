/* @flow */
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';

import { endpoint } from '..';
import { GET_DOCUMENT_CONTRACT_REQUEST, success, failure } from './action';

const getContractDocument = (action$, state$, { AjaxRequest }) =>
  action$
    .ofType(GET_DOCUMENT_CONTRACT_REQUEST)
    .map(({ payload: { contractId } }) => contractId)
    .mergeMap(id =>
      Observable.fromPromise(
        fetch(`${endpoint}/admin/contractcustom/contracts/${id}/pdf?type=CCB`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              'authenticationToken',
            )}`,
          },
        }),
      )
        .mergeMap(response => response.blob())
        .mergeMap(blob =>
          Observable.of(
            success({
              exist: blob.size !== 0,
              size: blob.size,
              uri: URL.createObjectURL(blob),
            }),
          ),
        )
        .catch(err => Observable.of(failure(err))),
    );

export default combineEpics(getContractDocument);
