import React from 'react';
import moment from 'moment';

import { Installment, OpportunitySimulation } from '~/typings/entities/Opportunity';
import toMoney from '~/common/masked/toMoney';
import { Alert, Divider, Flex, Typography } from '~/ui/components';

import { ResumeBody } from './Resume.styled';

export type ResumeProps = {
  contract: OpportunitySimulation;
};

export function Resume({ contract }: ResumeProps) {
  const formatDate = (date: string, format = 'DD/MM/YYYY') =>
    date ? moment(date).format(format) : '';

  return (
    <ResumeBody direction="column" gap={24} ph={32} pv={24} width="100%">
      <Flex align="center" justify="center" width="100%">
        <Typography type="paragraphLarge" weight={600} center fullWidth>
          Detalhes da oportunidade
        </Typography>
      </Flex>

      <Flex direction="column" gap={16} width="100%">
        <div>
          <Typography type="bodyMedium" fullWidth>
            Valor aproximado que vai receber
          </Typography>

          <Flex gap={8} align="center" width="100%">
            <Typography type="headingH5" weight={600}>
              {contract?.contractValue ? toMoney(contract?.contractValue || 0) : '-'}
            </Typography>
          </Flex>
        </div>

        <Flex gap={8} direction="column" mv={8} width="100%">
          <Typography type="bodyMedium" weight={600} fullWidth>
            Previsão de pagamento
          </Typography>

          <Typography type="bodySmall">
            Entre {formatDate(contract?.nearEstimatedEndDate) || '-'} e{' '}
            {formatDate(contract?.farEstimatedEndDate) || '-'}
          </Typography>
        </Flex>

        <Divider />

        <Flex gap={8} width="100%" direction="column">
          <Typography type="bodyLarge" weight={600} fullWidth>
            Detalhes por parcela
          </Typography>

          <Alert status="info">
            O valor retirado em cada parcela equivale a quanto o cliente recebe adicionando juros e
            IOF.
          </Alert>

          {contract?.installments?.map((installment: Installment) => (
            <Flex gap={8} direction="column" pv={8} key={installment.installmentNumber}>
              <Flex gap={8} align="center" width="100%">
                <Typography type="bodyMedium" weight={600} width={85} fullWidth>
                  Parcela {installment.installmentNumber}
                </Typography>

                <Typography type="bodyXSmall" element="span">
                  {formatDate(installment.dueDate)}
                </Typography>
              </Flex>

              <Flex gap={8} align="center" width="100%">
                <Typography type="bodySmall">Total retirado</Typography>

                <Typography type="bodySmall" weight={600}>
                  {toMoney(installment.totalAmount)}
                </Typography>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </ResumeBody>
  );
}
