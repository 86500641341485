/* @flow */

import getHistoryFiles from '~/store/getFileHistoryFiles/action';

export const FILE_HISTORY_REQUEST = 'FILE_HISTORY_REQUEST';
export const FILE_HISTORY_SUCCESS = 'FILE_HISTORY_SUCCESS';
export const FILE_HISTORY_FAILURE = 'FILE_HISTORY_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch(getHistoryFiles(payload));
    dispatch({
      type: FILE_HISTORY_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FILE_HISTORY_FAILURE,
      payload: resource,
    });
  };
}

export default function get({ contractId, fileType, personId }) {
  return dispatch => {
    dispatch({
      type: FILE_HISTORY_REQUEST,
      payload: {
        fileType,
        personId,
        contractId,
      },
    });
  };
}
