import styled from 'styled-components';
import { themeColor } from '~/common/utils/theme';

export type CustomComponentsAreaProps = {
  width?: string | number;
};

export const MultiSelectTrigger = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  background-color: ${themeColor('background.primary')};

  &:hover * {
    cursor: pointer;
  }

  i {
    background-color: ${themeColor('background.primary', { dark: 'background.secondary' })};
  }

  .icon-custom {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 0;
  }

  .ant-input-group-addon {
    border: none;
    border-bottom: 1px solid #c6c6c6;
    background-color: transparent;
  }

  svg {
    width: 12px;
    height: 12px;
    color: ${themeColor('element.primary', { dark: 'element.secondary' })};
    font-size: 12px;
    line-height: 12px;
  }
`;

export const MultiSelectOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    max-height: ${({ height }) => height || 400}px;
    overflow: auto;
  }

  .ant-checkbox-inner {
    border-color: #d22688 !important;
    background-color: ${({ theme }) => theme.colors.background.primary};
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${themeColor('brand.primary', { dark: 'brand.secondary' })} !important;
    }
  }

  .ant-checkbox-group-item {
    padding: 8px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;

    &:hover {
      overflow: visible;
    }

    & > :last-child {
      background-color: ${({ theme }) => theme.colors.background.primary};
      color: ${themeColor('element.primary', { dark: 'element.secondary' })};
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 8px;
      border-radius: 0 4px 4px 0;
    }
  }
`;

export const PortalSelect = styled.div<CustomComponentsAreaProps>`
  .ant-popover {
    width: ${({ width }) => (Number.isInteger(width) ? `${width}px` : width)} !important;
  }
`;
