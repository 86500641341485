// @flow

import React from 'react';
import solveDocuments, {
  SOLVE_DOCUMENT_SUCCESS,
} from '~/store/solveDocument/action';
import Documents from './Documents';
import updatePendencies from '~/screens/ContractDetails/Panels/Pendencies/components/actions/updatePendencies';

type DocumentsContainerProps = {};

const fileNames = {
  DrivesLicense__Front: 'cnhFront',
  DrivesLicense__Back: 'cnhBack',
  PersonDocument__Front: 'rgFront',
  PersonDocument__Back: 'rgBack',
  Document: 'cpf',
};

const DocumentsContainer = (props: DocumentsContainerProps) => {
  const onPressSend = files => {
    props.dispatch(
      solveDocuments({
        id: props.id,
        files: files.map(file => ({
          name: fileNames[file.type],
          file: file.file,
        })),
      }),
    );
  };

  return <Documents onPressSend={onPressSend} {...props} />;
};

export default updatePendencies('solveDocuments', SOLVE_DOCUMENT_SUCCESS)(
  DocumentsContainer,
);
