import styled, { css } from 'styled-components';
import { Typography } from '~/ui/components';

type LoadingProp = {
  loading?: boolean;
};

export const InputContainer = styled.div<LoadingProp>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ loading }) =>
    loading &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
`;

export const CodeContainer = styled.div<LoadingProp>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const Code = styled.input.attrs({
  maxLength: 1,
  type: 'text',
})`
  outline: 0;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.element.primary};
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.element.primary};
  text-align: center;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.fonts.montserrat};
  background: ${({ theme }) => theme.colors.background.primary};
`;

export const IntroText = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  color: theme.colors.element.primary,
  weight: 500,
}))``;

type HelpBoxProps = {
  type?: 'error' | 'info' | 'alert';
  slim?: boolean;
};

export const MessageBox = styled.div<HelpBoxProps>`
  border-radius: 16px;
  background: ${({ theme, type }) => theme.colors[type || 'info'].secondary};
  padding: ${({ slim }) => (slim ? '4px 16px' : '12px 16px')};
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;

  color: ${({ theme, type }) => theme.colors[type || 'info'].primaryAlt};

  svg {
    flex-shrink: 0;
    color: ${({ theme, type }) => theme.colors[type || 'info'].primaryAlt};
  }
`;

export const HelpText = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: theme.colors.element.primary,
  weight: 400,
  element: 'span',
}))``;
