import React, { useCallback } from 'react';

import FGTSIntegrationStatus from '~/typings/enums/FGTSIntegrationStatus';
import { Opportunity } from '~/typings/entities/Opportunity';
import { Person } from '~/typings/entities/person';
import masked from '~/common/masked';
import { InfoGrid, InfoGridItem, Tag, Tooltip } from '~/ui/components';
import { ClixContractsPopover } from '~/components/ClixContractsPopover/ClixContractsPopover';
import { useTheme } from 'styled-components';
import { Lock } from '~/ui/assets/icons';

interface AboutClientProps {
  personDetails: Person;
  clixContracts: any[];
  fgtsOpportunity: Opportunity;
  loading?: boolean;
}

export function AboutClient({
  personDetails,
  clixContracts,
  fgtsOpportunity,
  loading,
}: AboutClientProps) {
  const { colors } = useTheme();

  const renderIncomeInsuranceStatus = () => {
    const hasNotInsurance = !personDetails?.insuranceList?.length;
    let name: string;
    let colorScheme;

    if (hasNotInsurance) {
      name = 'Cliente não possui';
      colorScheme = { bgColor: colors.brand.secondary, textColor: colors.brand.primaryAlt };
    } else {
      name = 'Cliente possui';
      colorScheme = { bgColor: colors.positive.secondary, textColor: colors.positive.primaryAlt };
    }

    return (
      <Tag rounded small {...colorScheme}>
        {name}
      </Tag>
    );
  };

  const renderIntegrationStatus = useCallback((marginCode, marginStatus) => {
    if (marginCode === '7' && marginStatus === 'Autorizar Parati') {
      return 'Autorizar Parati';
    }

    return FGTSIntegrationStatus?.[marginCode]?.label;
  }, []);

  const renderIntegrationDescription = useCallback((marginCode, marginStatus) => {
    if (marginCode === '7' && marginStatus === 'Autorizar Parati') {
      return 'Autorizar Parati';
    }

    return FGTSIntegrationStatus?.[marginCode]?.description;
  }, []);

  const renderFGTSStatus = () => {
    const marginCode = fgtsOpportunity?.marginValues?.marginInformation?.marginCode;
    const marginStatus = fgtsOpportunity?.marginValues?.marginInformation?.marginStatus;

    if (marginCode) {
      const colorPalette = FGTSIntegrationStatus?.[marginCode]?.color;

      return (
        <>
          <Tag
            rounded
            small
            bgColor={colors?.[colorPalette]?.secondary}
            textColor={colors?.[colorPalette]?.primaryAlt}
          >
            {renderIntegrationStatus(marginCode, marginStatus)}
          </Tag>
          <Tooltip
            content={renderIntegrationDescription(marginCode, marginStatus)}
            placement="bottomLeft"
            maxWidth="350px"
          />
        </>
      );
    }

    return '-';
  };

  const renderInsuranceStatus = useCallback(() => {
    const blockedForLoan = personDetails?.blockedForLoan;

    if (blockedForLoan) {
      return (
        <Tag
          rounded
          small
          bgColor={colors.negative.secondary}
          textColor={colors.negative.primaryAlt}
        >
          <Lock width={14} height={14} /> Benefício bloqueado
        </Tag>
      );
    }

    return (
      <Tag rounded small bgColor={colors.positive.secondary} textColor={colors.positive.primaryAlt}>
        Benefício liberado
      </Tag>
    );
  }, [personDetails?.blockedForLoan]);

  const renderClixStatus = () =>
    !clixContracts?.length ? (
      'Contrato não disponível'
    ) : (
      <ClixContractsPopover contracts={clixContracts} />
    );

  const list: InfoGridItem[] = [
    {
      name: 'CPF',
      copyable: true,
      value: personDetails.document ? masked.toPersonDocument(personDetails.document) : '-',
      copyText: masked.toNumber(masked.toPersonDocument(personDetails.document)),
    },
    {
      name: 'Data de nascimento',
      copyable: true,
      value: masked.toDate(personDetails?.birthDate),
    },
    {
      name: 'Telefone',
      copyable: true,
      value: personDetails.phone ? masked.toPhone(personDetails.phone) : '-',
      copyText: masked.toNumber(personDetails.phone),
    },
    {
      name: 'E-mail',
      copyable: !!personDetails?.email,
      value: personDetails?.email || '-',
    },
    {
      name: 'Status da integração',
      value: renderFGTSStatus(),
    },
    {
      name: 'Seguro renda',
      value: renderIncomeInsuranceStatus(),
    },

    {
      name: 'Contrato clix',
      value: renderClixStatus(),
    },
  ];

  if (personDetails?.agreementName === 'INSS') {
    list.splice(5, 0, {
      name: 'Status do convênio',
      value: renderInsuranceStatus(),
    });
  }

  return <InfoGrid loading={loading} list={list} />;
}
