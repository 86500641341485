import React from 'react';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { message } from 'antd';
import moment from 'moment/moment';
import { useAwaitControl } from 'react-redux-await-control';

import { searchContracts } from '~/store/contracts/actions';
import screenRegister from '~/hoc/screenRegister';
import { Content } from '~/ui/components';
import { OptionProps } from '~/components';
import { useModal } from '~/hooks/useModal';
import JsonToCSV from '~/common/jsonToCSV';

import useDownloadFile from '~/hooks/useDownloadFile';
import CancelProposalModal from './Modals/CancelProposalModal/CancelProposalModal';
import { ContractsTable } from './Table/ContractsTable';
import { Filters } from './Filters/Filters';

function Contracts() {
  const { openModal } = useModal();
  const history = useHistory();
  const fetchContractsControl = useAwaitControl(searchContracts);
  const { entries } = fetchContractsControl.result();
  const contractsLoading = fetchContractsControl.isRunning();
  const download = useDownloadFile();

  const setQueryParams = (params) => {
    const { pathname } = history.location;
    const newSearch = queryString.stringify(params);
    history.replace({ pathname, search: newSearch });
  };

  const formatDate = (date) => {
    if (!date) return undefined;

    moment.locale('en');
    return moment(date).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  };

  const handleOnSubmit = (values) => {
    const hasSomeValue = Object.values(values).some((el) => el);
    const hasMainValues = Object.keys(values).some((key) =>
      ['document', 'dateRange'].includes(key),
    );
    const { dateRange, document, ...restValues } = values;

    const parsedValues = {
      ...restValues,
      document: document?.replace(/\D/g, ''),
      startDate: formatDate(dateRange?.[0]),
      endDate: formatDate(dateRange?.[1]),
      computeCommissioning: false,
    };

    if (!hasSomeValue) {
      message.warning('É necessário preencher pelo menos um dos filtros');
      return;
    }

    if (!hasMainValues) {
      message.warning('É necessário preencher pelo menos o CPF ou PERÍODO');
      return;
    }

    fetchContractsControl.start(parsedValues);
    setQueryParams(parsedValues);
  };

  const handleCancelPreProposal = () => {
    openModal(<CancelProposalModal />, {
      title: 'Cancelamento da Pré-Proposta',
      maskClosable: false,
      closable: true,
      width: 450,
    });
  };

  const handleDownloadList = () => {
    const labelsFilter = [
      'flatCommissioning',
      'deferredCommissioning',
      'flatCommissioningRefinPortability',
      'deferredCommissioningRefinPortability',
    ];

    download({
      name: 'contratos_emprestimos',
      uri: JsonToCSV(entries, true, labelsFilter),
      extension: '.csv',
    });
  };

  const options: OptionProps[] = [
    {
      label: 'Cancelamento de pré-proposta',
      onClick: handleCancelPreProposal,
    },
    {
      label: 'Baixar lista de contratos',
      onClick: handleDownloadList,
      disabled: !entries,
    },
  ];

  return (
    <Content>
      <Filters onSubmit={handleOnSubmit} loading={contractsLoading} options={options} />
      <ContractsTable data={entries} />
    </Content>
  );
}

export default screenRegister({
  screenName: 'Contracts',
  headerTitle: 'Contratos',
  path: '/backoffice/contracts',
  customLayout: true,
})(Contracts);
