import React, { useState, useCallback, useMemo, useEffect } from 'react';
import moment from 'moment/moment';

import { Caret, Flex, Typography, Button as UiButton } from '~/ui/components';
import { Agent } from '~/ui/assets/icons';
import { Media } from '~/ui/components/Media/Media';
import { useAwaitControl } from 'react-redux-await-control';
import { getTicketAssessment, getTicketMessages } from '~/store/tickets/actions';
import {
  Wrapper,
  Content,
  Header,
  AgentCard,
  Info,
  ClientCard,
  Position,
  CardWrapper,
  DateCard,
  DateWrapper,
  Date,
  Name,
  Text,
  Icon,
  TextWrapper,
  Button,
  MessageWrapper,
  Title,
} from './CustomerService.styled';

type MessageType = {
  body: string;
  index: number;
  author: string;
  media: [
    {
      category: string;
      size: number;
      filename: string;
      contentType: string;
      sid: string;
    },
  ];
  authorType: string;
  dateUpdated: string;
  dateCreated: string;
};

type CustomerServiceType = {
  ticket: any;
};

export function CustomerService({ ticket }: CustomerServiceType) {
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [lastPageToken, setLastPageToken] = useState('');

  const ticketMessagesControl = useAwaitControl(getTicketMessages);
  const ticketAssessmentControl = useAwaitControl(getTicketAssessment);

  const ticketAssessmentLoading = ticketAssessmentControl.isRunning();

  const ticketMessages = ticketMessagesControl.result();
  const ticketMessagesLoading = ticketMessagesControl.isRunning();
  const ticketMessagesFail = ticketMessagesControl.hasFailure();

  const isItBot = ['SYSTEM', 'BOT'];

  const formatDate = (date: string, format = 'DD [de] MMM [de] YYYY') =>
    moment(date).format(format);
  const formatHour = (date: string, format = 'HH:mm') => moment(date).format(format);

  const renderDate = useCallback(
    (line, prevDate) =>
      formatDate(line?.dateCreated) !== prevDate && (
        <DateWrapper marginTop={!!prevDate}>
          <DateCard>
            <Date>{formatDate(line?.dateCreated)}</Date>
          </DateCard>
        </DateWrapper>
      ),
    [],
  );

  const agent = useMemo(
    () => (
      <Icon>
        <Agent />
      </Icon>
    ),
    [],
  );

  const getNewMessages = useCallback(
    (ticketMessages) => {
      ticketMessagesControl.start({
        conversationId: ticket.conversationId,
        pageToken: lastPageToken ?? ticketMessages?.meta?.nextPageToken,
      });
    },
    [ticket, lastPageToken],
  );

  const renderConversation = useCallback(
    () => (
      <>
        {messages?.map((line, index) => {
          let prevMessageFrom = '';
          let prevDate = '';
          const hasIcon = messages[index + 1]?.authorType === 'CLIENT';

          if (index > 0) {
            prevMessageFrom = messages[index - 1]?.authorType !== 'CLIENT' ? 'agent' : 'client';
          }

          if (index > 0) {
            prevDate = formatDate(messages[index - 1]?.dateCreated);
          }

          if (line?.authorType !== 'CLIENT') {
            return (
              <React.Fragment key={line?.index}>
                {renderDate(line, prevDate)}
                <Position justifyContent="end">
                  {prevMessageFrom === 'agent' && (
                    <CardWrapper alignItems="end">
                      <MessageWrapper hasIcon={hasIcon}>
                        <AgentCard>
                          {line?.body && <Text weight={400}>{line?.body}</Text>}
                          {line?.media.length > 0 && (
                            <Media media={line?.media[0]} ticket={ticket.conversationId} />
                          )}
                        </AgentCard>
                        {hasIcon && agent}
                      </MessageWrapper>
                    </CardWrapper>
                  )}
                  {prevMessageFrom !== 'agent' && (
                    <CardWrapper
                      alignItems="end"
                      marginTop={formatDate(line?.dateCreated) === prevDate}
                    >
                      <MessageWrapper hasIcon={hasIcon}>
                        <TextWrapper>
                          <Info>
                            <Name>
                              {isItBot.includes(line?.authorType)
                                ? 'Auto Atendimento'
                                : line?.author}
                            </Name>
                            <Date hour>{formatHour(line?.dateCreated)}</Date>
                          </Info>
                          <AgentCard>
                            {line?.body && <Text weight={400}>{line?.body}</Text>}
                            {line?.media.length > 0 && (
                              <Media media={line?.media[0]} ticket={ticket.conversationId} />
                            )}
                          </AgentCard>
                        </TextWrapper>
                        {hasIcon && agent}
                      </MessageWrapper>
                    </CardWrapper>
                  )}
                </Position>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={line?.index}>
              {renderDate(line, prevDate)}
              <Position justifyContent="start">
                {prevMessageFrom === 'client' && (
                  <CardWrapper alignItems="start">
                    <ClientCard>
                      {line?.body && <Text weight={400}>{line?.body}</Text>}
                      {line?.media.length > 0 && (
                        <Media media={line?.media[0]} ticket={ticket.conversationId} />
                      )}
                    </ClientCard>
                  </CardWrapper>
                )}
                {prevMessageFrom !== 'client' && (
                  <CardWrapper
                    alignItems="start"
                    marginTop={formatDate(line?.dateCreated) === prevDate}
                  >
                    <Info>
                      <Name>{line?.author}</Name>
                      <Date hour>{formatHour(line?.dateCreated)}</Date>
                    </Info>
                    <ClientCard>
                      {line?.body && <Text weight={400}>{line?.body}</Text>}
                      {line?.media.length > 0 && (
                        <Media media={line?.media[0]} ticket={ticket.conversationId} />
                      )}
                    </ClientCard>
                  </CardWrapper>
                )}
              </Position>
            </React.Fragment>
          );
        })}
      </>
    ),
    [messages, ticket],
  );

  useEffect(() => {
    setIsVisible(false);
    setMessages([]);
  }, [ticket]);

  useEffect(() => {
    if (ticketMessages?.messages) {
      setMessages((prev) => {
        // Cria um conjunto de índices das mensagens existentes
        const existingIndices = new Set(prev.map((message) => message.index));

        // Filtra as novas mensagens para incluir apenas aquelas que não estão já presentes
        const newMessages = ticketMessages.messages.filter(
          (message) => !existingIndices.has(message.index),
        );

        return [...prev, ...newMessages];
      });
    }
  }, [ticketMessages]);

  useEffect(() => {
    if (
      ticketMessages &&
      !ticketMessagesFail &&
      ticketMessages?.meta?.nextPageToken &&
      !ticketAssessmentLoading
    ) {
      setLastPageToken(ticketMessages.meta.nextPageToken);
    }

    if (ticketAssessmentLoading) {
      setLastPageToken('');
    }
  }, [ticketMessages, ticketMessagesFail, ticketAssessmentLoading]);

  return (
    <Wrapper>
      <Header onClick={() => setIsVisible(!isVisible)}>
        <Typography type="bodyLarge" weight={600}>
          Histórico do atendimento
        </Typography>
        <Button>
          <Caret caretOpen={!isVisible} />
        </Button>
      </Header>

      {isVisible && messages.length > 0 && (
        <Content>
          {renderConversation()}
          {!ticketMessages?.meta?.lastPage && (
            <Flex justify="center" pv={16}>
              <UiButton
                size="sm"
                variant="text"
                onClick={() => getNewMessages(ticketMessages)}
                loading={ticketMessagesLoading}
              >
                Carregar mais
              </UiButton>
            </Flex>
          )}
        </Content>
      )}
      {isVisible && messages.length <= 0 && (
        <Content>
          <Title size={14}>
            Não foi possível exibir o histórico do atendimento, ticket não possuí conversa
            associada.
          </Title>
        </Content>
      )}
    </Wrapper>
  );
}
