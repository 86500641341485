import styled, { css } from 'styled-components';
import { themeToggleColor } from '~/common/utils/theme';
import { Typography } from '~/ui/components';

export const Info = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '30%')};

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      @media (max-width: 522px) {
        width: 45%;
      }

      @media (max-width: 365px) {
        width: 100%;
      }
    `}
`;

export const InfoTitle = styled(Typography).attrs(({ theme }) => ({
  type: 'paragraphSmall',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  weight: 500,
  element: 'h4',
}))``;

export const InfoValue = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: theme.colors.element.secondary,
  weight: 400,
  element: 'span',
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ fullWidth }) => (fullWidth ? 'unset' : 'nowrap')};
`;
