/* @flow */

export const FILL_CONTRACT_REQUEST = 'FILL_CONTRACT_REQUEST';
export const FILL_CONTRACT_SUCCESS = 'FILL_CONTRACT_SUCCESS';
export const FILL_CONTRACT_FAILURE = 'FILL_CONTRACT_FAILURE';

export function success(payload) {
  return dispatch => {
    dispatch({
      type: FILL_CONTRACT_SUCCESS,
      payload,
    });
  };
}

export function failure(resource) {
  return dispatch => {
    dispatch({
      type: FILL_CONTRACT_FAILURE,
      payload: resource,
    });
  };
}

export default function fillContract(params) {
  return dispatch => {
    dispatch({
      type: FILL_CONTRACT_REQUEST,
      payload: params,
    });
  };
}
