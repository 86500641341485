import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTheme } from 'styled-components';

import { Button, Flex, Input, Select, Table, TableColumns, Tag, Tooltip } from '~/ui/components';
import { TransactionGroup, WalletCard } from '~/typings/entities/Wallet';
import { Money } from '~/common';
import {
  TransactionStatusColors,
  TransactionStatusHelpText,
  TransactionStatusIcons,
  TransactionStatusString,
  WarrantyType,
} from '~/typings/enums/Clix';
import { AlertCircle } from '~/ui/assets/icons';
import { useAwaitControl } from 'react-redux-await-control';
import { getCards, getTransactions } from '~/store/wallet/actions';

import { getClientDetails } from '~/store/clix/actions';
import usePagination from '~/hooks/usePagination';
import { useDrawer } from '~/hooks/useDrawer';
import { CreditTransactionDetails } from '~/screens/Clix/ContractDetails/Drawers';
import TransactionsStatus from '~/constants/Clix';
import { Filters } from '~/components';
import { TableContainer } from '..';

export type CreditTransactionsProps = {
  loading?: boolean;
  personId: string;
};

export function CreditTransactions({ loading, personId }: CreditTransactionsProps) {
  const { colors } = useTheme();
  const { openDrawer } = useDrawer();
  const [filters, setFilters] = useState<{}>();

  const getClixDetailsControl = useAwaitControl(getClientDetails);
  const getCardsControl = useAwaitControl(getCards);
  const getTransactionsControl = useAwaitControl(getTransactions);
  const cards: WalletCard[] = getCardsControl.result();
  const clixInfo = getClixDetailsControl.result(`${personId}_${WarrantyType.CREDIT}`);
  const clixInfoSuccess = getClixDetailsControl.isSuccessful(`${personId}_${WarrantyType.CREDIT}`);
  const transactionsError = getTransactionsControl.hasFailure(personId);
  const { transactions, pagination } = getTransactionsControl.result(personId);

  const { page, size, paginatedData, renderPagination, clear } = usePagination({
    data: transactions,
    total: pagination?.totalElements,
  });

  const cardOptions = useMemo(
    () =>
      cards.map((card) => ({
        label: card.alias
          ? `${card.last4}/${card.brand} (${card.alias})`
          : `${card.last4}/${card.brand}`,
        value: card.cardId,
      })),
    [cards],
  );

  const fetchTransactions = (page: number) => {
    getTransactionsControl.start(
      {
        userId: clixInfo?.wallet?.userId,
        size,
        page,
        ...filters,
      },
      { actionId: personId },
    );
  };

  const handleFilterChange = (values) => {
    const status = values.status === 'PENDING' ? `PENDING,INITIAL` : values.status;

    setFilters({
      ...values,
      status,
    });
  };

  const statusOptions = Object.keys(TransactionsStatus)
    .filter((el) => el !== 'INITIAL')
    .map((key) => ({
      label: TransactionsStatus[key],
      value: key,
    }));

  const handleDetailsClick = (transaction: TransactionGroup) => {
    openDrawer(
      'credit-transaction-details',
      <CreditTransactionDetails transaction={transaction} />,
      { width: 470 },
    );
  };

  const columns: TableColumns<TransactionGroup> = [
    {
      id: 'status',
      maxSize: 50,
      cellAlign: 'center',
      header: () => '',
      cell: ({ row }) => {
        const value = row.original?.cashOut?.status;

        if (!value) return '-';

        const palette = TransactionStatusColors[value];
        const textColor = colors?.[palette]?.primaryAlt;
        const bgColor = colors?.[palette]?.secondary;
        const StatusIcon = TransactionStatusIcons[value];
        return (
          <Tooltip
            width={265}
            title={TransactionStatusString[value]}
            content={TransactionStatusHelpText[value]}
          >
            <Tag rounded textColor={textColor} bgColor={bgColor} small>
              <StatusIcon width={16} height={16} />
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      id: 'date',
      header: () => 'DATA',
      cell: ({ row }) => {
        const date = row.original?.cashOut?.date || row.original?.cashIn?.date;
        return moment(date).format('DD/MM/YYYY HH[h]mm');
      },
    },
    {
      id: 'transactionId',
      header: () => 'N. DA TRANSAÇÃO',
      cell: ({ row }) => {
        const value = row.original?.cashOut?.transactionId;
        return value ? `#${value}` : '-';
      },
    },
    {
      id: 'installments',
      header: () => 'N. DE PARCELAS',
      cell: ({ row }) => {
        const value = row.original?.cashIn?.installments;
        return value ? `${value}X` : '-';
      },
    },
    {
      id: 'totalAmount',
      header: () => 'TOTAL A PAGAR',
      cell: ({ row }) => {
        const value = row.original?.cashIn?.totalAmount;
        return Money(value);
      },
    },
    {
      id: 'amount',
      header: () => 'VALOR TRANSFERIDO',
      cell: ({ row }) => {
        const value = row.original?.cashIn?.amount;
        return Money(value);
      },
    },
    {
      id: 'serviceCharge',
      header: () => 'TAXA',
      cell: ({ row }) => {
        const value = row.original?.cashIn?.serviceCharge;
        return Money(value);
      },
    },
    {
      id: 'card',
      header: () => 'CARTÃO',
      cell: ({ row }) => {
        const card = row.original?.cashIn?.card || row.original?.cashOut?.card;
        return card ? `${card.last4}/${card.brand}` : '-';
      },
    },
    {
      id: 'actions',
      header: () => 'DETALHES',
      headerAlign: 'center',
      cellAlign: 'center',
      cell: (info) => (
        <Button
          onClick={() => handleDetailsClick(info.row.original)}
          customColor={colors.brand.primary}
          iconOnly
          icon="FileSearch"
          variant="text"
        />
      ),
    },
  ];

  const inputFilters = useMemo(
    () => [
      {
        id: 'status',
        input: <Select options={statusOptions} placeholder="Status da transação" allowClear />,
      },
      {
        id: 'cardId',
        input: (
          <Select
            options={cardOptions}
            placeholder="Cartão utilizado"
            notFoundContent="Nenhum cartão cadastrado"
            allowClear
          />
        ),
      },
    ],
    [cardOptions, statusOptions],
  );

  useEffect(() => {
    if (filters) {
      clear();
      fetchTransactions(0);
    }
  }, [filters]);

  useEffect(() => {
    if (clixInfoSuccess && !paginatedData?.length) {
      fetchTransactions(page);
    }
  }, [page, clixInfo, clixInfoSuccess]);

  return (
    <TableContainer>
      <Flex>
        <Filters filters={inputFilters} onSubmit={handleFilterChange} />
      </Flex>

      <Table
        noItemsMessage={
          transactionsError ? (
            <>
              <AlertCircle /> Erro ao consultar as transações
            </>
          ) : (
            'Nenhuma transação encontrada'
          )
        }
        loading={loading && !transactionsError && !paginatedData?.length}
        responsiveCols={['status', 'transactionId', 'date', 'amount', 'totalAmount', 'actions']}
        responsiveWidth={1280}
        noPadding
        columns={columns}
        data={paginatedData}
      />

      {transactions && renderPagination()}
    </TableContainer>
  );
}
