import React from 'react';
import styled from 'styled-components';

const size = 200;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  span {
    color: black;
    font-size: 16px;
    text-align: center;
    font-weight: 700;
  }
`;

export const Box = styled.div`
  width: ${size}px;
  height: ${size}px;
  border-radius: 10px;
  background-color: #fff;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Image = styled.img`
  flex-shrink: 0;
  object-fit: cover;
  width: ${size}px;
  height: ${size}px;
`;
