import React from 'react';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${props =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
  font-size: 16px;

  ${props =>
    props.collapsed &&
    css`
      display: none;
    `}

  ${props =>
    props.small &&
    css`
      font-size: 13px;
    `}
`;

export const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 25px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  span {
    font-size: 24px;
    font-weight: 400;
    color: white;
    ${props =>
      props.small &&
      css`
        font-size: 16px;
        font-weight: initial;
      `}
  }

  img {
    width: 50px;
    height: 50px;
  }

  ${props =>
    props.small &&
    css`
      width: 30px;
      height: 30px;
      border-radius: 15px;

      img {
        width: 30px;
        height: 30px;
      }
    `}
`;

export const Title = styled.span`
  font-weight: 500;
  color: black;
`;

export const SubTitle = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.grayDark};
`;
