import React, { useCallback } from 'react';
import { useAwaitControl } from 'react-redux-await-control';

import { Col, Divider, Row } from 'antd';

import modalBuilder from '~/hoc/modalBuilder';
import { getDatavalid } from '~/store/people/actions';
import masked from '~/common/masked';
import { SimpleInfo } from '~/components';
import { getContractDetails } from '~/store/contracts/actions';
import { Contract } from '~/typings/entities/contract';

const { toDate } = masked;

const MODAL_NAME = 'DATAVALID';

type DataValidProps = {
  infos: any;
  personId: number;
  setOnOkClicked: (onOk: () => any) => void;
};

function DataValid({ infos = {}, personId, setOnOkClicked }: DataValidProps) {
  const { available, probability, similarity, verificationDate, faceSimilarity, faceIsValid } =
    infos;

  const getDatavalidControl = useAwaitControl(getDatavalid);
  const getContractDetailsControl = useAwaitControl(getContractDetails);

  const contract: Contract = getContractDetailsControl.result();

  const onForceUpdateDatavalid = useCallback(() => {
    getDatavalidControl.start({ personId, forceUpdate: true });
  }, [personId]);

  setOnOkClicked(() => onForceUpdateDatavalid);

  return (
    <>
      <Row>
        <Col xs={{ span: 11 }}>
          <SimpleInfo
            label="ROSTO ENCONTRATO"
            value={available ? 'SIM' : 'NÃO'}
            tooltip="Se o rosto existe na base de dados do DATAVALID."
          />
        </Col>
        <Col xs={{ span: 12, offset: 1 }}>
          <SimpleInfo
            label="PROBABILIDADE DE AUTENTICIDADE"
            value={probability || 'Não Analisado'}
            tooltip="Probabilidade de ser a pessoa do CPF cadastrado."
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '18px' }}>
        <Col xs={{ span: 11 }}>
          <SimpleInfo
            label="ROSTO VALIDADO NO DOC"
            value={faceIsValid ? 'SIM' : 'NÃO'}
            tooltip="Se o rosto da selfie é similimar ao encontrado na foto do documento."
          />
        </Col>
        <Col xs={{ span: 12, offset: 1 }}>
          <SimpleInfo
            label="SIMILARIDADE ROSTO DOC"
            value={faceSimilarity ? `${faceSimilarity}%` : 'Não Analisado'}
            tooltip="Percentual de similaridade da selfie com a foto do documento."
          />
        </Col>
      </Row>

      <Row style={{ marginTop: '18px' }}>
        <Col xs={{ span: 11 }}>
          <SimpleInfo
            label="PERCENTUAL DATAVALID"
            value={similarity ? `${(similarity * 100).toFixed(2)}%` : 'Não Analisado'}
          />
        </Col>
        <Col xs={{ span: 12, offset: 1 }}>
          <SimpleInfo label="DATA DA VERIFICAÇÃO" value={toDate(verificationDate)} />
        </Col>
      </Row>

      <Divider />

      <Row style={{ marginTop: '18px' }}>
        <Col xs={{ span: 11 }}>
          <SimpleInfo
            label="PERCENTUAL SERPRO"
            value={
              contract?.serproScoreUnico
                ? `${(contract.serproScoreUnico * 100).toFixed(2)}%`
                : 'Não Analisado'
            }
          />
        </Col>
        <Col xs={{ span: 12, offset: 1 }}>
          <SimpleInfo
            label="DATA DA VERIFICAÇÃO - SERPRO"
            value={contract?.dataSerproScoreUnico ? toDate(contract?.dataSerproScoreUnico) : '-'}
          />
        </Col>
      </Row>
    </>
  );
}

export default modalBuilder(DataValid, MODAL_NAME, {
  title: 'Dados da Verificação',
  centered: true,
  customFooter: false,
  okText: 'Solicitar Verificação',
  width: 600,
});
