import styled, { css } from 'styled-components';

import { themeToggleColor } from '~/common/utils/theme';
import { Button as UiButton, Typography } from '~/ui/components';

export const Label = styled(Typography).attrs(({ theme }) => ({
  type: 'caption',
  color: themeToggleColor(theme, 'element.primary', { dark: 'element.secondary' }),
  element: 'p',
}))``;

type WrapperType = {
  maxHeight: number;
};

export const Wrapper = styled.div<WrapperType>`
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0.2em;
      }
    `};
`;

export const Button = styled(UiButton)`
  height: 30px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  svg {
    width: 10px;
    height: 10px;
  }
`;

type ItemProps = {
  disabled: boolean;
};

export const Item = styled.h5<ItemProps>`
  &:hover {
    background-color: ${({ theme }) => theme.colors.state.imageOverlay};
    cursor: pointer;

    p {
      font-weight: 500;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export const SubItem = styled.h6<ItemProps>`
  padding-left: 0.5em;

  &:hover {
    background-color: ${({ theme }) => theme.colors.state.hover};
    cursor: pointer;
    border-left: solid ${({ theme }) => theme.colors.brand.secondaryAlt};

    p {
      font-weight: 500;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;
