import styled from 'styled-components';
import theme from '~/ui/theme';
import { Typography } from '../Typography/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;

export const Label = styled(Typography).attrs({
  type: 'caption',
  element: 'p',
  weight: 500,
})`
  font-family: ${theme.typography.fonts.libreFranklin};
  margin-bottom: 6px;
`;

export const Info = styled(Typography).attrs({
  type: 'caption',
  element: 'span',
})`
  font-family: ${theme.typography.fonts.libreFranklin};
`;

export const Event = styled.li`
  display: flex;
  padding-bottom: 14px;
`;

export const IconContent = styled.div`
  position: relative;
  margin-right: 16px;
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  display: block;
  background-color: #ffa3d0;
  border-radius: 16px;
  border: 4px solid #ffe5f3;
`;

export const Line = styled.div`
  position: absolute;
  left: 0;
  width: 50%;
  border-right: 1px dashed;
  border-color: ${({ $isConected }) => ($isConected ? '#666666' : 'transparent')};
  height: 100%;
`;
