export default (rule, file) => {
  if (!file) return true;
  const fileSize = Number((file.size / 1000).toFixed(2));

  if (rule.min || rule.max) {
    if (rule.min && fileSize < rule.min) return false;
    if (rule.max && fileSize > rule.max) return false;
  }

  return true;
};
